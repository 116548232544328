import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import ViewMaster from "./gridmaster";
import AddReferral from "./addReferalDoctor";

const ReferralMaster = ({ match }) => (
  <Fragment>
    {/* Form Elements */}
    <Switch>
      <Route exact path={`${match.url}`} component={ViewMaster} />
      <Route exact path={`${match.url}/add`} component={AddReferral} />
      <Redirect from="*" to={match.url}></Redirect>
    </Switch>
  </Fragment>
);

export default ReferralMaster;
