import React, { Fragment, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import viewAll from "./viewAll";
import processPos from "./Process_POS";
import { ipdManagementActions } from "../../actions/ipdManagementActions";
import { wardManagementActions } from "../../actions/wardmanagement.actions";
// import "./index.scss";
import { consultantActions } from "../../actions/consultant.actions";
import { serviceMasterActions, departmentActions } from "../../actions";

class WardTransferList extends React.Component {
  componentDidMount() {
    let { dispatch } = this.props;
    dispatch(ipdManagementActions.getNewConsultant());
    dispatch(ipdManagementActions.getMaster());
    dispatch(wardManagementActions.getAllWardSubCatagory());
    dispatch(wardManagementActions.getRoomAll());
    dispatch(wardManagementActions.getBedAll());
    dispatch(consultantActions.getAll());
    dispatch(wardManagementActions.getAllWardFacility());
    dispatch(serviceMasterActions.getAllPackages());
    dispatch(wardManagementActions.getGraceperiodsettingAll());
    dispatch(departmentActions.getAll());
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={viewAll} />
          <Route path={`${this.props.match.url}/new`} component={processPos} />
          <Redirect from="*" to={`${this.props.match.url}`}></Redirect>
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { wardManagement } = state;
  return {
    wardbed: wardManagement.wardbed,
  };
}

const connectedWardTransferList = connect(mapStateToProps)(WardTransferList);
export default connectedWardTransferList;
