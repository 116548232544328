export const USER_KEY = "authorization";

export const DevelopmentApiUrl = "http://localhost:57679";

export const getApiUrl = () => {
  if (process.env.REACT_APP_ENV === "development") {
    return DevelopmentApiUrl;
  } else {
    return process.env.REACT_APP_API_URL;
  }
};
export const apiUrl = getApiUrl();
export const tiny_key = "h5ph6upxhzzjt6bl2nq1znbki8ogt87wh8ea22kx5glq01mk";
