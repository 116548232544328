import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";
import Switch from "react-switch";
import CustomButtons from "../Components/customebuttons";
import {
  registrationFeesActions,
  serviceMasterActions,
  tpaMasterActions,
} from "../../actions";
import { registrationWithFeesService } from "../../services";
import { toast, Bounce } from "react-toastify";
import PageTitle from "../MyComponents/PageTitle";
import { apiUrl } from "../../constants";
import { Icons } from "../../helpers/IconHelper";
// Forms
class RegistartionWiseserviceMaster1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedList: [],
      packageList: [],
    };
    this.onChange = this.onChange.bind(this);
    this.increased_cost_change = this.increased_cost_change.bind(this);
    this.decreased_cost_change = this.decreased_cost_change.bind(this);
    this.hide = this.hide.bind(this);
    this.showModel = this.showModel.bind(this);

    this.onRtmChange = this.onRtmChange.bind(this);
    this.onRetainValueChange = this.onRetainValueChange.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  handleActiveChange(data, index, type) {
    if (this.state.OPRegistrationtype === "services") {
      let selectedList = [...this.state.selectedList];
      selectedList[index].isActive = !selectedList[index].isActive;
      this.setState({
        selectedList: selectedList,
      });
    } else if (this.state.OPRegistrationtype === "packages") {
      let packageList = [...this.state.packageList];
      packageList[index].isActive = !packageList[index].isActive;
      this.setState({
        packageList: packageList,
      });
    }
  }

  onRetainValueChange() {
    this.setState({
      decreased_cost: 0,
      increased_cost: 0,
    });

    if (this.state.OPRegistrationtype === "services") {
      let updatedServiceList = [];
      let selectedList = [...this.state.selectedList];
      selectedList.forEach((data) => {
        data.newAmount = data.SERVICE_AMT;
        updatedServiceList.push(data);
      });
      this.setState({
        selectedList: updatedServiceList,
      });
    } else if (this.state.OPRegistrationtype === "packages") {
      let updatedPackageList = [];
      let packageList = [...this.state.packageList];
      packageList.forEach((data) => {
        data.newAmount = data.PACKAGE_AMT;
        updatedPackageList.push(data);
      });
      this.setState({
        packageList: packageList,
      });
    }
  }

  clearForm() {
    this.setState({
      rtwp_rtm_code: "",
      increased_cost: 0,
      decreased_cost: 0,
      OPRegistrationtype: "",
      selectedList: [],
      packageList: [],
    });
  }

  onRtmChange(e) {
    this.setState({
      rtwp_rtm_code: e.target.value,
      increased_cost: 0,
      decreased_cost: 0,
      OPRegistrationtype: "",
      selectedList: [],
      packageList: [],
    });
    let payload = {
      rtwp_rtm_code: e.target.value,
    };
    // registrationWithFeesService.getService(payload)
    // .then((data)=>{
    //     if(data.success){
    //         this.setState({
    //             selectedList : data.services,
    //             packageList : data.packages
    //         });
    //     }
    // });
  }

  hide() {
    this.setState({
      visible: false,
    });
  }

  showModel() {
    this.setState({
      visible: true,
    });
  }

  saveClick() {
    let { dispatch } = this.props;
    let payload = {
      type: this.state.OPRegistrationtype,
      rtwp_rtm_code: this.state.rtwp_rtm_code,
      serviceList:
        this.state.OPRegistrationtype === "services"
          ? this.state.selectedList
          : this.state.packageList,
    };
    registrationWithFeesService.addService(payload).then(
      (data) => {
        if (data.success)
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        else
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
      },
      (err) => {
        toast(err.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
      }
    );
  }

  increased_cost_change(e) {
    this.setState({
      decreased_cost: 0,
    });
    if (e.target.value) {
      if (this.state.OPRegistrationtype === "services") {
        let updatedServiceList = [];
        let selectedList = [...this.state.selectedList];
        selectedList.forEach((data) => {
          data.newAmount =
            data.SERVICE_AMT + (data.SERVICE_AMT * e.target.value) / 100;
          updatedServiceList.push(data);
        });
        this.setState({
          selectedList: updatedServiceList,
        });
      } else if (this.state.OPRegistrationtype === "packages") {
        let updatedPackageList = [];
        let packageList = [...this.state.packageList];
        packageList.forEach((data) => {
          data.newAmount =
            data.PACKAGE_AMT + (data.PACKAGE_AMT * e.target.value) / 100;
          updatedPackageList.push(data);
        });
        this.setState({
          packageList: packageList,
        });
      }
      this.setState({
        increased_cost: e.target.value,
      });
    } else {
      this.setState({
        increased_cost: 0,
      });
    }
  }

  decreased_cost_change(e) {
    this.setState({
      increased_cost: 0,
    });
    if (e.target.value) {
      if (e.target.value > 100) {
        if (this.state.OPRegistrationtype === "services") {
          let updatedServiceList = [];
          let selectedList = [...this.state.selectedList];
          selectedList.forEach((data) => {
            data.newAmount = data.SERVICE_AMT - (data.SERVICE_AMT * 100) / 100;
            updatedServiceList.push(data);
          });
          this.setState({
            selectedList: updatedServiceList,
          });
        } else if (this.state.OPRegistrationtype === "packages") {
          let updatedPackageList = [];
          let packageList = [...this.state.packageList];
          packageList.forEach((data) => {
            data.newAmount = data.PACKAGE_AMT - (data.PACKAGE_AMT * 100) / 100;
            updatedPackageList.push(data);
          });
          this.setState({
            packageList: packageList,
          });
        }
        this.setState({
          decreased_cost: 100,
        });
      } else {
        if (this.state.OPRegistrationtype === "services") {
          let updatedServiceList = [];
          let selectedList = [...this.state.selectedList];
          selectedList.forEach((data) => {
            data.newAmount =
              data.SERVICE_AMT - (data.SERVICE_AMT * e.target.value) / 100;
            updatedServiceList.push(data);
          });
          this.setState({
            selectedList: updatedServiceList,
          });
        } else if (this.state.OPRegistrationtype === "packages") {
          let updatedPackageList = [];
          let packageList = [...this.state.packageList];
          packageList.forEach((data) => {
            data.newAmount =
              data.PACKAGE_AMT - (data.PACKAGE_AMT * e.target.value) / 100;
            updatedPackageList.push(data);
          });
          this.setState({
            packageList: packageList,
          });
        }
        this.setState({
          decreased_cost: e.target.value,
        });
      }
    } else {
      if (this.state.OPRegistrationtype === "services") {
        let updatedServiceList = [];
        let selectedList = [...this.state.selectedList];
        selectedList.forEach((data) => {
          data.newAmount = data.SERVICE_AMT;
          updatedServiceList.push(data);
        });
        this.setState({
          selectedList: updatedServiceList,
        });
      } else if (this.state.OPRegistrationtype === "packages") {
        let updatedPackageList = [];
        let packageList = [...this.state.packageList];
        packageList.forEach((data) => {
          data.newAmount = data.PACKAGE_AMT;
          updatedPackageList.push(data);
        });
        this.setState({
          packageList: packageList,
        });
      }
      this.setState({
        decreased_cost: 0,
      });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onServiceValueChange(e, data) {}
  onPackageValueChange(e, data) {}

  componentDidMount() {
    let { dispatch } = this.props;
    //dispatch(serviceMasterActions.getAllPackages());
    dispatch(tpaMasterActions.getAll());
    dispatch(registrationFeesActions.getAll());
  }

  render() {
    let { serviceMaster, registrationWithFeesMaster, tpas } = this.props;

    let serviceRows;
    let packageRows;

    if (this.state.selectedList) {
      serviceRows = this.state.selectedList.map((data, index) => {
        if (this.state.filterText) {
          //let filterText= this.state.filterText.toString().toLower();
          if (
            data.USERDEFINED_NAME.toLowerCase().includes(
              this.state.filterText.toLowerCase()
            ) ||
            data.SERVICE_NAME.toLowerCase().includes(
              this.state.filterText.toLowerCase()
            ) ||
            data.SERVICE_AMT.toString()
              .toLowerCase()
              .includes(this.state.filterText.toLowerCase())
          ) {
            let tds = [<td>{index + 1}</td>];
            tds.push(<td>{data.D_CODE}</td>);
            tds.push(<td>{data.SERVICE_NAME}</td>);
            tds.push(<td>{data.SERVICE_AMT}</td>);
            tds.push(
              <td>
                <Input
                  placeholder="New Amount"
                  value={data.newAmount}
                  onChange={(e) => this.onServiceValueChange(e, data)}
                ></Input>
              </td>
            );
            tds.push(
              <td>
                <Switch
                  onChange={(e) => {
                    this.handleActiveChange(data, index, "services");
                  }}
                  checked={data.isActive}
                  className="mr-2 mb-2"
                  id="normal-switch"
                />
              </td>
            );
            return <tr key={data._id}>{tds}</tr>;
          }
        } else {
          let tds = [<td>{index + 1}</td>];
          tds.push(<td>{data.D_CODE}</td>);
          tds.push(<td>{data.SERVICE_NAME}</td>);
          tds.push(<td>{data.SERVICE_AMT}</td>);
          tds.push(
            <td>
              <Input
                placeholder="New Amount"
                value={data.newAmount}
                onChange={(e) => this.onServiceValueChange(e, data)}
              ></Input>
            </td>
          );
          tds.push(
            <td>
              <Switch
                onChange={(e) => {
                  this.handleActiveChange(data, index, "services");
                }}
                checked={data.isActive}
                className="mr-2 mb-2"
                id="normal-switch"
              />
            </td>
          );
          return <tr key={data._id}>{tds}</tr>;
        }
      });
    }
    if (this.state.packageList) {
      packageRows = this.state.packageList.map((data, index) => {
        if (this.state.filterText) {
          if (
            data.USERDEFINED_NAME.toLowerCase().includes(
              this.state.filterText.toLowerCase()
            ) ||
            data.PACKAGE_NAME.toLowerCase().includes(
              this.state.filterText.toLowerCase()
            ) ||
            data.PACKAGE_AMT.toString()
              .toLowerCase()
              .includes(this.state.filterText.toLowerCase())
          ) {
            let tds = [<td>{index + 1}</td>];
            tds.push(<td>{data.D_CODE}</td>);
            tds.push(
              <td>
                {data.PACKAGE_NAME} ({data.USERDEFINED_NAME})
              </td>
            );
            tds.push(<td>{data.PACKAGE_AMT}</td>);
            tds.push(
              <td>
                <Input
                  placeholder="New Amount"
                  value={data.newAmount}
                  onChange={(e) => this.onPackageValueChange(e, data)}
                ></Input>
              </td>
            );
            tds.push(
              <td>
                <Switch
                  onChange={(e) => {
                    this.handleActiveChange(data, index, "packages");
                  }}
                  checked={data.isActive}
                  className="mr-2 mb-2"
                  id="normal-switch"
                />
              </td>
            );

            return <tr key={data._id}>{tds}</tr>;
          }
        } else {
          let tds = [<td>{index + 1}</td>];
          tds.push(<td>{data.D_CODE}</td>);
          tds.push(
            <td>
              {data.PACKAGE_NAME} ({data.USERDEFINED_NAME})
            </td>
          );
          tds.push(<td>{data.PACKAGE_AMT}</td>);
          tds.push(
            <td>
              <Input
                placeholder="New Amount"
                value={data.newAmount}
                onChange={(e) => this.onPackageValueChange(e, data)}
              ></Input>
            </td>
          );
          tds.push(
            <td>
              <Switch
                onChange={(e) => {
                  this.handleActiveChange(data, index, "packages");
                }}
                checked={data.isActive}
                className="mr-2 mb-2"
                id="normal-switch"
              />
            </td>
          );

          return <tr key={data._id}>{tds}</tr>;
        }
      });
    }
    return (
      <Fragment>
        <PageTitle
          icon={Icons.PatientRegistration}
          heading="Registration Type Charges"
        />
        <Card>
          <CardBody>
            <Card>
              <CardHeader>
                <CardTitle></CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <Label style={{ fontWeight: "bold" }}>TPA Name</Label>
                    <Input
                      type="select"
                      onChange={this.onRtmChange}
                      name="rtwp_rtm_code"
                      value={this.state.rtwp_rtm_code}
                    >
                      <option value="">Select</option>
                      {tpas
                        ? tpas.map((data) => {
                            return (
                              <option key={data._id} value={data.code}>
                                {data.name}
                              </option>
                            );
                          })
                        : null}
                    </Input>
                  </Col>
                  <Col md={3} style={{ fontWeight: "bold" }}>
                    <Label>Search</Label>
                    <Input
                      name="filterText"
                      onChange={this.onChange}
                      placeholder="Enter Dept/name/amount"
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: "bold" }}>Choose Type</Label>
                    <FormGroup row className="hmgroup Registrationgroupradio">
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="Services"
                          value="services"
                          name="OPRegistrationtype"
                          label="Services"
                          onChange={this.onChange}
                          checked={this.state.OPRegistrationtype === "services"}
                        />
                      </Col>
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="Packages"
                          value="packages"
                          name="OPRegistrationtype"
                          label="Packages"
                          onChange={this.onChange}
                          checked={this.state.OPRegistrationtype === "packages"}
                        />
                      </Col>

                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="Groups"
                          value="groups"
                          name="OPRegistrationtype"
                          label="Groups"
                          onChange={this.onChange}
                          checked={this.state.OPRegistrationtype === "groups"}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  {/* <Col md={4}>
                                           <Button color="primary" style={{marginTop:"25px"}} onClick={this.showModel}>Add To Reg Type</Button>
                                       </Col> */}
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>Increased Cost By %</Label>
                    <Input
                      type="number"
                      max="100"
                      name="increased_cost"
                      onChange={this.increased_cost_change}
                      value={this.state.increased_cost}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Decreased Cost By %</Label>
                    <Input
                      type="number"
                      max="100"
                      name="decreased_cost"
                      onChange={this.decreased_cost_change}
                      value={this.state.decreased_cost}
                    />
                  </Col>
                  <Col md={5}>
                    <Row>
                      <Button
                        color="warning"
                        style={{ marginTop: "25px" }}
                        onClick={this.onRetainValueChange}
                      >
                        Retain Same Cost
                      </Button>
                      <Button
                        onClick={this.saveClick}
                        color="primary"
                        style={{ marginTop: "25px", marginLeft: "10px" }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={this.clearForm}
                        color="danger"
                        style={{ marginTop: "25px", marginLeft: "10px" }}
                      >
                        Clear Form
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <CardHeader>
                {this.state.OPRegistrationtype === "services"
                  ? "Service List"
                  : "Package List"}
              </CardHeader>

              <CardBody>
                {this.state.OPRegistrationtype === "services" ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>SR No.</th>
                        <th>Department</th>
                        <th>Service Name</th>
                        <th>Amount</th>
                        <th>New Amount</th>
                        <th>Active</th>
                      </tr>
                    </thead>
                    <tbody>{serviceRows}</tbody>
                  </Table>
                ) : this.state.OPRegistrationtype === "packages" ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>SR No.</th>
                        <th>Department</th>
                        <th>Package Name</th>
                        <th>Amount</th>
                        <th>New Amount</th>
                        <th>Active</th>
                      </tr>
                    </thead>
                    <tbody>{packageRows}</tbody>
                  </Table>
                ) : null}
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { registrationWithFeesMaster, serviceMaster, tpaMaster } = state;
  return {
    serviceMaster: serviceMaster,
    tpas: tpaMaster.data,
    registrationWithFeesMaster: registrationWithFeesMaster.registrationfees,
  };
}

const RegistartionWiseserviceMaster = connect(mapStateToProps)(
  RegistartionWiseserviceMaster1
);

export default RegistartionWiseserviceMaster;
