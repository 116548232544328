import { opBillingConstants } from "../constants";

export function opdBilling(state = {}, action) {
  switch (action.type) {
    case opBillingConstants.GET_ALL_OPD_BILLING_BILL_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_ALL_OPD_BILLING_BILL_SUCCESS:
      return { ...state, billList: action.data, loading: false };
    case opBillingConstants.GET_ALL_OPD_BILLING_BILL_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.GET_ALL_OPD_BILLING_HOLD_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_ALL_OPD_BILLING_HOLD_SUCCESS:
      return { ...state, holdbills: action.data, loading: false };
    case opBillingConstants.GET_ALL_OPD_BILLING_HOLD_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_BILLBYUHID_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLBYUHID_SUCCESS:
      return { ...state, patientbills: action.data, loading: false };
    case opBillingConstants.GET_OPD_BILLBYUHID_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_BILLING_QUEUE_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLING_QUEUE_SUCCESS:
      return { ...state, duebills: action.data, loading: false };
    case opBillingConstants.GET_OPD_BILLING_QUEUE_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_BILLBYDATERANGE_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLBYDATERANGE_SUCCESS:
      return {
        ...state,
        billList: action.body.data ? action.body.data.data : [],
        sumBillAmount: action.body.data ? action.body.data.sumTotalAmount : 0,
        pagedInfo: action.body?.pagedInfo,
        loading: false,
      };
    case opBillingConstants.GET_OPD_BILLBYDATERANGE_FAILURE:
      return { ...state, loading: false };

    //BILL_RECEIVABLE
    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_SUCCESS:
      return {
        ...state,
        receivableBillList: action.body.data ? action.body.data.data : [],
        pagedInfo: action.body?.pagedInfo,
        sumBillAmount: action.body.data ? action.body.data.sumTotalAmount : 0,
        loading: false,
      };
    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_BILLRECEIVABLE_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLRECEIVABLE_SUCCESS:
      return {
        ...state,
        pagedInfo: action.body?.pagedInfo,
        receivableBillList: action.body.data ? action.body.data.data : [],
        sumBillAmount: action.body.data ? action.body.data.sumTotalAmount : 0,
        loading: false,
      };
    case opBillingConstants.GET_OPD_BILLRECEIVABLE_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.GET_OPD_BILLCALCELLED_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLCALCELLED_SUCCESS:
      return {
        ...state,
        cancelBillList: action.body.data ? action.body.data.data : [],
        pagedInfoCancelled: action.body?.pagedInfo,
        sumBillAmount: action.body.data ? action.body.data.sumTotalAmount : 0,
        loading: false,
        type: "cancel",
      };
    case opBillingConstants.GET_OPD_BILLCALCELLED_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_BILLREFUNDED_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILLREFUNDED_SUCCESS:
      return {
        ...state,
        refundBillList: action.body.data ? action.body.data.data : [],
        pagedInfoRefunded: action.body?.pagedInfo,
        sumBillAmount: action.body.data ? action.body.data.sumTotalAmount : 0,
        loading: false,
        type: "refund",
      };
    case opBillingConstants.GET_OPD_BILLREFUNDED_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_ADVANCE_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.ADD_OPD_BILLING_ADVANCE_SUCCESS:
      return { ...state, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_ADVANCE_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.ADD_OPD_BILLING_SUCCESS:
      let patientbills = [];
      if (state.patientbills) patientbills = [...state.patientbills];
      patientbills.push(action.data);
      return { ...state, patientbills: patientbills, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_HOLD_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.ADD_OPD_BILLING_HOLD_SUCCESS:
      let holdbills = [];
      if (state.holdbills) holdbills = [...state.holdbills];
      holdbills.push(action.data);
      return { ...state, holdbills: holdbills, loading: false };
    case opBillingConstants.ADD_OPD_BILLING_HOLD_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.CLEAR_OPD_BILLING_HOLD_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.CLEAR_OPD_BILLING_HOLD_SUCCESS:
      let clearholdbills = [];
      if (state.holdbills) {
        clearholdbills = [...state.holdbills];
        let index = clearholdbills.findIndex(
          (data) => data._id === action.data._id
        );
        clearholdbills.splice(index, 1);
      }
      return { ...state, holdbills: clearholdbills, loading: false };
    case opBillingConstants.CLEAR_OPD_BILLING_HOLD_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.SELECT_BILL_SUCCESS:
      return { ...state, selected_bill: action.data };

    case opBillingConstants.CANCEL_BILL_BYBILLNO_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.CANCEL_BILL_BYBILLNO_SUCCESS:
      let patientcancelbills = [];
      let cancelIndex = -1;
      if (state.billList) {
        patientcancelbills = [...state.billList];
        patientcancelbills.forEach((data, index) => {
          if (data.bill_no === action.data.bill_no) {
            cancelIndex = index;
          }
        });
        patientcancelbills[cancelIndex].isCanceled = true;
      }
      return { ...state, billList: patientcancelbills, loading: false };
    case opBillingConstants.CANCEL_BILL_BYBILLNO_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.GET_OPD_RECEIVABLEBYUHID_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_RECEIVABLEBYUHID_SUCCESS:
      return { ...state, s: action.data, loading: false };
    case opBillingConstants.GET_OPD_RECEIVABLEBYUHID_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_SUCCESS:
      return { ...state, billList: action.data, loading: false };
    case opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_SUCCESS:
      return { ...state, receivableBillList: action.data, loading: false };
    case opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_FAILURE:
      return { ...state, loading: false };
    case opBillingConstants.REFUND_OPD_BILLBYBILLNO_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.REFUND_OPD_BILLBYBILLNO_SUCCESS:
      let billList = [];
      if (state.billList) {
        billList = [...state.billList];
        billList.forEach((data, index) => {
          if (data.bill_no === action.data.bill_no) {
            billList[index].isRefunded = true;
            billList[index].refund_date = action.data.refund_date;
            billList[index].refund_amount = action.data.refund_amount;
            billList[index].paid_through = action.data.paid_through;
            if (action.data.cheque_no)
              billList[index].cheque_no = action.data.cheque_no;
            if (action.data.cheque_date)
              billList[index].cheque_date = action.data.cheque_date;
            if (action.data.bank_name)
              billList[index].bank_name = action.data.bank_name;
            billList[index].refund_reason = action.data.reason;
          }
        });
      }
      return { ...state, billList: billList, loading: false };
    case opBillingConstants.REFUND_OPD_BILLBYBILLNO_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_REQUEST:
      return { ...state, loading: true };
    case opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_SUCCESS:
      let newBillList = [...state.receivableBillList];
      let billIndex = 0;
      if (newBillList) {
        newBillList.forEach((data, index) => {
          if (data.bill_no === action.data.bill_no) {
            billIndex = index;
          }
        });
        newBillList[billIndex] = action.data;
      }
      return { ...state, receivableBillList: newBillList, loading: false };
    case opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_FAILURE:
      return { ...state, loading: false };

    case opBillingConstants.CLAER_BILL:
      return {};

    default:
      return state;
  }
}
