import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const dmsService = {
    getdmsdata,
    uploadtodms,
    getdmsalldata
}

function getdmsdata(data) {

    const requestOptions = {
        method: 'POST',
      headers:{'Content-Type':'application/json',  authorization : helperService.getHeaderData() , } ,     
        body:JSON.stringify( data)
    };

    return fetch(`${apiUrl}/dmsapi/getdmsdetails`, requestOptions).then(helperService.handleResponse);
}

function getdmsalldata() {

    const requestOptions = {
        method: 'POST',
      headers:{'Content-Type':'application/json',  authorization : helperService.getHeaderData() , } ,     
    };

    return fetch(`${apiUrl}/dmsapi/getdmsalldetails`, requestOptions).then(helperService.handleResponse);
}

function uploadtodms(data) {

    console.log(data, "1111111111")

    const requestOptions = {
        method: 'POST',
    //   headers:{ 'Content-Type':"application/x-www-form-urlencoded", authorization : helperService.getHeaderData()  } ,     
    //   headers:{ 'Content-Type':"multipart/form-data; boundary=----WebKitFormBoundary4sbEIoc6zsvIkqg9", authorization : helperService.getHeaderData()  } ,     
      headers:{ authorization : helperService.getHeaderData()  } ,     
        body:data
    };

    return fetch(`${apiUrl}/dmsapi/uploadtodms`, requestOptions).then(helperService.handleResponse);
}