import { pharmacyConstants } from '../constants';
import { alertActions } from './alert.actions';
import {pharmacyService} from '../services';

export const  pharmacyActions = {  
  getAll
};

function getAll() {
  return dispatch => {     
    pharmacyService.getAll()
          .then(
              data =>{
                if(data.success)
                {
                  console.log('Pharmacy Response --------->>>>>',data)
                  dispatch({ type: pharmacyConstants.GET_ALL_PHARMACIES, pharmacies:data.pharmacies})                   
                }                 
                }             
          );
  };


 
}

