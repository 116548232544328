import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { discountAuthService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const discountAuthActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    discountAuthService.getAll().then(
      (data) => {
        if (data.success) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_DISCOUNT_AUTH_REQUEST };
  }
  function success(discountAuthorizations) {
    return {
      type: generalMasterConstants.GET_DISCOUNT_AUTH_SUCCESS,
      discountAuthorizations,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_DISCOUNT_AUTH_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    discountAuthService.add(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(data.value.discountAuthorization));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_DISCOUNT_AUTH_REQUEST };
  }
  function success(discountAuthorization) {
    return {
      type: generalMasterConstants.ADD_DISCOUNT_AUTH_SUCCESS,
      discountAuthorization,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_DISCOUNT_AUTH_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    discountAuthService.update(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(payload));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DISCOUNT_AUTH_REQUEST };
  }
  function success(discountAuthorization) {
    return {
      type: generalMasterConstants.UPDATE_DISCOUNT_AUTH_SUCCESS,
      discountAuthorization,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_DISCOUNT_AUTH_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    discountAuthService.remove(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(payload));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_DISCOUNT_AUTH_REQUEST };
  }
  function success(discountAuthorization) {
    return {
      type: generalMasterConstants.DELETE_DISCOUNT_AUTH_SUCCESS,
      discountAuthorization,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_DISCOUNT_AUTH_FAILURE, error };
  }
}
