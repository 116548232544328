import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  CardHeader
} from "reactstrap";
import CustomButtons from "../../Components/customebuttons";
import {registrationFeesActions, departmentActions} from "../../../actions";
import { consultantActions } from '../../../actions/consultant.actions';
import PageTitle from '../../MyComponents/PageTitle';
import {apiUrl} from '../../../constants'
import {tpaMasterActions} from '../../../actions'
import { Icons } from "../../../helpers/IconHelper";

// Forms
class TPAConsultantMaster extends React.Component {
    constructor(props){
        super(props);
        this.state= {};
        this.onChange= this.onChange.bind(this);
    }

    onChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onConsultantChange(e){
        let {consultants} = this.props;
        this.setState({
            con_code: e.target.value
        });
        if(consultants){
          let con =  consultants.find(item=>item.code===e.target.value);
          let opfees = 0;
          let ipfees= 0;
          if(con){
              ipfees = con.ipvisitcharges? con.ipvisitcharges.fees : 0;
              opfees = con.opvisitcharges? con.opvisitcharges.fees : 0;
          }
          this.setState({
              ipfees: ipfees,
              opfees: opfees
          })
        }

    }

    onSaveClick(){
        let {dispatch} = this.props;
        dispatch(tpaMasterActions.addConsFees(this.state))

    }

    onUpdateClick(){
        let {dispatch} = this.props;
        dispatch(tpaMasterActions.updateConsFees(this.state))
    }

    onCancelClick(){

    }

    componentDidMount(){
        let {dispatch} = this.props;
        dispatch(departmentActions.getAll());
        dispatch(consultantActions.getAll());
        dispatch(tpaMasterActions.getAll())
        dispatch(registrationFeesActions.getConsAll())
    }

    converttpacodetovalue(code){
            let {tpas} = this.props;
            try{
            if(tpas)
                 return tpas.find(item=>item.code===code).name;
                else return code;
            }
            catch{
                return code
            }
    
        }
    

    convertrtmcodetovalue(code){
        let {registrationWithFeesMaster} = this.props;
        try{
        if(registrationWithFeesMaster)
return registrationWithFeesMaster.find(item=>item.rtm_code===code).rtm_reg_type;
else return code;
        }
        catch{
            return code
        }

    }

    consultantToValue(code)
{
  try{
const {consultants}=this.props
if(consultants)
return consultants.find(item=>item.code===code).firstname
else return code;
  }
  catch{
    return code;
  }
}

departmentToValue(code)
{
  try{
const {departments}=this.props
if(departments)
return departments.find(item=>item.code===code).name
else return code;
  }
  catch{
    return code;
  }
}

editButtonClick(data){

}

deleteButtonClick(data){}


    render(){
        let {departments,consultants,registrationWithFeesMaster,registrationConsWithFeesMaster, tpas,tpaConsList} = this.props;
        let tableRows;
       if(tpaConsList){
           console.log("consoling from fees", tpaConsList);
           tableRows = tpaConsList.map((data,index)=>{
           let tds=[<td>{index+1}</td>];
           tds.push(<td>{this.converttpacodetovalue(data.tpa_code)}</td>)
           tds.push(<td>{this.consultantToValue(data.con_code)}</td>)
           tds.push(<td>{this.departmentToValue(data.dept_code)}</td>);
           tds.push(<td>{data.visit_charges}</td>);
           tds.push(<td>{data.followup_charges}</td>);
           tds.push(<td>
               <CustomButtons type="edit" onClickHandler={()=>this.editButtonClick(data,index)} />
               <CustomButtons type="delete" onClickHandler = {()=>this.deleteButtonClick(data._id,index)} />
           </td>)

           return (<tr key={data._id}>{tds}</tr>)
           })
        }

        return(
            <Fragment>
                <PageTitle  icon={Icons.PatientRegistration} heading="TPA Wise Consultant Fees"
                 data={registrationConsWithFeesMaster} csvFilename="tpawiseconsultant.csv"
                />
                <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    {this.state.isEditFlow? "Update":"ADD"}
                                    
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    
                                    <Col md={3}>
                                        <Label>TPA Type</Label>
                                        <Input type="select" name="tpa_code" 
                                        value={this.state.tpa_code}
                                        onChange={this.onChange}>
                                            <option value="">Select</option>
                                            {tpas?tpas.map((data)=>{
                                            return <option key={data._id} value={data.code}>{data.name}</option> 
                                            }):null}
                                        </Input>
                                    </Col>
                                    <Col md={3}>
                                        <Label>Department</Label>
                                        <Input type="select" name="dept_code" 
                                        value={this.state.dept_code}
                                        onChange={this.onChange}>
                                            <option value="">Select</option>
                                            {
                                                            departments? departments.map((type)=>{
                                                            return <option key={type._id} value={type.code} >{type.name}</option>
                                                            }):null
                                                        }
                                        </Input>
                                    </Col>
                                    <Col md={3}>
                                        <Label>Consultant</Label>
                                        <Input type="select" name="con_code" value={this.state.con_code}
                                        onChange={(e)=>this.onConsultantChange(e)}>
                                            <option value="">Select</option>
                                            {
                                        consultants? consultants.map((data)=>{
                                        return <option value={data.code} key={data._id}> {data.firstname} {data.lastname}</option>
                                        }) : null
                                    }
                                        </Input>
                                    </Col>
                                    <Col md={3}>
                                        <Label>IP/OP</Label>
                                        <Input type="select" 
                                        value={this.state.ip_op}
                                        name="ip_op" onChange={this.onChange}>
                                            <option value="">Select</option>
                                            <option value="ip">IP</option>
                                            <option value="op">OP</option>
                                        </Input>
                                    </Col>
                                    <Col md={2}>
                                        <Label>Old Fees</Label>
                                        <Input type="number" value = {this.state.ip_op === "ip"? this.state.ipfees : this.state.opfees} disabled></Input>
                                    </Col>
                                    <Col md={2}>
                                        <Label>Increment Type</Label>
                                        <Input type="select" onChange={this.onTypeChange}>
                                            <option value="per">Percentage</option>
                                            <option value="amount">Amount</option>
                                        </Input>
                                    </Col>
                                    <Col md={2}>
                                        <Label>Visit Charges</Label>
                                        <Input type="text" name="visit_charges" 
                                        value={this.state.visit_charges}
                                        onChange={this.onChange} placeholder="Visit Charges" />

                                    </Col>
                                    <Col md={2}>
                                        <Label>Follow Up Charges</Label>
                                        <Input onChange={this.onChange}
                                        value={this.state.followup_charges}
                                        name="followup_charges" placeholder="Follow up charges"></Input>
                                    </Col>
                                    
                                    <Col md={2}>
                                        <div style={{marginTop:"20px"}}>
                                        {!this.state.isEditFlow?
                                        <>
                                        <CustomButtons type="save" onClickHandler={this.onSaveClick}/>
                                        <CustomButtons type="clear" onClickHandler={this.onCancelClick}/>
                                        </>  :
                                        <>
                                       
                                        <CustomButtons type="update"  onClickHandler={this.onUpdateClick}/>
                                        <CustomButtons type="clear"  onClickHandler={this.onCancelClick}/>
                                        </> 
                                    
                                    }
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card style={{marginTop:"10px"}}>
                        <CardHeader>
                                <CardTitle>
                                    TPA Wise Consultant List
                                    
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>SR. No</th>
                                                
                                                <th>
                                                    TPA Type
                                                </th>
                                                <th>
                                                   Consultant Name 
                                                </th>
                                                <th>
                                                    Department
                                                </th>
                                                <th>
                                                    Visiting Fees
                                                </th>
                                                <th>
                                                    Follow Up Fees
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {tableRows}
                                        </tbody>
                                    </Table>
                            </CardBody>
                        </Card>
                        </CardBody>
                        </Card>

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { registrationWithFeesMaster, departments,  consultants,registrationConsWithFeesMaster, tpaMaster,tpaConsList} = state;
    return { 
        departments:departments.departments,
        consultants:consultants.consultants,
        registrationWithFeesMaster: registrationWithFeesMaster.registrationfees,
        registrationConsWithFeesMaster : registrationConsWithFeesMaster.registrationconfees,
        tpas: tpaMaster.data,
        tpaConsList : tpaConsList
    };
}

const ConnectedFessConsultantMaster = connect(mapStateToProps)(TPAConsultantMaster);

export { ConnectedFessConsultantMaster as TPAConsultantMaster} ;