import { alertActions } from "./alert.actions";
import { Bounce, toast } from "react-toastify";
import { httpHelperService } from "../services";
import {
  ipdManagementConstants,
  getAdmTypeContstant,
  getAllPatientConstants,
  getNewConsultantConstants,
  getReferalConstants,
  getInsuranceConstant,
  getCorporateConstants,
  getWardNamesConstant,
  getRoomTypeConstant,
  getMasterConstant,
} from "../constants";

import { ipdPatientManagementConstants } from "../constants/ipd.management.constants";

export const ipdManagementActions = {
  addNewPatient,
  getPatientAll,
  getAdmTypes,
  getNewConsultant,
  getReferals,
  getInsuranceCompany,
  getCorporateCompany,
  getWardNames,
  getRoomTypes,
  getMaster,
  admitPatient,
};

function admitPatient(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "api/ipadmission/admitPatient";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: ipdPatientManagementConstants.ADMIT_PATIENT_REQUEST };
  }
  function success(data) {
    return { type: ipdPatientManagementConstants.ADMIT_PATIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: ipdPatientManagementConstants.ADMIT_PATIENT_FAILURE, error };
  }
}

function addNewPatient() {
  return (dispatch) => {
    dispatch(request());
    let url = "";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          // toast(result.message,{
          //     transition :Bounce,
          //     closeButton :true,
          //     autoClose :5000,
          //     position : 'botton-center',
          //     type : 'success',
          // });
          // dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          // toast(result.message,{
          //     transition : Bounce,
          //     closeButton : true,
          //     autoClose :5000,
          //     position : 'bottom-center',
          //     type : 'error',
          // });
        }
      },
      (error) => {
        // toast(error.message, {
        //     transition : Bounce,
        //     closeButton : true,
        //     autoClose : 5000,
        //     position : 'bottom-center',
        //     type : 'error',
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: ipdManagementConstants.ADDNEW_PATIENT_REQUEST };
  }
  function success(data) {
    return { type: ipdManagementConstants.ADDNEW_PATIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: ipdManagementConstants.ADDNEW_PATIENT_FAIL, error };
  }
}

function getPatientAll() {
  return (dispatch) => {
    dispatch(request());
    let url = "https://aqua.101logix.com/";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: getAllPatientConstants.GETALL_PATIENT_REQUEST };
  }
  function success(data) {
    return { type: getAllPatientConstants.GETALL_PATIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: getAllPatientConstants.GETALL_PATIENT_FAIL, error };
  }
}

function getAdmTypes() {
  return (dispatch) => {
    dispatch(request());
    let url = "opregistration";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          console.log("sucess data ===========>");
          console.log(result.data);
          dispatch(success(result.data));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getAdmTypeContstant.GETALL_ADMTYPE_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getAdmTypeContstant.GETALL_ADMTYPE_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getAdmTypeContstant.GETALL_ADMTYPE_FAIL, error };
  }
}

function getNewConsultant() {
  return (dispatch) => {
    dispatch(request());
    let url = "newconsultant";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          console.log("dattttttttttttttttttttttttttttttttttttttttttt");
          console.log(result.consultants);
          dispatch(success(result.consultants));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getNewConsultantConstants.GETALL_NEWCONSULT_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getNewConsultantConstants.GETALL_NEWCONSULT_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getNewConsultantConstants.GETALL_NEWCONSULT_FAIL, error };
  }
}

function getReferals() {
  return (dispatch) => {
    dispatch(request());
    let url = "referrals";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          console.log(result.referrals);
          dispatch(success(result.referrals));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getReferalConstants.GETALL_REFERAL_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getReferalConstants.GETALL_REFERAL_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getReferalConstants.GETALL_REFERAL_FAIL, error };
  }
}

function getInsuranceCompany() {
  return (dispatch) => {
    dispatch(request());
    let url = "tpas";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.tpas));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getInsuranceConstant.GETALL_INSURANCE_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getInsuranceConstant.GETALL_INSURANCE_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getInsuranceConstant.GETALL_INSURANCE_FAIL, error };
  }
}

function getCorporateCompany() {
  return (dispatch) => {
    dispatch(request());
    let url = "corporates";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.corporates));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getCorporateConstants.GETALL_CORPORATE_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getCorporateConstants.GETALL_CORPORATE_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getCorporateConstants.GETALL_CORPORATE_FAIL, error };
  }
}

function getWardNames() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    console.log("1");
    return { type: getWardNamesConstant.GETALL_WARDNAMES_REQUEST };
  }
  function success(data) {
    console.log("2");
    console.log(data);
    return { type: getWardNamesConstant.GETALL_WARDNAMES_SUCCESS, data };
  }
  function failure(error) {
    console.log("3");
    return { type: getWardNamesConstant.GETALL_WARDNAMES_FAIL, error };
  }
}

function getRoomTypes(wardName) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardroomapi/getRoomBySubcatagory";

    httpHelperService.postRequest(wardName, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: getRoomTypeConstant.GETALL_ROOMTYPE_REQUEST };
  }
  function success(data) {
    return { type: getRoomTypeConstant.GETALL_ROOMTYPE_SUCCESS, data };
  }
  function failure(error) {
    return { type: getRoomTypeConstant.GETALL_ROOMTYPE_FAIL, error };
  }
}

function getMaster() {
  return (dispatch) => {
    dispatch(request());
    let url = "masters";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          //   dispatch(alertActions.success(result.message))
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: getMasterConstant.GETALL_MASTER_REQUEST };
  }
  function success(data) {
    return { type: getMasterConstant.GETALL_MASTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: getMasterConstant.GETALL_MASTER_FAIL, error };
  }
}
