import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class LoginPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <FormGroup style={{ width: "100%" }}>
              <Label for="exampleEmail">Username</Label>
              <Input
                type="text"
                name="username"
                id="exampleUsername"
                onChange={(e) =>
                  this.props.onChangeHandler("customer_email", e.target.value)
                }
                placeholder="Username here..."
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup style={{ width: "100%" }}>
              <Label for="examplePassword">Password</Label>
              <InputGroup>
                <Input
                  name="password"
                  id="examplePassword"
                  onChange={(e) =>
                    this.props.onChangeHandler("password", e.target.value)
                  }
                  type={this.state.hidden ? "password" : "text"}
                  maxLength={20}
                  placeholder="Password here..."
                />
                <InputGroupAddon addonType="append">
                  <Button color="primary" onClick={this.toggleShow}>
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <FormGroup style={{ width: "100%" }}>
              <Label for="location">Location</Label>
              <Input
                type="select"
                id="Location"
                placeholder="Select Location"
                onChange={(e) =>
                  this.props.onChangeHandler(
                    "customer_trans_id",
                    e.target.value
                  )
                }
              >
                <option value="5e4591c5ac7f1f2cfc557c56">Bangalore</option>
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup style={{ width: "100%" }}>
              <Label for="login_type">Login As</Label>
              <Input
                type="select"
                id="login_type"
                onChange={(e) => {
                  this.props.onChangeHandler("login_type", e.target.value);
                }}
              >
                <option value="user">Employee</option>
                <option value="consultant">Dr./Consultant</option>
                <option value="patient">Patient</option>
              </Input>
            </FormGroup>
          </Col>
        </Row> */}
      </div>
    );
  }
}
