import { otbookingConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { otBookingService } from "../services";
import { toast, Bounce } from "react-toastify";

export const otBookingActions = {
  getAll,
  add,
  update,
  remove,
  changeStatus,
  selectBooking,
  getByConsultant,
  cancelBooking,
  setReminder,
};

function getAll(query) {
  return (dispatch) => {
    dispatch(request());
    otBookingService.getAll(query).then(
      (data) => {
        if (data.success) {
          dispatch(success(data.bookings));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_REQUEST };
  }
  function success(bookings) {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_SUCCESS, bookings };
  }
  function failure(error) {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_FAILURE, error };
  }
}

function getByConsultant(id) {
  return (dispatch) => {
    dispatch(request());
    otBookingService.getByConsultantId(id).then(
      (data) => {
        if (data.success) {
          dispatch(success(data.bookings));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_REQUEST };
  }
  function success(bookings) {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_SUCCESS, bookings };
  }
  function failure(error) {
    return { type: otbookingConstants.GET_ALL_OT_BOOKING_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    return otBookingService.add(payload).then(
      (result) => {
        if (result.success === true) {
          dispatch(success(result.booking));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 2000,
            position: "bottom-center",
            type: "success",
          });
          return true;
        } else {
          toast(result.message ? result.message : "", {
            transition: Bounce,
            closeButton: true,
            autoClose: 2000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
          return false;
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.ADD_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.ADD_OT_BOOKING_SUCCESS, booking };
  }
  function failure(error) {
    return { type: otbookingConstants.ADD_OT_BOOKING_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());

    return otBookingService.update(payload).then(
      (result) => {
        //console.log('Booking update result',result)
        if (result.success) {
          dispatch(success(result.booking));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          return true;
        } else {
          dispatch(failure(result.err));
          return false;
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_SUCCESS, booking };
  }
  function failure(error) {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_FAILURE, error };
  }
}

function changeStatus(payload) {
  return (dispatch) => {
    dispatch(request());

    return otBookingService.changeStatus(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.booking));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          return true;
        } else {
          dispatch(failure(result.err));
          return false;
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_SUCCESS, booking };
  }
  function failure(error) {
    return { type: otbookingConstants.UPDATE_OT_BOOKING_FAILURE, error };
  }
}

function selectBooking(booking) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(booking));
  };

  function request() {
    return { type: otbookingConstants.SELECTED_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.SELECTED_OT_BOOKING_SUCCESS, booking };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    otBookingService.remove(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.DELETE_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.DELETE_OT_BOOKING_SUCCESS, booking };
  }
  function failure(error) {
    return { type: otbookingConstants.DELETE_OT_BOOKING_FAILURE, error };
  }
}

function cancelBooking(payload) {
  return (dispatch) => {
    dispatch(request());

    otBookingService.cancelBooking(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.booking));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.DELETE_OT_BOOKING_REQUEST };
  }
  function success(booking) {
    return { type: otbookingConstants.DELETE_OT_BOOKING_SUCCESS, booking };
  }
  function failure(error) {
    return { type: otbookingConstants.DELETE_OT_BOOKING_FAILURE, error };
  }
}
function setReminder(payload) {
  return (dispatch) => {
    dispatch(request());
    otBookingService.setReminder(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.reminder));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: otbookingConstants.OT_BOOKING_REMINDER_REQUEST };
  }
  function success(reminder) {
    return { type: otbookingConstants.OT_BOOKING_REMINDER_SUCCESS, reminder };
  }
  function failure(error) {
    return { type: otbookingConstants.OT_BOOKING_REMINDER_FAILURE, error };
  }
}
