import { appointmentConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { appointmentService } from "../services";
import { toast, Bounce } from "react-toastify";
import { patientActions } from "./patient.actions";

export const appointmentActions = {
  getAll,
  add,
  update,
  remove,
  selectAppointment,
  getByConsultant,
  UpdateAppointmentStatus,
  setReminder,
};

function MapOldpayloadAppointmentDateToNew(payload){
  return {
    patientId: payload.patient_id.id,
    consultantId: payload.consultant_id,
    appointmentType: parseInt(payload.appointment_type),
    status: payload.status,
    appointmentDate: payload.appointment_date,
    slot: payload.slot,
    timeSlotFrom: payload.time_slot_from,
    slotDuration: payload.slot_duration,
    serviceType: payload.service_type,
    plannedProcedures: payload.planned_procedures,
    procedureNotes: payload.procedure_notes,
    roomNumber: payload.room_number,
    hotspitalCode: payload.hotspital_code,
    transactionCode: payload.transaction_code,
    departmentId: payload.department_id,
    appointmentId: payload.appointment_id
  }
}

function MapNewpayloadAppointmentDateToOld(payload){
  return {
    _id:payload.id,
    patient_id: patientActions.oldBody(payload.patient),
    consultant_id: payload.consultantId,
    appointment_type: payload.appointmentType,
    status: payload.status,
    appointment_date: payload.appointmentDate,
    slot: payload.slot,
    time_slot_from: payload.timeSlotFrom,
    slot_duration: payload.slotDuration,
    service_type: payload.serviceType,
    planned_procedures: payload.plannedProcedures,
    procedure_notes: payload.procedureNotes,
    room_number: payload.roomNumber,
    hotspital_code: payload.hotspitalCode,
    transaction_code: payload.transactionCode,
    reason_for_cancel: payload.cancelReason,
    department_id: payload.departmentId,
  }
}

function getAll(query) {
  return (dispatch) => {
    dispatch(request());
    appointmentService.getAll(query).then(
      (data) => {
        if (data.isSuccess) {
          if (data.value) {
            const oldPayload = data.value.map((appointment) => {
              return MapNewpayloadAppointmentDateToOld(appointment);
            });
          dispatch(success(oldPayload));
        }} else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.GET_ALL_APPOINTMENT_REQUEST };
  }
  function success(appointments) {
    return {
      type: appointmentConstants.GET_ALL_APPOINTMENT_SUCCESS,
      appointments
    };
  }
  function failure(error) {
    return { type: appointmentConstants.GET_ALL_APPOINTMENT_FAILURE, error };
  }
}

function getByConsultant(id) {
  return (dispatch) => {
    dispatch(request());
    appointmentService.getByConsultantId(id).then(
      (data) => {
        if (data.isSuccess) {
          if (data.value) {
            const oldPayload = data.value.map((appointment) => {
              return MapNewpayloadAppointmentDateToOld(appointment);
            });
          dispatch(success(oldPayload));
          dispatch(alertActions.success("Consultants loaded"));
        }} else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.GET_ALL_APPOINTMENT_REQUEST };
  }
  function success(appointments) {
    return {
      type: appointmentConstants.GET_ALL_APPOINTMENT_SUCCESS,
      appointments,
    };
  }
  function failure(error) {
    return { type: appointmentConstants.GET_ALL_APPOINTMENT_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    const newPaylod = MapOldpayloadAppointmentDateToNew(payload);
    return appointmentService.add(newPaylod).then(
      (result) => {
        if (result.isSuccess === true) {
          dispatch(success(result.value));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.successMessage));
          return true;
        } else {
          toast(result.validationErrors[0].errorMessage ? result.validationErrors[0].errorMessage : "", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
          return false;
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.ADD_APPOINTMENT_REQUEST };
  }
  function success(appointment) {
    return { type: appointmentConstants.ADD_APPOINTMENT_SUCCESS, appointment };
  }
  function failure(error) {
    return { type: appointmentConstants.ADD_APPOINTMENT_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());
    const data = MapOldpayloadAppointmentDateToNew(payload)
    data.id = payload.appointment_id;
    return appointmentService.update(data).then(
      (result) => {
        console.log("Appointment update result", result);
        if (result.isSuccess) {
          dispatch(success(result.value));
          dispatch(alertActions.success(result.successMessage));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          return true;
        } else {
          dispatch(failure(result.err));
          return false;
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.UPDATE_APPOINTMENT_REQUEST };
  }
  function success(appointment) {
    return {
      type: appointmentConstants.UPDATE_APPOINTMENT_SUCCESS,
      appointment,
    };
  }
  function failure(error) {
    return { type: appointmentConstants.UPDATE_APPOINTMENT_FAILURE, error };
  }
}

function UpdateAppointmentStatus(payload) {
  return (dispatch) => {
    dispatch(request());


    const data  = {
      id: payload._id,
      status: payload.status
    } 

    appointmentService.UpdateAppointmentStatus(data).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));
          dispatch(alertActions.success(result.successMessage));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.UPDATE_APPOINTMENT_REQUEST };
  }
  function success(appointment) {
    return {
      type: appointmentConstants.UPDATE_APPOINTMENT_SUCCESS,
      appointment,
    };
  }
  function failure(error) {
    return { type: appointmentConstants.UPDATE_APPOINTMENT_FAILURE, error };
  }
}
function setReminder(payload) {
  return (dispatch) => {
    dispatch(request());

    appointmentService.setReminder(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.reminder));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.SET_REMINDER_REQUEST };
  }
  function success(reminder) {
    return { type: appointmentConstants.SET_REMINDER_SUCCESS, reminder };
  }
  function failure(error) {
    return { type: appointmentConstants.SET_REMINDER_FAILURE, error };
  }
}

function selectAppointment(appointment) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(appointment));
  };

  function request() {
    return { type: appointmentConstants.SELECTED_APPOINTMENT_REQUEST };
  }
  function success(appointment) {
    return {
      type: appointmentConstants.SELECTED_APPOINTMENT_SUCCESS,
      appointment,
    };
  }
}
function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    appointmentService.remove(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.successMessage));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: appointmentConstants.DELETE_APPOINTMENT_REQUEST };
  }
  function success(appointment) {
    return {
      type: appointmentConstants.DELETE_APPOINTMENT_SUCCESS,
      appointment,
    };
  }
  function failure(error) {
    return { type: appointmentConstants.DELETE_APPOINTMENT_FAILURE, error };
  }
}
