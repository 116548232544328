import { otbookingConstants } from "../constants";

export function bookings(state = { loading: false, bookings: [] }, action) {
  switch (action.type) {
    case otbookingConstants.GET_ALL_OT_BOOKING_REQUEST:
      return { ...state, loading: true };
    case otbookingConstants.GET_ALL_OT_BOOKING_SUCCESS:
      return { ...state, bookings: action.bookings, loading: false };
    case otbookingConstants.GET_ALL_OT_BOOKING_FAILURE:
      return { ...state, error: action.error, loading: false };

    case otbookingConstants.GET_BY_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case otbookingConstants.GET_BY_CONSULTANT_SUCCESS:
      return { ...state, consultantWise: action.bookings, loading: false };
    case otbookingConstants.GET_BY_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case otbookingConstants.ADD_OT_BOOKING_REQUEST:
      return { ...state, loading: true };
    case otbookingConstants.ADD_OT_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: [...state.bookings, action.booking],
        selected_booking: action.booking,
        loading: false,
      };
    case otbookingConstants.ADD_OT_BOOKING_FAILURE:
      return { ...state, error: action.error, loading: false };

    case otbookingConstants.SELECTED_OT_BOOKING_REQUEST:
      return { ...state, loading: true };
    case otbookingConstants.SELECTED_OT_BOOKING_SUCCESS:
      return {
        ...state,
        selected_booking: action.booking,
        loading: false,
      };
    case otbookingConstants.SELECTED_OT_BOOKING_FAILURE:
      return { ...state, error: action.error, loading: false };

    case otbookingConstants.UPDATE_OT_BOOKING_REQUEST:
      return { ...state, loading: true };

    case otbookingConstants.UPDATE_OT_BOOKING_SUCCESS:
      let updatedApp = action.booking;
      let index = state.bookings.findIndex((ob) => ob._id === updatedApp._id);

      state.bookings[index] = updatedApp;
      return {
        ...state,
        bookings: [...state.bookings],
        //selected_booking: null,
        loading: false,
      };

    case otbookingConstants.UPDATE_OT_BOOKING_FAILURE:
      return { ...state, error: action.error, loading: false };

    case otbookingConstants.DELETE_OT_BOOKING_REQUEST:
      return { ...state, loading: true };

    case otbookingConstants.DELETE_OT_BOOKING_SUCCESS:
      let deletedbooking = action.booking;
      let cindex = state.bookings.findIndex(
        (ob) => ob._id === deletedbooking._id
      );
      state.bookings[cindex] = deletedbooking;
      return {
        ...state,
        bookings: [...state.bookings],
        loading: false,
      };
    case otbookingConstants.DELETE_OT_BOOKING_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
