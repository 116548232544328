import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from "reactstrap";
import Switch from "react-switch";
import { connect } from "react-redux";
import { wardManagementActions } from "../../actions";
import CustomButtons from "../Components/customebuttons";
import DataTable2 from "../MyComponents/DataTable";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "../MyComponents/PageTitle";
import { apiUrl } from "../../constants";
import { Icons } from "../../helpers/IconHelper";

class WardBed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Add",
      ward_type: "",
      ward_catagory: "",
      ward_sub_catagory: "",
      room_id: "",
      room_name: "",
      bed_no: "",
      bed_prefix: "",
      total_beds: "",
      errormessage: "",
      visible: false,
      bed_suffix: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onWardChange = this.onWardChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onWardTypeCodeToName = this.onWardTypeCodeToName.bind(this);
    this.onWardCatagoryToName = this.onWardCatagoryToName.bind(this);
    this.wardSubCatagoryCodeToValue = this.wardSubCatagoryCodeToValue.bind(
      this
    );
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCatagoryChange = this.onCatagoryChange.bind(this);
    this.onBedChange = this.onRoomChange.bind(this);
    this.onSubCatagoryChange = this.onSubCatagoryChange.bind(this);
  }
  validationSchema = Yup.object().shape({
    ward_type: Yup.string().required("This field is required"),
    ward_catagory: Yup.string().required("This field is required"),
    ward_sub_catagory: Yup.string().required("This field is required"),
    room_name: Yup.string().required("This field is required"),
    room_id: Yup.string().required("This field is required"),
    // bed_prefix: Yup.string().required("This field is required"),
    bed_no: Yup.string().required("This field is required"),
    total_beds: Yup.string().required("This field is required"),
    // bed_suffix: Yup.string().required("This field is required"),
  });
  hide() {
    this.setState({
      visible: !this.state.hide,
    });
  }

  onDeleteClick(data) {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.deactiveWardRoom(data));
  }

  onUpdateClick() {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.updateWardSubCatagory(this.state));
    this.onClearClick();
  }

  onWardCatagoryToName(code) {
    let { wardcatagorys } = this.props;
    try {
      let name = wardcatagorys.find((data) => data.code === code).ward_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onWardTypeCodeToName(code) {
    let { wardtypes } = this.props;
    try {
      let name = wardtypes.find((data) => data.code === code).ward_type;
      return name;
    } catch {
      return code;
    }
  }

  wardSubCatagoryCodeToValue(code) {
    let { wardsubcatagory } = this.props;
    try {
      let name = wardsubcatagory.find((data) => data.code === code)
        .ward_sub_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onEditClick(data) {
    this.setState({
      ward_type: data.ward_type,
      ward_catagory: data.ward_catagory,
      ward_sub_catagory: data.ward_sub_catagory,
      room_name: data.room_name,
      _id: data._id,
      created_on: data.created_on,
      code: data.code,
      created_by: data.created_by,
      mode: "Edit",
    });
  }

  formValidation() {
    if (!this.state.ward_type) {
      this.setState({
        errormessage: "Ward Type required",
        visible: true,
      });
      return false;
    }
    if (!this.state.ward_catagory) {
      this.setState({
        errormessage: "Ward Catagory required",
        visible: true,
      });
      return false;
    }
    if (!this.state.ward_sub_catagory) {
      this.setState({
        errormessage: "Ward sub catagory required",
        visible: true,
      });
      return false;
    }

    if (!this.state.room_name) {
      this.setState({
        errormessage: "Room name required",
        visible: true,
      });
      return false;
    }

    return true;
  }

  onSubmitClick() {
    // let validateForm = this.formValidation();
    // if (!validateForm) return;
    let { dispatch } = this.props;
    const {
      ward_type,
      ward_catagory,
      ward_sub_catagory,
      room_id,
      room_name,
      bed_no,
      bed_prefix,
      bed_suffix,
      total_beds,
    } = this.state;

    const payload = {
      ward_type: ward_type,
      ward_catagory: ward_catagory,
      ward_sub_catagory: ward_sub_catagory,
      room_name: room_name,
      room_id: room_id,
      bed_no: bed_no,
      bed_prefix: bed_prefix,
      bed_suffix: bed_suffix,
      total_beds: total_beds,
      mode: "Add",
    };
    dispatch(wardManagementActions.addWardBed(payload));
    this.onClearClick();
    this.componentDidMount();
  }

  onClearClick() {
    this.setState({
      ward_type: "",
      ward_catagory: "",
      ward_sub_catagory: "",
      room_name: "",
      room_id: "",
      bed_prefix: "",
      bed_no: "",
      total_beds: "",
      bed_suffix: "",
      _id: undefined,
      mode: "Add",
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onWardChange(e) {
    let { dispatch } = this.props;
    dispatch(
      wardManagementActions.getByWardType({ ward_type: e.target.value })
    );
    this.setState({
      ward_type: e.target.value,
      ward_catagory: "",
      ward_sub_catagory: "",
      room_name: "",
      room_id: "",
      ward_room: "",
      wardRoomList: "",
    });
  }

  onRoomChange(e) {
    let { wardroom } = this.props;

    let roomList = wardroom.filter(
      (value) => value.default_room_name === e.target.value
    );
    this.setState({
      room_name: e.target.value,
      wardRoomList: roomList,
    });
  }

  onRoomListChange(e) {
    this.setState({
      room_id: e.target.value,
    });
  }

  componentDidMount() {
    let { dispatch } = this.props;
    // dispatch(userTypeActions.getAll());
    dispatch(wardManagementActions.getAllWardCatagory());
    dispatch(wardManagementActions.getWardAll());
    dispatch(wardManagementActions.getAllWardSubCatagory());
    dispatch(wardManagementActions.getRoomAll());
    dispatch(wardManagementActions.getBedAll());
  }

  handleClick(data) {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.deactiveWardBed({ _id: data._id }));
  }

  onCatagoryChange(e) {
    let { dispatch } = this.props;
    dispatch(
      wardManagementActions.getByWardCatagory({ ward_catagory: e.target.value })
    );
    this.setState({
      ward_catagory: e.target.value,
      ward_sub_catagory: "",
      room_name: "",
      room_id: "",
      ward_room: "",
      wardRoomList: "",
    });
  }

  onSubCatagoryChange(e) {
    const { wardroom } = this.props;

    const filterwardroom = wardroom.filter(
      (list) => list.ward_sub_catagory === e.target.value
    );

    const wardRoomGroup = _.chain(filterwardroom)
      .groupBy("default_room_name")
      .map((value, key) => ({
        default_room_name: value[0].default_room_name,
      }))
      .value();
    this.setState({
      ward_sub_catagory: e.target.value,
      ward_room: wardRoomGroup,
      room_name: "",
      room_id: "",
      wardRoomList: "",
    });
  }

  columns = [
    {
      name: "Ward Type",
      selector: (row) => this.onWardTypeCodeToName(row.ward_type),
      sortable: true,
    },
    {
      name: "Ward Category",
      selector: (row) => this.onWardCatagoryToName(row.ward_catagory),
      sortable: true,
    },
    {
      name: "Ward Sub Category",
      selector: (row) => this.wardSubCatagoryCodeToValue(row.ward_sub_catagory),
      sortable: true,
    },
    {
      name: "Room Name List",
      selector: (row) => row.room_name,
      sortable: true,
    },
    {
      name: "Bed Name",
      selector: (row) => row.bed_name,
      sortable: true,
    },
    {
      name: "Bed Status",
      selector: (row) =>
        row.bed_status === "A"
          ? "Available"
          : row.bed_status === "O"
          ? "Occupied"
          : "",
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => (
        <FormGroup>
          <Switch
            checked={row.isActive}
            className="mr-2 mb-2"
            id="normal-switch"
            onChange={(e) => this.handleClick(row)}
          />
        </FormGroup>
      ),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: "Created_on",
      selector: (row) => moment(row.created_on).format("DD/MM/YYYY"),
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //       <CustomButtons
    //         type="edit"
    //         disabled={row.isDeleted}
    //         onClickHandler={() => this.onEditClick(row)}
    //       ></CustomButtons>
    //       {row.isDeleted ? (
    //         <CustomButtons
    //           type="restore"
    //           onClickHandler={() => this.onDeleteClick(row)}
    //         ></CustomButtons>
    //       ) : (
    //         <CustomButtons
    //           type="delete"
    //           onClickHandler={() => this.onDeleteClick(row)}
    //         ></CustomButtons>
    //       )}
    //     </>
    //   ),
    // },
  ];

  render() {
    let { wardbycatagorys, wardtypes, wardsubcatagory, wardbed } = this.props;
    let { wardRoomList, ward_room } = this.state;

    // if (wardroom != undefined) {
    // const wardRoomGroup = _(wardroom)
    //   .groupBy((item) => item.default_room_name)
    //   .value();

    return (
      <Fragment>
        <PageTitle
          icon={Icons.PatientRegistration}
          heading="Ward Bed"
          columns={this.columns}
          data={wardbed}
          csvFilename="wardbed.csv"
        ></PageTitle>
        <Formik
          initialValues={this.state}
          validationSchema={this.validationSchema}
          onSubmit={
            this.state.mode === "Edit" ? this.onUpdateClick : this.onSubmitClick
          }
          enableReinitialize
        >
          {(props) => {
            const { errors } = props;
            return (
              <Form>
                <Card>
                  <CardHeader>{this.state.mode} Ward Bed</CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <Label>Ward Type</Label>
                        <Input
                          name="ward_type"
                          type="select"
                          value={this.state.ward_type}
                          onChange={this.onWardChange}
                        >
                          <option value="">Select</option>
                          {wardtypes
                            ? wardtypes.map((data, index) => {
                                if (data.isActive && !data.isDeleted)
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_type}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_type && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_type}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label>Ward Catagory</Label>
                        <Input
                          name="ward_catagory"
                          value={this.state.ward_catagory}
                          type="select"
                          onChange={this.onCatagoryChange}
                        >
                          <option value="">Select</option>
                          {wardbycatagorys
                            ? wardbycatagorys.map((data) => {
                                if (data.isActive && !data.isDeleted)
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_catagory}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_catagory && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_catagory}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label>Ward Sub Catagory</Label>
                        <Input
                          type="select"
                          name="ward_sub_catagory"
                          value={this.state.ward_sub_catagory}
                          onChange={this.onSubCatagoryChange}
                        >
                          <option value="">Select</option>
                          {wardsubcatagory
                            ? wardsubcatagory.map((data) => {
                                if (
                                  data.isActive &&
                                  !data.isDeleted &&
                                  data.ward_catagory ===
                                    this.state.ward_catagory
                                )
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_sub_catagory}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_sub_catagory && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_sub_catagory}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label>Ward Room Name</Label>
                        <Input
                          type="select"
                          name="room_name"
                          value={this.state.room_name}
                          onChange={(e) => this.onRoomChange(e)}
                        >
                          <option value="">Select</option>
                          {ward_room
                            ? ward_room.map((data, index) => {
                                return (
                                  <option
                                    key={data._id}
                                    value={data.default_room_name}
                                  >
                                    {data.default_room_name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                        {errors.room_name && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_name}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Room Number List</Label>
                        <Input
                          type="select"
                          name="room_id"
                          value={this.state.room_id}
                          onChange={(e) => this.onRoomListChange(e)}
                        >
                          <option value="">Select</option>
                          {wardRoomList
                            ? wardRoomList.map((data, index) => {
                                if (data.isActive)
                                  return (
                                    <option key={data._id} value={data._id}>
                                      {data.room_name}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.room_name && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_name}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Bed Prefix</Label>
                        <Input
                          type="text"
                          name="bed_prefix"
                          value={this.state.bed_prefix}
                          onChange={this.onChange}
                          placeholder="Bed Prefix"
                        ></Input>
                        {errors.bed_prefix && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.bed_prefix}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Bed No. start with</Label>
                        <Input
                          type="number"
                          name="bed_no"
                          value={this.state.bed_no}
                          onChange={this.onChange}
                          placeholder="Bed No"
                        ></Input>
                        {errors.bed_no && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.bed_no}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> No. of Beds</Label>
                        <Input
                          type="number"
                          name="total_beds"
                          value={this.state.total_beds}
                          onChange={this.onChange}
                          placeholder="No of Beds"
                        ></Input>
                        {errors.total_beds && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.total_beds}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Bed Suffix</Label>
                        <Input
                          type="text"
                          name="bed_suffix"
                          value={this.state.bed_suffix}
                          onChange={this.onChange}
                          placeholder="Bed Suffix"
                        ></Input>
                        {errors.bed_suffix && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.bed_suffix}
                          </div>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          type="submit"
                          color="primary"
                          style={{ marginTop: "30px", marginRight: "5px" }}
                          // onClick={
                          //   this.state.mode === "Add"
                          //     ? this.onSubmitClick
                          //     : this.onUpdateClick
                          // }
                        >
                          {this.state.mode === "Add" ? "Submit" : "Update"}
                        </Button>
                        <Button
                          color="warning"
                          style={{ marginTop: "30px" }}
                          onClick={this.onClearClick}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>Ward Bed List</CardHeader>
                  <CardBody>
                    <DataTable2
                      columns={this.columns}
                      data={wardbed}
                      conditionalRowStyles={this.conditionalRowStyles}
                      //   onRowClicked={(row) => {
                      //     this.onViewClicked(row);
                      //   }}
                      subHeader={true}
                      subHeaderComponent={<Row></Row>}
                      autoFocus={true}
                    />
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { newMaster, wardManagement } = state;
  return {
    newMaster: newMaster.userType,
    wardtypes: wardManagement.wardtypes,
    wardroom: wardManagement.wardroom,
    wardbed: wardManagement.wardbed,
    wardbycatagorys: wardManagement.wardbycatagorys,
    wardcatagorys: wardManagement.wardcatagorys,
    wardsubcatagory: wardManagement.wardsubcatagory,
  };
}

const connectedUserType = connect(mapStateToProps)(WardBed);

export default connectedUserType;
