import { faEraser, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { Bounce, toast } from "react-toastify";
import { appointmentStatus, getAppointmentStatusNumber } from "../../constants/appointmentStatus";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { departmentActions } from "../../actions";
import { consultantService } from "../../services";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

class BlockCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      consultant: null,
      appointment_date: new Date(),
      allSlots: null,
      slots_on_date: null,
    };
  }

  componentDidMount() {
    const { dispatch, consultant } = this.props;
    dispatch(departmentActions.getAll());
    if (consultant) {
      this.initialValues.consultantId = consultant.code;
      this.onConsultantSelected({ target: { value: consultant.code } });
    }
  }

  time_onSelect(key, name, data, event) {
    let value = moment(data).format("h:mm a");
    let list = [...this.state.data];

    switch (name) {
      case "slotAfrom":
        if (list[key].slotA.from !== "") {
          if (
            value === list[key].slotB.from ||
            value === list[key].slotC.from
          ) {
            toast("Please choose a different time", {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "error",
            });
            return;
          }
        }
        if (list[key].slotA.to === "") {
          list[key].slotA.to = moment(data)
            .add(
              this.props.formdata.slot_duration,
              this.props.formdata.slot_unit
            )
            .format("h:mm a");
        }
        list[key].slotA.from = value;
        break;
      case "slotAto":
        list[key].slotA.to = value;
        break;
      case "slotBfrom":
        if (list[key].slotB.from !== "") {
          if (
            value === list[key].slotA.from ||
            value === list[key].slotC.from
          ) {
            toast("Please choose a different time", {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "error",
            });
            return;
          }
        }
        list[key].slotB.from = value;
        if (list[key].slotB.to === "") {
          list[key].slotB.to = moment(data)
            .add(
              this.props.formdata.slot_duration,
              this.props.formdata.slot_unit
            )
            .format("h:mm a");
        }
        break;
      case "slotBto":
        list[key].slotB.to = value;
        break;
      case "slotCfrom":
        if (list[key].slotC.from !== "") {
          if (
            value === list[key].slotA.from ||
            value === list[key].slotB.from
          ) {
            toast("Please choose a different time", {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "error",
            });
            return;
          }
        }
        list[key].slotC.from = value;
        if (list[key].slotC.to === "") {
          list[key].slotC.to = moment(data)
            .add(
              this.props.formdata.slot_duration,
              this.props.formdata.slot_unit
            )
            .format("h:mm a");
        }
        break;
      case "slotCto":
        list[key].slotC.to = value;
        break;
    }

    let slotA = list[key].slotA;
    let slotB = list[key].slotB;
    let slotC = list[key].slotC;
    let slotAHours =
      (moment(slotA.to, "h:mm a") - moment(slotA.from, "h:mm a")) /
      (60 * 60 * 1000);
    let slotBHours =
      (moment(slotB.to, "h:mm a") - moment(slotB.from, "h:mm a")) /
      (60 * 60 * 1000);
    let slotCHours =
      (moment(slotC.to, "h:mm a") - moment(slotC.from, "h:mm a")) /
      (60 * 60 * 1000);

    slotAHours = isNaN(slotAHours) ? 0 : slotAHours;
    slotBHours = isNaN(slotBHours) ? 0 : slotBHours;
    slotCHours = isNaN(slotCHours) ? 0 : slotCHours;
    slotA.totalSlots = (slotAHours * 60) / this.props.formdata.slot_duration;
    slotB.totalSlots = (slotBHours * 60) / this.props.formdata.slot_duration;
    slotC.totalSlots = (slotCHours * 60) / this.props.formdata.slot_duration;

    console.log({ slotAHours, slotBHours, slotCHours });
    if (slotAHours + slotBHours + slotCHours > 24) {
      toast("Max slot duration can be 24 hours only.", {
        transition: Bounce,
        closeButton: true,
        autoClose: 2000,
        position: "bottom-center",
        type: "warning",
      });
      return;
    }
    this.setState({ data: list }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.data);
      }
    });
  }

  onClearClicked() {
    this.initialValues = {
      consultantId: "",
      blockReason: "",
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      duration: "All Day",
      slotABlocked: false,
      slotBBlocked: false,
      slotCBlocked: false,
      appointmentAction: appointmentStatus.Reschedule
    };
    this.setState({ initialValues: this.initialValues, selected_item: null });
  }

  initialValues = {
    consultantId: "",
    blockReason: "",
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    duration: "All Day",
    slotABlocked: false,
    slotBBlocked: false,
    slotCBlocked: false,
    appointmentAction: appointmentStatus.Reschedule
  };

  onSubmit = (values) => {
    const { dispatch } = this.props;
    values.consultantId = values.consultantId.value
      ? values.consultantId.value
      : values.consultantId;
    values.appointmentAction = parseInt(values.appointmentAction);
    consultantService
      .blockCalendar(values)
      .then((res) => {
        if (res.isSuccess)
          toast("Calendar blocked", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
        this.props.toggle(true);
      })
      .catch((err) => {
        toast("Some error occured", {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "warning",
        });
      });
  };

  validationSchema = Yup.object({
    consultantId: Yup.string().required("Required"),
    blockReason: Yup.string().required("Required"),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required"),
    duration: Yup.string().required("Required"),
    slotABlocked: Yup.bool(),
    slotBBlocked: Yup.bool(),
    slotCBlocked: Yup.bool(),
    appointmentAction: Yup.number().required("Required"),
  });

  onConsultantSelected(e, values) {
    const target = e.target ? e.target : e;
    const { consultants, departments } = this.props;
    const selectedCons = consultants.find((cons) => cons.code === target.value);
    if (selectedCons) {
      const selectedDept =
        departments &&
        departments.find((dep) => dep.code === selectedCons.department);

      this.setState(
        {
          allSlots: selectedCons.slot_setting,
          consultant: selectedCons,
          department: selectedDept,
        },
        () => {
          const date = new Date();
          const day = date.getDay();
          const allSlots = this.state.allSlots;
          const result =
            allSlots && allSlots.find((item) => item.day === days[day]);
          this.setState({ slots_on_date: result });
        }
      );
    }
  }

  onDateChange(e) {
    const target = e.target ? e.target : e;
    const date = new Date(target.value);
    const day = date.getDay();
    const allSlots = this.state.allSlots;
    const result = allSlots && allSlots.find((item) => item.day === days[day]);
    this.setState({ slots_on_date: result, appointment_date: date });
  }

  render() {
    const { consultant, department, slots_on_date } = this.state;

    let data = {};
    let maxTime = moment()
      .endOf("day")
      .toDate();

    const handleReset = (resetForm) => {
      resetForm();
    };

    return (
      <Fragment>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={this.onSubmit}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              // handleReset,
              getFieldProps,
              setFieldValue,
              setFieldTouched,
            } = props;
            return (
              <Modal
                isOpen={this.props.openModal}
                toggle={this.props.toggle}
                style={{ width: "550px" }}
              >
                <ModalHeader toggle={this.props.toggle}>
                  Block Calendar, Cancel and Reschedule Appointments
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <ReactCSSTransitionGroup
                      component="div"
                      transitionName="TabsAnimation"
                      transitionAppear={true}
                      transitionAppearTimeout={0}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <Row>
                        <Col sm={8} className="offset-sm-2">
                          <FormGroup>
                            <Label>Consultant</Label>
                            <ReactSelect
                              name="consultantId"
                              {...getFieldProps("consultantId")}
                              options={this.props.consultants.map((item) => ({
                                label:
                                  item.firstname +
                                  " " +
                                  (item.lastname ? item.lastname : ""),
                                value: item.id,
                              }))}
                              onChange={(e) => {
                                setFieldValue("consultantId", e);
                                this.onConsultantSelected(e, values);
                              }}
                              value={values.consultantId}
                            >
                              {" "}
                            </ReactSelect>

                            {errors.consultantId && touched.consultantId && (
                              <FormFeedback>
                                {errors.consultantId}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <>
                              {department && (
                                <div>
                                  <Label style={{ textAlign: "right" }}>
                                    Department : {department && department.name}
                                  </Label>
                                </div>
                              )}
                            </>
                          </FormGroup>

                          <Row>
                            <Col sm={4}>
                              <Label>Duration</Label>
                            </Col>
                            <Col sm={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="duration"
                                    value="All Day"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                  All Day
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="duration"
                                    value="Time Range"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                  Time Range
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            {slots_on_date && values.duration === "Time Range" && (
                              <>
                                <div>
                                  <Label>Select Time to Block :</Label>
                                </div>
                                <Row>
                                  <Col md={4}>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          name="slotABlocked"
                                          checked={values.slotABlocked}
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        />
                                        <span className="badge badge-pill badge-primary">
                                          {" "}
                                          A : {slots_on_date.slotA.from}-
                                          {slots_on_date.slotA.to}
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    {" "}
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          name="slotBBlocked"
                                          checked={values.slotBBlocked}
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        />
                                        <span className="badge badge-pill badge-primary">
                                          {" "}
                                          B : {slots_on_date.slotB.from}-
                                          {slots_on_date.slotB.to}
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          name="slotCBlocked"
                                          checked={values.slotCBlocked}
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        />
                                        <span className="badge badge-pill badge-primary">
                                          {" "}
                                          C : {slots_on_date.slotC.from}-
                                          {slots_on_date.slotC.to}
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </Col>

                                  <Col md={4}>
                                    From
                                    <DatePicker
                                      showTimeSelect
                                      showTimeSelectOnly
                                      className="form-control"
                                      value={data.from}
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat="h:mm A"
                                    />
                                  </Col>
                                  <Col md={4}>
                                    To
                                    <DatePicker
                                      showTimeSelect
                                      showTimeSelectOnly
                                      className="form-control"
                                      minTime={
                                        new Date(
                                          moment(data.from, "h:mm a").add(
                                            5,
                                            "m"
                                          )
                                        )
                                      }
                                      maxTime={maxTime}
                                      value={
                                        data.to
                                          ? data.to
                                          : data.from
                                          ? moment(data.from, "h:mm a")
                                              .add(5, 288)
                                              .format("h:mm a")
                                          : ""
                                      }
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat="h:mm a"
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </FormGroup>
                          <FormGroup row>
                            <Col md={6}>
                              <Label>Start Date</Label>
                              <InputGroup>
                                <Input
                                  type="date"
                                  name="startDate"
                                  bsSize="sm"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.onDateChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  {...getFieldProps("startDate")}
                                />
                              </InputGroup>
                              {errors.startDate && touched.startDate && (
                                <FormFeedback>{errors.startDate}</FormFeedback>
                              )}
                            </Col>
                            <Col md={6}>
                              <Label>End Date</Label>
                              <InputGroup>
                                <Input
                                  type="date"
                                  name="endDate"
                                  bsSize="sm"
                                  {...getFieldProps("endDate")}
                                />
                              </InputGroup>
                              {errors.endDate && touched.endDate && (
                                <FormFeedback>{errors.endDate}</FormFeedback>
                              )}
                            </Col>
                          </FormGroup>

                          <Row>
                            <Col sm={4}>
                              <Label>Appointment Action</Label>
                            </Col>
                            <Col sm={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="appointmentAction"
                                    value={appointmentStatus.Cancelled}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                  Cancel
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="appointmentAction"
                                    value={appointmentStatus.Reschedule}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                  Reschedule
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>

                          <FormGroup>
                            <Label>Block Reason</Label>
                            <Input
                              type="textarea"
                              name="blockReason"
                              invalid={
                                errors.blockReason && touched.blockReason
                              }
                              bsSize="sm"
                              placeholder="Block Reason"
                              {...getFieldProps("blockReason")}
                            />
                          </FormGroup>
                          {errors.blockReason && touched.blockReason && (
                            <FormFeedback>{errors.blockReason}</FormFeedback>
                          )}
                          <br />

                          <Row>
                            <Col sm={4} className="offset-sm-4">
                              <Button
                                color="warning"
                                className="btn btn-block"
                                onClick={handleReset.bind(
                                  null,
                                  props.resetForm
                                )}
                              >
                                <FontAwesomeIcon icon={faEraser} size="1x" />{" "}
                                Clear
                              </Button>
                            </Col>
                            <Col sm={4}>
                              <Button
                                color="primary"
                                className="btn btn-block"
                                type="submit"
                              >
                                <FontAwesomeIcon icon={faSave} size="1x" /> Save
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ReactCSSTransitionGroup>
                  </Form>
                </ModalBody>
              </Modal>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, customers, departments } = state;

  return {
    consultants: consultants.consultants,
    transaction: customers.currentTransaction,
    departments: departments.departments,
  };
}

const connectedBlockCalendar = connect(mapStateToProps)(BlockCalendar);
export default connectedBlockCalendar;
