import { createBrowserHistory } from "history";
// export const history = createBrowserHistory();

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

//remove the unwanted pathname from path url
export const removePath = (url) => {
  if (!url) return url;
  return url.slice(0, url.lastIndexOf("/"));
};

export const historyBack = (props) => {
  console.log(props, "propsdata", props?.location?.pathname?.includes('in-patient-department'))
  if (props.location?.state && !props?.location?.state?.recordType && !props?.location?.pathname?.includes('in-patient-department') && !props?.location?.pathname?.includes('laboratory')) {
    props.history.push({
      pathname: removePath(props.location.pathname),
      state: props.location.state,
    });
  } else {
    props.history.goBack();
  }
};
