export const newMasterConstants = {
  GET_ALL_EDUCATION_REQUEST: "GET_ALL_EDUCATION_REQUEST",
  GET_ALL_EDUCATION_SUCCESS: "GET_ALL_EDUCATION_SUCCESS",
  GET_ALL_EDUCATION_FAILURE: "GET_ALL_EDUCATION_FAILURE",

  ADD_EDUCATION_REQUEST: "ADD_EDUCATION_REQUEST",
  ADD_EDUCATION_SUCCESS: "ADD_EDUCATION_SUCCESS",
  ADD_EDUCATION_FAILURE: "ADD_EDUCATION_FAILURE",

  UPDATE_EDUCATION_REQUEST: "UPDATE_EDUCATION_REQUEST",
  UPDATE_EDUCATION_SUCCESS: "UPDATE_EDUCATION_SUCCESS",
  UPDATE_EDUCATION_FAILURE: "UPDATE_EDUCATION_FAILURE",

  DELETE_EDUCATION_REQUEST: "DELETE_EDUCATION_REQUEST",
  DELETE_EDUCATION_SUCCESS: "DELETE_EDUCATION_SUCCESS",
  DELETE_EDUCATION_FAILURE: "DELETE_EDUCATION_FAILURE",

  GET_ALL_USERTYPE_REQEST: "GET_ALL_USER_TYPE_REQUEST",
  GET_ALL_USERTYPE_SUCCESS: "GET_ALL_USER_TYPE_SUCCESS",
  GET_ALL_USERTYPE_FAILURE: "GET_ALL_USER_TYPE_FAILRE",

  UPDATE_USER_TYPE_REQUEST: "UPDATE_USER_TYPE_REQUEST",
  UPDATE_USER_TYPE_SUCCESS: "UPDATE_USER_TYPE_SUCCESS",
  UPDATE_USER_TYPE_FAILURE: "UPDATE_USER_TYPE_FAILURE",

  GET_ALL_DESIGNATION_REQUEST: "GET_ALL_DESIGNATION_REQUEST",
  GET_ALL_DESIGNATION_SUCCESS: "GET_ALL_DESIGNATION_SUCCESS",
  GET_ALL_DESIGNATION_FAILURE: "GET_ALL_DESIGNATION_FAILURE",

  ADD_DESIGNATION_REQUEST: "ADD_DESIGNATION_REQUEST",
  ADD_DESIGNATION_SUCCESS: "ADD_DESIGNATION_SUCCESS",
  ADD_DESIGNATION_FAILURE: "ADD_DESIGNATION_FAILURE",

  UPDATE_DESIGNATION_REQUEST: "UPDATE_DESIGNATION_REQUEST",
  UPDATE_DESIGNATION_SUCCESS: "UPDATE_DESIGNATION_SUCCESS",
  UPDATE_DESIGNATION_FAILURE: "UPDATE_DESIGNATION_FAILURE",

  DELETE_DESIGNATION_REQUEST: "DELETE_DESIGNATION_REQUEST",
  DELETE_DESIGNATION_SUCCESS: "DELETE_DESIGNATION_SUCCESS",
  DELETE_DESIGNATION_FAILURE: "DELETE_DESIGNATION_FAILURE",

  GET_ALL_ROLE_REQUEST: "GET_ALL_ROLE_REQUEST",
  GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
  GET_ALL_ROLE_FAILURE: "GET_ALL_ROLE_FAILURE",

  GET_ROLE_DATA_REQUEST: "GET_ROLE_DATA_REQUEST",
  GET_ROLE_DATA_SUCCESS: "GET_ROLE_DATA_SUCCESS",
  GET_ROLE_DATA_FAILURE: "GET_ROLE_DATA_FAILURE",

  DELETE_ROLE_DATA_REQUEST: "DELETE_ROLE_DATA_REQUEST",
  DELETE_ROLE_DATA_SUCCESS: "DELETE_ROLE_DATA_SUCCESS",
  DELETE_ROLE_DATA_FAILURE: "DELETE_ROLE_DATA_FAILURE",

  STATUS_UPDATE_ROLE_DATA_REQUEST: "STATUS_UPDATE_ROLE_DATA_REQUEST",
  STATUS_UPDATE_DATA_SUCCESS: "STATUS_UPDATE_DATA_SUCCESS",
  STATUS_UPDATE_DATA_FAILURE: "STATUS_UPDATE_DATA_FAILURE",

  GET_ALL_ACCESSGROUP_REQUEST: "GET_ALL_ACCESSGROUP_REQUEST",
  GET_ALL_ACCESSGROUP_SUCCESS: "GET_ALL_ACCESSGROUP_SUCCESS",
  GET_ALL_ACCESSGROUP_FAILURE: "GET_ALL_ACCESSGROUP_FAILURE",

  ADD_ACCESSGROUP_REQUEST: "ADD_ACCESSGROUP_REQUEST",
  ADD_ACCESSGROUP_SUCCESS: "ADD_ACCESSGROUP_SUCCESS",
  ADD_ACCESSGROUP_FAILURE: "ADD_ACCESSGROUP_FAILURE",

  UPDATE_ACCESSGROUP_REQUEST: "UPDATE_ACCESSGROUP_REQUEST",
  UPDATE_ACCESSGROUP_SUCCESS: "UPDATE_ACCESSGROUP_SUCCESS",
  UPDATE_ACCESSGROUP_FAILURE: "UPDATE_ACCESSGROUP_FAILURE",

  UPDATE_STATUS_ACCESSGROUP_REQUEST: "UPDATE_STATUS_ACCESSGROUP_REQUEST",
  UPDATE_STATUS_ACCESSGROUP_SUCCESS: "UPDATE_STATUS_ACCESSGROUP_SUCCESS",
  UPDATE_STATUS_ACCESSGROUP_FAILURE: "UPDATE_STATUS_ACCESSGROUP_FAILURE",

  DELETE_ACCESSGROUP_REQUEST: "DELETE_ACCESSGROUP_REQUEST",
  DELETE_ACCESSGROUP_SUCCESS: "DELETE_ACCESSGROUP_SUCCESS",
  DELETE_ACCESSGROUP_FAILURE: "DELETE_ACCESSGROUP_FAILURE",

  GET_ALL_ACCESSLIST_REQUEST: "GET_ALL_ACCESSLIST_REQUEST",
  GET_ALL_ACCESSLIST_SUCCESS: "GET_ALL_ACCESSLIST_SUCCESS",
  GET_ALL_ACCESSLIST_FAILURE: "GET_ALL_ACCESSLIST_FAILURE",

  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",

  CREATE_ORGANIZATION_REQUEST: "CREATE_ORGANIZATION_REQUEST",
  CREATE_ORGANIZATION_SUCCESS: "CREATE_ORGANIZATION_SUCCESS",
  CREATE_ORGANIZATION_FAILURE: "CREATE_ORGANIZATION_FAILURE",

  CLEAR: "CLEAR",
};
