import { consultantConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { consultantService } from "../services";
import { toast, Bounce } from "react-toastify";

export const consultantActions = {
  getAll,
  add,
  update,
  selectConsultant,
  setDefault,
  remove,
  search,
};

const mapOldToNewBody = (oldBody) => {
  const {
    aadhar_num,
    favourite_consultant,
    address,
    blood_group_code,
    category,
    certificate,
    costcenter,
    code,
    contact_no,
    department,
    designation_id,
    email,
    firstname,
    gender,
    deleted,
    ip_discount_applicable,
    ipvisitcharges,
    lastname,
    op_discount_applicable,
    opvisitcharges,
    photo,
    qualification,
    registration_no,
    send_email_notification,
    send_sms_notification,
    signature,
    slot_duration,
    slot_setting,
    slot_unit,
    title,
    id,
    selectedRoles,
    password,
    confirm_password,
    active,
  } = oldBody;

  return {
    slotDuration: slot_duration,
    slotUnit: `${slot_unit}`,
    sendSmsNotification: send_sms_notification,
    sendEmailNotification: send_email_notification,
    opDiscountApplicable: op_discount_applicable,
    ipDiscountApplicable: ip_discount_applicable,
    tokenApplicable: true, // Assuming tokenApplicable is always true
    qrCode: "", // You need to provide the qrCode value
    online: true, // Assuming online is always true
    category,
    department,
    designationId: designation_id,
    costCenter: `${costcenter}`, // You need to provide the costCenter value
    title,
    favouriteConsultant: favourite_consultant,
    firstName: firstname,
    lastName: lastname,
    gender,
    deleted: deleted ?? "false",
    qualification,
    contactNo: contact_no,
    email,
    registrationNo: registration_no,
    bloodGroupCode: blood_group_code,
    aadharNum: aadhar_num,
    panNum: aadhar_num, // You need to provide the panNum value
    photo,
    certificate,
    signature,
    address,
    organizationId: 7,
    password,
    confirmPassword: confirm_password,
    active,
    roles:
      selectedRoles !== undefined
        ? selectedRoles.map((role) => role.value)
        : "",

    slotSettings: slot_setting.map((setting, index) => {
      const { day, slotA, slotB, slotC, id, consultantId } = setting;
      const mapDay = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      };
      return {
        id,
        weekDay: mapDay[day],
        slotA: {
          from: slotA.from,
          to: slotA.to,
          totalSlots: slotA.totalSlots,
          dataKey: "",
        },
        slotB: {
          from: slotB.from,
          to: slotB.to,
          totalSlots: slotB.totalSlots,
          dataKey: "",
        },
        slotC: {
          from: slotC.from,
          to: slotC.to,
          totalSlots: slotC.totalSlots,
          dataKey: "",
        },
        consultantId,
      };
    }),
    ipVisitCharge: mapVisitChargesNew(ipvisitcharges, id),
    opVisitCharge: mapVisitChargesNew(opvisitcharges, id),
    id,
  };
};

const mapVisitChargesNew = (charges, id) => {
  return {
    id: id ?? 0,
    newPatient: mapChargeNew(charges[0]),
    revisit: mapChargeNew(charges[1]),
    encounter: mapChargeNew(charges[2]),
    consultantId: id ?? 0,
  };
};

const mapChargeNew = (charge) => {
  return {
    fee: charge.fee,
    validity: charge.validity,
    hospitalSharePercentage: charge.hospital_share_perc,
    hospitalShare: charge.hospital_share,
    consultantSharePercentage: charge.consultant_share_perc,
    consultantShare: charge.consultant_share,
    dataKey: "",
  };
};

const mapNewToOldBody = (newBody) => {
  const {
    slotDuration,
    slotUnit,
    sendSmsNotification,
    sendEmailNotification,
    opDiscountApplicable,
    ipDiscountApplicable,
    code,
    category,
    departmentId,
    designationId,
    costCenter,
    title,
    firstName,
    lastName,
    gender,
    qualification,
    contactNo,
    email,
    deleted,
    registrationNo,
    bloodGroupCode,
    aadharNum,
    panNum,
    photo,
    certificate,
    address,
    organizationId, // Assuming organizationId is present in the new body
    favouriteConsultant, // Assuming favouriteConsultant is present in the new body
    slotSetting,
    ipVisitCharge,
    opVisitCharge,
    id,
    signature,
    active,
  } = newBody;

  return {
    aadhar_num: aadharNum,
    active: true, // Assuming active is always true
    address,
    blood_group_code: bloodGroupCode,
    category,
    certificate,

    code,
    contact_no: contactNo,
    deleted,
    department: departmentId,
    designation_id: designationId,
    email,
    favourite_consultant: favouriteConsultant,
    firstname: firstName,

    gender,
    ip_discount_applicable: ipDiscountApplicable,
    ipvisitcharges: mapVisitChargesOld(ipVisitCharge),
    lastname: lastName,
    op_discount_applicable: opDiscountApplicable,
    opvisitcharges: mapVisitChargesOld(opVisitCharge),
    photo,
    qualification,
    registration_no: registrationNo,
    send_email_notification: sendEmailNotification,
    send_sms_notification: sendSmsNotification,
    signature,
    active,
    slot_duration: slotDuration,
    slot_unit: slotUnit,
    slot_setting: slotSetting.map((setting) => {
      const { weekDay, slotA, slotB, slotC, id, consultantId } = setting;
      const mapDay = {
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",
        7: "sunday",
      };
      return {
        id,
        day: mapDay[weekDay],
        slotA: {
          from: slotA.from,
          to: slotA.to,
          totalSlots: slotA.totalSlots,
        },
        slotB: {
          from: slotB.from,
          to: slotB.to,
          totalSlots: slotB.totalSlots,
        },
        slotC: {
          from: slotC.from,
          to: slotC.to,
          totalSlots: slotC.totalSlots,
        },
        consultantId,
      };
    }),
    costcenter: costCenter, // Assuming costCenter should be reverted back to costcenter
    organizationId, // Assuming organizationId remains unchanged

    title,
    id,
  };
};

const mapVisitChargesOld = (charges) => {
  return [
    mapChargeOld(charges["newPatient"], "First Visit"),
    mapChargeOld(charges["revisit"], "Revisit"),
    mapChargeOld(charges["encounter"], "Encounter"),
  ];
};

const mapChargeOld = (charge, displayName) => {
  return {
    display_visittype: displayName,
    fee: charge.fee,
    validity: charge.validity,
    hospital_share_perc: charge.hospitalSharePercentage,
    hospital_share: charge.hospitalShare,
    consultant_share_perc: charge.consultantSharePercentage,
    consultant_share: charge.consultantShare,
  };
};

function search(payload) {
  return (dispatch) => {
    dispatch(request());
    consultantService.search(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(data));
          dispatch(alertActions.success("Consultants loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: consultantConstants.SEARCH_ALL_CONSULTANT_REQUEST };
  }
  function success(data) {
    return { type: consultantConstants.SEARCH_ALL_CONSULTANT_SUCCESS, data };
  }
  function failure(error) {
    return { type: consultantConstants.SEARCH_ALL_CONSULTANT_FAILURE, error };
  }
}

function getAll(viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());
    consultantService.getAll(viewDeleted).then(
      (data) => {
        if (data.isSuccess) {
          const oldValue = data.value.map((consultant) => {
            return mapNewToOldBody(consultant);
          });

          dispatch(success(oldValue));
          dispatch(alertActions.success("Consultants loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: consultantConstants.GET_ALL_CONSULTANT_REQUEST };
  }
  function success(data) {
    return { type: consultantConstants.GET_ALL_CONSULTANT_SUCCESS, data };
  }
  function failure(error) {
    return { type: consultantConstants.GET_ALL_CONSULTANT_FAILURE, error };
  }
}

function selectConsultant(id) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(id));
    dispatch(alertActions.success("Consultant Selected"));
  };

  function request() {
    return { type: consultantConstants.SELECT_CONSULTANT_REQUEST };
  }
  function success(consultantId) {
    return {
      type: consultantConstants.SELECT_CONSULTANT_SUCCESS,
      consultantId,
    };
  }
  function failure(error) {
    return { type: consultantConstants.SELECT_CONSULTANT_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    try {
      return consultantService.add(mapOldToNewBody(payload)).then(
        (result) => {
          if (result.isSuccess) {
            const test = mapNewToOldBody(result.value);

            dispatch(success(test));
            // toast(result.message, {
            //   transition: Bounce,
            //   closeButton: true,
            //   autoClose: 1000,
            //   position: "bottom-center",
            //   type: "success",
            // });
            //window.location='/employees-registration/new-consultant-registration'
            return true;
          } else {
            toast("Invalid data check", {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "warning",
            });
            dispatch(failure("Error in saving data"));
            return false;
          }
        }
        // error => dispatch(failure(error.toString()))
      );
    } catch (e) {
      toast("Error occured in saving data" + JSON.stringify(e), {
        transition: Bounce,
        closeButton: true,
        autoClose: 1000,
        position: "bottom-center",
        type: "warning",
      });
      dispatch(failure("Error in saving data"));
      return false;
    }
  };

  function request() {
    return { type: consultantConstants.ADD_CONSULTANT_REQUEST };
  }
  function success(consultant) {
    return { type: consultantConstants.ADD_CONSULTANT_SUCCESS, consultant };
  }
  function failure(error) {
    return { type: consultantConstants.ADD_CONSULTANT_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());

    return consultantService
      .update(mapOldToNewBody(payload))
      .then(
        (result) => {
          if (result.isSuccess) {
            // dispatch(success(mapNewToOldBody(result.value)));
            dispatch(alertActions.success(result));
            toast("updated successfully", {
              transition: Bounce,
              closeButton: true,
              autoClose: 5000,
              position: "bottom-center",
              type: "success",
            });
            return true;
            //  window.location='/employees-registration/new-consultant-registration'
          } else {
            dispatch(failure(result.err));

            toast(result.err, {
              transition: Bounce,
              closeButton: true,
              autoClose: 5000,
              position: "bottom-center",
              type: "danger",
            });
            return false;
          }
        }
        // error => dispatch(failure(error.toString()))
      )
      .catch((e) => {
        toast("Invalid data " + JSON.stringify(e), {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "warning",
        });
        dispatch(failure("Error in updating data"));
        return false;
      });
  };

  function request() {
    return { type: consultantConstants.UPDATE_CONSULTANT_REQUEST };
  }
  function success(consultant) {
    return { type: consultantConstants.UPDATE_CONSULTANT_SUCCESS, consultant };
  }
  function failure(error) {
    return { type: consultantConstants.UPDATE_CONSULTANT_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    consultantService.remove(payload).then(
      (result) => {
        if (result) {
          dispatch(success(result));
          dispatch(alertActions.success(result));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else {
          dispatch(failure(result));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: consultantConstants.DELETE_CONSULTANT_REQUEST };
  }
  function success(consultant) {
    return { type: consultantConstants.DELETE_CONSULTANT_SUCCESS, consultant };
  }
  function failure(error) {
    return { type: consultantConstants.DELETE_CONSULTANT_FAILURE, error };
  }
}

function setDefault(payload) {
  return (dispatch) => {
    dispatch(request());

    consultantService.setDefault(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: consultantConstants.SET_DEFAULT_CONSULTANT_REQUEST };
  }
  function success(consultant) {
    return {
      type: consultantConstants.SET_DEFAULT_CONSULTANT_SUCCESS,
      consultant,
    };
  }
  function failure(error) {
    return { type: consultantConstants.SET_DEFAULT_CONSULTANT_FAILURE, error };
  }
}
