import { authHeader } from "../store";
import { storageService } from "./storage.service";
import { loginservice } from "./login.service";

import { apiUrl, USER_KEY, loginConstant } from "../constants";
import { toast } from "react-toastify";
export const helperService = { handleResponse, getHeaderData };

function logout() {
  // remove user from local storage to log user out
  //localStorage.removeItem(USER_KEY);
  // storageService.removeData(loginConstant.AUTHORIZATION_HEADER);

  loginservice
    .logout()
    .then((res) => {
      storageService.removeData("authorization");
      console.log("Logout Called");
      window.location.replace("./");
    })
    .catch((err) => {
      storageService.removeData("authorization");
      window.location.replace("./");
      toast(JSON.stringify(err))});
}

function refresh() {
  loginservice
    .refresh()
    .then((res) => {
      storageService.setData(loginConstant.AUTHORIZATION_HEADER, res.token);
    })
    .catch((err) => alert(JSON.stringify(err)));
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        storageService.removeData("authorization");
        window.location.replace("./");
      }

      if (response.status === 422) {
        refresh();
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return Promise.resolve(data);
  });
}
function getHeaderData() {
  let authHeader = storageService.getData(loginConstant.AUTHORIZATION_HEADER);
  return "Bearer " + authHeader;
}
