import {
    getInsuranceConstant
} from '../constants'


const getInsuranceReducer = (state = {}, action) =>{

       
      
       switch(action.type){
           case getInsuranceConstant.GETALL_INSURANCE_REQUEST :
               
               return {
                   ...state, loading :true ,
               };

           case getInsuranceConstant.GETALL_INSURANCE_SUCCESS :
               
                       
                       return{
                           
                           ...state, data :  action.data   ,
                       } 
                       
           case getInsuranceConstant.GETALL_INSURANCE_FAIL :
             
                   return {
                       ...state,
                       loading :false,
                       error : action.payload,
                   }            


                      

           default:
                 return state;          
       }

};

export {getInsuranceReducer};