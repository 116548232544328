import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Col,
  Card,
  CardBody,
  Button,
  Row,
  CardHeader,
  CardFooter,
} from "reactstrap";
import TextInput from "../MyComponents/TextInput";
import NumberInput from "../MyComponents/NumberInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { referralActions, sweetAlertActions } from "../../actions";

class ReferralMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      name: "",
      degree: "",
      mobile: "",
      email: "",
      address: "",
      active: false,
      selectedItem: props.location.state
        ? props.location.state.selectedItem
        : undefined,
      mode: props.location.state ? props.location.state.mode : "",
      error: "",
    };
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    mobile: Yup.string()
      .min(10, "Enter minimum 10 numbers")
      .required("This field is required"),
  });

  addMaster() {
    const { dispatch } = this.props;
    const { name, degree, email, mobile, address } = this.state;

    const data = {
      name: name,
      degree: degree,
      email: email,
      mobile: mobile,
      address: address,
      created_by: "Admin",
    };
    dispatch(referralActions.add(data));
    if (this.state.name !== "" && this.state.mobile !== "") {
      this.props.history.push({
        pathname: "/referrals/new-referral-registration",
      });
      this.clearBtn_Click();
    }
  }

  updateMaster() {
    const { dispatch, masters } = this.props;
    const { selectedItem, name, degree, email, mobile, address } = this.state;

    const data = {
      code: selectedItem.code,
      name: name,
      degree: degree,
      email: email,
      mobile: mobile,
      address: address,
      active: selectedItem.active,
      updated_by: "Admin",
    };
    dispatch(referralActions.update(data));
    if (this.state.name !== "" && this.state.mobile !== "") {
      this.props.history.push({
        pathname: "/referrals/new-referral-registration",
      });
      this.clearBtn_Click();
    }
  }

  clearBtn_Click() {
    this.setState({
      selectedItem: undefined,
      name: "",
      code: "",
      degree: "",
      email: "",
      mobile: "",
      address: "",
    });
  }

  componentDidMount() {
    const mode = this.state.mode;
    let editData = this.props.selected_referral;
    if (mode === "EDIT") {
      this.setState({
        name: editData.name,
        degree: editData.degree,
        email: editData.email,
        mobile: editData.mobile,
        address: editData.address,
      });
    }
  }

  componentDidUpdate(pp, ps) {
    console.log(pp, ps, "1111");
    if (pp.mode !== this.props.mode) {
      this.componentDidMount();
    }
  }

  onNameChange(e) {
    let pattern = /^[a-z\.\s]+$/i;
    const target = e.target;
    if (target.value === "" || pattern.test(target.value)) {
      this.setState({ [target.name]: target.value });
    }
  }

  onDegreeChange(e) {
    let pattern = /^[a-z\.,\s]+$/i;
    const target = e.target;
    if (target.value === "" || pattern.test(target.value)) {
      this.setState({ [target.name]: target.value });
    }
  }

  onAddressChange(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  onChange(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  render() {
    const { referrals } = this.props;
    return (
      <Fragment>
        <Formik
          initialValues={this.state}
          validationSchema={this.validationSchema}
          onSubmit={async (values, { setErrors }) => {
            const emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (this.state.email) {
              if (!emailRegExp.test(this.state.email)) {
                this.setState({
                  error: "Enter valid email address",
                });
                return;
              }
            }
            if (this.state.selectedItem === undefined) {
              this.addMaster();
            } else {
              this.updateMaster();
            }
          }}
          enableReinitialize
        >
          {(props) => {
            const { errors } = props;
            return (
              <Form>
                <Card>
                  <CardHeader>Referral Doctor </CardHeader>
                  <CardBody>
                    <Row form>
                      <Col md={6}>
                        <TextInput
                          label="Name"
                          name="name"
                          textOnly={true}
                          value={this.state.name}
                          required="true"
                          type="text"
                          placeholder="Enter Name"
                          onChangeHandler={(e) => this.onNameChange(e)}
                        />
                        {errors.name && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.name}
                          </div>
                        )}
                      </Col>
                      <Col md={6}>
                        <NumberInput
                          label="Mobile"
                          name="mobile"
                          required="true"
                          value={this.state.mobile}
                          maxLength={10}
                          placeholder=""
                          onChange={(e) => this.onChange(e)}
                        />
                        {errors.mobile && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.mobile}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row form>
                      <Col md={6}>
                        <TextInput
                          label="Degree"
                          name="degree"
                          textOnly={true}
                          value={this.state.degree}
                          type="text"
                          placeholder="Enter degree"
                          onChangeHandler={(e) => this.onDegreeChange(e)}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Email"
                          name="email"
                          value={this.state.email}
                          type="text"
                          placeholder="Enter email"
                          onChangeHandler={(e) => this.onChange(e)}
                        />
                        {this.state.error && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {this.state.error}
                          </div>
                        )}
                      </Col>

                      <Col md={12}>
                        <TextInput
                          label="Address"
                          name="address"
                          value={this.state.address}
                          type="text"
                          placeholder="Address"
                          onChangeHandler={(e) => this.onAddressChange(e)}
                        />
                      </Col>
                    </Row>
                    <br />

                    <Card></Card>
                  </CardBody>
                  <CardFooter>
                    <div>
                      {this.state.selectedItem === undefined
                        ? [
                            <Button
                              color="link"
                              onClick={() =>
                                this.props.history.push({
                                  pathname:
                                    "/referrals/new-referral-registration",
                                })
                              }
                            >
                              Cancel
                            </Button>,
                            <Button color="info" type="submit">
                              Save
                            </Button>,
                          ]
                        : [
                            <Button
                              color="warning"
                              type="submit"
                              className="mr-2"
                            >
                              Update
                            </Button>,

                            <Button
                              color="secondary"
                              onClick={() => {
                                this.clearBtn_Click();
                              }}
                            >
                              Clear
                            </Button>,
                          ]}
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { referrals } = state;
  return {
    referrals: referrals.referrals,
    selected_referral: referrals.selected_REFERRAL,
    error: referrals.error,
  };
}

const connectedReferralMaster = connect(mapStateToProps)(ReferralMaster);
export default connectedReferralMaster;
