import React, {Fragment} from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import {TPAMainView} from './ViewMaster'
import {TPAAddForm} from './AddTPA'
import { TPAConsultantMaster} from './TPAWiseConsultantionFees'
import TPAWiseserviceMaster from './TPAWiseServiceMaster'

const CorporateMaster = ({match}) => (
    <Fragment>
      

                    {/* Form Elements */}
<Switch>
                    <Route exact path={`${match.url}`} component={TPAMainView}/>
                   <Route exact path={`${match.url}/add`} component={TPAAddForm}/>
                   <Route exact path={`${match.url}/tpawiseconsultantmaster`} component={TPAConsultantMaster} />
                     <Route exact path = {`${match.url}/tpawiseservicemaster`} component={TPAWiseserviceMaster} />
                    {/*  <Route exact path={`${match.url}/generateQRCard`} component={GenerateQRCard} /> */}

                    {/*<Route path={`${match.url}/validation`} component={FormElementsValidation}/> */}
                    <Redirect from="*" to={match.url}></Redirect>
                    </Switch>
              
    </Fragment>
);

export default CorporateMaster;
