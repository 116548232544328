import React, { Component } from "react";

export default class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
    };
  }
  componentDidMount() {
    this.setState({ number: this.props.min });
  }
  textChanged(text) {
    const { min, max, maxLength, type } = this.props;
    // const num=text.target.value.replace(/[^0-9]/g, "");
    const num = text.target.value;

    if (min && max) {
      if (num <= max) {
        // this.setState({number:num})
        if (this.props.onChange)
          this.props.onChange({
            target: { name: this.props.name, value: num },
          });
      }
    }
    if (maxLength) {
      if (num.length <= maxLength) {
        // alert(num)
        //  this.setState({number:num})
        if (this.props.onChange)
          this.props.onChange({
            target: { name: this.props.name, value: num },
          });
      }
      //  else
      //  {
      //    if (this.props.onChange)
      //      this.props.onChange({target:{name:this.props.name,value: num}});
      //  }
    }
  }

  formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let isNumberOrDelete;

    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      isNumberOrDelete =
        (e.key >= "0" && e.key <= "9") || e.key === "Backspace";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      isNumberOrDelete =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    // Check if it's a number (0-9) or delete key (backspace)

    // If it's neither a number nor the delete key, prevent default behavior
    if (!isNumberOrDelete) {
      e.preventDefault();
    }
  };
  render() {
    const {
      key,
      label,
      placeholder,
      type,
      name,
      required,
      min,
      max,
      maxLength,
      value,
      invalid,
      onBlurEvent,
      readonly
    } = this.props;
    return (
      <div className="form-group">
        {label && (
          <label for={key}>
            {label} {required ? <a className="mandatory"> *</a> : ""}
          </label>
        )}
        <input
          type="number"
          style={invalid ? { border: "1px solid red" } : null}
          className="form-control form-control-sm"
          id={key}
          readOnly={readonly}
          placeholder={placeholder}
          value={this.props.value}
          name={name}
          onChange={(e) => {
            this.textChanged(e);
          }}
          onBlur={onBlurEvent ? onBlurEvent : undefined}
          onKeyDown={this.formatInput}
          invalid={this.props.invalid}
        />
      </div>
    );
  }
}
