import { referralConstants, referalConsultantConstants } from "../constants";
import { alertActions } from "./alert.actions";
import {
  ReferralsreferralService as referralService,
  referalConsultantService,
} from "../services";

import { toast, Bounce } from "react-toastify";

export const referralActions = {
  getAll,
  add,
  update,
  selectreferral,
  remove,
  addConsultantPayabal,
  getConsultantPayabal,
  deactiveServices,
  deleteServices,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    referralService.getAll().then(
      (data) => {
        if (data.value) {
          dispatch(success(data.value));
          dispatch(alertActions.success("referrals loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: referralConstants.GET_ALL_REFERRAL_REQUEST };
  }
  function success(referrals) {
    return { type: referralConstants.GET_ALL_REFERRAL_SUCCESS, referrals };
  }
  function failure(error) {
    return { type: referralConstants.GET_ALL_REFERRAL_FAILURE, error };
  }
}

function selectreferral(id) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(id));
    dispatch(alertActions.success("referral Selected"));
  };

  function request() {
    return { type: referralConstants.SELECT_REFERRAL_REQUEST };
  }
  function success(referralId) {
    return { type: referralConstants.SELECT_REFERRAL_SUCCESS, referralId };
  }
  function failure(error) {
    return { type: referralConstants.SELECT_REFERRAL_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    referralService.add(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));
          toast("added successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          toast("Invalid data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
        }
      },
      (error) => {
        toast(error, {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "error",
        });
        //dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: referralConstants.ADD_REFERRAL_REQUEST };
  }
  function success(referral) {
    return { type: referralConstants.ADD_REFERRAL_SUCCESS, referral };
  }
  function failure(error) {
    return { type: referralConstants.ADD_REFERRAL_FAILURE, error };
  }
}

function addConsultantPayabal(payload) {
  return (dispatch) => {
    dispatch(request());

    referalConsultantService.add(payload).then(
      (result) => {
        if (result.success === true) {
          dispatch(success(result.referral));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          toast("Invalid data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: referalConsultantConstants.ADD_REFERRAL_CONSULTANT_REQUEST };
  }
  function success(referral) {
    return {
      type: referalConsultantConstants.ADD_REFERRAL_CONSULTANT_SUCCESS,
      referral,
    };
  }
  function failure(error) {
    return {
      type: referalConsultantConstants.ADD_REFERRAL_CONSULTANT_FAILURE,
      error,
    };
  }
}

function getConsultantPayabal() {
  return (dispatch) => {
    dispatch(request());

    referalConsultantService.getAll().then(
      (result) => {
        if (result.success === true) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          toast("Invalid data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: referalConsultantConstants.GET_ALL_REFERRAL_DATA_REQUEST };
  }
  function success(data) {
    return {
      type: referalConsultantConstants.GET_ALL_REFERRAL_DATA_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: referalConsultantConstants.GET_ALL_REFERRAL_DATA_FAILURE,
      error,
    };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());

    referralService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.referral));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => {
        toast(error, {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: referralConstants.UPDATE_REFERRAL_REQUEST };
  }
  function success(referral) {
    return { type: referralConstants.UPDATE_REFERRAL_SUCCESS, referral };
  }
  function failure(error) {
    return { type: referralConstants.UPDATE_REFERRAL_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    referralService.remove(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: referralConstants.DELETE_REFERRAL_REQUEST };
  }
  function success(referral) {
    return { type: referralConstants.DELETE_REFERRAL_SUCCESS, referral };
  }
  function failure(error) {
    return { type: referralConstants.DELETE_REFERRAL_FAILURE, error };
  }
}

function deactiveServices(payload) {
  return (dispatch) => {
    dispatch(request());

    referralService.deactiveReferral(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: referralConstants.DEACTIVE_REFERRAL_REQUEST };
  }
  function success(referral) {
    return { type: referralConstants.DEACTIVE_REFERRAL_SUCCESS, referral };
  }
  function failure(error) {
    return { type: referralConstants.DEACTIVE_REFERRAL_FAILURE, error };
  }
}

function deleteServices(payload) {
  return (dispatch) => {
    dispatch(request());

    referralService.deleteReferral(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: referralConstants.DELETE_REFERRAL_REQUEST };
  }
  function success(referral) {
    return { type: referralConstants.DELETE_REFERRAL_SUCCESS, referral };
  }
  function failure(error) {
    return { type: referralConstants.DELETE_REFERRAL_FAILURE, error };
  }
}
