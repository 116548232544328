export const opBillingConstants = {

    GET_ALL_OPD_BILLING_BILL_REQUEST : "GET_ALL_OPD_BILLING_BILL_REQUEST",
    GET_ALL_OPD_BILLING_BILL_SUCCESS : "GET_ALL_OPD_BILLING_BILL_SUCCESS",
    GET_ALL_OPD_BILLING_BILL_FAILURE : "GET_ALL_OPD_BILLING_BILL_FAILURE",

    GET_ALL_OPD_BILLING_HOLD_REQUEST : "GET_ALL_OPD_BILLING_HOLD_REQUEST",
    GET_ALL_OPD_BILLING_HOLD_SUCCESS : "GET_ALL_OPD_BILLING_HOLD_SUCCESS",
    GET_ALL_OPD_BILLING_HOLD_FAILURE : "GET_ALL_OPD_BILLING_HOLD_FAILURE",

    GET_OPD_BILLBYUHID_REQUEST : "GET_OPD_BILLBYUHID_REQUEST",
    GET_OPD_BILLBYUHID_SUCCESS : "GET_OPD_BILLBYUHID_SUCCESS",
    GET_OPD_BILLBYUHID_FAILURE : "GET_OPD_BILLBYUHID_FAILURE",

    GET_OPD_BILLBYBILLNO_REQUEST : "GET_OPD_BILLBYBILLNO_REQUEST",
    GET_OPD_BILLBYBILLNO_SUCCESS : "GET_OPD_BILLBYBILLNO_SUCCESS",
    GET_OPD_BILLBYBILLNO_FAILURE : "GET_OPD_BILLBYBILLNO_FAILURE",

    GET_OPD_BILLBYDATERANGE_REQUEST : "GET_OPD_BILLBYDATERANGE_REQUEST",
    GET_OPD_BILLBYDATERANGE_SUCCESS : "GET_OPD_BILLBYDATERANGE_SUCCESS",
    GET_OPD_BILLBYDATERANGE_FAILURE : "GET_OPD_BILLBYDATERANGE_FAILURE",

    GET_OPD_BILL_RECEIVABLE_DATERANGE_REQUEST : "GET_OPD_BILL_RECEIVABLE_DATERANGE_REQUEST",
    GET_OPD_BILL_RECEIVABLE_DATERANGE_SUCCESS : "GET_OPD_BILL_RECEIVABLE_DATERANGE_SUCCESS",
    GET_OPD_BILL_RECEIVABLE_DATERANGE_FAILURE : "GET_OPD_BILL_RECEIVABLE_DATERANGE_FAILURE",
    
    GET_OPD_BILLCALCELLED_REQUEST : "GET_OPD_BILLCALCELLED_REQUEST",
    GET_OPD_BILLCALCELLED_SUCCESS : "GET_OPD_BILLCALCELLED_SUCCESS",
    GET_OPD_BILLCALCELLED_FAILURE : "GET_OPD_BILLCALCELLED_FAILURE",

    GET_OPD_BILLREFUNDED_REQUEST: "GET_OPD_BILLREFUNDED_REQUEST",
    GET_OPD_BILLREFUNDED_SUCCESS : "GET_OPD_BILLREFUNDED_SUCCESS",
    GET_OPD_BILLREFUNDED_FAILURE : "GET_OPD_BILLREFUNDED_FAILURE",

    GET_OPD_BILLRECEIVABLE_REQUEST: "GET_OPD_BILLRECEIVABLE_REQUEST",
    GET_OPD_BILLRECEIVABLE_SUCCESS : "GET_OPD_BILLRECEIVABLE_SUCCESS",
    GET_OPD_BILLRECEIVABLE_FAILURE : "GET_OPD_BILLRECEIVABLE_FAILURE",

    ADD_OPD_BILLING_REQUEST : "ADD_OPD_BILLING_REQUEST",
    ADD_OPD_BILLING_SUCCESS : "ADD_OPD_BILLING_SUCCESS",
    ADD_OPD_BILLING_FAILURE : "ADD_OPD_BILLING_FAILURE",


    
    GET_OPD_BILLING_QUEUE_REQUEST : "GET_OPD_BILLING_QUEUE_REQUEST",
    GET_OPD_BILLING_QUEUE_SUCCESS : "GET_OPD_BILLING_QUEUE_SUCCESS",
    GET_OPD_BILLING_QUEUE_FAILURE : "GET_OPD_BILLING_QUEUE_FAILURE",

    ADD_OPD_BILLING_HOLD_REQUEST : "ADD_OPD_BILLING_HOLD_REQUEST",
    ADD_OPD_BILLING_HOLD_SUCCESS : "ADD_OPD_BILLING_HOLD_SUCCESS",
    ADD_OPD_BILLING_HOLD_FAILURE : "ADD_OPD_BILLING_HOLD_FAILURE",

    CLEAR_OPD_BILLING_HOLD_REQUEST : "CLEAR_OPD_BILLING_HOLD_REQUEST",
    CLEAR_OPD_BILLING_HOLD_SUCCESS : "CLEAR_OPD_BILLING_HOLD_SUCCESS",
    CLEAR_OPD_BILLING_HOLD_FAILURE : "CLEAR_OPD_BILLING_HOLD_FAILURE",

    ADD_OPD_BILLING_ADVANCE_REQUEST : "ADD_OPD_BILLING_ADVANCE_REQUEST",
    ADD_OPD_BILLING_ADVANCE_SUCCESS : "ADD_OPD_BILLING_ADVANCE_SUCCESS",
    ADD_OPD_BILLING_ADVANCE_FAILURE : "ADD_OPD_BILLING_ADVANCE_FAILURE",
    
    CANCEL_BILL_BYBILLNO_REQUEST : "CANCEL_BILL_BYBILLNO_REQUEST",
    CANCEL_BILL_BYBILLNO_SUCCESS : "CANCEL_BILL_BYBILLNO_SUCCESS",
    CANCEL_BILL_BYBILLNO_FAILURE : "CANCEL_BILL_BYBILLNO_FAILURE",

    SELECT_BILL_REQUEST : "SELECT_BILL_REQUEST",
    SELECT_BILL_SUCCESS : "SELECT_BILL_SUCCESS",
    SELECT_BILL_FAILURE : "SELECT_BILL_FAILURE",
    
    GET_OPD_RECEIVABLEBYUHID_REQUEST : "GET_OPD_RECEIVABLEBYUHID_REQUEST",
    GET_OPD_RECEIVABLEBYUHID_SUCCESS : "GET_OPD_RECEIVABLEBYUHID_SUCCESS",
    GET_OPD_RECEIVABLEBYUHID_FAILURE : "GET_OPD_RECEIVABLEBYUHID_FAILURE",

    GET_OPD_BILL_SEARCH_RESULT_REQUEST: "GET_OPD_BILL_SEARCH_RESULT_REQUEST",
    GET_OPD_BILL_SEARCH_RESULT_SUCCESS: "GET_OPD_BILL_SEARCH_RESULT_SUCCESS",
    GET_OPD_BILL_SEARCH_RESULT_FAILURE: "GET_OPD_BILL_SEARCH_RESULT_FAILURE",

    GET_OPD_BILL_RECEIVABLE_SEARCH_REQUEST: "GET_OPD_BILL_RECEIVABLE_SEARCH_REQUEST",
    GET_OPD_BILL_RECEIVABLE_SEARCH_SUCCESS: "GET_OPD_BILL_RECEIVABLE_SEARCH_SUCCESS",
    GET_OPD_BILL_RECEIVABLE_SEARCH_FAILURE: "GET_OPD_BILL_RECEIVABLE_SEARCH_FAILURE",

    REFUND_OPD_BILLBYBILLNO_REQUEST : "REFUND_OPD_BILLBYBILLNO_REQUEST",
    REFUND_OPD_BILLBYBILLNO_SUCCESS : "REFUND_OPD_BILLBYBILLNO_SUCCESS",
    REFUND_OPD_BILLBYBILLNO_FAILURE : "REFUND_OPD_BILLBYBILLNO_FAILURE",

    PARTIALPAYMENT_OPD_BILLBYBILLNO_REQUEST : "PARTIALPAYMENT_OPD_BILLBYBILLNO_REQUEST",
    PARTIALPAYMENT_OPD_BILLBYBILLNO_SUCCESS : "PARTIALPAYMENT_OPD_BILLBYBILLNO_SUCCESS",
    PARTIALPAYMENT_OPD_BILLBYBILLNO_FAILURE : "PARTIALPAYMENT_OPD_BILLBYBILLNO_FAILURE",
   
    CLAER_BILL : "CLEAR_BILL"

}