//http://localhost:2020/aqua/form/getFormDetailById/5e500a9afdf8192f6ca8b567

//import { authHeader } from '../store';
import {helperService} from "./helper.service"
//import {apiUrl} from "../constants"

const apiUrl='http://localhost:2020/aqua'

export const formService = {      
    getById,
    getAll,
    getForm
   };


   function getForm() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/forms`, requestOptions).then(helperService.handleResponse);
}

function getById(formId) {
    const requestOptions = {
        method: 'GET',
       headers:{ "authorization" : helperService.getHeaderData()}
    };
    return fetch('http://localhost:4000/forms', requestOptions).then(helperService.handleResponse);
   // return fetch(`${apiUrl}/form/getFormDetailById/${formId}`, requestOptions).then(helperService.handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/form/getAllForms`, requestOptions).then(helperService.handleResponse);
}
