import React, { Component, Fragment } from "react";
// import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Table,
  Card,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
  Modal,
  CustomInput,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import {
  departmentActions,
} from "../../actions";
import { toast, Bounce } from "react-toastify";
import { httpHelperService } from "../../services/http.helper.service";
import _ from "lodash";
import PatientGeneralInfo from "../MyComponents/patientipdgeneralcomponent";
import Select, { components } from "react-select";
import { registrationWithFeesService } from "../../services";
import { loginservice } from "../../services/login.service";
import { referalConsultantService } from "../../services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icons } from "../../helpers/IconHelper";

class ProcessPos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Add",
      errormessage: "",
      visible: false,
      items: [],
      graceHour: [],
      transfer_history: [],
      max_date: moment(new Date()).format("YYYY-MM-DD"),
      from_date: moment(new Date()).format("YYYY-MM-DD"),
      from_time: moment(new Date()).format("HH:mm"),
      modal: false,
      packageAmtIncrease: {},
      amountCalculation: "increaseBy%",
      filterData: "all",
      deleteModal: false,
      isDeleteAuthorized: false,
      isAuthorized: false,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.packageAmtIncrease = this.packageAmtIncrease.bind(this);
    this.packageServiceIncrease = this.packageServiceIncrease.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  NoOptionsMessage(props) {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Select option here</span>
      </components.NoOptionsMessage>
    );
  }

  onSubmitForm = () => {
    let { dispatch, patient } = this.props;

    const items = this.state.items;

    delete items[0];

    const itemData = items.filter((value) => value != null);

    console.log(itemData, "itemData");

    let payload = {
      patient_ipd_mrn_no: patient.patient_ipd_mrn_no,
      patient_uhid: patient.patient_uhid,
      patient_id: patient._id,
      patient_ipd_mrn_no: patient.patient_ipd_mrn_no,
      ipd_admission_no: this.state.subCategoryList.ipd_admission_no,
      pos_service_list: itemData,
    };

    // if (this.state._id !== "") {
    //   payload._id = this.state._id;
    //   payload.ipd_admission_no = this.state.ipd_admission_no;
    // }

    if (!this.state._id) {
      let url = "api/pos/addPosServices";
      httpHelperService
        .postRequest(payload, url)
        .then((res) => {
          if (res.success) {
            this.props.history.push({
              pathname: `/in-patient-department/pos/pos`,
            });
            toast(res.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let url = `api/pos/updatePosServices/${this.state._id}`;
      httpHelperService
        .updateRequest(payload, url)
        .then((res) => {
          if (res.success) {
            this.props.history.push({
              pathname: `/in-patient-department/pos/pos`,
            });
            toast(res.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onChange(e) {
    if (e.target.value === "all") {
      this.setState({ searchValue: "" });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onconsultantChange(option, row, key) {
    const { registrationConsWithFeesMaster, consultants } = this.props;
    const {
      subCategoryList,
      subCategoryData,
      consultantserviceList,
      consultantpackageList,
      consultantgroupList,
    } = this.state;

    let list = [...this.state.items];
    let item = list[key];

    item["consultant"] = option.value;
    item.consultant_share = 0;
    let consultantShare = "";

    let consultshare = consultants
      .find((value) => value.code === option.value)
      ?.ipvisitcharges?.filter(
        (value) =>
          value?.display_visittype
            .replace(/ /g, "")
            .toLowerCase()
            .includes("firstvisit") ||
          value?.display_visittype
            .replace(/ /g, "")
            .toLowerCase()
            .includes("newpatient")
      )[0];

    if (row.type === "Packages") {
      consultantShare = consultantpackageList.find(
        (value) => value.CODE === row.CODE
      );
      item.consultant_share =
        (consultantShare &&
          consultantShare[`${option.label}_${option.value}_amount`]) ||
        0;
    } else {
      consultantShare = consultantserviceList.find(
        (value) => value.CODE === row.service_code
      );
      item.consultant_share =
        (consultantShare &&
          consultantShare[`${option.label}_${option.value}_amount`]) ||
        0;
    }

    if (item.consultant_share > item.pos_amount) {
      item.consultant_share = item.pos_amount;
    }

    if (
      row.selected_name
        .replace(/ /g, "")
        .toLowerCase()
        .includes("ipvisit")
    ) {
      const data = registrationConsWithFeesMaster.find(
        (item) => item.rtcf_rtm_code === subCategoryData?.ward_catagory
      );
      const filterData = data?.rtwp_sm_code?.find(
        (value) => value.code === option.value
      );

      let posValueAmount = 0;

      if (filterData) {
        posValueAmount =
          filterData[
            `${subCategoryData?.ward_sub_catagory}_${subCategoryData?.code}_amount`
          ] || 0;
      } else {
        posValueAmount = consultshare?.fee || 0;
      }

      item.pos_amount = posValueAmount;
      item.pos_total_amount = item.pos_qty * posValueAmount || 0;

      item.consultant_share =
        posValueAmount *
          (parseFloat(consultshare?.consultant_share_perc || 0) / 100) || 0;
    }
    this.setState({
      items: list,
      consultPercentage:
        parseFloat(consultshare?.consultant_share_perc || 0) / 100,
    });
  }

  handleClick(key) {
    let list = [...this.state.items];
    let item = list[key];
    if (item.isActive) {
      item.isActive = false;
    } else {
      item.isActive = true;
    }

    this.setState({
      items: list,
    });
  }

  componentDidMount() {
    let {
      wardsubcatagory,
      serviceMaster,
      patient,
      dispatch,
      wardcatagoryfees,
      consultants,
      wardCatagorys,
    } = this.props;
    dispatch(departmentActions.getAll());

    if (!this.props.location.state) {
      this.props.history.push({
        pathname: `/in-patient-department/pos/pos`,
      });
    }

    if (this.props.location && this.props.location.state) {
      const { mode, data } = this.props.location.state;

      if (mode === "NEW") {
        if (data) {
          console.log(data, "patientdatalist");

          const serviceData = serviceMaster.data.services.map((items, key) => {
            return {
              key: key,
              value: items.CODE,
              label: items.SERVICE_NAME,
              type: "Service",
            };
          });

          const packageData = serviceMaster.data.packages.map((items, key) => {
            return {
              key: key,
              value: items.CODE,
              label: items.PACKAGE_NAME,
              type: "Package",
            };
          });

          const groupData = serviceMaster.data.groups.map((items, key) => {
            return {
              key: key,
              value: items.CODE,
              label: items.GROUP_NAME,
              type: "Group",
            };
          });

          const consultantList = consultants
            .filter((value) => value.active && !value.deleted)
            .map((items, key) => {
              return {
                key: key,
                value: items.code,
                label:
                  // "Dr. " +
                  // + this.codeToValue(
                  //   items.title,
                  //   "titles"
                  // )
                  // " " +
                  items.firstname + " " + items.lastname,
                type: "Group",
              };
            });

          const subCategoryData = wardsubcatagory.find(
            (list1) => list1.code === data?.wsc_code
          );

          this.getWardServiceCharges(subCategoryData);
          this.consultantShareMaster(data?.wsc_code);

          let posData = [];
          let pos_id = "";

          let url = `api/pos/getPosServices?ipd_admission_no=${data?.ipd_admission_no}`;
          httpHelperService
            .getRequest(url)
            .then((res) => {
              if (res.success) {
                posData = res?.data?.pos_service_list || [];
                pos_id = res?.data?._id?._id;
                this.setState({
                  _id: res?.data?._id?._id,
                });

                toast(res.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 1000,
                  position: "bottom-center",
                  type: "success",
                });
              }

              console.log(posData, "posdata");

              const resultData = {
                sno: (posData?.length > 0 ? posData.length : 0) + 2,
                service_code: "",
                package_code: "value",
                wsc_code: data.wsc_code,
                pos_amount: 0,
                pos_total_amount: 0,
                pos_qty: 0,
                pos_date: new Date(),
                service_edit: false,
                pos_qty_edit: false,
                pos_amount_edit: false,
                tax_edit: false,
                dept_code: "",
                user: data.created_by,
                tax_amount: 0,
                tax_percentage: 0,
                consultant: "",
                consultant_share: 0,
                consultant_edit: true,
                discount_applicable: false,
                deleteIcon: false,
              };

              let registrationFeeData = {};
              wardcatagoryfees.find((value) => {
                if (value.ward_catagory === subCategoryData?.ward_catagory) {
                  const serviceName = serviceMaster.data.services.find(
                    (list) => list.D_CODE.toLowerCase() === "regs"
                  );
                  registrationFeeData = {
                    sno: (posData?.length > 0 ? posData.length : 0) + 1,
                    service_code: "abc",
                    selected_name:
                      serviceName?.SERVICE_NAME || "IP Registration Service",
                    package_code: "abc",
                    wsc_code: data.wsc_code,
                    pos_amount: value.registration_fees || 0,
                    amount: value.registration_fees || 0,
                    pos_total_amount: value.registration_fees || 0,
                    pos_qty: 1,
                    pos_date: new Date(),
                    service_edit: true,
                    pos_qty_edit: true,
                    pos_amount_edit: true,
                    tax_edit: true,
                    dept_code: "",
                    user: data.created_by,
                    tax_amount: 0,
                    tax_percentage: 0,
                    consultant: 0,
                    consultant_share: 0,
                    consultant_edit: true,
                    dept_code: serviceName?.D_CODE || "Registration",
                    discount_applicable: true,
                    deleteIcon: true,
                  };
                }
              });

              let posDataService = [];
              if (posData?.length > 0) {
                let deletedData = posData.filter((value) => value.is_deleted);
                let non_DeletedData = posData.filter(
                  (value) => !value.is_deleted
                );
                non_DeletedData.push(...deletedData);

                posDataService = [resultData, ...non_DeletedData];
              } else if (Object.keys(registrationFeeData)?.length > 0) {
                posDataService = [resultData, registrationFeeData];
              } else {
                posDataService = [resultData];
              }

              this.setState({
                items: posDataService,
                selectedList: [...serviceData, ...packageData, ...groupData],
                subCategoryList: data,
                packageMapping: serviceMaster.data.mappingData,
                serviceDataList: serviceData,
                consultantList: consultantList,
                subCategoryData: subCategoryData,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  onChangeText(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  codeToValue(code, name) {
    try {
      if (this.props.masters.data) {
        return this.props.masters.data[name].list.find(
          (item) => item.code === code
        ).name;
      } else {
        return code;
      }
    } catch (err) {
      return code;
    }
  }

  onLogin() {
    const {
      username,
      password,
      reason,
      mode,
      selectdata,
      indexValue,
    } = this.state;

    const { dispatch } = this.props;
    const payload = {
      username,
      password,
      reason,
    };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          this.addPackageService(selectdata, indexValue);
          this.togglePopup();
          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  getWardServiceCharges(payload) {
    registrationWithFeesService
      .getService({ rtwp_rtm_code: payload?.ward_catagory })
      .then((data) => {
        if (data.success) {
          this.setState({
            serviceList: data.services,
            packageList: data.packages,
            groupList: data.groups,
          });
        }
      });
  }

  consultantShareMaster(code) {
    referalConsultantService
      .getById({
        ward_sub_catagory: code,
      })
      .then((data) => {
        this.setState({
          consultantserviceList: data.services,
          consultantpackageList: data.packages,
          consultantgroupList: data.groups,
        });
      });
  }

  serviceHandler(option, i) {
    const {
      items,
      serviceList,
      packageList,
      groupList,
      subCategoryList,
      packageMapping,
    } = this.state;
    const { patient } = this.props;
    let serviceFilter;
    let selectedName;
    let serviceAmount;
    let taxAmount;
    let totalAmount;
    let deptCode;

    if (option.type === "Service") {
      // if(option.value === 212){
      //   selectedName = option.label;
      // } else {
      serviceFilter = serviceList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.SERVICE_NAME === option?.label;
      });
      selectedName = serviceFilter?.SERVICE_NAME;
      // }
    } else if (option.type === "Package") {
      serviceFilter = packageList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.PACKAGE_NAME === option?.label;
      });
      selectedName = serviceFilter?.PACKAGE_NAME;
    } else if (option.type === "Group") {
      serviceFilter = groupList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.GROUP_NAME === option?.label;
      });
      selectedName = serviceFilter?.GROUP_NAME;
    }

    if (option.type === "Package") {
      const packageFilter = packageMapping?.find((list) => {
        return list.PKM_CODE === option.value;
      });

      const packageMapFilter = {
        sno: items.length + packageFilter?.SM_CODE?.length,
        service_code: option?.value || option?.label,
        package_code: option?.value || option?.label,
        selected_name: selectedName || option?.label,
        package_service_name: "",
        package_service_amount: packageFilter?.PACKAGE_SERVICE_AMT || 0,
        package_amount_edit: false,
        service_edit: false,
        pos_amount: packageFilter?.PACKAGE_SERVICE_AMT || 0,
        pos_qty: 1,
        addButton: true,
        pos_qty_edit: serviceFilter?.IP_QTY_EDIT,
        pos_amount_edit: serviceFilter?.EDIT_SERVICE_AMT_IP,
        tax_percentage: serviceFilter?.TAX_AMOUNT_IP,
        tax_amount:
          (packageFilter?.PACKAGE_SERVICE_AMT * serviceFilter?.TAX_AMOUNT_IP) /
          100,
        pos_total_amount: 1 * (packageFilter?.PACKAGE_SERVICE_AMT || 0),
        dept_code: serviceFilter?.D_CODE,
        user: subCategoryList?.created_by,
        pos_date: new Date(),
        wsc_code: subCategoryList?.wsc_code,
        type: "Packages",
        sm_code: packageFilter?.SM_CODE,
        disabled: !serviceFilter?.EDIT_SERVICE_AMT_IP,
        amount: packageFilter?.PACKAGE_SERVICE_AM || 0,
        is_package: true,
        consultant: "",
        consultant_share: 0,
        discount_applicable: serviceFilter?.DISCOUNT_IP_APP,
        package_id: serviceFilter?._id,
        pos_service_type: serviceFilter?.D_CODE.toLowerCase(),
        dept_code: serviceFilter?.D_CODE,
        deleteIcon: true,
      };

      const packageServiceFilter = packageFilter?.SM_CODE?.map(
        (value, index) => {
          const serviceAmount =
            value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT || 0;

          return {
            sno: items.length - 1 + (packageFilter?.SM_CODE?.length - index),
            service_code: option?.value || option?.label,
            package_code: option?.value || option?.label,
            selected_name: "",
            package_service_name: value?.SERVICE_NAME,
            package_service_amount: serviceAmount,
            package_amount_edit: value?.EDIT_SERVICE_AMT_IP,
            service_edit: false,
            pos_amount: null,
            amount: serviceAmount,
            pos_qty: null,
            pos_qty_edit: value?.IP_QTY_EDIT,
            pos_amount_edit: false,
            // tax_percentage: value?.TAX_AMOUNT_IP,
            // tax_amount: (serviceAmount * value?.TAX_AMOUNT_IP) / 100,
            // pos_total_amount:
            //   1 * (serviceAmount + serviceAmount * value?.TAX_AMOUNT_IP),
            tax_percentage: null,
            tax_amount: null,
            pos_total_amount: null,
            tax_edit: value?.TAX_APPLICABLE_IN_IP,
            dept_code: value?.D_CODE,
            user: subCategoryList?.created_by,
            pos_date: new Date(),
            wsc_code: subCategoryList?.wsc_code,
            // type: "Packages",
            sm_code: packageFilter?.SM_CODE,
            disabled: true,
            consultant: "",
            consultant_share: 0,
            package_id: serviceFilter?._id,
            service_id: value?._id,
            deleteIcon: true,
            type: option?.type,
            // discount_applicable : value?.DISCOUNT_IP_APP
          };
        }
      );
      items.splice(0, 1, packageMapFilter, ...packageServiceFilter);
    } else {
      if (
        option.label
          .replace(/ /g, "")
          .toLowerCase()
          .includes("ipvisit")
      ) {
        serviceAmount = 0;
        taxAmount = 0;
        totalAmount = 0;
        deptCode = serviceFilter?.D_CODE;
      } else {
        serviceAmount =
          serviceFilter[
            `${subCategoryList?.ward_sub_catagory}_${subCategoryList?.code}_amount`
          ] ||
          serviceFilter?.newAmount ||
          serviceFilter?.SERVICE_AMT ||
          serviceFilter?.GROUP_AMT ||
          0;
        taxAmount = (serviceAmount * serviceFilter?.TAX_AMOUNT_IP) / 100;
        totalAmount = 1 * serviceAmount;
        deptCode = serviceFilter?.D_CODE;
      }
      const item = items[i];
      item.service_code = option?.value || option?.label;
      item.package_code = option?.value || option?.label;
      item.selected_name = selectedName || option?.label;
      item.package_service_name = "";
      item.service_edit = false;
      item.pos_amount = serviceAmount;
      item.amount = serviceAmount;
      item.pos_date = new Date();
      item.pos_qty = 1;
      item.pos_qty_edit = serviceFilter?.IP_QTY_EDIT;
      item.pos_amount_edit = serviceFilter?.EDIT_SERVICE_AMT_IP;
      item.tax_percentage = serviceFilter?.TAX_AMOUNT_IP || 0;
      item.tax_amount = taxAmount;
      item.pos_total_amount = totalAmount;
      item.tax_edit = serviceFilter?.TAX_APPLICABLE_IN_IP;
      item.pos_service_type = deptCode.toLowerCase();
      item.dept_code = deptCode;
      item.user = subCategoryList?.created_by;
      item.wsc_code = subCategoryList?.wsc_code;
      item.disabled = !serviceFilter?.EDIT_SERVICE_AMT_IP;
      item.consultant = "";
      item.consultant_share = 0;
      item.discount_applicable = serviceFilter?.DISCOUNT_IP_APP;
      item.service_id = serviceFilter?._id;
      item.deleteIcon = true;
      item.type = option?.type;
    }

    const resultData = {
      sno: items.length + 1,
      service_code: null,
      package_code: "value",
      wsc_code: subCategoryList?.wsc_code,
      pos_amount: 0,
      amount: 0,
      pos_total_amount: 0,
      pos_qty: 0,
      pos_date: new Date(),
      service_edit: false,
      pos_qty_edit: false,
      pos_amount_edit: false,
      tax_edit: false,
      dept_code: "",
      user: subCategoryList?.created_by,
      tax_amount: 0,
      tax_percentage: 0,
      disabled: false,
      consultant: "",
      consultant_share: 0,
      consultant_edit: true,
      discount_applicable: false,
      deleteIcon: false,
    };

    this.setState({
      items: [resultData, ...items],
    });
  }

  changeHandler(e, item, i) {
    const { items, consultPercentage } = this.state;

    const list = [...items];

    this.setState({
      packageAmtIncrease: {
        increasedAmount: parseInt(e.target.value),
        package: item,
        amount: list[i].pos_amount || 0,
        index: i,
      },
    });

    item[e.target.name] = e.target.value;

    if (e.target.name === "pos_qty") {
      item.pos_total_amount = e.target.value * (item.pos_amount || 0);
    }

    if (e.target.name === "pos_amount") {
      item.pos_total_amount = item.pos_qty * e.target.value;

      if (
        item.selected_name
          .replace(/ /g, "")
          .toLowerCase()
          .includes("ipvisit")
      ) {
        item.consultant_share = e.target.value * consultPercentage || 0;
      }
    }

    // if (e.target.name === "tax_percentage") {
    //   item.tax_amount = (e.target.value * item.pos_amount) / 100;
    //   item.pos_total_amount =
    //     item.pos_qty * (item.pos_amount + e.target.value * item.pos_amount);
    // }

    // if (e.target.name === "tax_amount") {
    //   item.tax_percentage = (e.target.value / item.pos_amount) * 100;
    //   item.pos_total_amount =
    //     item.pos_qty * (item.pos_amount + e.target.value * item.pos_amount);
    // }

    list[i] = item;

    this.setState({
      items: list,
    });
  }

  dateHandler(date, item, i) {
    const { items } = this.state;

    const list = [...items];

    item.pos_date = date;

    list[i] = item;

    this.setState({
      items: list,
    });
  }

  packageHandler(e, row, index) {
    const { items, packageMapping } = this.state;

    const list = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    list[index][e.target.name] = valueAmount;
    list[index].pos_total_amount = list[index]?.pos_qty * valueAmount;

    const percentage =
      (valueAmount - row?.package_service_amount) / row?.package_service_amount;

    for (let i = 1; i <= row?.sm_code?.length; i++) {
      const amount = parseInt(
        (list[index + i].amount + list[index + i].amount * percentage).toFixed(
          0
        )
      );
      list[index + i].package_service_amount = amount;
    }
    this.setState({
      items: list,
    });
  }

  consultant_share_amount(e, item, i) {
    const { items } = this.state;

    const list = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    if (valueAmount > item.pos_amount) {
      item[e.target.name] = item.pos_amount;
    } else {
      item[e.target.name] = valueAmount;
    }

    list[i] = item;

    this.setState({
      items: list,
    });
  }

  wardSubCatagoryCodeToValue(code) {
    let { wardsubcatagory } = this.props;
    try {
      let name = wardsubcatagory.find((data) => data.code === code)
        .ward_sub_catagory;
      return name;
    } catch {
      return code;
    }
  }

  togglePopup(data, i) {
    if (data || i) {
      this.setState({
        selectdata: data,
        indexValue: i,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleDelete(data, i) {
    if (data || i) {
      this.setState({
        deletedata: data,
        deleteIndex: i,
      });
    }
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }

  onDeleteLogin(data, i) {
    const { username, password, reason, deletedata, deleteIndex } = this.state;

    const { dispatch } = this.props;
    const payload = { username, password, reason, login_type: "consultant" };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          if (this.state.isDeleteAuthorized) {
            this.deletePos(data, i);
          } else {
            this.deletePos(deletedata, deleteIndex);
            this.setState({ isDeleteAuthorized: true });
            this.toggleDelete();
          }
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  packageAmtIncrease(e, row, i) {
    const { items, packageMapping, packageAmtIncrease } = this.state;

    const list = [...items];
    let findIndex;

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;
    list[i][e.target.name] = valueAmount;
    // list[i].amount = valueAmount;

    const length = row.sm_code?.length;
    findIndex = row.sm_code.findIndex(
      (value) =>
        value?.SERVICE_NAME === row.package_service_name &&
        (value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT) ===
          row?.amount
    );

    if (findIndex === -1) {
      findIndex = row.sm_code.findIndex(
        (value) =>
          value?.package_service_name === row.package_service_name &&
          value?.amount === row?.amount
      );
    }

    let indexValue = i - findIndex;
    let packageAmount = 0;

    for (let i = 0; i < length; i++) {
      packageAmount += parseFloat(
        list[indexValue + i].package_service_amount || 0
      );
    }

    list[i - findIndex - 1].pos_amount = packageAmount;
    list[i - findIndex - 1].pos_total_amount =
      list[i - findIndex - 1].pos_qty * packageAmount;
    this.setState({
      items: list,
    });
  }

  packageServiceIncrease() {
    const { items, packageMapping, packageAmtIncrease } = this.state;

    const list = [...items];

    const length = packageAmtIncrease?.package?.sm_code?.length;
    const findIndex = packageAmtIncrease?.package?.sm_code.findIndex(
      (value) =>
        value?.SERVICE_NAME ===
          packageAmtIncrease?.package?.package_service_name &&
        (value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT) ===
          packageAmtIncrease?.amount
    );

    list[packageAmtIncrease?.index - findIndex].package_service_amount +=
      packageAmtIncrease?.increasedAmount - packageAmtIncrease?.amount;

    this.setState({
      items: list,
    });
  }

  addPackageService(row, i) {
    const { items, subCategoryList } = this.state;
    const { patient } = this.props;

    const resultData = {
      sno: items.length - 1,
      service_code: "value",
      package_code: "",
      package_service_amount: 0,
      wsc_code: subCategoryList?.wsc_code,
      pos_amount: null,
      amount: 0,
      package_service_amount: 0,
      pos_total_amount: null,
      pos_qty: null,
      pos_date: new Date(),
      min_date: moment(subCategoryList?.admission_date).format("YYYY-MM-DD"),
      service_edit: false,
      pos_qty_edit: false,
      pos_amount_edit: false,
      tax_edit: false,
      dept_code: "",
      user: subCategoryList?.created_by,
      tax_amount: null,
      tax_percentage: null,
      // type: "Packages",
      sm_code: row.sm_code,
      package_id: row.package_id,
      consultant: "",
      consultant_share: 0,
      consultant_edit: true,
      discount_applicable: false,
      deleteIcon: false,
    };

    resultData.sm_code.unshift(resultData);
    items.splice(i + 1, 0, resultData);

    this.setState({
      items: items,
    });
  }

  packageServiceData(option, i) {
    const {
      items,
      serviceList,
      packageList,
      groupList,
      subCategoryList,
    } = this.state;
    const { patient } = this.props;

    let serviceFilter;
    let selectedName;
    let serviceAmount;

    if (option.type === "Service") {
      serviceFilter = serviceList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.SERVICE_NAME === option?.label;
      });
      selectedName = serviceFilter.SERVICE_NAME;
    } else if (option.type === "Package") {
      serviceFilter = packageList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.PACKAGE_NAME === option?.label;
      });
      selectedName = serviceFilter.PACKAGE_NAME;
    } else if (option.type === "Group") {
      serviceFilter = groupList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.GROUP_NAME === option?.label;
      });
      selectedName = serviceFilter.GROUP_NAME;
    }

    serviceAmount =
      serviceFilter[
        `${subCategoryList?.ward_sub_catagory}_${subCategoryList?.code}_amount`
      ] ||
      serviceFilter?.newAmount ||
      serviceFilter?.SERVICE_AMT ||
      serviceFilter?.GROUP_AMT ||
      0;

    const item = items[i];
    item.service_code = option.value;
    item.package_code = option.value;
    item.package_service_name = selectedName;
    item.service_edit = false;
    item.package_service_amount = 0;
    item.pos_amount = null;
    item.amount = 0;
    item.pos_date = new Date();
    item.pos_qty = null;
    item.pos_qty_edit = serviceFilter?.IP_QTY_EDIT;
    item.pos_amount_edit = false;
    item.package_amount_edit = true;
    item.tax_percentage = null;
    item.tax_amount = null;
    item.pos_total_amount = null;
    // item.tax_percentage = serviceFilter?.TAX_AMOUNT_IP;
    // item.tax_amount = (serviceAmount * serviceFilter?.TAX_AMOUNT_IP) / 100;
    // item.pos_total_amount =
    //   1 * (serviceAmount + serviceAmount * serviceFilter?.TAX_AMOUNT_IP);
    item.tax_edit = serviceFilter?.TAX_APPLICABLE_IN_IP;
    item.dept_code = serviceFilter?.D_CODE;
    item.user = subCategoryList?.created_by;
    item.wsc_code = subCategoryList?.wsc_code;
    item.service_id = serviceFilter?._id;
    item.type = option?.type;

    console.log(items, "items");

    this.setState({
      items: items,
    });
  }

  increased_cost_change(e, sub_name, code, i) {
    const { items } = this.state;
    let itemList = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    itemList.forEach((data, index) => {
      if (data.pos_amount != null && data.selected_name) {
        data.pos_amount =
          parseFloat(data.amount || 0) +
          (parseFloat(data.amount || 0) * valueAmount) / 100;
        data.pos_total_amount = data.pos_qty * data.pos_amount;

        itemList[index] = data;

        if (data.type === "Packages") {
          const percentage =
            (data.pos_amount - data?.package_service_amount) /
            data.package_service_amount;
          for (let i = 1; i <= data.sm_code.length; i++) {
            const amount = parseInt(
              (
                itemList[index + i].amount +
                itemList[index + i].amount * percentage
              ).toFixed(0)
            );
            itemList[index + i].package_service_amount = amount;
          }
        }
      }
    });

    this.setState({
      items: itemList,
    });

    // if (this.state.OPRegistrationtype ==== "services") {
    // let updatedServiceList = [];
    // let selectedList = [...this.state.selectedList];
    // selectedList.forEach((data, index) => {
    //   if (this.state.filterText && this.state.search_service) {
    //     if (
    //       data.D_CODE.toLowerCase() ===
    //         this.state.filterText.toLowerCase() &&
    //       data.SERVICE_NAME.toLowerCase() ===
    //         this.state.search_service.toLowerCase()
    //     ) {
    //       data[`${sub_name}_${code}_amount`] =
    //         data.SERVICE_AMT + (data.SERVICE_AMT * e.target.value) / 100;
    //       selectedList[index] = data;
    //     }
    //   } else if (this.state.filterText) {
    //     if (
    //       data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
    //     ) {
    //       data[`${sub_name}_${code}_amount`] =
    //         data.SERVICE_AMT + (data.SERVICE_AMT * e.target.value) / 100;
    //       selectedList[index] = data;
    //     }
    //   } else if (this.state.search_service) {
    //     if (
    //       data.SERVICE_NAME.toLowerCase() ===
    //       this.state.search_service.toLowerCase()
    //     ) {
    //       data[`${sub_name}_${code}_amount`] =
    //         data.SERVICE_AMT + (data.SERVICE_AMT * e.target.value) / 100;
    //       selectedList[index] = data;
    //     }
    //   } else {
    //     data[`${sub_name}_${code}_amount`] =
    //       data.SERVICE_AMT + (data.SERVICE_AMT * e.target.value) / 100;
    //     updatedServiceList.push(data);
    //   }
    // });
    // if (!this.state.filterText && !this.state.search_service) {
    //   this.setState({
    //     selectedList: updatedServiceList,
    //   });
    // } else {
    //   this.setState({
    //     selectedList: selectedList,
    //   });
    // }
    // } else if (this.state.OPRegistrationtype ==== "packages") {
    //   let updatedPackageList = [];
    //   let packageList = [...this.state.packageList];
    //   packageList.forEach((data, index) => {
    //     if (this.state.filterText && this.state.search_service) {
    //       if (
    //         data.D_CODE.toLowerCase() ===
    //           this.state.filterText.toLowerCase() &&
    //         data.PACKAGE_NAME.toLowerCase() ===
    //           this.state.search_service.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.PACKAGE_AMT + (data.PACKAGE_AMT * e.target.value) / 100;
    //         packageList[index] = data;
    //       }
    //     } else if (this.state.filterText) {
    //       if (
    //         data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.PACKAGE_AMT + (data.PACKAGE_AMT * e.target.value) / 100;
    //         packageList[index] = data;
    //       }
    //     } else if (this.state.search_service) {
    //       if (
    //         data.PACKAGE_NAME.toLowerCase() ===
    //         this.state.search_service.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.PACKAGE_AMT + (data.PACKAGE_AMT * e.target.value) / 100;
    //         packageList[index] = data;
    //       }
    //     } else {
    //       data[`${sub_name}_${code}_amount`] =
    //         data.PACKAGE_AMT + (data.PACKAGE_AMT * e.target.value) / 100;
    //       updatedPackageList.push(data);
    //     }
    //   });
    //   if (!this.state.filterText && !this.state.search_service) {
    //     this.setState({
    //       packageList: updatedPackageList,
    //     });
    //   } else {
    //     this.setState({
    //       packageList: packageList,
    //     });
    //   }
    // } else if (this.state.OPRegistrationtype ==== "groups") {
    //   let updatedGroupList = [];
    //   let groupList = [...this.state.groupList];
    //   groupList.forEach((data, index) => {
    //     if (this.state.filterText && this.state.search_service) {
    //       if (
    //         data.D_CODE.toLowerCase() ===
    //           this.state.filterText.toLowerCase() &&
    //         data.GROUP_NAME.toLowerCase() ===
    //           this.state.search_service.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.GROUP_AMT + (data.GROUP_AMT * e.target.value) / 100;
    //         groupList[index] = data;
    //       }
    //     } else if (this.state.filterText) {
    //       if (
    //         data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.GROUP_AMT + (data.GROUP_AMT * e.target.value) / 100;
    //         groupList[index] = data;
    //       }
    //     } else if (this.state.search_service) {
    //       if (
    //         data.GROUP_NAME.toLowerCase() ===
    //         this.state.search_service.toLowerCase()
    //       ) {
    //         data[`${sub_name}_${code}_amount`] =
    //           data.GROUP_AMT + (data.GROUP_AMT * e.target.value) / 100;
    //         groupList[index] = data;
    //       }
    //     } else {
    //       data[`${sub_name}_${code}_amount`] =
    //         data.GROUP_AMT + (data.GROUP_AMT * e.target.value) / 100;
    //       updatedGroupList.push(data);
    //     }
    //   });
    //   if (!this.state.filterText && !this.state.search_service) {
    //     this.setState({
    //       groupList: updatedGroupList,
    //     });
    //   } else {
    //     this.setState({
    //       groupList: groupList,
    //     });
    //   }
    // }
    // }
  }

  decreased_cost_change(e, sub_name, code, i) {
    const { items } = this.state;
    let itemList = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    itemList.forEach((data, index) => {
      if (data.pos_amount != null && data.selected_name) {
        data.pos_amount =
          parseFloat(data.amount || 0) -
          (parseFloat(data.amount || 0) * valueAmount) / 100;
        data.pos_total_amount = data.pos_qty * data.pos_amount;

        itemList[index] = data;

        if (data.type === "Packages") {
          const percentage =
            (data.pos_amount - data?.package_service_amount) /
            data.package_service_amount;
          for (let i = 1; i <= data.sm_code.length; i++) {
            const amount = parseInt(
              (
                itemList[index + i].amount +
                itemList[index + i].amount * percentage
              ).toFixed(0)
            );
            itemList[index + i].package_service_amount = amount;
          }
        }
      }
    });

    this.setState({
      items: itemList,
    });
  }

  increase_cost_amount(e) {
    const { items } = this.state;
    let itemList = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    itemList.forEach((data, index) => {
      if (data.pos_amount != null && data.selected_name) {
        data.pos_amount = parseFloat(data.amount || 0) + valueAmount;
        data.pos_total_amount = data.pos_qty * data.pos_amount;

        itemList[index] = data;

        if (data.type === "Packages") {
          const percentage =
            (data.pos_amount - data?.package_service_amount) /
            data.package_service_amount;
          for (let i = 1; i <= data.sm_code.length; i++) {
            const amount = parseInt(
              (
                itemList[index + i].amount +
                itemList[index + i].amount * percentage
              ).toFixed(0)
            );
            itemList[index + i].package_service_amount = amount;
          }
        }
      }
    });

    this.setState({
      items: itemList,
    });
  }

  decreased_cost_amount(e) {
    const { items } = this.state;
    let itemList = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    itemList.forEach((data, index) => {
      if (data.pos_amount != null && data.selected_name) {
        data.pos_amount = parseFloat(data.amount || 0) - valueAmount;
        data.pos_total_amount = data.pos_qty * data.pos_amount;

        itemList[index] = data;

        if (data.type === "Packages") {
          const percentage =
            (data.pos_amount - data?.package_service_amount) /
            data.package_service_amount;
          for (let i = 1; i <= data.sm_code.length; i++) {
            const amount = parseInt(
              (
                itemList[index + i].amount +
                itemList[index + i].amount * percentage
              ).toFixed(0)
            );
            itemList[index + i].package_service_amount = amount;
          }
        }
      }
    });

    this.setState({
      items: itemList,
    });
  }

  // decreased_cost_change(e, sub_name, code, i) {
  //   if (e.target.value) {
  //     if (e.target.value > 100) {
  //       if (this.state.OPRegistrationtype ==== "services") {
  //         let updatedServiceList = [];
  //         let selectedList = [...this.state.selectedList];
  //         selectedList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.SERVICE_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * 100) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * 100) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.SERVICE_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * 100) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.SERVICE_AMT - (data.SERVICE_AMT * 100) / 100;
  //             updatedServiceList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             selectedList: updatedServiceList,
  //           });
  //         } else {
  //           this.setState({
  //             selectedList: selectedList,
  //           });
  //         }
  //       } else if (this.state.OPRegistrationtype ==== "packages") {
  //         let updatedPackageList = [];
  //         let packageList = [...this.state.packageList];
  //         packageList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.PACKAGE_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * 100) / 100;
  //               packageList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * 100) / 100;
  //               packageList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.PACKAGE_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * 100) / 100;
  //               packageList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.PACKAGE_AMT - (data.PACKAGE_AMT * 100) / 100;
  //             updatedPackageList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             packageList: updatedPackageList,
  //           });
  //         } else {
  //           this.setState({
  //             packageList: packageList,
  //           });
  //         }
  //       } else if (this.state.OPRegistrationtype ==== "groups") {
  //         let updatedGroupList = [];
  //         let groupList = [...this.state.groupList];
  //         groupList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.GROUP_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * 100) / 100;
  //               groupList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * 100) / 100;
  //               groupList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.GROUP_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * 100) / 100;
  //               groupList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.GROUP_AMT - (data.GROUP_AMT * 100) / 100;
  //             updatedGroupList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             groupList: updatedGroupList,
  //           });
  //         } else {
  //           this.setState({
  //             groupList: groupList,
  //           });
  //         }
  //       }
  //       // this.setState({
  //       //   column_amount: 100,
  //       // });
  //     } else {
  //       if (this.state.OPRegistrationtype ==== "services") {
  //         let updatedServiceList = [];
  //         let selectedList = [...this.state.selectedList];
  //         selectedList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.SERVICE_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * e.target.value) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * e.target.value) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.SERVICE_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.SERVICE_AMT - (data.SERVICE_AMT * e.target.value) / 100;
  //               selectedList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.SERVICE_AMT - (data.SERVICE_AMT * e.target.value) / 100;
  //             updatedServiceList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             selectedList: updatedServiceList,
  //           });
  //         } else {
  //           this.setState({
  //             selectedList: selectedList,
  //           });
  //         }
  //       } else if (this.state.OPRegistrationtype ==== "packages") {
  //         let updatedPackageList = [];
  //         let packageList = [...this.state.packageList];
  //         packageList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.PACKAGE_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * e.target.value) / 100;
  //               packageList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * e.target.value) / 100;
  //               packageList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.PACKAGE_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.PACKAGE_AMT - (data.PACKAGE_AMT * e.target.value) / 100;
  //               packageList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.PACKAGE_AMT - (data.PACKAGE_AMT * e.target.value) / 100;
  //             updatedPackageList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             packageList: updatedPackageList,
  //           });
  //         } else {
  //           this.setState({
  //             packageList: packageList,
  //           });
  //         }
  //       } else if (this.state.OPRegistrationtype ==== "groups") {
  //         let updatedGroupList = [];
  //         let groupList = [...this.state.groupList];
  //         groupList.forEach((data, index) => {
  //           if (this.state.filterText && this.state.search_service) {
  //             if (
  //               data.D_CODE.toLowerCase() ===
  //                 this.state.filterText.toLowerCase() &&
  //               data.GROUP_NAME.toLowerCase() ===
  //                 this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * e.target.value) / 100;
  //               groupList[index] = data;
  //             }
  //           } else if (this.state.filterText) {
  //             if (
  //               data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * e.target.value) / 100;
  //               groupList[index] = data;
  //             }
  //           } else if (this.state.search_service) {
  //             if (
  //               data.GROUP_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //             ) {
  //               data[`${sub_name}_${code}_amount`] =
  //                 data.GROUP_AMT - (data.GROUP_AMT * e.target.value) / 100;
  //               groupList[index] = data;
  //             }
  //           } else {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.GROUP_AMT - (data.GROUP_AMT * e.target.value) / 100;
  //             updatedGroupList.push(data);
  //           }
  //         });
  //         if (!this.state.filterText && !this.state.search_service) {
  //           this.setState({
  //             groupList: updatedGroupList,
  //           });
  //         } else {
  //           this.setState({
  //             groupList: groupList,
  //           });
  //         }
  //       }
  //       // this.setState({
  //       //   column_amount: e.target.value,
  //       // });
  //     }
  //   } else {
  //     if (this.state.OPRegistrationtype ==== "services") {
  //       let updatedServiceList = [];
  //       let selectedList = [...this.state.selectedList];
  //       selectedList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ===
  //               this.state.filterText.toLowerCase() &&
  //             data.SERVICE_NAME.toLowerCase() ===
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.SERVICE_AMT;
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() === this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.SERVICE_AMT;
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.SERVICE_NAME.toLowerCase() ===
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.SERVICE_AMT;
  //             selectedList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = data.SERVICE_AMT;
  //           updatedServiceList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           selectedList: updatedServiceList,
  //         });
  //       } else {
  //         this.setState({
  //           selectedList: selectedList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype ==== "packages") {
  //       let updatedPackageList = [];
  //       let packageList = [...this.state.packageList];
  //       packageList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ===
  //               this.state.filterText.toLowerCase() &&
  //             data.PACKAGE_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.PACKAGE_AMT;
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.PACKAGE_AMT;
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.PACKAGE_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.PACKAGE_AMT;
  //             packageList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = data.PACKAGE_AMT;
  //           updatedPackageList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           packageList: updatedPackageList,
  //         });
  //       } else {
  //         this.setState({
  //           packageList: packageList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype === "groups") {
  //       let updatedGroupList = [];
  //       let groupList = [...this.state.groupList];
  //       groupList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.GROUP_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.GROUP_AMT;
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.GROUP_AMT;
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.GROUP_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = data.GROUP_AMT;
  //             groupList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = data.GROUP_AMT;
  //           updatedGroupList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           groupList: updatedGroupList,
  //         });
  //       } else {
  //         this.setState({
  //           groupList: groupList,
  //         });
  //       }
  //     }
  //     this.setState({
  //       column_amount: 0,
  //     });
  //   }
  // }

  // increase_cost_amount(e, sub_name, code, i) {
  //   if (e.target.value) {
  //     if (this.state.OPRegistrationtype === "services") {
  //       let updatedServiceList = [];
  //       let selectedList = [...this.state.selectedList];
  //       selectedList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.SERVICE_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.SERVICE_AMT + parseFloat(e.target.value);
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.SERVICE_AMT + parseFloat(e.target.value);
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.SERVICE_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.SERVICE_AMT + parseFloat(e.target.value);
  //             selectedList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] =
  //             data.SERVICE_AMT + parseFloat(e.target.value);
  //           updatedServiceList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           selectedList: updatedServiceList,
  //         });
  //       } else {
  //         this.setState({
  //           selectedList: selectedList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype === "packages") {
  //       let updatedPackageList = [];
  //       let packageList = [...this.state.packageList];
  //       packageList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.PACKAGE_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.PACKAGE_AMT + parseFloat(e.target.value);
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.PACKAGE_AMT + parseFloat(e.target.value);
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.PACKAGE_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.PACKAGE_AMT + parseFloat(e.target.value);
  //             packageList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] =
  //             data.PACKAGE_AMT + parseFloat(e.target.value);
  //           updatedPackageList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           packageList: updatedPackageList,
  //         });
  //       } else {
  //         this.setState({
  //           packageList: packageList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype === "groups") {
  //       let updatedGroupList = [];
  //       let groupList = [...this.state.groupList];
  //       groupList.forEach((data, index) => {
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.GROUP_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.GROUP_AMT + parseFloat(e.target.value);
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.GROUP_AMT + parseFloat(e.target.value);
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.GROUP_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] =
  //               data.GROUP_AMT + parseFloat(e.target.value);
  //             groupList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] =
  //             data.GROUP_AMT + parseFloat(e.target.value);
  //           updatedGroupList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           groupList: updatedGroupList,
  //         });
  //       } else {
  //         this.setState({
  //           groupList: groupList,
  //         });
  //       }
  //     }
  //     // this.setState({
  //     //   column_amount: e.target.value,
  //     // });
  //   } else {
  //     this.setState({
  //       column_amount: 0,
  //     });
  //   }
  // }

  // decreased_cost_amount(e, sub_name, code, i) {
  //   if (e.target.value) {
  //     if (this.state.OPRegistrationtype === "services") {
  //       let updatedServiceList = [];
  //       let selectedList = [...this.state.selectedList];
  //       selectedList.forEach((data, index) => {
  //         let serviceamount = data.SERVICE_AMT - parseFloat(e.target.value);
  //         if (serviceamount < 0) {
  //           serviceamount = 0;
  //         }
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.SERVICE_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = serviceamount;
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = serviceamount;
  //             selectedList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.SERVICE_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = serviceamount;
  //             selectedList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = serviceamount;
  //           updatedServiceList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           selectedList: updatedServiceList,
  //         });
  //       } else {
  //         this.setState({
  //           selectedList: selectedList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype === "packages") {
  //       let updatedPackageList = [];
  //       let packageList = [...this.state.packageList];
  //       packageList.forEach((data, index) => {
  //         let packageamount = data.PACKAGE_AMT - parseFloat(e.target.value);
  //         if (packageamount < 0) {
  //           packageamount = 0;
  //         }
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.PACKAGE_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = packageamount;
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = packageamount;
  //             packageList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.PACKAGE_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = packageamount;
  //             packageList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = packageamount;
  //           updatedPackageList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           packageList: updatedPackageList,
  //         });
  //       } else {
  //         this.setState({
  //           packageList: packageList,
  //         });
  //       }
  //     } else if (this.state.OPRegistrationtype === "groups") {
  //       let updatedGroupList = [];
  //       let groupList = [...this.state.groupList];
  //       groupList.forEach((data, index) => {
  //         let groupamount = data.GROUP_AMT - parseFloat(e.target.value);
  //         if (groupamount < 0) {
  //           groupamount = 0;
  //         }
  //         if (this.state.filterText && this.state.search_service) {
  //           if (
  //             data.D_CODE.toLowerCase() ==
  //               this.state.filterText.toLowerCase() &&
  //             data.GROUP_NAME.toLowerCase() ==
  //               this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = groupamount;
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.filterText) {
  //           if (
  //             data.D_CODE.toLowerCase() == this.state.filterText.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = groupamount;
  //             groupList[index] = data;
  //           }
  //         } else if (this.state.search_service) {
  //           if (
  //             data.GROUP_NAME.toLowerCase() ==
  //             this.state.search_service.toLowerCase()
  //           ) {
  //             data[`${sub_name}_${code}_amount`] = groupamount;
  //             groupList[index] = data;
  //           }
  //         } else {
  //           data[`${sub_name}_${code}_amount`] = groupamount;
  //           updatedGroupList.push(data);
  //         }
  //       });
  //       if (!this.state.filterText && !this.state.search_service) {
  //         this.setState({
  //           groupList: updatedGroupList,
  //         });
  //       } else {
  //         this.setState({
  //           groupList: groupList,
  //         });
  //       }
  //     }
  //     // this.setState({
  //     //   column_amount: e.target.value,
  //     // });
  //   } else {
  //     this.setState({
  //       column_amount: 0,
  //     });
  //   }
  // }

  deletePos(data, i) {
    const { dispatch } = this.props;
    const { items } = this.state;
    const list = [...items];
    data.is_deleted = true;
    // list[i] = data;
    // const itemList = list.filter(value => !value.is_deleted);
    // for(let j= 0; j < i; j++) {
    //   if(!list[j].is_deleted){
    //     list[j].sno = itemList?.length - j
    //   }
    // }
    list.splice(i, 1);
    list.push(data);

    this.setState({
      items: list,
    });
  }

  departmentToValue(code) {
    try {
      const { departments } = this.props;
      if (departments)
        return departments.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const { patient } = this.props;
    const { subCategoryList } = this.state;
    let min_date = subCategoryList && new Date(subCategoryList?.admission_date);
    // let min_date = subCategoryList && moment(subCategoryList?.admission_date).format("YYYY-MM-DD");

    let totalAmount = 0;
    this.state.items &&
      this.state.items
        .filter((list) => !list.is_deleted)
        .map((value) => {
          return (totalAmount += parseFloat(value.pos_total_amount || 0));
        });

    let serviceList;
    if (this.state.items) {
      serviceList = this.state.items
        .filter((list) => !list.is_deleted)
        .map((data, i) => {
          if (this.state.searchValue) {
            if (
              this.departmentToValue(data.dept_code)
                ?.toLowerCase()
                .includes(this.state.searchValue.toLowerCase()) ||
              data?.selected_name
                ?.toLowerCase()
                .includes(this.state.searchValue.toLowerCase())
            ) {
              let tds = [
                <td>
                  {this.state.items?.filter((value) => !value.is_deleted)
                    ?.length - i}
                </td>,
              ];
              tds.push(
                <td>
                  <div style={{ width: "150px" }}>
                    <DatePicker
                      selected={new Date(data?.pos_date)}
                      name="pos_date"
                      placeholderText="Select date"
                      minDate={min_date}
                      onChange={(date) => this.dateHandler(date, data, i)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control removeUnwantedClass"
                      autoComplete="off"
                    />
                  </div>
                </td>
              );
              tds.push(<td>{this.departmentToValue(data.dept_code)}</td>);
              tds.push(
                <td>
                  {
                    <div style={{ width: "200px" }} className="serviceWrapper">
                      {!data.service_code ? (
                        <Select
                          name="service_code"
                          placeholder="Select Service"
                          onChange={(opt) => this.serviceHandler(opt, i)}
                          options={this.state.selectedList}
                          value={data.service_code}
                          menuPortalTarget={document.body}
                          menuPosition="absolute"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          maxMenuHeight={150}
                        ></Select>
                      ) : (
                        data?.selected_name
                      )}
                    </div>
                  }
                </td>
              );
              tds.push(
                <td>
                  {
                    <div style={{ width: "200px" }} className="serviceWrapper">
                      {!data.package_code ? (
                        <Select
                          name="package_code"
                          placeholder="Select Service"
                          onChange={(opt) => this.packageServiceData(opt, i)}
                          options={this.state.serviceDataList}
                          value={this.state.serviceDataList?.find(
                            (obj) => obj.value === data.service_code
                          )}
                          menuPortalTarget={document.body}
                          menuPosition="absolute"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          maxMenuHeight={150}
                        ></Select>
                      ) : (
                        data?.package_service_name
                      )}
                    </div>
                  }
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data?.package_service_amount >= 0 && (
                      <Input
                        type="text"
                        name="package_service_amount"
                        value={data.package_service_amount}
                        onChange={(e) => this.packageAmtIncrease(e, data, i)}
                        disabled={!data.package_amount_edit}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  {data?.addButton ? (
                    <div style={{ width: "50px" }}>
                      <Button
                        onClick={() => this.togglePopup(data, i)}
                        // onClick={() => this.addPackageService(data, i)}
                      >
                        Add
                      </Button>
                    </div>
                  ) : null}
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "50px" }}>
                    <Input
                      type="text"
                      name="tpa_code"
                      value={data.tpa_code}
                      onChange={(e) => this.changeHandler(e, data, i)}
                    ></Input>
                  </div>
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data?.pos_amount != null && (
                      <Input
                        type="text"
                        name="pos_amount"
                        value={data?.pos_amount}
                        onChange={(e) =>
                          data.type === "Packages"
                            ? this.packageHandler(e, data, i)
                            : this.changeHandler(e, data, i)
                        }
                        disabled={!data.pos_amount_edit}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              // tds.push(
              //   <td>
              //     <div style={{ width: "50px" }}>
              //     {data?.tax_percentage != null &&

              //       <Input
              //         type="text"
              //         name="tax_percentage"
              //         value={data?.tax_percentage}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //         disabled={true}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //     {data?.tax_amount != null &&

              //       <Input
              //         type="text"
              //         name="tax_amount"
              //         value={data?.tax_amount}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //         disabled={true}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              tds.push(
                <td>
                  <div style={{ width: "50px" }}>
                    {data?.pos_qty != null && (
                      <Input
                        type="text"
                        name="pos_qty"
                        value={data?.pos_qty}
                        onChange={(e) => this.changeHandler(e, data, i)}
                        disabled={!data?.pos_qty_edit}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data?.pos_total_amount != null && (
                      <Input
                        type="text"
                        name="pos_total_amount"
                        value={
                          data?.pos_total_amount ||
                          (parseFloat(data?.pos_amount) || 0) *
                            (parseFloat(data?.pos_qty) || 0)
                        }
                        disabled={true}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td style={{ width: "150px" }}>
                  {this.wardSubCatagoryCodeToValue(data.wsc_code)}
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {data.consultant ? (
                      <Select
                        name="consultant"
                        placeholder="Select consultant"
                        onChange={(opt) =>
                          this.onconsultantChange(opt, data, i)
                        }
                        options={this.state.consultantList}
                        value={this.state.consultantList?.find(
                          (obj) => obj.value === data.consultant
                        )}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : data.deleteIcon ? (
                      <Select
                        name="consultant"
                        placeholder="Select consultant"
                        onChange={(opt) =>
                          this.onconsultantChange(opt, data, i)
                        }
                        options={this.state.consultantList}
                        value={""}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data.deleteIcon && (
                      <Input
                        type="text"
                        name="consultant_share"
                        value={data.consultant_share}
                        onChange={(e) =>
                          this.consultant_share_amount(e, data, i)
                        }
                      ></Input>
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    <Input
                      type="checkbox"
                      name="tpa_amount"
                      // value={data.consultant_share}
                    ></Input>
                  </div>
                </td>
              );
              tds.push(<td>{data.user}</td>);
              tds.push(
                <td>
                  {data.deleteIcon && (
                    <a>
                      <img
                        src={Icons.UnDelete}
                        alt=""
                        onClick={() =>
                          this.state.isDeleteAuthorized
                            ? this.onDeleteLogin(data, i)
                            : this.toggleDelete(data, i)
                        }
                        className="delete-icon"
                      />
                    </a>
                  )}
                </td>
              );

              return <tr key={data._id}>{tds}</tr>;
            }
          } else {
            let tds = [
              <td>
                {this.state.items?.filter((value) => !value.is_deleted)
                  ?.length - i}
              </td>,
            ];
            tds.push(
              <td>
                <div style={{ width: "200px" }}>
                  <DatePicker
                    selected={new Date(data?.pos_date)}
                    name="pos_date"
                    placeholderText="Select date"
                    minDate={min_date}
                    onChange={(date) => this.dateHandler(date, data, i)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control removeUnwantedClass"
                    autoComplete="off"
                  />
                </div>
              </td>
            );
            tds.push(<td>{this.departmentToValue(data.dept_code)}</td>);
            tds.push(
              <td>
                {
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {!data.service_code ? (
                      <Select
                        name="service_code"
                        placeholder="Select Service"
                        onChange={(opt) => this.serviceHandler(opt, i)}
                        options={this.state.selectedList}
                        value={data.service_code}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      data?.selected_name
                    )}
                  </div>
                }
              </td>
            );
            tds.push(
              <td>
                {
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {!data.package_code ? (
                      <Select
                        name="package_code"
                        placeholder="Select Service"
                        onChange={(opt) => this.packageServiceData(opt, i)}
                        options={this.state.serviceDataList}
                        value={this.state.serviceDataList?.find(
                          (obj) => obj.value === data.service_code
                        )}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      data?.package_service_name
                    )}
                  </div>
                }
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data?.package_service_amount >= 0 && (
                    <Input
                      type="text"
                      name="package_service_amount"
                      value={data.package_service_amount}
                      onChange={(e) => this.packageAmtIncrease(e, data, i)}
                      disabled={!data.package_amount_edit}
                    ></Input>
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                {data?.addButton ? (
                  <div style={{ width: "100px" }}>
                    <Button onClick={() => this.togglePopup(data, i)}>
                      Add
                    </Button>
                  </div>
                ) : null}
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "50px" }}>
                  <Input
                    type="text"
                    name="tpa_code"
                    value={data.tpa_code}
                    onChange={(e) => this.changeHandler(e, data, i)}
                  ></Input>
                </div>
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data?.pos_amount != null && (
                    <Input
                      type="text"
                      name="pos_amount"
                      value={data?.pos_amount}
                      onChange={(e) =>
                        data.type === "Packages"
                          ? this.packageHandler(e, data, i)
                          : this.changeHandler(e, data, i)
                      }
                      disabled={!data.pos_amount_edit}
                    ></Input>
                  )}
                </div>
              </td>
            );
            // tds.push(
            //   <td>
            //     <div style={{ width: "50px" }}>
            //     {data?.tax_percentage != null &&

            //       <Input
            //         type="text"
            //         name="tax_percentage"
            //         value={data?.tax_percentage}
            //         onChange={(e) => this.changeHandler(e, data, i)}
            //         disabled={true}
            //       ></Input>
            //     }
            //     </div>
            //   </td>
            // );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //       {data?.tax_amount != null && (
            //         <Input
            //           type="text"
            //           name="tax_amount"
            //           value={data?.tax_amount}
            //           onChange={(e) => this.changeHandler(e, data, i)}
            //           disabled={true}
            //         ></Input>
            //       )}
            //     </div>
            //   </td>
            // );
            tds.push(
              <td>
                <div style={{ width: "50px" }}>
                  {data?.pos_qty != null && (
                    <Input
                      type="text"
                      name="pos_qty"
                      value={data?.pos_qty}
                      onChange={(e) => this.changeHandler(e, data, i)}
                      disabled={!data?.pos_qty_edit}
                    ></Input>
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data?.pos_total_amount != null && (
                    <Input
                      type="text"
                      name="pos_total_amount"
                      value={
                        data?.pos_total_amount ||
                        (parseFloat(data?.pos_amount) || 0) *
                          (parseFloat(data?.pos_qty) || 0)
                      }
                      disabled={true}
                    ></Input>
                  )}
                </div>
              </td>
            );
            tds.push(
              <td style={{ width: "150px" }}>
                {this.wardSubCatagoryCodeToValue(data.wsc_code)}
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "200px" }} className="serviceWrapper">
                  {data.consultant ? (
                    <Select
                      name="consultant"
                      placeholder="Select consultant"
                      onChange={(opt) => this.onconsultantChange(opt, data, i)}
                      options={this.state.consultantList}
                      value={this.state.consultantList?.find(
                        (obj) => obj.value === data.consultant
                      )}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      maxMenuHeight={150}
                    ></Select>
                  ) : data.deleteIcon ? (
                    <>
                      <Select
                        name="consultant"
                        placeholder="Select consultant"
                        onChange={(opt) =>
                          this.onconsultantChange(opt, data, i)
                        }
                        options={this.state.consultantList}
                        value={""}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data.deleteIcon ? (
                    <Input
                      type="text"
                      name="consultant_share"
                      value={data.consultant_share}
                      onChange={(e) => this.consultant_share_amount(e, data, i)}
                    ></Input>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  <Input
                    type="checkbox"
                    name="tpa_amount"
                    // value={data.consultant_share}
                  ></Input>
                </div>
              </td>
            );
            tds.push(<td>{data.user}</td>);
            tds.push(
              <td>
                {data.deleteIcon && (
                  <a>
                    <img
                      src={Icons.UnDelete}
                      alt=""
                      onClick={() =>
                        this.state.isDeleteAuthorized
                          ? this.onDeleteLogin(data, i)
                          : this.toggleDelete(data, i)
                      }
                      className="delete-icon"
                    />
                  </a>
                )}
              </td>
            );

            return <tr key={data._id}>{tds}</tr>;
          }
        });
    }

    return (
      <Fragment>
        <Card>
          <CardHeader>General POS</CardHeader>
          <PatientGeneralInfo
            patientData={this.state.subCategoryList}
          ></PatientGeneralInfo>
        </Card>
        {/* <DataTable2
          columns={this.column}
          data={this.state.items}
          conditionalRowStyles={this.conditionalRowStyles}
          subHeader={true}
          subHeaderComponent={<Row></Row>}
          autoFocus={true}
        /> */}
        <div className="d-flex mt-3">
          <Col>
            <Label style={{ fontWeight: "bold" }}>Modify Amount</Label>
            <FormGroup
              row
              className="hmgroup Registrationgroupradio"
              style={{ width: "58%" }}
            >
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="increaseBy%"
                  value="increaseBy%"
                  name="amountCalculation"
                  label="Increased Cost By %"
                  onChange={this.onChange}
                  checked={this.state.amountCalculation === "increaseBy%"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="decreaseBy%"
                  value="decreaseBy%"
                  name="amountCalculation"
                  label="Decreased Cost By %"
                  onChange={this.onChange}
                  checked={this.state.amountCalculation === "decreaseBy%"}
                  className="labelFontSmall"
                />
              </div>

              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="increaseByCost"
                  value="increaseByCost"
                  name="amountCalculation"
                  label="Increased Cost by Amount"
                  onChange={this.onChange}
                  checked={this.state.amountCalculation === "increaseByCost"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="decreaseByCost"
                  value="decreaseByCost"
                  name="amountCalculation"
                  label="Decreased Cost by Amount"
                  onChange={this.onChange}
                  checked={this.state.amountCalculation === "decreaseByCost"}
                  className="labelFontSmall"
                />
              </div>
            </FormGroup>
          </Col>
        </div>
        <div className="d-flex my-3 align-items-end">
          <Col md={4}>
            <Label style={{ fontWeight: "bold" }}>Filter by</Label>
            <FormGroup
              row
              className="hmgroup Registrationgroupradio"
              style={{ width: "100%" }}
            >
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="date"
                  value="date"
                  name="filterData"
                  label="Date"
                  onChange={this.onChange}
                  checked={this.state.filterData === "date"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="service"
                  value="service"
                  name="filterData"
                  label="Service"
                  onChange={this.onChange}
                  checked={this.state.filterData === "service"}
                  className="labelFontSmall"
                />
              </div>

              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="department"
                  value="department"
                  name="filterData"
                  label="Department"
                  onChange={this.onChange}
                  checked={this.state.filterData === "department"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="all"
                  value="all"
                  name="filterData"
                  label="All"
                  onChange={this.onChange}
                  checked={this.state.filterData === "all"}
                  className="labelFontSmall"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={2}>
            <div className="mr-3">
              {this.state.filterData !== "all" && (
                <>
                  <Label style={{ fontWeight: "bold" }}>Filter by</Label>
                  <FormGroup>
                    <div className="wardService_types">
                      <CustomInput
                        type="text"
                        value={this.state.searchValue}
                        name="searchValue"
                        onChange={this.onChange}
                      />
                    </div>
                  </FormGroup>
                </>
              )}
            </div>
          </Col>

          <Col>
            <div className="d-flex align-items-end justify-content-end">
              {/* <div className="mr-3">
                {this.state.filterData != "all" && (
                  <>
                    <Label style={{ fontWeight: "bold" }}>Filter by</Label>
                    <FormGroup>
                      <div className="wardService_types">
                        <CustomInput
                          type="text"
                          value={this.state.searchValue}
                          name="searchValue"
                          onChange={this.onChange}
                        />
                      </div>
                    </FormGroup>
                  </>
                )}
              </div> */}
              <div>
                <Button
                  color="success"
                  className="mr-2"
                  onClick={this.onSubmitForm}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Col>
        </div>
        <div className="d-flex my-3">
          <strong style={{ fontSize: "20px" }}>
            Total Amount : {totalAmount}
          </strong>
        </div>
        <Row style={{ overflow: "hidden" }}>
          <Col>
            <Card>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>SR No.</th>
                      <th>Date</th>
                      <th>Department</th>
                      <th>Service</th>
                      <th>Pack-Services</th>
                      <th>Package Amount</th>
                      <th>Add Service</th>
                      <th>TPA Code</th>
                      <th>
                        Amount
                        <Input
                          placeholder="Enter value"
                          name="column_amount"
                          onChange={(e) =>
                            this.state.amountCalculation === "increaseBy%"
                              ? this.increased_cost_change(e)
                              : this.state.amountCalculation === "decreaseBy%"
                              ? this.decreased_cost_change(e)
                              : this.state.amountCalculation ===
                                "increaseByCost"
                              ? this.increase_cost_amount(e)
                              : this.state.amountCalculation ===
                                "decreaseByCost"
                              ? this.decreased_cost_amount(e)
                              : ""
                          }
                        ></Input>
                      </th>
                      {/* <th>Tax Percentage</th>
                      <th>Tax Amount</th> */}
                      <th>Qty</th>
                      <th>Total Amount</th>
                      <th>Ward</th>
                      <th>Consultant</th>
                      <th>Share</th>
                      <th>TPA</th>
                      <th>User</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{serviceList}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.togglePopup()}
          className={this.props.className}
          style={{ width: 400 }}
        >
          <ModalHeader toggle={() => this.togglePopup()}>
            Authentication Required!{" "}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={8} sm={8} className="offset-sm-1">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason for adding service</Label>
                  <Input
                    type="text"
                    name="reason"
                    placeholder="Reason"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={2}>
                {" "}
                <Button color="link" onClick={() => this.togglePopup()}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                {" "}
                <Button color="primary" onClick={() => this.onLogin()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={() => this.toggleDelete()}
          className={this.props.className}
          style={{ width: 400 }}
        >
          <ModalHeader toggle={() => this.toggleDelete()}>
            Authentication Required!{" "}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={8} sm={8} className="offset-sm-1">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason for Delete</Label>
                  <Input
                    type="text"
                    name="reason"
                    placeholder="Reason for Delete"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={2}>
                {" "}
                <Button color="link" onClick={() => this.toggleDelete()}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                {" "}
                <Button color="primary" onClick={() => this.onDeleteLogin()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    newMaster,
    wardManagement,
    patients,
    consultants,
    serviceMaster,
    departments,
    masters,
    registrationConsWithFeesMaster,
  } = state;
  return {
    newMaster: newMaster.userType,
    wardtypes: wardManagement.wardtypes,
    wardroom: wardManagement.wardroom,
    wardbycatagorys: wardManagement.wardbycatagorys,
    wardcatagorys: wardManagement.wardcatagorys,
    wardsubcatagory: wardManagement.wardsubcatagory,
    patient: patients.selected_patient,
    wardbed: wardManagement.wardbed,
    wardFacility: wardManagement.wardfacility,
    consultants: consultants.consultants,
    serviceMaster: serviceMaster,
    graceperiod: wardManagement.graceperiod,
    departments: departments.departments,
    wardcatagoryfees: wardManagement.wardcatagoryfees,
    masters: masters,
    registrationConsWithFeesMaster:
      registrationConsWithFeesMaster.registrationconfees,
  };
}

const connectedProcessPos = connect(mapStateToProps)(ProcessPos);

export default connectedProcessPos;
