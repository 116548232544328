import React, {Fragment} from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import {CorporateMainView} from './ViewMaster'
import {CorporateAddForm} from './AddCorporate'
import {CorporateConsultantMaster} from './CorporateWiseConsultantMaster'
import CorporateWiseserviceMaster from './CorporateWiseServiceMaster'

const CorporateMaster = ({match}) => (
    <Fragment>
      

                    {/* Form Elements */}
<Switch>
                    <Route exact path={`${match.url}`} component={CorporateMainView}/>
                   <Route exact path={`${match.url}/add`} component={CorporateAddForm}/>
                    <Route exact path={`${match.url}/corporatewiseconsultantfees`} component={CorporateConsultantMaster} />
                     <Route exact path = {`${match.url}/corporatewiseservicemaster`} component={CorporateWiseserviceMaster} />
                    {/*  <Route exact path={`${match.url}/generateQRCard`} component={GenerateQRCard} /> */}

                    {/*<Route path={`${match.url}/validation`} component={FormElementsValidation}/> */}
                    <Redirect from="*" to={match.url}></Redirect>
                    </Switch>
              
    </Fragment>
);

export default CorporateMaster;
