import React, { Fragment, Component } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
    ModalHeader, ModalBody, ModalFooter, TabContent, TabPane,
} from 'reactstrap';
import Rodal from 'rodal';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Container } from 'reactstrap';


export default class VerifyOtpComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row className="text-center"></Row>

                    <Rodal visible={this.props.visibleOTPScreen}
                        onClose={this.props.hide}

                        showMask={false}
                    >
                        <ModalHeader>Verify OTP</ModalHeader>
                        <ModalBody>

                            <h4>Please enter the OTP here</h4>
                            <Row>
                                <Col>
                                    <FormGroup style={{ "width": "100%" }}>
                                        <Label for="EnterOTP">OTP</Label>
                                        <Input type="text" name="OTP" id="EnterOTP" onChange={(e) => this.props.onChangeHandler("OTP", e.target.value)}
                                            placeholder="Enter OTP here..." maxLength="6" />
                                    </FormGroup>

                                    <p className="error" style={{ display: "none" }}>Invalid OTP</p>
                                </Col>

                            </Row>




                        </ModalBody>
                        <ModalFooter>
                            <Button color="link" onClick={this.props.hide}>Cancel</Button>
                            <Button color="primary" onClick={this.props.onOTPClickHandler}>Verify OTP</Button>
                        </ModalFooter>
                    </Rodal>
                </CSSTransitionGroup>
        )
    }
}