import { consultantConstants } from "../constants";

export function consultants(
  state = { loading: false, consultants: [] },
  action
) {
  switch (action.type) {
    case consultantConstants.SELECT_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case consultantConstants.SELECT_CONSULTANT_SUCCESS:
      // let selected_consultant = state.consultants.find(
      //   (ob) => ob.id === action.consultantId
      // );

      return {
        ...state,
        selected_consultant: state.consultants[action.consultantId],
        loading: false,
      };

    case consultantConstants.SELECT_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case consultantConstants.GET_ALL_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case consultantConstants.GET_ALL_CONSULTANT_SUCCESS:
      return {
        ...state,
        consultants: action.data,
        //favorite_consultant: action.data.favorite_consultant,
        loading: false,
      };
    case consultantConstants.GET_ALL_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case consultantConstants.ADD_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case consultantConstants.ADD_CONSULTANT_SUCCESS:
      return {
        ...state,
        consultants: [...state.consultants, action.consultant],
        loading: false,
      };
    case consultantConstants.ADD_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case consultantConstants.SET_DEFAULT_CONSULTANT_REQUEST:
      return { ...state, loading: true };

    case consultantConstants.SET_DEFAULT_CONSULTANT_SUCCESS:
      return {
        ...state,
        favorite_consultant: action.consultant.code,
        loading: false,
      };
    case consultantConstants.SET_DEFAULT_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case consultantConstants.DELETE_CONSULTANT_SUCCESS:
      let deletedconsultant = action.consultant;
      let cindex = state.consultants.findIndex(
        (ob) => ob.id === deletedconsultant.id
      );
      // state.consultants.splice(cindex, 1);

      state.consultants[cindex].deleted = deletedconsultant.deleted;

      return { ...state, consultants: [...state.consultants], loading: false };
    case consultantConstants.DELETE_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case consultantConstants.SEARCH_ALL_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case consultantConstants.SEARCH_ALL_CONSULTANT_SUCCESS:
      return {
        ...state,
        consultants: action.data.consultants,
        favorite_consultant: action.data.favorite_consultant,
        loading: false,
      };
    case consultantConstants.SEARCH_ALL_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
