import {
    getWardNamesConstant
} from '../constants'


const getWardNamesReducer = (state = {}, action) =>{

       
      
       switch(action.type){
           case getWardNamesConstant.GETALL_WARDNAMES_REQUEST :
               
               return {
                   ...state, loading :true ,
               };

           case getWardNamesConstant.GETALL_WARDNAMES_SUCCESS :
               
                       
                       return{
                           
                           ...state, data :  action.data   ,
                       } 
                       
           case  getWardNamesConstant.GETALL_WARDNAMES_FAIL :
             
                   return {
                       ...state,
                       loading :false,
                       error : action.error,
                   }            


                      

           default:
                 return state;          
       }

};

export {getWardNamesReducer};