import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "../../../helpers/IconHelper";
import { Col, Row, Button, Card, CardBody } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { registerservice } from "../../../services/register.service";
import { useHistory } from "react-router-dom";

const Register = () => {
  const history = useHistory();
  const initialValues = {
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    check: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .min(6, "Company Name must be at least 6 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*[!@#$%^&*()_+{}":;'<>?,./])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    check: Yup.boolean().oneOf([true], "Must accept terms and conditions"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    values.userName = values.email;
    registerservice
      .register(values)
      .then((data) => {
        if (data.isSuccess) {
          console.log("in toast")
          toast.success("You have successfully created your account. Please login.", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          history.push("/login");
        }
        else {
          toast.error(data.validationErrors[0].errorMessage, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      })
      .catch((err) => {
        toast.error("The user with same details already existed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="h-100">
      <Row className="h-100 no-gutters">
        <Col lg="4" className="d-none d-lg-block">
          <div className="slider-light">
            <img
              src={Icons.Dna}
              style={{ backgroundSize: "cover" }}
              alt="DNA Icon"
            />
          </div>
        </Col>
        <Col
          md="8"
          className="h-100 d-flex justify-content-center align-items-center"
        >
          <Col lg="9" md="7" sm="12" className="mx-auto">
            <Card>
              <CardBody>
                <div className="logo logoWrapper">
                  <div
                    className="clientLogo"
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <img src={Icons.Logo} alt="logo" />
                    <div style={{ position: "relative", top: -16, right: 10 }}>
                      <b>V1.3.0</b>
                    </div>
                  </div>
                  <div className="dariaLogo">
                    <h4>AQUA</h4>
                  </div>
                </div>
                <h6>
                  Welcome, It only takes a few seconds to create your account
                </h6>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label htmlFor="companyName">Company Name</label>
                            <Field
                              type="text"
                              name="companyName"
                              id="companyName"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="companyName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label htmlFor="confirmPassword">
                              Confirm Password
                            </label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="form-group">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            name="check"
                            id="check"
                            className="form-check-input"
                          />
                          <label htmlFor="check" className="form-check-label">
                            Accept our <a href="#">Terms and Conditions</a>.
                          </label>
                          <ErrorMessage
                            name="check"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="mt-4 d-flex align-items-center">
                        <h6 className="mb-0">
                          Already have an account?{" "}
                          <Link to="/login" className="text-primary">
                            Sign in
                          </Link>
                        </h6>
                        <div className="ml-auto">
                          <Button
                            type="submit"
                            color="primary"
                            className="btn-wide btn-pill btn-shadow btn-hover-shine"
                            size="lg"
                            disabled={isSubmitting}
                          >
                            {isSubmitting
                              ? "Creating Account..."
                              : "Create Account"}
                          </Button>
                        </div>
                        <ToastContainer />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
