import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./loader.css";
class MyLoader extends Component {

    render() {
            
        return (
            <div class="overlay">
                <div id="loading-img">
                    <Loader
                        type="Audio"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
            </div>
            
        );
    }
}
export default MyLoader;