import moment from 'moment';
import React from 'react';
import { Table } from 'reactstrap';
import { apiUrl } from '../../../constants/config';

class PatientInfo extends React.Component
{
  constructor(props)
  {
    super(props);  
   this.state={selectedReferral:null}   
  }

  componentDidMount()
  {
    const {referrals,selected_patient}=this.props;
    if(selected_patient)
   {  
  
   }
  }
  codeToValue(code,name)
  {
    try{
      const {masters}=this.props
      if(masters)
      return masters[name].list.find(item=>item.code===code).name
      else return code;
        }
        catch{
          return code;
        }
  }
 
  render(){
        const selected_patient=this.props.patient;
         
      return ( <>
 
          <Table className="ot-booking-table">
<tr>
    <td className="booking-label">Patient UHID</td>
    <td className="booking-info">{selected_patient.patient_uhid}</td>
  
</tr>


<tr>
    <td className="booking-label">Registration Date</td>
    <td className="booking-info">{moment(selected_patient.reg_date).format('DD/MM/YYYY')}</td>
</tr>
<tr>
    <td className="booking-label">Registration Date</td>
    <td className="booking-info">{moment(selected_patient.reg_date).format('DD/MM/YYYY')}</td>
</tr>
<tr>
    <td className="booking-label">Patient Name</td>
    <td className="booking-info">{this.codeToValue( selected_patient.title_code,'titles')} { selected_patient.firstname +" "+(selected_patient.middlename||"")+" "+(selected_patient.lastname||"")}</td>
</tr>
<tr>
    <td  className="booking-label">Photo</td>
    <td>  <div
                      style={{
                        width: "120px",
                        height: "120px"
                      }}
                    >
                      <img
                        src={apiUrl+selected_patient.photo}
                        style={{ width: "100%", height: 'auto',border:'1px solid black',maxWidth:100 }}
                      />              
                    </div></td>
</tr>

<tr>
    <td className="booking-label">Gender</td>
    <td className="booking-info">{this.codeToValue( selected_patient.gender_code,'genders')}</td>
</tr>
<tr>
    <td className="booking-label">Marital Status</td>
    <td className="booking-info"> {this.codeToValue( selected_patient.marital_status,'marital_statuses')}</td>
</tr>
<tr>
    <td className="booking-label">Date of birth </td>
    <td className="booking-info"> {moment(selected_patient.dob).format('DD/MM/YYYY')}    </td>
</tr>
<tr>
    <td className="booking-label">Age </td>
    <td className="booking-info"> {selected_patient.age_in_years?<>{selected_patient.age_in_years} &nbsp; <b> Y </b></>:""}&nbsp; 
                    {selected_patient.age_in_months?<>{selected_patient.age_in_months}&nbsp;<b> M</b></>:""} &nbsp;  
                    {selected_patient.age_in_days?<>{selected_patient.age_in_days}&nbsp;<b>D</b></>:""}    </td>
</tr>
<tr>
    <td className="booking-label">Mobile </td>
    <td className="booking-info"> {selected_patient.mobile}    </td>
</tr>
<tr>
    <td className="booking-label">Email </td>
    <td className="booking-info"> {selected_patient.email}    </td>
</tr>
<tr>
    <td className="booking-label">Address </td>
    <td className="booking-info"> {selected_patient.permanent_address}    </td>
</tr>
<tr>
    <td className="booking-label">Nationality/Religion </td>
    <td className="booking-info"> {this.codeToValue(selected_patient.nationality_code,'nationalities')}/  {this.codeToValue( selected_patient.religion_code,'religions')}   </td>
</tr>
<tr>
    <td className="booking-label">Occupation / Bloodgroup </td>
    <td className="booking-info">  {this.codeToValue(selected_patient.occupation,'occupations')} /  {this.codeToValue(selected_patient.blood_group_code,'bloodgroups')}   </td>
</tr>
<tr>
    <td className="booking-label">Relative Info</td>
    <td className="booking-info"> {selected_patient.relative_name} /  {selected_patient.secondary_contact}  </td>
</tr>
<tr>
    <td className="booking-label">ID Type / Number </td>
    <td className="booking-info"> {this.codeToValue(selected_patient.id_type1,'identifications')} &nbsp;[ {selected_patient.id_number1}] 
    <br/>
    <img src={apiUrl+selected_patient.id_document1} style={{height:100}}/></td>
</tr>
<tr>
    <td className="booking-label">Referral Source</td>
    <td className="booking-info"> {this.codeToValue(selected_patient.reason_of_knowing,'how_did_you_know_us')}  </td>
</tr>
      </Table>
    
      
        </>  )
}}
export default PatientInfo;