import React from 'react'
import {

    Button

} from 'reactstrap';

const getNavStates = (indx, length) => {
    let styles = []
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push('done')
        } else if (i === indx) {
            styles.push('doing')
        } else {
            styles.push('todo')
        }
    }
    return {current: indx, styles: styles}
}

const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            showPreviousBtn: true,
            showNextBtn: true,
            showSaveBtn:false,
            showTransactionsBtn:false
        }
    } else if (currentStep === 0) {
        return {
            showPreviousBtn: false,
            showNextBtn: false,
            showSaveBtn:true,
            showTransactionsBtn:false
        }
    } else {
        return {
            showPreviousBtn: true,
            showNextBtn: false,
            showSaveBtn:false,
            showTransactionsBtn:false
        }
    }
}

export default class MultiStep extends React.Component {
    state = {
        showPreviousBtn: false,
        showNextBtn: false,
        compState: 0,
        showSaveBtn:true,
        navState: getNavStates(0, this.props.steps.length)
    }

    setNavState = next => {
        this.setState({
            navState: getNavStates(next, this.props.steps.length)
        })
        if (next < this.props.steps.length) {
            this.setState({compState: next})
        }
        this.setState(checkNavState(next, this.props.steps.length))
    }


    handleOnClick = evt => {
        if (
            evt.currentTarget.value === this.props.steps.length - 1 &&
            this.state.compState === this.props.steps.length - 1
        ) {
            this.setNavState(this.props.steps.length)
        } else {
            this.setNavState(evt.currentTarget.value)
        }
    }

    save = () => {
        this.setState({showTransactionsBtn:true})
    }

    next = () => {
        this.setNavState(this.state.compState + 1)
    }

    previous = () => {
        if (this.state.compState > 0) {
            this.setNavState(this.state.compState - 1)
        }
    }

    getClassName = (className, i) => {
        return className + '-' + this.state.navState.styles[i]
    }

    renderSteps = () => {
        return this.props.steps.map((s, i) => (
            <li
                className={this.getClassName('form-wizard-step', i)}
                onClick={this.handleOnClick}
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span>{this.props.steps[i].name}</span>
            </li>
        ))
    }

    render() {
        return (
            <div onKeyDown={this.handleKeyDown}>
                {/* <ol className='forms-wizard'>
                    {this.renderSteps()}
                </ol> */}
                {this.props.steps[this.state.compState].component}
               
               
                <div className="clearfix">
                    
                </div>
            </div>
        )
    }
}

MultiStep.defaultProps = {
    showNavigation: true
}
