import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const menuService = {
  getAll,
  getRoleBased,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/menus`, requestOptions).then(
    helperService.handleResponse
  );
}

function getRoleBased(role) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
    //body: JSON.stringify({ role: role }),
  };
  return fetch(`${apiUrl}/Users/GetPermissions`, requestOptions).then(
    helperService.handleResponse
  );
}
