import React, { useState, useEffect, Fragment } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast, Bounce } from "react-toastify";
import { faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { storageService, loginservice } from "../../../services";
import { apiUrl } from "../../../constants/config";
import { Icons } from "../../../helpers/IconHelper";

const PasswordChangeSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("Old password is required")
    .min(6, "Old password must have 6 characters"),
  new_password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .matches(/(?=.*\d)/, "Password must contain at least one digit")
    .matches(
      /(?=.*[!@#$%^&*()_+{}":;'<>?,./])/,
      "Password must contain at least one special character"
    )
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function UserBox(props) {
  const [state, setState] = useState({
    active: false,
    imageUrl: null,
    name: null,
    email: null,
    designation: null,
    resetPasswordFlow: false,
    hidden: true,
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  useEffect(() => {
    loginservice.getUserDetails().then(
      (result) => {
        if (result) {
          setState((prevState) => ({
            ...prevState,
            name: result.userName,
            email: result.email,
            designation: result.roles,
          }));
        }
      },
      (error) => console.error("Error fetching user details:", error)
    );
  }, []);

  const toggleShow = () => {
    setState((prevState) => ({ ...prevState, hidden: !prevState.hidden }));
  };

  const onChangePassword = () => {
    setState((prevState) => ({
      ...prevState,
      resetPasswordFlow: !prevState.resetPasswordFlow,
    }));
  };

  const logout = () => {
    storageService.removeData("authorization");
    window.location.replace("./");
  };

  let orgData = sessionStorage.getItem("orgInfo");
  if (orgData) {
    orgData = JSON.parse(orgData);
  }

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  {state.imageUrl ? (
                    <img
                      width={42}
                      className="rounded-circle"
                      src={apiUrl + "/" + state.imageUrl}
                      alt=""
                    />
                  ) : (
                    <img
                      width={42}
                      className="rounded-circle"
                      src={Icons.MaleIcon}
                      alt=""
                    />
                  )}
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-1"
                        style={{
                          backgroundImage: "url(" + Icons.Abstract9 + ")",
                        }}
                      />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img
                                width={42}
                                className="rounded-circle"
                                src={
                                  state.imageUrl
                                    ? apiUrl + "/" + state.imageUrl
                                    : Icons.MaleIcon
                                }
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">{state.name}</div>
                              <div className="widget-subheading">
                                {state.email}
                              </div>
                            </div>
                            <div className="widget-content-right mt-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "0.5rem",
                      }}
                    >
                      <Button
                        className="btn-shadow btn-shine"
                        color="danger"
                        onClick={logout}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                  <Nav vertical className="text-dark">
                    <NavItem>
                      <NavLink href="#" onClick={onChangePassword}>
                        Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{state.name}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={state.resetPasswordFlow} toggle={onChangePassword}>
        <ModalHeader toggle={onChangePassword}>Change Password</ModalHeader>
        <Formik
          initialValues={{
            old_password: "",
            new_password: "",
            confirm_password: "",
          }}
          validationSchema={PasswordChangeSchema}
          onSubmit={(values, { setSubmitting }) => {
            loginservice
              .ChangePassword({
                oldPassword: values.old_password,
                newPassword: values.new_password,
                confirmPassword: values.confirm_password,
              })
              .then((data) => {
                setSubmitting(false);
                if (data.isSuccess) {
                  toast("Password changed successfully", {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: "bottom-center",
                    type: "success",
                  });
                  onChangePassword();
                }
                if (!data.isSuccess) {
                  toast("Old Password not matched", {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: "top-center",
                    type: "error",
                  });
                } else {
                  toast(data.errors, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: "bottom-center",
                    type: "error",
                  });
                }
              });
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <ModalBody>
                <Row className="flex-column">
                  <Col md={6} className="mx-auto mb-2">
                    <Label for="old_password">Enter Old Password</Label>
                    <Field
                      name="old_password"
                      type={state.hidden ? "password" : "text"}
                      as={Input}
                    />
                    {errors.old_password && touched.old_password ? (
                      <div className="text-danger">{errors.old_password}</div>
                    ) : null}
                  </Col>
                  <Col md={6} className="mx-auto mb-2">
                    <Label for="new_password">New Password</Label>
                    <Field
                      name="new_password"
                      type={state.hidden ? "password" : "text"}
                      as={Input}
                    />
                    {errors.new_password && touched.new_password ? (
                      <div className="text-danger">{errors.new_password}</div>
                    ) : null}
                  </Col>
                  <Col md={6} className="mx-auto">
                    <Label for="confirm_password">Confirm Password</Label>
                    <InputGroup>
                      <Field
                        name="confirm_password"
                        type={state.hidden ? "password" : "text"}
                        as={Input}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="secondary" onClick={toggleShow}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.confirm_password && touched.confirm_password ? (
                      <div className="text-danger">
                        {errors.confirm_password}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={onChangePassword}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Change Password
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  selected_patient: state.patients.selected_patient,
});

export default connect(mapStateToProps)(UserBox);
