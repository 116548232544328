import { customerConstants } from '../constants';
import { alertActions } from './alert.actions';
import {customerService} from '../services/customer.service';
import {history} from '../helpers/history';
import {
  toast,
  Bounce
} from 'react-toastify';


export const  customerActions = {  
  getTenantDetails
};

function getAllCustomers() {
  return dispatch => {
      dispatch(request());
      customerService.getAllCustomers()
          .then(
              data =>{
                if(data.success)
                {
                  dispatch(success(data.decrupted_data)) 
                  dispatch(alertActions.success('Customers loaded'))
                  
                }
                 else dispatch(failure(data.err))
                },
              error => dispatch(failure(error.toString()))
          );
  };

  function request() { return { type: customerConstants.GET_ALL_CUSTOMERS_REQUEST  } }
  function success(data) { return { type: customerConstants.GET_ALL_CUSTOMERS_SUCCESS, data } }
  function failure(error) { return { type: customerConstants.GET_ALL_CUSTOMERS_FAILURE, error } }
}

function getTenantDetails() {
  return dispatch => {
      dispatch(request());
      customerService.getTenantDetails()
          .then(
              data =>{
                if(data.isSuccess)
                {
                  dispatch(success(data.value[0])) 
                  dispatch(alertActions.success('Customer loaded'))
                  
                }
                 else dispatch(failure(data.err))
                },
              error => dispatch(failure(error.toString()))
          );
  };

  function request() { return { type: customerConstants.GET_CUSTOMER_BY_ID_REQUEST  } }
  function success(data) { return { type: customerConstants.GET_CUSTOMER_BY_ID_SUCCESS, data } }
  function failure(error) { return { type: customerConstants.GET_CUSTOMER_BY_ID_FAILURE, error } }
}