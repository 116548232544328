//import { authHeader } from '../store';
import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const drugPurchaseService = {
  getAll,
  getSupplierGRN,
  add,
  getReports,
  addopenstock,
  update,
  updateDue,
  getreportByDate,
  remove,
  get,
  search,
  getreportSearch,
  getByProductId,
  getByDate,
  getByDateAndStatus,
  getTaxWiseSummary,
  returnBill,
  GrnDateSearch
};

function getAll(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase/previousmonth?${query}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getReports() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchase/report`, requestOptions).then(
    helperService.handleResponse
  );
}
function getreportByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase/report?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getreportSearch(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchase?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getSupplierGRN(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase/supplier?${query}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchase/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getTaxWiseSummary(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase/taxWiseSummary?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase`, requestOptions).then(
    helperService.handleResponse
  );
}
//for open stock
function addopenstock(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase/openStock`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase/update`, requestOptions).then(
    helperService.handleResponse
  );
}
function updateDue(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase/updateDue`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function search(name) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchaseItem/search?name=${name}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByProductId(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchaseItem/getByProductId?id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function returnBill(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchase/returnBill`, requestOptions).then(
    helperService.handleResponse
  );
}
function getByDateAndStatus(from, to,status) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase?from=${from}&to=${to}&status=${status}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function GrnDateSearch(query,from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchase/previousmonth?previous_month=${query}&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}