import React, { Component, Fragment } from 'react';

import moment from 'moment'
import {
    Row, Col,
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Table,
    Card, CardBody, CardTitle, Form,
    FormGroup, Label,
    Input,
    CardHeader,

} from 'reactstrap';
import {
    faCalendarAlt, faSearch, faWindowClose,

} from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";

import {
    AreaChart, Area, LineChart, Line,
    ResponsiveContainer,
    BarChart, Bar,
    ComposedChart,
    CartesianGrid
} from 'recharts';

import {
    faAngleUp,
    faAngleDown,
    faArrowLeft,
    faArrowRight,
    faEllipsisH,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { sweetAlertActions, registrationMasterActions, serviceMasterActions, departmentActions } from '../../../actions';
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import AppFooter from "../../../Layout/AppFooter";
import PageTitle from '../PageTitle';
import {apiUrl} from '../../../constants';
import DataTable from 'react-data-table-component';
import CustomButtons from '../../Components/customebuttons';
import NumberFormat from 'react-number-format';
import { Icons } from '../../../helpers/IconHelper';



class ViewServiceMaster1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverResponse: null,
            type: "services"
        }
        this.componentRef =  React.createRef();
        this.onChange = this.onChange.bind(this);
        this.addNavigation = this.addNavigation.bind(this)
        this.filterMap = this.filterMap.bind(this)
    }
    componentDidMount() {
        let { dispatch } = this.props;
        dispatch(registrationMasterActions.getAll());
        dispatch(serviceMasterActions.getAllPackages());
        dispatch(departmentActions.getAll());

    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    addNavigation() {
        this.props.history.push({
            pathname: `${this.props.match.url}/add`,
            state :{
                type : this.state.type
            }

        })
    }

    filterMap(e) {
        this.setState({
            filterText: e.target.value
        })
    }

    handleActiveChange(data,index,type) {
        let {dispatch} = this.props;
        let payload = {
            _id: data._id,
            type: type
        };
        dispatch(serviceMasterActions.deactiveServices(payload,type,index))
    }

    deleteBtn_Click(data,index,type){
        let {dispatch} = this.props;
        let payload = {
            _id: data._id,
            type: type
        };
        dispatch(serviceMasterActions.deleteServices(payload,type,index))
    }
	
    editBtn_Click(data, type) {
        this.props.history.push({
            pathname: `${this.props.match.url}/add`,
            state: {
                formData: data,
                type: type,
                flow: "edit"
            }

        })

    }


    deptcodeToValue(code){
        try{
            const {departments}=this.props
            if(departments)
                return departments.find(item=>item.code===code).name
            else return code;
        }
        catch{
            return code;
        }
    }


    subdeptcodeToValue(deptcode, subdeptcode){
        try{
            const {departments}=this.props
            if(departments){
                let department = departments.find(item=>item.code===deptcode );
                return department.subdepartments.find(item=>item.code === subdeptcode).name;
            }
            else return subdeptcode;
        }
        catch{
            return subdeptcode;
        }
    }



    render() {
        let { serviceMaster } = this.props;
        let serviceMaster1 = { ...serviceMaster };
        let serverResponse = null;
        let packagesRow = null;
        let serviceRow = null;
        let packageCSVData;
        let serviceCSVData;
        let groupCSVData;
        let packageList = [
        ];
        let  serviceList= [
        ];
        let groupList = [];
        let serviceColumns = [
            {
                name: 'SR. No',
                sortable: true,
                selector:(data)=>(data.count),
                width:"100px"
              },
            {
                name: 'Service Name',
                sortable: true,
                selector:(data)=>( `${data.SERVICE_NAME}`),
                width:"225px"
              },
              {
                name: 'Department',
                sortable: true,
                selector:(data)=>( `${this.deptcodeToValue(data.D_CODE)}`),
                width:"175px"
              },
              {
                name: 'Sub Dept.',
                sortable: true,
                selector:(data)=>( `${data.SD_CODE?this.subdeptcodeToValue(data.D_CODE,data.SD_CODE):""}`),
                width:"175px"
              },
              {
                name: 'Service Amount',
                sortable: true,
                cell:(data)=>(  <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} displayType={'text'} value={data.SERVICE_AMT}/>),
                width:"150px"
              },

              {
                name: 'Active',
                sortable: true,
                selector:(data)=>(
                    <FormGroup>
                                        <Switch
                                           onChange={(e) => { this.handleActiveChange(data, data.index, "services") }}
                                            checked={data.isActive}
                                             className="mr-2 mb-2"
                                          id="normal-switch"
                                      />
                                     </FormGroup>
                ),
                width:"100px"
              },

              {
                name: 'Actions',
                sortable: true,
                width:'150px',
                cell:data=><>
               <CustomButtons type="edit" title="Edit Services" width="40px" onClickHandler= {() => { this.editBtn_Click(data, "services") }} />
               {data.isDeleted ? <CustomButtons type="restore" title = "Reactive Services" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "services")}} width="40px" />:<CustomButtons type="delete" title = "Delete Services" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "services")}} width="40px" />}
                                   
                {/* <a style={{padding:3,margin:2}} onClick={()=>{this.onEditClicked(row)}} title="Edit"  className="text-warning font-icon-wrapper "> <FontAwesomeIcon icon={faUserEdit} size="1x"/></a>
                        
                <a style={{padding:3,margin:2}}  onClick={()=>{this.onViewClicked(row)}}  title="View" className="text-primary font-icon-wrapper "> <FontAwesomeIcon icon={faEye} size="1x"/>  </a>                
                  <a style={{padding:'3px 5px',margin:2}}  onClick={()=>{this.onDeleteClicked(row)}}  title="Delete" className="text-danger font-icon-wrapper "> <FontAwesomeIcon icon={faTrash} size="1x"/>  </a>
                 */}
                 </>
            },
        ];
        let packageColumns = [
            {
                name: 'SR. No',
                sortable: true,
                selector:(data)=>(data.count),
                width:"100px"
              },
            {
                name: 'Package Name',
                sortable: true,
                selector:(data)=>( `${data.PACKAGE_NAME}`),
                width:"225px"
              },
              {
                name: 'Department',
                sortable: true,
                selector:(data)=>( `${this.deptcodeToValue(data.D_CODE)}`),
                width:"175px"
              },
              {
                name: 'Sub Dept.',
                sortable: true,
                selector:(data)=>( `${data.SD_CODE?this.subdeptcodeToValue(data.D_CODE,data.SD_CODE):""}`),
                width:"175px"
              },
              {
                name: 'Package Amount',
                sortable: true,
                cell:(data)=>(  <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} displayType={'text'} value={data.PACKAGE_AMT}/>),
                width:"100px"
              },
              {
                name: 'Active',
                sortable: true,
                selector:(data)=>(
                    <FormGroup>
                                        <Switch
                                           onChange={(e) => { this.handleActiveChange(data, data.index, "packages") }}
                                            checked={data.isActive}
                                             className="mr-2 mb-2"
                                          id="normal-switch"
                                      />
                                     </FormGroup>
                ),
                width:"100px"
              },

              {
                name: 'Actions',
                sortable: true,
                width:'150px',
                cell:data=><>
               <CustomButtons type="edit" title="Edit Packages" width="40px" onClickHandler= {() => { this.editBtn_Click(data, "packages") }} />
                {data.isDeleted?<CustomButtons type="restore" title = "Delete Packages" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "packages")}} width="40px" />:<CustomButtons type="delete" title = "Delete Packages" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "packages")}} width="40px" />}
                                   
                {/* <a style={{padding:3,margin:2}} onClick={()=>{this.onEditClicked(row)}} title="Edit"  className="text-warning font-icon-wrapper "> <FontAwesomeIcon icon={faUserEdit} size="1x"/></a>
                        
                <a style={{padding:3,margin:2}}  onClick={()=>{this.onViewClicked(row)}}  title="View" className="text-primary font-icon-wrapper "> <FontAwesomeIcon icon={faEye} size="1x"/>  </a>                
                  <a style={{padding:'3px 5px',margin:2}}  onClick={()=>{this.onDeleteClicked(row)}}  title="Delete" className="text-danger font-icon-wrapper "> <FontAwesomeIcon icon={faTrash} size="1x"/>  </a>
                 */}
                 </>
            },
        ];
        let groupsColumns = [
            {
                name: 'SR. No',
                sortable: true,
                selector:(data)=>(data.count),
                width:"100px"
              },
            {
                name: 'Group Name',
                sortable: true,
                selector:(data)=>( `${data.GROUP_NAME}`),
                width:"225px"
              },
              {
                name: 'Department',
                sortable: true,
                selector:(data)=>( `${this.deptcodeToValue(data.D_CODE)}`),
                width:"175px"
              },
              {
                name: 'Sub Dept.',
                sortable: true,
                selector:(data)=>( `${data.SD_CODE?this.subdeptcodeToValue(data.D_CODE,data.SD_CODE):""}`),
                width:"175px"
              },
              {
                name: 'Group Amount',
                sortable: true,
                cell:(data)=>(  <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} displayType={'text'} value={data.GROUP_AMT}/>),
                width:"100px"
              },
              {
                name: 'Active',
                sortable: true,
                selector:(data)=>(
                    <FormGroup>
                                        <Switch
                                           onChange={(e) => { this.handleActiveChange(data, data.index, "groups") }}
                                            checked={data.isActive}
                                             className="mr-2 mb-2"
                                          id="normal-switch"
                                      />
                                     </FormGroup>
                ),
                width:"100px"
              },

              {
                name: 'Actions',
                sortable: true,
                width:'150px',
                cell:data=><>
               <CustomButtons type="edit" title="Edit Packages" width="40px" onClickHandler= {() => { this.editBtn_Click(data, "groups") }} />
                {data.isDeleted?<CustomButtons type="restore" title = "Delete Packages" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "groups")}} width="40px" />:<CustomButtons type="delete" title = "Delete Groups" onClickHandler={()=>{this.deleteBtn_Click(data, data.index, "groups")}} width="40px" />}
                                   
                {/* <a style={{padding:3,margin:2}} onClick={()=>{this.onEditClicked(row)}} title="Edit"  className="text-warning font-icon-wrapper "> <FontAwesomeIcon icon={faUserEdit} size="1x"/></a>
                        
                <a style={{padding:3,margin:2}}  onClick={()=>{this.onViewClicked(row)}}  title="View" className="text-primary font-icon-wrapper "> <FontAwesomeIcon icon={faEye} size="1x"/>  </a>                
                  <a style={{padding:'3px 5px',margin:2}}  onClick={()=>{this.onDeleteClicked(row)}}  title="Delete" className="text-danger font-icon-wrapper "> <FontAwesomeIcon icon={faTrash} size="1x"/>  </a>
                 */}
                 </>
            },
        ];
        if (serviceMaster) {
            if (!serviceMaster.loading) {
                if (serviceMaster.data) {
                    if(serviceMaster.data.packages){
                        let index = -1;
                        let count =0;
                        packageCSVData = serviceMaster.data.packages;
                        serviceMaster.data.packages.forEach(data => {
                            index = index + 1;
                            data.index = index;
                            if (this.state.filterText) {
                                if (data.USERDEFINED_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || this.deptcodeToValue(data.D_CODE).toLowerCase().includes(this.state.filterText.toLowerCase()) || data.PACKAGE_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || (data.SD_CODE?this.subdeptcodeToValue(data.D_CODE,data.SD_CODE):"").toLowerCase().includes(this.state.filterText.toLowerCase()) || (data.PACKAGE_AMT?data.PACKAGE_AMT:0).toString().toLowerCase().includes(this.state.filterText.toLowerCase())) {
                                    count= count+1;
                                    data.count = count;
                                    packageList.push(data)
                                }
                            }
                            else{
                                count= count+1;
                                data.count = count;
                                packageList.push(data);
                            }
                        });
                    }
                    if(serviceMaster.data.services){
                        serviceCSVData=serviceMaster.data.services;
                        let index = -1;
                        let count = 0;
                        serviceMaster.data.services.forEach(data => {
                            index = index + 1;
                            data.index = index;
                            if (this.state.filterText) {
                                if (data.USERDEFINED_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || this.deptcodeToValue(data.D_CODE).toLowerCase().includes(this.state.filterText.toLowerCase()) || data.SERVICE_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || (data.SD_CODE?this.subdeptcodeToValue(data.D_CODE,data.SD_CODE):"").toLowerCase().includes(this.state.filterText.toLowerCase()) || (data.SERVICE_AMT?data.SERVICE_AMT:0).toString().toLowerCase().includes(this.state.filterText.toLowerCase())) {
                                    count= count+1;
                                    data.count = count;
                                    serviceList.push(data)
                                }
                            }
                            else{
                                count= count+1;
                                data.count = count;
                                serviceList.push(data)
                            }    
                        })
                    
                    }
                    if(serviceMaster.data.groups){
                        groupCSVData=serviceMaster.data.groups;
                        let index = -1;
                        let count = 0;
                        serviceMaster.data.groups.forEach(data => {
                            index = index + 1;
                            data.index = index;
                            if (this.state.filterText) {
                                if (data.USERDEFINED_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || this.deptcodeToValue(data.D_CODE).toLowerCase().includes(this.state.filterText.toLowerCase()) || data.GROUP_NAME.toLowerCase().includes(this.state.filterText.toLowerCase()) || ((data.SD_CODE?this.subdeptcodeToValue(data.SD_CODE,data.D_CODE):"").toLowerCase().includes(this.state.filterText.toLowerCase())) || data.GROUP_AMT.toString().toLowerCase().includes(this.state.filterText.toLowerCase())) {
                                    count= count+1;
                                    data.count = count;
                                    groupList.push(data)
                                }
                            }
                            else{
                                count= count+1;
                                data.count = count;
                                groupList.push(data)
                            }    
                        })
                    
                    }

                }


            }
        }
       
        console.log(serverResponse)
        return (
            <Fragment>

                <PageTitle icon={Icons.PatientRegistration} heading={this.state.type==="services"?"IPD Service List" : 
                this.state.type==="packages" ?
                "IPD Package List" : "IPD Group List" }
                  printComponent={this.componentRef}
                 data={this.state.type==="services"?serviceCSVData:packageCSVData} csvFilename={this.state.type==="services"?"Servicelist.csv":"Packagelist.csv"}/>
                 
                <Card>
                    <CardHeader>
                        <Row style={{ width: "100%" }}>
                            <Col md="3">

                                <Input type="select" name="type" id="type" onChange={(e) => { this.onChange(e) }}>
                                    <option value="services">Service</option>
                                    <option value="packages">Package</option>
                                    <option value="groups">Groups</option>
                                </Input>
                            </Col>
                            <Col md="3" style={{textAlign : "center", fontWeight: "bold"}}>
                                {/* {this.state.type == "packages" ? "Package Master" : "Service Master"} */}
                            </Col>
                            <Col md="3">
                                <Input name="search" placeholder="search" onChange={this.filterMap} 
                                
                                >
                                   
                                </Input>
                                 <FontAwesomeIcon style={{fontSize: '20px',
                                    marginTop:" -30px",
                                    position:'absolute',
                                    marginLeft:"204px"
                                    }} icon={faSearch} size="1x"/>
                            </Col>
                            <Col md="3">
                                <Label> </Label>
                                <Button style={{width:"200px",float: "right"}} color="primary" onClick={this.addNavigation}>Add New</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div ref={el => (this.componentRef = el)}>
                            <DataTable 
                            keyField="_id"
                            pagination={true}
                            expandableRows={false}
                            expandOnRowClicked={false}
                            dense
                            noHeader
                            striped
                            paginationPerPage={25}
                            paginationRowsPerPageOptions={[25,50,75,100]}
                            columns={this.state.type==="services"?serviceColumns:
                            this.state.type==="groups"? groupsColumns :
                            packageColumns} 
                            data={this.state.type === "services"? serviceList:
                            this.state.type === "groups"? groupList :
                            packageList}/>
                       </div>
                    </CardBody>
                </Card>


            </Fragment>

        )
    }

}

function mapStateToProps(state) {
    const { serviceMaster,departments } = state;
    return { serviceMaster: serviceMaster,
        departments:departments.departments
    };
}

const ViewServiceMaster = connect(mapStateToProps)(ViewServiceMaster1);

export default ViewServiceMaster;
