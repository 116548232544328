import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const drugSalesService = {
  getAll,
  add,
  update,
  updatedue,
  getByIpPatientreport,
  getByOPPatientreport,
  remove,
  get,
  getByDate,
  search,
  getDepartmentWise,
  getTaxWiseSummary,
  returnBill,
  getBilledForWise,
  getAllbydueamount,
  getCustomerWise,
  getAllbycleareddue,
  getduepayment,
  getdueamountByDate,
  getcleareddueByDate
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales`, requestOptions).then(
    helperService.handleResponse
  );
}
function getByIpPatientreport(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales/ip?from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getByOPPatientreport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales/op`, requestOptions).then(
    helperService.handleResponse
  );
}

function getduepayment(from,to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales/payment?from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getAllbydueamount() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales?due_balance=true`, requestOptions).then(
    helperService.handleResponse
  );
}
function getdueamountByDate(from,to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales?due_balance=true&from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getAllbycleareddue() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales?is_cleared_due=true`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getcleareddueByDate(from,to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales?is_cleared_due=true&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getDepartmentWise(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales/departmentWise?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getBilledForWise(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales/billedForWise?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getCustomerWise(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales/customerWise?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getTaxWiseSummary(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales/taxWiseSummary?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function search(from, to, text, bill_no_from, bill_no_to, created_by) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaSales?from=${from}&to=${to}&text=${text}&bill_from=${bill_no_from}&bill_to=${bill_no_to}&created_by=${created_by}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaSales/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaSales`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaSales/update`, requestOptions).then(
    helperService.handleResponse
  );
}
function updatedue(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaSales/updateDue`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaSales/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function returnBill(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaSales/returnBill`, requestOptions).then(
    helperService.handleResponse
  );
}
