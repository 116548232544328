import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { locationService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const countryActions = {
  getAll,
  setSelected,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    locationService.getCountries().then(
      (data) => {
        if (data.success) {
          dispatch(success(data));
          dispatch(alertActions.success("Countries loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_COUNTRY_REQUEST };
  }
  function success(countries) {
    return { type: generalMasterConstants.GET_COUNTRY_SUCCESS, countries };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_COUNTRY_FAILURE, error };
  }
}

function setSelected(id) {
  return (dispatch) => {
    dispatch({ type: generalMasterConstants.SET_SELECTED_COUNTRY_SUCCESS, id });
  };
}
function add(payload) {
  return (dispatch) => {
    dispatch(request());
    locationService.addCountry(payload).then(
      (data) => {
        if (data.success) {
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(success(data.country));
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => {
        toast(error.toString(), {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_COUNTRY_REQUEST };
  }
  function success(country) {
    return { type: generalMasterConstants.ADD_COUNTRY_SUCCESS, country };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_COUNTRY_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    locationService.updateCountry(payload).then(
      (data) => {
        if (data.success) {
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });

          dispatch(success(data.country));
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => {
        toast(error.toString(), {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_COUNTRY_REQUEST };
  }
  function success(country) {
    return { type: generalMasterConstants.UPDATE_COUNTRY_SUCCESS, country };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_COUNTRY_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    locationService.deleteCountry(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(data.country));
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_COUNTRY_REQUEST };
  }
  function success(country) {
    return { type: generalMasterConstants.DELETE_COUNTRY_SUCCESS, country };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_COUNTRY_FAILURE, error };
  }
}
