import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  titleAlign: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  title: {
    textTransform: "uppercase",
    color: "rgba(18, 21, 78, 0.7)",
    fontWeight: "bold",
    fontSize: "0.88rem",
  },
  textFieldOutline: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        top: "-10px",
      },
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
    },
  },
  labelHeader: {
    fontWeight: "600",
    padding: "5px",
    fontSize: "0.88rem",
  },
}));
