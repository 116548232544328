import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const designationService = {
  getAll,
  add,
  update,
  remove,
};

function getAll(ViewDeleted=true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/Designations?ViewDeleted=${ViewDeleted}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/Designations?ViewDeleted=true`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/Designations/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/Designations/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}
