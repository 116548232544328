import { alertActions } from "./alert.actions";
import { loginservice, storageService } from "../services";
import { loginConstant } from "../constants";
import { toast, Bounce } from "react-toastify";

export const loginActions = {
  login,
  generateOtp,
  verifyOTP,
  resetPassword,
};

function login(payload, type) {
  return (dispatch) => {
    dispatch(request());

    loginservice.login(payload, type).then(
      (result) => {
        if (result.isSuccess) {
          if (result.value.token) {
          toast("Logged in successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
            if (result?.value.organization_data) {
              sessionStorage.setItem(
                "orgInfo",
                JSON.stringify(result?.organization_data)
              );
            }

            storageService.setData(
              loginConstant.AUTHORIZATION_HEADER,
              result.value.token
            );
          }

          dispatch(success(type, result.data));

          if (payload.login_type === "consultant") {
            window.location.replace("./consultantHome");
          } else if (payload.login_type === "patient") {
            window.location.replace("./patientHome");
          } else {
            window.location.replace("./");
          }

          dispatch(alertActions.success(result.message));
        } else {
          toast("wrong credentials", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
          dispatch(failure(result.err));
        }
      },
      (error) => {
        toast(error.toString(), {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: loginConstant.LOGIN_REQUEST };
  }
  function success(type, data) {
    return { type: loginConstant.LOGIN_SUCESS, data: { data } };
  }
  function failure(error) {
    return { type: loginConstant.LOGIN_FAILURE, error };
  }
}

function generateOtp(payload, type) {
  return (dispatch) => {
    dispatch(request());
    loginservice.generateOtp(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(type, result));

          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: loginConstant.GENERATE_OTP };
  }
  function success(type, data) {
    return { type: loginConstant.GENERATE_OTP_SUCESS, data };
  }
  function failure(error) {
    return { type: loginConstant.GENERATE_OTP_FAILURE, error };
  }
}

function verifyOTP(payload, type) {
  return (dispatch) => {
    dispatch(request());
    loginservice.verifyOtp(payload, type).then(
      (result) => {
        if (result.success) {
          if (result.token) {
            storageService.setData(
              loginConstant.AUTHORIZATION_HEADER,
              result.token
            );
          }
          if (result.type) {
            if (result.type === "verify_otp_login_flow") {
              setTimeout(() => {
                window.location.replace("./");
              }, 1000);
            }
          }
          dispatch(success(type, result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: loginConstant.VERIFY_OTP };
  }
  function success(type, data) {
    return { type: loginConstant.VERIFY_OTP_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: loginConstant.VERIFY_OTP_FAILURE, error };
  }
}

function resetPassword(payload, type) {
  return (dispatch) => {
    dispatch(request());
    loginservice.resetPassword(payload, type).then(
      (result) => {
        if (result.success) {
          dispatch(success(type, result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          setTimeout(() => {
            window.location.replace("./");
          }, 1000);
        } else {
          dispatch(failure(result.err));

          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: loginConstant.RESET_PASSWORD };
  }
  function success(type, data) {
    return { type: loginConstant.RESET_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: loginConstant.VERIFY_OTP_FAILURE, error };
  }
}
