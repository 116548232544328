import { referralConstants } from "../constants";

export function referrals(state = { loading: false, referrals: [] }, action) {
  switch (action.type) {
    case referralConstants.SELECT_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.SELECT_REFERRAL_SUCCESS:
      let selected_REFERRAL = state.referrals.find(
        (ob) => ob._id === action.referralId
      );
      return { ...state, selected_REFERRAL: selected_REFERRAL, loading: false };

    case referralConstants.SELECT_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case referralConstants.GET_ALL_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.GET_ALL_REFERRAL_SUCCESS:
      return { ...state, referrals: action.referrals, loading: false };
    case referralConstants.GET_ALL_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case referralConstants.ADD_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.ADD_REFERRAL_SUCCESS:
      return {
        referrals: [...state.referrals, action.referral],
        loading: false,
      };
    case referralConstants.ADD_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case referralConstants.UPDATE_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.UPDATE_REFERRAL_SUCCESS:
      let updatedState = action.referral;
      let index = state.referrals.findIndex(
        (ob) => ob.code === updatedState.code
      );
      state.referrals[index] = updatedState;
      return { ...state, referrals: [...state.referrals], loading: false };

    case referralConstants.UPDATE_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    // case referralConstants.DELETE_REFERRAL_REQUEST:
    // return { ...state, loading: true };
    // case referralConstants.DELETE_REFERRAL_SUCCESS:
    //   let deletedreferral = action.referral;
    //   let cindex = state.referrals.findIndex((ob) => ob.code === deletedreferral.code);
    //    state.referrals.splice(cindex,1) ;
    //   return { ...state, referrals: [...state.referrals], loading: false };
    // case referralConstants.DELETE_REFERRAL_FAILURE:
    //   return { ...state, error: action.error, loading: false };

    case referralConstants.DELETE_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.DELETE_REFERRAL_SUCCESS:
      let updateDelete = action.referral;
      let cindex = state.referrals.findIndex(
        (ob) => ob.code === updateDelete.code
      );
      state.referrals[cindex] = updateDelete;
      return { ...state, referrals: [...state.referrals], loading: false };

    case referralConstants.DELETE_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case referralConstants.DEACTIVE_REFERRAL_REQUEST:
      return { ...state, loading: true };
    case referralConstants.DEACTIVE_REFERRAL_SUCCESS:
      let updateActive = action.referral;
      let cindex1 = state.referrals.findIndex(
        (ob) => ob.code === updateActive.code
      );
      state.referrals[cindex1] = updateActive;
      return { ...state, referrals: [...state.referrals], loading: false };

    case referralConstants.DEACTIVE_REFERRAL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case referralConstants.REFERRAL_CLEAR:
      return {};
    default:
      return state;
  }
}
