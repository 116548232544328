import { storeConstants } from '../constants';

export function stores(state = {master_store:null,stores:[]}, action) {

  switch (action.type) {              

      
            case storeConstants.GET_MASTER_STORE:
            return {master_store:action.store};

            case storeConstants.GET_ALL_STORES:
              return {...state,stores:action.stores}
      
         
    default:
      return state
  }
}