import {
  faEdit,
  faEnvelope,
  faMedkit,
  faPen,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { appointmentActions } from "../../actions";
import { apiUrl } from "../../constants/config";
import { styles } from "./ConsultantList/styles";
import { withStyles } from "@material-ui/core";
import { getAppointmentStatus } from "../../constants/appointmentStatus";
import { appointmentStatus } from "../../constants";
import { patientService } from "../../services";
class AppointmentPopup extends Component {
  constructor(props) {
    super(props);
  }

  // On Appointment Update 
  async onUpdate(status, e) {
    const { appointment, dispatch } = this.props;
    appointment.status = status
    console.log(appointment)
    let isBooked = await dispatch(appointmentActions.UpdateAppointmentStatus(appointment));
  }

  onReschedule(e) {
  }

  
  render() {
    const { appointment, consultants,classes } = this.props;
    if (!appointment.patient_id) {
      return <>No data to show</>;
    }

   let patient = appointment.patient_id;
    //let patient = "";
    let consultant =
      consultants &&
      consultants.find((cons) => {
        return cons.id === appointment.consultant_id;
      });
    
      return (
      <Fragment>
        <div>
          <Row>
          {patient.photo ?
            <Col sm='2'>
            <img
                src={`${apiUrl}${patient.photo}`}
                alt="User Image"
                className={classes.appointmentPopImage}
              />
            </Col>
            :""}
            <Col sm="10">
              <Row className="m-0 p-0">
                <Col sm="10">
                  <h4 className="mb-0">
                    {patient.firstname} {patient.middlename} {patient.lastname}{" "}
                  </h4>
                </Col>
                <Col sm="2">
                  <Button
                    color="link"
                    outline
                   className={classes.appointmentEdit}
                  >
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </Button>
                </Col>
              </Row>
              <p className="m-0">UHID : {patient.patient_uhid}</p>
            </Col>
          </Row>
          <hr />
          <p>
            <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> &nbsp;{" "}
            {patient.mobile}
            <br />
            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp;{" "}
            {patient.email}
          </p>
          <hr />
          <p>
            <FontAwesomeIcon icon={faMedkit}></FontAwesomeIcon> &nbsp;
            Appointment with{" "}
            <b>Dr. {consultant ? consultant.firstname : "Dr. Name"}</b> at{" "}
            {moment(appointment.time_slot_from).format("HH:mm a")} for{" "}
            {appointment.slot_duration} mins
          </p>
          <hr />
          <p>Status : {getAppointmentStatus(appointment.status)}</p>
          <Row>
            <Col sm="3">
              <Button
                block
                size="sm"
                color="primary"
                onClick={this.onUpdate.bind(this, appointmentStatus.Engaged)}
              >
                Engage
              </Button>{" "}
            </Col>
            <Col sm="3">
              <Button
                block
                size="sm"
                color="success"
                onClick={this.onUpdate.bind(this, appointmentStatus.Done)}
              >
                Done
              </Button>{" "}
            </Col>
            <Col sm="3">
              <Button
                block
                size="sm"
                color="danger"
                onClick={this.onUpdate.bind(this, appointmentStatus.Cancelled)}
              >
                Cancel
              </Button>
            </Col>
            {/* <Col sm="3">
              <Button
                block
                size="sm"
                color="warning"
                onClick={this.onReschedule.bind(this)}
              >
                Re-schedule
              </Button>
            </Col> */}
          </Row>

          <hr />
          <p>
            {appointment.notes}{" "}
          </p>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, patients } = state;
  return { consultants: consultants.consultants };
}

const connectedAppointmentPopup = connect(mapStateToProps)(AppointmentPopup);
export default withStyles(styles)(connectedAppointmentPopup);
