import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

// Forms

import FormElementsLayouts from "./Elements/Layouts";
import FormElementsControls from "./Elements/Controls";
import FormElementsValidation from "./Elements/Validation";
import { Services } from "./Services";
import Packages from "./Packages";
import Groups from "./Groups";
import MultiStep from "./Elements/Wizard";
import { serviceMasterActions } from "../../actions";
import FormValidation from "./FormValidation";
// Layout

import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import AppFooter from "../../Layout/AppFooter";

class CustomerMasters1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cSelected: [],
      dropdownOpen: false,
      visible: false,
      type: props.location.state ? props.location.state.type : null,
      formData: props.location.state
        ? props.location.state.flow === "edit"
          ? props.location.state
          : null
        : null,
    };
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.save = this.save.bind(this);
    this.addNavigation = this.addNavigation.bind(this);
    this.handleEditFlow = this.handleEditFlow.bind(this);
    this.gettypes = this.gettypes.bind(this);
    this.hide = this.hide.bind(this);
    this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
  }

  addNavigation() {
    //     this.props.history.push({
    //         pathname:`${this.props.match.url}/`
    //     })
    this.props.history.goBack();
  }

  hide() {
    this.setState({
      visible: false,
    });
  }

  handleEditFlow(data) {
    this.setState(
      {
        type: "packages",
        formData: data,
      },
      this.props.history.push({
        pathname: `${this.props.match.url}/add`,
      })
    );
  }

  componentDidMount() {
    if (this.props.location) {
      if (this.props.location.state) {
        if (this.props.location.state.formData) {
          console.log("consoling from service", this.props.location.state);
          this.setState({
            formData: this.props.location.state.formData,
            isCameFromEditFlow: true,
          });
        } else {
          this.setState({
            type: this.props.location.state.type,
          });
        }
      }
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  onCheckboxBtnClick(selected) {
    const index = this.state.cSelected.indexOf(selected);
    if (index < 0) {
      this.state.cSelected.push(selected);
    } else {
      this.state.cSelected.splice(index, 1);
    }
    this.setState({ cSelected: [...this.state.cSelected] });
  }

  formValidation(data) {
    if (data.type === "services") {
      if (
        data.Revenue_Perc_Lab +
          data.Revenue_Perc_OutS +
          data.Revenue_Perc_Hosp !==
          100 &&
        data.SERVICE_AMT !== 0
      ) {
        this.setState({
          errorMessage: "The sum of revenue percentages should be 100",
        });
        return false;
      }
      if (!data.D_CODE) {
        this.setState({
          errorMessage: "Please select the department",
        });
        return false;
      }

      if (data.SERVICE_AMT < 0) {
        this.setState({
          errorMessage: "Please select the amounts",
        });
        return false;
      }
      if (!data.SERVICE_NAME) {
        this.setState({
          errorMessage: "Please enter the names",
        });
        return false;
      }
      if (!data.USERDEFINED_NAME) {
        this.setState({
          errorMessage: "Please enter the user defined name",
        });
        return false;
      }
      if (!data.CCM_CODE) {
        this.setState({
          errorMessage: "Please enter the cost center",
        });
        return false;
      }
    }
    if (data.type === "packages") {
      if (!data.SERVICE_NAME) {
        this.setState({
          errorMessage: "Please enter the names",
        });
        return false;
      }
      if (!data.USERDEFINED_NAME) {
        this.setState({
          errorMessage: "Please enter the user defined name",
        });
        return false;
      }
      if (!data.D_CODE) {
        this.setState({
          errorMessage: "Please select the department",
        });
        return false;
      }
      if (!data.CCM_CODE) {
        this.setState({
          errorMessage: "Please enter the user defined name",
        });
        return false;
      }
    }
    return true;
  }

  save(data) {
    let { dispatch } = this.props;
    data.reporting_type_op = data.reporting_type_ip;
    delete data.deptCode;

    if (data.transactionType === "edit") {
      let validForm = this.formValidation(data);
      if (validForm) {
        if (!data.SERVICE_AMT) {
          data.SERVICE_AMT = 0;
        }
        dispatch(serviceMasterActions.Edit(data));
      } else {
        this.setState({
          visible: true,
        });
      }
    } else {
      let validForm = this.formValidation(data);
      if (validForm) {
        if (!data.SERVICE_AMT) {
          data.SERVICE_AMT = 0;
        }
        dispatch(serviceMasterActions.add(data));
      } else {
        this.setState({
          visible: true,
        });
      }
    }
  }

  gettypes(data) {
    this.setState({
      type: data,
    });
  }

  render() {
    let enableNext;
    let responseObject;
    let { serviceMaster } = this.props;
    if (serviceMaster) {
      console.log(serviceMaster);
      if (serviceMaster.data) {
        if (serviceMaster.data.success) {
          //this.addNavigation();
          enableNext = serviceMaster.data.success;
          responseObject = serviceMaster.data.data;
        }
      }
    }

    const steps = [
      {
        name: "Services",
        component: (
          <Services
            save={this.save}
            addNavigation={this.addNavigation}
            enableNext={enableNext}
            formData={this.state.formData}
            gettypes={this.gettypes}
            type={this.state.type}
          />
        ),
      },
      {
        name: "Packages",
        component: (
          <Packages
            addNavigation={this.addNavigation}
            responseObject={responseObject}
            handleEditFlow={this.handleEditFlow}
            isCameFromEditFlow={this.state.isCameFromEditFlow}
          />
        ),
      },
      { name: "Groups", component: <Groups /> },
    ];

    return (
      <Fragment>
        <Card className="main-card mb-3">
          <CardBody>
            <MultiStep
              showNavigation={true}
              steps={steps}
              enableNext={enableNext}
              type={this.state.type}
            />
          </CardBody>
        </Card>
        <FormValidation
          visible={this.state.visible}
          hide={this.hide}
          errormessage={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { serviceMaster } = state;
  return { serviceMaster: serviceMaster.data };
}

const CustomerMasters = connect(mapStateToProps)(CustomerMasters1);

export default CustomerMasters;
