import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import { Icons } from "../../../helpers/IconHelper";

export default class CustomButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClick() {
    alert("clicked");
  }
  render() {
    return (
      <Fragment>
        {this.props.type === "clear" ? (
          <Button
            title={this.props.title ? this.props.title : "Clear"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img src={Icons.Clear} alt="clear" style={{ width: "40px" }} />
          </Button>
        ) : null}
        {this.props.type === "delete" ? (
          <Button
            title={this.props.title ? this.props.title : "Delete"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Delete}
              alt="Delete"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "save" ? (
          <Button
            title={this.props.title ? this.props.title : "Save"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Save}
              alt="Save"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "search" ? (
          <Button
            title={this.props.title ? this.props.title : "Search"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Search}
              alt="Search"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "view" ? (
          <Button
            title={this.props.title ? this.props.title : "View"}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.View}
              alt="View"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "edit" ? (
          <Button
            title={this.props.title ? this.props.title : "edit"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Edit}
              alt="edit"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "add" ? (
          <Button
            title={this.props.title ? this.props.title : "Add"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Add}
              alt="Add"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}

        {this.props.type === "update" ? (
          <Button
            title={this.props.title ? this.props.title : "Add"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Update}
              alt="Update"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "restore" ? (
          <Button
            title={this.props.title ? this.props.title : "Restore"}
            disabled={this.props.disabled} 
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Restore}
              alt="Restore"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "launch" ? (
          <Button
            title={this.props.title ? this.props.title : "launch"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Launch}
              alt="launch"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "record" ? (
          <Button
            title={this.props.title ? this.props.title : "record"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Record}
              alt="record"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
        {this.props.type === "upload" ? (
          <Button
            title={this.props.title ? this.props.title : "upload"}
            disabled={this.props.disabled}
            onClick={
              this.props.onClickHandler
                ? this.props.onClickHandler
                : this.onClick
            }
            style={{
              border: "none",
              background: "transparent",
              padding: "0px",
              paddingLeft: "5px",
            }}
          >
            <img
              src={Icons.Upload}
              alt="upload"
              style={
                this.props.width
                  ? { width: this.props.width }
                  : { width: "40px" }
              }
            />
          </Button>
        ) : null}
      </Fragment>
    );
  }
}
