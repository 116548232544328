import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Icons } from "../../helpers/IconHelper";
import { Link } from "react-router-dom";
import { Slider } from "react-burgers";

import AppMobileMenu from "../AppMobileMenu";
import { apiUrl } from "../../constants";
import { customerService } from "../../services";

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
      app_logo: null,
      app_name: null,
    };
  }

  componentDidMount = () => {
    // if (!this?.state?.app_logo)
    //   customerService.getNameAndLogo().then((res) => {
    //     if (res.success && res.data) {
    //       this.setState({
    //         app_name: res.data.name,
    //         app_logo: apiUrl + res.data.logo,
    //       });
    //       document.getElementById("favicon").href =
    //         apiUrl + "/cutomerTransimages/favicon.ico";
    //       document.title = res.data.name;
    //     }
    //   });
  };

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    let orgData = sessionStorage.getItem("orgInfo");
    if (orgData) {
      orgData = JSON.parse(orgData);
    }
    return (
      <Fragment>
        <div className="app-header__logo">
          {/* <div className="logo-src" style={{backgroundSize:'contain',backgroundRepeat:'no-repeat',height:"42px"}}/> */}
          <div>
            <div className="navLogo">
              <Link to="/">
                <img src={Icons.Logo} alt="AquaHis" style={{ width: "100%" }} />
              </Link>
            </div>
            {/* <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar}>
                        <Slider 
                         width={26}
                         lineHeight={2}
                         lineSpacing={5}
                         color='#6c757d'
                         active={this.state.active}
                         onClick={() => this.setState({ active: !this.state.active })}/>
                        </div>
                    </div> */}
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
