import React from 'react';
import AppMain from '../../Layout/AppMain';
import SweetAlertContainer from './Components/SweetAlertContainer';


class Main extends React.Component {
    constructor(props) {
        super(props);
    

    }
 

    render() {

        return (
           <>
          
                            <AppMain />
                            
                  
                        <SweetAlertContainer/>
          </>
        )
    }
}



export default Main;