import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  CardHeader
} from "reactstrap";
import Switch from 'react-switch';
import CustomButtons from "../../Components/customebuttons";
import {registrationFeesActions, serviceMasterActions} from "../../../actions";
import {registrationWithFeesService} from '../../../services';
import {
    toast,
    Bounce
  } from 'react-toastify';
  import PageTitle from '../../MyComponents/PageTitle';
  import {apiUrl} from '../../../constants'
import { Icons } from "../../../helpers/IconHelper";
// Forms
class CorprateWiseserviceMaster1 extends React.Component {
    constructor(props){
        super(props);
        this.state={
            visible : false,
            selectedList: [], 
            packageList : []
        };
    }

    onTPAChange(){

    }

    increased_cost_change(e){

    }

    decreased_cost_change(e){

    }

    onRetainValueChange(){

    }

    saveClick(){

    }

    clearForm(){

    }

    onChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    render(){
        let { serviceMaster,registrationWithFeesMaster } = this.props;
        return(
            <Fragment>
                 <PageTitle  icon={Icons.PatientRegistration} heading="Corporate Wise Charges"/>
                 <Card>
                     <CardHeader></CardHeader>
                     <CardBody>
                         <Card>
                             <CardHeader>
                                 ADD
                             </CardHeader>
                             <CardBody>
                                   <Row>
                                       <Col md={3}>
                                           <Label style={{fontWeight: "bold"}}>Corporate Type</Label>
                                           <Input type="select" onChange={this.onTPAChange} name="rtwp_rtm_code" value={this.state.rtwp_rtm_code}>
                                               <option value="">Select</option>
                                               {registrationWithFeesMaster?registrationWithFeesMaster.map((data)=>{
                return <option key={data._id} value={data.rtm_code}>{data.rtm_reg_type}</option> 
                }):null}
                                           </Input>
                                       </Col>
                                       <Col md={3} style={{fontWeight: "bold"}}>
                                            <Label>Search</Label>
                                            <Input name="filterText" onChange={this.onChange} placeholder="Enter Dept/name/amount"></Input>
                                        </Col>
                                       <Col md={4}>
                                           <Label style={{fontWeight: "bold"}}>Choose Type</Label>
                                           <FormGroup row className="hmgroup Registrationgroupradio">
                          <Col sm={4} >
                                <CustomInput type="radio" id="Services" value="services" name="OPRegistrationtype"
                                             label="Services" onChange={this.onChange} checked={this.state.OPRegistrationtype === "services"}/>
                          </Col>
                          <Col sm={4}>
                                <CustomInput type="radio" id="Packages" value="packages" name="OPRegistrationtype"
                                             label="Packages" onChange={this.onChange} checked={this.state.OPRegistrationtype === "packages"}/>
                           </Col>
                           
                           <Col sm={4}>
                                 <CustomInput type="radio" id="Groups" value="groups" name="OPRegistrationtype"
                                              label="Groups" onChange={this.onChange} checked={this.state.OPRegistrationtype === "groups"}/>
                            </Col>
                        </FormGroup>
                                       </Col>
                                       {/* <Col md={4}>
                                           <Button color="primary" style={{marginTop:"25px"}} onClick={this.showModel}>Add To Reg Type</Button>
                                       </Col> */}
                                    </Row> 
                                    <Row>
                                        <Col md={3}>
                                            <Label>Increased Cost By %</Label>
                                            <Input type="number" max="100" name="increased_cost" onChange={this.increased_cost_change} value={this.state.increased_cost}/>
                                        </Col>
                                        <Col md={3}>
                                            <Label>Decreased Cost By %</Label>
                                            <Input type="number" max="100" name="decreased_cost" onChange={this.decreased_cost_change} value={this.state.decreased_cost}/>
                                        </Col>
                                        <Col md={5}>
                                            <Row>
                                            <Button color="warning" style={{marginTop:"25px"}} onClick={this.onRetainValueChange}>Retain Same Cost</Button>
                                            <Button onClick={this.saveClick} color="primary"  style={{marginTop:"25px",marginLeft:"10px"}}>Save</Button>
                                            <Button onClick = {this.clearForm} color="danger" style={{marginTop:"25px",marginLeft:"10px"}}>Clear Form</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                         </Card>
                         <Card>
                         <CardHeader>
                                <CardTitle>{this.state.type==="services"?"Service List":"Package List"}</CardTitle>
                            </CardHeader>

                         </Card>
                     </CardBody>
                 </Card>

            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    const { registrationWithFeesMaster,serviceMaster } = state;
    return { 
        serviceMaster : serviceMaster,
        registrationWithFeesMaster: registrationWithFeesMaster.registrationfees };
}

const CorporateWiseserviceMaster = connect(mapStateToProps)(CorprateWiseserviceMaster1);

export default CorporateWiseserviceMaster;