import { sweetAlertConstants } from '../constants';

export function sweetAlert(state = {show:false,props:{}}, action) {
  switch (action.type) {
    case sweetAlertConstants.SHOW:
      return {
        show:true,message:action.message,props:action.props
      };
    case sweetAlertConstants.HIDE:
      return {
        show:false,props:{}
      };
    default:
      return state
  }
}