// Search bar on Table which is a front end search and it is not query based
export const searchObjectsByPartialString = (data, searchString) => {
  const results = data.filter((obj) =>
    objectContainsPartialString(obj, searchString)
  );
  return results;
};

const objectContainsPartialString = (obj, searchString) => {
  for (const key in obj) {
    if (
      typeof obj[key] === "string" &&
      obj[key].toLowerCase().includes(searchString.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
};

// Restriction on adding user, consultant and patient since the branches is not added
export const userCreationRestrict = (organizationData) => {
  let orgData = sessionStorage.getItem("orgInfo");
  if (orgData) {
    const parent_org_id = JSON.parse(sessionStorage.getItem("orgInfo"))
      ?.parent_org_id;
    if (parent_org_id === "6516cad2c5bb548b893f3b70") {
      if (organizationData?.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// Difference between the dob and current date. With the year, month and day
export const diffInDOB = (data) => {
  // the data format should be "2002-10-11T13:44:33.000Z"
  const birthdate = new Date(data);
  const currentDate = new Date();

  let ageYears = currentDate.getFullYear() - birthdate.getFullYear();
  let ageMonths = currentDate.getMonth() - birthdate.getMonth();
  let ageDays = currentDate.getDate() - birthdate.getDate();

  // Adjust for negative months or days
  if (ageDays < 0) {
    ageMonths -= 1;
    ageDays += new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();
  }

  if (ageMonths < 0) {
    ageYears -= 1;
    ageMonths += 12;
  }

  return {
    years: ageYears,
    months: ageMonths,
    days: ageDays
  }
};
