export const generalMasterConstants = {
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",

  SET_SELECTED_COUNTRY_REQUEST: "SET_SELECTED_COUNTRY_REQUEST",
  SET_SELECTED_COUNTRY_SUCCESS: "SET_SELECTED_COUNTRY_SUCCESS",
  SET_SELECTED_COUNTRY_FAILURE: "SET_SELECTED_COUNTRY_FAILURE",

  ADD_COUNTRY_REQUEST: "ADD_COUNTRY_REQUEST",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",

  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "GET_STATE_FAILURE",

  SET_SELECTED_BANK_REQUEST: "SET_SELECTED_BANK_REQUEST",
  SET_SELECTED_BANK_SUCCESS: "SET_SELECTED_BANK_SUCCESS",
  SET_SELECTED_BANK_FAILURE: "SET_SELECTED_BANK_FAILURE",

  UPDATE_STATE_REQUEST: "UPDATE_STATE_REQUEST",
  UPDATE_STATE_SUCCESS: "UPDATE_STATE_SUCCESS",
  UPDATE_STATE_FAILURE: "UPDATE_STATE_FAILURE",

  ADD_STATE_REQUEST: "ADD_STATE_REQUEST",
  ADD_STATE_SUCCESS: "ADD_STATE_SUCCESS",
  ADD_STATE_FAILURE: "ADD_STATE_FAILURE",

  DELETE_STATE_REQUEST: "DELETE_STATE_REQUEST",
  DELETE_STATE_SUCCESS: "DELETE_STATE_SUCCESS",
  DELETE_STATE_FAILURE: "DELETE_STATE_FAILURE",

  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",

  ADD_CITY_REQUEST: "ADD_CITY_REQUEST",
  ADD_CITY_SUCCESS: "ADD_CITY_SUCCESS",
  ADD_CITY_FAILURE: "ADD_CITY_FAILURE",

  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILURE: "GET_LOCATION_FAILURE",

  ADD_LOCATION_REQUEST: "ADD_LOCATION_REQUEST",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
  ADD_LOCATION_FAILURE: "ADD_LOCATION_FAILURE",

  UPDATE_COUNTRY_REQUEST: "UPDATE_COUNTRY_REQUEST",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_FAILURE: "UPDATE_COUNTRY_FAILURE",

  DELETE_COUNTRY_REQUEST: "DELETE_COUNTRY_REQUEST",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",

  DELETE_LOCATION_REQUEST: "DELETE_LOCATION_REQUEST",
  DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
  DELETE_LOCATION_FAILURE: "DELETE_LOCATION_FAILURE",

  GET_BANK_REQUEST: "GET_BANK_REQUEST",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  GET_BANK_FAILURE: "GET_BANK_FAILURE",

  ADD_BANK_REQUEST: "ADD_BANK_REQUEST",
  ADD_BANK_SUCCESS: "ADD_BANK_SUCCESS",
  ADD_BANK_FAILURE: "ADD_BANK_FAILURE",

  UPDATE_BANK_REQUEST: "UPDATE_BANK_REQUEST",
  UPDATE_BANK_SUCCESS: "UPDATE_BANK_SUCCESS",
  UPDATE_BANK_FAILURE: "UPDATE_BANK_FAILURE",

  SET_SELECTED_BANK_REQUEST: "SET_SELECTED_BANK_REQUEST",
  SET_SELECTED_BANK_SUCCESS: "SET_SELECTED_BANK_SUCCESS",
  SET_SELECTED_BANK_FAILURE: "SET_SELECTED_BANK_FAILURE",

  DELETE_BANK_REQUEST: "DELETE_BANK_REQUEST",
  DELETE_BANK_SUCCESS: "DELETE_BANK_SUCCESS",
  DELETE_BANK_FAILURE: "DELETE_BANK_FAILURE",

  GET_BRANCH_REQUEST: "GET_BRANCH_REQUEST",
  GET_BRANCH_SUCCESS: "GET_BRANCH_SUCCESS",
  GET_BRANCH_FAILURE: "GET_BRANCH_FAILURE",

  ADD_BRANCH_REQUEST: "ADD_BRANCH_REQUEST",
  ADD_BRANCH_SUCCESS: "ADD_BRANCH_SUCCESS",
  ADD_BRANCH_FAILURE: "ADD_BRANCH_FAILURE",

  UPDATE_BRANCH_REQUEST: "UPDATE_BRANCH_REQUEST",
  UPDATE_BRANCH_SUCCESS: "UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_FAILURE: "UPDATE_BRANCH_FAILURE",

  DELETE_BRANCH_REQUEST: "DELETE_BRANCH_REQUEST",
  DELETE_BRANCH_SUCCESS: "DELETE_BRANCH_SUCCESS",
  DELETE_BRANCH_FAILURE: "DELETE_BRANCH_FAILURE",

  GET_DEPARTMENT_REQUEST: "GET_DEPARTMENT_REQUEST",
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_FAILURE: "GET_DEPARTMENT_FAILURE",

  ADD_DEPARTMENT_REQUEST: "ADD_DEPARTMENT_REQUEST",
  ADD_DEPARTMENT_SUCCESS: "ADD_DEPARTMENT_SUCCESS",
  ADD_DEPARTMENT_FAILURE: "ADD_DEPARTMENT_FAILURE",

  DELETE_DEPARTMENT_REQUEST: "DELETE_DEPARTMENT_REQUEST",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  DELETE_DEPARTMENT_FAILURE: "DELETE_DEPARTMENT_FAILURE",

  UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
  UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_FAILURE: "UPDATE_DEPARTMENT_FAILURE",

  SET_SELECTED_DEPARTMENT_SUCCESS: "SET_SELECTED_DEPARTMENT_SUCCESS",
  SET_SELECTED_DEPARTMENT_REQUEST: "SET_SELECTED_DEPARTMENT_REQUEST",
  SET_SELECTED_DEPARTMENT_FAILURE: "SET_SELECTED_DEPARTMENT_FAILURE",

  GET_SUBDEPARTMENT_REQUEST: "GET_SUBDEPARTMENT_REQUEST",
  GET_SUBDEPARTMENT_SUCCESS: "GET_SUBDEPARTMENT_SUCCESS",
  GET_SUBDEPARTMENT_FAILURE: "GET_SUBDEPARTMENT_FAILURE",

  ADD_SUBDEPARTMENT_REQUEST: "ADD_SUBDEPARTMENT_REQUEST",
  ADD_SUBDEPARTMENT_SUCCESS: "ADD_SUBDEPARTMENT_SUCCESS",
  ADD_SUBDEPARTMENT_FAILURE: "ADD_SUBDEPARTMENT_FAILURE",

  UPDATE_SUBDEPARTMENT_REQUEST: "UPDATE_SUBDEPARTMENT_REQUEST",
  UPDATE_SUBDEPARTMENT_SUCCESS: "UPDATE_SUBDEPARTMENT_SUCCESS",
  UPDATE_SUBDEPARTMENT_FAILURE: "UPDATE_SUBDEPARTMENT_FAILURE",

  DELETE_SUBDEPARTMENT_REQUEST: "DELETE_SUBDEPARTMENT_REQUEST",
  DELETE_SUBDEPARTMENT_SUCCESS: "DELETE_SUBDEPARTMENT_SUCCESS",
  DELETE_SUBDEPARTMENT_FAILURE: "DELETE_SUBDEPARTMENT_FAILURE",

  ADD_COSTCENTER_REQUEST: "ADD_COSTCENTER_REQUEST",
  ADD_COSTCENTER_SUCCESS: "ADD_COSTCENTER_SUCCESS",
  ADD_COSTCENTER_FAILURE: "ADD_COSTCENTER_FAILURE",

  GET_COSTCENTER_REQUEST: "GET_COSTCENTER_REQUEST",
  GET_COSTCENTER_SUCCESS: "GET_COSTCENTER_SUCCESS",
  GET_COSTCENTER_FAILURE: "GET_COSTCENTER_FAILURE",

  UPDATE_COSTCENTER_REQUEST: "UPDATE_COSTCENTER_REQUEST",
  UPDATE_COSTCENTER_SUCCESS: "UPDATE_COSTCENTER_SUCCESS",
  UPDATE_COSTCENTER_FAILURE: "UPDATE_COSTCENTER_FAILURE",

  DELETE_COSTCENTER_REQUEST: "DELETE_COSTCENTER_REQUEST",
  DELETE_COSTCENTER_SUCCESS: "DELETE_COSTCENTER_SUCCESS",
  DELETE_COSTCENTER_FAILURE: "DELETE_COSTCENTER_FAILURE",

  ADD_SPECIALITY_REQUEST: "ADD_SPECIALITY_REQUEST",
  ADD_SPECIALITY_SUCCESS: "ADD_SPECIALITY_SUCCESS",
  ADD_SPECIALITY_FAILURE: "ADD_SPECIALITY_FAILURE",

  GET_SPECIALITY_REQUEST: "GET_SPECIALITY_REQUEST",
  GET_SPECIALITY_SUCCESS: "GET_SPECIALITY_SUCCESS",
  GET_SPECIALITY_FAILURE: "GET_SPECIALITY_FAILURE",

  UPDATE_SPECIALITY_REQUEST: "UPDATE_SPECIALITY_REQUEST",
  UPDATE_SPECIALITY_SUCCESS: "UPDATE_SPECIALITY_SUCCESS",
  UPDATE_SPECIALITY_FAILURE: "UPDATE_SPECIALITY_FAILURE",

  DELETE_SPECIALITY_REQUEST: "DELETE_SPECIALITY_REQUEST",
  DELETE_SPECIALITY_SUCCESS: "DELETE_SPECIALITY_SUCCESS",
  DELETE_SPECIALITY_FAILURE: "DELETE_SPECIALITY_FAILURE",

  ADD_DEPARTMENT_TYPE_REQUEST: "ADD_DEPARTMENT_TYPE_REQUEST",
  ADD_DEPARTMENT_TYPE_SUCCESS: "ADD_DEPARTMENT_TYPE_SUCCESS",
  ADD_DEPARTMENT_TYPE_FAILURE: "ADD_DEPARTMENT_TYPE_FAILURE",

  GET_DEPARTMENT_TYPE_REQUEST: "GET_DEPARTMENT_TYPE_REQUEST",
  GET_DEPARTMENT_TYPE_SUCCESS: "GET_DEPARTMENT_TYPE_SUCCESS",
  GET_DEPARTMENT_TYPE_FAILURE: "GET_DEPARTMENT_TYPE_FAILURE",

  UPDATE_DEPARTMENT_TYPE_REQUEST: "UPDATE_DEPARTMENT_TYPE_REQUEST",
  UPDATE_DEPARTMENT_TYPE_SUCCESS: "UPDATE_DEPARTMENT_TYPE_SUCCESS",
  UPDATE_DEPARTMENT_TYPE_FAILURE: "UPDATE_DEPARTMENT_TYPE_FAILURE",

  DELETE_DEPARTMENT_TYPE_REQUEST: "DELETE_DEPARTMENT_TYPE_REQUEST",
  DELETE_DEPARTMENT_TYPE_SUCCESS: "DELETE_DEPARTMENT_TYPE_SUCCESS",
  DELETE_DEPARTMENT_TYPE_FAILURE: "DELETE_DEPARTMENT_TYPE_FAILURE",

  ADD_DISCOUNT_TYPE_REQUEST: "ADD_DISCOUNT_TYPE_REQUEST",
  ADD_DISCOUNT_TYPE_SUCCESS: "ADD_DISCOUNT_TYPE_SUCCESS",
  ADD_DISCOUNT_TYPE_FAILURE: "ADD_DISCOUNT_TYPE_FAILURE",

  GET_DISCOUNT_TYPE_REQUEST: "GET_DISCOUNT_TYPE_REQUEST",
  GET_DISCOUNT_TYPE_SUCCESS: "GET_DISCOUNT_TYPE_SUCCESS",
  GET_DISCOUNT_TYPE_FAILURE: "GET_DISCOUNT_TYPE_FAILURE",

  UPDATE_DISCOUNT_TYPE_REQUEST: "UPDATE_DISCOUNT_TYPE_REQUEST",
  UPDATE_DISCOUNT_TYPE_SUCCESS: "UPDATE_DISCOUNT_TYPE_SUCCESS",
  UPDATE_DISCOUNT_TYPE_FAILURE: "UPDATE_DISCOUNT_TYPE_FAILURE",

  DELETE_DISCOUNT_TYPE_REQUEST: "DELETE_DISCOUNT_TYPE_REQUEST",
  DELETE_DISCOUNT_TYPE_SUCCESS: "DELETE_DISCOUNT_TYPE_SUCCESS",
  DELETE_DISCOUNT_TYPE_FAILURE: "DELETE_DISCOUNT_TYPE_FAILURE",

  ADD_DISCOUNT_AUTH_REQUEST: "ADD_DISCOUNT_AUTH_REQUEST",
  ADD_DISCOUNT_AUTH_SUCCESS: "ADD_DISCOUNT_AUTH_SUCCESS",
  ADD_DISCOUNT_AUTH_FAILURE: "ADD_DISCOUNT_AUTH_FAILURE",

  GET_DISCOUNT_AUTH_REQUEST: "GET_DISCOUNT_AUTH_REQUEST",
  GET_DISCOUNT_AUTH_SUCCESS: "GET_DISCOUNT_AUTH_SUCCESS",
  GET_DISCOUNT_AUTH_FAILURE: "GET_DISCOUNT_AUTH_FAILURE",

  UPDATE_DISCOUNT_AUTH_REQUEST: "UPDATE_DISCOUNT_AUTH_REQUEST",
  UPDATE_DISCOUNT_AUTH_SUCCESS: "UPDATE_DISCOUNT_AUTH_SUCCESS",
  UPDATE_DISCOUNT_AUTH_FAILURE: "UPDATE_DISCOUNT_AUTH_FAILURE",

  DELETE_DISCOUNT_AUTH_REQUEST: "DELETE_DISCOUNT_AUTH_REQUEST",
  DELETE_DISCOUNT_AUTH_SUCCESS: "DELETE_DISCOUNT_AUTH_SUCCESS",
  DELETE_DISCOUNT_AUTH_FAILURE: "DELETE_DISCOUNT_AUTH_FAILURE",

  ADD_DISCHARGE_TYPE_REQUEST: "ADD_DISCHARGE_TYPE_REQUEST",
  ADD_DISCHARGE_TYPE_SUCCESS: "ADD_DISCHARGE_TYPE_SUCCESS",
  ADD_DISCHARGE_TYPE_FAILURE: "ADD_DISCHARGE_TYPE_FAILURE",

  GET_DISCHARGE_TYPE_REQUEST: "GET_DISCHARGE_TYPE_REQUEST",
  GET_DISCHARGE_TYPE_SUCCESS: "GET_DISCHARGE_TYPE_SUCCESS",
  GET_DISCHARGE_TYPE_FAILURE: "GET_DISCHARGE_TYPE_FAILURE",

  UPDATE_DISCHARGE_TYPE_REQUEST: "UPDATE_DISCHARGE_TYPE_REQUEST",
  UPDATE_DISCHARGE_TYPE_SUCCESS: "UPDATE_DISCHARGE_TYPE_SUCCESS",
  UPDATE_DISCHARGE_TYPE_FAILURE: "UPDATE_DISCHARGE_TYPE_FAILURE",

  DELETE_DISCHARGE_TYPE_REQUEST: "DELETE_DISCHARGE_TYPE_REQUEST",
  DELETE_DISCHARGE_TYPE_SUCCESS: "DELETE_DISCHARGE_TYPE_SUCCESS",
  DELETE_DISCHARGE_TYPE_FAILURE: "DELETE_DISCHARGE_TYPE_FAILURE",

  ADD_ConsentForm_REQUEST: "ADD_ConsentForm_REQUEST",
  ADD_ConsentForm_SUCCESS: "ADD_ConsentForm_SUCCESS",
  ADD_ConsentForm_FAILURE: "ADD_ConsentForm_FAILURE",

  GET_ConsentForm_REQUEST: "GET_ConsentForm_REQUEST",
  GET_ConsentForm_SUCCESS: "GET_ConsentForm_SUCCESS",
  GET_ConsentForm_FAILURE: "GET_ConsentForm_FAILURE",

  UPDATE_ConsentForm_REQUEST: "UPDATE_ConsentForm_REQUEST",
  UPDATE_ConsentForm_SUCCESS: "UPDATE_ConsentForm_SUCCESS",
  UPDATE_ConsentForm_FAILURE: "UPDATE_ConsentForm_FAILURE",

  DELETE_ConsentForm_REQUEST: "DELETE_ConsentForm_REQUEST",
  DELETE_ConsentForm_SUCCESS: "DELETE_ConsentForm_SUCCESS",
  DELETE_ConsentForm_FAILURE: "DELETE_ConsentForm_FAILURE",

  CLEAR: "CLEAR",
};
