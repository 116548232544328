//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const labTestSampleCollectionService = {        
    getAll,
    getlogs,
    getDetailedlogs,
    get,
    add,
    update,
    remove,
    search,
    getByDate,
    approveSample,
    rejectSample,
    abortSample,
    updateStatus,
    getStatusReport,
    discardSample,
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample`, requestOptions).then(helperService.handleResponse);
}

function getlogs(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample/logs/${from}/${to}`, requestOptions).then(helperService.handleResponse);
}

function getDetailedlogs(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample/detailedlogs/${from}/${to}`, requestOptions).then(helperService.handleResponse);
}
function get(patient_id,service_id) {
    var query="";
    if(patient_id)
    {
       query= `labPatient_id=${patient_id}`
    }
    if(service_id)
    {
        query+=`&&service=${service_id}`
    }
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample?${query}`, requestOptions).then(helperService.handleResponse);
}

function search(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify({query:data})
    };
   
    return fetch(`${apiUrl}/labtestsample/search`, requestOptions).then(helperService.handleResponse);
}
function getByDate(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample/getByDate/${from}/${to}`, requestOptions).then(helperService.handleResponse);
}

function getStatusReport() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/labtestsample/dailyreport`, requestOptions).then(helperService.handleResponse);
}



function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/labtestsample`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'PUT',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/labtestsample/update`, requestOptions).then(helperService.handleResponse);
}

function discardSample(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/labtestsample/discardSample`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/labtestsample/delete`, requestOptions).then(helperService.handleResponse);
}

function approveSample(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };   
    return fetch(`${apiUrl}/labtestsample/approve`, requestOptions).then(helperService.handleResponse);
}

function updateStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };   
    return fetch(`${apiUrl}/labtestsample/updateStatus`, requestOptions).then(helperService.handleResponse);
}

function rejectSample(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };   
    return fetch(`${apiUrl}/labtestsample/reject`, requestOptions).then(helperService.handleResponse);
}
function abortSample(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };   
    return fetch(`${apiUrl}/labtestsample/abort`, requestOptions).then(helperService.handleResponse);
}