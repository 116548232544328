//import { authHeader } from '../store';
import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const departmentIndentService = {
  getAll,
  add,
  update,
  get,
  getByDate,
  getdeptstock,
  _delete,
};

function getAll(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departmentIndent?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getdeptstock(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/departmentIndent/indent?${query}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departmentIndent/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/departmentIndent?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departmentIndent`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departmentIndent/update`, requestOptions).then(
    helperService.handleResponse
  );
}
function _delete(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departmentIndent/delete`, requestOptions).then(
    helperService.handleResponse
  );
}
