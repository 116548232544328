import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const serviceMasterService = {
  add,
  deleteService,
  getAllServices,
  addPackageMapping,
  getAllPackagesMainTestLab,
  getAllPackagesSubTestLab,
  getAllPackages,
  editServices,
  getPackageMappingDetailsById,
  deactiveService,
  getAllGroups,
  getGroupingMappingData,
};

function getGroupingMappingData(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(`${apiUrl}/groups/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getPackageMappingDetailsById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/packages/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteService(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  if (data.type === "services") {
    return fetch(`${apiUrl}/services/${data.id}`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.type === "packages") {
    return fetch(`${apiUrl}/packages/${data.id}`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.type === "groups") {
    return fetch(`${apiUrl}/groups/${data.id}`, requestOptions).then(
      helperService.handleResponse
    );
  } else {
    return fetch(`${apiUrl}/services/${data.id}`, requestOptions).then(
      helperService.handleResponse
    );
  }
}

function deactiveService(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);

  return fetch(`${apiUrl}/services/deactive`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  if (data.service)
    return fetch(`${apiUrl}/services`, requestOptions).then(
      helperService.handleResponse
    );
  else if (data.package) {
    return fetch(`${apiUrl}/packages`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.group) {
    return fetch(`${apiUrl}/groups`, requestOptions).then(
      helperService.handleResponse
    );
  } else {
    return fetch(`${apiUrl}/services`, requestOptions).then(
      helperService.handleResponse
    );
  }
}

function getAllServices(type = 2, viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/services/applicableTo/${type}?viewDeleted=${viewDeleted}&page=1&records=2000`,
    requestOptions
  ).then(helperService.handleResponse);
}

function addPackageMapping(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  if (data.isEditFlow) {
    return fetch(`${apiUrl}/packagemapping/update`, requestOptions).then(
      helperService.handleResponse
    );
  } else {
    return fetch(`${apiUrl}/packagemapping`, requestOptions).then(
      helperService.handleResponse
    );
  }
}

function getAllPackages(type = 2, viewDeleted=true) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/packages/applicableTo/${type}?viewDeleted=${viewDeleted}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllPackagesMainTestLab() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/services/servicewithpackage?pages=maintest`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllPackagesSubTestLab() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/services/servicewithpackage?pages=subtest`,
    requestOptions
  ).then(helperService.handleResponse);
}

function editServices(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  if (data.service)
    return fetch(`${apiUrl}/services/${id}`, requestOptions).then(
      helperService.handleResponse
    );
  else if (data.package) {
    return fetch(`${apiUrl}/packages/${id}`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.group) {
    return fetch(`${apiUrl}/groups/${id}`, requestOptions).then(
      helperService.handleResponse
    );
  } else {
    return fetch(`${apiUrl}/services/${id}`, requestOptions).then(
      helperService.handleResponse
    );
  }
}

function getAllGroups(type = 2, viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/groups/applicableTo/${type}?viewDeleted=${viewDeleted}`,
    requestOptions
  ).then(helperService.handleResponse);
}
