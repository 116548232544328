import { menuConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { menuService } from "../services/menu.service";

export const menuActions = {
  get,
  getRoleBasedMenu,
};

function get() {
  return (dispatch) => {
    dispatch(request());
    menuService.getAll().then(
      (data) => {
        if (data.success) {
          dispatch(success(data.menus));

          dispatch(alertActions.success("Menus loaded"));
        } else dispatch(failure(data.message));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: menuConstants.GET_MENU_REQUEST };
  }
  function success(menus) {
    return { type: menuConstants.GET_MENU_SUCCESS, menus };
  }
  function failure(error) {
    return { type: menuConstants.GET_MENU_FAILURE, error };
  }
}

function getRoleBasedMenu(role) {
  return (dispatch) => {
    dispatch(request());
    menuService.getRoleBased(role).then(
      (menus) => {
        const data = { menus };
        if (data) {
          dispatch(success(data.menus));
          dispatch(alertActions.success("Menus loaded"));
        } else dispatch(failure(data.message));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: menuConstants.GET_MENU_REQUEST };
  }
  function success(menus) {
    return { type: menuConstants.GET_MENU_SUCCESS, menus };
  }
  function failure(error) {
    return { type: menuConstants.GET_MENU_FAILURE, error };
  }
}
