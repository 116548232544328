import {helperService} from "./helper.service"
import {apiUrl} from "../constants";

export const CostCenterService = {
    getAll
};

function getAll() {
    const requestOptions = {
        method: 'GET',
      headers:{  authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/costcenters`, requestOptions).then(helperService.handleResponse);
}