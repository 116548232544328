//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const opdPrescriptionService = {        
    getAll,
    save,
    update,  
    getByDate,
    getByPatient,
    getHistory
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/opdprescription`, requestOptions).then(helperService.handleResponse);
}

function getByDate() {  
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/opdprescription/date`, requestOptions).then(helperService.handleResponse);
    // return fetch(`${apiUrl}/opdprescription?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}
function getByPatient(id) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/opdprescription/date?patient_id=${id}`, requestOptions).then(helperService.handleResponse);
}

function save(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/opdprescription`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/opdprescription/update`, requestOptions).then(helperService.handleResponse);
}

function getHistory(id) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/opdprescription/pasthistory?patient_id=${id}`, requestOptions).then(helperService.handleResponse);
  }


