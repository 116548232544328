export const servieMasterConstants = {
  GET_ALL_REQUEST: "GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_FAILURE",

  ADD_REQUEST: "ADD_REQUEST",
  ADD_SUCCESS: "ADD_SUCCESS",
  ADD_FAILURE: "ADD_FAILURE",

  ADD_PACKAGE_REQUEST: "ADD_PACKAGE_REQUEST",
  ADD_PACKAGE_SUCCESS: "ADD_PACKAGE_SUCCESS",
  ADD_PACKAGE_FAILURE: "ADD_PACKAGE_FAILURE",

  DELETE_PACKAGE_REQUEST: "DELETE_PACKAGE_REQUEST",
  DELETE_PACKAGE_SUCCESS: "DELETE_PACKAGE_SUCCESS",
  DELETE_PACKAGE_FAILURE: "DELETE_PACKAGE_FAILURE",

  DELETE_SERVICE_REQUEST: "DELETE_SERVICE_REQUEST",
  DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_FAILURE: "DELETE_SERVICE_FAILURE",

  DEACTIVE_SERVICE_REQUEST: "DEACTIVE_SERVICE_REQUEST",
  DEACTIVE_SERVICE_SUCCESS: "DEACTIVE_SERVICE_SUCCESS",
  DEACTIVE_SERVICE_FAILURE: "DEACTIVE_SERVICE_FAILURE",

  EDIT_PACKAGE_REQUEST: "EDIT_PACKAGE_REQUEST",
  EDIT_PACKAGE_SUCCESS: "EDIT_PACKAGE_SUCCESS",
  EDIT_PACKAGE_FAILURE: "EDIT_PACKAGE_FAILURE",

  GET_ALL_SERVICES_REQUEST: "GET_ALL_SERVICES_REQUEST",
  GET_ALL_SERVICES_SUCCESS: "GET_ALL_SERVICES_SUCCESS",
  GET_ALL_SERVICES_FAILURE: "GET_ALL_SERVICES_FAILURE",

  GET_ALL_PACKAGES_REQUEST: "GET_ALL_PACKAGES_REQUEST",
  GET_ALL_PACKAGES_SUCCESS: "GET_ALL_PACKAGES_SUCCESS",
  GET_ALL_PACKAGES_FAILURE: "GET_ALL_PACKAGES_FAILURE",

  GET_ALL_GROUPS_REQUEST: "GET_ALL_GROUPS_REQUEST",
  GET_ALL_GROUPS_SUCCESS: "GET_ALL_GROUPS_SUCCESS",
  GET_ALL_GROUPS_FAILURE: "GET_ALL_GROUPS_FAILURE",

  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILURE: "DELETE_FAILURE",

  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  CLEAR: "CLEAR",
};
