import { helperService } from "./helper.service";
import { loginConstant } from "../constants";
import { storageService } from "./storage.service";
import { apiUrl } from "../constants";

export const loginservice = {
  login,
  logout,
  refresh,
  generateOtp,
  verifyOtp,
  resetPassword,
  userType,
  updateuserType,
  ChangePassword,
  authenticate,
  purchaseOrder,
  approvePurchaseorder,
  getUserDetails,
};

function purchaseOrder(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/approve`, requestOptions).then(
    helperService.handleResponse
  );
}

function approvePurchaseorder(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/approved`, requestOptions).then(
    helperService.handleResponse
  );
}

function authenticate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/user/authenticate`, requestOptions).then(
    helperService.handleResponse
  );
}

function userType() {
  return fetch(`${apiUrl}/usertypes/getusertypes`).then(
    helperService.handleResponse
  );
}

function logout() {
  console.log("Loggin from service", "logout invoked");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify("logout"),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/logout`, requestOptions).then(
    helperService.handleResponse
  );
}

function refresh() {
  console.log("Refresh from service", "Refresh invoked");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  console.log("Refresh from service", requestOptions);
  return fetch(`${apiUrl}/user/refresh`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateuserType(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/usertypes/changeActivetypes`, requestOptions).then(
    helperService.handleResponse
  );
}

function login(data) {
  //const { customer_email: username, password } = data;
  //const newData = { userName : data.username , password };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  if (data.login_type === "customer") {
    return fetch(`${apiUrl}/aqua/customerMaster/login`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.login_type === "consultant") {
    // return fetch(`${apiUrl}/consultant/login`, requestOptions).then(helperService.handleResponse);
    return fetch(`${apiUrl}/user/login`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.login_type === "patient") {
    return fetch(`${apiUrl}/user/login`, requestOptions).then(
      helperService.handleResponse
    );
  } else if (data.login_type === "user") {
    return fetch(`${apiUrl}/authenticate`, requestOptions).then(
      helperService.handleResponse
    );
  } else {
    return fetch(`${apiUrl}/authenticate`, requestOptions).then(
      helperService.handleResponse
    );
  }
}

function generateOtp(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/aqua/smsMail/sendMail`, requestOptions).then(
    helperService.handleResponse
  );
}

function verifyOtp(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/verifyOTP/verifyResetPasswordOTP`,
    requestOptions
  ).then(helperService.handleResponse);
}

function ChangePassword(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/users/changepassword`, requestOptions).then(
    helperService.handleResponse
  );
}

function resetPassword(data) {
  console.log("Loggin from service", data);
  const authorization = storageService.getData(
    loginConstant.AUTHORIZATION_HEADER
  );
  const authorizationKey = loginConstant.AUTHORIZATION_HEADER;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerMaster/resetPassword`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getUserDetails() {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(`${apiUrl}/Users/Info`, requestOptions).then(
    helperService.handleResponse
  );
}
