import { wardManagementConstants } from "../constants";

export function wardManagement(state = {}, action) {
  switch (action.type) {
    case wardManagementConstants.GET_ALL_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_WARD_TYPE_SUCCESS:
      return { ...state, wardtypes: action.data, loading: false };
    case wardManagementConstants.GET_ALL_WARD_TYPE_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_WARD_TYPE_SUCCESS:
      let wards = [];
      if (state.wardtypes) {
        wards = [...state.wardtypes];
        wards.push(action.data);
      } else {
        wards = [action.data];
      }
      return { ...state, wardtypes: wards, loading: false };
    case wardManagementConstants.ADD_ALL_WARD_TYPE_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_TYPE_SUCCESS:
      let allwardtypes = [...state.wardtypes];
      allwardtypes.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardtypes[index].isDeleted = !allwardtypes[index].isDeleted;
          allwardtypes[index].isActive = !allwardtypes[index].isActive;
        }
      });
      return { ...state, wardtypes: allwardtypes, loading: false };
    case wardManagementConstants.DELETE_ALL_WARD_TYPE_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_WARD_TYPE_SUCCESS:
      let allwardtypesupdate = [...state.wardtypes];
      allwardtypesupdate.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardtypesupdate[index].ward_type = action.data.ward_type;
        }
      });
      return { ...state, wardtypes: allwardtypesupdate, loading: false };
    case wardManagementConstants.UPDATE_ALL_WARD_TYPE_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_SUCCESS:
      let alldeactivewardtypes = [...state.wardtypes];
      alldeactivewardtypes.forEach((data, index) => {
        if (data._id === action.data._id) {
          alldeactivewardtypes[index].isActive = !alldeactivewardtypes[index]
            .isActive;
        }
      });
      return { ...state, wardtypes: alldeactivewardtypes, loading: false };
    case wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_WARD_CATAGORY_SUCCESS:
      return { ...state, wardcatagorys: action.data, loading: false };
    case wardManagementConstants.GET_ALL_WARD_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_WARD_CATAGORY_SUCCESS:
      let wardcatagorys = [];
      if (state.wardcatagorys) {
        wardcatagorys = [...state.wardcatagorys];
        wardcatagorys.push(action.data);
      } else {
        wardcatagorys = [action.data];
      }
      return { ...state, wardcatagorys: wardcatagorys, loading: false };
    case wardManagementConstants.ADD_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_SUCCESS:
      let allDeletewardCatagory = [...state.wardcatagorys];
      allDeletewardCatagory.forEach((data, index) => {
        if (data._id === action.data._id) {
          allDeletewardCatagory[index].isDeleted = !allDeletewardCatagory[index]
            .isDeleted;
          allDeletewardCatagory[index].isActive = !allDeletewardCatagory[index].isActive;
        }
      });
      return { ...state, wardcatagorys: allDeletewardCatagory, loading: false };
    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_SUCCESS:
      let allwardcatagoryupdate = [...state.wardcatagorys];
      allwardcatagoryupdate.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardcatagoryupdate[index].ward_type = action.data.ward_type;
          allwardcatagoryupdate[index].ward_catagory =
            action.data.ward_catagory;
        }
      });
      return { ...state, wardcatagorys: allwardcatagoryupdate, loading: false };
    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_SUCCESS:
      let allwardcatagorydeactive = [...state.wardcatagorys];
      allwardcatagorydeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardcatagorydeactive[index].isActive = !allwardcatagorydeactive[
            index
          ].isActive;
        }
      });
      return {
        ...state,
        wardcatagorys: allwardcatagorydeactive,
        loading: false,
      };
    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_BY_WARD_TYPE_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_BY_WARD_TYPE_SUCCESS:
      return { ...state, wardbycatagorys: action.data, loading: false };
    case wardManagementConstants.GET_BY_WARD_TYPE_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_SUCCESS:
      return { ...state, wardsubcatagory: action.data, loading: false };
    case wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_SUCCESS:
      let wardsubcatagory = [];
      if (state.wardsubcatagory) {
        wardsubcatagory = [...state.wardsubcatagory];
        wardsubcatagory.push(action.data);
      } else {
        wardsubcatagory = [action.data];
      }
      return { ...state, wardsubcatagory: wardsubcatagory, loading: false };
    case wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_SUCCESS:
      let allDeletewardsubCatagory = [...state.wardsubcatagory];
      allDeletewardsubCatagory.forEach((data, index) => {
        if (data._id === action.data._id) {
          allDeletewardsubCatagory[index].isDeleted = !allDeletewardsubCatagory[
            index
          ].isDeleted;
          allDeletewardsubCatagory[index].isActive = !allDeletewardsubCatagory[index].isActive;
        }
      });
      return {
        ...state,
        wardsubcatagory: allDeletewardsubCatagory,
        loading: false,
      };
    case wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_SUCCESS:
      let allwardsubcatagoryupdate = [...state.wardsubcatagory];
      allwardsubcatagoryupdate.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardsubcatagoryupdate[index].ward_type = action.data.ward_type;
          allwardsubcatagoryupdate[index].ward_catagory =
            action.data.ward_catagory;
          allwardsubcatagoryupdate[index].ward_sub_catagory =
            action.data.ward_sub_catagory;
        }
      });
      return {
        ...state,
        wardsubcatagory: allwardsubcatagoryupdate,
        loading: false,
      };
    case wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_SUCCESS:
      let allwardsubcatagorydeactive = [...state.wardsubcatagory];
      allwardsubcatagorydeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardsubcatagorydeactive[
            index
          ].isActive = !allwardsubcatagorydeactive[index].isActive;
        }
      });
      return {
        ...state,
        wardsubcatagory: allwardsubcatagorydeactive,
        loading: false,
      };
    case wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_SUCCESS:
      return { ...state, wardcatagoryfees: action.data, loading: false };
    case wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_SUCCESS:
      let wardcatagoryfees = [];
      if (state.wardcatagoryfees) {
        wardcatagoryfees = [...state.wardcatagoryfees];
        wardcatagoryfees.unshift(action.data);
      } else {
        wardcatagoryfees = [action.data];
      }
      return { ...state, wardcatagoryfees: wardcatagoryfees, loading: false };
    case wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_SUCCESS:
      let allDeletewardcatagoryfees = [...state.wardcatagoryfees];
      allDeletewardcatagoryfees.forEach((data, index) => {
        if (data._id === action.data._id) {
          allDeletewardcatagoryfees[
            index
          ].isDeleted = !allDeletewardcatagoryfees[index].isDeleted;
          allDeletewardcatagoryfees[index].isActive = !allDeletewardcatagoryfees[index].isActive;
        }
      });
      return {
        ...state,
        wardcatagoryfees: allDeletewardcatagoryfees,
        loading: false,
      };
    case wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_SUCCESS:
      let allwardcatagoryfeesUpdate = [...state.wardcatagoryfees];
      allwardcatagoryfeesUpdate.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardcatagoryfeesUpdate[index] = action.data;
        }
      });
      return {
        ...state,
        wardcatagoryfees: allwardcatagoryfeesUpdate,
        loading: false,
      };
    case wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_SUCCESS:
      let allwardcatagoryfeesdeactive = [...state.wardcatagoryfees];
      allwardcatagoryfeesdeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardcatagoryfeesdeactive[
            index
          ].isActive = !allwardcatagoryfeesdeactive[index].isActive;
        }
      });
      return {
        ...state,
        wardcatagoryfees: allwardcatagoryfeesdeactive,
        loading: false,
      };
    case wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_WARD_FACILITY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_WARD_FACILITY_SUCCESS:
      return { ...state, wardfacility: action.data, loading: false };
    case wardManagementConstants.GET_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_WARD_FACILITY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_WARD_FACILITY_SUCCESS:
      let wardfacility = [];
      if (state.wardfacility) {
        wardfacility = [...state.wardfacility];
        wardfacility.push(action.data);
      } else {
        wardfacility = [action.data];
      }
      return { ...state, wardfacility: wardfacility, loading: false };
    case wardManagementConstants.ADD_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_WARD_FACILITY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_FACILITY_SUCCESS:
      let allDeletewardfacility = [...state.wardfacility];
      allDeletewardfacility.forEach((data, index) => {
        if (data._id === action.data._id) {
          allDeletewardfacility[index].isDeleted = !allDeletewardfacility[index]
            .isDeleted;
          allDeletewardfacility[index].isActive = !allDeletewardfacility[index].isActive;
        }
      });
      return { ...state, wardfacility: allDeletewardfacility, loading: false };
    case wardManagementConstants.DELETE_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_WARD_FACILITY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_WARD_FACILITY_SUCCESS:
      let allwardfacilityupdate = [...state.wardfacility];
      allwardfacilityupdate.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardfacilityupdate[index] = action.data;
        }
      });
      return { ...state, wardfacility: allwardfacilityupdate, loading: false };
    case wardManagementConstants.UPDATE_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_SUCCESS:
      let allwardfacilitydeactive = [...state.wardfacility];
      console.log(allwardfacilitydeactive, "facility");
      allwardfacilitydeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          console.log(action.data);
          // const idx = data.inward_services.findIndex(
          //   (item) => item._id == action.data._id
          // );
          // console.log(idx, "index");
          // allwardfacilitydeactive[index].isActive = !allwardfacilitydeactive[
          //   index
          // ].isActive;
          allwardfacilitydeactive[index] = action.data;
        }
      });
      return {
        ...state,
        wardfacility: allwardfacilitydeactive,
        loading: false,
      };
    case wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_BY_WARD_CATAGORY_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_BY_WARD_CATAGORY_SUCCESS:
      return { ...state, wardbysubcatagorys: action.data, loading: false };
    case wardManagementConstants.GET_BY_WARD_CATAGORY_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_ROOM_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_ROOM_SUCCESS:
      return { ...state, wardroom: action.data, loading: false };
    case wardManagementConstants.GET_ALL_ROOM_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ROOM_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ROOM_SUCCESS:
      let wardroom = [];
      if (state.wardroom) {
        wardroom = [...state.wardroom];
        wardroom.push(action.data);
      } else {
        wardroom = [action.data];
      }
      return { ...state, wardroom: wardroom, loading: false };
    case wardManagementConstants.ADD_ROOM_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_SUCCESS:
      let allwardroomdeactive = [...state.wardroom];
      allwardroomdeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardroomdeactive[index].isActive = !allwardroomdeactive[index]
            .isActive;
        }
      });
      return { ...state, wardroom: allwardroomdeactive, loading: false };
    case wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DELETE_ALL_WARD_ROOM_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_WARD_ROOM_SUCCESS:
      let allwardrooms = [...state.wardroom];
      allwardrooms.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardrooms[index].isDeleted = !allwardrooms[index].isDeleted;
          allwardrooms[index].isActive = !allwardrooms[index].isActive;
        }
      });
      return { ...state, wardroom: allwardrooms, loading: false };
    case wardManagementConstants.DELETE_ALL_WARD_ROOM_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_GRACEPERIOD_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_GRACEPERIOD_SUCCESS:
      return { ...state, graceperiod: action.data, loading: false };
    case wardManagementConstants.GET_ALL_GRACEPERIOD_FAILURE:
      return { ...state, loading: false, err: action.error };
    case wardManagementConstants.ADD_ALL_GRACEPERIOD_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_ALL_GRACEPERIOD_SUCCESS:
      let graceperiod = [];
      if (state.graceperiod) {
        graceperiod = [...state.graceperiod];
        graceperiod.push(action.data);
      } else {
        graceperiod = [action.data];
      }

      return { ...state, graceperiod: graceperiod, loading: false };
    case wardManagementConstants.ADD_ALL_GRACEPERIOD_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DELETE_ALL_GRACEPERIOD_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DELETE_ALL_GRACEPERIOD_SUCCESS:
      let allgraceperiod = [...state.graceperiod];
      allgraceperiod.forEach((data, index) => {
        if (data._id === action.data._id) {
          allgraceperiod[index].isDeleted = !allgraceperiod[index].isDeleted;
          allgraceperiod[index].isActive = !allgraceperiod[index].isActive;
        }
      });
      return { ...state, graceperiod: allgraceperiod, loading: false };
    case wardManagementConstants.DELETE_ALL_GRACEPERIOD_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.UPDATE_ALL_GRACEPERIOD_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.UPDATE_ALL_GRACEPERIOD_SUCCESS:
      let allgraceperiodupdate = [...state.graceperiod];
      allgraceperiodupdate.forEach((data, index) => {
        if (data?._id === action.data?._id) {
          allgraceperiodupdate[index] = action.data;
        }
      });
      return { ...state, graceperiod: allgraceperiodupdate, loading: false };
    case wardManagementConstants.UPDATE_ALL_GRACEPERIOD_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.DEACTIVE_ALL_GRACEPERIOD_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_GRACEPERIOD_SUCCESS:
      let alldeactivegraceperiod = [...state.graceperiod];
      alldeactivegraceperiod.forEach((data, index) => {
        if (data._id === action.data._id) {
          alldeactivegraceperiod[index].isActive = !alldeactivegraceperiod[
            index
          ].isActive;
        }
      });
      return { ...state, graceperiod: alldeactivegraceperiod, loading: false };
    case wardManagementConstants.DEACTIVE_ALL_GRACEPERIOD_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.GET_ALL_BED_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.GET_ALL_BED_SUCCESS:
      return { ...state, wardbed: action.data, loading: false };
    case wardManagementConstants.GET_ALL_BED_FAILURE:
      return { ...state, loading: false, err: action.error };

    case wardManagementConstants.ADD_BED_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.ADD_BED_SUCCESS:
      let wardbed = [];
      if (state.wardBed) {
        wardbed = [...state.wardbed];
        wardbed.push(action.data);
      } else {
        wardbed = [action.data];
      }
      return { ...state, wardbed: wardbed, loading: false };
    case wardManagementConstants.ADD_BED_FAILURE:
      return { ...state, loading: false };

    case wardManagementConstants.DEACTIVE_ALL_WARD_BED_REQUEST:
      return { ...state, loading: true };
    case wardManagementConstants.DEACTIVE_ALL_WARD_BED_SUCCESS:
      let allwardbeddeactive = [...state.wardbed];
      allwardbeddeactive.forEach((data, index) => {
        if (data._id === action.data._id) {
          allwardbeddeactive[index].isActive = !allwardbeddeactive[index]
            .isActive;
        }
      });
      return { ...state, wardBed: allwardbeddeactive, loading: false };
    case wardManagementConstants.DEACTIVE_ALL_WARD_BED_FAILURE:
      return { ...state, loading: false, err: action.error };

    default:
      return state;
  }
}
