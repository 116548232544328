import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const registrationMasterService = {
  getAll,
  add,
  update,
  setDefault,
  deleteMaster,
  getActiveOnly,
  getAllMasters,
};

function getAllMasters(payload) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/RegistrationSettings/Type/${payload.key}?Page=${payload.page}&Records=${payload.records}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAll(viewDeleted = false) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/RegistrationSettings?viewDeleted=${viewDeleted}&page=1&records=300`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getActiveOnly() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/masters?active=true`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/RegistrationSettings`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/RegistrationSettings/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function setDefault(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/RegistrationSettings/setFavourite/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function deleteMaster(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/RegistrationSettings/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
