import React, { Fragment, Component } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
    ModalHeader, ModalBody, ModalFooter, TabContent, TabPane,
} from 'reactstrap';
import Rodal from 'rodal';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Container } from 'reactstrap';


export default class ResetOtpComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row className="text-center"></Row>

                    <Rodal visible={this.props.visibleResetScreen}
                        onClose={this.props.hide}

                        showMask={false}
                    >
                        <ModalHeader>Reset Password</ModalHeader>
                        <ModalBody>

                            <p>To reset the password please enter new password here</p>
                            <FormGroup style={{ "width": "100%" }}>
                                <Label for="EnterPassword">New Password</Label>
                                <Input type="password" name="password" id="EnterPassword" onChange={(e) => this.props.onChangeHandler("password", e.target.value)}
                                    placeholder="Enter Password here..." />
                            </FormGroup>
                            <FormGroup style={{ "width": "100%" }}>
                                <Label for="ConfirmPassword">Confirm Password</Label>
                                <Input type="password" name="password" id="ConfirmPassword" onChange={(e) => this.props.onChangeHandler("confirmPassword", e.target.value)}
                                    placeholder="Re-Enter Password here..." />
                            </FormGroup>
                            <p className="error" style={{ display: "none" }}>Invalid Password</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="link" onClick={this.props.hide}>Cancel</Button>
                            <Button color="primary" onClick={this.props.onConfirmClickHandler}>Confirm</Button>
                        </ModalFooter>
                    </Rodal>
                </CSSTransitionGroup>
        )
    }
}