//import { authHeader } from '../store';
import {helperService} from "./helper.service";
import {apiUrl} from "../constants";

export const userGroupsService = {        
    getAll,
    add,
    addUserIdToGroup,
    getUserIdByUserGroupName
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/api/usergroups/getAllUserGroups`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" :helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/api/usergroups/addUserGroup`, requestOptions).then(helperService.handleResponse);
}


function addUserIdToGroup(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" :helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/api/usergroups/addUserIdsToUserGroup`, requestOptions).then(helperService.handleResponse);
}

function getUserIdByUserGroupName(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" :helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/api/usergroups/getUserIdsByUserGroupName`, requestOptions).then(helperService.handleResponse);
}