import { generalMasterConstants } from "../constants";

export function dischargemaster(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_DISCHARGE_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_DISCHARGE_TYPE_SUCCESS:
      return {
        ...state,
        dischargemaster: action.dischargemaster,
        loading: false,
      };
    case generalMasterConstants.GET_DISCHARGE_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_DISCHARGE_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_DISCHARGE_TYPE_SUCCESS:
      return {
        ...state,
        dischargemaster: [...state.dischargemaster, action.dischargemaster],
        loading: false,
      };
    case generalMasterConstants.ADD_DISCHARGE_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_DISCHARGE_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_DISCHARGE_TYPE_SUCCESS:
      let updatedState = [...state.dischargemaster];
      let index = updatedState.findIndex(
        (ob) => ob.id === action.dischargemaster.id
      );
      updatedState[index] = action.dischargemaster;
      return {
        ...state,
        dischargemaster: [...updatedState],
        loading: false,
      };
    case generalMasterConstants.UPDATE_DISCHARGE_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_DISCHARGE_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_DISCHARGE_TYPE_SUCCESS:
      const updatedDischargeMaster = state.dischargemaster
        ? state.dischargemaster.map((dischargeMaster) =>
            dischargeMaster.id === action.dischargemaster
              ? { ...dischargeMaster, deleted: !dischargeMaster.deleted }
              : dischargeMaster
          )
        : state.dischargemaster;

      return {
        ...state,
        dischargemaster: updatedDischargeMaster,
        loading: false,
      };
    case generalMasterConstants.DELETE_DISCHARGE_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
