import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const laboratoryMasterService = {
  getAll,
  add,
  update,
  setDefault,
  deleteMaster,
  getActiveOnly,
};

function getAll(key) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/labmasters/type/${key}`, requestOptions).then(
    helperService.handleResponse
  );

  // return fetch(`${apiUrl}/labmasters`, requestOptions).then(res=>res.json()).then(res=>{
  //      alert('response '+JSON.stringify(res))
  //  }).then(helperService.handleResponse);
}

function getActiveOnly() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/labmasters?active=true`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/labmasters`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/labmasters/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function setDefault(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/labmasters/setfavourite/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function deleteMaster(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/labmasters/delete/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}
