import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback
} from "reactstrap";

const InputFlexComponent = props => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <Label className="input-label-component">
            {props.label} <sup className="label-star">{props.star}</sup>
          </Label>
        </Col>
        <Col lg={8}>
          <Input
            style={{ height: props.height }}
            tag={props.tag}
            type={props.type}
            name={props.name}
            value={props.value}
            //   onChange={onChange}
            placeholder={props.placeholder}
            className={props.errors && props.touched ? "invalid" : "valid"}
          />
          {props.errors && props.touched ? (
            <FormFeedback>{props.errors}</FormFeedback>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default InputFlexComponent;
