import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"


export const discountAuthService = {  
    add, 
    update,   
    remove, 
    getAll   
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/discountAuth`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/discountAuth`, requestOptions).then(helperService.handleResponse);
}

function update(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/discountAuth/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/discountAuth/delete`, requestOptions).then(helperService.handleResponse);
}


