import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DropdownMenu,
  DropdownToggle,
  ListGroupItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import AppointmentPopup from "../AppointmentPopup";
import { styles } from "../ConsultantList/styles";
import { withStyles } from "@material-ui/core";
import cx from "classnames";
import { appointmentType } from "../../../constants";

class AppointmentItem extends Component {
  onSelectAppointment(id, e) {
    if (this.props.onClick) {
      this.props.onClick(id);
    }
  }
  render() {
    const { appointment, consultants,classes } = this.props;

    let timing,
      patientName,
      consultant,
      tokenNumber,
      id,
      appointment_date,
      appointment_type;
    patientName = appointment.patient_id
      ? appointment.patient_id.firstname +
        " " +
        appointment.patient_id.middlename +
        " " +
        appointment.patient_id.lastname
      : "";

    let cons_name =
      consultants &&
      consultants.find((cons) => {
        return cons.id === appointment.consultant_id;
      });
    appointment_date = appointment.appointment_date;
    timing = appointment.time_slot_from;
    let consultantName = cons_name ? cons_name.firstname : "No";
    //tokenNumber = appointment.tokenNumber;
    appointment_type = appointment.appointment_type === appointmentType.Walkin ? "W" : "A";

    let slotDuration = appointment.slot_duration;
    let timeAndDate = new Date(appointment.time_slot_from);

    let getMins = timeAndDate.setMinutes(
      timeAndDate.getMinutes() + slotDuration
    );

    return (
      <ListGroupItem
        tag="button"
        action
        className={classes.patientWidget}
        onClick={this.props.onClick}
        onDoubleClick={this.props.onDoubleClick}
      >
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div
              className="widget-content-left mr-2"
              style={{ fontSize: "12px" }}
            >
              {moment(timing).format("hh:mm A")}
              - <br />
              {moment(getMins).format("hh:mm A")}
              <br />
              {moment(appointment_date).format("DD/MM")}{" "}
            </div>
            <div className={cx("widget-content-left",classes.widgetAlign)}>
              <b>{patientName}</b>
              <br />
              Dr. {consultantName}
            </div>
            <div className={cx("widget-content-right",classes.widgetAlign)}>
              <div
                className="ml-auto badge badge-pill badge-warning"
                style={{ "background-color": "skyblue" }}
              >
                {appointment_type}
              </div>
              {/* <div className="ml-auto badge badge-pill badge-warning">
                {tokenNumber}
              </div> */}
              <UncontrolledButtonDropdown>
                <DropdownToggle color="basic">
                  <a
                    title="View"
                    className="text-primary font-icon-wrapper p-1"
                  >
                    <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                  </a>
                </DropdownToggle>
                <DropdownMenu
                  positionFixed={true}
                  className="dropdown-menu-left dropdown-menu-rounded "
                >
                  <AppointmentPopup
                    appointment={appointment}
                    onEdit={this.onSelectAppointment.bind(this, appointment)}
                  />
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }
}

function mapStateToProps(state) {
  const { consultants } = state;

  return { consultants: consultants.consultants };
}

const connectedAppointmentItem = connect(mapStateToProps)(AppointmentItem);
export default withStyles(styles)(connectedAppointmentItem);
