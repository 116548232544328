import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
class SweetAlertContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <SweetAlert {...this.props.sweetAlertProps} show={this.props.show} title="Alert"/>
            </Fragment>
        )
    }
}


const mapStateToProp = state => ({
    show: state.sweetAlert.show,
    message: state.sweetAlert.message,
    sweetAlertProps: state.sweetAlert.props
});

export default connect(mapStateToProp)(SweetAlertContainer);