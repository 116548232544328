import {helperService} from "./helper.service";
import {apiUrl} from "../constants";

export const referalConsultantService = {
    add,
    getById,
    getAll
};

function getAll(){
    const requestOptions = {
        method: 'GET',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()}   
        
    };
    return fetch(`${apiUrl}/referalconsultantpayabalapi/getAll`, requestOptions).then(helperService.handleResponse);
}

function getById(data){
    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
    return fetch(`${apiUrl}/referalconsultantpayabalapi/getConsltantServices`, requestOptions).then(helperService.handleResponse);
}

function add(data){
    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/referalconsultantpayabalapi`, requestOptions).then(helperService.handleResponse);
}