import { faEraser, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { valuesIn } from "lodash";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { otBookingActions, consultantActions } from "../../actions";
import { otBookingService, serviceMasterService } from "../../services";
import { otRoomService } from "../../services/operationTheatres/otRoom.service";
import PatientInfo from "../MyComponents/PatientInfo";
import PatientSearchBox from "../MyComponents/PatientSearchBox";
import Select from "../MyComponents/Select";
import StaffModal from "./StaffModal";
import OTChargesModal from "./OTChargesModal";
import ConsultantListModal from "./ConsultantListModal";
const maxDate = new Date().setFullYear(new Date().getFullYear() + 1);

class OTBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStaffModal: false,
      showOTChargesModal: false,
      showConsultantListModal: false,
      mode: "NEW",
      patient: null,
      operation_date: new Date(),
      initialValues: this.initialValues,
      serviceName: "",
      serviceAmount: 0,
      minTime: new Date(),
      maxTime: new Date(),
      otList: [],
      serviceOptions: [],
    };
  }

  initialValues = {
    patient_id: "",
    consultant_id: "",
    planned_procedure: "",
    service_type: "service",
    procedure_notes: "",
    operation_date: moment(new Date()).format("YYYY-MM-DD"),
    time_slot_from: new Date(),
    time_slot_to: new Date(),
    slot_duration: "",
    ot_id: "",
    staff_list: [],
    ot_charges: [],
    consultant_list: [],
  };

  onPatientSelected(patient, setFieldValue) {
    if (patient) {
      setFieldValue("patient_id", patient._id);
      setFieldValue("consultant_id", patient.consultant_id);
      this.onConsultantSelected({ target: { value: patient.consultant_id } });
      this.setState({ patient: patient });
    }
  }
  onSubmit = async (values) => {
    const { booking, mode } = this.props;
    const { patient } = this.state;
    if (patient) {
      const { dispatch } = this.props;
      let isBooked = false;
      if (mode === "NEW") {
        const data = { patient_id: patient._id, ...values };

        isBooked = await dispatch(otBookingActions.add(data));
      }
      if (mode === "EDIT") {
        const data = { id: booking._id, patient_id: patient._id, ...values };
        isBooked = await dispatch(otBookingActions.update(data));
      }
      if (isBooked) {
        this.props.toggle();
        if (this.props.otBooked) this.props.otBooked();
      }
    }
  };

  validationSchema = Yup.object({
    patient_id: Yup.string().required("Required"),
    consultant_id: Yup.string().required("Required"),
    planned_procedure: Yup.string().required("Required"),
    operation_date: Yup.string().required("Required"),
    time_slot_from: Yup.string().required("Required"),
    time_slot_to: Yup.string().required("Required"),
    slot_duration: Yup.string().required("Required"),
    procedure_notes: Yup.string(),
    ot_id: Yup.string().required("Required"),
    service_type: Yup.string(),
    staff_list: Yup.array(),
    ot_charges: Yup.array(),
    consultant_list: Yup.array(),
  });

  handleTimeChange(e, values, setFieldValue) {
    const { name, value } = e.target;
    let duration;
    if (name === "time_slot_from")
      duration = moment(values.time_slot_to, "HH:mm:ss").diff(
        moment(value, "HH:mm:ss")
      );
    else if (name === "time_slot_to")
      duration = moment(value, "HH:mm:ss").diff(
        moment(values.time_slot_from, "HH:mm:ss")
      );
    var d = moment.duration(duration);
    if (d.asHours() >= 0) {
      var s = Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
      setFieldValue("slot_duration", s);
    }
  }

  componentDidMount() {
    const { dispatch, booking } = this.props;
    dispatch(consultantActions.getAll());
    otRoomService
      .getAll()
      .then((res) => {
        if (res.success) {
          this.setState({ otList: res.data });
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    if (booking) {
      this.setState({ patient: booking.patient_id });
      let initialValues = {
        patient_id: booking.patient_id._id,
        consultant_id: booking.consultant_id.code,
        planned_procedure: booking.planned_procedure,
        operation_date: moment(booking.appointment_date).format("YYYY-MM-DD"),
        time_slot_from: booking.time_slot_from,
        time_slot_to: booking.time_slot_to,
        slot_duration: booking.slot_duration,
        procedure_notes: booking.procedure_notes,
        ot_id: booking.ot_id,
        service_type: booking.service_type,
        staff_list: booking.staff_list,
        ot_charges: booking.ot_charges,
        consultant_list: booking.consultant_list,
      };
      this.handleServiceTypeChange({ target: { value: booking.service_type } });

      initialValues.planned_procedure = parseInt(booking.planned_procedure);
      this.setState({ initialValues: initialValues });
    } else {
      this.handleServiceTypeChange({ target: { value: "service" } });
    }
  }

  componentDidUpdate(pp, ps) {
    const { booking } = this.props;
    if (booking !== pp.booking) {
      this.setState({ patient: booking.patient_id });
      let initialValues = {
        patient_id: booking.patient_id._id,
        consultant_id: booking.consultant_id.code,
        planned_procedure: booking.planned_procedure,
        operation_date: moment(booking.appointment_date).format("YYYY-MM-DD"),
        time_slot_from: booking.time_slot_from,
        time_slot_to: booking.time_slot_to,
        slot_duration: booking.slot_duration,
        procedure_notes: booking.procedure_notes,
        ot_id: booking.ot_id,
        service_type: booking.service_type,
        staff_list: booking.staff_list,
        ot_charges: booking.ot_charges,
        consultant_list: booking.consultant_list,
      };
      this.handleServiceTypeChange({ target: { value: booking.service_type } });

      initialValues.planned_procedure = parseInt(booking.planned_procedure);
      this.setState({ initialValues: initialValues });
    }
    //this.setState({ consumable_list: data.consumables_list });
  }

  onConsultantSelected(e, setFieldValue) {
    const target = e.target;
    const { consultants } = this.props;
    const selectedCons = consultants.find((cons) => cons.code === target.value);
    this.setState({
      consultant: selectedCons,
    });
  }

  onDateChange(e, setFieldValue) {
    const target = e.target;
    setFieldValue("operation_date", target.value);
  }

  //#region OT CHARGES Functions

  onToggleOTChargesModal() {
    this.setState({ showOTChargesModal: !this.state.showOTChargesModal });
  }

  onAssignChargesClick = (e) => {
    this.setState({ showOTChargesModal: true });
  };

  onOTChargesAssigned = (values, data, e) => {
    this.setState({
      initialValues: { ...values, ot_charges: data },
      showOTChargesModal: false,
    });
  };
  //#endregion

  onToggleStaffModal() {
    this.setState({ showStaffModal: !this.state.showStaffModal });
  }
  onAssignStaffClick = (e) => {
    this.setState({ showStaffModal: true });
  };

  onStaffAssigned = (values, data, e) => {
    this.setState({
      initialValues: { ...values, staff_list: data },
      showStaffModal: false,
    });
  };

  onToggleConsultantListModal() {
    this.setState({
      showConsultantListModal: !this.state.showConsultantListModal,
    });
  }
  onAssignConsultantsClick = (e) => {
    this.setState({ showConsultantListModal: true });
  };

  onConsultantsAssigned = (values, data, e) => {
    this.setState(
      {
        initialValues: { ...values, consultant_list: data },
        showConsultantListModal: false,
      },
      () => {
        // console.log('Callback',this.state.initialValues);
      }
    );
  };

  onServiceChange(e, values) {
    const { value } = e.target;
    if (!this.props.packageData) {
      return;
    }
    const { packages, services, groups } = this.props.packageData;

    let exist;
    let returnItem;
    if (value === 0) return;
    if (values.service_type === "package") {
      exist = packages && packages.find((item) => value === item.CODE);
      if (exist) {
        this.setState({
          serviceName: exist.PACKAGE_NAME,
          serviceAmount: exist.PACKAGE_AMT,
        });
        returnItem = { label: exist.PACKAGE_NAME, value: exist.CODE };
      }
    } else if (values.service_type === "service") {
      exist = services && services.find((item) => value === item.CODE);

      if (exist) {
        this.setState({
          serviceName: exist.SERVICE_NAME,
          serviceAmount: exist.SERVICE_AMT,
        });
        returnItem = { label: exist.SERVICE_NAME, value: exist.CODE };
      }
    } else if (values.service_type === "group") {
      exist = groups && groups.find((item) => value === item.CODE);
      if (exist) {
        this.setState({
          serviceName: exist.GROUP_NAME,
          serviceAmount: exist.GRP_AMT,
        });
        returnItem = { label: exist.GROUP_NAME, value: exist.CODE };
      }
    }

    return returnItem;
  }

  handleServiceTypeChange(e, that) {
    // console.log(e)
    const { packageData } = this.props;
    const { value } = e.target;
    let serviceOptions = null;
    if (packageData) {
      if (value === "package")
        serviceOptions =
          packageData.packages &&
          packageData.packages.map((item) => ({
            value: item.CODE,
            label: item.PACKAGE_NAME,
          }));
      else if (value === "service")
        serviceOptions =
          packageData.services &&
          packageData.services.map((item) => ({
            value: item.CODE,
            label: item.SERVICE_NAME,
          }));
      else if (value === "group")
        serviceOptions =
          packageData.groups &&
          packageData.groups.map((item) => ({
            value: item.CODE,
            label: item.GROUP_NAME,
          }));
    }
    this.setState({ serviceOptions });
  }

  onClearClicked() {
    this.setState({ patient: null });
  }

  render() {
    const { patient, mode } = this.state;
    return (
      <Formik
        initialValues={this.state.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
        enableReinitialize
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            getFieldProps,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <Modal
              isOpen={this.props.showModal}
              toggle={this.props.toggle}
              style={{ width: "1000px" }}
            >
              <ModalHeader toggle={this.props.toggle}>Schedule OT</ModalHeader>
              <Form>
                <fieldset disabled={this.props.mode === "VIEW" ? true : false}>
                  <ModalBody>
                    <Fragment>
                      <ReactCSSTransitionGroup
                        component="div"
                        transitionName="TabsAnimation"
                        transitionAppear={true}
                        transitionAppearTimeout={0}
                        transitionEnter={false}
                        transitionLeave={false}
                      >
                        <PatientSearchBox
                          onPatientSelected={(pat) =>
                            this.onPatientSelected(pat, setFieldValue)
                          }
                        />
                        {patient && (
                          <Row>
                            <Col sm={12}>
                              <br />
                              <PatientInfo patient={patient} />
                            </Col>
                          </Row>
                        )}
                        <fieldset disabled={patient ? false : true}>
                          <Row>
                            <Col sm="5">
                              <Row>
                                <Col sm={3}>
                                  <FormGroup>
                                    <Label for="service_type">
                                      Service Type{" "}
                                    </Label>
                                    <CustomInput
                                      type="select"
                                      bsSize="sm"
                                      invalid={
                                        errors.service_type &&
                                        touched.service_type
                                          ? true
                                          : false
                                      }
                                      name="service_type"
                                      {...getFieldProps("service_type")}
                                      onChange={(e) => {
                                        handleChange(e);
                                        this.handleServiceTypeChange(e);
                                      }}
                                    >
                                      <option value="service">Service</option>
                                      <option value="package">Package</option>
                                      <option value="group">Group</option>
                                    </CustomInput>

                                    {errors.service_type &&
                                      touched.service_type && (
                                        <FormFeedback>
                                          {errors.service_type}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                                <Col sm={9}>
                                  <FormGroup>
                                    <Label for="package">
                                      Planned Procedure{" "}
                                    </Label>

                                    <Select
                                      style={{ padding: 0 }}
                                      label=""
                                      readOnly={
                                        !patient || this.props.mode === "VIEW"
                                          ? true
                                          : false
                                      }
                                      name="planned_procedure"
                                      required="true"
                                      value={values.planned_procedure}
                                      data={{
                                        dataSrc: "values",
                                        values: this.state.serviceOptions || [],
                                      }}
                                      onChange={(v) => {
                                        this.onServiceChange(v, values);
                                        setFieldValue(
                                          "planned_procedure",
                                          v.target.value
                                        );
                                      }}
                                    />

                                    {errors.planned_procedure &&
                                      touched.planned_procedure && (
                                        <FormFeedback>
                                          {errors.planned_procedure}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                              </Row>

                              <FormGroup>
                                <Label for="procedure_notes">
                                  Procedure Notes
                                </Label>
                                <Input
                                  type="textarea"
                                  style={{ resize: "none", height: "92px" }}
                                  bsSize="sm"
                                  name="procedure_notes"
                                  placeholder="Procedure Notes"
                                  {...getFieldProps("procedure_notes")}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="7">
                              <Row>
                                <Col sm="4">
                                  <FormGroup>
                                    <Label htmlFor="color">Consultant *</Label>
                                    <CustomInput
                                      type="select"
                                      bsSize="sm"
                                      invalid={
                                        errors.consultant_id &&
                                        touched.consultant_id
                                          ? true
                                          : false
                                      }
                                      name="consultant_id"
                                      value={values.consultant_id}
                                      {...getFieldProps("consultant_id")}
                                      // onChange={(e) => {
                                      //   this.onConsultantSelected(
                                      //     e,
                                      //     setFieldValue
                                      //   );
                                      //   handleChange(e);
                                      // }}
                                    >
                                      <option value="">Select</option>
                                      {this.props.consultants.map((item) => (
                                        <option value={item.code}>
                                          {item.firstname +
                                            " " +
                                            (item.lastname
                                              ? item.lastname
                                              : "")}
                                        </option>
                                      ))}
                                    </CustomInput>
                                    {errors.consultant_id &&
                                      touched.consultant_id && (
                                        <FormFeedback>
                                          {errors.consultant_id}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                                <Col sm="4">
                                  <FormGroup>
                                    <Label for="operation_date">
                                      Scheduled On *
                                    </Label>
                                    <InputGroup>
                                      <Input
                                        type="date"
                                        name="operation_date"
                                        bsSize="sm"
                                        placeholder=""
                                        value={values.operation_date}
                                        min={moment().format("YYYY-MM-DD")}
                                        //   max={new Date()}
                                        onChange={(e) => {
                                          this.onDateChange(e, setFieldValue);
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        //{...getFieldProps("operation_date")}
                                      />

                                      {errors.appointment_date &&
                                        touched.appointment_date && (
                                          <FormFeedback>
                                            {errors.appointment_date}
                                          </FormFeedback>
                                        )}
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="ot_id">OT Number</Label>

                                    <CustomInput
                                      type="select"
                                      bsSize="sm"
                                      invalid={
                                        errors.ot_id && touched.ot_id
                                          ? true
                                          : false
                                      }
                                      name="ot_id"
                                      value={values.ot_id}
                                      {...getFieldProps("ot_id")}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {this.state.otList.map((item) => (
                                        <option value={item.code}>
                                          {item.room_name}
                                        </option>
                                      ))}
                                    </CustomInput>

                                    {errors.ot_id && touched.ot_id && (
                                      <FormFeedback>
                                        {errors.ot_id}
                                      </FormFeedback>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm={4}>
                                  <FormGroup>
                                    <Label for="time_slot_from">
                                      Time from *
                                    </Label>

                                    <DatePicker
                                      name="time_slot_from"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      disabled={
                                        this.props.mode === "VIEW"
                                          ? true
                                          : false
                                      }
                                      onChange={(dateString) => {
                                        setFieldValue(
                                          "time_slot_from",
                                          dateString
                                        );
                                        this.handleTimeChange(
                                          {
                                            target: {
                                              name: "time_slot_from",
                                              value: dateString,
                                            },
                                          },
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                      onSelect={(dateString) => {
                                        setFieldValue(
                                          "time_slot_from",
                                          dateString
                                        );
                                        this.handleTimeChange(
                                          {
                                            target: {
                                              name: "time_slot_from",
                                              value: dateString,
                                            },
                                          },
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                      autoComplete="off"
                                      className="form-control"
                                      minTime={moment()
                                        .startOf("day")
                                        .toDate()}
                                      maxTime={moment()
                                        .endOf("day")
                                        .toDate()}
                                      value={
                                        values.time_slot_from !== ""
                                          ? moment(
                                              values.time_slot_from
                                            ).format("hh:mm a")
                                          : new Date(this.state.minTime)
                                      }
                                      timeIntervals={30}
                                      dateFormat="h:mm A"
                                    />

                                    {errors.time_slot_from &&
                                      touched.time_slot_from && (
                                        <FormFeedback>
                                          {errors.time_slot_from}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                                <Col sm={4}>
                                  <FormGroup>
                                    <Label for="time_slot_from">
                                      Time to *
                                    </Label>
                                    <DatePicker
                                      name="time_slot_to"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      onChange={(dateString) => {
                                        setFieldValue(
                                          "time_slot_to",
                                          dateString
                                        );
                                        this.handleTimeChange(
                                          {
                                            target: {
                                              name: "time_slot_to",
                                              value: dateString,
                                            },
                                          },
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                      onSelect={(dateString) => {
                                        setFieldValue(
                                          "time_slot_to",
                                          dateString
                                        );
                                        this.handleTimeChange(
                                          {
                                            target: {
                                              name: "time_slot_to",
                                              value: dateString,
                                            },
                                          },
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                      autoComplete="off"
                                      className="form-control"
                                      // minTime={values.time_slot_from?values.time_slot_from:new Date()}
                                      // maxTime={moment().endOf('day').toDate()}
                                      minTime={moment()
                                        .startOf("day")
                                        .toDate()}
                                      maxTime={moment()
                                        .endOf("day")
                                        .toDate()}
                                      value={
                                        values.time_slot_to !== ""
                                          ? moment(values.time_slot_to).format(
                                              "hh:mm a"
                                            )
                                          : new Date(this.state.minTime)
                                      }
                                      timeIntervals={30}
                                      disabled={
                                        this.props.mode === "VIEW"
                                          ? true
                                          : false
                                      }
                                      // timeIntervals={values.slot_duration}
                                      dateFormat="h:mm A"
                                    />
                                    {errors.time_slot_from &&
                                      touched.time_slot_from && (
                                        <FormFeedback>
                                          {errors.time_slot_from}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="slot_duration">
                                      Slot Duration
                                    </Label>
                                    <Input
                                      type="text"
                                      autoComplete="off"
                                      bsSize="sm"
                                      invalid={
                                        errors.slot_duration &&
                                        touched.slot_duration
                                          ? true
                                          : false
                                      }
                                      name="slot_duration"
                                      value={values.slot_duration}
                                      {...getFieldProps("slot_duration")}
                                    />

                                    {errors.slot_duration &&
                                      touched.slot_duration && (
                                        <FormFeedback>
                                          {errors.slot_duration}
                                        </FormFeedback>
                                      )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <Button
                                    style={{ marginTop: 31 }}
                                    color="primary"
                                    className="btn btn-block"
                                    onClick={this.onAssignChargesClick.bind(
                                      this
                                    )}
                                  >
                                    Assign Charges
                                  </Button>
                                  <OTChargesModal
                                    serviceName={this.state.serviceName}
                                    serviceAmount={this.state.serviceAmount}
                                    charges_list={values.ot_charges}
                                    onSubmit={this.onOTChargesAssigned.bind(
                                      this,
                                      values
                                    )}
                                    showModal={this.state.showOTChargesModal}
                                    toggle={this.onToggleOTChargesModal.bind(
                                      this
                                    )}
                                  />
                                </Col>

                                <Col md="4">
                                  <Button
                                    style={{ marginTop: 31 }}
                                    color="primary"
                                    className="btn btn-block"
                                    onClick={this.onAssignConsultantsClick.bind(
                                      this
                                    )}
                                  >
                                    Assign Consultants
                                  </Button>
                                  <ConsultantListModal
                                    consultant_list={values.consultant_list}
                                    onSubmit={this.onConsultantsAssigned.bind(
                                      this,
                                      values
                                    )}
                                    showModal={
                                      this.state.showConsultantListModal
                                    }
                                    toggle={this.onToggleConsultantListModal.bind(
                                      this
                                    )}
                                  />
                                </Col>
                                <Col md="4">
                                  <Button
                                    style={{ marginTop: 31 }}
                                    color="primary"
                                    className="btn btn-block"
                                    onClick={this.onAssignStaffClick.bind(this)}
                                  >
                                    Assign Staff
                                  </Button>
                                  <StaffModal
                                    staff_list={values.staff_list}
                                    onSubmit={this.onStaffAssigned.bind(
                                      this,
                                      values
                                    )}
                                    showModal={this.state.showStaffModal}
                                    toggle={this.onToggleStaffModal.bind(this)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </fieldset>
                      </ReactCSSTransitionGroup>
                    </Fragment>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="warning"
                      className="btn"
                      onClick={(e) => {
                        handleReset();
                        this.onClearClicked();
                      }}
                    >
                      <FontAwesomeIcon icon={faEraser} size="1x" /> Clear
                    </Button>

                    <Button color="primary" className="btn" type="submit">
                      <FontAwesomeIcon icon={faSave} size="1x" /> &nbsp;
                      {this.state.mode === "NEW" && "Book OT"}
                      {this.state.mode === "EDIT" && "Update OT"}
                    </Button>
                  </ModalFooter>
                </fieldset>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
function mapStateToProps(state) {
  const {
    consultants,
    departments,
    serviceMaster,
    bookings,
    registrationMaster,
  } = state;
  return {
    consultants: consultants.consultants,
    departments: departments.departments,
    packageData: serviceMaster.data,
    booking: bookings.selected_booking,
    masters: registrationMaster.data,
  };
}

const connectedOTBooking = connect(mapStateToProps)(OTBooking);
export default connectedOTBooking;
