import {
    faPrint
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { opBillingService } from '../../../services';
import PrintBillModal from '../../OPDBilling/demoprint';

class Reciepts extends React.Component {
    constructor(props)
    {super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            allbills:[],
            openModal : false,
          
        };

    }

    onPrintClick(data){
        this.setState({
            billNo : data.bill_no,
            openModal: true
        })
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
componentDidMount()
{
    const {patient}=this.props;
    if(patient)
    opBillingService.getBillByUHID({UHID:patient.patient_uhid}).then(res=>{
    
     if(res.success)
     {
         const allBills=res.data;
       const billList=[];
       var ind=0;
       allBills.forEach(element => {
                if(!element.isCanceled && !element.isRefunded){
                    billList.push({sno:ind+1,...element})
                    ind++;
                }
            });
        
         this.setState({billList:billList})
     }
    })
}

 columns = [
    {   name: 'Sr No.',
        sortable: true,
            selector:'sno',
            width:"50px" 
    },
          
    {
        name: 'Bill No',
        sortable: true,
        selector:(row)=>( row.bill_no),
        width:"150px"
    },
    {
        name: 'Bill Date',
        sortable: true,
        selector:(row)=>( moment(row.created_on).format("DD/MM/YYYY HH:mm:ss")),
        width:"100px"
    },
    {
        name: 'Bill Amount',
        sortable: true,
        selector:(row)=>( row.bill_summary? row.bill_summary.totalAmount : 0),
        width:"100px"
    },
    {
        name: 'Discount Percentage',
        sortable: true,
        selector:(row)=>( row.bill_summary? row.bill_summary.discount : 0),
        width:"100px"
    },
    {
        name: 'Discount Amount',
        sortable: true,
        selector:(row)=>( row.discount_amount? row.discount_amount : 0),
        width:"100px"
    },
    {
        name: 'Tax',
        sortable: true,
        selector:(row)=>( row.tax? row.tax : 0),
        width:"70px"
    },
    {
        name: 'Cash',
        sortable: true,
        selector:(row)=>( row.cash_amount? row.cash_amount : 0),
        width:"70px"
    },
    {
        name: 'Card',
        sortable: true,
        selector:(row)=>( row.card_amount? row.card_amount : 0),
        width:"70px"
    },
    {
        name: 'Cheque',
        sortable: true,
        selector:(row)=>( row.cheque_amount? row.cheque_amount : 0),
        width:"70px"
    },
   
    {
        name: 'Net Amount',
        sortable: true,
        selector:(row)=>( row.bill_summary? row.bill_summary.totalAmount : 0),
        width:"100px"
    },
    {
        name: 'Balance Amount',
        sortable: true,
        selector:(row)=>( row.due_amount? row.due_amount : 0),
        width:"100px"
    },
    {
        name: 'Refund Amount',
        sortable: true,
        selector:(row)=>( row.isRefunded? row.refunded_amount : 0),
        width:"100px"
    },
    
    {
        name: 'Created By',
        sortable: true,
        selector:(row)=>( row.created_by),
        width:"100px"
    },

    {
        name: 'Actions',
        sortable: true,
        width:'180px',
        cell:row=><>
        {(row.isRefunded && !row.isCanceled)?<span style={{color:"red"}}> Refuned</span>:row.isCanceled ? <span style={{color:"red"}}> Cancelled</span>:
       <Button className="mb-2 mr-2 btn-icon font-size-10px" onClick={()=>{this.onPrintClick(row)}} color="success" >
       <FontAwesomeIcon icon={faPrint} size="1x"  style={{ marginRight: "3px" }}/>
  Print Bill
</Button>}

                                </>
    }
]

    render() {
        let {billList} = this.state;
      
      
        
        return (
            <Fragment>
              
                 <Card className="main-card mb-3">
                                    <CardBody>
                                         <Nav pills fill>
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => {
                                                        this.toggle('1');
                                                    }}
                                                >
                                                    OP
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => {
                                                        this.toggle('2');
                                                    }}
                                                >
                                                    IP
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => {
                                                        this.toggle('3');
                                                    }}
                                                >
                                                    Advances
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: this.state.activeTab === '4' })}
                                                    onClick={() => {
                                                        this.toggle('4');
                                                    }}
                                                >
                                                    Dues Payables
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: this.state.activeTab === '5' })}
                                                    onClick={() => {
                                                        this.toggle('5');
                                                    }}
                                                >
                                                    Refunds Receivables
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="1">
                                            <DataTable 
                       keyField="_id"
                       dense
                       noHeader
                       pagination={true}
                       paginationPerPage={25}
                    paginationRowsPerPageOptions={[25,50,75,100]}
                       striped
                       columns={this.columns} 
                       data={billList}/>    
                    
                                            </TabPane>
                                            <TabPane tabId="2">
                                            <DataTable 
                       keyField="_id"
                       dense
                       noHeader
                       pagination={true}
                       paginationPerPage={25}
                    paginationRowsPerPageOptions={[25,50,75,100]}
                       striped
                       columns={this.columns} 
                       data={billList}/>   
                                            </TabPane>
                                            <TabPane tabId="3">
                                            <DataTable 
                       keyField="_id"
                       dense
                       noHeader
                       pagination={true}
                       paginationPerPage={25}
                    paginationRowsPerPageOptions={[25,50,75,100]}
                       striped
                       columns={this.columns} 
                       data={billList}/>   
                                            </TabPane>
                                            <TabPane tabId="4">
                                            <DataTable 
                       keyField="_id"
                       dense
                       noHeader
                       pagination={true}
                       paginationPerPage={25}
                    paginationRowsPerPageOptions={[25,50,75,100]}
                       striped
                       columns={this.columns} 
                       data={billList}/>   
                                            </TabPane>
                                            <TabPane tabId="5">
                                            <DataTable 
                       keyField="_id"
                       dense
                       noHeader
                       pagination={true}
                       paginationPerPage={25}
                    paginationRowsPerPageOptions={[25,50,75,100]}
                       striped
                       columns={this.columns} 
                       data={billList}/>   
                                            </TabPane>
                                        </TabContent>
                                        {this.state.openModal? 
                            <PrintBillModal
                          billNo ={this.state.billNo}
                          printType = {this.state.printing_option}
                         openModal={this.state.openModal} printing_option={this.state.printing_option}
                         toggle={this.toggleBilling}></PrintBillModal>
                         :null}
                                    </CardBody>
                                </Card>
     
          
           
            </Fragment>
        )
    }
}

export default Reciepts;



