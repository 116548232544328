//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const drugManufacturersService = {
  getAll,
  add,
  update,
  remove,
  get,
  getByDate,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    // headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/PharmaManufacturers`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/drugManufacturers?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugManufacturers/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/drugManufacturers`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/PharmaManufacturers/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/drugManufacturers/delete`, requestOptions).then(
    helperService.handleResponse
  );
}
