import React, { Component } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
    ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

import Rodal from 'rodal';
import { Row, Button } from 'reactstrap';


export default class FormValidation extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row className="text-center"></Row>
                <Rodal visible={this.props.visible}
                    onClose={this.props.hide}
                    showMask={false}
                >
                    <ModalHeader></ModalHeader>
                    
                    <ModalBody>
                        <p>Please correct the below error and re-submit:</p>
                        <p style={{color: "red"}}>{this.props.errormessage} </p>
                    </ModalBody>
                      
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.hide}>OK</Button>
                        
                    </ModalFooter>
                </Rodal>
            </CSSTransitionGroup>
        )
    }
}