import { faEraser, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  appointmentActions,
  consultantActions,
  departmentActions,
  serviceMasterActions,
} from "../../../actions";
// import Select from "react-select";
import { appointmentService, serviceMasterService } from "../../../services";
import Select from "../../MyComponents/Select";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

class NewAppointment extends Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      patient: null,
      consultant: null,
      appointment_date: new Date(),
      allSlots: null,
      slots_on_date: null,
      groups: [],
      bookedSlots: null,
      nextAppointmentTime: "",
      minTime: "",
      maxTime: "",
      initialValues: this.initialValues,
    };
  }
  setFieldValue = null;

  onPatientSelected(patient, setFieldValue) {
    if (patient) {
      setFieldValue("patient_uhid", patient.patient_uhid);
      setFieldValue("consultant_id", patient.consultant_id);
      this.onConsultantSelected({ target: { value: patient.consultant_id } });
      this.setState({ patient: patient });
      appointmentService.getLastAppointment().then((res) => {
        if (res.success) {
          this.setFieldValue &&
            this.setFieldValue(
              "roomNumber",
              res.appointment[0] ? res.appointment[0].roomNumber : "1"
            );
        }
      });
    }
  }

  initialValues = {
    patient_uhid: "",
    consultant_id: "",
    procedure_notes: "",
    appointment_type: "by_Appointment",
    appointment_date: moment(new Date()).format("YYYY-MM-DD"),
    time_slot_from: new Date(),
    slot_duration: "15",
    planned_procedure: "",
    roomNumber: "",
    service_type: "",
    slot: "A",
  };

  onSubmit = (values) => {
    const { transaction } = this.props;

    const { patient } = this.props;
    if (patient) {
      const { dispatch } = this.props;
      const data = {
        patient_id: patient._id,
        department_id: patient.department_id,
        hospital_code: transaction.customer_id,
        transaction_code: transaction.customer_location_id,
        ...values,
      };
      dispatch(appointmentActions.add(data));
    }
  };

  validationSchema = Yup.object({
    patient_uhid: Yup.string().required("Required"),
    consultant_id: Yup.string().required("Required"),
    appointment_type: Yup.string().required("Required"),
    planned_procedure: Yup.string(),
    appointment_date: Yup.string().required("Required"),
    time_slot_from: Yup.string().required("Required"),
    slot_duration: Yup.string().required("Required"),
    procedure_notes: Yup.string(),
    roomNumber: Yup.string(),
    service_type: Yup.string(),
    slot: Yup.string().required("Required"),
  });

  componentDidMount() {
    const { dispatch, appointment, consultant, patient } = this.props;
    dispatch(consultantActions.getAll());
    dispatch(departmentActions.getAll());
    dispatch(serviceMasterActions.getAllPackages());
    serviceMasterService
      .getAllGroups()
      .then((res) => {
        this.setState({ groups: res.data });
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });

    if (patient) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          patient_uhid: patient.patient_uhid,
          consultant_id: patient.consultant_id,
        },
      });

      this.onConsultantSelected({ target: { value: patient.consultant_id } });
    }
  }

  onConsultantSelected(e, setFieldValue) {
    const target = e.target;
    const { consultants, departments } = this.props;
    const selectedCons = consultants.find((cons) => cons.code === target.value);
    if (selectedCons) {
      const selectedDept = departments.find(
        (dep) => dep.code === selectedCons.department
      );
      setFieldValue &&
        setFieldValue("slot_duration", selectedCons.slot_duration);
      appointmentService
        .getBookedSlots(selectedCons._id, this.state.appointment_date)
        .then((result) => {
          if (result.success) {
            if (result.appointments) {
              let slots = result.appointments.slots;
              let nextAppTime = slots[slots.length - 1].fromTime;
              this.setState({
                bookedSlots: result.appointments,
                nextAppointmentTime: nextAppTime,
              });
            }
          }
        })
        .catch((err) => {
          //alert(JSON.stringify(err))
        });

      this.setState(
        {
          allSlots: selectedCons.slot_setting,
          consultant: selectedCons,
          department: selectedDept,
        },
        () => {
          const date = new Date();
          const day = date.getDay();
          const allSlots = this.state.allSlots;
          const result =
            allSlots && allSlots.find((item) => item.day === days[day]);

          this.setState({ slots_on_date: result }, () => {
            this.onSlotChanged({ target: { value: "A" } });
          });
        }
      );
    }
  }

  onDateChange(e, setFieldValue) {
    const target = e.target;
    const date = new Date(target.value);
    const day = date.getDay();
    const allSlots = this.state.allSlots;
    const result = allSlots && allSlots.find((item) => item.day === days[day]);
    this.setState({ slots_on_date: result, appointment_date: date });
    setFieldValue("appointment_date", target.value);
  }

  onSlotChanged(data) {
    let slot = data.target.value;
    let minTime, maxTime;
    let { slots_on_date } = this.state;
    switch (slot) {
      case "A":
        minTime = slots_on_date && slots_on_date.slotA.from;
        maxTime = slots_on_date && slots_on_date.slotA.to;
        break;
      case "B":
        minTime = slots_on_date && slots_on_date.slotB.from;
        maxTime = slots_on_date && slots_on_date.slotB.to;
        break;
      case "C":
        minTime = slots_on_date && slots_on_date.slotC.from;
        maxTime = slots_on_date && slots_on_date.slotC.to;
        break;
      default:
        break;
    }

    this.setState({
      minTime: moment(minTime, "hh:mm a").toDate(),
      maxTime: moment(maxTime, "hh:mm a").toDate(),
    });
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const { packageData } = this.props;

    const {
      patient,
      department,
      slots_on_date,
      groups,
      bookedSlots,
      nextAppointmentTime,
    } = this.state;

    return (
      <Formik
        initialValues={this.state.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
        enableReinitialize
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            getFieldProps,
            setFieldValue,
            setFieldTouched,
          } = props;
          console.log(errors);
          this.setFieldValue = setFieldValue;

          let serviceOptions = null;
          {
            if (values.service_type === "package")
              serviceOptions =
                packageData &&
                packageData.packages &&
                packageData.packages.map((item) => ({
                  value: item.CODE,
                  label: item.PACKAGE_NAME,
                }));
            else if (values.service_type === "service")
              serviceOptions =
                packageData &&
                packageData.services &&
                packageData.services.map((item) => ({
                  value: item.CODE,
                  label: item.SERVICE_NAME,
                }));
            else if (values.service_type === "group")
              serviceOptions =
                groups &&
                groups.map((item) => ({
                  value: item.CODE,
                  label: item.GROUP_NAME,
                }));
          }

          return (
            <Form>
              <Fragment>
                <ReactCSSTransitionGroup
                  component="div"
                  transitionName="TabsAnimation"
                  transitionAppear={true}
                  transitionAppearTimeout={0}
                  transitionEnter={false}
                  transitionLeave={false}
                >
                  <Row>
                    <Col sm={8} className="offset-sm-2">
                      <h4>Book Appointment</h4>
                      <fieldset>
                        <FormGroup>
                          <Label htmlFor="color">Consultant *</Label>
                          <CustomInput
                            type="select"
                            bsSize="sm"
                            invalid={
                              errors.consultant_id && touched.consultant_id
                                ? true
                                : false
                            }
                            name="consultant_id"
                            value={values.consultant_id}
                            {...getFieldProps("consultant_id")}
                            onChange={(e) => {
                              this.onConsultantSelected(e, setFieldValue);
                              handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {this.props.consultants.map((item) => (
                              <option value={item.code}>
                                {item.firstname +
                                  " " +
                                  (item.lastname ? item.lastname : "")}
                              </option>
                            ))}
                          </CustomInput>
                          {errors.consultant_id && touched.consultant_id && (
                            <FormFeedback>{errors.consultant_id}</FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          {slots_on_date ? (
                            <>
                              <div>
                                <Label style={{ float: "left" }}>Slots :</Label>
                                <Label style={{ float: "right" }}>
                                  Department : {department && department.name}
                                </Label>
                                <div style={{ clear: "both" }} />
                              </div>
                              <Row>
                                {slots_on_date.slotA.from !== "0:00 pm" &&
                                  slots_on_date.slotA.to !== "0:00 pm" && (
                                    <Col md={4}>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="radio"
                                            value="A"
                                            name="slot"
                                            checked={
                                              values.slot === "A" ? true : false
                                            }
                                            onChange={(e) => {
                                              this.onSlotChanged(e);
                                              handleChange(e);
                                            }}
                                          />
                                          <span className="badge badge-pill badge-primary">
                                            {" "}
                                            A : {slots_on_date.slotA.from}-
                                            {slots_on_date.slotA.to}
                                          </span>
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {slots_on_date.slotB.from !== "0:00 pm" &&
                                  slots_on_date.slotB.to !== "0:00 pm" && (
                                    <Col md={4}>
                                      {" "}
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="radio"
                                            value="B"
                                            name="slot"
                                            checked={
                                              values.slot === "B" ? true : false
                                            }
                                            onChange={(e) => {
                                              this.onSlotChanged(e);
                                              handleChange(e);
                                            }}
                                          />
                                          <span className="badge badge-pill badge-primary">
                                            {" "}
                                            B : {slots_on_date.slotB.from}-
                                            {slots_on_date.slotB.to}
                                          </span>
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {slots_on_date.slotC.from !== "0:00 pm" &&
                                  slots_on_date.slotC.to !== "0:00 pm" && (
                                    <Col md={4}>
                                      {" "}
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="radio"
                                            value="C"
                                            name="slot"
                                            checked={
                                              values.slot === "C" ? true : false
                                            }
                                            onChange={(e) => {
                                              this.onSlotChanged(e);
                                              handleChange(e);
                                            }}
                                          />
                                          <span className="badge badge-pill badge-primary">
                                            {" "}
                                            C : {slots_on_date.slotC.from}-
                                            {slots_on_date.slotC.to}
                                          </span>
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                            </>
                          ) : (
                            <FormGroup>
                              <br />
                              <Label htmlFor="color">Slot Details</Label>
                            </FormGroup>
                          )}
                        </FormGroup>

                        <Row>
                          <Col sm={6}>
                            <FormGroup>
                              <Label for="appointment_type">
                                Appointment Type *
                              </Label>
                              <CustomInput
                                type="select"
                                bsSize="sm"
                                invalid={
                                  errors.appointment_type &&
                                  touched.appointment_type
                                    ? true
                                    : false
                                }
                                name="appointment_type"
                                {...getFieldProps("appointment_type")}
                              >
                                <option value="Appointment">
                                  By Appointment
                                </option>
                                <option value="WalkIn">Walk In</option>
                              </CustomInput>
                              {errors.appointment_type &&
                                touched.appointment_type && (
                                  <FormFeedback>
                                    {errors.appointment_type}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Label for="dob">Appointment Date *</Label>
                              <InputGroup>
                                <Input
                                  type="date"
                                  name="appointment_date"
                                  bsSize="sm"
                                  placeholder=""
                                  value={values.appointment_date}
                                  onChange={(e) => {
                                    this.onDateChange(e, setFieldValue);
                                  }}
                                  onBlur={handleBlur}
                                  //{...getFieldProps("appointment_date")}
                                />

                                {errors.appointment_date &&
                                  touched.appointment_date && (
                                    <FormFeedback>
                                      {errors.appointment_date}
                                    </FormFeedback>
                                  )}
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <Label for="time_slot_from">Time from *</Label>

                              <br />
                              <DatePicker
                                name="time_slot_from"
                                showTimeSelect
                                showTimeSelectOnly
                                onChange={(dateString) =>
                                  setFieldValue("time_slot_from", dateString)
                                }
                                onSelect={(dateString) =>
                                  setFieldValue("time_slot_from", dateString)
                                }
                                autoComplete="off"
                                className="form-control"
                                minTime={new Date(this.state.minTime)}
                                maxTime={new Date(this.state.maxTime)}
                                value={
                                  values.time_slot_from !== ""
                                    ? moment(values.time_slot_from).format(
                                        "hh:mm a"
                                      )
                                    : new Date(this.state.minTime)
                                }
                                timeIntervals={values.slot_duration}
                                dateFormat="h:mm A"
                              />

                              {errors.time_slot_from &&
                                touched.time_slot_from && (
                                  <FormFeedback>
                                    {errors.time_slot_from}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label for="slot_duration">Slot Duration</Label>
                              <CustomInput
                                type="select"
                                bsSize="sm"
                                invalid={
                                  errors.slot_duration && touched.slot_duration
                                    ? true
                                    : false
                                }
                                name="slot_duration"
                                {...getFieldProps("slot_duration")}
                                //  value={consultant&&consultant.slot_duration+""}
                              >
                                <option value="5">5 minutes</option>
                                <option value="10">10 minutes</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="45">45 minutes</option>
                                <option value="45">60 minutes</option>
                              </CustomInput>

                              {errors.slot_duration &&
                                touched.slot_duration && (
                                  <FormFeedback>
                                    {errors.slot_duration}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>

                          <Col md={4}>
                            <FormGroup>
                              <Label for="roomNumber">Room Number</Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                invalid={
                                  errors.roomNumber && touched.roomNumber
                                    ? true
                                    : false
                                }
                                name="roomNumber"
                                {...getFieldProps("roomNumber")}
                              />

                              {errors.roomNumber && touched.roomNumber && (
                                <FormFeedback>{errors.roomNumber}</FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={3}>
                            <FormGroup>
                              <Label for="service_type">Service Type </Label>
                              <CustomInput
                                type="select"
                                bsSize="sm"
                                invalid={
                                  errors.service_type && touched.service_type
                                    ? true
                                    : false
                                }
                                name="service_type"
                                {...getFieldProps("service_type")}
                              >
                                <option value=""></option>
                                <option value="service">Service</option>
                                <option value="package">Package</option>
                                <option value="group">Group</option>
                              </CustomInput>

                              {errors.service_type && touched.service_type && (
                                <FormFeedback>
                                  {errors.service_type}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm={9}>
                            <FormGroup>
                              <Label for="package">Planned Procedure</Label>

                              <Select
                                style={{ padding: 0 }}
                                label=""
                                name="planned_procedure"
                                required="true"
                                value={values.planned_procedure}
                                // readOnly={readOnly}
                                data={{
                                  dataSrc: "values",
                                  values: serviceOptions || [],
                                }}
                                onChange={(v) => {
                                  setFieldValue(
                                    "planned_procedure",
                                    v.target.value + ""
                                  );
                                  // setFieldTouched('planned_procedure',true)
                                }}
                              />
                              {/* <CustomInput
                          type="select"
                          bsSize="sm"
                          invalid={errors.planned_procedure && touched.planned_procedure ? true : false}
                          name="planned_procedure"
                          {...getFieldProps("planned_procedure")}
                        >
                        <option value="">Select</option>
                      
                          {serviceOptions}
                        
                        </CustomInput> */}
                              {errors.planned_procedure &&
                                touched.planned_procedure && (
                                  <FormFeedback>
                                    {errors.planned_procedure}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup>
                          <Label for="planned_procedure">Procedure Notes</Label>
                          <Input
                            type="textarea"
                            bsSize="sm"
                            name="procedure_notes"
                            placeholder="Procedure Notes"
                            {...getFieldProps("procedure_notes")}
                          />
                        </FormGroup>

                        <br />
                        <Row>
                          <Col sm={4} className="offset-sm-4">
                            <Button
                              color="warning"
                              className="btn btn-block"
                              onClick={handleReset}
                            >
                              <FontAwesomeIcon icon={faEraser} size="1x" />{" "}
                              Clear
                            </Button>
                          </Col>
                          <Col sm={4}>
                            <Button
                              color="primary"
                              className="btn btn-block"
                              type="submit"
                            >
                              <FontAwesomeIcon icon={faSave} size="1x" /> &nbsp;
                              Book Appointment
                            </Button>
                          </Col>
                        </Row>
                      </fieldset>
                    </Col>
                  </Row>
                </ReactCSSTransitionGroup>
              </Fragment>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
function mapStateToProps(state) {
  const {
    consultants,
    customers,
    departments,
    serviceMaster,
    appointments,
    registrationMaster,
  } = state;

  return {
    consultants: consultants.consultants,
    transaction: customers.currentTransaction,
    departments: departments.departments,
    packageData: serviceMaster.data,
    appointment: appointments.selected_appointment,
    masters: registrationMaster.data,
  };
}

const connectedNewAppointment = connect(mapStateToProps)(NewAppointment);
export default connectedNewAppointment;
