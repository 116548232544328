import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { departmentTypeService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const departmentTypeActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    departmentTypeService.getAll().then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success("Department types loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_DEPARTMENT_TYPE_REQUEST };
  }
  function success(departmentTypes) {
    return {
      type: generalMasterConstants.GET_DEPARTMENT_TYPE_SUCCESS,
      departmentTypes,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_DEPARTMENT_TYPE_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentTypeService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_DEPARTMENT_TYPE_REQUEST };
  }
  function success(departmentType) {
    return {
      type: generalMasterConstants.ADD_DEPARTMENT_TYPE_SUCCESS,
      departmentType,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_DEPARTMENT_TYPE_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentTypeService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_TYPE_REQUEST };
  }
  function success(departmentType) {
    return {
      type: generalMasterConstants.UPDATE_DEPARTMENT_TYPE_SUCCESS,
      departmentType,
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.UPDATE_DEPARTMENT_TYPE_FAILURE,
      error,
    };
  }
}

function remove(payload, departmentType) {
  return (dispatch) => {
    dispatch(request());
    departmentTypeService.remove(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // dispatch(alertActions.success(data.message));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_DEPARTMENT_TYPE_REQUEST };
  }
  function success(departmentType) {
    return {
      type: generalMasterConstants.DELETE_DEPARTMENT_TYPE_SUCCESS,
      departmentType,
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.DELETE_DEPARTMENT_TYPE_FAILURE,
      error,
    };
  }
}
