export const wardManagementConstants = {
  GET_ALL_WARD_TYPE_REQUEST: "GET_ALL_WARD_TYPE_REQUEST",
  GET_ALL_WARD_TYPE_SUCCESS: "GET_ALL_WARD_TYPE_SUCCESS",
  GET_ALL_WARD_TYPE_FAILURE: "GET_ALL_WARD_TYPE_FAILURE",

  ADD_ALL_WARD_TYPE_REQUEST: "ADD_ALL_WARD_TYPE_REQUEST",
  ADD_ALL_WARD_TYPE_SUCCESS: "ADD_ALL_WARD_TYPE_SUCCESS",
  ADD_ALL_WARD_TYPE_FAILURE: "ADD_ALL_WARD_TYPE_FAILURE",

  UPDATE_ALL_WARD_TYPE_REQUEST: "UPDATE_ALL_WARD_TYPE_REQUEST",
  UPDATE_ALL_WARD_TYPE_SUCCESS: "UPDATE_ALL_WARD_TYPE_SUCCESS",
  UPDATE_ALL_WARD_TYPE_FAILURE: "UPDATE_ALL_WARD_TYPE_FAILURE",

  DELETE_ALL_WARD_TYPE_REQUEST: "DELETE_ALL_WARD_TYPE_REQUEST",
  DELETE_ALL_WARD_TYPE_SUCCESS: "DELETE_ALL_WARD_TYPE_SUCCESS",
  DELETE_ALL_WARD_TYPE_FAILURE: "DELETE_ALL_WARD_TYPE_FAILURE",

  DEACTIVE_ALL_WARD_TYPE_REQUEST: "DEACTIVE_ALL_WARD_TYPE_REQUEST",
  DEACTIVE_ALL_WARD_TYPE_SUCCESS: "DEACTIVE_ALL_WARD_TYPE_SUCCESS",
  DEACTIVE_ALL_WARD_TYPE_FAILURE: "DEACTIVE_ALL_WARD_TYPE_FAILURE",

  GET_ALL_WARD_CATAGORY_REQUEST: "GET_ALL_WARD_CATAGORY_REQUEST",
  GET_ALL_WARD_CATAGORY_SUCCESS: "GET_ALL_WARD_CATAGORY_SUCCESS",
  GET_ALL_WARD_CATAGORY_FAILURE: "GET_ALL_WARD_CATAGORY_FAILURE",

  ADD_ALL_WARD_CATAGORY_REQUEST: "ADD_ALL_WARD_CATAGORY_REQUEST",
  ADD_ALL_WARD_CATAGORY_SUCCESS: "ADD_ALL_WARD_CATAGORY_SUCCESS",
  ADD_ALL_WARD_CATAGORY_FAILURE: "ADD_ALL_WARD_CATAGORY_FAILURE",

  UPDATE_ALL_WARD_CATAGORY_REQUEST: "UPDATE_ALL_WARD_CATAGORY_REQUEST",
  UPDATE_ALL_WARD_CATAGORY_SUCCESS: "UPDATE_ALL_WARD_CATAGORY_SUCCESS",
  UPDATE_ALL_WARD_CATAGORY_FAILURE: "UPDATE_ALL_WARD_CATAGORY_FAILURE",

  DELETE_ALL_WARD_CATAGORY_REQUEST: "DELETE_ALL_WARD_CATAGORY_REQUEST",
  DELETE_ALL_WARD_CATAGORY_SUCCESS: "DELETE_ALL_WARD_CATAGORY_SUCCESS",
  DELETE_ALL_WARD_CATAGORY_FAILURE: "DELETE_ALL_WARD_CATAGORY_FAILURE",

  DEACTIVE_ALL_WARD_CATAGORY_REQUEST: "DEACTIVE_ALL_WARD_CATAGORY_REQUEST",
  DEACTIVE_ALL_WARD_CATAGORY_SUCCESS: "DEACTIVE_ALL_WARD_CATAGORY_SUCCESS",
  DEACTIVE_ALL_WARD_CATAGORY_FAILURE: "DEACTIVE_ALL_WARD_CATAGORY_FAILURE",

  GET_ALL_WARD_SUB_CATAGORY_REQUEST: "GET_ALL_WARD_SUB_CATAGORY_REQUEST",
  GET_ALL_WARD_SUB_CATAGORY_SUCCESS: "GET_ALL_WARD_SUB_CATAGORY_SUCCESS",
  GET_ALL_WARD_SUB_CATAGORY_FAILURE: "GET_ALL_WARD_SUB_CATAGORY_FAILURE",

  ADD_ALL_WARD_SUB_CATAGORY_REQUEST: "ADD_ALL_WARD_SUB_CATAGORY_REQUEST",
  ADD_ALL_WARD_SUB_CATAGORY_SUCCESS: "ADD_ALL_WARD_SUB_CATAGORY_SUCCESS",
  ADD_ALL_WARD_SUB_CATAGORY_FAILURE: "ADD_ALL_WARD_SUB_CATAGORY_FAILURE",

  UPDATE_ALL_WARD_SUB_CATAGORY_REQUEST: "UPDATE_ALL_WARD_SUB_CATAGORY_REQUEST",
  UPDATE_ALL_WARD_SUB_CATAGORY_SUCCESS: "UPDATE_ALL_WARD_SUB_CATAGORY_SUCCESS",
  UPDATE_ALL_WARD_SUB_CATAGORY_FAILURE: "UPDATE_ALL_WARD_SUB_CATAGORY_FAILURE",

  DELETE_ALL_WARD_SUB_CATAGORY_REQUEST: "DELETE_ALL_WARD_SUB_CATAGORY_REQUEST",
  DELETE_ALL_WARD_SUB_CATAGORY_SUCCESS: "DELETE_ALL_WARD_SUB_CATAGORY_SUCCESS",
  DELETE_ALL_WARD_SUB_CATAGORY_FAILURE: "DELETE_ALL_WARD_SUB_CATAGORY_FAILURE",

  DEACTIVE_ALL_WARD_SUB_CATAGORY_REQUEST:
    "DEACTIVE_ALL_WARD_SUB_CATAGORY_REQUEST",
  DEACTIVE_ALL_WARD_SUB_CATAGORY_SUCCESS:
    "DEACTIVE_ALL_WARD_SUB_CATAGORY_SUCCESS",
  DEACTIVE_ALL_WARD_SUB_CATAGORY_FAILURE:
    "DEACTIVE_ALL_WARD_SUB_CATAGORY_FAILURE",

  GET_BY_WARD_TYPE_REQUEST: "GET_BY_WARD_TYPE_REQUEST",
  GET_BY_WARD_TYPE_SUCCESS: "GET_BY_WARD_TYPE_SUCCESS",
  GET_BY_WARD_TYPE_FAILURE: "GET_BY_WARD_TYPE_FAILURE",

  GET_ALL_WARD_FACILITY_REQUEST: "GET_ALL_WARD_FACILITY_REQUEST",
  GET_ALL_WARD_FACILITY_SUCCESS: "GET_ALL_WARD_FACILITY_SUCCESS",
  GET_ALL_WARD_FACILITY_FAILURE: "GET_ALL_WARD_FACILITY_FAILURE",

  ADD_ALL_WARD_FACILITY_REQUEST: "ADD_ALL_WARD_FACILITY_REQUEST",
  ADD_ALL_WARD_FACILITY_SUCCESS: "ADD_ALL_WARD_FACILITY_SUCCESS",
  ADD_ALL_WARD_FACILITY_FAILURE: "ADD_ALL_WARD_FACILITY_FAILURE",

  UPDATE_ALL_WARD_FACILITY_REQUEST: "UPDATE_ALL_WARD_FACILITY_REQUEST",
  UPDATE_ALL_WARD_FACILITY_SUCCESS: "UPDATE_ALL_WARD_FACILITY_SUCCESS",
  UPDATE_ALL_WARD_FACILITY_FAILURE: "UPDATE_ALL_WARD_FACILITY_FAILURE",

  DELETE_ALL_WARD_FACILITY_REQUEST: "DELETE_ALL_WARD_FACILITY_REQUEST",
  DELETE_ALL_WARD_FACILITY_SUCCESS: "DELETE_ALL_WARD_FACILITY_SUCCESS",
  DELETE_ALL_WARD_FACILITY_FAILURE: "DELETE_ALL_WARD_FACILITY_FAILURE",

  DEACTIVE_ALL_WARD_FACILITY_REQUEST: "DEACTIVE_ALL_WARD_FACILITY_REQUEST",
  DEACTIVE_ALL_WARD_FACILITY_SUCCESS: "DEACTIVE_ALL_WARD_FACILITY_SUCCESS",
  DEACTIVE_ALL_WARD_FACILITY_FAILURE: "DEACTIVE_ALL_WARD_FACILITY_FAILURE",

  GET_ALL_VISIT_TYPE_REQUEST: "GET_ALL_VISIT_TYPE_REQUEST",
  GET_ALL_VISIT_TYPE_SUCCESS: "GET_ALL_VISIT_TYPE_SUCCESS",
  GET_ALL_VISIT_TYPE_FAILURE: "GET_ALL_VISIT_TYPE_FAILURE",

  ADD_ALL_VISIT_TYPE_REQUEST: "ADD_ALL_VISIT_TYPE_REQUEST",
  ADD_ALL_VISIT_TYPE_SUCCESS: "ADD_ALL_VISIT_TYPE_SUCCESS",
  ADD_ALL_VISIT_TYPE_FAILURE: "ADD_ALL_VISIT_TYPE_FAILURE",

  UPDATE_ALL_VISIT_TYPE_REQUEST: "UPDATE_ALL_VISIT_TYPE_REQUEST",
  UPDATE_ALL_VISIT_TYPE_SUCCESS: "UPDATE_ALL_VISIT_TYPE_SUCCESS",
  UPDATE_ALL_VISIT_TYPE_FAILURE: "UPDATE_ALL_VISIT_TYPE_FAILURE",

  DELETE_ALL_VISIT_TYPE_REQUEST: "DELETE_ALL_VISIT_TYPE_REQUEST",
  DELETE_ALL_VISIT_TYPE_SUCCESS: "DELETE_ALL_VISIT_TYPE_SUCCESS",
  DELETE_ALL_VISIT_TYPE_FAILURE: "DELETE_ALL_VISIT_TYPE_FAILURE",

  DEACTIVE_ALL_VISIT_TYPE_REQUEST: "DEACTIVE_ALL_VISIT_TYPE_REQUEST",
  DEACTIVE_ALL_VISIT_TYPE_SUCCESS: "DEACTIVE_ALL_VISIT_TYPE_SUCCESS",
  DEACTIVE_ALL_VISIT_TYPE_FAILURE: "DEACTIVE_ALL_VISIT_TYPE_FAILURE",

  GET_ALL_WARD_CATAGORY_FEES_REQUEST: "GET_ALL_WARD_CATAGORY_FEES_REQUEST",
  GET_ALL_WARD_CATAGORY_FEES_SUCCESS: "GET_ALL_WARD_CATAGORY_FEES_SUCCESS",
  GET_ALL_WARD_CATAGORY_FEES_FAILURE: "GET_ALL_WARD_CATAGORY_FEES_FAILURE",

  ADD_ALL_WARD_CATAGORY_FEES_REQUEST: "ADD_ALL_WARD_CATAGORY_FEES_REQUEST",
  ADD_ALL_WARD_CATAGORY_FEES_SUCCESS: "ADD_ALL_WARD_CATAGORY_FEES_SUCCESS",
  ADD_ALL_WARD_CATAGORY_FEES_FAILURE: "ADD_ALL_WARD_CATAGORY_FEES_FAILURE",

  UPDATE_ALL_WARD_CATAGORY_FEES_REQUEST:
    "UPDATE_ALL_WARD_CATAGORY_FEES_REQUEST",
  UPDATE_ALL_WARD_CATAGORY_FEES_SUCCESS:
    "UPDATE_ALL_WARD_CATAGORY_FEES_SUCCESS",
  UPDATE_ALL_WARD_CATAGORY_FEES_FAILURE:
    "UPDATE_ALL_WARD_CATAGORY_FEES_FAILURE",

  DELETE_ALL_WARD_CATAGORY_FEES_REQUEST:
    "DELETE_ALL_WARD_CATAGORY_FEES_REQUEST",
  DELETE_ALL_WARD_CATAGORY_FEES_SUCCESS:
    "DELETE_ALL_WARD_CATAGORY_FEES_SUCCESS",
  DELETE_ALL_WARD_CATAGORY_FEES_FAILURE:
    "DELETE_ALL_WARD_CATAGORY_FEES_FAILURE",

  DEACTIVE_ALL_WARD_CATAGORY_FEES_REQUEST:
    "DEACTIVE_ALL_WARD_CATAGORY_FEES_REQUEST",
  DEACTIVE_ALL_WARD_CATAGORY_FEES_SUCCESS:
    "DEACTIVE_ALL_WARD_CATAGORY_FEES_SUCCESS",
  DEACTIVE_ALL_WARD_CATAGORY_FEES_FAILURE:
    "DEACTIVE_ALL_WARD_CATAGORY_FEES_FAILURE",

  GET_BY_WARD_CATAGORY_REQUEST: "GET_BY_WARD_CATAGORY_REQUEST",
  GET_BY_WARD_CATAGORY_SUCCESS: "GET_BY_WARD_CATAGORY_SUCCESS",
  GET_BY_WARD_CATAGORY_FAILURE: "GET_BY_WARD_CATAGORY_FAILURE",

  GET_ALL_ROOM_REQUEST: "GET_ALL_ROOM_REQUEST",
  GET_ALL_ROOM_SUCCESS: "GET_ALL_ROOM_SUCCESS",
  GET_ALL_ROOM_FAILURE: "GET_ALL_ROOM_FAILURE",

  ADD_ROOM_REQUEST: "ADD_ROOM_REQUEST",
  ADD_ROOM_SUCCESS: "ADD_ROOM_SUCCESS",
  ADD_ROOM_FAILURE: "ADD_ROOM_FAILURE",

  GET_ALL_BED_REQUEST: "GET_ALL_BED_REQUEST",
  GET_ALL_BED_SUCCESS: "GET_ALL_BED_SUCCESS",
  GET_ALL_BED_FAILURE: "GET_ALL_BED_FAILURE",

  ADD_BED_REQUEST: "ADD_BED_REQUEST",
  ADD_BED_SUCCESS: "ADD_BED_SUCCESS",
  ADD_BED_FAILURE: "ADD_BED_FAILURE",

  DEACTIVE_ALL_WARD_BED_REQUEST: "DEACTIVE_ALL_WARD_BED_REQUEST",
  DEACTIVE_ALL_WARD_BED_SUCCESS: "DEACTIVE_ALL_WARD_BED_SUCCESS",
  DEACTIVE_ALL_WARD_BED_FAILURE: "DEACTIVE_ALL_WARD_BED_FAILURE",

  DELETE_ALL_WARD_ROOM_REQUEST: "DELETE_ALL_WARD_ROOM_REQUEST",
  DELETE_ALL_WARD_ROOM_SUCCESS: "DELETE_ALL_WARD_ROOM_SUCCESS",
  DELETE_ALL_WARD_ROOM_FAILURE: "DELETE_ALL_WARD_ROOM_FAILURE",

  DEACTIVE_ALL_WARD_ROOM_REQUEST: "DEACTIVE_ALL_WARD_ROOM_REQUEST",
  DEACTIVE_ALL_WARD_ROOM_SUCCESS: "DEACTIVE_ALL_WARD_ROOM_SUCCESS",
  DEACTIVE_ALL_WARD_ROOM_FAILURE: "DEACTIVE_ALL_WARD_ROOM_FAILURE",

  GET_ALL_GRACEPERIOD_REQUEST: "GET_ALL_GRACEPERIOD_REQUEST",
  GET_ALL_GRACEPERIOD_SUCCESS: "GET_ALL_GRACEPERIOD_SUCCESS",
  GET_ALL_GRACEPERIOD_FAILURE: "GET_ALL_GRACEPERIOD_FAILURE",

  ADD_ALL_GRACEPERIOD_REQUEST: "ADD_ALL_GRACEPERIOD_REQUEST",
  ADD_ALL_GRACEPERIOD_SUCCESS: "ADD_ALL_GRACEPERIOD_SUCCESS",
  ADD_ALL_GRACEPERIOD_FAILURE: "ADD_ALL_GRACEPERIOD_FAILURE",

  UPDATE_ALL_GRACEPERIOD_REQUEST: "UPDATE_ALL_GRACEPERIOD_REQUEST",
  UPDATE_ALL_GRACEPERIOD_SUCCESS: "UPDATE_ALL_GRACEPERIOD_SUCCESS",
  UPDATE_ALL_GRACEPERIOD_FAILURE: "UPDATE_ALL_GRACEPERIOD_FAILURE",

  DELETE_ALL_GRACEPERIOD_REQUEST: "DELETE_ALL_GRACEPERIOD_REQUEST",
  DELETE_ALL_GRACEPERIOD_SUCCESS: "DELETE_ALL_GRACEPERIOD_SUCCESS",
  DELETE_ALL_GRACEPERIOD_FAILURE: "DELETE_ALL_GRACEPERIOD_FAILURE",

  DEACTIVE_ALL_GRACEPERIOD_REQUEST: "DEACTIVE_ALL_GRACEPERIOD_REQUEST",
  DEACTIVE_ALL_GRACEPERIOD_SUCCESS: "DEACTIVE_ALL_GRACEPERIOD_SUCCESS",
  DEACTIVE_ALL_GRACEPERIOD_FAILURE: "DEACTIVE_ALL_GRACEPERIOD_FAILURE",
};
