import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from "reactstrap";

import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  BarChart,
  Bar,
  ComposedChart,
  CartesianGrid,
} from "recharts";

import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

import Switch from "react-switch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  sweetAlertActions,
  userTypeActions,
  wardManagementActions,
} from "../../actions";
import cx from "classnames";
import CustomButtons from "../Components/customebuttons";
import FormValidation from "../MyComponents/FormValidation";
import DataTable2 from "../MyComponents/DataTable";
import { flatten } from "lodash";
import { toast, Bounce } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "../MyComponents/PageTitle";
import { apiUrl } from "../../constants";
import { Icons } from "../../helpers/IconHelper";
class WardRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Add",
      errormessage: "",
      visible: false,
      room_name: "",
      room_no: "",
      room_prefix: "",

      total_rooms: "",
      room_suffix: "",
      ward_type: "",
      ward_catagory: "",
      ward_sub_catagory: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onWardChange = this.onWardChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onWardTypeCodeToName = this.onWardTypeCodeToName.bind(this);
    this.onWardCatagoryToName = this.onWardCatagoryToName.bind(this);
    this.wardSubCatagoryCodeToValue = this.wardSubCatagoryCodeToValue.bind(
      this
    );
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCatagoryChange = this.onCatagoryChange.bind(this);
    this.onRoomChange = this.onRoomChange.bind(this);
  }
  validationSchema = Yup.object().shape({
    room_name: Yup.string().required("This field is required"),
    room_no: Yup.string().required("This field is required"),
    // room_prefix:Yup.string().required("This field is required"),

    total_rooms: Yup.string().required("This field is required"),
    // room_suffix: Yup.string().required("This field is required"),
    ward_type: Yup.string().required("This field is required"),
    ward_catagory: Yup.string().required("This field is required"),
    ward_sub_catagory: Yup.string().required("This field is required"),
  });

  hide() {
    this.setState({
      visible: !this.state.hide,
    });
  }

  onDeleteClick(data) {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.deactiveWardRoom(data));
  }

  onUpdateClick() {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.updateWardSubCatagory(this.state));
    this.onClearClick();
  }

  onWardCatagoryToName(code) {
    let { wardcatagorys } = this.props;
    try {
      let name = wardcatagorys.find((data) => data.code === code).ward_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onWardTypeCodeToName(code) {
    let { wardtypes } = this.props;
    console.log(wardtypes, "wardtypesssss");
    try {
      let name = wardtypes.find((data) => data.code === code).ward_type;
      return name;
    } catch {
      return code;
    }
  }

  wardSubCatagoryCodeToValue(code) {
    let { wardsubcatagory } = this.props;
    try {
      let name = wardsubcatagory.find((data) => data.code === code)
        .ward_sub_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onEditClick(data) {
    this.setState({
      ward_type: data.ward_type,
      ward_catagory: data.ward_catagory,
      ward_sub_catagory: data.ward_sub_catagory,
      room_name: data.room_name,
      _id: data._id,
      created_on: data.created_on,
      code: data.code,
      created_by: data.created_by,
      mode: "Edit",
    });
  }

  formValidation() {
    if (!this.state.ward_type) {
      this.setState({
        errormessage: "Ward Type required",
        visible: true,
      });
      return false;
    }
    if (!this.state.ward_catagory) {
      this.setState({
        errormessage: "Ward Catagory required",
        visible: true,
      });
      return false;
    }
    if (!this.state.ward_sub_catagory) {
      this.setState({
        errormessage: "Ward sub catagory required",
        visible: true,
      });
      return false;
    }

    if (!this.state.room_name) {
      this.setState({
        errormessage: "Room name required",
        visible: true,
      });
      return false;
    }

    if (!this.state.room_no) {
      this.setState({
        errormessage: "Room number required",
        visible: true,
      });
      return false;
    }

    if (!this.state.room_prefix) {
      this.setState({
        errormessage: "Room prefix required",
        visible: true,
      });
      return false;
    }

    if (!this.state.total_rooms) {
      this.setState({
        errormessage: "Total rooms required",
        visible: true,
      });
      return false;
    }

    return true;
  }

  errorMessage() {}

  onSubmitClick() {
    let validateForm = this.formValidation();

    if (!validateForm) return;
    let { dispatch } = this.props;
    const {
      ward_type,
      ward_catagory,
      ward_sub_catagory,
      room_name,
      room_no,
      room_prefix,
      room_suffix,
      total_rooms,
    } = this.state;

    const payload = {
      ward_type: ward_type,
      ward_catagory: ward_catagory,
      ward_sub_catagory: ward_sub_catagory,
      room_name: room_name,
      room_no: room_no,
      room_prefix: room_prefix,
      room_suffix: room_suffix,
      total_rooms: total_rooms,
      mode: "Add",
    };
    dispatch(wardManagementActions.addWardRoom(payload));
    // this.onClearClick();
    this.componentDidMount();
  }

  onClearClick() {
    this.setState({
      ward_type: "",
      ward_catagory: "",
      ward_sub_catagory: "",
      room_name: "",
      room_no: "",
      room_prefix: "",
      room_suffix: "",
      total_rooms: "",
      _id: undefined,
      mode: "Add",
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onRoomChange(e) {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
    });
  }

  onWardChange(e) {
    let { dispatch } = this.props;
    dispatch(
      wardManagementActions.getByWardType({ ward_type: e.target.value })
    );
    this.setState({
      ward_type: e.target.value,
      ward_catagory: "",
      ward_sub_catagory: "",
    });
  }

  componentDidMount() {
    let { dispatch } = this.props;
    // dispatch(userTypeActions.getAll());
    dispatch(wardManagementActions.getAllWardCatagory());
    dispatch(wardManagementActions.getWardAll());
    dispatch(wardManagementActions.getAllWardSubCatagory());
    dispatch(wardManagementActions.getRoomAll());
  }

  handleClick(data) {
    let { dispatch } = this.props;
    dispatch(wardManagementActions.deactiveWardRoom(data));
    this.componentDidMount();
  }

  onCatagoryChange(e) {
    let { dispatch } = this.props;
    dispatch(
      wardManagementActions.getByWardCatagory({ ward_catagory: e.target.value })
    );
    this.setState({
      ward_catagory: e.target.value,
      ward_sub_catagory: "",
    });
  }

  columns = [
    {
      name: "Ward Type",
      selector: (row) => this.onWardTypeCodeToName(row.ward_type),
      sortable: true,
    },
    {
      name: "Ward Category",
      selector: (row) => this.onWardCatagoryToName(row.ward_catagory),
      sortable: true,
    },
    {
      name: "Ward Sub Category",
      selector: (row) => this.wardSubCatagoryCodeToValue(row.ward_sub_catagory),
      sortable: true,
    },
    {
      name: "Room Name",
      selector: (row) => row.default_room_name,
      sortable: true,
    },
    {
      name: "Room List",
      selector: (row) => row.room_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => (
        <FormGroup>
          <Switch
            checked={row.isActive}
            className="mr-2 mb-2"
            id="normal-switch"
            onChange={(e) => this.handleClick(row)}
          />
        </FormGroup>
      ),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: "Created_on",
      selector: (row) => moment(row.created_on).format("DD/MM/YYYY"),
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //       <CustomButtons
    //         type="edit"
    //         disabled={row.isDeleted}
    //         onClickHandler={() => this.onEditClick(row)}
    //       ></CustomButtons>
    //       {row.isDeleted ? (
    //         <CustomButtons
    //           type="restore"
    //           onClickHandler={() => this.onDeleteClick(row)}
    //         ></CustomButtons>
    //       ) : (
    //         <CustomButtons
    //           type="delete"
    //           onClickHandler={() => this.onDeleteClick(row)}
    //         ></CustomButtons>
    //       )}
    //     </>
    //   ),
    // },
  ];

  render() {
    let { wardbycatagorys, wardtypes, wardsubcatagory, wardroom } = this.props;

    return (
      <Fragment>
        <PageTitle
          icon={Icons.PatientRegistration}
          heading="Ward Room"
          columns={this.columns}
          data={wardroom}
          csvFilename="wardroom.csv"
        ></PageTitle>
        <Formik
          initialValues={this.state}
          validationSchema={this.validationSchema}
          onSubmit={
            this.state.mode === "Edit" ? this.onUpdateClick : this.onSubmitClick
          }
          enableReinitialize
        >
          {(props) => {
            const { errors } = props;
            return (
              <Form>
                <Card>
                  <CardHeader>{this.state.mode} Ward Room</CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <Label>Ward Type</Label>
                        <Input
                          name="ward_type"
                          type="select"
                          value={this.state.ward_type}
                          onChange={this.onWardChange}
                        >
                          <option value="">Select</option>
                          {wardtypes
                            ? wardtypes.map((data, index) => {
                                if (data.isActive && !data.isDeleted)
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_type}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_type && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_type}
                          </div>
                        )}
                        {/* {this.state.errormessage.wardType} */}
                      </Col>
                      <Col md={3}>
                        <Label>Ward Catagory</Label>
                        <Input
                          name="ward_catagory"
                          value={this.state.ward_catagory}
                          type="select"
                          onChange={this.onCatagoryChange}
                        >
                          <option value="">Select</option>
                          {wardbycatagorys
                            ? wardbycatagorys.map((data) => {
                                if (data.isActive && !data.isDeleted)
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_catagory}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_catagory && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_catagory}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label>Ward Sub Catagory</Label>
                        <Input
                          type="select"
                          name="ward_sub_catagory"
                          onChange={this.onChange}
                        >
                          <option value="">Select</option>
                          {wardsubcatagory
                            ? wardsubcatagory.map((data) => {
                                if (
                                  data.isActive &&
                                  !data.isDeleted &&
                                  data.ward_catagory ===
                                    this.state.ward_catagory
                                )
                                  return (
                                    <option key={data._id} value={data.code}>
                                      {data.ward_sub_catagory}
                                    </option>
                                  );
                              })
                            : null}
                        </Input>
                        {errors.ward_sub_catagory && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.ward_sub_catagory}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Room Name</Label>
                        <Input
                          type="text"
                          name="room_name"
                          value={this.state.room_name}
                          onChange={this.onChange}
                          placeholder="Room Name"
                        ></Input>
                        {errors.room_name && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_name}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Room Prefix</Label>
                        <Input
                          type="text"
                          name="room_prefix"
                          value={this.state.room_prefix}
                          onChange={this.onChange}
                          placeholder="Room Prefix"
                        ></Input>
                        {errors.room_prefix && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_prefix}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Room No. start with</Label>
                        <Input
                          type="number"
                          name="room_no"
                          value={this.state.room_no}
                          onChange={this.onRoomChange}
                          placeholder="Room No"
                        ></Input>
                        {errors.room_no && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_no}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> No. of Rooms</Label>
                        <Input
                          type="number"
                          name="total_rooms"
                          value={this.state.total_rooms}
                          onChange={this.onRoomChange}
                          placeholder="No of Rooms"
                        ></Input>
                        {errors.total_rooms && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.total_rooms}
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <Label> Room Suffix</Label>
                        <Input
                          type="text"
                          name="room_suffix"
                          value={this.state.room_suffix}
                          onChange={this.onChange}
                          placeholder="Room Suffix"
                        ></Input>
                        {errors.room_suffix && (
                          <div style={{ marginTop: "10px", color: "red" }}>
                            {errors.room_suffix}
                          </div>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          type="submit"
                          color="primary"
                          style={{ marginTop: "30px", marginRight: "5px" }}
                          // onClick={
                          //   this.state.mode === "Add"
                          //     ? this.onSubmitClick
                          //     : this.onUpdateClick
                          // }
                        >
                          {this.state.mode === "Add" ? "Submit" : "Update"}
                        </Button>
                        <Button
                          color="warning"
                          style={{ marginTop: "30px" }}
                          onClick={this.onClearClick}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>Ward Room List</CardHeader>
                  <CardBody>
                    <DataTable2
                      columns={this.columns}
                      data={wardroom}
                      conditionalRowStyles={this.conditionalRowStyles}
                      //   onRowClicked={(row) => {
                      //     this.onViewClicked(row);
                      //   }}
                      subHeader={true}
                      subHeaderComponent={<Row></Row>}
                      autoFocus={true}
                    />
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { newMaster, wardManagement } = state;
  return {
    newMaster: newMaster.userType,
    wardtypes: wardManagement.wardtypes,
    wardroom: wardManagement.wardroom,
    wardbycatagorys: wardManagement.wardbycatagorys,
    wardcatagorys: wardManagement.wardcatagorys,
    wardsubcatagory: wardManagement.wardsubcatagory,
  };
}

const connectedUserType = connect(mapStateToProps)(WardRoom);

export default connectedUserType;
