import { toast, Bounce } from "react-toastify";

export function toastHelper(message, type) {
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-center",
    type: type,
  });
}
