//import { authHeader } from '../store';
import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"



export const otRoomService = {        
    getAll,
    add,
    update,
    get,
    updateStatus,
    getWards 
   };

   

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/wardroomapi/getRoomByCatagoryName/OT`, requestOptions).then(helperService.handleResponse);
}


function getWards() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/wardcatagoryapi`, requestOptions).then(helperService.handleResponse);
}

function updateStatus(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/wardroomapi/changeRoomStatus`, requestOptions).then(helperService.handleResponse);
}


function get(code) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/wardroomapi/${code}`, requestOptions).then(helperService.handleResponse);
}


function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{"authorization" : helperService.getHeaderData()},   
        body:data
    };

    return fetch(`${apiUrl}/wardroomapi`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/wardroomapi/update`, requestOptions).then(helperService.handleResponse);
}



