import { registrationMasterConstants } from "../constants";

export function registrationMaster(state = {}, action) {
  switch (action.type) {
    case registrationMasterConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.data.value,
        pageInfo: action.data.pagedInfo,
        loading: false,
      };
    case registrationMasterConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };
    case registrationMasterConstants.GET_ACTIVE_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.GET_ACTIVE_SUCCESS:
      return { ...state, dataAll: action.data, loading: false };
    case registrationMasterConstants.GET_ACTIVE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case registrationMasterConstants.ADD_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.ADD_SUCCESS:
      var prevData = [...state.data];
      prevData[prevData.length] = action.data.data;

      return {
        ...state,
        data: prevData,
        pageInfo: {
          ...state.pageInfo,
          totalRecords: state.pageInfo.totalRecords + 1,
        },
        loading: false,
      };
    case registrationMasterConstants.ADD_FAILURE:
      return { ...state, error: action.error, loading: false };

    case registrationMasterConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.UPDATE_SUCCESS:
      let updatedState = action.data;
      let index = state.data.findIndex((ob) => ob.id === updatedState.id);
      state.data[index] = updatedState;
      return { ...state, data: [...state.data], loading: false };
    case registrationMasterConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case registrationMasterConstants.SET_DEFAULT_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.SET_DEFAULT_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.data.type) {
          return {
            ...action.data.data,
          };
        } else if (item.favourite) {
          return {
            ...item,
            favourite: false,
          };
        }
        return item;
      });
      return { ...state, data: updatedData, loading: false };
    case registrationMasterConstants.SET_DEFAULT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case registrationMasterConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case registrationMasterConstants.DELETE_SUCCESS:
      // var prevData = { ...state.data };
      // prevData[action.data.type] = action.data.data;
      //return { ...state, data: prevData, loading: false };
      // let deleteddata = action.data;
      // console.log(deleteddata, "deleted");
      let cindex = state.data.findIndex((ob) => ob.id === action.data.id);
      const newState = {
        ...state,
        data: [...state.data], // Copy the data array
      };

      // Update the copied data
      newState.data[cindex].deleted = !newState.data[cindex].deleted;

      // Now return the updated state
      return {
        ...newState,
        loading: false,
      };

    case registrationMasterConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case registrationMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
