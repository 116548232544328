import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const educationService = {
  getAll,
  add,
  update,
  _delete,
};

function getAll(viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/EmployeeEducations?viewDeleted=${viewDeleted}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/EmployeeEducations`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/EmployeeEducations/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}
function _delete(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/employeeeducations/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
