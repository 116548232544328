import React, { Fragment, Component } from "react";
import { Route } from 'react-router-dom';
import classnames from 'classnames';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
    ModalHeader, ModalBody, ModalFooter, TabContent, TabPane,
} from 'reactstrap';

import Rodal from 'rodal';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Container } from 'reactstrap';
import { capchaConfig } from '../../../../config/captchaConfig';
import ReCAPTCHA from "react-google-recaptcha";
export default class GenerateOtpComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row className="text-center"></Row>

            <Rodal visible={this.props.visible}
                onClose={this.props.hide}

                showMask={false}
            >
                <ModalHeader>Reset Password</ModalHeader>
                <ModalBody>

                    <p>To reset the password please enter your mail id</p>
                    <Row>
                        <Col>
                            <FormGroup style={{ "width": "100%" }}>
                                <Label for="exampleEmail">Email</Label>
                                <Input type="email" name="email" id="ResetEmail" onChange={(e) => this.props.onChangeHandler("customer_email", e.target.value)}
                                    placeholder="Email here..." />
                            </FormGroup>

                            <p className="error" style={{ display: "none" }}>Invalid E-mail</p>
                        </Col>
                        <Col>
                            <FormGroup style={{ "width": "100%" }}>
                                <Label for="login_type">Login As</Label>
                                <Input type="select" id="login_type" onChange={(e) => { this.props.onChangeHandler("login_type", e.target.value) }}>
                                    <option value="user">Employee</option>
                                    <option value="consultant">Dr./Consultant</option>
                                    <option value="patient">Patient</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="link" onClick={this.props.hide}>Cancel</Button>
                    <Button color="primary" onClick={this.props.onResetClickHandler}>Generate OTP</Button>
                </ModalFooter>
            </Rodal>
        </CSSTransitionGroup> 
        )
    }
}