import { newMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { designationService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const designationActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll(viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());
    designationService.getAll(viewDeleted).then(
      (data) => {
        if (data) {
          console.log(data);
          dispatch(success(data.value));
          dispatch(alertActions.success("designation master loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.GET_ALL_DESIGNATION_REQUEST };
  }
  function success(designation) {
    return {
      type: newMasterConstants.GET_ALL_DESIGNATION_SUCCESS,
      designation,
    };
  }
  function failure(error) {
    return { type: newMasterConstants.GET_ALL_EDUCATION_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    designationService.add(payload).then(
      (result) => {
        if (result) {
          dispatch(success(result.value));
          toast("Data Saved Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result));
          toast("Failed to save data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.ADD_DESIGNATION_REQUEST };
  }
  function success(designation) {
    return { type: newMasterConstants.ADD_DESIGNATION_SUCCESS, designation };
  }
  function failure(error) {
    return { type: newMasterConstants.ADD_DESIGNATION_FAILURE, error };
  }
}

function update(data) {
  return (dispatch) => {
    dispatch(request());
    designationService.update(data).then(
      (data) => {
        if (data) {
          toast("Updated Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(success(data.value));
          // dispatch(alertActions.success('designation master loaded'))
        } else {
          toast("Failed to Update", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
          dispatch(failure(data.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.UPDATE_DESIGNATION_REQUEST };
  }
  function success(designation) {
    return { type: newMasterConstants.UPDATE_DESIGNATION_SUCCESS, designation };
  }
  function failure(error) {
    return { type: newMasterConstants.UPDATE_DESIGNATION_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    designationService.remove(payload).then(
      (result) => {
        if (result) {
          dispatch(success(payload));
          dispatch(alertActions.success(result));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else {
          dispatch(failure(result));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.DELETE_DESIGNATION_REQUEST };
  }
  function success(designation) {
    return { type: newMasterConstants.DELETE_DESIGNATION_SUCCESS, designation };
  }
  function failure(error) {
    return { type: newMasterConstants.DELETE_DESIGNATION_FAILURE, error };
  }
}
