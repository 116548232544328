export const loginConstant={
    LOGIN_REQUEST:"LOGIN_CUSTOMER",
    LOGIN_SUCESS: "LOGIN_SUCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    GENERATE_OTP: "GENERATE_OTP",
    GENERATE_OTP_SUCESS: "GENERATE OTP SUCESSFULLY",
    GENERATE_OTP_FAILURE: "GENERATE OTP FAILURE",
    GENERATE_OTP_TYPE: "reset_password_otp",
    GENERATE_OTP_SERVER_REQUEST_TYPE: "reset_password",
    VERIFY_OTP : "VERIFY OTP",
    VERIFY_OTP_SUCCESS : "VERIFY OTP SUCCESS",
    VERIFY_OTP_FAILURE : "VERIFY OTP FAILRE",
    VERIFY_OTP_LOGIN_FLOW_TYPE : "verify_otp_login_flow",
    VERIFY_OTP_SERVER_REQUEST_TYPE : "login",
    VERIFY_OTP_RESET_FLOW : "verify_otp_reset_flow",
    AUTHORIZATION_HEADER : "authorization",
    RESET_PASSWORD_TYPE : "reset_password",
    RESET_PASSWORD : "RESET PASSWORD",
    RESET_PASSWORD_SUCCESS : "RESET PASSWORD SUCCESS",
    RESET_PASSWORD_FAILURE : "RESET PASSWORD FAILRE",

};