
import {loginConstant} from '../constants';
export function login(state = {}, action) {

        switch (action.type) {
                case loginConstant.LOGIN_REQUEST:
                  return { ...state, loading: true };
                case loginConstant.LOGIN_SUCESS:
                  return { ...state, data: action.data, loading: false };
                case loginConstant.LOGIN_FAILRE:
                  return { ...state, error: action.error, loading: false };
                case loginConstant.GENERATE_OTP:
                  return { ...state, loading: true };
                case loginConstant.GENERATE_OTP_SUCESS:
                  return { ...state, data: action.data, loading: false };
                case loginConstant.GENERATE_OTP_FAILURE:
                  return { ...state, error: action.error, loading: false };
                  case loginConstant.VERIFY_OTP:
                    return { ...state, loading: true };
                  case loginConstant.VERIFY_OTP_SUCCESS:
                    return { ...state, data: action.data.data, loading: false };
                  case loginConstant.VERIFY_OTP_FAILURE:
                    return { ...state, error: action.error, loading: false };
                    case loginConstant.RESET_PASSWORD:
                      return { ...state, loading: true };
                    case loginConstant.RESET_PASSWORD_SUCCESS:
                      return { ...state, data: action.data, loading: false };
                    case loginConstant.RESET_PASSWORD_FAILURE:
                      return { ...state, error: action.error, loading: false };
                default:
                  return state;
            
              }
}