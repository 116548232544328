import { generalMasterConstants } from "../constants";

export function departmentTypes(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_DEPARTMENT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_DEPARTMENT_TYPE_SUCCESS:
      return {
        ...state,
        departmentTypes: action.departmentTypes,
        loading: false,
      };
    case generalMasterConstants.GET_DEPARTMENT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_DEPARTMENT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_DEPARTMENT_TYPE_SUCCESS:
      return {
        ...state,
        departmentTypes: [...state.departmentTypes, action.departmentType],
        loading: false,
      };
    case generalMasterConstants.ADD_DEPARTMENT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_DEPARTMENT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_DEPARTMENT_TYPE_SUCCESS:
      let updatedState = [...state.departmentTypes];
      let index = updatedState.findIndex(
        (ob) => ob.id === action.departmentType.id
      );
      updatedState[index] = action.departmentType;
      return {
        ...state,
        departmentTypes: [...updatedState],
        loading: false,
      };
    case generalMasterConstants.UPDATE_DEPARTMENT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_DEPARTMENT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_DEPARTMENT_TYPE_SUCCESS:
      const updatedDepartmentTypes = state.departmentTypes
        ? state.departmentTypes.map((departmentType) =>
            departmentType.id === action.departmentType.id
              ? { ...departmentType, deleted: !departmentType.deleted }
              : departmentType
          )
        : state.departmentTypes;

      return {
        ...state,
        departmentTypes: updatedDepartmentTypes,
        loading: false,
      };

    case generalMasterConstants.DELETE_DEPARTMENT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
