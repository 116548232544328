import React, { Component } from "react";
import { Button, Spinner } from 'reactstrap';

class SpinnerButton extends Component {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
        this.state = {
            isLoading: false
        };
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.closeSpinner = this.closeSpinner.bind(this);
    }

    handleButtonClick = (event) => {
        this.setState({ isLoading: true });
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            setTimeout(async () => {
                this.props.onClick(event, this.closeSpinner);
            }, 500);
        }
    }

    closeSpinner = () => {
        this.setState({ isLoading: false });
    }

    render() {
        const { title } = this.props;
        const { isLoading } = this.state;
        return (
            <Button
                {...this.props}
                disabled={isLoading}
                onClick={this.handleButtonClick}
            >
                {title}
                {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
            </Button>
        );
    }
}

export default SpinnerButton;
