import { faEraser, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  appointmentActions,
  consultantActions,
  departmentActions,
  serviceMasterActions,
} from "../../actions";
import { appointmentStatus, appointmentType } from "../../constants";
import { appointmentService, serviceMasterService } from "../../services";
import PatientInfo from "../MyComponents/PatientInfo";
import PatientSearchBox from "../MyComponents/PatientSearchBox";
import Select from "../MyComponents/Select";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

class Appointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patient: null,
      consultant: null,
      appointment_date: new Date(),
      allSlots: null,
      slots_on_date: null,
      groups: [],
      bookedSlots: null,
      nextAppointmentTime: "",
      minTime: "",
      maxTime: "",
    };
  }
  setFieldValue = null;
  values = {};

  onPatientSelected(patient, setFieldValue) {
    if (patient) {
      setFieldValue("patient_id", patient.id);
      setFieldValue("consultant_id", patient.consultantId);
      this.onConsultantSelected({ target: { value: patient.consultantId } });
      this.setState({ patient: patient });
      appointmentService.getLastAppointment().then((res) => {
        if (res.isSuccess) {
          this.setFieldValue &&
            this.setFieldValue(
              "roomNumber",
              res.value[0] ? res.value[0].roomNumber : "1"
            );
        }
      });
    }
  }

  initialValues = {
    patient_id: "",
    consultant_id: "",
    procedure_notes: "",
    appointment_type: appointmentType.ByAppointment,
    appointment_date: moment(new Date()).format("YYYY-MM-DD"),
    time_slot_from: new Date(),
    slot_duration: "15",
    planned_procedure: "",
    roomNumber: "",
    service_type: "service",
    slot: "A",
  };

  onSubmit = async (values) => {
    const { appointment, mode } = this.props;

    const { patient, department } = this.state;
    if (patient) {
      const { dispatch } = this.props;
      let isBooked = false;

      values.appointment_date = this.state.appointment_date;
      values.time_slot_from.setDate(this.state.appointment_date.getDate());
      values.time_slot_from.setMonth(this.state?.appointment_date?.getMonth());

      if (mode === "NEW") {
        let data = {
          department_id: department.id,
          status: appointmentStatus.New,
          ...values,
        };

        data.patient_id = patient;

        isBooked = await dispatch(appointmentActions.add(data));
      }
      if (mode === "EDIT") {
        const data = {
          appointment_id: appointment._id,
          status: appointmentStatus.New,
          ...values,
        };
        data.patient_id = patient;
        data.department_id =
          department === undefined ? appointment.department_id : department.id;
        isBooked = await dispatch(appointmentActions.update(data));
      }
      if (isBooked) {
        this.props.toggle();
        this.setState({ ...this.state, patient: {} });
        if (this.props.appointmentBooked) this.props.appointmentBooked();
      }
    }
  };

  validationSchema = Yup.object({
    patient_id: Yup.string().required("Required"),
    consultant_id: Yup.string().required("Required"),
    appointment_type: Yup.string().required("Required"),
    planned_procedure: Yup.string().required("Required"),
    appointment_date: Yup.string().required("Required"),
    time_slot_from: Yup.string().required("Required"),
    slot_duration: Yup.string().required("Required"),
    procedure_notes: Yup.string(),
    roomNumber: Yup.string(),
    service_type: Yup.string(),
    slot: Yup.string().required("Required"),
  });

  componentDidMount() {
    const { dispatch, appointment, consultant, mode } = this.props;
    dispatch(consultantActions.getAll());
    dispatch(departmentActions.getAll());
    serviceMasterService
      .getAllGroups()
      .then((result) => {
        if (result.isSuccess) this.setState({ groups: result.value });
      })
      .catch((err) => {
        toast(JSON.stringify(err) + " in getting groups");
      });
    serviceMasterService
      .getAllPackages()
      .then((result) => {
        if (result.isSuccess) this.setState({ groups: result.value });
      })
      .catch((err) => {
        toast(JSON.stringify(err) + " in getting packages");
      });
    serviceMasterService
      .getAllServices()
      .then((result) => {
        if (result.isSuccess) this.setState({ groups: result.value });
      })
      .catch((err) => {
        toast(JSON.stringify(err) + " in getting service");
      });
    if (consultant) {
      this.initialValues.consultant_id = consultant.code;
      this.onConsultantSelected({ target: { value: consultant.code } });
    }

    if (appointment && mode === "EDIT") {
      this.setState({ patient: appointment.patient_id });
      this.initialValues = {
        patient_id: appointment.patient_id.id,
        consultant_id: appointment.consultant_id,
        planned_procedure: appointment.planned_procedure,
        appointment_type: appointment.appointment_type,
        appointment_date: moment(appointment.appointment_date).format(
          "YYYY-MM-DD"
        ),
        time_slot_from: appointment.time_slot_from,
        slot_duration: appointment.slot_duration,
        procedure_notes: appointment.procedure_notes,
        roomNumber: appointment.room_Number,
        service_type: appointment.service_type,
        slot: appointment.slot,
      };
      this.onConsultantSelected({
        target: { value: appointment.consultant_id },
      });
    }
  }

  onConsultantSelected(e, setFieldValue) {
    const target = e.target;
    const { consultants, departments } = this.props;

    const selectedCons = consultants.find(
      (cons) => cons.id === parseInt(target.value)
    );
    if (selectedCons) {
      const selectedDept = departments.find(
        (dep) => dep.id === selectedCons.department
      );

      if (this.values.slot_duration !== "") {
        setFieldValue &&
          setFieldValue("slot_duration", selectedCons.slot_duration);
      }
      let slotDuration = this.values.slot_duration;
      appointmentService
        .getBookedSlots(
          selectedCons.id,
          this.state.appointment_date.toUTCString()
        )
        .then((result) => {
          if (result.isSuccess) {
            if (result.value.length > 0) {
              let slots = result.value.slots;

              let nextAppTime = slots[slots.length - 1].fromTime;

              const nowTime = new Date(nextAppTime);

              if (slotDuration) {
                const remainingMin = nowTime.getMinutes() % slotDuration;
                var leftMin = slotDuration - remainingMin;
                if (leftMin > 0) {
                  nowTime.setMinutes(nowTime.getMinutes() + leftMin);
                }
                this.setFieldValue &&
                  this.setFieldValue("time_slot_from", nowTime);
              }
              this.setState({
                bookedSlots: result.value,
                nextAppointmentTime: nextAppTime,
              });
            } else {
              const nowTime = new Date(this.state.appointment_date);

              if (selectedCons.slot_duration) {
                const remainingMin = nowTime.getMinutes() % slotDuration;
                var leftMin = slotDuration - remainingMin;
                if (leftMin > 0) {
                  nowTime.setMinutes(nowTime.getMinutes() + leftMin);
                }
                this.setFieldValue &&
                  this.setFieldValue("time_slot_from", nowTime);
              }
            }
          }
        })
        .catch((err) => {});

      this.setState(
        {
          allSlots: selectedCons.slot_setting,
          consultant: selectedCons,
          department: selectedDept,
        },
        () => {
          const date = new Date();
          const day = date.getDay();
          const allSlots = this.state.allSlots;
          const result =
            allSlots && allSlots.find((item) => item.day === days[day]);

          this.setState({ slots_on_date: result }, () => {
            this.onSlotChanged({ target: { value: "A" } });
          });
        }
      );
    }
  }

  onSlotDurationChanged(e, setFieldValue) {
    const target = e.target;

    setFieldValue({ [target.name]: target.value });
    this.onConsultantSelected(
      { target: { value: this.values.consultant_id } },
      setFieldValue
    );
  }

  onDateChange(e, setFieldValue, values) {
    const target = e.target;
    const date = new Date(target.value);
    const day = date.getDay();
    const allSlots = this.state.allSlots;
    const result = allSlots && allSlots.find((item) => item.day === days[day]);
    this.setState({ slots_on_date: result, appointment_date: date });
    setFieldValue("appointment_date", target.value);
    appointmentService
      .getBookedSlots(values.consultant_id, date.toUTCString())
      .then((result) => {
        if (result.isSuccess) {
          if (result.value.length > 0) {
            let slots = result.value.slots;

            let nextAppTime = slots[slots.length - 1].fromTime;

            const nowTime = new Date(nextAppTime);

            if (values.slotDuration) {
              const remainingMin = nowTime.getMinutes() % values.slotDuration;
              var leftMin = values.slotDuration - remainingMin;
              if (leftMin > 0) {
                nowTime.setMinutes(nowTime.getMinutes() + leftMin);
              }
              this.setFieldValue &&
                this.setFieldValue("time_slot_from", nowTime);
            }
            this.setState({
              bookedSlots: result.appointments,
              nextAppointmentTime: nextAppTime,
            });
          } else {
            const nowTime = new Date(this.state.appointment_date);

            if (this.state.consultant.slot_duration) {
              try {
                const remainingMin =
                  nowTime.getMinutes() % this.state.consultant.slot_duration;
                var leftMin =
                  this.state.consultant.slot_duration - remainingMin;
                if (leftMin > 0) {
                  nowTime.setMinutes(nowTime.getMinutes() + leftMin);
                }
                this.setFieldValue &&
                  this.setFieldValue("time_slot_from", nowTime);
              } catch (ex) {
                console.log("ex----", ex);
              }
            }
          }
        }
      })
      .catch((err) => {});
  }

  onSlotChanged(data) {
    let slot = data.target.value;
    let minTime, maxTime;
    let { slots_on_date } = this.state;
    switch (slot) {
      case "A":
        minTime = slots_on_date && slots_on_date.slotA.from;
        maxTime = slots_on_date && slots_on_date.slotA.to;
        break;
      case "B":
        minTime = slots_on_date && slots_on_date.slotB.from;
        maxTime = slots_on_date && slots_on_date.slotB.to;
        break;
      case "C":
        minTime = slots_on_date && slots_on_date.slotC.from;
        maxTime = slots_on_date && slots_on_date.slotC.to;
        break;
      default:
        break;
    }

    this.setState({
      minTime: moment(minTime, "hh:mm a").toDate(),
      maxTime: moment(maxTime, "hh:mm a").toDate(),
    });
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const { formdata, appointment, departments, mode, consultant } = this.props;

    const {
      patient,
      department,
      slots_on_date,
      groups,
      packages,
      services,
      bookedSlots,
      nextAppointmentTime,
    } = this.state;

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
        enableReinitialize
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            getFieldProps,
            setFieldValue,
            setFieldTouched,
          } = props;
          this.setFieldValue = setFieldValue;
          this.values = values;

          let serviceOptions = null;
          {
            if (values.service_type === "package")
              serviceOptions =
                packages &&
                packages.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));
            else if (values.service_type === "service")
              serviceOptions =
                services &&
                services.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));
            else if (values.service_type === "group")
              serviceOptions =
                groups &&
                groups.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));
          }

          return (
            <Modal
              isOpen={this.props.openModal}
              toggle={this.props.toggle}
              className="appointment-modal"
            >
              <ModalHeader toggle={this.props.toggle}>
                Schedule Appointment
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Fragment>
                    <ReactCSSTransitionGroup
                      component="div"
                      transitionName="TabsAnimation"
                      transitionAppear={true}
                      transitionAppearTimeout={0}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <PatientSearchBox
                        onPatientSelected={(pat) =>
                          this.onPatientSelected(pat, setFieldValue)
                        }
                      />

                      {patient && (
                        <Row>
                          <Col sm={12}>
                            <br />
                            <PatientInfo patient={patient} />
                          </Col>
                        </Row>
                      )}
                      <fieldset disabled={patient ? false : true}>
                        <Row>
                          <Col sm={5}>
                            <FormGroup>
                              <Label htmlFor="color">Consultant *</Label>
                              <CustomInput
                                type="select"
                                bsSize="sm"
                                invalid={
                                  errors.consultant_id && touched.consultant_id
                                    ? true
                                    : false
                                }
                                name="consultant_id"
                                value={values.consultant_id}
                                {...getFieldProps("consultant_id")}
                                onChange={(e) => {
                                  this.onConsultantSelected(e, setFieldValue);
                                  handleChange(e);
                                }}
                                disabled={this.props.disabled}
                              >
                                <option value="">Select</option>
                                {this.props.consultants.map((item) => {
                                  if (item.active === true)
                                    return (
                                      <option value={item.id}>
                                        {item.firstname +
                                          " " +
                                          (item.lastname ? item.lastname : "")}
                                      </option>
                                    );
                                })}
                              </CustomInput>
                              {errors.consultant_id &&
                                touched.consultant_id && (
                                  <FormFeedback>
                                    {errors.consultant_id}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                          <Col sm={7}>
                            <FormGroup>
                              {slots_on_date ? (
                                <>
                                  <div>
                                    <Label style={{ float: "left" }}>
                                      Slots :
                                    </Label>
                                    <Label style={{ float: "right" }}>
                                      Department :{" "}
                                      {department && department.name}
                                    </Label>
                                    <div style={{ clear: "both" }} />
                                  </div>
                                  <Row>
                                    {slots_on_date.slotA.from !== "0:00 pm" &&
                                      slots_on_date.slotA.to !== "0:00 pm" && (
                                        <Col md={4}>
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="radio"
                                                value="A"
                                                name="slot"
                                                checked={
                                                  values.slot === "A"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  this.onSlotChanged(e);
                                                  handleChange(e);
                                                }}
                                              />
                                              <span className="badge badge-pill badge-primary">
                                                {" "}
                                                A : {slots_on_date.slotA.from}-
                                                {slots_on_date.slotA.to}
                                              </span>
                                            </Label>
                                          </FormGroup>
                                        </Col>
                                      )}
                                    {slots_on_date.slotB.from !== "0:00 pm" &&
                                      slots_on_date.slotB.to !== "0:00 pm" && (
                                        <Col md={4}>
                                          {" "}
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="radio"
                                                value="B"
                                                name="slot"
                                                checked={
                                                  values.slot === "B"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  this.onSlotChanged(e);
                                                  handleChange(e);
                                                }}
                                              />
                                              <span className="badge badge-pill badge-primary">
                                                {" "}
                                                B : {slots_on_date.slotB.from}-
                                                {slots_on_date.slotB.to}
                                              </span>
                                            </Label>
                                          </FormGroup>
                                        </Col>
                                      )}
                                    {slots_on_date.slotC.from !== "0:00 pm" &&
                                      slots_on_date.slotC.to !== "0:00 pm" && (
                                        <Col md={4}>
                                          {" "}
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="radio"
                                                value="C"
                                                name="slot"
                                                checked={
                                                  values.slot === "C"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  this.onSlotChanged(e);
                                                  handleChange(e);
                                                }}
                                              />
                                              <span className="badge badge-pill badge-primary">
                                                {" "}
                                                C : {slots_on_date.slotC.from}-
                                                {slots_on_date.slotC.to}
                                              </span>
                                            </Label>
                                          </FormGroup>
                                        </Col>
                                      )}
                                  </Row>
                                </>
                              ) : (
                                <FormGroup>
                                  <br />
                                  <Label htmlFor="color">Slot Details</Label>
                                </FormGroup>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5}>
                            <Row>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label for="dob">Scheduled On *</Label>
                                  <InputGroup>
                                    <Input
                                      type="date"
                                      name="appointment_date"
                                      bsSize="sm"
                                      placeholder=""
                                      value={values.appointment_date}
                                      onChange={(e) => {
                                        this.onDateChange(
                                          e,
                                          setFieldValue,
                                          values
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />

                                    {errors.appointment_date &&
                                      touched.appointment_date && (
                                        <FormFeedback>
                                          {errors.appointment_date}
                                        </FormFeedback>
                                      )}
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="time_slot_from">
                                    Time from *
                                  </Label>

                                  <DatePicker
                                    name="time_slot_from"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    onChange={(dateString) =>
                                      setFieldValue(
                                        "time_slot_from",
                                        dateString
                                      )
                                    }
                                    onSelect={(dateString) =>
                                      setFieldValue(
                                        "time_slot_from",
                                        dateString
                                      )
                                    }
                                    autoComplete="off"
                                    className="form-control"
                                    minTime={new Date(this.state.minTime)}
                                    maxTime={new Date(this.state.maxTime)}
                                    value={
                                      values.time_slot_from !== ""
                                        ? moment(values.time_slot_from).format(
                                            "hh:mm a"
                                          )
                                        : new Date(this.state.minTime)
                                    }
                                    timeIntervals={values.slot_duration}
                                    dateFormat="h:mm A"
                                  />

                                  {errors.time_slot_from &&
                                    touched.time_slot_from && (
                                      <FormFeedback>
                                        {errors.time_slot_from}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={4}>
                                <FormGroup>
                                  <Label for="appointment_type">
                                    Appointment Type *
                                  </Label>
                                  <CustomInput
                                    type="select"
                                    bsSize="sm"
                                    invalid={
                                      errors.appointment_type &&
                                      touched.appointment_type
                                        ? true
                                        : false
                                    }
                                    name="appointment_type"
                                    {...getFieldProps("appointment_type")}
                                  >
                                    <option
                                      value={appointmentType.ByAppointment}
                                    >
                                      By Appointment
                                    </option>
                                    <option value={appointmentType.Walkin}>
                                      Walk In
                                    </option>
                                  </CustomInput>
                                  {errors.appointment_type &&
                                    touched.appointment_type && (
                                      <FormFeedback>
                                        {errors.appointment_type}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>

                              <Col md={4}>
                                <FormGroup>
                                  <Label for="slot_duration">
                                    Slot Duration
                                  </Label>
                                  <CustomInput
                                    type="select"
                                    bsSize="sm"
                                    invalid={
                                      errors.slot_duration &&
                                      touched.slot_duration
                                        ? true
                                        : false
                                    }
                                    name="slot_duration"
                                    value={values.slot_duration}
                                    {...getFieldProps("slot_duration")}
                                  >
                                    <option value="5">5 minutes</option>
                                    <option value="10">10 minutes</option>
                                    <option value="15">15 minutes</option>
                                    <option value="30">30 minutes</option>
                                    <option value="45">45 minutes</option>
                                    <option value="45">60 minutes</option>
                                  </CustomInput>

                                  {errors.slot_duration &&
                                    touched.slot_duration && (
                                      <FormFeedback>
                                        {errors.slot_duration}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>

                              <Col md={4}>
                                <FormGroup>
                                  <Label for="roomNumber">Room Number</Label>
                                  <Input
                                    type="text"
                                    bsSize="sm"
                                    invalid={
                                      errors.roomNumber && touched.roomNumber
                                        ? true
                                        : false
                                    }
                                    name="roomNumber"
                                    {...getFieldProps("roomNumber")}
                                  />

                                  {errors.roomNumber && touched.roomNumber && (
                                    <FormFeedback>
                                      {errors.roomNumber}
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={7}>
                            <Row>
                              <Col sm={3}>
                                <FormGroup>
                                  <Label for="service_type">
                                    Service Type{" "}
                                  </Label>
                                  <CustomInput
                                    type="select"
                                    bsSize="sm"
                                    invalid={
                                      errors.service_type &&
                                      touched.service_type
                                        ? true
                                        : false
                                    }
                                    name="service_type"
                                    {...getFieldProps("service_type")}
                                  >
                                    <option value="service">Service</option>
                                    <option value="package">Package</option>
                                    <option value="group">Group</option>
                                  </CustomInput>

                                  {errors.service_type &&
                                    touched.service_type && (
                                      <FormFeedback>
                                        {errors.service_type}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col sm={9}>
                                <FormGroup>
                                  <Label for="package">Planned Procedure</Label>

                                  <Select
                                    style={{ padding: 0 }}
                                    label=""
                                    name="planned_procedure"
                                    required="true"
                                    value={values.planned_procedure}
                                    // readOnly={readOnly}
                                    data={{
                                      dataSrc: "values",
                                      values: serviceOptions || [],
                                    }}
                                    onChange={(v) => {
                                      setFieldValue(
                                        "planned_procedure",
                                        v.target.value + ""
                                      );
                                    }}
                                  />
                                  {errors.planned_procedure &&
                                    touched.planned_procedure && (
                                      <FormFeedback>
                                        {errors.planned_procedure}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <FormGroup>
                              <Label for="planned_procedure">
                                Procedure Notes
                              </Label>
                              <Input
                                type="textarea"
                                bsSize="sm"
                                name="procedure_notes"
                                placeholder="Procedure Notes"
                                {...getFieldProps("procedure_notes")}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={2} className="offset-sm-7">
                          {
                            this.props.mode === "NEW" ?
                            <Button
                              color="warning"
                              className="btn btn-block"
                              onClick={handleReset}
                            >
                              <FontAwesomeIcon icon={faEraser} size="1x" />{" "}
                              Clear
                            </Button>
                           : ''
                          }
                          </Col>
                          <Col sm={3}>
                            <Button
                              color="primary"
                              className="btn btn-block"
                              type="submit"
                            >
                              <FontAwesomeIcon icon={faSave} size="1x" /> &nbsp;
                              {this.props.mode === "NEW" && "Book Appointment"}
                              {this.props.mode === "EDIT" &&
                                "Update Appointment"}
                            </Button>
                          </Col>
                        </Row>
                      </fieldset>
                    </ReactCSSTransitionGroup>
                  </Fragment>
                </Form>
              </ModalBody>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
function mapStateToProps(state) {
  const {
    consultants,
    customers,
    departments,
    serviceMaster,
    appointments,
    registrationMaster,
  } = state;

  return {
    consultants: consultants.consultants,
    transaction: customers.currentTransaction,
    departments: departments.departments,
    packageData: serviceMaster.data,
    appointment:
      appointments.selected_appointment ?? appointments.appointments[0],
    masters: registrationMaster.data,
  };
}

const connectedAppointment = connect(mapStateToProps)(Appointment);
export default connectedAppointment;
