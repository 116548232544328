import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const reportsService = {
    getDashboardReportCharts,
	getReceiptWiseCollectionReport
}

const serialize = function(obj) {
	var str = [];
	for (var p in obj)
	  if (obj.hasOwnProperty(p)) {
		str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	  }
	return str.join("&");
}

function getDashboardReportCharts(payload) {
    const requestOptions = {
        method: 'GET',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/api/reports/opd/getDashboardReportCharts?${serialize(payload)}`, requestOptions).then(helperService.handleResponse);
}

function getReceiptWiseCollectionReport(payload) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(payload)
    };
    return fetch(`${apiUrl}/api/reports/opd/getReceiptWiseCollectionReport`, requestOptions).then(helperService.handleResponse);
}

