import { alertActions } from "./alert.actions";
import { toast, Bounce } from "react-toastify";
import { httpHelperService } from "../services";
import { wardManagementConstants } from "../constants";

export const wardManagementActions = {
  getWardAll,
  addWardType,
  deleteWardtype,
  deactiveWardType,
  updateWardType,

  getAllWardCatagory,
  getByWardType,
  addWardCatagory,
  deleteWardCatagory,
  deactiveWardCatagory,
  updateWardCatagory,

  getAllWardSubCatagory,
  addWardSubCatagory,
  deleteWardSubCatagory,
  deactiveWardSubCatagory,
  updateWardSubCatagory,

  getAllWardCatagoryFees,
  addWardCatagoryFees,
  deactiveWardCatagoryFees,
  deleteWardCatagoryFees,
  updateWardCatagoryFees,

  getByWardCatagory,
  getAllWardFacility,
  addWardFacility,
  deleteWardFacility,
  deactiveWardFacility,
  updateWardFacility,

  getRoomAll,
  addWardRoom,
  deactiveWardRoom,
  deleteWardRoom,

  getGraceperiodsettingAll,
  addGraceperiodsetting,
  updateGraceperiodsetting,
  deleteGraceperiodsetting,

  getBedAll,
  addWardBed,
  deactiveWardBed,
};

function getWardAll() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardtypeapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_ALL_WARD_TYPE_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.GET_ALL_WARD_TYPE_FAILURE, error };
  }
}

function addWardType(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardtypeapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.ADD_ALL_WARD_TYPE_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.ADD_ALL_WARD_TYPE_FAILURE, error };
  }
}

function deleteWardtype(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardtypeapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DELETE_ALL_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.DELETE_ALL_WARD_TYPE_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_TYPE_FAILURE,
      error,
    };
  }
}

function updateWardType(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardtypeapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.UPDATE_ALL_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.UPDATE_ALL_WARD_TYPE_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_TYPE_FAILURE,
      error,
    };
  }
}

function deactiveWardType(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardtypeapi/deactive";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_TYPE_FAILURE,
      error,
    };
  }
}

function getAllWardCatagory() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function addWardCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function updateWardCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function deleteWardCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function deactiveWardCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi/deactive";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function getByWardType(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryapi/getByWardType";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_BY_WARD_TYPE_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_BY_WARD_TYPE_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.GET_BY_WARD_TYPE_FAILURE, error };
  }
}

function getAllWardSubCatagory() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_SUB_CATAGORY_FAILURE,
      error,
    };
  }
}

function addWardSubCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_SUB_CATAGORY_FAILURE,
      error,
    };
  }
}

function deactiveWardSubCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi/deactive";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_SUB_CATAGORY_FAILURE,
      error,
    };
  }
}

function deleteWardSubCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_SUB_CATAGORY_FAILURE,
      error,
    };
  }
}

function updateWardSubCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_SUB_CATAGORY_FAILURE,
      error,
    };
  }
}

function getAllWardCatagoryFees() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryfeesapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_CATAGORY_FEES_FAILURE,
      error,
    };
  }
}

function addWardCatagoryFees(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryfeesapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_CATAGORY_FEES_FAILURE,
      error,
    };
  }
}

function deactiveWardCatagoryFees(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryfeesapi/deactive";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_CATAGORY_FEES_FAILURE,
      error,
    };
  }
}
function deleteWardCatagoryFees(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryfeesapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_CATAGORY_FEES_FAILURE,
      error,
    };
  }
}

function updateWardCatagoryFees(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardcatagoryfeesapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_CATAGORY_FEES_FAILURE,
      error,
    };
  }
}

function getAllWardFacility() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardfacalityapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_WARD_FACILITY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_FACILITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.GET_ALL_WARD_FACILITY_FAILURE,
      error,
    };
  }
}

function addWardFacility(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardfacalityapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_WARD_FACILITY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_FACILITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.ADD_ALL_WARD_FACILITY_FAILURE,
      error,
    };
  }
}

function deactiveWardFacility(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardfacalityapi/deactiveservice";
    // let url = "wardfacalityapi/deactive";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_FACILITY_FAILURE,
      error,
    };
  }
}
function deleteWardFacility(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardfacalityapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DELETE_ALL_WARD_FACILITY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_FACILITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_FACILITY_FAILURE,
      error,
    };
  }
}

function updateWardFacility(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardfacalityapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.UPDATE_ALL_WARD_FACILITY_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_FACILITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_WARD_FACILITY_FAILURE,
      error,
    };
  }
}

function getByWardCatagory(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardsubcatagoryapi/getByWardCatagory";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_BY_WARD_CATAGORY_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_BY_WARD_CATAGORY_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.GET_BY_WARD_CATAGORY_FAILURE,
      error,
    };
  }
}

function getRoomAll() {
  return (dispatch) => {
    dispatch(request());
    let url = "wardroomapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_ROOM_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_ALL_ROOM_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.GET_ALL_ROOM_FAILURE, error };
  }
}

function addWardRoom(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardroomapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ROOM_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.ADD_ROOM_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.ADD_ROOM_FAILURE, error };
  }
}

function deleteWardRoom(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardroomapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DELETE_ALL_WARD_ROOM_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.DELETE_ALL_WARD_ROOM_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_WARD_ROOM_FAILURE,
      error,
    };
  }
}

function deactiveWardRoom(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "wardroomapi/deactive";
    // let url = "wardroomapi/deactiveRoom";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_ROOM_FAILURE,
      error,
    };
  }
}
function getGraceperiodsettingAll() {
  return (dispatch) => {
    dispatch(request());
    let url = "graceperiodsettingapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_GRACEPERIOD_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_ALL_GRACEPERIOD_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.GET_ALL_GRACEPERIOD_FAILURE, error };
  }
}
function addGraceperiodsetting(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "graceperiodsettingapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_ALL_GRACEPERIOD_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.ADD_ALL_GRACEPERIOD_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.ADD_ALL_GRACEPERIOD_FAILURE, error };
  }
}
function updateGraceperiodsetting(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "graceperiodsettingapi/update";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.UPDATE_ALL_GRACEPERIOD_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.UPDATE_ALL_GRACEPERIOD_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.UPDATE_ALL_GRACEPERIOD_FAILURE,
      error,
    };
  }
}

function deleteGraceperiodsetting(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "graceperiodsettingapi/delete";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DELETE_ALL_GRACEPERIOD_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DELETE_ALL_GRACEPERIOD_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DELETE_ALL_GRACEPERIOD_FAILURE,
      error,
    };
  }
}

function getBedAll() {
  return (dispatch) => {
    dispatch(request());
    let url = "bedapi";

    httpHelperService.getRequest(url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.GET_ALL_BED_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.GET_ALL_BED_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.GET_ALL_BED_FAILURE, error };
  }
}

function addWardBed(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "bedapi";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.ADD_BED_REQUEST };
  }
  function success(data) {
    return { type: wardManagementConstants.ADD_BED_SUCCESS, data };
  }
  function failure(error) {
    return { type: wardManagementConstants.ADD_BED_FAILURE, error };
  }
}

function deactiveWardBed(payload) {
  return (dispatch) => {
    dispatch(request());
    let url = "bedapi/deactive";
    // let url = "wardBedapi/deactiveBed";

    httpHelperService.postRequest(payload, url).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: wardManagementConstants.DEACTIVE_ALL_WARD_BED_REQUEST };
  }
  function success(data) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_BED_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: wardManagementConstants.DEACTIVE_ALL_WARD_BED_FAILURE,
      error,
    };
  }
}
