import { servieMasterConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { serviceMasterService } from "../services/servicemaster.service";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";
import { toastHelper } from "../helpers/toast.helper";

export const serviceMasterActions = {
  add,
  getAllServices,
  addPackageMapping,
  getAllPackages,
  getAllGroups,
  getAllPackagesMainTestLab,
  getAllPackagesSubTestLab,
  Edit,
  deactiveServices,
  deleteServices,
  mapOldServicePayload,
  mapOldPackagePayload,
  mapOldGroupPayload,
};

let serviceTypeEnum = {
  services: 1,
  packages: 2,
  groups: 3,
};

let enumServiceType = 
{
    Surgery : 1,
    Procedure : 2,
    Service: 3
}
function mapServicePayload(oldPayload) {
  return {
    name: oldPayload.SERVICE_NAME,
    userDefinedName: oldPayload.USERDEFINED_NAME,
    departmentId: parseInt(oldPayload.D_CODE),
    subDepartmentId: parseInt(oldPayload.SD_CODE),
    serviceType: oldPayload.PROCEDURE === "Surgery"
    ? 1 
    : oldPayload.PROCEDURE === "Procedure"
    ? 2
    : oldPayload.PROCEDURE === "Service"
    ? 3: 0, // Assuming a default value for service type
    roundOffType:
      oldPayload.roundoffType === "floor"
        ? 1
        : oldPayload.roundoffType === "ceil"
        ? 2
        : 3,
    printingSequence: parseInt(oldPayload.SEQUENCE) || 0,
    serviceAmount: oldPayload.GstExclusiveAmount || 0,
    serviceAccessType: oldPayload.IN_HOUSE === "Inhouse Diagnostics"
    ?  1
    : oldPayload.IN_HOUSE === "TP Diagnostics"
    ? 2
    : oldPayload.IN_HOUSE === "Outsource Diagnostics"
    ? 3
    : "", // Assuming a default value for service access type
    costCenterId: oldPayload.CCM_CODE,
    hospitalSharingPercent: oldPayload.Revenue_Perc_Hosp || 0,
    hospitalSharingAmount: oldPayload.REVENUE_SHARE_HOSP || 0,
    hospitalLabSharingPercent: oldPayload.Revenue_Perc_Lab || 0,
    hospitalLabSharingAmount: oldPayload.Revenue_Share_Lab || 0,
    outsourcedSharingPercent: oldPayload.Revenue_Perc_OutS || 0,
    outSourcedSharingAmount: oldPayload.REVENUE_SHARE_OUT || 0,
    mandatoryConsultantName: oldPayload.CONS_NAME_BILLING,
    mandatoryReferralName: oldPayload.REF_AMT_APP,
    editServiceQuantity: oldPayload.OP_QTY_EDIT,
    editServiceAmount: oldPayload.EDIT_SERVICE_AMT,
    allowDiscount: oldPayload.DISCOUNT_APP,
    gst: oldPayload.TAX_APPLICABLE_IN_OP,
    gstPercent: oldPayload.TAX_AMOUNT_OP || 0,
    gstAmount: oldPayload.gstAmount || 0,
    gstInclusiveAmount: oldPayload.gstInclusiveAmount || 0,
    defaultService: oldPayload.AUTO_SHOW_OP || false,
    editDischargeSummary: oldPayload.dischargeSummeryOP || false, // This field is not present in the old payload
    reportingType:
      oldPayload.reporting_type_op === "tabular_format"
        ? 1
        : oldPayload.reporting_type_op === "template_format"
        ? 2
        : oldPayload.reporting_type_op === "culture_format"
        ? 3
        : 0,
    notes: oldPayload.notes_for_op || "",
    applicableTo: 2,
    linkedRecordId: 0, // Assuming a default value for linked record ID
  };
}

function mapOldServicePayload(newPayload) {
  return {
    id: newPayload.id ?? 0,
    isDeleted: newPayload.deleted ?? false,
    SERVICE_NAME: newPayload.name,
    USERDEFINED_NAME: newPayload.userDefinedName,
    D_CODE: newPayload.departmentId || 0,
    SD_CODE: newPayload.subDepartmentId || 0,
    type:
      newPayload.serviceType === 1
        ? "services"
        : newPayload.serviceType === 2
        ? "packages"
        : "groups", // Assuming a default value for service type
    roundoffType:
      newPayload.roundOffType === 1
        ? "floor"
        : newPayload.roundOffType === 2
        ? "ceil"
        : "other",
    SEQUENCE: String(newPayload.printingSequence || 0),
    SERVICE_AMT: newPayload.gstInclusiveAmount || 0,
    CCM_CODE: newPayload.costCenterId || 0,
    REVENUE_SHARE_HOSP: newPayload.hospitalSharingAmount || 0,
    Revenue_Perc_Lab: newPayload.hospitalLabSharingPercent || 0,
    Revenue_Share_Lab: newPayload.hospitalLabSharingAmount || 0,
    Revenue_Perc_OutS: newPayload.outsourcedSharingPercent || 0,
    Revenue_Perc_Hosp: newPayload.hospitalSharingPercent || 0,
    REVENUE_SHARE_OUT: newPayload.outSourcedSharingAmount || 0,
    CONS_NAME_BILLING: newPayload.mandatoryConsultantName,
    REF_AMT_APP: newPayload.mandatoryReferralName,
    OP_QTY_EDIT: newPayload.editServiceQuantity,
    EDIT_SERVICE_AMT: newPayload.editServiceAmount,
    DISCOUNT_APP: newPayload.allowDiscount,
    TAX_APPLICABLE_IN_OP: newPayload.gst,
    TAX_AMOUNT_OP: String(newPayload.gstPercent || 0),
    gstAmount: String(newPayload.gstAmount || 0),
    gstExclusiveAmount: newPayload.serviceAmount || 0,
    AUTO_SHOW_OP: newPayload.defaultService || false,
    reporting_type_op:
      newPayload.reportingType === 1
        ? "tabular_format"
        : newPayload.reportingType === 2
        ? "template_format"
        : newPayload.reportingType === 3
        ? "culture_format"
        : "",
    notes_for_op: newPayload.notes || "",
    CREATED_BY: newPayload.createdBy || "",
    UPDATED_BY: newPayload.updatedBy || "",
    dischargeSummeryOP: newPayload.editDischargeSummary || false,
    type: "services",
    IN_HOUSE :  newPayload.serviceAccessType === 1
    ? "Inhouse Diagnostics"
    : newPayload.serviceAccessType === 2
    ? "TP Diagnostics"
    : newPayload.serviceAccessType === 3
    ? "Outsource Diagnostics"
    : "",
    PROCEDURE : newPayload.serviceType === 1
    ? "Surgery" 
    : newPayload.serviceType === 2
    ? "Procedure"
    : newPayload.serviceType === 3
    ? "Service": ""
  }
}

function mapGroupPayload(oldPayload) {
  return {
    group: {
      name: oldPayload.SERVICE_NAME,
      userDefinedName: oldPayload.USERDEFINED_NAME,
      departmentId: parseInt(oldPayload.D_CODE),
      subDepartmentId: parseInt(oldPayload.SD_CODE),
      serviceType: serviceTypeEnum[oldPayload.type], // Assuming a default value for service type
      roundOffType:
        oldPayload.roundoffType === "ceil"
          ? 1
          : oldPayload.roundoffType === "floor"
          ? 2
          : 3,
      printingSequence: parseInt(oldPayload.SEQUENCE) || 0,
      serviceAccessType: 1, // Assuming a default value for service access type
      costCenterId: oldPayload.CCM_CODE,
      discountPercentage: oldPayload.perChange ? 100 - oldPayload.perChange : 0,
      // Assuming a default value for discount percentage
      applicableTo: 2, // Assuming a default value for applicableTo
      hospitalSharingPercent: oldPayload.Revenue_Perc_Hosp || 0,
      hospitalSharingAmount: oldPayload.REVENUE_SHARE_HOSP || 0,
      hospitalLabSharingPercent: oldPayload.Revenue_Perc_Lab || 0,
      hospitalLabSharingAmount: oldPayload.Revenue_Share_Lab || 0,
      outsourcedSharingPercent: oldPayload.Revenue_Perc_OutS || 0,
      outSourcedSharingAmount: oldPayload.REVENUE_SHARE_OUT || 0,
      services: oldPayload.selectedState.map((service) => ({
        serviceId: service.id || 0,
        newAmount: service.newamount || 0,
        notes: service.notes || "",
        priority: parseInt(service.reporting_priority) || 0,
      })),
    },
    applicableTo: 2, // Assuming a default value for applicableTo
  };
}

function mapOldGroupPayload(newData) {
  return {
    APPLICABLE_IP: newData.serviceType === 1 ? true : false,
    APPLICABLE_OP: newData.serviceType === 0 ? true : false,
    CCM_CODE: newData.costCenterId,
    id: newData.id,
    // CONS_NAME_BILLING: newData.services[0].mandatoryConsultantName,
    CREATED_BY: newData.createdBy,
    CREATED_ON: newData.createdAt,
    //DISCOUNT_APP: newData.services[0].allowDiscount ? 1 : 0,
    D_CODE: newData.departmentId,
    //EDIT_SERVICE_AMT: newData.services[0].editServiceAmount ? 1 : 0,
    GROUP_AMT: newData.groupAmount,
    GROUP_NAME: newData.name,
    IN_HOUSE: newData.IN_HOUSE,
    //  IP_CONS_NAME_BILLING: newData.services[0].mandatoryConsultantName,
    IP_QTY_EDIT: newData.IP_QTY_EDIT,
    OP_QTY_EDIT: newData.OP_QTY_EDIT ? 1 : 0,
    // OUT_SOURCED:
    // newData.services[0].outsourcedSharingPercent > 0 ? true : false,
    // REF_AMT_APP: newData.services[0].mandatoryReferralName ? 1 : 0,
    REVENUE_SHARE_HOSP: newData.hospitalSharingAmount,
    REVENUE_SHARE_OUT: newData.outSourcedSharingAmount,
    Revenue_Perc_Hosp: newData.hospitalSharingPercent,
    Revenue_Perc_Lab: newData.hospitalLabSharingPercent,
    Revenue_Perc_OutS: newData.outsourcedSharingPercent,
    Revenue_Share_Lab: newData.hospitalLabSharingAmount,
    SD_CODE: newData.subDepartmentId,
    SEQUENCE: newData.printingSequence,
    UPDATED_BY: newData.updatedBy,
    USERDEFINED_NAME: newData.userDefinedName,
    discount: newData.discountPercentage,
    isActive: newData.isActive,
    isDeleted: newData.deleted ? true : false,
    roundoffType:
      newData.roundOffType === 1
        ? "ceil"
        : newData.roundOffType === 2
        ? "floor"
        : "other",
    services: newData.services,
    serviceInfo: newData.serviceInfo,
  };
}

function mapPackagePayload(oldPayload) {
  return {
    package: {
      name: oldPayload.SERVICE_NAME,
      userDefinedName: oldPayload.USERDEFINED_NAME,
      departmentId: parseInt(oldPayload.D_CODE),
      subDepartmentId: parseInt(oldPayload.SD_CODE),
      serviceType: serviceTypeEnum[oldPayload.type], // Assuming a default value for service type
      roundOffType:
        oldPayload.roundoffType === "ceil"
          ? 1
          : oldPayload.roundoffType === "floor"
          ? 2
          : 3,
      printingSequence: parseInt(oldPayload.SEQUENCE) || 0,
      serviceAccessType: 1, // Assuming a default value for service access type
      costCenterId: oldPayload.CCM_CODE,
      discountPercentage: oldPayload.perChange ? 100 - oldPayload.perChange : 0, // Assuming a default value for discount percentage
      applicableTo: 2, // Assuming a default value for applicableTo
      hospitalSharingPercent: oldPayload.Revenue_Perc_Hosp || 0,
      hospitalSharingAmount: oldPayload.REVENUE_SHARE_HOSP || 0,
      hospitalLabSharingPercent: oldPayload.Revenue_Perc_Lab || 0,
      hospitalLabSharingAmount: oldPayload.Revenue_Share_Lab || 0,
      outsourcedSharingPercent: oldPayload.Revenue_Perc_OutS || 0,
      outSourcedSharingAmount: oldPayload.REVENUE_SHARE_OUT || 0,
      services: [],
      groups: [],
    },
    applicableTo: 2, // Assuming a default value for applicableTo
  };
}

function mapOldPackagePayload(newData) {
  return {
    APPLICABLE_IP: newData.serviceType === 1 ? true : false,
    APPLICABLE_OP: newData.serviceType === 0 ? true : false,
    CCM_CODE: newData.costCenterId,
    id: newData.id,
    // CONS_NAME_BILLING: newData.services[0].mandatoryConsultantName,
    CREATED_BY: newData.createdBy,
    CREATED_ON: newData.createdAt,
    // newData.services[0].allowDiscount ? 1 : 0,
    D_CODE: newData.departmentId,
    //EDIT_SERVICE_AMT: newData.services[0].editServiceAmount ? 1 : 0,
    PACKAGE_AMT: newData.grossAmount,
    PACKAGE_SERVICE_AMT: newData.packageAmount,
    PACKAGE_NAME: newData.name,
    IN_HOUSE: newData.IN_HOUSE,
    //IP_CONS_NAME_BILLING: newData.services[0].mandatoryConsultantName,
    IP_QTY_EDIT: newData.IP_QTY_EDIT,
    OP_QTY_EDIT: newData.OP_QTY_EDIT ? 1 : 0,
    //OUT_SOURCED:
    // newData.services[0].outsourcedSharingPercent > 0 ? true : false,
    //REF_AMT_APP: newData.services[0].mandatoryReferralName ? 1 : 0,
    REVENUE_SHARE_HOSP: newData.hospitalSharingAmount,
    REVENUE_SHARE_OUT: newData.outSourcedSharingAmount,
    Revenue_Perc_Hosp: newData.hospitalSharingPercent,
    Revenue_Perc_Lab: newData.hospitalLabSharingPercent,
    Revenue_Perc_OutS: newData.outsourcedSharingPercent,
    Revenue_Share_Lab: newData.hospitalLabSharingAmount,
    SD_CODE: newData.subDepartmentId,
    SEQUENCE: newData.printingSequence,
    UPDATED_BY: newData.updatedBy,
    USERDEFINED_NAME: newData.userDefinedName,
    discount: newData.discountPercentage,
    isActive: newData.isActive,
    isDeleted: newData.deleted ? true : false,
    roundoffType:
      newData.roundOffType === 1
        ? "ceil"
        : newData.roundOffType === 2
        ? "floor"
        : "other",
    services: newData.services,
    groups: newData.groups,
    serviceInfo: newData.serviceInfo,
    groupsInfo: newData.groupsInfo,
  };
}

function deleteServices(payload, servicetype, index) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.deleteService(payload).then(
      (result) => {
        if (result) {
          dispatch(success(servicetype, index));
          toastHelper("deleted successfully", "success");
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toastHelper(`${result.message}`, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.DELETE_SERVICE_REQUEST };
  }
  function success(servicetype, index) {
    return {
      type: servieMasterConstants.DELETE_SERVICE_SUCCESS,

      servicetype,
      index,
    };
  }
  function failure(error) {
    return { type: servieMasterConstants.DELETE_SERVICE_FAILURE, error };
  }
}

function deactiveServices(payload, servicetype, index) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.deactiveService(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result, servicetype, index));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.DEACTIVE_SERVICE_REQUEST };
  }
  function success(data, servicetype, index) {
    return {
      type: servieMasterConstants.DEACTIVE_SERVICE_SUCCESS,
      data,
      servicetype,
      index,
    };
  }
  function failure(error) {
    return { type: servieMasterConstants.DEACTIVE_SERVICE_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    let newPayload;
    if (payload.type === "services") {
      newPayload = { service: mapServicePayload(payload) };
    } else if (payload.type === "packages") {
      newPayload = mapPackagePayload(payload);
      payload.selectedState.reduce((acc, item) => {
        if (item.type === "services") {
          acc.package.services.push({
            serviceId: item.id || 0,
            newAmount: item.newamount || 0,
            notes: item.notes || "",
            priority: parseInt(item.reporting_priority) || 0,
          });
        } else if (item.type === "groups") {
          acc.package.groups.push({
            groupId: item.id || 0,
            newAmount: item.newamount || 0,
            notes: item.notes || "",
            priority: parseInt(item.reporting_priority) || 0,
          });
        }
        return acc;
      }, newPayload);
    } else {
      newPayload = mapGroupPayload(payload);
    }
    serviceMasterService.add(newPayload).then(
      (result) => {
        if (result.isSuccess) {
          toastHelper("added successfully", "success");
          dispatch(alertActions.success(result.successMessage));

          history.goBack();
        } else {
          dispatch(failure(result.err));
          toastHelper(result.errors, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.ADD_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.ADD_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: servieMasterConstants.ADD_FAILURE, error };
  }
}

function Edit(payload) {
  return (dispatch) => {
    dispatch(request());
    let newPayload;
    if (payload.type === "services") {
      newPayload = { id: payload.id, service: mapServicePayload(payload) };
    } else if (payload.type === "packages") {
      newPayload = {
        id: payload.id,
        package: mapPackagePayload(payload).package,
      };
      payload.selectedState.reduce((acc, item) => {
        if (item.type === "services") {
          acc.package.services.push({
            serviceId: item.id || 0,
            newAmount: item.newamount || 0,
            notes: item.notes || "",
            priority: parseInt(item.reporting_priority) || 0,
          });
        } else if (item.type === "groups") {
          acc.package.groups.push({
            groupId: item.id || 0,
            newAmount: item.newamount || 0,
            notes: item.notes || "",
            priority: parseInt(item.reporting_priority) || 0,
          });
        }
        return acc;
      }, newPayload);
    } else {
      newPayload = { id: payload.id, group: mapGroupPayload(payload).group };
    }
    serviceMasterService.editServices(newPayload, payload.id).then(
      (result) => {
        if (result) {
          dispatch(success(result));
          toastHelper("updated successfully", "success");

          history.goBack();

          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toastHelper(result.errors, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.EDIT_PACKAGE_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.EDIT_PACKAGE_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: servieMasterConstants.EDIT_PACKAGE_FAILURE, error };
  }
}

function getAllServices(type, viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.getAllServices(type, viewDeleted).then(
      (result) => {
        if (result.isSuccess) {
          const oldBody = result.value.map((service) =>
            mapOldServicePayload(service)
          );

          dispatch(success(oldBody));

          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toastHelper(result.errors, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.GET_ALL_SERVICES_REQUEST };
  }
  function success(services) {
    return { type: servieMasterConstants.GET_ALL_SERVICES_SUCCESS, services };
  }
  function failure(error) {
    return { type: servieMasterConstants.GET_ALL_SERVICES_FAILURE, error };
  }
}

function getAllPackages(type, viewDeleted= true) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.getAllPackages(type, viewDeleted).then(
      (result) => {
        if (result.isSuccess) {
          const oldBody = result.value.map((service) =>
            mapOldPackagePayload(service)
          );

          dispatch(success(oldBody));

          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toastHelper(result.errors, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_FAILURE, error };
  }
}

function getAllGroups(type, viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.getAllGroups(type, viewDeleted).then(
      (result) => {
        if (result.isSuccess) {
          const oldBody = result.value.map((group) =>
            mapOldGroupPayload(group)
          );

          dispatch(success(oldBody));

          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toastHelper(result.errors, "error");
        }
      },
      (error) => {
        toastHelper(error.message, "error");
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.GET_ALL_GROUPS_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.GET_ALL_GROUPS_SUCCESS, data };
  }
  function failure(error) {
    return { type: servieMasterConstants.GET_ALL_GROUPS_FAILURE, error };
  }
}

function getAllPackagesMainTestLab() {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.getAllPackagesMainTestLab().then(
      (result) => {
        if (result.success) {
          dispatch(success(result));

          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_FAILURE, error };
  }
}

function getAllPackagesSubTestLab() {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.getAllPackagesSubTestLab().then(
      (result) => {
        if (result.success) {
          dispatch(success(result));

          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: servieMasterConstants.GET_ALL_PACKAGES_FAILURE, error };
  }
}

function addPackageMapping(payload) {
  return (dispatch) => {
    dispatch(request());

    serviceMasterService.addPackageMapping(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          history.goBack();
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: servieMasterConstants.ADD_PACKAGE_REQUEST };
  }
  function success(data) {
    return { type: servieMasterConstants.ADD_PACKAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: servieMasterConstants.ADD_PACKAGE_FAILURE, error };
  }
}
