//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const laboratoryReportService = {
  getAll,
  update,
  createReport,
  updateReport,
  updateFormData,
  approveReport,
  getByDate,
  getStatusCount,
  remove,
  search,
  getCollectionReport,
  getConsolidatedCollectionReport,
  getByLabPatientId,
  getPatientReport,
  approvePurchaseorder,
  purchaseOrder,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/laboratoryreports`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByLabPatientId(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratoryreports/getByLabPatientId?id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function purchaseOrder(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/approve`, requestOptions).then(
    helperService.handleResponse
  );
}
function approvePurchaseorder(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/approved`, requestOptions).then(
    helperService.handleResponse
  );
}
function search(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ query: data }),
  };

  return fetch(`${apiUrl}/laboratoryreports/search`, requestOptions).then(
    helperService.handleResponse
  );
}
function getCollectionReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ query: data }),
  };

  return fetch(
    `${apiUrl}/laboratoryreports/getCollectionReport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getStatusCount(data) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(`${apiUrl}/laboratoryreports/getCounts`, requestOptions).then(
    helperService.handleResponse
  );
}

function getConsolidatedCollectionReport(data) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/laboratoryreports/getConsolidated`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratoryreports/getByDate/${from}/${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function createReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratoryreports/createReport`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratoryreports/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateFormData(data) {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
    body: data,
  };

  return fetch(`${apiUrl}/laboratoryreports/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function approveReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/laboratoryreports/approveReport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function updateReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratoryreports/updateReport`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratoryreports/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function getPatientReport(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/laboratoryreports/getPatientReport/?patient_id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
