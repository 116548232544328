export const reportsConstants = {

    GET_PATIENT_REG_COUNT_REQUEST : "GET_PATIENT_REG_COUNT_REQUEST",
    GET_PATIENT_REG_COUNT_SUCCESS : "GET_PATIENT_REG_COUNT_SUCCESS",
    GET_PATIENT_REG_COUNT_FAILURE : "GET_PATIENT_REG_COUNT_FAILURE",

    GET_BILLWISE_COLLECTION_REQUEST : "GET_BILLWISE_COLLECTION_REQUEST",
    GET_BILLWISE_COLLECTION_SUCCESS : "GET_BILLWISE_COLLECTION_SUCCESS",
    GET_BILLWISE_COLLECTION_FAILURE : "GET_BILLWISE_COLLECTION_FAILURE",

}