import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { costcenterService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const costcenterActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll(viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());
    costcenterService.getAll(viewDeleted).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success("COst centers loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_COSTCENTER_REQUEST };
  }
  function success(costcenters) {
    return { type: generalMasterConstants.GET_COSTCENTER_SUCCESS, costcenters };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_COSTCENTER_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    costcenterService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_COSTCENTER_REQUEST };
  }
  function success(costcenter) {
    return { type: generalMasterConstants.ADD_COSTCENTER_SUCCESS, costcenter };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_COSTCENTER_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    costcenterService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_COSTCENTER_REQUEST };
  }
  function success(costcenter) {
    return {
      type: generalMasterConstants.UPDATE_COSTCENTER_SUCCESS,
      costcenter,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_COSTCENTER_FAILURE, error };
  }
}

function remove(payload, costcenter) {
  return (dispatch) => {
    dispatch(request());
    costcenterService.remove(payload).then(
      (data) => {
        if (data) {
          dispatch(success(payload.id));
          //dispatch(alertActions.success(data.message));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_COSTCENTER_REQUEST };
  }
  function success(costcenter) {
    return {
      type: generalMasterConstants.DELETE_COSTCENTER_SUCCESS,
      costcenter,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_COSTCENTER_FAILURE, error };
  }
}
