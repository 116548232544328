//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"
import { da } from "date-fns/locale";



export const agreementService = {        
    getAll,
    add,
    update,
    remove,
    get
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
      headers:{  authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/consentforms?viewDeleted=true`, requestOptions).then(helperService.handleResponse);
}

function get(code) {
    const requestOptions = {
        method: 'GET',
      headers:{  authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/agreements/${code}`, requestOptions).then(helperService.handleResponse);
}


function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/consentforms`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'Put',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/consentforms/${data.id}`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'DELETE',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/consentforms/delete/${data.id}`, requestOptions).then(helperService.handleResponse);
}
