
    export const formConstants={

       GET_FORM_REQUEST:'GET_FORM_REQUEST',
       GET_FORM_SUCCESS:'GET_FORM_SUCCESS',
       GET_FORM_FAILURE:'GET_FORM_FAILURE' ,

       GET_ALL_FORM_REQUEST:'GET_ALL_FORM_REQUEST',
       GET_ALL_FORM_SUCCESS: 'GET_ALL_FORM_SUCCESS',
       GET_ALL_FORM_FAILURE:' GET_ALL_FORM_FAILURE' ,

       FORM_CLEAR:'FORM_CLEAR'

    
    }