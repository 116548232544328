import { patientConstants } from "../constants";

export function patients(
  state = { loading: false, patients: [], dateWisePatients: [] },
  action
) {
  switch (action.type) {
    case patientConstants.GET_BY_UHID_REQUEST:
      return { ...state, loading: true };
    case patientConstants.GET_BY_UHID_SUCCESS:
      return {
        ...state,
        partner_patient: action.data.patients,
        pagedInfo: action.data.pagedInfo,
        loading: false,
      };
    case patientConstants.GET_BY_UHID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.SELECT_PATIENT_REQUEST:
      return { ...state, loading: true };
    case patientConstants.SELECT_PATIENT_SUCCESS:
      return {
        ...state,
        selected_patient: action.patient,
        partner_patient: null,
        loading: false,
      };

    case patientConstants.SELECT_PATIENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.SELECT_PATIENT:
      return {
        ...state,
        selected_patient: action.patient,
        partner_patient: null,
        loading: false,
      };

    case patientConstants.GET_ALL_PATIENT_REQUEST:
      return { ...state, loading: true };
    case patientConstants.GET_ALL_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.data.patients,
        pagedInfo: action.data.pagedInfo,
        loading: false,
      };
    case patientConstants.GET_ALL_PATIENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.SEARCH_PATIENT_REQUEST:
      return { ...state, loading: true };
    case patientConstants.SEARCH_PATIENT_SUCCESS:
      return {
        ...state,
        dateWisePatients: action.data.patient,
        patients: action.data.patients,
        pagedInfo: action.data.pagedInfo,
        loading: false,
      };
    case patientConstants.SEARCH_PATIENTS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.GET_BY_DATE_PATIENTS_REQUEST:
      return { ...state, loading: true };
    case patientConstants.GET_BY_DATE_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.data.patients,
        pagedInfo: action.data.pagedInfo,
        dateWisePatients: action.data.patient,
        loading: false,
      };
    case patientConstants.GET_BY_DATE_PATIENTS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.ADD_PATIENT_REQUEST:
      return { ...state, loading: true };
    case patientConstants.ADD_PATIENT_SUCCESS:
      return {
        patients: [...state.patients, action.patient],
        selected_patient: action.patient,
        loading: false,
      };
    case patientConstants.ADD_PATIENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case patientConstants.DELETE_PATIENT_REQUEST:
      return { ...state, loading: true };

    case patientConstants.DELETE_PATIENT_SUCCESS:
      let deletedpatient = action.patient;
      let cindex = state.patients.findIndex(
        (ob) => ob._id === deletedpatient.id
      );
      state.patients.splice(cindex, 1);
      return { ...state, patients: [...state.patients], loading: false };
    case patientConstants.DELETE_PATIENT_FAILURE:
      return { ...state, error: action.error, loading: false };
    case patientConstants.SEARCH_PATIENTS_REQUEST:
      return { ...state, loading: true };
    case patientConstants.SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.data.patients,
        pagedInfo: action.data.pagedInfo,
        loading: false,
      };
    case patientConstants.SEARCH_PATIENTS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case patientConstants.PATIENT_CLEAR:
      return {};

    default:
      return state;
  }
}
