import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const initialAssesmentMasterService = {      
    getAll,
    add,
    addIVF,
    getIVF,
    addInitialForm,
    getFormData,
    updateInitialForm,
    _delete,
    deleteIVF,
    getPrintTemplate,
    deleteInitialForm,
    getLatestAssesmentByUHID,
    getLaboratoryData,
    getRadiologyData,
    getAllByDateRange

   };
   
   function getAllByDateRange(params) {
 
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
   
    return fetch(`${apiUrl}/initialassesmentForm/getAllByDateRange`, requestOptions).then(helperService.handleResponse);
}


   function getPrintTemplate(params) {
  
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)       
    }; 
    return fetch(`${apiUrl}/initialassesment/gettemplate`, requestOptions).then(helperService.handleResponse);
}


function getLaboratoryData(params) {
  
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)       
    }; 
    return fetch(`${apiUrl}/laboratorytestlist/getbyUHID`, requestOptions).then(helperService.handleResponse);
}


function getRadiologyData(params) {
  
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)       
    }; 
    return fetch(`${apiUrl}/radiologytestlist/getbyUHID`, requestOptions).then(helperService.handleResponse);
}


function  getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}        
    };
    return fetch(`${apiUrl}/initialassesment`, requestOptions).then(helperService.handleResponse);
}

function  getIVF() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}        
    };
    return fetch(`${apiUrl}/initialassesment/getivf`, requestOptions).then(helperService.handleResponse);
}

function addIVF(params){
   const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
    return fetch(`${apiUrl}/initialassesment/addivf`, requestOptions).then(helperService.handleResponse);
}

function add(params) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };

    return fetch(`${apiUrl}/initialassesment`, requestOptions).then(helperService.handleResponse);
}

function addInitialForm(params) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
  
    return fetch(`${apiUrl}/initialassesmentForm`, requestOptions).then(helperService.handleResponse);
}


function getLatestAssesmentByUHID(params) {
  
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };

    return fetch(`${apiUrl}/initialassesmentForm/getLatestAssesmentByUHID`, requestOptions).then(helperService.handleResponse);
}



function updateInitialForm(params) {
 
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
   
    return fetch(`${apiUrl}/initialassesmentForm/update`, requestOptions).then(helperService.handleResponse);
}

function deleteInitialForm(params) {
  
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
   
    return fetch(`${apiUrl}/initialassesmentForm/delete`, requestOptions).then(helperService.handleResponse);
}


function  getFormData() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}        
    };
    return fetch(`${apiUrl}/initialassesmentForm`, requestOptions).then(helperService.handleResponse);
}

function _delete(params) {
 
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
 
    return fetch(`${apiUrl}/initialassesment/delete`, requestOptions).then(helperService.handleResponse);
}

function deleteIVF(params) {
 
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(params)
    };
  
    return fetch(`${apiUrl}/initialassesment/deleteivf`, requestOptions).then(helperService.handleResponse);
}

