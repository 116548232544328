import { newMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { educationService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const educationActions = {
  getAll,
  add,
  update,
  _delete,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    educationService.getAll().then(
      (data) => {
        if (data) {
          console.log(data);
          dispatch(success(data.value));
          dispatch(alertActions.success("education master loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.GET_ALL_EDUCATION_REQUEST };
  }
  function success(education) {
    return { type: newMasterConstants.GET_ALL_EDUCATION_SUCCESS, education };
  }
  function failure(error) {
    return { type: newMasterConstants.GET_ALL_EDUCATION_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    educationService.add(payload).then(
      (result) => {
        if (result) {
          dispatch(success(result.value));
          toast("Saved Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toast.error("Failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "errorr",
          });
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
      }
    );
  };

  function request() {
    return { type: newMasterConstants.ADD_EDUCATION_REQUEST };
  }
  function success(education) {
    return { type: newMasterConstants.ADD_EDUCATION_SUCCESS, education };
  }
  function failure(error) {
    return { type: newMasterConstants.ADD_EDUCATION_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    educationService.update(payload).then(
      (result) => {
        if (result) {
          dispatch(success(result.value));
          toast("Updated Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toast.error("Failed to Update", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Failed to Update", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
      }
    );
  };

  function request() {
    return { type: newMasterConstants.UPDATE_EDUCATION_REQUEST };
  }
  function success(education) {
    return { type: newMasterConstants.UPDATE_EDUCATION_SUCCESS, education };
  }
  function failure(error) {
    return { type: newMasterConstants.UPDATE_EDUCATION_FAILURE, error };
  }
}
function _delete(payload) {
  return (dispatch) => {
    dispatch(request());
    educationService._delete(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result));
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.DELETE_EDUCATION_REQUEST };
  }
  function success(education) {
    return { type: newMasterConstants.DELETE_EDUCATION_SUCCESS, education };
  }
  function failure(error) {
    return { type: newMasterConstants.DELETE_EDUCATION_FAILURE, error };
  }
}
