import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {Icons} from "../../helpers/IconHelper";
import { apiUrl } from "../../constants";
import { helperService } from "../../services/helper.service";

class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      result: "",
      showQRModal: false,
    };
    this.textInput = React.createRef();
    // this.toggle = this.toggle.bind(this);
  }

  start() {
    this.textInput.current.focus();
    this.textInput.current.value = "";
    this.toggleQRModal();
  }

  // Init a timeout variable to be used below
  timeout = null;

  onKeyUp(e) {
    e.persist();
    // Make a new timeout set to go off in 1000ms (1 second)
    this.timeout = setTimeout(() => {
      let data = e.target.value;
      const options = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: helperService.getHeaderData(),
        },
        body: JSON.stringify({ data: data }),
      };
      fetch(`${apiUrl}/scanqrcode`, options)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          if (json.success)
            if (this.props.onScanCompleted) {
              this.props.onScanCompleted(JSON.parse(json.data));
            }
        })
        .catch((err) => alert(JSON.stringify(err)));
      //  alert(qrData)
    }, 1000);
    // this.toggleQRModal();
  }

  toggleQRModal(val) {
    this.setState({ showQRModal: val });
  }

  render() {
    return (
      <Fragment>
        <div style={{ cursor: "pointer" }}>
          <div
            onClick={() => {
              this.toggleQRModal(true);
            }}
            style={{ height: 60, width: 60 }}
          >
            <img
              src={Icons.ScanQR}
              style={{ width: "100%", backgroundColor: "transparent" }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { state };
}

const connectedQRScanner = connect(mapStateToProps)(QRScanner);
export default connectedQRScanner;
