export const userMasterConstants = {
  GET_ALL_REQUEST: "GET_ALL_USER_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_USER_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_USER_FAILURE",

  SEARCH_ALL_REQUEST: "SEARCH_ALL_REQUEST",
  SEARCH_ALL_SUCCESS: "SEARCH_ALL_SUCCESS",
  SEARCH_ALL_FAILURE: "SEARCH_ALL_FAILURE",

  ADD_REQUEST: "ADD_USER_REQUEST",
  ADD_SUCCESS: "ADD_USER_SUCCESS",
  ADD_FAILURE: "ADD_USER_FAILURE",

  DELETE_REQUEST: "DELETE_USER_REQUEST",
  DELETE_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_FAILURE: "DELETE_USER_FAILURE",

  UPDATE_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_FAILURE: "UPDATE_USER_FAILURE",

  SELECT_EMPLOYEE_REQUEST: "SELECT_EMPLOYEE_REQUEST",
  SELECT_EMPLOYEE_SUCCESS: "SELECT_EMPLOYEE_SUCCESS",
  SELECT_EMPLOYEE_FAILURE: "SELECT_EMPLOYEE_FAILURE",

  CLEAR: "CLEAR",
};
