
    export const clinicConstants={

       ADD_CLINIC_REQUEST:'ADD_CLINIC_REQUEST',
       ADD_CLINIC_SUCCESS:'ADD_CLINIC_SUCCESS',
       ADD_CLINIC_FAILURE:'ADD_CLINIC_FAILURE' ,

       GET_ALL_CLINIC_REQUEST:'GET_ALL_CLINIC_REQUEST',
       GET_ALL_CLINIC_SUCCESS:'GET_ALL_CLINIC_SUCCESS',
       GET_ALL_CLINIC_FAILURE:'GET_ALL_CLINIC_FAILURE' ,

       UPDATE_CLINIC_REQUEST:'UPDATE_CLINIC_REQUEST',
       UPDATE_CLINIC_SUCCESS:'UPDATE_CLINIC_SUCCESS',
       UPDATE_CLINIC_FAILURE:'UPDATE_CLINIC_FAILURE' ,

       DELETE_CLINIC_REQUEST:'DELETE_CLINIC_REQUEST',
       DELETE_CLINIC_SUCCESS:'DELETE_CLINIC_SUCCESS',
       DELETE_CLINIC_FAILURE:'DELETE_CLINIC_FAILURE' ,

       SELECT_CLINIC_REQUEST:'SELECT_CLINIC_REQUEST',
       SELECT_CLINIC_SUCCESS:'SELECT_CLINIC_SUCCESS',
       SELECT_CLINIC_FAILURE:'SELECT_CLINIC_FAILURE' ,

       CLINIC_CLEAR:'CLINIC_CLEAR'

    
    }