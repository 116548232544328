import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import classnames from "classnames";
import Packages from "../Packages";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  TabContent,
  TabPane,
  CardHeader,
  CardFooter,
  ButtonGroup,
} from "reactstrap";
import AmountSetting from "../amount.setting";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  BarChart,
  Bar,
  ComposedChart,
  CartesianGrid,
} from "recharts";

import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  sweetAlertActions,
  registrationMasterActions,
  serviceMasterActions,
} from "../../../actions";
import { departmentService } from "../../../services/department.service";
import { CostCenterService } from "../../../services/costcenter.service";
import { serviceMasterService } from "../../../services";
import UploadModal from "../Packages/Modals/UploadModal";

class Services extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    if (props.location) {
      if (props.location.state) {
        console.log("consoling from service", props.location.state);
      }
    }
    this.state = {
      activeTab: "1",
      showMore: true,
      transform: true,
      showInkBar: true,
      selectedState: [],
      type: "services",
      selectedTabKey: 0,
      transformWidth: 400,
      SERVICE_AMT: 0,
      perChange: 0,
      isToggleOn: true,
      Revenue_Perc_Hosp: 100,
      Revenue_Perc_OutS: 0,
      Revenue_Perc_Lab: 0,
      totalAmount: 0,
      OLD_AMOUNT: props.responseObject ? props.responseObject.PACKAGE_AMT : 0,
      PROCEDURE: "None",
      IN_HOUSE: true,
      CONS_NAME_BILLING: false,
      CONS_NAME_BILLING_IP: false,
      DISCOUNT_IP_APP: true,
      DISCOUNT_APP: true,
      EDIT_SERVICE_AMT: true,
      EDIT_SERVICE_AMT_IP: true,
      REF_AMT_APP: false,
      REF_AMT_IP_APP: false,
      OP_QTY_EDIT: false,
      IP_QTY_EDIT: false,
      AUTO_SHOW_OP: false,
      AUTO_SHOW_IP: false,
      TAX_APPLICABLE_IN_IP: false,
      TAX_APPLICABLE_IN_OP: false,
      isReportingTypeForIP: true,
      isReportingTypeForOP: true,
      reporting_type_ip: "tabular_format",
      reporting_type_op: "tabular_format",
      isActive: true,
      D_CODE: "",
    };
    this.handleRoundOffChange = this.handleRoundOffChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleAddConsultantNameinIPBilling = this.handleAddConsultantNameinIPBilling.bind(
      this
    );
    this.handleDiscountApplicableForIP = this.handleDiscountApplicableForIP.bind(
      this
    );
    this.handleDoNotEditServiceAmountForIP = this.handleDoNotEditServiceAmountForIP.bind(
      this
    );
    this.handleReferralAmountApplicableForIP = this.handleReferralAmountApplicableForIP.bind(
      this
    );
    this.handleGenerateTokenForIP = this.handleGenerateTokenForIP.bind(this);
    this.handleIPQtyEditable = this.handleIPQtyEditable.bind(this);

    this.handleAddConsultantNameinOPBilling = this.handleAddConsultantNameinOPBilling.bind(
      this
    );
    this.handleDiscountApplicableForOP = this.handleDiscountApplicableForOP.bind(
      this
    );
    this.handleDoNotEditServiceAmountForOP = this.handleDoNotEditServiceAmountForOP.bind(
      this
    );
    this.handleReferralAmountApplicableForOP = this.handleReferralAmountApplicableForOP.bind(
      this
    );
    this.handleGenerateTokenForOP = this.handleGenerateTokenForOP.bind(this);
    this.handleOPQtyEditable = this.handleOPQtyEditable.bind(this);

    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleDurationInterval = this.handleDurationInterval.bind(this);
    this.addSlots = this.addSlots.bind(this);
    this.onRoundOffChange = this.onRoundOffChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.addService = this.addService.bind(this);
    this.handleRevenuueShare = this.handleRevenuueShare.bind(this);
    this.handleInHouseChange = this.handleInHouseChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.NavigateTopackagePage = this.NavigateTopackagePage.bind(this);
    this.EditService = this.EditService.bind(this);
    this.handleHospitalRevenue = this.handleHospitalRevenue.bind(this);
    this.handleOutSorceRevenue = this.handleOutSorceRevenue.bind(this);
    this.handleHospitalLabRevenue = this.handleHospitalLabRevenue.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onServiceNameChange = this.onServiceNameChange.bind(this);
    this.onServiceAmountChange = this.onServiceAmountChange.bind(this);
    this.onBaseAmountSetupChange = this.onBaseAmountSetupChange.bind(this);
    this.onBaseAmountSetupPercentageChange = this.onBaseAmountSetupPercentageChange.bind(
      this
    );
    this.onServiceAmountSetupChange = this.onServiceAmountSetupChange.bind(
      this
    );
    this.onServiceAmountSetupValueChange = this.onServiceAmountSetupValueChange.bind(
      this
    );
    this.handleAutoShowinOPBilling = this.handleAutoShowinOPBilling.bind(this);
    this.handleAutoShowinIPBilling = this.handleAutoShowinIPBilling.bind(this);
    this.handleTaxApplicableOPBilling = this.handleTaxApplicableOPBilling.bind(
      this
    );
    this.handleTaxApplicableIPBilling = this.handleTaxApplicableIPBilling.bind(
      this
    );
    this.handleTaxAmountIP = this.handleTaxAmountIP.bind(this);
    this.handleTaxAmountOP = this.handleTaxAmountOP.bind(this);
    this.onCCMChange = this.onCCMChange.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.onTotalAmountChange = this.onTotalAmountChange.bind(this);
    this.removeService = this.removeService.bind(this);
    this.onHospitalRevenueChange = this.onHospitalRevenueChange.bind(this);
    this.onHospitalLabRevenueChange = this.onHospitalLabRevenueChange.bind(
      this
    );
    this.onRevenueOutChange = this.onRevenueOutChange.bind(this);
    this.handleDischargeSummaryOPChange = this.handleDischargeSummaryOPChange.bind(
      this
    );
    this.handleReportingTypeForIP = this.handleReportingTypeForIP.bind(this);
    this.handleReportingTypeForOP = this.handleReportingTypeForOP.bind(this);
    this.onReportingChange = this.onReportingChange.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
  }
  handleDischargeSummaryOPChange(e) {
    this.setState({
      dischargeSummeryOP: !this.state.dischargeSummeryOP,
    });
  }

  onTotalAmountChange(e) {
    if (e.target.value) {
      let amount = parseFloat(e.target.value);
      let perChange = (amount / this.state.OLD_AMOUNT) * 100;
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      let serviceList = [...this.state.selectedState];
      let newServiceList = [];
      serviceList.forEach((data) => {
        data.newamount =
          this.state.roundoffType === "floor"
            ? Math.floor((data.SERVICE_AMT * perChange) / 100)
            : Math.ceil((data.SERVICE_AMT * perChange) / 100);
        newServiceList.push(data);
      });
      this.setState({
        totalAmount: amount,
        perChange:
          this.state.roundoffType === "floor"
            ? Math.floor(perChange)
            : Math.ceil(perChange),
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
        selectedState: newServiceList,
      });
    } else {
      let serviceList = [...this.state.selectedState];
      let newServiceList = [];
      serviceList.forEach((data) => {
        data.newamount = 0;
        newServiceList.push(data);
      });
      this.setState({
        totalAmount: 0,
        perChange: 0,
        REVENUE_SHARE_HOSP: 0,
        REVENUE_SHARE_OUT: 0,
        Revenue_Share_Lab: 0,
        selectedState: newServiceList,
      });
    }
  }

  handleServiceChange(services, totalAmount) {
    let selectedState = [...this.state.selectedState];
    services.forEach((element) => {
      selectedState.push(element);
    });
    //selectedState.push(services);
    //selectedState.flat();
    if (!this.state.totalAmount) {
      let Amount = this.state.totalAmount + totalAmount;
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      let newServiceList = [];
      selectedState.forEach((data) => {
        data.newamount = data.SERVICE_AMT;
        newServiceList.push(data);
      });
      this.setState({
        selectedState: newServiceList,
        totalAmount: Amount,
        OLD_AMOUNT: Amount,
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
    } else {
      let updatedAmount = this.state.OLD_AMOUNT + totalAmount;
      let perChange = (this.state.totalAmount / updatedAmount) * 100;
      let newServiceList = [];
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      selectedState.forEach((data) => {
        data.newamount =
          this.state.roundoffType === "floor"
            ? Math.floor(
                data.type === "services"
                  ? (data.SERVICE_AMT * perChange) / 100
                  : (data.PACKAGE_AMT * perChange) / 100
              )
            : Math.ceil(
                data.type === "services"
                  ? (data.SERVICE_AMT * perChange) / 100
                  : (data.PACKAGE_AMT * perChange) / 100
              );
        newServiceList.push(data);
      });
      this.setState({
        OLD_AMOUNT: updatedAmount,
        perChange:
          this.state.roundoffType === "floor"
            ? Math.floor(perChange)
            : Math.floor(perChange),
        selectedState: newServiceList,
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
    }
  }

  onCCMChange(e) {
    if (e.target.value === "EXP" || e.target.value === "Shared") {
      this.setState({
        OUT_SOURCED: true,
      });
    }

    this.setState({
      CCM_CODE: e.target.value,
    });
  }

  onHospitalRevenueChange(e) {
    if (e.target.value) {
      let amount = parseInt(e.target.value);
      let sum =
        this.state.REVENUE_SHARE_OUT + this.state.Revenue_Share_Lab + amount;
      if (sum > this.state.SERVICE_AMT) {
        amount =
          this.state.SERVICE_AMT -
          (this.state.REVENUE_SHARE_OUT + this.state.Revenue_Share_Lab);
      }
      let hospitalPercentage = (amount / this.state.SERVICE_AMT) * 100;
      this.setState({
        Revenue_Perc_Hosp: hospitalPercentage,
        REVENUE_SHARE_HOSP: amount,
      });
    } else {
      this.setState({
        Revenue_Perc_Hosp: 0,
        REVENUE_SHARE_HOSP: e.target.value,
      });
    }
  }

  onHospitalLabRevenueChange(e) {
    if (e.target.value) {
      let amount = parseInt(e.target.value);
      let sum =
        this.state.REVENUE_SHARE_OUT + this.state.REVENUE_SHARE_HOSP + amount;
      if (sum > this.state.SERVICE_AMT) {
        amount =
          this.state.SERVICE_AMT -
          (this.state.REVENUE_SHARE_OUT + this.state.REVENUE_SHARE_HOSP);
      }
      let labPercentage = (amount / this.state.SERVICE_AMT) * 100;
      this.setState({
        Revenue_Share_Lab: amount,
        Revenue_Perc_Lab: labPercentage,
      });
    } else {
      this.setState({
        Revenue_Share_Lab: e.target.value,
        Revenue_Perc_Lab: 0,
      });
    }
  }

  onRevenueOutChange(e) {
    if (e.target.value) {
      let amount = parseInt(e.target.value);
      let sum =
        this.state.REVENUE_SHARE_HOSP +
        this.state.Revenue_Share_Lab +
        parseInt(e.target.value);
      if (sum > this.state.SERVICE_AMT) {
        amount =
          this.state.SERVICE_AMT -
          (this.state.REVENUE_SHARE_HOSP + this.state.Revenue_Share_Lab);
      }
      let outPercentage = (amount / this.state.SERVICE_AMT) * 100;
      this.setState({
        Revenue_Perc_OutS: outPercentage,
        REVENUE_SHARE_OUT: amount,
      });
    } else {
      this.setState({
        Revenue_Perc_OutS: 0,
        REVENUE_SHARE_OUT: e.target.value,
      });
    }
  }

  handleTaxAmountIP(e) {
    this.setState({
      TAX_AMOUNT_IP: e.target.value,
    });
  }

  handleTaxAmountOP(e) {
    this.setState({
      TAX_AMOUNT_OP: e.target.value,
    });
  }

  handleTaxApplicableOPBilling() {
    this.setState({
      TAX_APPLICABLE_IN_OP: !this.state.TAX_APPLICABLE_IN_OP,
    });
  }

  handleTaxApplicableIPBilling() {
    this.setState({
      TAX_APPLICABLE_IN_IP: !this.state.TAX_APPLICABLE_IN_IP,
    });
  }

  handleAutoShowinOPBilling(e) {
    this.setState({
      AUTO_SHOW_OP: !this.state.AUTO_SHOW_OP,
    });
  }

  handleAutoShowinIPBilling(e) {
    this.setState({
      AUTO_SHOW_IP: !this.state.AUTO_SHOW_IP,
    });
  }

  onServiceAmountSetupValueChange(e) {
    this.setState({
      serviceAmountSettingValue: e.target.value,
    });
  }

  onServiceAmountSetupChange(e) {
    this.setState({
      serviceAmountSetting: e.target.value,
    });
  }

  onBaseAmountSetupPercentageChange(e) {
    this.setState({
      baseAmountSettingValue: e.target.value,
    });
  }

  removeService(index) {
    let selectedState = [...this.state.selectedState];
    let totalAmount = this.state.totalAmount;
    if (index > -1) {
      if (selectedState[index].isActive) {
        if (selectedState[index].newamount) {
          totalAmount = totalAmount - selectedState[index].newamount;
        }
        let old_amount =
          this.state.OLD_AMOUNT - selectedState[index].SERVICE_AMT;

        let REVENUE_SHARE_HOSP =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
              );
        let REVENUE_SHARE_OUT =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
              );
        let Revenue_Share_Lab =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
              );
        selectedState.splice(index, 1);

        this.setState({
          selectedState: selectedState,
          OLD_AMOUNT: old_amount,
          totalAmount: totalAmount,

          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      } else {
        let selectedState = [...this.state.selectedState];
        selectedState.splice(index, 1);
        this.setState({
          selectedState: selectedState,
        });
      }
    }
  }

  onBaseAmountSetupChange(e) {
    this.setState({
      baseAmountSetting: e.target.value,
    });
  }

  clearForm() {
    this.setState({
      D_CODE: "",
      SD_CODE: "",
      CCM_CODE: "",
      SERVICE_NAME: "",
      USERDEFINED_NAME: "",
      SERVICE_AMT: 0,
      isActive: true,
      REVENUE_SHARE_HOSP: false,
      REVENUE_SHARE_OUT: false,
      SEQUENCE: "",
      DISCOUNT_APP: false,
      EDIT_SERVICE_AMT: false,
      REF_AMT_APP: false,
      GEN_TOKEN: false,
      DISCOUNT_IP_APP: false,
      EDIT_SERVICE_AMT_IP: false,
      REF_AMT_IP_APP: false,
      CONS_NAME_BILLING_IP: false,
      OP_QTY_EDIT: false,
      IP_QTY_EDIT: false,
      GEN_TOKEN_IP: false,
      Revenue_Perc_Hosp: 0,
      Revenue_Perc_OutS: 0,
      Revenue_Perc_Lab: 0,
      Revenue_Share_Lab: 0,
      roundoffType: "floor",
    });
  }
  onServiceAmountChange(e) {
    let amount = parseFloat(e.target.value);
    if (amount) {
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Hosp) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Hosp) / 100);
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_OutS) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_OutS) / 100);
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Lab) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Lab) / 100);
      this.setState({
        SERVICE_AMT: amount,
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
    } else {
      this.setState({
        SERVICE_AMT: e.target.value,
        REVENUE_SHARE_HOSP: 0,
        REVENUE_SHARE_OUT: 0,
        Revenue_Share_Lab: 0,
      });
    }
  }
  handleRoundOffChange() {
    if (this.state.roundoffType === "floor") {
      this.setState({
        roundoffType: "ceil",
      });
    } else {
      this.setState({
        roundoffType: "floor",
      });
    }
  }
  handleHospitalRevenue(e) {
    //let total = Revenue_Perc_Hosp Revenue_Perc_OutS Revenue_Perc_Lab
    if (e.target.value) {
      let Revenue_Perc_Hosp = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_Lab +
        this.state.Revenue_Perc_OutS +
        Revenue_Perc_Hosp;
      if (total > 100) {
        Revenue_Perc_Hosp =
          100 - (this.state.Revenue_Perc_Lab + this.state.Revenue_Perc_OutS);
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_Hosp * this.state.SERVICE_AMT) / 100)
            : Math.ceil((Revenue_Perc_Hosp * this.state.SERVICE_AMT) / 100);
        this.setState({
          Revenue_Perc_Hosp: Revenue_Perc_Hosp,
          REVENUE_SHARE_HOSP: amount,
        });
      } else {
        let amount = (Revenue_Perc_Hosp * this.state.SERVICE_AMT) / 100;
        this.setState({
          Revenue_Perc_Hosp: Revenue_Perc_Hosp,
          REVENUE_SHARE_HOSP: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_Hosp: 0,
        REVENUE_SHARE_HOSP: 0,
      });
    }
  }
  handleHospitalLabRevenue(e) {
    if (e.target.value) {
      let Revenue_Perc_Lab = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_OutS +
        this.state.Revenue_Perc_Hosp +
        Revenue_Perc_Lab;
      if (total > 100) {
        Revenue_Perc_Lab =
          100 - (this.state.Revenue_Perc_OutS + this.state.Revenue_Perc_Hosp);
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_Lab * this.state.SERVICE_AMT) / 100)
            : Math.ceil((Revenue_Perc_Lab * this.state.SERVICE_AMT) / 100);
        this.setState({
          Revenue_Perc_Lab: Revenue_Perc_Lab,
          Revenue_Share_Lab: amount,
        });
      } else {
        let amount = (Revenue_Perc_Lab * this.state.SERVICE_AMT) / 100;
        this.setState({
          Revenue_Perc_Lab: Revenue_Perc_Lab,
          Revenue_Share_Lab: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_Lab: 0,
        Revenue_Share_Lab: 0,
      });
    }
  }
  handleOutSorceRevenue(e) {
    if (e.target.value) {
      let Revenue_Perc_OutS = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_Lab +
        this.state.Revenue_Perc_Hosp +
        Revenue_Perc_OutS;
      if (total > 100) {
        Revenue_Perc_OutS =
          100 - (this.state.Revenue_Perc_Lab + this.state.Revenue_Perc_Hosp);
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_OutS * this.state.SERVICE_AMT) / 100)
            : Math.ceil((Revenue_Perc_OutS * this.state.SERVICE_AMT) / 100);
        this.setState({
          Revenue_Perc_OutS: Revenue_Perc_OutS,
          REVENUE_SHARE_OUT: amount,
        });
      } else {
        let amount = (Revenue_Perc_OutS * this.state.SERVICE_AMT) / 100;
        this.setState({
          Revenue_Perc_OutS: Revenue_Perc_OutS,
          REVENUE_SHARE_OUT: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_OutS: 0,
        REVENUE_SHARE_OUT: 0,
      });
    }
  }

  handleAddConsultantNameinIPBilling() {
    this.setState({ CONS_NAME_BILLING_IP: !this.state.CONS_NAME_BILLING_IP });
  }
  handleDiscountApplicableForIP() {
    this.setState({ DISCOUNT_IP_APP: !this.state.DISCOUNT_IP_APP });
  }
  handleDoNotEditServiceAmountForIP() {
    this.setState({ EDIT_SERVICE_AMT_IP: !this.state.EDIT_SERVICE_AMT_IP });
  }
  handleReferralAmountApplicableForIP() {
    this.setState({ REF_AMT_IP_APP: !this.state.REF_AMT_IP_APP });
  }
  handleGenerateTokenForIP() {
    this.setState({ GEN_TOKEN_IP: !this.state.GEN_TOKEN_IP });
  }
  handleIPQtyEditable() {
    this.setState({ IP_QTY_EDIT: !this.state.IP_QTY_EDIT });
  }

  handleAddConsultantNameinOPBilling() {
    this.setState({
      CONS_NAME_BILLING: !this.state.CONS_NAME_BILLING,
    });
  }
  handleDiscountApplicableForOP() {
    this.setState({ DISCOUNT_APP: !this.state.DISCOUNT_APP });
  }
  handleDoNotEditServiceAmountForOP() {
    this.setState({ EDIT_SERVICE_AMT: !this.state.EDIT_SERVICE_AMT });
  }
  handleReferralAmountApplicableForOP() {
    this.setState({ REF_AMT_APP: !this.state.REF_AMT_APP });
  }
  handleGenerateTokenForOP() {
    this.setState({
      GEN_TOKEN: !this.state.GEN_TOKEN,
    });
  }
  handleOPQtyEditable() {
    this.setState({ OP_QTY_EDIT: !this.state.OP_QTY_EDIT });
  }

  handleClick() {
    this.setState(function(prevState) {
      return { isToggleOn: !prevState.isToggleOn };
    });
  }

  handleDurationChange(e) {
    e.preventDefault();
    //console.log(e.target.value);
    //console.log(this.state)
    this.setState({ slotduration: e.target.value });
  }
  handleDurationInterval(e) {
    e.preventDefault();
    //console.log(e.target.value);
    //console.log(this.state)
    this.setState({ slotinterval: e.target.value });
  }

  addSlots = (e) => {
    e.preventDefault();
    let slots = this.state.slots.concat([""]);
    this.setState({
      slots,
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    let deptCode = "";

    departmentService.getAll().then((data) => {
      deptCode =
        data.departments &&
        data.departments.find((dep) => dep.name.toLowerCase().includes("lab"))
          ?.code;

      this.setState({
        department: data.departments,
        deptCode: deptCode,
      });
    });

    CostCenterService.getAll().then((data) => {
      this.setState({
        costcenter: data.costcenters,
      });
    });
    console.log("consoling from component did mount", this.props.formData);
    if (this.props.type) {
      this.setState({
        type: this.props.type,
      });
    }
    if (this.props.formData) {
      if (this.props.formData.type === "services") {
        let data = this.props.formData.formData;
        this.setState({
          comeFromEditFlow: true,
          _id: data._id,
          type: this.props.formData.type,
          D_CODE: data.D_CODE,
          SD_CODE: data.SD_CODE,
          CCM_CODE: data.CCM_CODE,
          SERVICE_NAME: data.SERVICE_NAME,
          USERDEFINED_NAME: data.USERDEFINED_NAME,
          SERVICE_AMT: data.SERVICE_AMT,
          isActive: data.isActive,
          REVENUE_SHARE_HOSP: data.REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: data.REVENUE_SHARE_OUT,
          SEQUENCE: data.SEQUENCE,
          DISCOUNT_APP: data.DISCOUNT_APP,
          EDIT_SERVICE_AMT: data.EDIT_SERVICE_AMT,
          REF_AMT_APP: data.REF_AMT_APP,
          GEN_TOKEN: data.GEN_TOKEN,
          DISCOUNT_IP_APP: data.DISCOUNT_IP_APP,
          EDIT_SERVICE_AMT_IP: data.EDIT_SERVICE_AMT_IP,
          REF_AMT_IP_APP: data.REF_AMT_IP_APP,
          CONS_NAME_BILLING_IP: data.CONS_NAME_BILLING_IP,
          OP_QTY_EDIT: data.OP_QTY_EDIT,
          IP_QTY_EDIT: data.IP_QTY_EDIT,
          GEN_TOKEN_IP: data.GEN_TOKEN_IP,
          Revenue_Perc_Hosp: data.Revenue_Perc_Hosp
            ? data.Revenue_Perc_Hosp
            : 0,
          Revenue_Perc_OutS: data.Revenue_Perc_OutS
            ? data.Revenue_Perc_OutS
            : 0,
          Revenue_Perc_Lab: data.Revenue_Perc_Lab ? data.Revenue_Perc_Lab : 0,
          Revenue_Share_Lab: data.Revenue_Share_Lab,
          REVENUE_SHARE_OUT: data.REVENUE_SHARE_OUT,
          REVENUE_SHARE_HOSP: data.REVENUE_SHARE_HOSP,
          OUT_SOURCED: data.OUT_SOURCED,
          IN_HOUSE: data.IN_HOUSE,
          OUT_SOURCED_TP: data.OUT_SOURCED_TP,
          OUT_SOURCED_DIA: data.OUT_SOURCED_DIA,
          PROCEDURE: data.PROCEDURE,
          CONS_NAME_BILLING: data.CONS_NAME_BILLING,
          roundoffType: data.roundoffType,
          baseAmountSetting: data.baseAmountSetting,
          baseAmountSettingValue: data.baseAmountSettingValue,
          serviceAmountSetting: data.serviceAmountSetting,
          serviceAmountSettingValue: data.serviceAmountSettingValue,
          AUTO_SHOW_OP: data.AUTO_SHOW_OP,
          AUTO_SHOW_IP: data.AUTO_SHOW_IP,
          TAX_APPLICABLE_IN_OP: data.TAX_APPLICABLE_IN_OP,
          TAX_APPLICABLE_IN_IP: data.TAX_APPLICABLE_IN_IP,
          TAX_AMOUNT_OP: data.TAX_AMOUNT_OP,
          TAX_AMOUNT_IP: data.TAX_AMOUNT_IP,
          isReportingTypeForIP: data.isReportingTypeForIP,
          reporting_type_ip: data.reporting_type_ip,
          isReportingTypeForOP: data.isReportingTypeForOP,
          reporting_type_op: data.reporting_type_op,
        });
        console.log(data.D_CODE, "D_CODE");
        if (data.D_CODE === deptCode) {
          this.setState({
            // isReportingTypeForIP: data.isReportingTypeForIP,
            // reporting_type_ip: data.reporting_type_ip,
            // isReportingTypeForOP: data.isReportingTypeForOP,
            // reporting_type_op: data.reporting_type_op,
          });
        }
      } else if (this.props.formData.type === "packages") {
        let data = this.props.formData.formData;
        serviceMasterService
          .getPackageMappingDetailsById(data.CODE)
          .then((data) => {
            console.log(data);
            if (data.mappingDetails) {
              let perChange = Math.round(
                (parseFloat(data.mappingDetails.PACKAGE_SERVICE_AMT) /
                  parseFloat(data.packageDetails.PACKAGE_AMT)) *
                  100
              );
              this.setState({
                PKM_CODE: data.mappingDetails.PKM_CODE,
                selectedState: data.mappingDetails.SM_CODE,
                OLD_AMOUNT: parseFloat(data.packageDetails.PACKAGE_AMT),
                totalAmount: parseFloat(
                  data.mappingDetails.PACKAGE_SERVICE_AMT
                ),
                roundoffType: data.mappingDetails.roundoffType,
                baseAmountSetting: data.mappingDetails.baseAmountSetting,
                baseAmountSettingValue:
                  data.mappingDetails.baseAmountSettingValue,
                serviceAmountSetting: data.mappingDetails.serviceAmountSetting,
                serviceAmountSettingValue:
                  data.mappingDetails.serviceAmountSettingValue,
                Revenue_Perc_Hosp: data.packageDetails.Revenue_Perc_Hosp,
                Revenue_Perc_OutS: data.packageDetails.Revenue_Perc_OutS,
                Revenue_Perc_Lab: data.packageDetails.Revenue_Perc_Lab,
                Revenue_Share_Lab: data.packageDetails.Revenue_Share_Lab,
                REVENUE_SHARE_OUT: data.packageDetails.REVENUE_SHARE_OUT,
                REVENUE_SHARE_HOSP: data.packageDetails.REVENUE_SHARE_HOSP,

                perChange: perChange,
              });
            }
          });
        this.setState({
          comeFromEditFlow: true,
          _id: data._id,
          type: this.props.formData.type,
          D_CODE: data.D_CODE,
          SD_CODE: data.SD_CODE,
          CCM_CODE: data.CCM_CODE,
          SEQUENCE: data.SEQUENCE,
          SERVICE_NAME: data.PACKAGE_NAME,
          USERDEFINED_NAME: data.USERDEFINED_NAME,
          SERVICE_AMT: data.PACKAGE_AMT,
          isActive: data.isActive,
          REVENUE_SHARE_HOSP: data.REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: data.REVENUE_SHARE_OUT,
          OUT_SOURCED_TP: data.OUT_SOURCED_TP,
          OUT_SOURCED_DIA: data.OUT_SOURCED_DIA,
          SEQUENCE: data.SEQUENCE,
          DISCOUNT_APP: data.DISCOUNT_APP,
          EDIT_SERVICE_AMT: data.EDIT_SERVICE_AMT,
          REF_AMT_APP: data.REF_AMT_APP,
          GEN_TOKEN: data.GEN_TOKEN,
          DISCOUNT_IP_APP: data.DISCOUNT_IP_APP,
          EDIT_SERVICE_AMT_IP: data.EDIT_SERVICE_AMT_IP,
          REF_AMT_IP_APP: data.REF_AMT_IP_APP,
          CONS_NAME_BILLING_IP: data.CONS_NAME_BILLING_IP,
          OP_QTY_EDIT: data.OP_QTY_EDIT,
          IP_QTY_EDIT: data.IP_QTY_EDIT,
          GEN_TOKEN_IP: data.GEN_TOKEN_IP,
          Revenue_Perc_Hosp: data.Revenue_Perc_Hosp,
          Revenue_Perc_OutS: data.Revenue_Perc_OutS,
          Revenue_Perc_Lab: data.Revenue_Perc_Lab,
          Revenue_Share_Lab: data.Revenue_Share_Lab,
          REVENUE_SHARE_OUT: data.REVENUE_SHARE_OUT,
          REVENUE_SHARE_HOSP: data.REVENUE_SHARE_HOSP,
          OUT_SOURCED: data.OUT_SOURCED,
          CONS_NAME_BILLING: data.CONS_NAME_BILLING,
          IN_HOUSE: data.IN_HOUSE,
          PROCEDURE: data.PROCEDURE,
          AUTO_SHOW_OP: data.AUTO_SHOW_OP,
          AUTO_SHOW_IP: data.AUTO_SHOW_IP,
          TAX_APPLICABLE_IN_OP: data.TAX_APPLICABLE_IN_OP,
          TAX_APPLICABLE_IN_IP: data.TAX_APPLICABLE_IN_IP,
          TAX_AMOUNT_OP: data.TAX_AMOUNT_OP,
          TAX_AMOUNT_IP: data.TAX_AMOUNT_IP,
        });
      } else if (this.props.formData.type === "groups") {
        let data = this.props.formData.formData;
        let payload = {
          CODE: data.CODE,
        };
        serviceMasterService
          .getGroupingMappingData(payload)
          .then((mappingData) => {
            this.setState({
              comeFromEditFlow: true,
              _id: data._id,
              D_CODE: data.D_CODE,
              SD_CODE: data.SD_CODE,
              CCM_CODE: data.CCM_CODE,
              SEQUENCE: data.SEQUENCE,
              SERVICE_NAME: data.GROUP_NAME,
              USERDEFINED_NAME: data.USERDEFINED_NAME,
              SERVICE_AMT: data.GROUP_AMT,
              isActive: data.isActive,
              GM_CODE: mappingData.data ? mappingData.data.GM_CODE : data.CODE,
              OUT_SOURCED: data.OUT_SOURCED,
              IN_HOUSE: data.IN_HOUSE,
              OUT_SOURCED_TP: data.OUT_SOURCED_TP,
              OUT_SOURCED_DIA: data.OUT_SOURCED_DIA,
              PROCEDURE: data.PROCEDURE,
              selectedState: mappingData.data ? mappingData.data.SM_CODE : [],
              OLD_AMOUNT: parseFloat(
                mappingData.data ? mappingData.data.GROUP_OLD_AMT : 0
              ),
              totalAmount: parseFloat(
                mappingData.data ? mappingData.data.GROUP_AMT : 0
              ),
            });
          });
      }
      departmentService
        .getSubDepartmentById(this.props.formData.formData.D_CODE)
        .then((data) => {
          console.log(data.subdepartments);
          this.setState({
            subdepartments: data.subdepartments,
          });
        });
    }
  }

  addService(e) {
    console.log(this.state);
    // const { dispatch } = this.props;
    // dispatch(serviceMasterActions.add(this.state));
    this.props.save(this.state);
    //this.clearForm();
  }

  NavigateTopackagePage(responseObject) {
    this.props.navigation({ name: "Packages", component: <Packages /> });
  }

  handleDepartmentChange(e) {
    //this.props.handleChange(e);

    this.onChange(e);
    this.setState({
      D_CODE: e.target.value,
    });
    if (e.target.value) {
      try {
        let selectedDepartment = this.state.department.find(
          (data) => data.code === e.target.value
        );
        if (selectedDepartment) {
          this.setState({
            CCM_CODE: selectedDepartment.ccm_code,
            Revenue_Perc_Hosp: selectedDepartment.hospital_share
              ? selectedDepartment.hospital_share
              : 0,
            Revenue_Perc_Lab: selectedDepartment.out_source_share
              ? selectedDepartment.out_source_share
              : 0,
            REVENUE_SHARE_HOSP: selectedDepartment.hospital_share
              ? (selectedDepartment.hospital_share * this.state.SERVICE_AMT) /
                100
              : 0,
            Revenue_Share_Lab: selectedDepartment.out_source_share
              ? (selectedDepartment.out_source_share * this.state.SERVICE_AMT) /
                100
              : 0,
          });
        }
      } catch {}
    }
    if (e.target.value) {
      departmentService.getSubDepartmentById(e.target.value).then((data) => {
        console.log(data.subdepartments);
        this.setState({
          subdepartments: data.subdepartments,
        });
      });
    }
  }

  EditService() {
    let payload = {
      ...this.state,
      transactionType: "edit",
    };

    this.props.save(payload);
  }

  onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [e.target.name]: value,
    });
    if (name === "type") {
      this.props.gettypes(value);
    }
  }

  onServiceNameChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [e.target.name]: value,
      USERDEFINED_NAME: value,
    });
  }

  handleCheckBox(e) {
    let checked = e.target.checked;
    let name = e.target.name;
    if (checked) {
      this.setState({
        [e.target.name]: true,
      });
    } else {
      this.setState({
        [e.target.name]: true,
      });
    }
  }

  handleRevenuueShare(e) {
    let value = e.target.value;
    let name = e.target.name;
    let correspondname = e.target.name + "_AMOUNT";
    let correspondvalue = 0;
    if (e.target.value > 100) {
      this.setState({
        [name]: 100,
        [correspondname]: this.state.SERVICE_AMT,
      });
    } else {
      if (this.state.SERVICE_AMT) {
        correspondvalue = (this.state.SERVICE_AMT * value) / 100;
      }
      this.setState({
        [name]: value,
        [correspondname]: correspondvalue,
      });
    }
  }

  onReportingChange(e, index) {
    let selectedState = [...this.state.selectedState];
    selectedState[index].reporting_priority = e.target.value;
    this.setState({
      selectedState: selectedState,
    });
  }

  onNotesChange(e, index) {
    let selectedState = [...this.state.selectedState];
    selectedState[index].notes = e.target.value;
    this.setState({
      selectedState: selectedState,
    });
  }

  onAmountChange(e, index) {
    let selectedState = [...this.state.selectedState];
    if (e.target.value) {
      if (selectedState[index].amountAlreadyDedcted) {
        let totalAmount =
          this.state.totalAmount - parseFloat(selectedState[index].newamount);
        totalAmount = totalAmount + parseFloat(e.target.value);
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;
        selectedState[index].newamount = e.target.value;
        if (this.props.isCameFromEditFlow) {
          this.setState({
            selectedState: selectedState,
            totalAmount: totalAmount,
            REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
            REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
            Revenue_Share_Lab: Revenue_Share_Lab,
          });
        } else {
          this.setState({
            selectedState: selectedState,
            totalAmount: totalAmount,
            OLD_AMOUNT: totalAmount,
            REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
            REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
            Revenue_Share_Lab: Revenue_Share_Lab,
          });
        }
      } else {
        let totalAmount =
          this.state.totalAmount - selectedState[index].SERVICE_AMT;
        totalAmount = totalAmount + parseFloat(e.target.value);
        selectedState[index].newamount = e.target.value;
        selectedState[index].amountAlreadyDedcted = true;
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;

        this.setState({
          selectedState: selectedState,
          totalAmount: totalAmount,
          OLD_AMOUNT: totalAmount,
          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      }
    } else {
      if (selectedState[index].amountAlreadyDedcted) {
        let totalAmount =
          this.state.totalAmount - parseFloat(selectedState[index].newamount);
        totalAmount = totalAmount + selectedState[index].SERVICE_AMT;
        selectedState[index].newamount = selectedState[index].SERVICE_AMT;
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;

        this.setState({
          selectedState: selectedState,
          totalAmount: totalAmount,
          OLD_AMOUNT: totalAmount,
          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      }
    }
  }

  handlePackageActiveChange(e, index) {
    let selectedState = [...this.state.selectedState];
    if (index > -1) {
      if (selectedState[index].isActive) {
        let totalAmount =
          this.state.totalAmount - selectedState[index].newamount;
        let old_amount =
          this.state.OLD_AMOUNT - selectedState[index].SERVICE_AMT;
        selectedState[index].isActive = false;
        this.setState({
          totalAmount: totalAmount,
          OLD_AMOUNT: old_amount,
          selectedState: selectedState,
        });
      } else {
        let totalAmount =
          this.state.totalAmount + selectedState[index].newamount;
        let old_amount =
          this.state.OLD_AMOUNT + selectedState[index].SERVICE_AMT;
        selectedState[index].isActive = true;
        this.setState({
          totalAmount: totalAmount,
          OLD_AMOUNT: old_amount,
          selectedState: selectedState,
        });
      }
    }
  }

  handleInHouseChange(e) {
    if (e.target.value === "IN_HOUSE") {
      if (e.target.checked) {
        this.setState({
          IN_HOUSE: true,
          OUT_SOURCED_DIA: false,
          OUT_SOURCED_TP: false,
          REVENUE_SHARE_OUT: 0,
          Revenue_Perc_OutS: 0,
        });
      }
    } else if (e.target.value === "OUT_SOURCED_TP") {
      if (e.target.checked) {
        this.setState({
          OUT_SOURCED_DIA: false,
          IN_HOUSE: false,
          OUT_SOURCED_TP: true,
        });
      }
    } else {
      if (e.target.checked) {
        this.setState({
          OUT_SOURCED_DIA: true,
          IN_HOUSE: false,
          OUT_SOURCED_TP: false,
          REVENUE_SHARE_OUT: 0,
          Revenue_Perc_OutS: 0,
        });
      }
    }
  }

  handleActiveChange(e) {
    this.setState({
      isActive: !this.state.isActive,
    });
  }

  handleReportingTypeForIP(e) {
    this.setState({
      isReportingTypeForIP: !this.state.isReportingTypeForIP,
    });
  }

  handleReportingTypeForOP(e) {
    this.setState({
      isReportingTypeForOP: !this.state.isReportingTypeForOP,
    });
  }

  onRoundOffChange(e) {
    if (e.target.value === "floor") {
      let amount = parseInt(this.state.SERVICE_AMT / 10) * 10;
      let REVENUE_SHARE_HOSP =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Hosp) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Hosp) / 100);
      let REVENUE_SHARE_OUT =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_OutS) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_OutS) / 100);
      let Revenue_Share_Lab =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Lab) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Lab) / 100);
      this.setState({
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
      this.setState({
        exact_service_amount: this.state.SERVICE_AMT,
        SERVICE_AMT: parseInt(this.state.SERVICE_AMT / 10) * 10,
        roundoffType: "floor",
      });
    } else if (e.target.value === "ceil") {
      let amount = parseInt(this.state.SERVICE_AMT / 10) * 10 + 10;
      let REVENUE_SHARE_HOSP =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Hosp) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Hosp) / 100);
      let REVENUE_SHARE_OUT =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_OutS) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_OutS) / 100);
      let Revenue_Share_Lab =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Lab) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Lab) / 100);
      this.setState({
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
      this.setState({
        exact_service_amount: this.state.SERVICE_AMT,
        SERVICE_AMT: parseInt(this.state.SERVICE_AMT / 10) * 10 + 10,
        roundoffType: "ceil",
      });
    } else {
      let amount =
        parseInt(this.state.SERVICE_AMT / 10) ===
        parseInt(
          (this.state.exact_service_amount
            ? this.state.exact_service_amount
            : 0) / 10
        )
          ? this.state.exact_service_amount
          : this.state.SERVICE_AMT;
      let REVENUE_SHARE_HOSP =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Hosp) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Hosp) / 100);
      let REVENUE_SHARE_OUT =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_OutS) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_OutS) / 100);
      let Revenue_Share_Lab =
        e.target.value === "floor"
          ? Math.floor((amount * this.state.Revenue_Perc_Lab) / 100)
          : Math.ceil((amount * this.state.Revenue_Perc_Lab) / 100);
      this.setState({
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
      this.setState({
        SERVICE_AMT:
          parseInt(this.state.SERVICE_AMT / 10) ===
          parseInt(
            (this.state.exact_service_amount
              ? this.state.exact_service_amount
              : 0) / 10
          )
            ? this.state.exact_service_amount
            : this.state.SERVICE_AMT,
      });
    }
  }

  onProcedureChange(e) {
    this.setState({
      PROCEDURE: e.target.value,
    });
  }

  render() {
    const { department, subdepartments, costcenter } = this.state;
    let enableNext;
    let responseObject;
    let { serviceMaster } = this.props;
    if (serviceMaster) {
      console.log(serviceMaster);
      if (serviceMaster.data) {
        if (serviceMaster.data.success) {
          enableNext = serviceMaster.data.success;
          responseObject = serviceMaster.data.data;
        }
      }
    }

    const AddConsultantNameinIPBilling = (
      <Row form>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Add Consultant Name in IP Billing
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleAddConsultantNameinIPBilling}
              checked={this.state.CONS_NAME_BILLING_IP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );
    const DiscountApplicableForIP = (
      <Row form>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Discount Applicable For IP
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleDiscountApplicableForIP}
              checked={this.state.DISCOUNT_IP_APP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );
    const DoNotEditServiceAmountForIP = (
      <Row form>
        <Col md={8}>
          <Label for="Customer_Tran_Name" className="marginTopNegativeFour">
            Edit Service Amount For IP
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleDoNotEditServiceAmountForIP}
              checked={this.state.EDIT_SERVICE_AMT_IP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );
    const ReferralAmountApplicableForIP = (
      <Row form>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Referral Amount Applicable For IP
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleReferralAmountApplicableForIP}
              checked={this.state.REF_AMT_IP_APP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );

    const DischargeSummaryForOP = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleDischargeSummaryOPChange}
              checked={this.state.dischargeSummeryOP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Discharge Summary For OP
          </Label>
        </Col>
      </Row>
    );
    const IPQtyEditable = (
      <Row form>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            IP Qty Editable
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleIPQtyEditable}
              checked={this.state.IP_QTY_EDIT}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );

    const AutoShowinIPBilling = (
      <Row form>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Auto Show in IP Billing
          </Label>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleAutoShowinIPBilling}
              checked={this.state.AUTO_SHOW_IP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );

    const TaxApplicableinIPBilling = (
      <Row form>
        <Col md={4}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Tax Applicable %
          </Label>
        </Col>
        <Col md={4}>
          {this.state.TAX_APPLICABLE_IN_IP ? (
            <Input
              name="TAX_AMOUNT_IP"
              style={{ marginTop: "-6px" }}
              onChange={(e) => {
                this.handleTaxAmountIP(e);
              }}
              value={this.state.TAX_AMOUNT_IP}
              placeholder="IP Tax Percentage"
            ></Input>
          ) : null}
        </Col>

        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleTaxApplicableIPBilling}
              checked={this.state.TAX_APPLICABLE_IN_IP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );

    const AutoShowinOpBilling = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleAutoShowinOPBilling}
              checked={this.state.AUTO_SHOW_OP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Auto Show in OP Billing
          </Label>
        </Col>
      </Row>
    );

    const TaxApplicableinOPBilling = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleTaxApplicableOPBilling}
              checked={this.state.TAX_APPLICABLE_IN_OP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Tax Applicable %
          </Label>
        </Col>
        <Col>
          {this.state.TAX_APPLICABLE_IN_OP ? (
            <Input
              name="TAX_AMOUNT_OP"
              style={{ marginTop: "-6px" }}
              onChange={(e) => {
                this.handleTaxAmountOP(e);
              }}
              value={this.state.TAX_AMOUNT_OP}
              placeholder="OP Tax Percentage"
            ></Input>
          ) : null}
        </Col>
      </Row>
    );

    const NotesForOP = (
      <Row form>
        <Col md={4}>
          <Label for="notes_for_op">Notes For OP</Label>
        </Col>
        <Col>
          <Input
            name="notes_for_op"
            onChange={(e) => {
              this.onChange(e);
            }}
            value={this.state.notes_for_op}
            placeholder="Enter Notes for OP Billing"
          ></Input>
        </Col>
      </Row>
    );

    const NotesForIP = (
      <Row form>
        <Col md={4}>
          <Label for="notes_for_ip">Notes For IP</Label>
        </Col>
        <Col>
          <Input
            name="notes_for_ip"
            onChange={(e) => {
              this.onChange(e);
            }}
            value={this.state.notes_for_ip}
            placeholder="Enter Notes for IP Billing"
          ></Input>
        </Col>
      </Row>
    );

    const AddConsultantNameinOPBilling = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleAddConsultantNameinOPBilling}
              checked={this.state.CONS_NAME_BILLING}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Add Consultant Name in OP Billing
          </Label>
        </Col>
      </Row>
    );
    const DiscountApplicableForOP = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleDiscountApplicableForOP}
              checked={this.state.DISCOUNT_APP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Discount Applicable For OP
          </Label>
        </Col>
      </Row>
    );
    const DoNotEditServiceAmountForOP = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleDoNotEditServiceAmountForOP}
              checked={this.state.EDIT_SERVICE_AMT}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Edit Service Amount For OP
          </Label>
        </Col>
      </Row>
    );
    const ReferralAmountApplicableForOP = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleReferralAmountApplicableForOP}
              checked={this.state.REF_AMT_APP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Referral Amount Applicable For OP
          </Label>
        </Col>
      </Row>
    );

    const OPQtyEditable = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleOPQtyEditable}
              checked={this.state.OP_QTY_EDIT}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={8}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            OP Qty Editable
          </Label>
        </Col>
      </Row>
    );

    const ReportingTypeForIP = (
      <Row form>
        <Col md={4}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Reporting Type
          </Label>
        </Col>
        <Col md={4}>
          {this.state.isReportingTypeForIP ? (
            <Input
              type="select"
              name="reporting_type_ip"
              onChange={this.onChange}
              value={this.state.reporting_type_ip}
            >
              <option value="tabular_format">Tabular Format</option>
              <option value="template_format">Template Format</option>
              <option value="culture_format">Culture Format</option>
              <option value="andrology_reports">Andrology Reports</option>
            </Input>
          ) : null}
        </Col>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleReportingTypeForIP}
              checked={this.state.isReportingTypeForIP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    );

    const ReportingTypeForOP = (
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Switch
              onChange={this.handleReportingTypeForOP}
              checked={this.state.isReportingTypeForOP}
              className="mr-2 mb-2"
              id="normal-switch"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <Label className="marginTopNegativeFour" for="Customer_Tran_Name">
            Reporting Type
          </Label>
        </Col>
        <Col>
          {this.state.isReportingTypeForOP ? (
            <Input
              type="select"
              name="reporting_type_op"
              onChange={this.onChange}
              value={this.state.reporting_type_op}
            >
              <option value="tabular_format">Tabular Format</option>
              <option value="template_format">Template Format</option>
              <option value="culture_format">Culture Format</option>
            </Input>
          ) : null}
        </Col>
      </Row>
    );

    let tableRows;
    if (this.state.selectedState) {
      tableRows = this.state.selectedState.map((data, index) => {
        let tds = [<td>{index + 1}</td>];
        tds.push(
          <td>
            {data.type === "services" ? data.SERVICE_NAME : data.PACKAGE_NAME}
          </td>
        );
        tds.push(
          <td>
            {data.type === "services" ? data.SERVICE_AMT : data.PACKAGE_AMT}
          </td>
        );
        tds.push(
          <td>
            <Input
              name="new_amount"
              type="number"
              placeholder="enter new amount"
              value={data.newamount}
              disabled
              onChange={(e) => {
                this.onAmountChange(e, index);
              }}
            ></Input>
          </td>
        );
        tds.push(
          <td>
            <Input
              name="reporting_priority"
              type="number"
              placeholder="Reporting Priority"
              value={data.reporting_priority}
              onChange={(e) => this.onReportingChange(e, index)}
            ></Input>
          </td>
        );
        tds.push(
          <td>
            <FormGroup style={{ marginTop: "13px" }}>
              <Switch
                checked={data.isActive}
                className="mr-2 mb-2"
                id="normal-switch"
                onChange={(e) => this.handlePackageActiveChange(e, index)}
              />
            </FormGroup>
          </td>
        );

        tds.push(
          <td>
            <Input
              name="notes"
              type="text"
              placeholder="Notes"
              value={data.notes}
              onChange={(e) => this.onNotesChange(e, index)}
            ></Input>
          </td>
        );

        tds.push(
          <td>
            <Button
              className="mb-2 mr-2 btn-icon btn-icon-only"
              outline
              color="danger"
              title="Delete"
              onClick={() => {
                this.removeService(index);
              }}
            >
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
            </Button>
          </td>
        );

        return <tr key={data._id}>{tds}</tr>;
      });
    }

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardHeader>
              <h4>
                <strong>
                  {this.state.comeFromEditFlow ? "Edit " : "Add "}
                  {this.state.type === "packages" ? "Packages" : "Services"}
                </strong>
              </h4>
            </CardHeader>
            <CardBody>
              <Form>
                <Row form>
                  <Col md={4} style={{ marginBottom: "10px" }}>
                    <FormGroup row>
                      <Label for="Customer_Cluster_Type" sm={3}>
                        Type
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          id="type"
                          name="type"
                          value={
                            this.state.type === "services"
                              ? "Service"
                              : this.state.type === "packages"
                              ? "Package"
                              : "Group"
                          }
                          disabled
                        ></Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="D_CODE" sm={3}>
                        Dept.<span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          id="D_CODE"
                          name="D_CODE"
                          onChange={(e) => this.handleDepartmentChange(e)}
                        >
                          <option value="">Select</option>
                          {department
                            ? department.map((type) => {
                                return (
                                  <option
                                    key={type._id}
                                    value={type.code}
                                    selected={this.state.D_CODE === type.code}
                                  >
                                    {type.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="Customer_Cluster_Type" sm={3}>
                        Sub Dept <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          id="SD_CODE"
                          name="SD_CODE"
                          onChange={(e) => this.onChange(e)}
                        >
                          <option value="">Select</option>
                          {subdepartments
                            ? subdepartments.map((type) => {
                                return (
                                  <option
                                    value={type.code}
                                    key={type._id}
                                    selected={this.state.SD_CODE === type.code}
                                  >
                                    {type.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="SERVICE_NAME" sm={3}>
                        {this.state.type === "packages"
                          ? "Package Name"
                          : this.state.type === "groups"
                          ? "Group Name"
                          : "Service Name"}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="SERVICE_NAME"
                          id="SERVICE_NAME"
                          placeholder=""
                          onChange={(e) => {
                            this.onServiceNameChange(e);
                          }}
                          value={this.state.SERVICE_NAME}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="USERDEFINED_NAME" sm={3}>
                        User Defined Name
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="USERDEFINED_NAME"
                          id="USERDEFINED_NAME"
                          placeholder=""
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          value={this.state.USERDEFINED_NAME}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="CCM_CODE" sm={3}>
                        Cost Centre <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          id="CCM_CODE"
                          name="CCM_CODE"
                          onChange={(e) => {
                            this.onCCMChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {costcenter
                            ? costcenter.map((data) => {
                                return (
                                  <option
                                    key={data._id}
                                    value={data.code}
                                    selected={this.state.CCM_CODE === data.code}
                                  >
                                    {data.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  {this.state.type === "services" ? (
                    <Col md={4}>
                      <FormGroup row>
                        {this.state.type === "services" ? (
                          <FormGroup row>
                            <Label for="SERVICE_AMT" sm={3}>
                              {this.state.type === "packages"
                                ? "Package Amount"
                                : this.state.type === "groups"
                                ? "Group Amount"
                                : "Service Amount"}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Col sm={7}>
                              <Input
                                type="text"
                                name="SERVICE_AMT"
                                id="SERVICE_AMT"
                                placeholder=""
                                onChange={(e) => {
                                  this.onServiceAmountChange(e);
                                }}
                                value={this.state.SERVICE_AMT}
                              />
                            </Col>
                          </FormGroup>
                        ) : null}
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col md={4}>
                    <FormGroup row>
                      <>
                        <Label for="roundoffType" sm={3}>
                          Round Off Type
                        </Label>
                        <Col sm={7}>
                          <Input
                            type="select"
                            name="roundoffType"
                            id="roundoffType"
                            placeholder=""
                            onChange={(e) => {
                              this.onRoundOffChange(e);
                            }}
                            value={this.state.roundoffType}
                          >
                            <option value="">Select</option>
                            <option value="floor">Flooring</option>
                            <option value="ceil">Ceiling</option>
                            <option value="exact">Exact</option>
                          </Input>
                        </Col>
                      </>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="Customer_Tran_Hospital_Beds" sm={3}>
                        Printing Sequece
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="number"
                          name="SEQUENCE"
                          id="SEQUENCE"
                          placeholder=""
                          value={this.state.SEQUENCE}
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={4}>
                    <FormGroup row className="hmgroup">
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="procedure1"
                          name="procedure"
                          label="Surgery"
                          checked={this.state.PROCEDURE === "Surgery"}
                          value="Surgery"
                          onChange={(e) => {
                            this.onProcedureChange(e);
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="procedure2"
                          name="procedure"
                          label="Procedure"
                          checked={this.state.PROCEDURE === "Procedure"}
                          value="Procedure"
                          onChange={(e) => {
                            this.onProcedureChange(e);
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="procedure3"
                          name="procedure"
                          label="None"
                          checked={this.state.PROCEDURE === "None"}
                          value="None"
                          onChange={(e) => {
                            this.onProcedureChange(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row className="hmgroup">
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="inhouse1"
                          name="inhouse"
                          label="Inhouse Diagnostics"
                          value="IN_HOUSE"
                          checked={this.state.IN_HOUSE}
                          onChange={(e) => {
                            this.handleInHouseChange(e);
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="inhouse2"
                          name="inhouse"
                          label="Outsource Diagnostics"
                          value="OUT_SOURCED_DIAG"
                          checked={this.state.OUT_SOURCED_DIA}
                          onChange={(e) => {
                            this.handleInHouseChange(e);
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <CustomInput
                          type="radio"
                          id="inhouse3"
                          name="inhouse"
                          label="TP Diagnostics"
                          value="OUT_SOURCED_TP"
                          checked={this.state.OUT_SOURCED_TP}
                          onChange={(e) => {
                            this.handleInHouseChange(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="Customer_Tran_Hospital_Beds" sm={3}>
                        Active
                      </Label>
                      <Col sm={7}>
                        <FormGroup>
                          <Switch
                            onChange={this.handleActiveChange}
                            checked={this.state.isActive}
                            className="mr-2 mb-2"
                            id="normal-switch"
                          />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {this.state.type !== "services" ? (
            <Card className="main-card mb-3">
              <CardHeader>Service List</CardHeader>
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col sm={2}>
                      <Label>
                        {this.state.type === "packages"
                          ? "Package Amount"
                          : "Group Amount"}
                      </Label>
                      <Input
                        disabled
                        name="old_amount"
                        value={this.state.OLD_AMOUNT}
                      ></Input>
                    </Col>
                    <Col sm={2}>
                      <Label>New Amount</Label>
                      <Input
                        name="new_amount"
                        value={this.state.totalAmount}
                        onChange={(e) => {
                          this.onTotalAmountChange(e);
                        }}
                      ></Input>
                    </Col>
                    <Col sm={3}>
                      <Label for="discount">
                        {this.state.perChange < 100
                          ? "Discount Percentage"
                          : "Increased Percentage"}
                      </Label>
                      <Input
                        id="discount"
                        name="discount"
                        value={
                          this.state.perChange === 0
                            ? 0
                            : this.state.perChange < 100
                            ? 100 - this.state.perChange
                            : this.state.perChange - 100
                        }
                        disabled
                      ></Input>
                    </Col>
                    <Col>
                      <UploadModal
                        handleServiceChange={this.handleServiceChange}
                        type={this.state.type}
                        selectedState={this.state.selectedState}
                      />
                    </Col>
                  </Row>
                </CardTitle>

                <Table hover responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Service Name</th>
                      <th>Service Amuont</th>
                      <th>New Amount</th>
                      <th>Report Priority</th>
                      <th>Active</th>
                      <th>Notes</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>
                </Table>
              </CardBody>
            </Card>
          ) : null}

          {this.state.type === "services" ? (
            <Card className="main-card mb-3" style={{ marginTop: "10px" }}>
              <CardHeader>Revenue Sharing</CardHeader>
              <CardBody>
                <Form>
                  <Row form>
                    {this.state.type === "services" ? (
                      <Col md={6}>
                        <Table>
                          <thead>
                            <tr>
                              <th>Type of Sharing</th>
                              <th>Percentage</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Revenue Sharing % for Hospital</td>
                              <td>
                                <Input
                                  type="number"
                                  name="Revenue_Perc_Hosp"
                                  id="Revenue_Perc_Hosp"
                                  placeholder=""
                                  onChange={(e) => {
                                    this.handleHospitalRevenue(e);
                                  }}
                                  value={this.state.Revenue_Perc_Hosp}
                                  max="100"
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name="REVENUE_SHARE_HOSP"
                                  id="REVENUE_SHARE_HOSP"
                                  placeholder=""
                                  value={this.state.REVENUE_SHARE_HOSP}
                                  onChange={(e) =>
                                    this.onHospitalRevenueChange(e)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Revenue Sharing % for Hospital Lab</td>
                              <td>
                                <Input
                                  type="number"
                                  name="Revenue_Perc_Lab"
                                  id="Revenue_Perc_Lab"
                                  placeholder=""
                                  max="100"
                                  onChange={(e) => {
                                    this.handleHospitalLabRevenue(e);
                                  }}
                                  value={this.state.Revenue_Perc_Lab}
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name="Revenue_Share_Lab"
                                  id="Revenue_Share_Lab"
                                  placeholder=""
                                  value={this.state.Revenue_Share_Lab}
                                  onChange={(e) =>
                                    this.onHospitalLabRevenueChange(e)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Revenue Sharing % for OutSource</td>
                              <td>
                                <Input
                                  type="number"
                                  name="Revenue_Perc_OutS"
                                  id="Revenue_Perc_OutS"
                                  placeholder=""
                                  max="100"
                                  onChange={(e) => {
                                    this.handleOutSorceRevenue(e);
                                  }}
                                  value={this.state.Revenue_Perc_OutS}
                                  disabled={!this.state.OUT_SOURCED_TP}
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name="REVENUE_SHARE_OUT"
                                  id="REVENUE_SHARE_OUT"
                                  placeholder=""
                                  value={this.state.REVENUE_SHARE_OUT}
                                  onChange={(e) => this.onRevenueOutChange(e)}
                                  disabled={!this.state.OUT_SOURCED_TP}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ) : null}
                    {this.state.type === "services" ? (
                      <Col md={6}>
                        <FormGroup row>
                          <Col sm={12}>
                            <FormGroup row>
                              <Col sm={12}>
                                <div className="servicetab">
                                  <Button
                                    size="sm"
                                    outline
                                    color="alternate"
                                    className={
                                      "btn-pill btn-wide " +
                                      classnames({
                                        active: this.state.activeTab === "1",
                                      })
                                    }
                                    onClick={() => {
                                      this.toggle("1");
                                    }}
                                  >
                                    {" "}
                                    Apply for IP{" "}
                                  </Button>
                                </div>
                              </Col>
                            </FormGroup>

                            <div className="servicetabcontent">
                              <TabContent activeTab={this.state.activeTab}>
                                {/* <TabPane
                                  className="servicetabcontent1"
                                  tabId="1"
                                >
                                  {AddConsultantNameinOPBilling}
                                  {DiscountApplicableForOP}
                                  {DoNotEditServiceAmountForOP}
                                  {ReferralAmountApplicableForOP}

                                  {OPQtyEditable}
                                  {AutoShowinOpBilling}
                                  {TaxApplicableinOPBilling}
                                  {DischargeSummaryForOP}
                                  {this.state.D_CODE == this.state.deptCode
                                    ? ReportingTypeForOP
                                    : null}
                                  {NotesForOP}
                                </TabPane> */}
                                <TabPane
                                  className="servicetabcontent1"
                                  tabId="1"
                                >
                                  {AddConsultantNameinIPBilling}
                                  {ReferralAmountApplicableForIP}
                                  {IPQtyEditable}
                                  {DoNotEditServiceAmountForIP}
                                  {DiscountApplicableForIP}
                                  {TaxApplicableinIPBilling}
                                  {AutoShowinIPBilling}
                                  {this.state.D_CODE === this.state.deptCode
                                    ? ReportingTypeForIP
                                    : null}
                                  {/* {ReportingTypeForIP} */}
                                  {NotesForIP}
                                </TabPane>
                              </TabContent>
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          ) : null}
          <AmountSetting
            title="Service Master Amount Settings"
            roundoffType={this.state.roundoffType}
            handleRoundOffChange={this.handleRoundOffChange}
            baseAmountSetting={this.state.baseAmountSetting}
            onBaseAmountSetupChange={this.onBaseAmountSetupChange}
            onBaseAmountSetupPercentageChange={
              this.onBaseAmountSetupPercentageChange
            }
            baseAmountSettingValue={this.state.baseAmountSettingValue}
            onServiceAmountSetupChange={this.onServiceAmountSetupChange}
            serviceAmountSetting={this.state.serviceAmountSetting}
            onServiceAmountSetupValueChange={
              this.onServiceAmountSetupValueChange
            }
            serviceAmountSettingValue={this.state.serviceAmountSettingValue}
            baseAmountDateChange={this.baseAmountDateChange}
            serviceAmountDateChange={this.serviceAmountDateChange}
            onChange={this.onChange}
          />
          <Button
            color="primary"
            className="mt-2 right-side"
            onClick={(e) => {
              this.props.addNavigation(e);
            }}
          >
            Exit To Grid
          </Button>
          {this.state.comeFromEditFlow ? (
            <Button
              color="primary"
              className="mt-2 right-side"
              onClick={(e) => {
                this.EditService(e);
              }}
            >
              Update
            </Button>
          ) : (
            <Button
              color="primary"
              className="mt-2 right-side"
              onClick={(e) => {
                this.addService(e);
              }}
            >
              Save
            </Button>
          )}
          {!this.state.comeFromEditFlow ? (
            <Button
              color="warning"
              className="mt-2 right-side"
              onClick={(e) => {
                this.clearForm();
              }}
            >
              Clear Form
            </Button>
          ) : null}
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { serviceMaster } = state;
  return { serviceMaster: serviceMaster.data };
}

const connectedViewMaster = connect(mapStateToProps)(Services);

export { connectedViewMaster as Services };
