import { storeConstants } from '../constants';
import { alertActions } from './alert.actions';
import {storeService} from '../services/stores';

export const  storeActions = {  
  getAll
};

function getAll() {
  return dispatch => {     
    storeService.getAll()
          .then(
              data =>{
                if(data.success)
                {
                  console.log('Store Response --------->>>>>',data)
                  dispatch({ type: storeConstants.GET_ALL_STORES, stores:data.stores})                   
                }                 
                }             
          );
  };


 
}

