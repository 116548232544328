import { CorporateConstants } from "../constants";

export function corporateMaster(state = {}, action) {
  switch (action.type) {
    case CorporateConstants.GET_ALL_CORPORATE_REQUEST:
      return { ...state, loading: true };
    case CorporateConstants.GET_ALL_CORPORATE_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case CorporateConstants.GET_ALL_CORPORATE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CorporateConstants.ADD_CORPORATE_REQUEST:
      return { ...state, loading: true };
    case CorporateConstants.ADD_CORPORATE_SUCCESS:
      let corporateList = [];
      if (state.data) {
        corporateList = state.data;
      }
      corporateList.push(action.data);
      return { ...state, data: action.data, loading: false };
    case CorporateConstants.ADD_CORPORATE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CorporateConstants.UPDATE_CORPORATE_REQUEST:
      return { ...state, loading: true };
    case CorporateConstants.UPDATE_CORPORATE_SUCCESS:
      let corporateUpdatedList = [];
      if (state.data) {
        corporateUpdatedList = state.data;
        corporateUpdatedList.forEach((element, index) => {
          if (element.code === action.data.code) {
            corporateUpdatedList.splice(index, 1);
          }
        });
      }
      corporateUpdatedList.push(action.data);
      return { ...state, data: action.data, loading: false };
    case CorporateConstants.UPDATE_CORPORATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case CorporateConstants.GET_CORPORATE_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case CorporateConstants.GET_CORPORATE_CONS_FEES_SUCCESS:
      return { ...state, ConsData: action.data, loading: false };
    case CorporateConstants.GET_CORPORATE_CONS_FEES_FAILURE:
      return { ...state, error: action.error, loading: false };

    case CorporateConstants.ADD_CORPORATE_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case CorporateConstants.ADD_CORPORATE_CONS_FEES_SUCCESS:
      let ConsData = [];
      if (state.ConsData) {
        ConsData = state.ConsData;
      }
      ConsData.push(action.data);
      return { ...state, ConsData: ConsData, loading: false };
    case CorporateConstants.ADD_CORPORATE_CONS_FEES_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
}
