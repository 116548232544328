import { laboratoryMasterConstants } from "../constants";

export function laboratoryMasters(state = {}, action) {
  switch (action.type) {
    case laboratoryMasterConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.GET_ALL_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case laboratoryMasterConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.GET_ACTIVE_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.GET_ACTIVE_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case laboratoryMasterConstants.GET_ACTIVE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.ADD_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.ADD_SUCCESS:
      var prevData = [...state.data];
      prevData[prevData.length] = action.data.data;
      return { ...state, data: prevData, loading: false };
    case laboratoryMasterConstants.ADD_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.UPDATE_SUCCESS:
      let updatedState = action.data;
      let index = state.data.findIndex((ob) => ob.id === updatedState.id);
      state.data[index] = updatedState;
      return { ...state, data: [...state.data], loading: false };
    case laboratoryMasterConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.SET_DEFAULT_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.SET_DEFAULT_SUCCESS:
      const updatedData = state.data.map((item) => {
        if (item.id === action.data.type) {
          return {
            ...action.data.data,
          };
        } else if (item.favourite) {
          return {
            ...item,
            favourite: false,
          };
        }
        return item;
      });
      return { ...state, data: updatedData, loading: false };
    case laboratoryMasterConstants.SET_DEFAULT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case laboratoryMasterConstants.DELETE_SUCCESS:
      // var prevData = { ...state.data };
      // prevData[action.data.type] = action.data.data;
      //return { ...state, data: prevData, loading: false };

      let deleteddata = action.data.data;
      console.log(deleteddata.code);
      let cindex = state.data.findIndex((ob) => ob.id === deleteddata.id);

      // state.data[action.data.type].list.splice(cindex, 1);
      state.data[cindex].deleted = deleteddata.deleted;
      //state.data[action.data.type].list[cindex].active = deleteddata.active;
      return { ...state, data: [...state.data], loading: false };
    case laboratoryMasterConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case laboratoryMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
