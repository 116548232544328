import { tpaConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { tpaService, tpaConsultantService } from "../services";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";

export const tpaMasterActions = {
  add,
  getAll,
  deleteUser,
  update,
  addConsFees,
  updateConsFees,
  deleteConsFees,
  getConsFeesAll,
};

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaService.add(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.ADD_TPA_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.ADD_TPA_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.ADD_TPA_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.UPDATE_TPA_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.UPDATE_TPA_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.UPDATE_TPA_FAILURE, error };
  }
}

function deleteUser(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaService.deleteMaster(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.DELETE_TPA_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.DELETE_TPA_REQUEST, data };
  }
  function failure(error) {
    return { type: tpaConstants.DELETE_TPA_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    tpaService.getAll().then(
      (result) => {
        if (result.success) {
          dispatch(success(result?.tpas));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'success'
          // });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          //   toast(result.message, {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: 'bottom-center',
          //     type: 'error'
          // });
        }
      },
      (error) => {
        //   toast(error.message, {
        //     transition: Bounce,
        //     closeButton: true,
        //     autoClose: 5000,
        //     position: 'bottom-center',
        //     type: 'error'
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.GET_ALL_TPA_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.GET_ALL_TPA_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.GET_ALL_TPA_FAILURE, error };
  }
}

function addConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaConsultantService.add(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.ADD_TPA_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.ADD_TPA_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.ADD_TPA_CONS_FEES_FAILURE, error };
  }
}

function updateConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaConsultantService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.UPDATE_TPA_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.UPDATE_TPA_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.UPDATE_TPA_CONS_FEES_FAILURE, error };
  }
}

function deleteConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    tpaConsultantService.deleteMaster(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.DELETE_TPA_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.DELETE_TPA_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.DELETE_TPA_CONS_FEES_FAILURE, error };
  }
}

function getConsFeesAll() {
  return (dispatch) => {
    dispatch(request());

    tpaConsultantService.getAll().then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: tpaConstants.GET_TPA_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: tpaConstants.GET_TPA_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tpaConstants.GET_TPA_CONS_FEES_FAILURE, error };
  }
}
