import { patientQueueConstants } from "../constants";

export function patientQueue(state = { queue: [] }, action) {
  switch (action.type) {
    case patientQueueConstants.GET_ALL_QUEUE_PATIENTS:
      return { ...state, queue: action.data };

    case patientQueueConstants.PATIENT_ADDED_TO_QUEUE:
      return { ...state, queue: [...state.queue, action.data] };

    // case patientQueueConstants.ADD_PATIENT_TO_QUEUE_SUCCESS:
    //   return { ...state, queue: [...state.queue, action.data] };

    case patientQueueConstants.UPDATE_PATIENT_IN_QUEUE:
      let updatedState = action.data;
      let index = state.queue.findIndex(
        (ob) => ob.patient_id._id === updatedState.patient_id._id
      );
      state.queue[index] = updatedState;
      return { ...state, queue: [...state.queue] };

    case patientQueueConstants.REMOVE_PATIENT_IN_QUEUE:
      let data = action.data;
      let cindex = state.queue.findIndex(
        (ob) => ob.patient_id === data.patient_id
      );
      state.queue.splice(cindex, 1);
      return { ...state, queue: [...state.queue], loading: false };

    case patientQueueConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
