import { appointmentConstants } from "../constants";

export function appointments(
  state = { loading: false, appointments: [] },
  action
) {
  switch (action.type) {
    case appointmentConstants.GET_ALL_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case appointmentConstants.GET_ALL_APPOINTMENT_SUCCESS:
      return { ...state, appointments: action.appointments, loading: false };
    case appointmentConstants.GET_ALL_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case appointmentConstants.GET_BY_CONSULTANT_REQUEST:
      return { ...state, loading: true };
    case appointmentConstants.GET_BY_CONSULTANT_SUCCESS:
      return { ...state, consultantWise: action.appointments, loading: false };
    case appointmentConstants.GET_BY_CONSULTANT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case appointmentConstants.ADD_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case appointmentConstants.ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: [...state.appointments, action.appointment],
        selected_appointment: action.appointment,
        loading: false,
      };
    case appointmentConstants.ADD_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case appointmentConstants.SELECTED_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case appointmentConstants.SELECTED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        selected_appointment: action.appointment,
        loading: false,
      };
    case appointmentConstants.SELECTED_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case appointmentConstants.UPDATE_APPOINTMENT_REQUEST:
      return { ...state, loading: true };

    case appointmentConstants.UPDATE_APPOINTMENT_SUCCESS:
      let updatedApp = action.appointment;
      let index = state.appointments.findIndex(
        (ob) => ob._id === updatedApp._id
      );
      console.log("Appointment index", index);
      state.appointments[index] = updatedApp;
      return {
        ...state,
        appointments: [...state.appointments],
        selected_appointment: action.appointment,
        loading: false,
      };

    case appointmentConstants.UPDATE_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case appointmentConstants.DELETE_APPOINTMENT_REQUEST:
      return { ...state, loading: true };

    case appointmentConstants.DELETE_APPOINTMENT_SUCCESS:
      let deletedappointment = action.appointment;
      let cindex = state.appointments.findIndex(
        (ob) => ob._id === deletedappointment._id
      );

      console.log("Cancelled appointment", cindex);
      state.appointments[cindex] = deletedappointment;
      return {
        ...state,
        appointments: [...state.appointments],
        loading: false,
      };
    case appointmentConstants.DELETE_APPOINTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
