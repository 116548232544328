import { authHeader } from '../store';
import {apiUrl,USER_KEY} from "../helpers"
import {helperService} from "./helper.service"
import {storageService} from './storage.service'


export const adminService = {
    isSignedIn,   
    logout 
};

function isSignedIn() {   
    const res=  storageService.getData(USER_KEY);
    console.log(res)
    if (!res) {
        return false;            
      } else
       {   
       return true;
      }  
  };


 function logout() {
  //  return new Promise(async(resolve,reject)=>{
       // localStorage.removeItem(USER_KEY);
       storageService.removeData(USER_KEY);
        //resolve(true);
   // })
    // remove user from local storage to log user out  
}

