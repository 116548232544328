import React, { Fragment, Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import Switch from "react-switch";
import { toISODateString } from "react-dates";
export default class AmountSetting extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Card>
          <CardHeader>{this.props.title}</CardHeader>
          <CardBody style={{ paddingRight: "0px" }}>
            <Row>
              <Col
                md={5}
                style={{
                  border: "1px solid black",
                  marginLeft: "5px",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col md={6}>
                    <Label>Set option for base amount</Label>
                    <Input
                      type="select"
                      onChange={this.props.onBaseAmountSetupChange}
                    >
                      <option>Select</option>
                      <option
                        selected={this.props.baseAmountSetting === "AI"}
                        value="AI"
                      >
                        {" "}
                        Auto Increment
                      </option>

                      <option
                        selected={this.props.baseAmountSetting === "AD"}
                        value="AD"
                      >
                        Auto Decrement
                      </option>
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Label>Select Date</Label>
                    <Input
                      type="date"
                      name="baseAmountSettingDate"
                      onChange={this.props.onChange}
                    ></Input>
                  </Col>
                </Row>

                <Input
                  type="number"
                  placeholder="Enter the percentage"
                  onChange={this.props.onBaseAmountSetupPercentageChange}
                  value={this.props.baseAmountSettingValue}
                  style={{ marginBottom: "4px" }}
                ></Input>
              </Col>
              <Col md={1}></Col>

              <Col
                md={5}
                style={{
                  border: "1px solid black",
                  marginLeft: "5px",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col md={6}>
                    <Label>Set option For Service Amount</Label>
                    <Input
                      type="select"
                      name="serviceAmountSetup"
                      onChange={this.props.onServiceAmountSetupChange}
                    >
                      <option value="">Select</option>
                      <option
                        selected={this.props.serviceAmountSetting === "AI"}
                        value="AI"
                      >
                        Auto Increment
                      </option>

                      <option
                        selected={this.props.serviceAmountSetting === "AD"}
                        value="AD"
                      >
                        Auto Decrement
                      </option>
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Label>Select Date</Label>
                    <Input
                      type="date"
                      name="serviceAmountSettingDate"
                      onChange={this.props.serviceAmountSettingDate}
                    ></Input>
                  </Col>
                </Row>

                <Input
                  type="number"
                  placeholder="Enter the percentage"
                  onChange={this.props.onServiceAmountSetupValueChange}
                  value={this.props.serviceAmountSettingValue}
                  style={{ marginBottom: "4px" }}
                ></Input>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
