//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const appointmentService = {        
    getAll,
    add,
    update,
    remove,
    getDateWise,
    getByConsultantId,
    getAppointmentCounts,
    getLastAppointment,
    getSlotWise,
    getBookedSlots,
    UpdateAppointmentStatus,
    setReminder,
    getDepartmentWiseCounts
   };

function getAll(query) {
    const requestOptions = {
        method: 'GET',  
       headers:{ authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/appointments?${query}`, requestOptions).then(helperService.handleResponse);
}
function getDateWise(query) {
    const requestOptions = {
        method: 'GET',  
       headers:{ authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/appointments/consultantByDate?${query}`, requestOptions).then(helperService.handleResponse);
}
function getAppointmentCounts() {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/appointments/getAppointmentCounts`, requestOptions).then(helperService.handleResponse);
}

function getDepartmentWiseCounts(query) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/appointments/getCountByDepartment?${query}`, requestOptions).then(helperService.handleResponse);
}
function getLastAppointment() {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/appointments/getLastAppointment`, requestOptions).then(helperService.handleResponse);
}


function getByConsultantId(id) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()   }
    };
    return fetch(`${apiUrl}/appointments?consultantId=${id}`, requestOptions).then(helperService.handleResponse);
}

function getSlotWise(id,appointment_date) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()}   
    };
    return fetch(`${apiUrl}/appointments/getSlotWise?consultantId=${id}&&appointment_date=${appointment_date}`, requestOptions).then(helperService.handleResponse);
}

function getBookedSlots(id,appointment_date) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()}   
    };
    return fetch(`${apiUrl}/appointments/bookedSlots?consultantId=${id}&&date=${appointment_date}`, requestOptions).then(helperService.handleResponse);
}
function add(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/appointments`, requestOptions).then(helperService.handleResponse);
}

function UpdateAppointmentStatus(data) {

    const requestOptions = {
        method: 'PUT',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/appointments/updateStatus/${data.id}`, requestOptions).then(helperService.handleResponse);
}

function setReminder(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/appointments/reminders`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    console.log(data)
    const requestOptions = {
        method: 'PUT',
        headers:{'Content-Type':'application/json', authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/appointments/${data.id}`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'DELETE',
        headers:{'Content-Type':'application/json', authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/appointments/${data.id}}`, requestOptions).then(helperService.handleResponse);
}