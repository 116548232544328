//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const supportTicketService = {        
    getAll,
    add,
    update,
    remove
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/supportTicket`, requestOptions).then(helperService.handleResponse);
}



function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{"authorization" : helperService.getHeaderData()},   
        body:data
    };

    return fetch(`${apiUrl}/supportTicket`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/supportTicket/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/supportTicket/delete`, requestOptions).then(helperService.handleResponse);
}
