import React, { Component } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, ModalBody, ModalFooter, Row } from 'reactstrap';
import Rodal from 'rodal';



export default class FormValidation extends Component {
  
    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row className="text-center"></Row>
                <Rodal visible={this.props.visible}
                    onClose={this.props.hide}
                   
                >
                    
                    <ModalBody>
                        <p>Please correct the below error and re-submit:</p>
                        <p style={{color: "red"}}>{this.props.errormessage} </p>
                    </ModalBody>
                      
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.hide}>OK</Button>
                        
                    </ModalFooter>
                </Rodal>
            </CSSTransitionGroup>
        )
    }
}