// import * as pharmacyActions from "../PharmacyActionTypes";
let initialState = {
  supplier: {
    selectedSupplier: undefined,
    supplierListTotal: undefined,
    supplierNotification: undefined,
  },
  countryList: undefined,
};

export const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    //     case pharmacyActions.FETCH_SUPPLIER_LIST:
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierListTotal: action.payload,
    //         },
    //       };
    //     case pharmacyActions.SAVE_SELECTED_SUPPLIER:
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           selectedSupplier: state.supplier.supplierListTotal.find(
    //             (ele) => ele._id === action.payload["_id"]
    //           ),
    //         },
    //       };
    //     case pharmacyActions.REMOVE_SELECTED_SUPPLIER:
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           selectedSupplier: undefined,
    //         },
    //       };
    //     case pharmacyActions.ACTIVE_TOGGLE_SELECTED_SUPPLIER:
    //       let supplierToUpdate = state.supplier.supplierListTotal.find(
    //         (supp) => supp._id === action.payload.id
    //       );
    //       supplierToUpdate.active = !supplierToUpdate.active;
    //       let updatedSupplierList = [
    //         ...state.supplier.supplierListTotal,
    //         // supplierToUpdate,
    //       ];
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierListTotal: updatedSupplierList,
    //           supplierNotification: action.payload.notification,
    //         },
    //       };
    //     case pharmacyActions.DELETE_TOGGLE_SUPPLIER:
    //       let supplierToDelete = state.supplier.supplierListTotal.find(
    //         (supp) => supp._id === action.payload.id
    //       );
    //       supplierToDelete.deleted = !supplierToDelete.deleted;
    //       supplierToDelete.active = false;
    //       let updatedSupplierList1 = [
    //         ...state.supplier.supplierListTotal,
    //         // supplierToUpdate,
    //       ];
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierListTotal: updatedSupplierList1,
    //           supplierNotification: action.payload.notification,
    //         },
    //       };
    //     case pharmacyActions.ADD_SUPPLIER_NOTIFICATION:
    //       let updatedSupplierListAdd = [
    //         ...state.supplier.supplierListTotal,
    //         action.payload.newSupplier,
    //       ];
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierListTotal: updatedSupplierListAdd,
    //           supplierNotification: action.payload.notification,
    //         },
    //       };
    //     case pharmacyActions.CLEAR_SUPPLIER_NOTIFICTION:
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierNotification: undefined,
    //         },
    //       };
    //     case pharmacyActions.ADD_NOTIFICATION_ONLY:
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierNotification: action.payload.notification,
    //         },
    //       };
    //     case pharmacyActions.UPDATE_SELECTED_SUPPLIER:
    //       let index = 0;
    //       state.supplier.supplierListTotal.forEach((ele, ind) => {
    //         if (ele._id === action.payload._id) {
    //           index = ind;
    //         }
    //       });
    //       state.supplier.supplierListTotal.splice(
    //         index,
    //         1,
    //         action.payload.updatedSupplier
    //       );
    //       let updatedList = [...state.supplier.supplierListTotal];
    //       return {
    //         ...state,
    //         supplier: {
    //           ...state.supplier,
    //           supplierListTotal: updatedList,
    //           selectedSupplier: action.payload.updatedSupplier,
    //           supplierNotification: action.payload.notification,
    //         },
    //       };
    //     case pharmacyActions.FETCH_COUNTRY_LIST:
    //       return {
    //         ...state,
    //         countryList: action.payload.countryList,
    //       };
    default:
      return state;
  }
};
