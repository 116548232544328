import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const symptomsService = {
  getAll,
  add,
  update,
  remove,
  // get,
  getByDate,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/symptoms`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/symptoms`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/symptoms/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(`${apiUrl}/symptoms/delete/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/symptoms?from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}
