//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const radiologyReportService = {
  getAll,
  createReport,
  updateReport,
  getByDate,
  getStatusCount,
  remove,
  search,
  getCollectionReport,
  getConsolidatedCollectionReport,
  getByUHID,
  getOpPatientReport,
};

function getAll(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/radiologyreports?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function search(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ query: data }),
  };

  return fetch(`${apiUrl}/radiologyreports/search`, requestOptions).then(
    helperService.handleResponse
  );
}
function getCollectionReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ query: data }),
  };

  return fetch(
    `${apiUrl}/radiologyreports/getCollectionReport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByUHID(uhid) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ patient_uhid: uhid }),
  };

  return fetch(`${apiUrl}/radiologyreports/getByUHID`, requestOptions).then(
    helperService.handleResponse
  );
}

function getStatusCount(data) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(`${apiUrl}/radiologyreports/getCounts`, requestOptions).then(
    helperService.handleResponse
  );
}

function getConsolidatedCollectionReport(data) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/radiologyreports/getConsolidated`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/radiologyreports/getByDate/${from}/${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function createReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/radiologyreports/createReport`, requestOptions).then(
    helperService.handleResponse
  );
}
function updateReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/radiologyreports/updateReport`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/radiologyreports/delete`, requestOptions).then(
    helperService.handleResponse
  );
}
function getOpPatientReport(from,to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/radiologyreports/opPatientReport?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}



