import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const bankService = {
  addBank,
  updateBank,
  deleteBank,
  addBranch,
  updateBranch,
  deleteBranch,
  getAll,
  getAllBranches,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/banks`, requestOptions).then(
    helperService.handleResponse
  );
}

function addBank(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/banks`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateBank(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/banks/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteBank(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/banks/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function addBranch(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/Branches`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateBranch(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/Branches/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteBranch(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/banks/deleteBranch`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllBranches(BankId) {
  const url = new URL(`${apiUrl}/Branches`);
  url.searchParams.append("BankId", BankId);
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(url, requestOptions).then(helperService.handleResponse);
}
