import React, { Fragment, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PatientForm from "./Patient/index";
import PatientList from "./PatientList/PatientListTable";
// import PatientList from "./PatientList/PatientList";
import IPDPatientList from "./ViewAllPatients";
import PatientCancelList from "./PatientList/PatientCancelList";
import { ipdManagementActions } from "../../actions/ipdManagementActions";
import { wardManagementActions } from "../../actions/wardmanagement.actions";
import "./index.scss";
import {
  departmentActions,
} from "../../actions";
import { consultantActions } from '../../actions/consultant.actions';

class Ipd extends React.Component {
  componentDidMount() {
    let { dispatch } = this.props;
    dispatch(ipdManagementActions.getAdmTypes());
    dispatch(ipdManagementActions.getNewConsultant());
    dispatch(ipdManagementActions.getReferals());
    dispatch(ipdManagementActions.getInsuranceCompany());
    dispatch(ipdManagementActions.getCorporateCompany());
    dispatch(ipdManagementActions.getWardNames());
    dispatch(ipdManagementActions.getMaster());
    dispatch(wardManagementActions.getAllWardCatagory());
    dispatch(wardManagementActions.getWardAll());
    dispatch(wardManagementActions.getAllWardSubCatagory());
    dispatch(wardManagementActions.getRoomAll());
    dispatch(wardManagementActions.getBedAll());
    dispatch(departmentActions.getAll());
    dispatch(consultantActions.getAll());
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}`}
            component={IPDPatientList}
          />
          <Route
            path={`${this.props.match.url}/ipd-patient-form`}
            component={PatientForm}
          />
          <Route
            path={`${this.props.match.url}/ipd-patient-list`}
            component={PatientList}
          />
          <Route
            path={`${this.props.match.url}/ipd-patient-cancel-list`}
            component={PatientCancelList}
          />
          <Redirect from="*" to={`${this.props.match.url}`}></Redirect>
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { wardManagement } = state;
  return {
    wardbed: wardManagement.wardbed,
  };
}

const connectedIPDList = connect(mapStateToProps)(Ipd);
export default connectedIPDList;
