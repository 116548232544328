import { newMasterConstants } from '../../constants';
import { alertActions } from '../alert.actions';
import { loginservice } from '../../services';
import {
  toast,
  Bounce
} from 'react-toastify';


export const userTypeActions = {
  getAll,
  updateUserType
};

function getAll() {
    return dispatch => {
      dispatch(request());
      loginservice.userType()
        .then(
          data => {
            if (data.success) {
                console.log(data);
              dispatch(success(data.data))
              dispatch(alertActions.success('user type master loaded'))
            }
            else dispatch(failure(data.err))
          },
          error => dispatch(failure(error.toString()))
        );
    };
  
    function request() { return { type: newMasterConstants.GET_ALL_USERTYPE_REQEST } }
    function success(userType) { return { type: newMasterConstants.GET_ALL_USERTYPE_SUCCESS, userType } }
    function failure(error) { return { type: newMasterConstants.GET_ALL_USERTYPE_FAILURE, error } }
  }

  function updateUserType(payload) {
    return dispatch => {
      dispatch(request());
      loginservice.updateuserType(payload)
        .then(
          data => {
            if (data.success) {
                console.log(data);
              dispatch(success(payload,data.data))
              dispatch(alertActions.success('user type master loaded'))
            }
            else dispatch(failure(data.err))
          },
          error => dispatch(failure(error.toString()))
        );
    };
  
    function request() { return { type: newMasterConstants.UPDATE_USER_TYPE_REQUEST } }
    function success(payload,userType) { return { type: newMasterConstants.UPDATE_USER_TYPE_SUCCESS, payload,userType } }
    function failure(error) { return { type: newMasterConstants.UPDATE_USER_TYPE_FAILURE, error } }
  }

