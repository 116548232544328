import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Switch from "react-switch";
import { FormGroup } from "reactstrap";
import CustomButtons from "../DemoPages/Components/customebuttons";
import { useStyles } from "./styles";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import DateRangePicker from "../DemoPages/MyComponents/DateRangePicker";

function CommonTable(props) {
  // Props for the Table
  const {
    handleStatus = () => {},
    handleEdit = () => {},
    handleDelete = () => {},
    handleView = () => {},
    handleLink = () => {},
    paginationPerPage = 25,
    columnHeader = [],
    tableData = [],
    paginationRowsPerPageOptions,
    subHeader = false,
    addButton = false,
    addButtonName = "Add New",
    search = false,
    dateWiseSearch = false,
    handleDateSearch = () => {},
    handleTextSearch = () => {},
    handleHeaderButton = () => {},
    customStyles = {},
    handleNumberField = () => {},
    pagination = true,
    handleTextFieldChange = () => {},
    striped = false,
  } = props;
  const classes = useStyles();
  const [date, setDate] = useState();
  const [searchData, setSearchData] = useState("");

  // Handle the Actions Delete, Edit and View
  const handleActions = (type, data, switch_type, index) => {
    if (type === "edit") {
      handleEdit(data, switch_type);
    } else if (type === "delete" || type === "restore") {
      handleDelete(data, switch_type, index);
    } else {
      handleView(data, switch_type);
    }
  };

  // Table data Alignment
  const getData = (tableData) => {
    return tableData.map((val, i) => {
      return { sr: i + 1, ...val };
    });
  };

  // Table Cell Loaded based on the Data type
  const getColumns = (column) => {
    let data = [];
    column.forEach((val, index) => {
      if (val?.type === "SRNO") {
        data.push({ ...val, selector: "sr" });
      } else if (val?.type === "NON_EDIT_TEXTFIELD") {
        data.push({
          ...val,
          selector: (data) => {
            if (data[val?.changeType]) {
              return (
                <TextField
                  name={data[val?.changeType]}
                  onChange={(e) =>
                    handleTextFieldChange(e.target.value, data[val?.changeType])
                  }
                  // value={data[val?.field]}
                />
              );
            } else {
              return (
                <div>
                  <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    prefix={"₹"}
                    value={data[val?.field]}
                    disabled={data[val?.edit] === "yes" ? false : true}
                    onValueChange={
                      data[val?.edit] === "yes"
                        ? (e) => handleNumberField(e, data[val?.props])
                        : () => {}
                    }
                  />
                </div>
              );
            }
          },
        });
      } else if (val?.type === "TEXT") {
        data.push({
          ...val,
          selector: (data) => {
            return data[val?.field] ? data[val?.field] : "-";
          },
        });
      } else if (val?.type === "DATE") {
        data.push({
          ...val,
          selector: (data) => {
            return data[val?.field]
              ? moment(data[val?.field]).format("DD/MM/YYYY HH:mm:ss")
              : "-";
          },
        });
      } else if (val?.type === "HYPERLINK") {
        data.push({
          ...val,
          selector: (data) => {
            return (
              <div
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={() => handleLink(data)}
              >
                {data[val?.field]}
              </div>
            );
          },
        });
      } else if (val?.type === "NUMBER") {
        data.push({ ...val, selector: val?.field });
      } else if (val?.type === "AMOUNT") {
        data.push({
          ...val,
          selector: (data) => {
            return (
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                displayType={"text"}
                value={data[val?.field]}
              />
            );
          },
        });
      } else if (val?.type === "STATUS") {
        data.push({
          ...val,
          selector: (data) => (
            <FormGroup>
              <Switch
                onChange={(e) => {
                  handleStatus(data, data?.index, data?.switch_type);
                }}
                checked={data[val?.field]}
                // disabled={data.active}
                className="mr-2 mb-2"
                id="normal-switch"
              />
            </FormGroup>
          ),
        });
      } else if (val?.type === "ACTIONS") {
        data.push({
          ...val,
          selector: (data, index) => {
            return val.field.map((item) => {
              if (item !== "delete") {
                return (
                  <CustomButtons
                    type={item}
                    disabled={data.isDeleted}
                    onClickHandler={() => {
                      handleActions(item, data, data?.switch_type);
                    }}
                  />
                );
              } else {
                if (data?.isDeleted === true) {
                  return (
                    <CustomButtons
                      type={"restore"}
                      disabled={!data.isDeleted}
                      onClickHandler={() => {
                        handleActions(item, data, data?.switch_type, index);
                      }}
                    />
                  );
                } else {
                  return (
                    <CustomButtons
                      type={item}
                      disabled={data.isDeleted}
                      onClickHandler={() => {
                        handleActions(item, data, data?.switch_type, index);
                      }}
                    />
                  );
                }
              }
            });
          },
        });
      } else {
        data.push(val);
      }
    });
    return data;
  };

  // handle Date
  const onDateChange = (e) => {
    const target = e.target;
    setDate({ startDate: target.value.start, endDate: target.value.end });
  };

  // Handle date type
  const handleDate = () => {
    const { startDate, endDate } = date;
    if (startDate && endDate) {
      const from = moment(startDate).format("YYYY-MM-DD");
      const to = moment(endDate).format("YYYY-MM-DD");
      handleDateSearch(from, to);
    }
  };

  // Handle Search based on Date
  const handleChange = (value) => {
    if (value.length < 1) {
      if (date?.startDate && date?.endDate) {
        const from = moment(date?.startDate).format("YYYY-MM-DD");
        const to = moment(date?.endDate).format("YYYY-MM-DD");
        handleTextSearch(from, to, searchData);
      }
    }
    setSearchData(value);
  };

  // Handle Search based on Text field
  const onFieldSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (date?.startDate && date?.endDate) {
        const from = moment(date?.startDate).format("YYYY-MM-DD");
        const to = moment(date?.endDate).format("YYYY-MM-DD");
        handleTextSearch(from, to, searchData);
      }
    }
  };

  return (
    <>
      <DataTable
        columns={getColumns(columnHeader)}
        data={getData(tableData)}
        pagination={pagination}
        dense
        noHeader
        paginationPerPage={paginationPerPage || 25}
        paginationRowsPerPageOptions={
          paginationRowsPerPageOptions || [15, 25, 50, 75, 100]
        }
        striped={striped}
        style={{
          minHeight: props.minHeight || "none",
          backgroundColor: "#fff",
        }}
        customStyles={customStyles}
        subHeader={subHeader}
        subHeaderComponent={
          <div
            style={{ padding: "5px", backgroundColor: "rgb(214, 240, 214)" }}
          >
            <Grid container spacing={2} style={{ alignItems: "end" }}>
              {search && (
                <Grid item md={addButton ? 3 : 4} style={{}}>
                  <Typography className={classes.labelHeader}>
                    SEARCH
                  </Typography>
                  <TextField
                    id="outlined-size-small"
                    value={searchData}
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    className={classes.textFieldOutline}
                    fullWidth={true}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyPress={(e) => onFieldSearch(e)}
                  />
                </Grid>
              )}
              <Grid item md={addButton ? 4 : 6}>
                {dateWiseSearch && (
                  <DateRangePicker
                    name="dateRange"
                    onChange={(e) => onDateChange(e)}
                    onSubmit={() => handleDate()}
                    startDate={date?.startDate}
                    endDate={date?.endDate}
                  />
                )}
              </Grid>
              <Grid item md={addButton ? 3 : 2}></Grid>
              {addButton && (
                <Grid
                  item
                  md={2}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", marginRight: "6px" }}
                    onClick={() => handleHeaderButton()}
                  >
                    {addButtonName}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        }
      />
    </>
  );
}

export default CommonTable;
