import {initialAssesmentMaster} from '../constants';
import { closestIndexTo } from 'date-fns';
export function initialAssesment(state = {}, action) {

        switch (action.type) {
                case initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_REQEST:
                  return { ...state, loading: true };
                case initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_SUCCESS:
                  return { ...state, 
                    advice: action.data.data.advice, 
                    ccm:action.data.data.ccm,
                    obs : action.data.data.obs,
                    pahm : action.data.data.pahm,
                    pehm : action.data.data.pehm,
                    history :  action.data.data.history,
                    gd : action.data.data.gd,
                    sd : action.data.data.sd,
                    loading: false };
                case initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_FAILURE:
                  return { ...state, error: action.error, loading: false };

                  case initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_REQUEST:
                    return { ...state, loading: true };
                  case initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_SUCCESS:
                    let  advice = state.advice?[...state.advice]:[];
                let  history = state.history?[...state.history]: [];
                let  ccm = state.history?[...state.ccm]:[];
                let gd = state.gd?[...state.gd]:[];
                let sd = state.sd? [...state.sd]:[];
                let count = 0;
                  if(action.changetype==="ccm"){
                    ccm.forEach((data,index)=>{
                      if(data._id === action.data._id){
                        ccm[index] = action.data;
                        //ccm.push(action.data);
                        count=1;
                       
                      }
                    })
                    if(count<1){
                      ccm.push(action.data)
                    }
                    
                  }
                  else if(action.changetype==="history"){
                     history.forEach((data,index)=>{
                      if(data._id === action.data._id){
                        history[index] = action.data;
                        //history.push(action.data);
                        count=1;
                      }
                    })
                    if(count<1){
                        history.push(action.data);
                    }
                  }
                  else if(action.changetype === "advice"){
                    advice.forEach((data,index)=>{
                      if(data._id === action.data._id){
                        advice[index]= action.data;
                         // advice.push(action.data);
                          count = 1;
                      }
                    });
                    if(count<1){
                      advice.push(action.data)
                    }
                    
                  }
                  else if(action.changetype === "generalexamination" ){
                    gd.forEach((data,index)=>{
                      if(data._id === action.data._id){
                        gd[index]= action.data;
                         // advice.push(action.data);
                          count = 1;
                      }
                    });
                    if(count<1){
                      gd.push(action.data)
                    }
                   
                  }
                  else if(action.changetype === "systemetic" ){
                    sd.forEach((data,index)=>{
                      if(data._id === action.data._id){
                        sd[index]= action.data;
                         // advice.push(action.data);
                          count = 1;
                      }
                    });
                    if(count<1){
                      sd.push(action.data)
                    }
                  }
                  return { ...state, 
                    advice: advice,
                    history: history,
                    ccm : ccm,
                    gd : gd,
                    sd : sd,
                    loading: false };
                  case initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_FAILURE:
                    return { ...state, error: action.error, loading: false };
  


                    case initialAssesmentMaster.DELETE_INITIALASSESMENT_REQUEST:
                      return { ...state, loading: true };
                    case initialAssesmentMaster.DELETE_INITIALASSESMENT_SUCCESS:
                    let  advicedelte = [...state.advice];
                   let historydelte = [...state.history];
                   let ccmdelte = [...state.ccm];
                   let generalDelta = [...state.gd];
                   let systemDelta = [...state.sd];
                  if(action.changetype==="ccm"){
                    ccmdelte.forEach((data,index)=>{
                      if(data._id === action._id){
                        ccmdelte[index].isDeleted = !ccmdelte[index].isDeleted
                      }
                    })
                   
                  }
                  else if(action.changetype==="history"){
                    historydelte.forEach((data,index)=>{
                      if(data._id === action._id){
                        historydelte[index].isDeleted = !historydelte[index].isDeleted
                      }
                    })
                  }
                  else if(action.changetype === "advice"){
                    advicedelte.forEach((data,index)=>{
                      if(data._id === action._id){
                        advicedelte[index].isDeleted = !advicedelte[index].isDeleted;
                      }
                    });
                  }
                  else if(action.changetype === "generalexamination"){
                    generalDelta.forEach((data,index)=>{
                      if(data._id === action._id){
                        generalDelta[index].isDeleted = !generalDelta[index].isDeleted;
                      }
                    });
                  }
                  else if(action.changetype === "systemetic"){
                    systemDelta.forEach((data,index)=>{
                      if(data._id === action._id){
                        systemDelta[index].isDeleted = !systemDelta[index].isDeleted;
                      }
                    });
                  }

                  return { ...state, 
                    advice: advicedelte,
                    history: historydelte,
                    ccm : ccmdelte,
                    gd : generalDelta,
                    sd: systemDelta,
                    loading: false };
                case initialAssesmentMaster.DELETE_INITIALASSESMENT_FAILURE:
                      return { ...state, error: action.error, loading: false };
                case initialAssesmentMaster.ADD_IVF_REQUEST:
                  return { ...state, loading: true };
                  case initialAssesmentMaster.ADD_IVF_SUCCESS:
                  return {...state, loading: false, ivfs: action.data.ivf};
                  case initialAssesmentMaster.ADD_IVF_FAILURE:
                    return { ...state, loading: false };
                  
                   case initialAssesmentMaster.ADD_IVF_FORM_REQUEST:
                  return { ...state, loading: true };
                  case initialAssesmentMaster.ADD_IVF_FORM_SUCCESS:
                  return {...state, loading: false, ivfForm: action.data};
                  case initialAssesmentMaster.ADD_IVF_FORM_FAILURE:
                    return { ...state, loading: false };

                    
                   case initialAssesmentMaster.UPDATE_FORM_REQUEST:
                  return { ...state, loading: true };
                  case initialAssesmentMaster.UPDATE_FORM_SUCCESS:
                  return {...state, loading: false, ivfForm: action.data};
                  case initialAssesmentMaster.UPDATE_FORM_FAILURE:
                    return { ...state, loading: false };
                  
                    case initialAssesmentMaster.GET_IVF_REQUEST:
                      return { ...state, loading: true };
                      case initialAssesmentMaster.GET_IVF_SUCCESS:
                      return {...state, loading: false, ivfs: action.data};
                      case initialAssesmentMaster.GET_IVF_FAILURE:
                        return { ...state, loading: false };

                        case initialAssesmentMaster.DELETE_IVF_REQUEST:
                          return { ...state, loading: true };
                          case initialAssesmentMaster.DELETE_IVF_SUCCESS:
                            let deleteIvf = [...state.ivfs];
                            if(deleteIvf){
                              deleteIvf.forEach((data,index)=>{
                                if(data._id === action._id){
                                  deleteIvf[index].isDeleted = !deleteIvf[index].isDeleted
                                }
                              });
                            }
                          return {...state, loading: false, ivfs: deleteIvf};
                          case initialAssesmentMaster.DELETE_IVF_FAILURE:
                            return { ...state, loading: false };
                            
                   case initialAssesmentMaster.GET_FORM_REQUEST:
                      return { ...state, loading: true };
                      case initialAssesmentMaster.GET_FORM_SUCCESS:
                      return {...state, loading: false, formData: action.data};
                      case initialAssesmentMaster.GET_FORM_FAILURE:
                        return { ...state, loading: false };

                        case initialAssesmentMaster.DELETE_FORM_REQUEST:
                          return { ...state, loading: true };
                          case initialAssesmentMaster.DELETE_FORM_SUCCESS:
                            let deleteFormData = [...state.formData];
                            if(deleteFormData){
                              deleteFormData.forEach((data,index)=>{
                                if(data._id === action._id){
                                  deleteFormData[index].isDeleted = !deleteFormData[index].isDeleted;
                                }
                              })
                            }
                          return {...state, loading: false, formData: deleteFormData};
                          case initialAssesmentMaster.DELETE_FORM_FAILURE:
                            return { ...state, loading: false };


                  case initialAssesmentMaster.GET_LATEST_INITIALASSESMENT_REQUEST:
                      return { ...state, loading: true };
                  case initialAssesmentMaster.GET_LATEST_INITIALASSESMENT_SUCCESS:
                      return {...state, loading: false, selectedInitialAssesment: action.data};
                  case initialAssesmentMaster.GET_LATEST_INITIALASSESMENT_FAILURE:
                      return { ...state, loading: false };
                      case initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_REQUEST:
                        return { ...state, loading: true };
                    case initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_SUCCESS:
                        return {...state, loading: false, laboratorydata: action.data};
                    case initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_FAILURE:
                        return { ...state, loading: false };
                        case initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_REQUEST :
                          return { ...state, loading: true };
                      case initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_SUCCESS:
                          return {...state, loading: false, radiologydata: action.data};
                      case initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_FAILURE :
                          return { ...state, loading: false };
                  default:
                    return state
        }
    }

    