import React, { Component } from "react";
import { Table, CustomInput } from "reactstrap";
import { connect } from "react-redux";

class OTCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charges_list: [],
      ot_list: [],
      ward_list: [],
    };
  }
  componentDidMount() {
    this.setState({
      ot_list: [
        { name: "OT1", code: "ot1" },
        { name: "OT2", code: "ot2" },
      ],
    });
    this.setState({
      ward_list: [
        { name: "Ward1", code: "ward1" },
        { name: "Ward2", code: "ward2" },
      ],
    });
    const { charges_list } = this.props;
    this.setState({ charges_list: charges_list });
  }

  componentDidUpdate(pp, ps) {
    const { charges_list } = this.props;
    if (charges_list) {
      if (pp.charges_list.length !== ps.charges_list.length) {
        this.setState({ charges_list: charges_list });
      }
    }
  }

  getConsultantOrWardOrOt = (item) => {
    let val, exist;
    const { consultants } = this.props;
    const { ot_list, ward_list } = this.state;
    switch (item.charges_type) {
      case "Ward Charges":
        exist = ward_list.find((wd) => wd.code === item.ward);
        return exist ? exist.name : "";
      case "OT Charges":
        exist = ot_list.find((ot) => ot.code === item.ot);
        return exist ? exist.name : "";
      case "Basic Investigations":
        return "";
      default:
        exist = consultants.find((cons) => cons.code === item.consultant);
        return exist ? exist.firstname : "";
    }
  };

  render() {
    const { consultants } = this.props;
    const { charges_list, ot_list, ward_list } = this.state;

    return (
      <Table size="sm" className="ot-booking-table">
        <thead>
          <th>Sno.</th>
          <th>Service Name</th>
          <th>Ward/Consultant</th>
          <th>Amount</th>
          <th>Amount %</th>
        </thead>
        <tbody>
          {charges_list &&
            charges_list.map((item, ind) => (
              <tr>
                <td>{ind + 1}</td>
                <td>{item.charges_type}</td>
                <td>{this.getConsultantOrWardOrOt(item)}</td>
                <td>{item.amount}</td>
                <td>{item.amount_percent}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, serviceMaster } = state;

  return {
    consultants: consultants.consultants,
    packageData: serviceMaster.data,
  };
}

const connectedOTCharges = connect(mapStateToProps)(OTCharges);
export default connectedOTCharges;
