import { newMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { accessService, accessListService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const accessGroupActions = {
  getAll,
  add,
  update,
  remove,
  updateStatus,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    accessService.getAll().then(
      (data) => {
        if (data.success) {
          dispatch(success(data.data));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success("accessgroup master loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.GET_ALL_ACCESSGROUP_REQUEST };
  }
  function success(accessgroup) {
    return {
      type: newMasterConstants.GET_ALL_ACCESSGROUP_SUCCESS,
      accessgroup,
    };
  }
  function failure(error) {
    return { type: newMasterConstants.GET_ALL_ACCESSGROUP_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    accessService.add(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          dispatch(alertActions.success(result.message));
        } else {
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.ADD_ACCESSGROUP_REQUEST };
  }
  function success(accessgroup) {
    return { type: newMasterConstants.ADD_ACCESSGROUP_SUCCESS, accessgroup };
  }
  function failure(error) {
    return { type: newMasterConstants.ADD_ACCESSGROUP_FAILURE, error };
  }
}

function update(data) {
  return (dispatch) => {
    dispatch(request());
    accessService.update(data).then(
      (data) => {
        if (data.success) {
          console.log(data);
          dispatch(success(data.data));
          dispatch(alertActions.success("accessgroup master loaded"));
        } else {
          dispatch(failure(data.err));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.UPDATE_ACCESSGROUP_REQUEST };
  }
  function success(accessgroup) {
    return { type: newMasterConstants.UPDATE_ACCESSGROUP_SUCCESS, accessgroup };
  }
  function failure(error) {
    return { type: newMasterConstants.UPDATE_ACCESSGROUP_FAILURE, error };
  }
}

function updateStatus(data) {
  return (dispatch) => {
    dispatch(request());
    accessService.updateStatus(data).then(
      (data) => {
        if (data.success) {
          console.log(data);
          dispatch(success(data.data));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success("accessgroup master loaded"));
        } else {
          dispatch(failure(data.err));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.UPDATE_STATUS_ACCESSGROUP_REQUEST };
  }
  function success(accessgroup) {
    return {
      type: newMasterConstants.UPDATE_STATUS_ACCESSGROUP_SUCCESS,
      accessgroup,
    };
  }
  function failure(error) {
    return {
      type: newMasterConstants.UPDATE_STATUS_ACCESSGROUP_FAILURE,
      error,
    };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    accessService.remove(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: newMasterConstants.DELETE_ACCESSGROUP_REQUEST };
  }
  function success(accessgroup) {
    return { type: newMasterConstants.DELETE_ACCESSGROUP_SUCCESS, accessgroup };
  }
  function failure(error) {
    return { type: newMasterConstants.DELETE_ACCESSGROUP_FAILURE, error };
  }
}
