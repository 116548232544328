import { CorporateConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { corporateConsultantService, corporateService, cor } from "../services";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";

export const corporateMasterActions = {
  add,
  getAll,
  deleteUser,
  update,
  addConsFees,
  updateConsFees,
  deleteConsFees,
  getConsFeesAll,
};

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateService.add(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.ADD_CORPORATE_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.ADD_CORPORATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: CorporateConstants.ADD_CORPORATE_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.UPDATE_CORPORATE_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.UPDATE_CORPORATE_FAILURE, data };
  }
  function failure(error) {
    return { type: CorporateConstants.UPDATE_CORPORATE_SUCCESS, error };
  }
}

function deleteUser(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateService.deleteMaster(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.DELETE_CORPORATE_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.DELETE_CORPORATE_REQUEST, data };
  }
  function failure(error) {
    return { type: CorporateConstants.DELETE_CORPORATE_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    corporateService.getAll().then(
      (result) => {
        console.log(result, "result");
        if (result.success) {
          dispatch(success(result?.corporates));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "error",
          // });
        }
      },
      (error) => {
        // toast(error.message, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 5000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.GET_ALL_CORPORATE_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.GET_ALL_CORPORATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: CorporateConstants.GET_ALL_CORPORATE_FAILURE, error };
  }
}

function addConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateConsultantService.add(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.ADD_CORPORATE_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.ADD_CORPORATE_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: CorporateConstants.ADD_CORPORATE_CONS_FEES_FAILURE, error };
  }
}

function updateConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateConsultantService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.UPDATE_CORPORATE_CONS_FEES_REQUEST };
  }
  function success(data) {
    return {
      type: CorporateConstants.UPDATE_CORPORATE_CONS_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: CorporateConstants.UPDATE_CORPORATE_CONS_FEES_FAILURE,
      error,
    };
  }
}

function deleteConsFees(payload) {
  return (dispatch) => {
    dispatch(request());

    corporateConsultantService.deleteMaster(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.DELETE_CORPORATE_CONS_FEES_REQUEST };
  }
  function success(data) {
    return {
      type: CorporateConstants.DELETE_CORPORATE_CONS_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: CorporateConstants.DELETE_CORPORATE_CONS_FEES_FAILURE,
      error,
    };
  }
}

function getConsFeesAll() {
  return (dispatch) => {
    dispatch(request());

    corporateConsultantService.getAll().then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: CorporateConstants.GET_CORPORATE_CONS_FEES_REQUEST };
  }
  function success(data) {
    return { type: CorporateConstants.GET_CORPORATE_CONS_FEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: CorporateConstants.GET_CORPORATE_CONS_FEES_FAILURE, error };
  }
}
