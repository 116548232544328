import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Row, Card, CardBody,
  CardTitle, Form, FormGroup, Label, Input, CustomInput, InputGroup, InputGroupAddon
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nestedModal: false,
      closeAll: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }


    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleNested() {
      this.setState({
        nestedModal: !this.state.nestedModal,
        closeAll: false
      });
    }
    toggleAll() {
      this.setState({
        nestedModal: !this.state.nestedModal,
        closeAll: true
      });
    }
    render() {
      const removebuttons = (
        <FormGroup>
          <Col sm={10}>
            <Button>Remove</Button>
          </Col>
        </FormGroup>
      );

      const ServiceAmount = (
        <CustomInput
          type="number"
          id="ServiceAmount"
          name="ServiceAmount"
          defaultValue="10"
        />
      );

      const NewAmount = (
        <CustomInput
          type="number"
          id="NewAmount"
          name="NewAmount"
          defaultValue="15"
        />
      );

      const products = [
        {
          sno: "1",
          servicename: "Complete Blood Count (CBC)",
          serviceamount: ServiceAmount,
          newamount: NewAmount,
          active: <CustomInput type="checkbox" id="CBC" name="CBC" label="" />
        },
        {
          sno: "2",
          servicename: "Complete Haemogram",
          serviceamount: ServiceAmount,
          newamount: NewAmount,
          active: <CustomInput type="checkbox" id="CompleteHaemogram" name="CompleteHaemogram" label="" />
        },
        {
          sno: "3",
          servicename: "Liver Function Test",
          serviceamount: ServiceAmount,
          newamount: NewAmount,
          active: <CustomInput type="checkbox" id="LiverFunctionTest" name="LiverFunctionTest" label="" />
        },
        {
          sno: "4",
          servicename: "Urine Analysis",
          serviceamount: ServiceAmount,
          newamount: NewAmount,
          active: <CustomInput type="checkbox" id="UrineAnalysis" name="UrineAnalysis" label="" />
        }
      ];
      const columns = [
        {
          dataField: "sno",
          text: "SI.No",
          sort: true
        },
        {
          dataField: "servicename",
          text: "Service Name",
          sort: true
        },
        {
          dataField: "serviceamount",
          text: "Service Amount",
          sort: true
        },
        {
          dataField: "newamount",
          text: "New Amount",
          sort: true
        },
        {
          dataField: "active",
          text: "Active",
          sort: true
        }
      ];
      const defaultSorted = [
        {
          dataField: "name",
          order: "ASC"
        }
      ];


      const ModalClasses = `${this.props.className} uploadmodal`;
        return (
            <span className="d-inline-block mb-2 mr-2">
                <Button outline color="primary" onClick={this.toggle}>Group Mapping</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={ModalClasses}>
                    <ModalHeader toggle={this.toggle}>Groups Mapping</ModalHeader>
                    <ModalBody>
                    <div className="table-responsive">
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={products}
                        columns={columns}
                        defaultSorted={defaultSorted}
                      />
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <Button color="primary" onClick={this.toggle}>Submit</Button>{' '}
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}

export default ModalExample;
