
    export const laboratoryMasterConstants={

      GET_ALL_REQUEST:'GET_ALL_LAB_MASTER_REQUEST',
      GET_ALL_SUCCESS:'GET_ALL_LAB_MASTER_SUCCESS',
      GET_ALL_FAILURE:'GET_ALL_LAB_MASTER_FAILURE',   
      
      GET_ACTIVE_REQUEST:'GET_ACTIVE_LAB_MASTER_REQUEST',
      GET_ACTIVE_SUCCESS:'GET_ACTIVE_LAB_MASTER_SUCCESS',
      GET_ACTIVE_FAILURE:'GET_ACTIVE_LAB_MASTER_FAILURE', 

      ADD_REQUEST:'ADD_LAB_MASTER_REQUEST',
      ADD_SUCCESS:'ADD_LAB_MASTER_SUCCESS',
      ADD_FAILURE:'ADD_LAB_MASTER_FAILURE',  

      DELETE_REQUEST:'DELETE_LAB_MASTER_REQUEST',
      DELETE_SUCCESS:'DELETE_LAB_MASTER_SUCCESS',
      DELETE_FAILURE:'DELETE_LAB_MASTER_FAILURE',  

      UPDATE_REQUEST:'UPDATE_LAB_MASTER_REQUEST',
      UPDATE_SUCCESS:'UPDATE_LAB_MASTER_SUCCESS',
      UPDATE_FAILURE:'UPDATE_LAB_MASTER_FAILURE', 

      SET_DEFAULT_REQUEST:'SET_DEFAULT_LAB_MASTER_REQUEST',
      SET_DEFAULT_SUCCESS:'SET_DEFAULT_LAB_MASTER_SUCCESS',
      SET_DEFAULT_FAILURE:'SET_DEFAULT_LAB_MASTER_FAILURE', 

      CLEAR:'CLEAR'
       
       
    
    }