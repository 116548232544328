import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from "classnames";
import moment from 'moment';
import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import {
  Button, Col,
  DropdownMenu, DropdownToggle,
  ListGroup,
  ListGroupItem, Row,
  TabContent,
  TabPane, UncontrolledButtonDropdown
} from "reactstrap";
import { consultantActions } from "../../../actions/consultant.actions";
import Loader from '../../MyComponents/loader';
import BookingItem from '../../OperationTheatre/BookingMaster/TodaySchedule/BookingItem';

class TodaySchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      allBookings:[]
    };
  }

  toggle(val) {
    this.setState({ activeTab: val });
  }

  onSelectBooking(id,e)
  {
if(this.props.onBookingSelected)
{
  this.props.onBookingSelected({id:id})
}
  }
  onDoubleClick(id,e)
{
if(this.props.onDoubleClick)
{
this.props.onDoubleClick(id)
}
}

  componentDidMount() {
    const { dispatch } = this.props;

   dispatch(consultantActions.getAll());
  }
  render() {
    const { bookings,consultants } = this.props;
    let todaysBookings=[],waitingBookings=[],engagedBookings=[],doneBookings=[],abandonedBookings=[],walkInBookings=[];

bookings.forEach(item=>
  {
  let element= <BookingItem booking={item}  onClick={this.onSelectBooking.bind(this,item)} onDoubleClick={this.onDoubleClick.bind(this,item)}/>
    todaysBookings.push(element);
    if(item.appointment_type==="Walk In")
    {
      walkInBookings.push(element)
    }
    switch(item.status)
    {
case 'WAITING':
  waitingBookings.push(element)
  break;
  case 'ENGAGED':
    engagedBookings.push(element)
    break;
    case 'DONE':
      doneBookings.push(element)
      break;
      case 'CANCELLED':
        abandonedBookings.push(element)
        break;
    }
    }
  )  

    return (
      <div style={{border:'2px solid #E0F3FF'}}>
        <h6 style={{textAlign:'center',padding:'10px 5px',backgroundColor:'#545CD8',color:'#ffffff'}}>OT Bookings</h6>

        <Col md={12}>
<div className="scroll-area-lg">
 
  {this.props.loading?
<Loader/>:
<PerfectScrollbar>
          <div>          
              <ListGroup>
                {todaysBookings}
            
              {
                waitingBookings
                }
              {
                engagedBookings
              }
           
              {doneBookings}
             
              {abandonedBookings}
           
              {walkInBookings}
           </ListGroup>
          </div>
          
          </PerfectScrollbar>
         } </div>  
          </Col>
      </div>
   
    );
  }
}

function mapStateToProps(state) {
  const { bookings,consultants } = state;

  return { bookings: bookings.bookings,loading:bookings.loading, consultants:consultants.consultants };
}

const connectedTodaySchedule = connect(mapStateToProps)(TodaySchedule);
export default connectedTodaySchedule;