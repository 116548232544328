export const styles = () => ({
  apointmentIndicators: {
    padding: "8px 5px",
  },
  allDoctorsList: {
    cursor: "pointer",
    padding: "8px 5px",
  },
  consultantList: {
    padding: 0,
    paddingRight: "5px",
    cursor: "pointer",
  },
  consultantImage: {
    borderRadius: "50% !important",
    width: 40,
    height: 40,
    border: "1px solid grey",
  },
  consultantOnline: {
    top: "5px !important",
    right: "-4px !important",
    backgroundColor: "green",
  },
  consultantOffline: {
    top: "5px !important",
    right: "-4px !important",
  },
  widgetContent: {
    fontSize: "12px !important",
    marginLeft: "-10px !important",
  },
  patientWidget: {
    padding: "0px 5px",
    cursor: "pointer",
  },
  widgetAlign: {
    fontSize: 12,
    paddingLeft: 10,
  },
  appointmentPopImage: {
    width: "50px !important",
    height: "auto !important",
    borderRadius: "50% !important",
  },
  appointmentEdit: {
    borderRadius: "50%",
    backgroundColor: "#5a9ece",
    color: "#fff",
    padding: "5px 8px",
  },
});
