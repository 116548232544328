import { alertActions } from './alert.actions';
import {initialAssesmentMasterService} from '../services';
import {history} from '../helpers/history';
import {initialAssesmentMaster} from '../constants';

import {
  toast,
  Bounce
} from 'react-toastify';

export const initialAssesmentMasterAction = {
  getAll,
  add,
  addIVF,
  addIVFForm,
  getIVF,
  getFormData,
  updateForm,
  deleteMaster,
  deleteIVFMaster,
  deleteInitialForm,
  getLaboratoryData,
  getRadiologyData,
  getAllByDateRange
};

function getAllByDateRange(payload) {
  return dispatch => {
      dispatch(request());

      initialAssesmentMasterService.getAllByDateRange(payload)
          .then(
              result =>{
                if(result.success)
                {
                  dispatch(success(result.data));
                  
                  dispatch(alertActions.success(result.message))
                }
                 else
                 {
                    dispatch(failure(result.err))
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'error'
                  });
                 }
                },
              error => 
              {
                toast(error.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 5000,
                  position: 'bottom-center',
                  type: 'error'
              });
                dispatch(failure(error.toString()))
              }
          );
  };

  function request() { return { type: initialAssesmentMaster.GET_FORM_REQUEST  } }
  function success(data) { return { type: initialAssesmentMaster.GET_FORM_SUCCESS,data } }
  function failure(error) { return { type: initialAssesmentMaster.GET_FORM_SUCCESS, error } }
}

function getIVF() {
  return dispatch => {
      dispatch(request());

      initialAssesmentMasterService.getIVF()
          .then(
              result =>{
                if(result.success)
                {
                  dispatch(success(result.ivfs));
                  dispatch(alertActions.success(result.message))
                }
                 else
                 {
                    dispatch(failure(result.err))
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'error'
                  });
                 }
                },
              error => 
              {
                toast(error.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 5000,
                  position: 'bottom-center',
                  type: 'error'
              });
                dispatch(failure(error.toString()))
              }
          );
  };

  function request() { return { type: initialAssesmentMaster.GET_IVF_REQUEST  } }
  function success(data) { return { type: initialAssesmentMaster.GET_IVF_SUCCESS,data } }
  function failure(error) { return { type: initialAssesmentMaster.GET_IVF_FAILURE, error } }
}

function addIVFForm(data){
   return dispatch => {
      dispatch(request());

      initialAssesmentMasterService.addInitialForm(data)
          .then(
              result =>{
                if(result.success)
                {
                  dispatch(success(result.data));
                  toast(result.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'success'
                });
                  dispatch(alertActions.success(result.message))
                }
                 else
                 {
                    dispatch(failure(result.err))
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'error'
                  });
                 }
                },
              error => 
              {
                toast(error.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 5000,
                  position: 'bottom-center',
                  type: 'error'
              });
                dispatch(failure(error.toString()))
              }
          );
  };

  function request() { return { type: initialAssesmentMaster.ADD_IVF_FORM_REQUEST  } }
  function success(data) { return { type: initialAssesmentMaster.ADD_IVF_FORM_REQUEST,data } }
  function failure(error) { return { type: initialAssesmentMaster.ADD_IVF_FORM_REQUEST, error } }
}

function updateForm(data){
   return dispatch => {
      dispatch(request());

      initialAssesmentMasterService.updateInitialForm(data)
          .then(
              result =>{
                if(result.success)
                {
                  dispatch(success(result.data));
                  toast(result.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'success'
                });
                  dispatch(alertActions.success(result.message))
                }
                 else
                 {
                    dispatch(failure(result.err))
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'error'
                  });
                 }
                },
              error => 
              {
                toast(error.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 5000,
                  position: 'bottom-center',
                  type: 'error'
              });
                dispatch(failure(error.toString()))
              }
          );
  };

  function request() { return { type: initialAssesmentMaster.UPDATE_FORM_REQUEST  } }
  function success(data) { return { type: initialAssesmentMaster.UPDATE_FORM_SUCCESS,data } }
  function failure(error) { return { type: initialAssesmentMaster.UPDATE_FORM_FAILURE, error } }
}





function getLaboratoryData(data){
  return dispatch => {
     dispatch(request());

     initialAssesmentMasterService.getLaboratoryData(data)
         .then(
             result =>{
               if(result.success)
               {
                 dispatch(success(result.testlist));
                 toast(result.message, {
                   transition: Bounce,
                   closeButton: true,
                   autoClose: 5000,
                   position: 'bottom-center',
                   type: 'success'
               });
                 dispatch(alertActions.success(result.message))
               }
                else
                {
                   dispatch(failure(result.err))
                   toast(result.message, {
                     transition: Bounce,
                     closeButton: true,
                     autoClose: 5000,
                     position: 'bottom-center',
                     type: 'error'
                 });
                }
               },
             error => 
             {
               toast(error.message, {
                 transition: Bounce,
                 closeButton: true,
                 autoClose: 5000,
                 position: 'bottom-center',
                 type: 'error'
             });
               dispatch(failure(error.toString()))
             }
         );
 };

 function request() { return { type: initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_REQUEST  } }
 function success(data) { return { type: initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_SUCCESS,data } }
 function failure(error) { return { type: initialAssesmentMaster.GET_LABORATORY_DATA_BY_UHID_FAILURE, error } }
}


function getRadiologyData(data){
  return dispatch => {
     dispatch(request());
     initialAssesmentMasterService.getRadiologyData(data)
         .then(
             result =>{
               if(result.success)
               {
                 dispatch(success(result.testlist));
                 toast(result.message, {
                   transition: Bounce,
                   closeButton: true,
                   autoClose: 5000,
                   position: 'bottom-center',
                   type: 'success'
               });
                 dispatch(alertActions.success(result.message))
               }
                else
                {
                   dispatch(failure(result.err))
                   toast(result.message, {
                     transition: Bounce,
                     closeButton: true,
                     autoClose: 5000,
                     position: 'bottom-center',
                     type: 'error'
                 });
                }
               },
             error => 
             {
               toast(error.message, {
                 transition: Bounce,
                 closeButton: true,
                 autoClose: 5000,
                 position: 'bottom-center',
                 type: 'error'
             });
               dispatch(failure(error.toString()))
             }
         );
 };

 function request() { return { type: initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_REQUEST  } }
 function success(data) { return { type: initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_REQUEST,data } }
 function failure(error) { return { type: initialAssesmentMaster.GET_RADIOLOGY_DATA_BY_UHID_REQUEST, error } }
}


function getFormData() {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.getFormData()
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result.data));
                   
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.GET_FORM_REQUEST  } }
    function success(data) { return { type: initialAssesmentMaster.GET_FORM_SUCCESS,data } }
    function failure(error) { return { type: initialAssesmentMaster.GET_FORM_SUCCESS, error } }
  }

function getAll() {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.getAll()
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result));
                   
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_REQEST  } }
    function success(data) { return { type: initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_SUCCESS,data:{data} } }
    function failure(error) { return { type: initialAssesmentMaster.GET_ALL_INITIALASSESMENTMASTER_FAILURE, error } }
  }

  function add(type,data) {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.add(data)
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result.data,type));
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'success'
                  });
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_REQUEST  } }
    function success(data,changetype) { return { type: initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_SUCCESS,data,changetype } }
    function failure(error) { return { type: initialAssesmentMaster.ADD_INITIALASSESMENTMASTER_FAILURE, error } }
  }


  function deleteInitialForm(data) {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.deleteInitialForm(data)
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result,data._id));
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'success'
                  });
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.DELETE_FORM_REQUEST  } }
    function success(data,_id) { return { type: initialAssesmentMaster.DELETE_FORM_SUCCESS,data,_id } }
    function failure(error) { return { type: initialAssesmentMaster.DELETE_FORM_FAILURE, error } }
  }


  function deleteMaster(data,type) {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService._delete(data)
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result.data,type,data._id));
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'success'
                  });
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.DELETE_INITIALASSESMENT_REQUEST  } }
    function success(data,changetype,_id) { return { type: initialAssesmentMaster.DELETE_INITIALASSESMENT_SUCCESS,data,changetype,_id } }
    function failure(error) { return { type: initialAssesmentMaster.DELETE_INITIALASSESMENT_SUCCESS, error } }
  }


  function deleteIVFMaster(data) {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.deleteIVF(data)
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result.data,data._id));
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'success'
                  });
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.DELETE_IVF_REQUEST  } }
    function success(data,_id) { return { type: initialAssesmentMaster.DELETE_IVF_SUCCESS,data,_id } }
    function failure(error) { return { type: initialAssesmentMaster.DELETE_IVF_FAILURE, error } }
  }

  function addIVF(data) {
    return dispatch => {
        dispatch(request());
  
        initialAssesmentMasterService.addIVF(data)
            .then(
                result =>{
                  if(result.success)
                  {
                    dispatch(success(result));
                    toast(result.message, {
                      transition: Bounce,
                      closeButton: true,
                      autoClose: 5000,
                      position: 'bottom-center',
                      type: 'success'
                  });
                    dispatch(alertActions.success(result.message))
                  }
                   else
                   {
                      dispatch(failure(result.err))
                      toast(result.message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                   }
                  },
                error => 
                {
                  toast(error.message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
                  dispatch(failure(error.toString()))
                }
            );
    };
  
    function request() { return { type: initialAssesmentMaster.ADD_IVF_REQUEST  } }
    function success(data) { return { type: initialAssesmentMaster.ADD_IVF_SUCCESS, data } }
    function failure(error) { return { type: initialAssesmentMaster.ADD_IVF_FAILURE, error } }
  }

  
  
