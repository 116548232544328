export * from './menu.service';
export * from './customer.service'
export * from './admin.service';
export * from './form.service'
export * from './registrationMaster.service'
export * from './login.service'
export * from './general'
export * from './patient.service'
export * from './storage.service'
export * from './newmaster'
export * from './department.service'

export * from './location.service'
export * from './userMaster.service'
export * from './consultant.service'
export * from './servicemaster.service'
export * from './referral.service'
export * from './clinic.service'
export * from './initialassesment.master.services';
export * from './registrationwithfees.service';
export * from './appointment.service'
export * from './agreement.service'
export * from './referalconsultant.service'
export * from './opdorderservice.service'
export * from './opdpharmacyorder.service'
export * from './opbilling.service'

export * from './drugMaster.service';
export * from './drugManufacturers.service';
export * from './drugCategories.service';
export * from './drugContents.service';
export * from './drugGenerics.service';
export * from './drugTypes.service';
export * from './drugUnits.service';
export * from './drugTax.service';
export * from './drugRacks.service';
export * from './creditAuth.service';
export * from './drugCustomer.service';
export * from './drugSuppliers.service';
export * from './pharmacy/purchase.service';
export * from './pharmacy/sales.service';
export * from './pharmacy/pharmacy.service';
export * from './pharmacy/departmentIndent.service';
export * from './pharmacy/purchaseOrder.service';
export * from './pharmacy/departmentTransfer.service';
export * from './pharmacy/departmentUsage.service';
export * from './pharmacy/prescription.service';
export * from './pharmacy/purchaseReturn.service';
export * from './pharmacy/saleReturn.service';
export * from './pharmacy/stock.service'

export * from './operationTheatres/theatre.service'
export * from './operationTheatres/otbooking.service';

export * from './radiologyTemplates.service';
export * from './radiologyReport.service';

export * from './laboratoryMaster.service'
export * from './labTechnicians.service';
export * from './queue.service'
export * from './posConfig.service'
export * from './labOutSource.service';
export * from './labOutSourceMapping.service';
export * from './labGroupMapping.service';
export * from './labTemplates.service';
export * from './laboratoryReport.service';
export * from './labTestMapping.service';
export * from './labTestSampleCollection.service'
export * from './labTestMapping.service';
export * from './laboratoryResultEntry.service';
export * from './ageWiseMaster.service';
export * from './ageWiseGenderMaster.service';
export * from './labTestPrintPriority.service';
export * from './labAntibioticMapping.service';
export * from './corporatewiseconsultantfees.service'
export * from './corporate.service'
export * from './tpa.service'
export * from './tpawiseconsultantfees.service'
export * from './ticketPriority.service';
export * from './ticketPriority.service';
export * from './problemCategory.service';
export * from './supportTicket.service'
export * from './socket.service'
export * from './opPatientsReporting.service'
export * from './master.service'
export * from './labpatientlist.service'
export * from './labSubTestMapping.service'
export * from './dms.service'
export * from './http.helper.service'
export * from './reminder.service'
export * from './reportMenu.service'
export * from './opdprescription.service'

export * from './emailSmsCategory.service'
export * from './paymentGatewayConfig.service'
export * from './smsConfig.service';
export * from './smsTemplate.service';
export * from './smsEmailSetting.service';
export * from './userGroups.service'
export * from './cronJob.service';
export * from './reports.service';
export * from './initialAssessment/symptoms.service'
export * from './initialAssessment/findings.service'
export * from './initialAssessment/diagnosis.service'
export * from './initialAssessment/associatedSymptoms.service'
export * from './initialAssessment/instructions.service'
export * from './initialAssessment/procedures.service'
export * from './initialAssessment/iaconsultant.service'
export * from './initialAssessment/medicalProblems.service'
export * from './initialAssessment/lifeStyle.service'
export * from './initialAssessment/drugallergy.service'
export * from './initialAssessment/generalallergy.service'
export * from './initialAssessment/riskFactors.service'
export * from './initialAssessment/familyHistory.service'
export * from './initialAssessment/medicalHistory.service'

export const ENDPOINTS = {
    AUTHORIZATION:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcl9pZCI6IjVlYzY0ZTRiODZjNDVmMDc3YzIyNWE0MiIsImN1c3RvbWVyX2xvY2F0aW9uX2lkIjoiNWVjNjRlZWU4NmM0NWYwNzdjMjI1YTQzIiwiZGVzaWduYXRpb24iOiJNYW5hZ2luZyBEaXJlY3RvciIsInVzZXJfbmFtZSI6Ik1yLiBTdXNoaWwgQmhvc2FsZSIsInVzZXJfZW1haWwiOiJzYWNoaW5AZ21haWwuY29tIiwiaXNBY3RpdmUiOnRydWUsImlkIjoiNjA4N2I2MzhjZjRlNjIwYmUwMGNiZmU5IiwibG9naW5fdHlwZSI6InVzZXIiLCJhY2Nlc3NfZ3JvdXAiOnsiZGVmYXVsdF9yZWFkX2FjY2Vzc19ncm91cCI6Ik1hbmFnaW5nIERpcmVjdG9yIiwiZGVmYXVsdF93cml0ZV9hY2Nlc3NfZ3JvdXAiOiJNYW5hZ2luZyBEaXJlY3RvciJ9LCJzZXNzaW9uSWQiOiIyNDNzOTdna2ZpOCIsImlhdCI6MTYxOTg3ODE1MiwiZXhwIjoxNjE5OTY0NTUyfQ.grNZaWrNjpayfAkRTZMiYDnvcTHNekUqymeHPdlPyrc",
    BASE_URL: "https://aqua.101logix.com/",
    GET_ALL_DRUG_RACK: "drugRack",
    UPDATE_DRUG_RACK: "drugRack/update",
    GET_ALL_DRUG_SUPPLIER: "drugSupplier",
    UPDATE_DRUG_SUPPLIER: "drugSupplier/update",
    GET_ALL_DRUG_TAX: "tax",
    UPDATE_DRUG_TAX: "tax/update",
    GET_ALL_DRUG_TYPE: "drugTypes",
    UPDATE_DRUG_TYPE: "drugTypes/update",
    GET_ALL_DRUG_CREDIT_AUTH: "drugCreditAuth",
    UPDATE_DRUG_CREDIT_AUTH: "drugCreditAuth/update",
    GET_ALL_DRUG_PRODUCT: "drugs",
    UPDATE_DRUG_PRODUCT: "drugs/update",
    // IPD API's
    GET_ALL_REFERANCES: "referrals",
    GET_ALL_CONSULTANT: "newconsultant",
    GET_ALL_COMPANY: "tpas",
    GET_ALL_PATIENT_LIST: "newpatient",
    GET_ADM_TYPE : 'opregistration',
  
  };
