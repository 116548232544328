//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const laboratoryResultEntryService = {        
    getAll,
    create,    
    getByDate,
    getStatusCount,    
    search,
    getCollectionReport,
    getConsolidatedCollectionReport

   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratoryResultEntry`, requestOptions).then(helperService.handleResponse);
}
function search(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify({query:data})
    };
   
    return fetch(`${apiUrl}/laboratoryResultEntry/search`, requestOptions).then(helperService.handleResponse);
}
function getCollectionReport(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify({query:data})
    };
   
    return fetch(`${apiUrl}/laboratoryResultEntry/getCollectionReport`, requestOptions).then(helperService.handleResponse);
}

function getStatusCount(data) {

    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()},
        
    };
   
    return fetch(`${apiUrl}/laboratoryResultEntry/getCounts`, requestOptions).then(helperService.handleResponse);
}

function getConsolidatedCollectionReport(data) {

    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()},
        
    };
   
    return fetch(`${apiUrl}/laboratoryResultEntry/getConsolidated`, requestOptions).then(helperService.handleResponse);
}

function getByDate(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratoryResultEntry/getByDate/${from}/${to}`, requestOptions).then(helperService.handleResponse);
}

function create(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratoryResultEntry/create`, requestOptions).then(helperService.handleResponse);
}


