import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import TextareaAutosize from "react-textarea-autosize";
import PatientAddressModal from "./PatientAddressModal";
import LinkModal from "./LinkModal";
import { ipdManagementActions } from "../../../actions/ipdManagementActions";
import DrawGrid from "./DrawGrid";
import PatientGeneralInfo from "../../MyComponents/patientipdgeneralcomponent";
import moment from "moment";
import _ from "lodash";
import PageTitle from "../../MyComponents/PageTitle";
import { apiUrl } from "../../../constants";
import { toast, Bounce } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Icons } from "../../../helpers/IconHelper";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Drug Name is Required"),
  wardName: Yup.string().required("Ward Name Name is Required"),
  primary_consultant: Yup.string().required("Primary Consultant is Required"),
  payment_type: Yup.string().required("Payment Type is Required"),
});

class PatientForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      ipd_admission_no: "",
      racks: [],
      modal: false,
      linkModal: false,
      consultant: [],
      referances: [],
      company: [],
      primary_consultant: "",
      secondary_consultant: "",
      secondary_consultant1: "",
      secondary_consultant2: "",
      selectedValue: "",
      referral: "",
      admType: "",
      admTypes: [],
      admission_time: new Date(),
      admission_date: new Date(),
      beds: [],
      occupiedBeds: [],
      reservedBed: [],
      vaccantBed: [],
      wardBedList: [],
      bed_code: "",
      addressDetails: {
        pincode: "",
        add1: "",
        alt_contact: "",
        email: "",
        age: "",
        attender: "",
      },

      initialValues: [
        {
          name: "",
          wardName: "",
          primary_consultant: "",
          secondary_consultants: [],
          secondary_consultant: "",
          primary_consultants: [],
          payment_type: "Self",
          referral: "",
          insurance: "",
          insurances: [],
          corporates: [],
          corporate: "",
          policy_no: "",
          employee_id: "",
          wardName: "",
          wardNames: [],
          room_type: "",
          room_types: [],
          bedSelected: [],
          seatReserved: "",
          seatAvailable: "",
          totalSeats: "",
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.codeToValue = this.codeToValue.bind(this);
    this.selectRoomType = this.selectRoomType.bind(this);
    this.onWardChange = this.onWardChange.bind(this);
    this.onClickData = this.onClickData.bind(this);
    this.secondaryOptions = this.secondaryOptions.bind(this);
    this.secondaryOptions1 = this.secondaryOptions1.bind(this);
    this.secondaryOptions2 = this.secondaryOptions2.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getTime = this.getTime.bind(this);
    this.onWardCatagoryToName = this.onWardCatagoryToName.bind(this);
    this.onWardTypeCodeToName = this.onWardTypeCodeToName.bind(this);
    this.wardSubCatagoryCodeToValue = this.wardSubCatagoryCodeToValue.bind(
      this
    );
    this.roomListChange = this.roomListChange.bind(this);
    this.addressChange = this.addressChange.bind(this);
  }

  onWardCatagoryToName(code) {
    let { wardcatagorys } = this.props;
    try {
      let name = wardcatagorys.find((data) => data.code === code).ward_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onWardTypeCodeToName(code) {
    let { wardtypes } = this.props;
    try {
      let name = wardtypes.find((data) => data.code === code).ward_type;
      return name;
    } catch {
      return code;
    }
  }

  wardSubCatagoryCodeToValue(code) {
    let { wardsubcatagory } = this.props;
    try {
      let name = wardsubcatagory.find((data) => data.code === code)
        .ward_sub_catagory;
      return name;
    } catch {
      return code;
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle1 = () => {
    this.setState({
      linkModal: !this.state.linkModal,
    });
  };

  submitForm = () => {
    let { dispatch, patient, wardbed } = this.props;

    if (
      this.state.primary_consultant === "" ||
      this.state.referral === "" ||
      this.state.bed_code === ""
    ) {
      toast("some fields are missing", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let payload = {
      patient_ipd_mrn_no: patient.patient_ipd_mrn_no,
      patient_uhid: patient.patient_uhid,
      patient_id: patient._id,
      admission_date: this.state.admission_date,
      admission_time: moment(this.state.admission_time).format("hh:mm a"),
      admission_consultant_dr: this.state.primary_consultant,
      admission_approved_dr: [
        this.state.secondary_consultant,
        this.state.secondary_consultant1,
        this.state.secondary_consultant2,
      ],
      adimission_referal_dr: this.state.referral,
      payment_type: this.state.payment_type || "Self",
      wsc_code: this.state.wardName,
      room_code: this.state.room_type,
      room_name: this.state.room_name,
      bed_code: this.state.bed_code,
      bed_name: wardbed.find((item) => item.code === this.state.bed_code)
        ?.bed_name,
      admission_notes: this.state.admission_notes,
      patient_admission_status: "admitted",
      reason_for_status: "admitted for patient",
      admType: this.state.admType,
      ...this.state.addressDetails,
    };

    if (this.state._id !== "") {
      payload._id = this.state._id;
      payload.ipd_admission_no = this.state.ipd_admission_no;
    }

    dispatch(ipdManagementActions.admitPatient(payload));
    this.props.history.push({
      pathname: `/in-patient-department/ipd-admission/ipd-patient-list`,
    });
  };

  clearData() {
    this.setState({
      admission_time: new Date(),
      admission_date: new Date(),
      secondary_consultants: [],
      referral: "",
      bed_code: "",
      wardName: "",
      room_name: "",
      room_type: "",
      payment_type: "Self",
      admType:
        this.props.getAdmTypes.data &&
        this.props.getAdmTypes.data
          .filter((value) => value.isActive && !value.isDeleted)
          .find((list) => list.rtm_reg_type.toLowerCase() === "normal")
          ?.rtm_code,
      selectedBed: "",
      primary_consultant: "",
      secondary_consultant: "",
      secondary_consultant1: "",
      secondary_consultant2: "",
      reservedBed: [],
      vaccantBed: [],
      occupiedBeds: [],
      wardBedList: [],
    });
  }

  addressChange(value) {
    this.setState({
      addressDetails: {
        ...this.state.addressDetails,
        pincode: value.pincode,
        add1: value.add1,
        alt_contact: value.alt_contact,
        email: value.email,
        age: value.age,
        attender: value.attender,
      },
    });
    this.toggle();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onRoomChange(e) {
    let { wardroom } = this.props;

    let roomList = wardroom.filter(
      (value) => value.default_room_name === e.target.value
    );
    this.setState({
      room_name: e.target.value,
      wardRoomList: roomList,
    });
  }

  roomListChange(e) {
    let { wardroom, wardbed } = this.props;
    let roomId = wardroom.find((value) => value.code === e.target.value);
    const bedList = wardbed.filter(
      (data) => data.room_name === roomId.room_name
    );

    const vacant = bedList.filter((value) => value.bed_status === "A");
    const occupied = bedList.filter((value) => value.bed_status !== "A");

    this.setState({
      room_type: e.target.value,
      wardBedList: bedList,
      vaccantBed: vacant,
      occupiedBeds: occupied,
      reservedBed: [],
      selectedBed: "",
    });
  }

  componentDidMount() {
    let { dispatch, patient, wardroom, wardbed } = this.props;

    if (patient) {
      this.setState({
        addressDetails: {
          ...this.state.addressDetails,
          pincode: patient?.pincode,
          add1: patient?.communication_address,
          alt_contact: patient?.secondary_contact,
          email: patient?.email,
          age: patient?.age_in_years,
          attender: patient?.relative_name,
        },
      });
    }

    if (!this.props.location.state) {
      this.props.history.push({
        pathname: `/in-patient-department/ipd-admission`,
      });
    }

    if (this.props.location && this.props.location.state) {
      const { mode, data } = this.props.location.state;

      const defaultAdmType =
        this.props.getAdmTypes.data &&
        this.props.getAdmTypes.data
          .filter((value) => value.isActive && !value.isDeleted)
          .find((list) => list.rtm_reg_type.toLowerCase() === "normal")
          ?.rtm_code;

      if (!data) {
        this.setState({
          admType: defaultAdmType,
        });
      }

      if (data) {
        this.wardsubcatagory(data?.wsc_code, data?.room_name, data?.room_code);
        // let wardRoomGroup = [];
        // let bedList = [];
        // let vacant = [];
        // let occupied = [];
        // const filterwardroom = wardroom?.filter(
        //   (list) => list.ward_sub_catagory === data.wsc_code
        // );
        // if (filterwardroom) {
        //   wardRoomGroup = _.chain(filterwardroom)
        //     .groupBy("default_room_name")
        //     .map((value, key) => ({
        //       default_room_name: value[0].default_room_name,
        //     }))
        //     .value();
        // }

        // let roomList = wardroom?.filter(
        //   (value) => value.default_room_name === data.room_name
        // );

        // let roomId = wardroom?.find((value) => value.code === data.room_code);
        // if (roomId) {
        //   bedList = wardbed?.filter(
        //     (data) => data?.room_name === roomId?.room_name
        //   );
        // }
        // if (bedList) {
        //   vacant = bedList?.filter((value) => value.bed_status === "A");
        //   occupied = bedList?.filter((value) => value.bed_status != "A");
        // }

        this.setState({
          _id: data._id,
          ipd_admission_no: data?.ipd_admission_no,
          patient_ipd_mrn_no: data?.patient_ipd_mrn_no,
          patient_uhid: data?.patient_uhid,
          patient_id: data?.patient_id,
          admission_date: new Date(data?.admission_date),
          admission_time: new Date(
            moment(new Date(data?.admission_date)).set({
              hour: data?.admission_time.toString().split(":")[0],
              minute: data?.admission_time.toString().split(":")[1],
            })
          ),
          primary_consultant: data?.admission_consultant_dr,
          secondary_consultant: data?.admission_approved_dr[0],
          secondary_consultant1: data?.admission_approved_dr[1],
          secondary_consultant2: data?.admission_approved_dr[2],
          referral: data?.adimission_referal_dr,
          payment_type: data?.payment_type || "Self",
          // wardName: data?.wsc_code,
          // room_type: data?.room_code,
          bed_code: data?.bed_code,
          admission_notes: data?.admission_notes,
          admType: data?.admType,
          // ward_room: wardRoomGroup,
          // wardRoomList: roomList,
          // wardBedList: bedList,
          // vaccantBed: vacant,
          // occupiedBeds: occupied,
          reservedBed: [wardbed.find((item) => item.code === data?.bed_code)],
          selectedBed: wardbed.find((item) => item.code === data?.bed_code)
            ?.bed_name,
          // room_name: data.room_name,
          patientData: data,
          mode: mode,
        });
      }
    }
  }

  codeToValue(code, name) {
    try {
      if (this.props.masters.data) {
        return this.props.masters.data[name].list.find(
          (item) => item.code === code
        ).name;
      } else {
        return code;
      }
    } catch (err) {
      return code;
    }
  }

  onWardChange(e) {
    const { wardroom } = this.props;

    const filterwardroom = wardroom.filter(
      (list) => list.ward_sub_catagory === e.target.value
    );

    const wardRoomGroup = _.chain(filterwardroom)
      .groupBy("default_room_name")
      .map((value, key) => ({
        default_room_name: value[0].default_room_name,
      }))
      .value();

    this.setState({
      wardName: e.target.value,
      ward_room: wardRoomGroup,
    });
  }

  wardsubcatagory(wardname, room_name, room_code) {
    const { wardroom } = this.props;

    const filterwardroom = wardroom?.filter(
      (list) => list?.ward_sub_catagory === wardname
    );

    const wardRoomGroup = _.chain(filterwardroom)
      .groupBy("default_room_name")
      .map((value, key) => ({
        default_room_name: value[0]?.default_room_name,
      }))
      .value();

    this.setState({
      wardName: wardname,
      ward_room: wardRoomGroup,
    });
    if (wardRoomGroup) {
      this.onRoomNameChange(room_name, room_code);
    }
  }

  onRoomNameChange(room_name, room_code) {
    let { wardroom } = this.props;

    let roomList = wardroom?.filter(
      (value) => value?.default_room_name === room_name
    );
    this.setState({
      room_name: room_name,
      wardRoomList: roomList,
    });
    if (roomList) {
      this.listRoomChange(room_code);
    }
  }

  listRoomChange(room_code) {
    let { wardroom, wardbed } = this.props;
    let roomId = wardroom?.find((value) => value?.code === room_code);
    const bedList = wardbed?.filter(
      (data) => data?.room_name === roomId?.room_name
    );

    const vacant = bedList?.filter((value) => value?.bed_status === "A");
    const occupied = bedList?.filter((value) => value?.bed_status !== "A");

    this.setState({
      room_type: room_code,
      wardBedList: bedList,
      vaccantBed: vacant,
      occupiedBeds: occupied,
    });
  }

  onClickData(seat) {
    if (this.state.mode !== "View") {
      this.setState({
        reservedBed: [seat],
        selectedBed: seat.bed_name,
        bed_code: seat.code,
      });
    }
  }

  getDate(value) {
    let { patient } = this.props;

    const compare_date =
      new Date(value).getDate() === new Date(patient?.reg_date).getDate();
    const min_time = new Date(patient?.reg_date).getTime();

    if (compare_date) {
      this.setState({
        admission_time: new Date(patient?.reg_date),
      });
    }

    this.setState({
      admission_date: value,
    });
  }

  getTime(time) {
    this.setState({
      admission_time: time,
    });
    console.log(time);
  }

  selectRoomType(wardName) {
    let { dispatch } = this.props;
    var payload = { ward_sub_catagory: wardName };
    console.log("---------=====================");
    console.log(wardName);
    dispatch(ipdManagementActions.getRoomTypes(payload));
    if (this.props.getRoomTypes.data) {
      console.log(
        "--------------------------------> inside the select room type"
      );
      console.log(this.props.getRoomTypes.data);
    }
  }

  secondaryOptions(e) {
    this.setState({
      secondary_consultant: e.target.value,
    });
  }

  secondaryOptions1(e) {
    this.setState({
      secondary_consultant1: e.target.value,
    });
  }

  secondaryOptions2(e) {
    this.setState({
      secondary_consultant2: e.target.value,
    });
  }

  render() {
    let { wardsubcatagory, wardbed, patient } = this.props;
    let {
      wardRoomList,
      ward_room,
      wardBedList,
      max_date,
      addressDetails,
    } = this.state;
    var arr = [];
    if (this.props.getNewConsultant.data) {
      arr = this.props.getNewConsultant.data
        .filter((value) => value.active && !value.deleted)
        .map((data) => {
          return {
            label:
              this.codeToValue(data.title, "titles") +
              " " +
              data.firstname +
              " " +
              data.lastname,
            value: data.code,
          };
        });
    }
    const min_date = new Date(patient?.reg_date);
    const min_time = new Date(patient?.reg_date).getTime();
    const compare_date =
      new Date(this.state?.admission_date).getDate() ===
      new Date(patient?.reg_date).getDate();

    return (
      <Fragment>
        <PageTitle
          icon={Icons.PatientRegistration}
          heading="IPD Admission"
          actionWrapperClass="pageTitleAction"
        />
        {this.state.modal ? (
          <PatientAddressModal
            isOpen={this.state.modal}
            toggle={this.toggle}
            data={addressDetails}
            onChange={this.addressChange}
            mode={this.state.mode}
          />
        ) : (
          ""
        )}
        {this.state.linkModal ? (
          <LinkModal isOpen={this.state.linkModal} toggle={this.toggle1} />
        ) : (
          ""
        )}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PatientGeneralInfo
            patientData={this.state.patientData}
          ></PatientGeneralInfo>
          <Card className="main-card mb-3">
            <CardBody>
              <Formik
                initialValues={this.state.initialValues}
                enableReinitialize
                validationSchema={SignupSchema}
                onSubmit={this.submitForm}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  console.log(values);
                  return (
                    <Form>
                      <div
                        className="title-heading-bar"
                        style={{ marginTop: "-20px" }}
                      >
                        <h5 className="title-text">
                          Patient Admission Details
                        </h5>
                      </div>
                      <Row form style={{ paddingBottom: "30px" }}>
                        <Col md={4}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <Label>Adm Date</Label>
                              </Col>
                              <Col lg={8}>
                                <DatePicker
                                  selected={this.state.admission_date}
                                  name="admission_date"
                                  placeholderText="Select date"
                                  required
                                  maxDate={new Date()}
                                  minDate={min_date}
                                  onChange={(date) => this.getDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                  className="form-control removeUnwantedClass"
                                  autoComplete="off"
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Primary Consultant
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  onChange={this.handleChange}
                                  name="primary_consultant"
                                  value={this.state.primary_consultant}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>

                                  {this.props.getNewConsultant &&
                                  this.props.getNewConsultant.data
                                    ? this.props.getNewConsultant.data
                                        .filter(
                                          (value) =>
                                            value.active &&
                                            !value.deleted &&
                                            value.code !==
                                              this.state
                                                .secondary_consultant1 &&
                                            value.code !==
                                              this.state
                                                .secondary_consultant2 &&
                                            value.code !==
                                              this.state.secondary_consultant
                                        )
                                        .filter((list) =>
                                          (
                                            this.props.departments &&
                                            this.props.departments.filter(
                                              (value) =>
                                                value.active && !value.deleted
                                            )
                                          ).some(
                                            (dept) =>
                                              dept.code === list.department
                                          )
                                        )
                                        .map((data) => {
                                          return (
                                            <option
                                              value={data.code}
                                              key={data._id}
                                            >
                                              {this.codeToValue(
                                                data.title,
                                                "titles"
                                              ) +
                                                " " +
                                                data?.firstname +
                                                " " +
                                                (data?.lastname
                                                  ? data?.lastname
                                                  : "")}
                                            </option>
                                          );
                                        })
                                    : null}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Payment Type
                                </Label>
                              </Col>
                              <Col lg={8}>
                                {" "}
                                <Input
                                  type="select"
                                  onChange={this.handleChange}
                                  name="payment_type"
                                  value={this.state.payment_type}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="Self">Self</option>
                                  <option value="Insurance">Insurance</option>
                                  <option value="Corporate">Corporate</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <Label>Adm Time</Label>
                              </Col>
                              <Col lg={8}>
                                <DatePicker
                                  selected={this.state.admission_time}
                                  onChange={(date) => this.getTime(date)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  minTime={
                                    compare_date === true
                                      ? min_time
                                      : setHours(setMinutes(new Date(), 0), 0)
                                  }
                                  maxTime={setHours(
                                    setMinutes(new Date(), 59),
                                    23
                                  )}
                                  className="form-control removeUnwantedClass"
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Secondary Consultant
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  onChange={this.secondaryOptions}
                                  name="secondary_consultant"
                                  value={this.state?.secondary_consultant}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>

                                  {this.props.getNewConsultant &&
                                    this.props.getNewConsultant.data &&
                                    this.props.getNewConsultant.data
                                      .filter(
                                        (value) =>
                                          value.active &&
                                          !value.deleted &&
                                          value.code !==
                                            this.state.secondary_consultant1 &&
                                          value.code !==
                                            this.state.secondary_consultant2 &&
                                          value.code !==
                                            this.state.primary_consultant
                                      )
                                      .filter((list) =>
                                        (
                                          this.props.departments &&
                                          this.props.departments.filter(
                                            (value) =>
                                              value.active && !value.deleted
                                          )
                                        ).some(
                                          (dept) =>
                                            dept.code === list.department
                                        )
                                      )
                                      .map((data) => {
                                        return (
                                          <option
                                            key={data._id}
                                            value={data.code}
                                          >
                                            {this.codeToValue(
                                              data.title,
                                              "titles"
                                            ) +
                                              " " +
                                              data?.firstname +
                                              " " +
                                              (data?.lastname
                                                ? data?.lastname
                                                : "")}
                                          </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Secondary Consultant
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  onChange={this.secondaryOptions1}
                                  name="secondary_consultant1"
                                  value={this.state?.secondary_consultant1}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>

                                  {this.props.getNewConsultant &&
                                    this.props.getNewConsultant.data &&
                                    this.props.getNewConsultant.data
                                      .filter(
                                        (value) =>
                                          value.active &&
                                          !value.deleted &&
                                          value.code !==
                                            this.state.secondary_consultant &&
                                          value.code !==
                                            this.state.secondary_consultant2 &&
                                          value.code !==
                                            this.state.primary_consultant
                                      )
                                      .filter((list) =>
                                        (
                                          this.props.departments &&
                                          this.props.departments.filter(
                                            (value) =>
                                              value.active && !value.deleted
                                          )
                                        ).some(
                                          (dept) =>
                                            dept.code === list.department
                                        )
                                      )
                                      .map((data) => {
                                        return (
                                          <option
                                            key={data._id}
                                            value={data.code}
                                          >
                                            {this.codeToValue(
                                              data.title,
                                              "titles"
                                            ) +
                                              " " +
                                              data?.firstname +
                                              " " +
                                              (data?.lastname
                                                ? data?.lastname
                                                : "")}
                                          </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Secondary Consultant
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  onChange={this.secondaryOptions2}
                                  name="secondary_consultant2"
                                  value={this.state?.secondary_consultant2}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>

                                  {this.props.getNewConsultant &&
                                    this.props.getNewConsultant.data &&
                                    this.props.getNewConsultant.data
                                      .filter(
                                        (value) =>
                                          value.active &&
                                          !value.deleted &&
                                          value.code !==
                                            this.state.secondary_consultant &&
                                          value.code !==
                                            this.state.secondary_consultant1 &&
                                          value.code !==
                                            this.state.primary_consultant
                                      )
                                      .filter((list) =>
                                        (
                                          this.props.departments &&
                                          this.props.departments.filter(
                                            (value) =>
                                              value.active && !value.deleted
                                          )
                                        ).some(
                                          (dept) =>
                                            dept.code === list.department
                                        )
                                      )
                                      .map((data) => {
                                        return (
                                          <option
                                            key={data._id}
                                            value={data.code}
                                          >
                                            {this.codeToValue(
                                              data.title,
                                              "titles"
                                            ) +
                                              " " +
                                              data?.firstname +
                                              " " +
                                              (data?.lastname
                                                ? data?.lastname
                                                : "")}
                                          </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          {this.state.payment_type === "Insurance" ? (
                            <>
                              <FormGroup>
                                <Row>
                                  <Col lg={4}>
                                    <Label className="input-label-component">
                                      Insurance Company
                                    </Label>
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      type="select"
                                      onChange={this.handleChange}
                                      name="insurance"
                                      value={this.state.insurance}
                                      disabled={
                                        this.state.mode === "View"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">Select</option>

                                      {this.props.getInsurance.data
                                        ? this.props.getInsurance.data.map(
                                            (data) => {
                                              return (
                                                <option
                                                  key={data._id}
                                                  value={data.code}
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : this.state.payment_type === "Corporate" ? (
                            <>
                              <FormGroup>
                                <Row>
                                  <Col lg={4}>
                                    <Label className="input-label-component">
                                      Corporate Company
                                    </Label>
                                  </Col>
                                  <Col lg={8}>
                                    {" "}
                                    <Input
                                      type="select"
                                      onChange={this.handleChange}
                                      name="corporate"
                                      value={this.state.corporate}
                                      disabled={
                                        this.state.mode === "View"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">Select</option>

                                      {this.props.getCorporate.data
                                        ? this.props.getCorporate.data.map(
                                            (data) => {
                                              return (
                                                <option
                                                  key={data._id}
                                                  value={data.code}
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col lg={4}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Adm Type
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  onChange={this.handleChange}
                                  name="admType"
                                  value={this.state.admType}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  {this.props.getAdmTypes.data
                                    ? this.props.getAdmTypes.data
                                        .filter(
                                          (value) =>
                                            value.isActive && !value.isDeleted
                                        )
                                        .map((data) => {
                                          return (
                                            <option
                                              key={data?._id}
                                              value={data?.rtm_code}
                                            >
                                              {data?.rtm_reg_type}
                                            </option>
                                          );
                                        })
                                    : null}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Referal
                                </Label>
                              </Col>
                              <Col lg={8}>
                                {" "}
                                <Input
                                  type="select"
                                  onChange={this.handleChange}
                                  name="referral"
                                  value={this.state.referral}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>
                                  {this.props.getReferals.data
                                    ? this.props.getReferals.data.map(
                                        (data) => {
                                          return (
                                            <option
                                              key={data._id}
                                              value={data.code}
                                            >
                                              {data.name}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          {this.state.payment_type === "Insurance" ? (
                            <>
                              <FormGroup>
                                <Row>
                                  <Col lg={4}>
                                    <Label className="input-label-component">
                                      Policy No
                                    </Label>
                                  </Col>
                                  <Col lg={8}>
                                    {" "}
                                    <Input
                                      type="text"
                                      onChange={this.handleChange}
                                      name="policy_no"
                                      value={this.state.policy_no}
                                      disabled={
                                        this.state.mode === "View"
                                          ? true
                                          : false
                                      }
                                      placeholder="Enter Policy Number"
                                    ></Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : this.state.payment_type === "Corporate" ? (
                            <>
                              <FormGroup>
                                <Row>
                                  <Col lg={4}>
                                    <Label className="input-label-component">
                                      Employee Id
                                    </Label>
                                  </Col>
                                  <Col lg={8}>
                                    {" "}
                                    <Input
                                      type="text"
                                      onChange={this.handleChange}
                                      name="employee_id"
                                      value={this.state.employee_id}
                                      disabled={
                                        this.state.mode === "View"
                                          ? true
                                          : false
                                      }
                                      placeholder="Enter Employee Number"
                                    ></Input>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : (
                            ""
                          )}
                          <Button
                            onClick={this.toggle}
                            className="edit-address-btn"
                          >
                            Edit Address
                          </Button>
                        </Col>
                      </Row>
                      {/* <div className="title-heading-bar">
                      <h5 className="title-text">Admiting Ward Details</h5>
                    </div> */}
                      <Row>
                        <Col lg={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          ></div>
                        </Col>
                        <Col lg={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          ></div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <div className="title-heading-bar">
                            <h5 className="title-text">
                              Admiting Ward Details
                            </h5>
                          </div>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Ward Subcatagory Name
                                </Label>
                              </Col>
                              <Col lg={8}>
                                {" "}
                                <Input
                                  type="select"
                                  onChange={this.onWardChange}
                                  name="wardName"
                                  value={this.state.wardName}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>
                                  {wardsubcatagory
                                    ? wardsubcatagory.map((data) => {
                                        if (data.isActive && !data.isDeleted)
                                          return (
                                            <option
                                              key={data._id}
                                              value={data.code}
                                            >
                                              {data.ward_sub_catagory}
                                            </option>
                                          );
                                      })
                                    : null}
                                  {/* <option value="">Select</option>
                                  {this.props.getWardNames.data
                                    ? this.props.getWardNames.data.map(
                                        (data) => {
                                          return (
                                            <option
                                              key={data._id}
                                              value={data.code}
                                            >
                                              {"" +
                                                this.onWardTypeCodeToName(
                                                  data.ward_type
                                                ) +
                                                ", " +
                                                this.onWardCatagoryToName(
                                                  data.ward_catagory
                                                ) +
                                                ", " +
                                                data.ward_sub_catagory}
                                            </option>
                                          );
                                        }
                                      )
                                    : null} */}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label>Ward Room Name</Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="select"
                                  name="room_name"
                                  onChange={(e) => this.onRoomChange(e)}
                                  value={this.state.room_name}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>
                                  {ward_room
                                    ? ward_room.map((data, index) => {
                                        return (
                                          <option
                                            key={data._id}
                                            value={data.default_room_name}
                                          >
                                            {data.default_room_name}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Room Type
                                </Label>
                              </Col>
                              <Col lg={8}>
                                {" "}
                                <Input
                                  type="select"
                                  onChange={this.roomListChange}
                                  name="room_type"
                                  value={this.state.room_type}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                >
                                  <option value="">Select</option>
                                  {/* {this.props.getRoomTypes.data
                                    ? this.props.getRoomTypes.data.map(
                                        (data) => {
                                          return (
                                            <option
                                              key={data._id}
                                              value={data.code}
                                            >
                                              {data.room_name}
                                            </option>
                                          );
                                        }
                                      )
                                    : null} */}
                                  {wardRoomList
                                    ? wardRoomList.map((data, index) => {
                                        if (data.isActive)
                                          return (
                                            <option
                                              key={data._id}
                                              value={data.code}
                                            >
                                              {data.room_name}
                                            </option>
                                          );
                                      })
                                    : null}
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <Row>
                              <Col lg={4}>
                                <Label>Alerts</Label>
                              </Col>
                              <Col lg={8}>
                                <TextareaAutosize
                                  style={{ width: "100%" }}
                                  minRows={3}
                                  maxRows={6}
                                  tag={Field}
                                  type="text"
                                  name="notes"
                                  value={values.notes}
                                  onChange={(e) =>
                                    setFieldValue("notes", e.target.value)
                                  }
                                  placeholder="Enter Alerts"
                                  className={
                                    errors.notes && touched.notes
                                      ? `invalid form-control`
                                      : "form-control"
                                  }
                                  errors={errors.notes}
                                  touched={touched.notes}
                                  disabled={
                                    this.state.mode === "View" ? true : false
                                  }
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col lg={8}>
                          <div
                            className="title-heading-bar"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h5 className="title-text">Bed Availablity</h5>
                            <a
                              style={{ marginLeft: "10px", color: "blue" }}
                              onClick={this.toggle1}
                            >
                              Ward Facility
                            </a>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button>
                              Total {this.state.wardBedList.length}
                            </Button>
                            <Button>
                              Occupied {this.state.occupiedBeds.length}
                            </Button>
                            <Button>
                              Reserved {this.state.reservedBed.length}
                            </Button>
                            <Button>
                              Vacant{" "}
                              {this.state.selectedBed
                                ? this.state.vaccantBed.length - 1
                                : this.state.vaccantBed.length}
                            </Button>
                            <span>
                              <Label className="mr-2">Selected Bed</Label>
                              <Button color="primary" style={{ minHeight: 30 }}>
                                {this.state.selectedBed}
                              </Button>
                            </span>
                            {/* <Button color="primary">SP 101 B</Button> */}
                          </div>
                          <div className="box-divider"></div>
                          <div style={{ display: "flex" }}>
                            <div style={{ display: "flex" }}>
                              <div className="booked-box"></div>
                              <span style={{ margin: "5px" }}> Occupied </span>
                              <div className="available-box"></div>
                              <span style={{ margin: "5px" }}> Vacant </span>
                              <div className="reserved-box"></div>
                              <span style={{ margin: "5px" }}> Reserved </span>
                            </div>
                            <div
                              className="beds-grid"
                              style={{ marginLeft: "50px" }}
                            >
                              {wardBedList && wardBedList.length > 0 && (
                                <DrawGrid
                                  beds={wardBedList}
                                  available={this.state.vaccantBed}
                                  reserved={this.state.reservedBed}
                                  occupied={this.state.occupiedBeds}
                                  onClickData={this.onClickData}
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div></div>
                        <div>
                          <Button
                            className="add-form-exit-btn mt-2"
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="add-form-clear-btn mt-2"
                            onClick={() => this.clearData()}
                            disabled={this.state.mode === "View" ? true : false}
                          >
                            Clear
                          </Button>
                          <Button
                            type="submit"
                            className="add-form-save-btn mt-2"
                            onClick={this.submitForm}
                            disabled={this.state.mode === "View" ? true : false}
                          >
                            {this.state.mode === "EDIT"
                              ? "Update Patient"
                              : "Admit Patient"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
          {/* </Container> */}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    getAdmTypes,
    patients,
    wardManagement,
    getNewConsultant,
    getReferals,
    getInsurance,
    getCorporate,
    getWardNames,
    getRoomTypes,
    masters,
    departments,
  } = state;

  return {
    getAdmTypes: getAdmTypes,
    patient: patients.selected_patient,
    getNewConsultant: getNewConsultant,
    getReferals: getReferals,
    getInsurance: getInsurance,
    getCorporate: getCorporate,
    getWardNames: getWardNames,
    getRoomTypes: getRoomTypes,
    masters: masters,
    wardtypes: wardManagement.wardtypes,
    wardroom: wardManagement.wardroom,
    wardbycatagorys: wardManagement.wardbycatagorys,
    wardcatagorys: wardManagement.wardcatagorys,
    wardsubcatagory: wardManagement.wardsubcatagory,
    wardbed: wardManagement.wardbed,
    departments: departments.departments,
  };
}

export default connect(mapStateToProps)(PatientForm);
