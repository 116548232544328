import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Table, Form, FormGroup, Label, Input, CustomInput, CardHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import { customerActions } from '../../../actions';
import PageTitle from '../../MyComponents/PageTitle'
import {apiUrl} from '../../../constants';
import DataTable from 'react-data-table-component';
import CustomButtons from '../../Components/customebuttons';
import Switch from "react-switch";
import { Icons } from '../../../helpers/IconHelper';


class MainView extends Component {

    constructor(props) {
        super(props); 
        this.state={};
        this.onAddClickNavigate = this.onAddClickNavigate.bind(this);
        this.onFilterTextChange = this.onFilterTextChange.bind(this);
    }

    
    editBtn_Click(data){
        this.props.history.push({
            pathname: `${this.props.match.url}/add`,
            state: {
                formData: data
            }

        });
    }

    deleteBtn_Click(data){
        
    }

    onFilterTextChange(e){
        this.setState({
            filterText: e.target.value
        })
    }

    onAddClickNavigate(e){
        this.props.history.push({
            pathname: `${this.props.match.url}/add`,
        });
    }
    render(){
        let {corporates} = this.props;
        let columns = [
            {
                name: 'SR. No',
                sortable: true,
                selector:(data)=>(data.count),
                width:"5%"
              },
              {
                name: 'Name',
                sortable: true,
                selector:(data)=>(data.name),
                width:"8%"
              },
              {
                name: 'Address',
                sortable: true,
                selector:(data)=>(data.address),
                width:"8%"
              },
              {
                name: 'Primary Contact Name',
                sortable: true,
                selector:(data)=>(data.primary_contact_name),
                width:"8%"
              },
              {
                name: 'Primary Contact No',
                sortable: true,
                selector:(data)=>(data.primary_contact_no),
                width:"8%"
              },
              {
                name: 'Secondary Contact Name',
                sortable: true,
                selector:(data)=>(data.secondary_contact_name),
                width:"8%"
              },
              {
                name: 'Patient Submission Within',
                sortable: true,
                selector:(data)=>(data.patient_details_submission_within),
                width:"10%"
              },
              {
                name: 'Payment Recycle After',
                sortable: true,
                selector:(data)=>(data.repayment_cycle_after),
                width:"10%"
              },
              {
                name: 'Repayment Cycle within',
                sortable: true,
                selector:(data)=>(data.repayment_cycle_within),
                width:"8%"
              },
              {
                name: 'Actions',
                sortable: true,
                cell:data=><><CustomButtons type="edit" onClickHandler = {() => { this.editBtn_Click(data) }} 
                width="40px" title="Edit Corporates" />
                <CustomButtons type="delete" onClickHandler = {() => { this.deleteBtn_Click(data) }} 
                width="40px" />
                </>,
                width:"8%"
              },
              { name: 'Active',
              sortable: true,
              selector:(data)=>(
                  <FormGroup>
                                      <Switch
                                       
                                          checked={data.active}
                                           className="mr-2 mb-2"
                                        id="normal-switch"
                                    />
                                   </FormGroup>
              ),
              width:"10%"
            }
        ];
        let CorporateCSVDate=[];
        if(corporates){
            let count = 0;
            if(this.state.filterText){
                let filterText = this.state.filterText.toLowerCase();
                corporates.map((data,index)=>{
                    if(data.name.toLowerCase().includes(filterText)  || 
                    data.address.toLowerCase().includes(filterText) ||
                    data.primary_contact_name.toLowerCase().includes(filterText) ||
                    data.primary_contact_no.toLowerCase().includes(filterText)
                    ){
                        count +=1;
                        data.count = count;
                        CorporateCSVDate.push(data)
                    }
                })
            }
            else{
                corporates.map((data,index)=>{
                    count +=1;
                    data.count = count;
                    CorporateCSVDate.push(data)
                })
            }
        }
        return(
            <Fragment>
            <PageTitle icon={Icons.PatientRegistration} heading="Corporate's List" 
            data={CorporateCSVDate}
            csvFilename="CorporateData.csv"/>
            <Card>

                <CardHeader>
                    <Row style={{width:"100%"}}>
                        <Col><Input name="filterText" id="filterText" placeholder="search" onChange={(e) => { this.onFilterTextChange(e) }}></Input></Col>
                        <Col></Col>
                        <Col><Button color="primary" onClick={this.onAddClickNavigate}>Add Corporate</Button></Col>
                    </Row>
                    
                </CardHeader>
                <CardBody>
                <div ref={el => (this.componentRef = el)}>
                            
                            <DataTable 
                                                       keyField="_id"
                                                       pagination={true}
                                                       expandableRows={false}
                                                       expandOnRowClicked={false}
                                                       dense
                                                       noHeader
                                                       striped
                                                       paginationPerPage={25}
                                                       paginationRowsPerPageOptions={[25,50,75,100]}
                                                       columns={columns} 
                                                       data={CorporateCSVDate}/>
                                                       </div>
                </CardBody>
            </Card>

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    let { corporateMaster } = state;

    return {
        corporates: corporateMaster.data
    };
}

const connectedViewMaster = connect(mapStateToProps)(MainView);

export { connectedViewMaster as CorporateMainView };