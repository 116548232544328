// import ThemeOptions from './ThemeOptions';

// export default {
//     ThemeOptions
// };


import {addNewPatientReducer} from '../reducers/addNewPatientReducer'
import {combineReducers} from 'redux'
import { getAdmTypeReducer } from '../reducers/getAdmTypeReducer'
import { getNewConsultantReducer } from './getNewConsultantReducer';
import { getReferalReducer } from './getReferalReducer';
import { getInsuranceReducer } from './getInsuranceReducer';
import { getCorporateReducer } from './getCorporateReducer';
import { getWardNamesReducer } from './getWardNameReducer';
import { getRoomTypeReducer } from './getRoomTypeReducer';
import { getMasterReducer } from './getMasterReducer';


import { menu } from "./menu.reducer";
import { customers } from "./customer.reducer";
import { alert } from "./alert.reducer";
import { forms } from "./form.reducer";
import { registrationMaster } from "./registrationMaster.reducer";
import { locations } from "./location.reducer";
import ThemeOptions from "./ThemeOptions";
import { sweetAlert } from "./sweetAlert.reducer";
import { login } from "./login.reducer";
import { banks } from "./banks.reducer";
import { departments } from "./departments.reducer";
import { costcenters } from "./costcenter.reducer";
import { specialities } from "./speciality.reducer";
import { departmentTypes } from "./departmentTypes.reducer";
import { discountTypes } from "./discountType.reducer";
import { discountAuths } from "./discountAuth.reducer";
import { dischargemaster } from "./dischargemaster.reducer";
import { patients } from "./patient.reducer";
import { newMaster } from "./newmasters.reducer";
import { userMaster } from "./userMaster.reducer";
import { consultants } from "./consultant.reducer";
import { serviceMaster } from "./servicemaster.reducer";
import { referrals } from "./referral.reducer";
import { clinics } from "./clinic.reducer";
import { initialAssesment } from "./initial.assesment.reducer";
import { registrationWithFeesMaster } from "./registrationwithfees.reducer";
import { registrationConsWithFeesMaster } from "./regitrationwithconsfees.reducer";
import { appointments } from "./appointment.reducer";
import { referralConsultants } from "./referalconsultant.reducer";
import { opdBilling } from "./opbilling.reducer";
import { laboratoryMasters } from "./laboratoryMaster.reducer";
import { tpaMaster } from "./tpa.reducer";
import { corporateMaster } from "./corporate.reducer";
import { notifications } from "./notification.reducer";
import { supplierReducer } from "./supplier.reducer";
import {labpatient} from './labpatient.reducer'
import {wardManagement} from './wardmanagement.reducer'
import {patientQueue} from './patientQueue.reducer'
import {pharmacies} from './pharmacy.reducer'
import {stores} from './store.reducer';
import {bookings} from './otbooking.reducer'
import {Loader} from "./loader";
import {otMasters} from './otMasters.reducer'
import { reports } from './reports.reducer'
import{ConsentForm} from './consentform.reducer'
const rootReducer = combineReducers({
  Loader,
  sweetAlert,
  alert,
  menu,
  customers,
  tpaMaster,
  corporateMaster,
  wardManagement,
  forms,
  ThemeOptions,
  registrationMaster,
  locations,
  login,
  banks,
  departments,
  costcenters,
  specialities,
  departmentTypes,
  discountAuths,
  discountTypes,
  dischargemaster,
  patients,
  newMaster,
  userMaster,
  consultants,
  serviceMaster,
  referrals,
  clinics,
  appointments,
  opdBilling,
  initialAssesment,
  registrationWithFeesMaster,
  registrationConsWithFeesMaster,
  referralConsultants,
  laboratoryMasters,
  notifications,
  supplierReducer,
  labpatient,
  patientQueue,
  pharmacies,
  stores,
  bookings,
  otMasters,
  reports,
  addNewPatient : addNewPatientReducer,
  getAdmTypes : getAdmTypeReducer,
  getNewConsultant : getNewConsultantReducer,
  getReferals : getReferalReducer,
  getInsurance : getInsuranceReducer,
  getCorporate : getCorporateReducer,
  getWardNames : getWardNamesReducer,
  getRoomTypes : getRoomTypeReducer,
  masters : getMasterReducer,
  ConsentForm
});

export default rootReducer;
