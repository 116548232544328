import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

const LinkModal = props => {
  // const [modal, setModal] = useState(false);

  // const toggle = () => {
  //   setModal(!modal);
  // };

  const submitForm = () => {
    console.log("hii");
  };

  return (
    <span className="d-inline-block mb-2 mr-2">
      {/* <Button color="primary" onClick={toggle}>
        Address Form Modal
      </Button> */}
      <Modal
        className="modal-content"
        isOpen={props.isOpen}
        toggle={props.toggle}
      >
        <ModalHeader style={{ borderRadius: "20px" }} toggle={props.toggle}>
          Address Information Edit
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <h1>Hiii</h1>
          </Fragment>
        </ModalBody>
        {/* <ModalFooter>
          <Button type="submit" color="primary" onClick={toggle}>
            Update Address
          </Button>
        </ModalFooter> */}
      </Modal>
    </span>
  );
};

export default LinkModal;
