import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from "reactstrap";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { loginservice } from "../../services/login.service";
import Switch from "react-switch";
import CustomButtons from "../Components/customebuttons";

import { referralActions, sweetAlertActions } from "../../actions";
import PageTitle from "../MyComponents/PageTitle";
import { apiUrl } from "../../constants";
import { Icons } from "../../helpers/IconHelper";

class ReferralMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      isAuthorized: false,
      modal: false,
      mode: "",
    };
    this.OnAddClick = this.OnAddClick.bind(this);
  }

  OnAddClick() {
    let { history } = this.props;
    history.push(`${this.props.match.url}/add`);
  }

  editBtn_Click(item) {
    this.setState({
      mode: "EDIT",
      selectedItem: item,
      name: item.name,
      code: item.code,
      degree: item.degree,
      email: item.email,
      mobile: item.mobile,
      address: item.address,
    });
    this.toggle();
  }

  deleteBtn_Click(data) {
    let { dispatch } = this.props;
    let payload = {
      _id: data._id,
      updated_by: "admin",
    };
    dispatch(referralActions.deleteServices(payload));
  }

  componentDidMount() {
    const { referrals, dispatch } = this.props;
    dispatch(referralActions.getAll());
  }

  onChangeText(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  onLogin() {
    const { username, password, mode, selectedItem } = this.state;
    const { dispatch } = this.props;
    const payload = { username, password, login_type: "consultant" };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          dispatch(referralActions.selectreferral(selectedItem._id));
          this.props.history.push({
            pathname: "/referrals/new-referral-registration/add",
            state: {
              mode: "EDIT",
              selectedItem: selectedItem,
            },
          });

          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  handleActiveChange(data) {
    let { dispatch } = this.props;
    let payload = {
      _id: data._id,
      updated_by: "admin",
    };
    dispatch(referralActions.deactiveServices(payload));
  }

  columns = [
    {
      name: "S.No",
      selector: (row, idx) => {
        let index = this.props.referrals.findIndex((value) => value === row);
        return index + 1;
      },
      sortable: true,
      width: "80px",
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      width: "120px",
    },
    {
      name: "Name",
      sortable: true,
      selector: "name",
      width: "200px",
    },
    {
      name: "Degree",
      sortable: true,
      selector: "degree",
      width: "100px",
    },
    {
      name: "Mobile",
      sortable: true,
      selector: "mobile",
      width: "140px",
    },
    {
      name: "Email",
      sortable: true,
      selector: "email",
      width: "200px",
    },
    {
      name: "Address",
      sortable: true,
      selector: "address",
      width: "250px",
    },
    {
      name: "Active",
      sortable: true,
      selector: (row) => (
        <FormGroup>
          <Switch
            onChange={(e) => {
              this.handleActiveChange(row);
            }}
            checked={row.active}
            className="mr-2 mb-2"
            id="normal-switch"
          />
        </FormGroup>
      ),
      width: "100px",
    },
    {
      name: "Actions",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <>
          <a
            style={{ padding: 3, margin: 2 }}
            onClick={() => {
              this.editBtn_Click(row);
            }}
            title="Edit"
            className="text-warning font-icon-wrapper "
          >
            {" "}
            <FontAwesomeIcon
              className="icon-style"
              icon={faUserEdit}
              size="1x"
            />
          </a>
          {row.deleted ? (
            <CustomButtons
              type="restore"
              title="Restore"
              onClickHandler={() => {
                this.deleteBtn_Click(row);
              }}
              width="32px"
            />
          ) : (
            <CustomButtons
              type="delete"
              title="Delete"
              onClickHandler={() => {
                this.deleteBtn_Click(row);
              }}
              width="32px"
            />
          )}
        </>
      ),
    },
  ];
  render() {
    const { referrals } = this.props;
    return (
      <Fragment>
        <PageTitle
          icon={Icons.PatientRegistration}
          heading="New Referral Registration"
          printComponent={this.componentRef}
          data={referrals}
          columns={this.columns}
          csvFilename="newreferralregistration.csv"
        />
        <Card>
          <CardHeader>
            <Row style={{ width: "100%" }}>
              <Col md={4}>IPD Referral Doctor </Col>
              <Col md={4}></Col>
              <Col md={4}>
                <Button color="primary" onClick={this.OnAddClick}>
                  Add
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div ref={(el) => (this.componentRef = el)}>
              <DataTable
                keyField="_id"
                pagination={true}
                expandableRows={false}
                expandOnRowClicked={false}
                dense
                noHeader
                striped
                paginationPerPage={25}
                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                columns={this.columns}
                data={referrals}
              />
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              className={this.props.className}
              style={{ width: 400 }}
            >
              <ModalHeader toggle={() => this.toggle()}>
                Authentication Required!
              </ModalHeader>
              <ModalBody>
                <Row form>
                  <Col md={8} sm={8} className="offset-sm-1">
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        onChange={(e) => {
                          this.onChangeText(e);
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        onChange={(e) => {
                          this.onChangeText(e);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="reason">Reason for Edit</Label>
                      <Input
                        type="text"
                        name="reason"
                        placeholder="Reason for Edit"
                        onChange={(e) => {
                          this.onChangeText(e);
                        }}
                      />
                    </FormGroup>
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col md={7}></Col>
                  <Col md={2}>
                    {" "}
                    <Button color="link" onClick={() => this.toggle()}>
                      Cancel
                    </Button>
                  </Col>
                  <Col md={2}>
                    {" "}
                    <Button color="primary" onClick={() => this.onLogin()}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { referrals } = state;
  return { referrals: referrals.referrals };
}

const connectedReferralMaster = connect(mapStateToProps)(ReferralMaster);
export default connectedReferralMaster;
