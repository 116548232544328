import {helperService} from "./helper.service"
import {apiUrl} from "../constants"


export const corporateService = {  
    add, 
    update,   
    remove, 
    getAll,
    changeActive   
   };


function getAll() {
    const requestOptions = {
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        method: 'GET'
    };
    return fetch(`${apiUrl}/corporates`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/corporates`, requestOptions).then(helperService.handleResponse);
}

function update(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/corporates/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/corporates/delete`, requestOptions).then(helperService.handleResponse);
}

function changeActive(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/corporates/changeActive`, requestOptions).then(helperService.handleResponse);
}


