import React, { Fragment, Component } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputComponent from "../../utils/InputComponent/InputComponent";
import InputFlexComponent from "../../utils/InputFlexComponent/InputFlexComponent";
import {} from "react";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Address is Required"),
  pincode: Yup.string().required("Pincode is Required"),
  alt_contact: Yup.string().required("Alt Contact is Required"),
  attender: Yup.string().required("Attender is Required"),
});

class PatientAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,

      initialValues: {
        name: "",
        pincode: "",
        add1: "",
        alt_contact: "",
        email: "",
        age: "",
        attender: "",
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          add1: data.add1,
          email: data.email,
          alt_contact: data.alt_contact,
          age: data.age,
          pincode: data.pincode,
          attender: data.attender,
        },
      });
    }
  }

  // const [modal, setModal] = useState(false);

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  submitForm(values) {
    this.props.onChange(values);
  }

  handleChange(e) {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [e.target.name]: e.target.value,
      },
    });
  }

  render() {
    return (
      <span className="d-inline-block mb-2 mr-2">
        {/* <Button color="primary" onClick={toggle}>
        Address Form Modal
      </Button> */}
        <Modal
          className="modal-content"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader style={{ borderRadius: "20px" }} toggle={this.toggle}>
            Address Information Edit
          </ModalHeader>
          <ModalBody>
            <Fragment>
              <Formik
                initialValues={this.state.initialValues}
                enableReinitialize
                validationSchema={SignupSchema}
                // onSubmit={(values) => {
                //   this.submitForm(values);
                // }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  // console.log(values);
                  return (
                    <Form
                      style={{ padding: "40px" }}
                      // onSubmit={(e, values) => this.submitForm(e, values)}
                    >
                      <Row form>
                        <Col lg={8}>
                          <FormGroup>
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Address
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="textarea"
                                  onChange={(e) => this.handleChange(e)}
                                  name="add1"
                                  value={values.add1}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Pincode
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  onChange={(e) => this.handleChange(e)}
                                  name="pincode"
                                  value={values.pincode}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          {/* <FormGroup>
                          <InputComponent
                            label="Address"
                            star="*"
                            tag={Field}
                            type="select"
                            name="address"
                            // value={values.name}
                            // lines= {3}
                            onChange={this.handleChange}
                            placeholder="Enter Address"
                            className={
                              errors.name && touched.name ? "invalid" : ""
                            }
                            errors={errors.name}
                            touched={touched.name}
                          />
                        </FormGroup> */}

                          <FormGroup>
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Alt Contact
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  onChange={(e) => this.handleChange(e)}
                                  name="alt_contact"
                                  value={values.alt_contact}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          {/* <FormGroup>
                          <InputComponent
                            label="Alt Contact"
                            star="*"
                            tag={Field}
                            type="text"
                            name="alt_contact"
                            value={values.alt_contact}
                            onChange={this.handleChange}
                            placeholder="Enter Alt Contact"
                            className={
                              errors.alt_contact && touched.alt_contact
                                ? "invalid"
                                : ""
                            }
                            errors={errors.alt_contact}
                            touched={touched.alt_contact}
                          />
                        </FormGroup> */}
                        </Col>
                        <Col md={8}>
                          <FormGroup>
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Email
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  onChange={(e) => this.handleChange(e)}
                                  name="email"
                                  value={values.email}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Age
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  onChange={(e) => this.handleChange(e)}
                                  name="age"
                                  value={values.age}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          {/* <FormGroup>
                          <InputComponent
                            label="Pincode"
                            star="*"
                            tag={Field}
                            type="text"
                            name="pincode"
                            value={values.pincode}
                            onChange={this.handleChange}
                            placeholder="Enter Pincode"
                            errors={errors.pincode}
                            touched={touched.pincode}
                          />
                        </FormGroup> */}

                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <Label className="input-label-component">
                                  Attender
                                </Label>
                              </Col>
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  onChange={(e) => this.handleChange(e)}
                                  name="attender"
                                  value={values.attender}
                                  disabled={
                                    this.props.mode === "View" ? true : false
                                  }
                                ></Input>
                              </Col>
                            </Row>
                          </FormGroup>

                          {/* <FormGroup>
                          <InputComponent
                            label="Attender"
                            star="*"
                            tag={Field}
                            type="text"
                            name="attender"
                            value={values.attender}
                            onChange={this.handleChange}
                            placeholder="Enter Attender"
                            className={
                              errors.attender && touched.attender
                                ? "invalid"
                                : ""
                            }
                            errors={errors.attender}
                            touched={touched.attender}
                          />
                        </FormGroup> */}
                        </Col>
                      </Row>
                      <Button
                        // type="submit"
                        color="primary"
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                          backgroundColor: "#4da8da",
                          marginTop: "20px",
                        }}
                        onClick={() => this.submitForm(values)}
                        disabled={this.props.mode === "View" ? true : false}
                      >
                        Update Address
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Fragment>
          </ModalBody>
          {/* <ModalFooter>
          <Button type="submit" color="primary" onClick={toggle}>
            Update Address
          </Button>
        </ModalFooter> */}
        </Modal>
      </span>
    );
  }
}

export default PatientAddressModal;
