import React, { Component, Fragment } from 'react';

import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Table, Form, FormGroup, Label, Input, CustomInput, CardHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import {tpaMasterActions} from '../../../actions'
import {history} from '../../../helpers/history';


class TPAAddForm extends Component {

    constructor(props){
        super(props);
        this.state={
            formData : props.location.state ? props.location.state.formData : null,
        }
        this.onChange= this.onChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    onChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSaveClick(){
        let {dispatch} = this.props;
        let payload = {
            name : this.state.name,
            address : this.state.address,
            primary_contact_name : this.state.primary_contact_name,
            secondary_contact_name: this.state.secondary_contact_name,
            primary_contact_no : this.state.primary_contact_no,
            secondary_contact_no : this.state.secondary_contact_no,
            patient_details_submission_within : this.state.patient_details_submission_within,
            repayment_cycle_after : this.state.repayment_cycle_after,
            repayment_cycle_within : this.state.repayment_cycle_within
        }

        dispatch(tpaMasterActions.add(payload))

    }

    onUpdateClick(){
        let {dispatch} = this.props;
        let payload = {
            code : this.state.code,
            name : this.state.name,
            address : this.state.address,
            primary_contact_name : this.state.primary_contact_name,
            secondary_contact_name: this.state.secondary_contact_name,
            primary_contact_no : this.state.primary_contact_no,
            secondary_contact_no : this.state.secondary_contact_no,
            patient_details_submission_within : this.state.patient_details_submission_within,
            repayment_cycle_after : this.state.repayment_cycle_after,
            repayment_cycle_within : this.state.repayment_cycle_within
        }
        dispatch(tpaMasterActions.update(payload))
    }

    componentDidMount(){
        if(this.state.formData){
            let data = this.state.formData;
            this.setState({
                code : data.code,
            name : data.name,
            address : data.address,
            primary_contact_name : data.primary_contact_name,
            secondary_contact_name: data.secondary_contact_name,
            primary_contact_no : data.primary_contact_no,
            secondary_contact_no : data.secondary_contact_no,
            patient_details_submission_within : data.patient_details_submission_within,
            repayment_cycle_after : data.repayment_cycle_after,
            repayment_cycle_within : data.repayment_cycle_within,
            isEditFlow: true
            })
        }
    }

    onClearClick(){

        this.setState({
            name : "",
            address : "",
            primary_contact_name : "",
            secondary_contact_name: "",
            primary_contact_no : "",
            secondary_contact_no : "",
            patient_details_submission_within : "",
            repayment_cycle_within : "",
            repayment_cycle_after : "",
            isEditFlow: false
        })

    }

    onBackClick(){
        history.goBack()
    }

    render(){
        return(
            <Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>TPA Master</CardTitle>
                        <Row>
                            <Col md={4}>
                            <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Input type="text" name="name" id="name"
                                               placeholder="Name" 
                                               onChange={this.onChange}
                                               value={this.state.name}/>
                                    </FormGroup>
                            </Col>
                            <Col md={4}>
                            <FormGroup>
                                        <Label for="address">Address</Label>
                                        <Input type="text" name="address" id="address"
                                               placeholder="Address" 
                                               onChange={this.onChange}
                                               value={this.state.address}/>
                                    </FormGroup>
                            </Col>
                            <Col md={4}>
                            <FormGroup>
                                        <Label for="shortname">Short Name</Label>
                                        <Input type="text" name="shortname" id="shortname"
                                               placeholder="shortname" 
                                               onChange={this.onChange}
                                               value={this.state.shortname}/>
                                    </FormGroup>
                            </Col>
                           
                        </Row>
                        <Row>
                        <Col md={3}>
                                    <FormGroup>
                                        <Label for="primary_contact_name">Primary Contact Name</Label>
                                        <Input type="text" name="primary_contact_name" id="primary_contact_name"
                                               placeholder="Primary Contact Name" 
                                               onChange={this.onChange}
                                               value={this.state.primary_contact_name}/>
                                    </FormGroup>
                            </Col>
                        <Col md={3}>
                                    <FormGroup>
                                        <Label for="primary_contact_no">Primary Contact No</Label>
                                        <Input type="text" name="primary_contact_no" id="primary_contact_no"
                                               placeholder="Primary Contact No" 
                                               onChange={this.onChange}
                                               value={this.state.primary_contact_no}/>
                                    </FormGroup>
                            </Col>
                            <Col md={3}>
                                    <FormGroup>
                                        <Label for="secondary_contact_name">Secondary Contact Name</Label>
                                        <Input type="text" name="secondary_contact_name" id="secondary_contact_name"
                                               placeholder="Secondary Contact Name" 
                                               onChange={this.onChange}
                                               value={this.state.secondary_contact_name}/>
                                    </FormGroup>
                            </Col>
                            <Col md={3}>
                                    <FormGroup>
                                        <Label for="secondary_contact_no">Secondary Contact No</Label>
                                        <Input type="text" name="secondary_contact_no" id="secondary_contact_no"
                                               placeholder="Secondary Contact No" 
                                               onChange={this.onChange}
                                               value={this.state.secondary_contact_no}/>
                                    </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                        <Col md={3}>
                                    <FormGroup>
                                        <Label for="patient_details_submission_within">Patient Details Submission Within</Label>
                                        <Input type="text" name="patient_details_submission_within" id="tm_patient_details_submission_within"
                                               placeholder="Days" 
                                               onChange={this.onChange}
                                               value={this.state.patient_details_submission_within}/>
                                    </FormGroup>
                            </Col>
                            <Col md={3}>
                                    <FormGroup>
                                        <Label for="repayment_cycle_after">Repayment Cycle After</Label>
                                        <Input type="text" name="repayment_cycle_after" id="repayment_cycle_after"
                                               placeholder="Days" 
                                               onChange={this.onChange}
                                               value={this.state.repayment_cycle_after}/>
                                    </FormGroup>
                            </Col>
                            <Col md={3}>
                                    <FormGroup>
                                        <Label for="repayment_cycle_within">Repayment Within</Label>
                                        <Input type="text" name="repayment_cycle_within" id="repayment_cycle_within"
                                               placeholder="Days" 
                                               onChange={this.onChange}
                                               value={this.state.tm_repayment_cycle_within}/>
                                    </FormGroup>
                            </Col>
                            <Col md={3}>

                           
                        <div className="buttonWrapper floatRight" style={{marginTop:"28px"}}>
                            {
                                this.state.isEditFlow?
                                <Button color="primary" onClick={this.onUpdateClick} className="marginTenPx">Update</Button>:<Button className="marginTenPx" color="primary" onClick={this.onSaveClick}>Save</Button>
                            }
                            <Button color="primary" className="marginTenPx" onClick={this.onClearClick}>Clear</Button>
                            <Button color="warning" className="marginTenPx" onClick={this.onBackClick}>Back to Grid</Button>
                            
                        </div>
                        </Col>
                        </Row>
                    </CardBody>
                </Card>

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    let { customers } = state;

    return {
        customers: customers
    };
}

const connectedViewMaster = connect(mapStateToProps)(TPAAddForm);

export { connectedViewMaster as TPAAddForm };