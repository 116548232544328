import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import ReactToPrint from 'react-to-print';

import LaddaButton, {
    ZOOM_IN,
} from 'react-ladda';

import {
    Button,
    UncontrolledTooltip,
    Row,Col
} from 'reactstrap';

import {
    toast,
    Slide
} from 'react-toastify';

import {
  faPrint,
 faFileExport,
 faSave,
  
 } from '@fortawesome/free-solid-svg-icons';
 import CsvDownload from 'react-json-to-csv'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class PageTitle extends React.Component {

   

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,

            heading,
            icon,
            subheading
        } = this.props;
        return (

            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div style={{borderRadius:'50%',padding:'10',backgroundColor:'#fff'}} className="shadow">
                            <img src={icon} style={{height:70}}/>
                            {/* <i className={icon}/> */}
                        </div>
                        <div style={{marginLeft:10}}>
                          <h4>  {heading}</h4>
                            <div
                                className={cx("page-title-subheading", {'d-none': !enablePageTitleSubheading})}>
                                {subheading}
                            </div>
                        </div>
                    </div>
                    <div className="page-title-actions">
                     
                    <ReactToPrint
          trigger={() =>  <Button className="mb-2 mr-2 btn-icon" color="info">
          <FontAwesomeIcon icon={faPrint} size="1x"/>  
           Print List
          </Button>}
          content={() => this.props.printComponent}
        />
                            
                                  
                                                 <Button className="mb-2 mr-2 btn-icon" color="success">
                                    <FontAwesomeIcon icon={faFileExport} size="1x"/>  
                                    <CsvDownload  style={{backgroundColor:'transparent',color:'#fff',border:0,outline:0}} data={this.props.data} filename={this.props.csvFilename} >
                                        Export to Excel
                                    </CsvDownload>
                                    </Button>  

           
                    
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});
PageTitle.defaultProps={
    printComponent:null,
    data:[],
    csvFilename:"CSVfile.csv"

}

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);