//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const labSubTestMappingService = {        
    getAll,
    add,
    update,
    remove,
    getByService,
    getById,
    updateMany,
    removeSubtest,
    getValues,
    activeSubtest,
    getByDate
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratorysubtestMapping`, requestOptions).then(helperService.handleResponse);
}

function getValues() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratorysubtestMapping/get/getValues`, requestOptions).then(helperService.handleResponse);
}

function getByDate(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratorysubtestMapping?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}

function getByService(service) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratorysubtestMapping?service=${service}`, requestOptions).then(helperService.handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/laboratorysubtestMapping/${id}`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/laboratorysubtestMapping`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratorysubtestMapping/update`, requestOptions).then(helperService.handleResponse);
}

function updateMany(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratorysubtestMapping/updateMany`, requestOptions).then(helperService.handleResponse);
}
function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratorysubtestMapping/delete`, requestOptions).then(helperService.handleResponse);
}
function removeSubtest(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratorysubtestMapping/removeSubtest`, requestOptions).then(helperService.handleResponse);
}

function activeSubtest(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/laboratorysubtestMapping/activeSubtest`, requestOptions).then(helperService.handleResponse);
}
