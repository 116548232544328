import { servieMasterConstants } from "../constants/servicemaster.constants";

export function serviceMaster(state = {}, action) {
  switch (action.type) {
    case servieMasterConstants.ADD_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.ADD_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case servieMasterConstants.ADD_FAILURE:
      return { ...state, error: action.error, loading: false };
    case servieMasterConstants.EDIT_PACKAGE_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.EDIT_PACKAGE_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case servieMasterConstants.EDIT_PACKAGE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case servieMasterConstants.GET_ALL_SERVICES_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        data: state.data
          ? { ...state.data, services: action.services }
          : { services: action.services },
        loading: false,
      };
    case servieMasterConstants.GET_ALL_SERVICES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case servieMasterConstants.ADD_PACKAGE_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.ADD_PACKAGE_SUCCESS:
      return { ...state, package: action.data, loading: false };
    case servieMasterConstants.ADD_PACKAGE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case servieMasterConstants.DELETE_SERVICE_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.DELETE_SERVICE_SUCCESS:
      let data;
      if (state.data) {
        data = { ...state.data };
        if (data) {
          if (action.servicetype === "services") {
            state.data.services[action.index].isDeleted = !state.data.services[
              action.index
            ].isDeleted;
            state.data.services[action.index].isActive = !state.data.services[
              action.index
            ].isActive;
          } else if (action.servicetype === "packages") {
            state.data.packages[action.index].isDeleted = !state.data.packages[
              action.index
            ].isDeleted;
            state.data.packages[action.index].isActive = !state.data.packages[
              action.index
            ].isActive;
          } else if (action.servicetype === "groups") {
            state.data.groups[action.index].isDeleted = !state.data.groups[
              action.index
            ].isDeleted;
            state.data.groups[action.index].isActive = !state.data.groups[
              action.index
            ].isActive;
          }
        }
      }

      return { ...state, data: data, loading: false };
    case servieMasterConstants.DELETE_SERVICE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case servieMasterConstants.DEACTIVE_SERVICE_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.DEACTIVE_SERVICE_SUCCESS:
      let deactivedata;
      if (state.data) {
        deactivedata = { ...state.data };
        if (deactivedata) {
          if (action.servicetype === "services") {
            state.data.services[action.index].isActive = !state.data.services[
              action.index
            ].isActive;
          } else if (action.servicetype === "packages") {
            state.data.packages[action.index].isActive = !state.data.packages[
              action.index
            ].isActive;
          } else if (action.servicetype === "groups") {
            state.data.groups[action.index].isActive = !state.data.groups[
              action.index
            ].isActive;
          }
        }
      }
      return { ...state, loading: false };
    case servieMasterConstants.DEACTIVE_SERVICE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case servieMasterConstants.GET_ALL_PACKAGES_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        data: state.data
          ? { ...state.data, packages: action.data }
          : { packages: action.data },
        loading: false,
      };
    case servieMasterConstants.GET_ALL_PACKAGES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case servieMasterConstants.GET_ALL_GROUPS_REQUEST:
      return { ...state, loading: true };
    case servieMasterConstants.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        data: state.data
          ? { ...state.data, groups: action.data }
          : { groups: action.data },
        loading: false,
      };
    case servieMasterConstants.GET_ALL_GROUPS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case servieMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
