import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import $ from "jquery";
import React from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { apiUrl } from "../../constants/config";
import "../index.css";
import CommonTable from "../../components/commonTable";
import { Icons } from "../../helpers/IconHelper";

class PageTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    var { columns } = this.props;
    if (columns) {
      columns = [
        { name: "Sr. No.", sortable: true, width: "60px", selector: "sno" },
        ...columns,
      ];
      columns = columns.filter((col) => col.name !== "Actions");
      this.setState({ data: columns });
    }
  }

  state = { printModel: false };

  togglePrintModal() {
    this.setState({ printModel: !this.state.printModel });
  }

  createExcelData() {
    if (this.props.onExportClicked) {
      this.props.onExportClicked();
    } else {
      try {
        let { columns } = this.props;

        let table = $(this.props.printComponent).find(".rdt_Table");
        let tableBody = $(table).find(".rdt_TableBody");
        let tableRows = $(tableBody)
          .find(".rdt_TableRow")
          .toArray();
        let tableHead = $(table).find(".rdt_TableHead");
        let tableHeadRow = $(tableHead).find(".rdt_TableHeadRow");
        let tableCols = $(tableHeadRow)
          .find(".rdt_TableCol")
          .toArray();

        const columnDelimiter = ",";
        const lineDelimiter = "\n";
        let result;

        const keys = tableCols.map((col) => {
          let t = col.innerText.replace("\n▲", "");
          return t;
        });

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        if (tableRows.length > 0) {
          tableRows.forEach((item) => {
            //let ctr = 0;
            let tableCells = $(item)
              .find(".rdt_TableCell")
              .toArray();
            for (var i = 0; i < keys.length; i++) {
              if (i > 0) result += columnDelimiter;
              result += tableCells[i].innerText;
            }
            result += lineDelimiter;
          });

          const link = document.createElement("a");
          let csv = result;
          if (csv == null) return;

          const filename = this.props.csvFilename || "export.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
          }

          link.setAttribute("href", encodeURI(csv));
          link.setAttribute("download", filename);
          link.click();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];

        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: "li",
      handleSelector: "a",
    };

    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      heading,
      icon,
      subheading,
      data,
      customer,
      columns,
      newTable,
    } = this.props;
    console.log(heading);

    data = data
      ?.map((item, i) => {
        if (!item) return;
        item["sno"] = i + 1;
        return item;
      })
      .filter((item, i) => item !== undefined);

    return (
      <div className="app-page-title" style={{ margin: " -30px -30px 0px" }}>
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            {icon ? (
              <div className="shadow iconWrapper">
                <img src={icon} className="iconWrapper_image" />
              </div>
            ) : null}

            <div style={{ marginLeft: 10 }}>
              <h3> {heading}</h3>
              <div
                className={cx("page-title-subheading", {
                  "d-none": !enablePageTitleSubheading,
                })}
              >
                {subheading}
              </div>
            </div>
          </div>
          <div
            className={`page-title-actions ${this.props.actionWrapperClass}`}
          >
            <Button
              className="mr-2 btn-icon"
              style={{ border: "2px solid #0AA3FF" }}
              color="info"
              onClick={() => {
                this.togglePrintModal();
              }}
              disabled={this.props.disablePrintButton}
            >
              <FontAwesomeIcon icon={faPrint} size="1x" />
              &nbsp; Print List
            </Button>

            <Button
              className="btn-icon"
              style={{ border: "2px solid #0AA3FF" }}
              color="info"
              onClick={() => {
                this.createExcelData();
              }}
              disabled={this.props.disableCsvButton}
            >
              <FontAwesomeIcon icon={faPrint} size="1x" />
              &nbsp; Export to CSV
            </Button>

            <Modal isOpen={false} className="mb-4">
              <ModalHeader>{heading}</ModalHeader>
              <ModalBody></ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.printModel}
              toggle={() => this.togglePrintModal()}
              className="mb-4"
            >
              <ModalHeader toggle={() => this.togglePrintModal()}>
                {heading}
              </ModalHeader>
              <ModalBody>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      className="mb-2 mr-2 btn-icon"
                      style={{ border: "2px solid #0AA3FF" }}
                      color="info"
                    >
                      <FontAwesomeIcon icon={faPrint} size="1x" />
                      &nbsp; Print List
                    </Button>
                  )}
                  content={() => this.componentRef}
                />

                <div ref={(el) => (this.componentRef = el)}>
                  {customer ? (
                    <div
                      className="border"
                      style={{ padding: "10px 10px", paddingBottom: 0 }}
                    >
                      <div style={{ width: "15%", float: "left" }}>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            float: "left",
                          }}
                        >
                          <img
                            style={{ width: "100%", maxHeight: "90px" }}
                            src={
                              apiUrl +
                              (customer.logo_url
                                ? customer.logo_url
                                : Icons.Hospital)
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          float: "left",
                          width: "60%",
                          paddingTop: "10px",
                        }}
                      >
                        <h4>{customer.customer_Tran_Name} </h4>
                        <p>{customer.customer_Tran_Address}</p>
                      </div>
                      <div style={{ width: "15%", float: "right" }}>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            float: "left",
                          }}
                        ></div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="line"></div>
                  {newTable ? (
                    <CommonTable
                      columnHeader={this.props?.columns}
                      tableData={this?.props?.data}
                    />
                  ) : (
                    <DataTable
                      columns={columns || []}
                      keyField="id"
                      data={data}
                      style={{
                        minHeight: 600,
                        width: this.props?.width ?? "100%",
                      }}
                      dense
                      noHeader
                      striped
                      subHeader={false}
                    />
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="link" onClick={() => this.togglePrintModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
  transaction: state.customers.currentTransaction,
  customer: state.customers.selected_customer,
});

const mapDispatchToProps = (dispatch) => ({});
PageTitle.defaultProps = {
  printComponent: null,
  data: [],
  csvFilename: "CSVfile.csv",
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
