import { registrationMasterConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { registrationWithFeesService } from "../services";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";
import { registrationWithFeesConstants } from "../constants/registrationwithfees.constants";

export const registrationFeesActions = {
  add,
  getAll,
  addCons,
  getConsAll,
  deleteCons,
  _delete,
  updateCons,
  update,
  deactive,
};

function oldPayload(data) {
  return {
    id: data.id,
    rtm_code: String(data.id),
    deleted: data.deleted,
    created_by: data.createdBy,
    updated_by: data.updatedBy,
    created_on: data.createdAt,
    updated_on: data.updatedAt,
    op_registration_fees: data.opRegistrationFees,
    ip_registration_fees: data.ipRegistrationFees,
    rtm_reg_type: data.rtmRegType,
  };
}

function getAll(viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.getAll(viewDeleted).then(
      (result) => {
        if (result.isSuccess) {
          // if (flag) {
          //   
            // dispatch(success(actionData));
          // } else {
          //   dispatch(success(result.value));
          // }
          const actionData = result.value.map((value) => oldPayload(value));
          dispatch(success(actionData));
          dispatch(alertActions.success(result));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_FEES_SUCCESS,
      data: { data },
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_FEES_FAILURE,
      error,
    };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.add(payload).then(
      (result) => {
        if (result.isSuccess) {
          const actionData = oldPayload(result.value) 
          dispatch(success(actionData));
          dispatch(alertActions.success(result));
          // dispatch(success(result.value));
          toast("Data Saved Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("Failed to Save Data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("Server Error", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_FEES_FAILURE,
      error,
    };
  }
}

function getConsAll() {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.getConsAll().then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));

          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_CONS_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_CONS_FEES_SUCCESS,
      data: { data },
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.GET_REGISTRATION_CONS_FEES_FAILURE,
      error,
    };
  }
}

function addCons(payload) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.addConsFess(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));
          toast("Successfully Created", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.error));
          toast("Failed to Create", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_FAILURE,
      error,
    };
  }
}

function deleteCons(payload, index) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.deleteConsFees(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value, index));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_CONS_FEES_REQUEST,
    };
  }
  function success(data, index) {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_CONS_FEES_SUCCESS,
      data,
      index
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_CONS_FEES_FAILURE,
      error,
    };
  }
}

function updateCons(payload, index) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.updateConsFees(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value, index));
          toast("Updated Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("Failed to Update", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("Server error", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_REQUEST,
    };
  }
  function success(data, index) {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_SUCCESS,
      data,
      index,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_FAILURE,
      error,
    };
  }
}

function update(payload, index) {
  return (dispatch) => {
    dispatch(request());
    const { id, rtmRegType, ipRegistrationFees, opRegistrationFees } = payload;

    const data = {
      id,
      rtmRegType,
      ipRegistrationFees,
      opRegistrationFees,
    };

    registrationWithFeesService.updateRegFee(data).then(
      (result) => {
        if (result.isSuccess) {
          const actionData = oldPayload(result.value) 
          dispatch(success(actionData, index));
          dispatch(alertActions.success(result));
          
          toast("Updated Successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast("Failed to Update", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("Server Error", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_REQUEST,
    };
  }
  function success(data, index) {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_SUCCESS,
      data,
      index,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_FAILURE,
      error,
    };
  }
}

function _delete(payload, index) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.deleteMaster(payload).then(
      (result) => {
        if (result) {
          dispatch(success(index));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "error",
          // });
        }
      },
      (error) => {
        // toast(error.message, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 5000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.DELETE_REGISTRATION_FEES_FAILURE,
      error,
    };
  }
}

function deactive(payload, index) {
  return (dispatch) => {
    dispatch(request());

    registrationWithFeesService.deactivateMaster(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(index));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_REQUEST,
    };
  }
  function success(data) {
    return {
      type: registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_FAILURE,
      error,
    };
  }
}
