import { data } from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import {
  referralActions,
  registrationMasterActions,
  consultantActions,
} from "../../actions";
import { apiUrl } from "../../constants/config";
import { oldBody } from "../../actions/patient.actions";
import "./index.css";
class PatientInfo extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(registrationMasterActions.getAll());
    dispatch(referralActions.getAll());
    dispatch(consultantActions.getAll());
  }

  // code to value
  codeToValue(code, name) {
    try {
      const { masters } = this.props;
     if (masters)
        return masters[name].list.find((item) => item.id === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  // consultant to value
  consultantToValue(code) {
    try {
      const { referrals } = this.props;
      if (referrals) {
        const reff = referrals.find((item) => item.code === code);
        return reff ? reff.name : code;
      } else return code;
    } catch {
      return code;
    }
  }

  // Consultant Doctor to value
  consultantdoctorToValue(code) {
    try {
      const { consultants } = this.props;
      if (consultants) {
        const consultantdr = consultants.find((item) => item.code === code);
        return consultantdr
          ? consultantdr.firstname + consultantdr.lastname
          : code;
      } else return code;
    } catch {
      return code;
    }
  }

  render() {
    var { patient } = this.props;
    if(patient.patient_uhid === undefined){ // checking the patient object is in the new body is so then convert it to old body
      patient = oldBody(patient);
    }
    return (
      <>
        {patient?.id && (
          <div className="patientInfo">
            <div style={{ float: "left", width: "15%" }}>
              <div className="icon-wrapper rounded-circle ">
                <div className="icon-wrapper-bg" />
                {patient.confidentiality ? (
                  ""
                ) : (
                  <img
                    src={`${apiUrl + patient.photo}`}
                    style={{ height: "100%", width: "100%" }}
                  />
                )}
              </div>
            </div>
            <div style={{ float: "left", width: "29%" }}>
              <div>
                <div style={{ fontSize: "1.5rem" }}>
                  {patient.confidentiality
                    ? " X X X X X"
                    : `${patient.firstname} ${patient.middlename} ${patient.lastname}`}
                </div>
                <div style={{ color: "black" }}>
                  <div className="d-inline text-danger pr-1">
                    <b>
                      {" "}
                      <span className="pl-1">UHID : </span>
                    </b>
                  </div>
                  {patient.confidentiality ? " X X X X X" : patient.patient_uhid}
                  <br />
                  <div className="d-inline text-danger pr-1">
                    <span className="pl-1">
                      <b>Email</b> :{" "}
                      {patient.confidentiality ? " X X X X X" : patient.email}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ float: "left", width: "20%" }}>
              <div>
                <div className="d-inline text-danger pr-1">
                  <b>
                    <span className="pl-1">Patient Type : </span>
                  </b>
                </div>
                {patient.confidentiality
                  ? " X X X X X"
                  : this.codeToValue(patient.patientType, "PatientTypes")}
                <br />{" "}
                <div className="d-inline text-danger pr-1">
                  <span className="pl-1">
                    <b>Age</b> :{" "}
                    {patient.confidentiality ? (
                      " X X X X X"
                    ) : (
                      <>
                        {patient.ageInYears ? patient.ageInYears : 0}
                        <b> Y </b>
                        {patient.ageInMonths ? patient.ageInMonths : 0}
                        <b> M </b>
                        {patient.ageInDays ? patient.ageInDays : 0}
                        <b> D</b>
                      </>
                    )}{" "}
                  </span>
                </div>
                <br />
                <div className="d-inline text-danger pr-1">
                  <span className="pl-1">
                    <b> Gender</b> :{" "}
                    {patient.confidentiality
                      ? " X X X X X"
                      : this.codeToValue(patient.gender_code, "Genders")}{" "}
                  </span>
                </div>
                <br />
                <div className="d-inline text-danger pr-1">
                  <span className="pl-1">
                    <b>Mobile</b> :{" "}
                    {patient.confidentiality ? " X X X X X" : patient.mobile}{" "}
                  </span>
                </div>
                <br />
                <div className="d-inline text-danger pr-1">
                  <span className="pl-1">
                    <b>Sec. Contact</b> :{" "}
                    {patient.confidentiality
                      ? " X X X X X"
                      : patient.secondaryContact}{" "}
                  </span>
                </div>
                <br />
                <div className="d-inline text-danger pr-1">
                  <span className="pl-1">
                    <b>Email</b> :{" "}
                    {patient.confidentiality ? " X X X X X" : patient.email}{" "}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ float: "left", width: "24%" }}>
              <div>
                <div>
                  <div className="d-inline text-danger pr-1">
                    <span className="pl-1">
                      <b>Referal Doctor</b> :{" "}
                      {patient.confidentiality
                        ? " X X X X X"
                        : patient.referralDoctor
                        ? this.consultantToValue(patient.referralDoctor)
                        : patient.referralDoctor}{" "}
                    </span>
                  </div>
                  <br />
                  <div className="d-inline text-danger pr-1">
                    <span className="pl-1">
                      <b>Consultant Doctor</b> :{" "}
                      {patient.confidentiality
                        ? " X X X X X"
                        : patient.consultantId
                        ? this.consultantdoctorToValue(patient.consultantId)
                        : patient.consultantId}{" "}
                    </span>
                  </div>
                  <br />
                  <div className="d-inline text-danger pr-1">
                    <span className="pl-1">
                      <b>Confidentiality </b>:
                      {patient.confidentiality ? " Yes" : " No"}
                    </span>
                  </div>
                  <br />
                  <div className="d-inline text-danger pr-1">
                    <span className="pl-1">
                      <b>Registered As</b> :{" "}
                      {patient.confidentiality
                        ? " X X X X X"
                        : patient.registeringAs
                        ? patient.registeringAs.toUpperCase()
                        : null}{" "}
                    </span>
                  </div>

                  {patient.registeringAs === "other" ? (
                    <>
                      {" "}
                      <br />
                      <div className="d-inline text-danger pr-1">
                        <span className="pl-1">
                          <b>Relationship </b>:{" "}
                          {patient.relationship
                            ? patient.confidentiality
                              ? "X X X X X"
                              : this.codeToValue(
                                  patient.relationship,
                                  "relationships"
                                )
                            : null}{" "}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {patient &&
                  patient.registering_as === "other" &&
                  patient.partner_uhid !== "" ? (
                    <>
                      {" "}
                      <br />
                      <div className="d-inline text-danger pr-1">
                        <span className="pl-1">
                          <b>Partner UHID</b> :{" "}
                          {patient.confidentiality
                            ? " X X X X X"
                            : patient.partnerUhid}{" "}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div style={{ float: "left", width: "12%" }}>
              <div className="icon-wrapper">
                <div className="icon-wrapper-bg" />
                <img
                  src={`${apiUrl + "/" + patient.qrcode}`}
                  style={{ height: "100%", width: "100%", padding: 5 }}
                />
              </div>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { registrationMaster, referrals, consultants } = state;
  return {
    masters: registrationMaster.dataAll,
    referrals: referrals.referrals,
    consultants: consultants.consultants,
  };
}

const connectedPatientInfo = connect(mapStateToProps)(PatientInfo);
export default connectedPatientInfo;
