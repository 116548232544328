import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const departmentService = {
  getAll: getAll,
  add,
  active,
  update,
  remove,
  addSubdepartment,
  updateSubdepartment,
  removeSubdepartment,
  getStock,
  getSubDepartmentById: getSubDepartmentById,
  getDepartmentTypes: getDepartmentTypes,
  getAllSubdepartments,
};

function getAll(viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/Departments?viewDeleted=${viewDeleted}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/Departments`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/Departments/${data.departmentId}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function active(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json/",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/newdepartments/active`, requestOptions).then(
    helperService.handleResponse
  );
}
function remove(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departments/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function addSubdepartment(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/SubDepartments`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateSubdepartment(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/SubDepartments/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function removeSubdepartment(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(
    `${apiUrl}/SubDepartments/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}

//get stock from department stock table

function getStock() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departmentStock`, requestOptions).then(
    helperService.handleResponse
  );
}

function getSubDepartmentById(depertment) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/subdepartments/${depertment}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getDepartmentTypes() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departmentType`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllSubdepartments(DepartmentId, viewDeleted = true) {
  const url = new URL(`${apiUrl}/SubDepartments/${DepartmentId}?viewDeleted=${viewDeleted}`);
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(url, requestOptions).then(helperService.handleResponse);
}
