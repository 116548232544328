import { opBillingConstants } from "../constants";
import { reportsConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { opBillingService } from "../services";
import { reportsService } from "../services";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";

export const reportsActions = {
	getDashboardReportCharts,
  getReceiptWiseCollectionReport
};

function getDashboardReportCharts(payload) {
  return (dispatch) => {
    dispatch(request());

    reportsService.getDashboardReportCharts(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: reportsConstants.GET_PATIENT_REG_COUNT_REQUEST };
  }
  function success(data) {
    return { type: reportsConstants.GET_PATIENT_REG_COUNT_SUCCESS, data };
  }
  function failure(error) {
    return { type: reportsConstants.GET_PATIENT_REG_COUNT_FAILURE, error };
  }
}

function getReceiptWiseCollectionReport(payload) {
  return (dispatch) => {
    dispatch(request());

    reportsService.getReceiptWiseCollectionReport(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: reportsConstants.GET_BILLWISE_COLLECTION_REQUEST };
  }
  function success(data) {
    return { type: reportsConstants.GET_BILLWISE_COLLECTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: reportsConstants.GET_BILLWISE_COLLECTION_FAILURE, error };
  }
}

