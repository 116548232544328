import { clinicConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { clinicService } from "../services";
import { toast, Bounce } from "react-toastify";

export const clinicActions = {
  getAll,
  add,
  update,
  selectclinic,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    clinicService.getAll().then(
      (data) => {
        if (data.success) {
          dispatch(success(data.clinics));
          dispatch(alertActions.success("clinics loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: clinicConstants.GET_ALL_CLINIC_REQUEST };
  }
  function success(clinics) {
    return { type: clinicConstants.GET_ALL_CLINIC_SUCCESS, clinics };
  }
  function failure(error) {
    return { type: clinicConstants.GET_ALL_CLINIC_FAILURE, error };
  }
}

function selectclinic(id) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(id));
    dispatch(alertActions.success("clinic Selected"));
  };

  function request() {
    return { type: clinicConstants.SELECT_CLINIC_REQUEST };
  }
  function success(clinicId) {
    return { type: clinicConstants.SELECT_CLINIC_SUCCESS, clinicId };
  }
  function failure(error) {
    return { type: clinicConstants.SELECT_CLINIC_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    clinicService.add(payload).then(
      (result) => {
        if (result.success === true) {
          dispatch(success(result.clinic));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          toast("Invalid data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "warning",
          });
          dispatch(failure("Error in saving data"));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: clinicConstants.ADD_CLINIC_REQUEST };
  }
  function success(clinic) {
    return { type: clinicConstants.ADD_CLINIC_SUCCESS, clinic };
  }
  function failure(error) {
    return { type: clinicConstants.ADD_CLINIC_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());

    clinicService.update(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.clinic));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: clinicConstants.UPDATE_CLINIC_REQUEST };
  }
  function success(clinic) {
    return { type: clinicConstants.UPDATE_CLINIC_SUCCESS, clinic };
  }
  function failure(error) {
    return { type: clinicConstants.UPDATE_CLINIC_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    clinicService.remove(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: clinicConstants.DELETE_CLINIC_REQUEST };
  }
  function success(clinic) {
    return { type: clinicConstants.DELETE_CLINIC_SUCCESS, clinic };
  }
  function failure(error) {
    return { type: clinicConstants.DELETE_CLINIC_FAILURE, error };
  }
}
