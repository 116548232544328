import React, { Component } from "react";
import Select from "react-select";
import { apiUrl } from "../../constants/config";
import "./form.css";

export default class MySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      fatchData: [],
      size: 1,
      flag: this.props.triggerChangeOnLoad,
    };
    this.selectRef = React.createRef();
  }
  getProperty(obj, prop) {
    var parts = prop.split("."),
      last = parts.pop(),
      l = parts.length,
      i = 1,
      current = parts[0];
    if (parts.length === 0) return obj[prop];

    while ((obj = obj[current]) && i < l) {
      current = parts[i];
      i++;
    }

    if (obj) {
      return obj[last];
    }
  }

  componentDidMount() {
    const { key, label, data, name, defaultValue } = this.props;
    let value_options = [];
    let selectedValue = "";
    // console.log(
    //   "Default value --> mount" + this.props.name + "---" + defaultValue
    // );

    if (data.dataSrc === "values") {
      value_options = data?.values?.map((o) => {
        return { value: o.value, label: o.label, color: "#00B8D9" };
      });

      this.setState({ options: value_options }, () => {
        if (this.props.value) {
          this.selectRef.current.value = this.props.value;
          selectedValue = this.props.value;
        } else {
          selectedValue = defaultValue;
        }

        this.onChange({
          value: selectedValue,
          name: this.props.name,
          type: "select",
        });
      });
      // console.log('Default value -->'+this.props.name+'---'+defaultValue)

      //this.props.onChange({target:{name:name,value:defaultValue}})
    } else if (data.dataSrc === "url") {
      fetch(apiUrl + data.url)
        .then((res) => res.json())
        .then((json) => {
          // console.log(
          //   "Department :",
          //   this.getProperty(json, data.selectValues)
          // );
          let items = this.getProperty(json, data.selectValues);
          value_options = items
            ? items.map((o) => {
                var label = o.name || "";
                if (data.nameProperty && data.nameProperty.length > 0) {
                  data.nameProperty.forEach((element) => {
                    label += " " + o[element];
                  });
                }
                return { value: o[data.valueProperty], label: label };
              })
            : [];
          this.setState({ options: value_options, fatchData: items }, () => {
            if (this.props.value) {
              this.selectRef.current.value = this.props.value;
              selectedValue = this.props.value; //data.values.length>0?data.values[0].value:"";
            } else {
              selectedValue = defaultValue;
              // selectedValue=items.length>0?items[0][data.valueProperty]:"";
            }

            this.onChange({
              value: selectedValue,
              name: this.props.name,
              type: "select",
            });
          });
          if (this.props.onFatchData) {
            this.props.onFatchData(items, data.selectValues);
          }
        });
    }
  }

  componentDidUpdate(prev_props, pstate) {
    const { key, label, data, name, defaultValue, value } = this.props;
    let value_options = "";

    if (
      data.dataSrc === "values" &&
      JSON.stringify(data.values) !== JSON.stringify(prev_props.data.values)
    ) {
      this.onChange({
        value: defaultValue,
        name: this.props.name,
        type: "select",
      });
      value_options = data?.values?.map((o) => {
        return { value: o.value, label: o.label, color: "#00B8D9" };
      });
      this.setState({ options: value_options }, () => {
        // let value=data.values.length>0?data.values[0].value:"";
        let val = value ? value : defaultValue;
        if (this.props.triggerChangeOnValueChange) {
          this.onChange({
            value: val,
            name: this.props.name,
            type: "select",
          });
        }
        // this.props.onChange({target:{name:name,value:defaultValue}})
      });
    }
    if (prev_props.value !== this.props.value) {
      if (this.props.triggerChangeOnValueChange) {
        this.onChange({
          value: this.props.value ? this.props.value : this.props.defaultValue,
          name: this.props.name,
          type: "select",
        });
      }
    }
  }

  onChange(e) {
    let { value } = e;

    const { data, name } = this.props;
    let items = this.state.fatchData;

    let item =
      items &&
      items.find((item) => {
        return item[data.valueProperty] === value;
      });
    if (this.state.flag) {
      this.props.onChange({ target: { name: name, value: value } }, item);
    }
    this.setState({ flag: true });
  }

  render() {
    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };
    const groupBadgeStyles = {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center",
    };
    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    const {
      key,
      label,
      data,
      name,
      required,
      value,
      readOnly,
      defaultValue,
    } = this.props;
    const { options } = this.state;

    const customStyles = {
      control: (_, { selectProps: { height } }) => ({
        //  height: height,
      }),
    };
    return (
      <>
        <div className="form-group">
          {label && (
            <label for={key}>
              {label}
              {required ? <a className="mandatory"> *</a> : ""}
            </label>
          )}
          <div
            style={{
              border: "1px solid #4ce600",
              borderRadius: "5px",
              height: "30px",
            }}
          >
            <Select
              isDisabled={readOnly}
              className="react-select-container"
              ref={this.selectRef}
              value={
                options?.find((item) => {
                  let val = value ? value : defaultValue ? defaultValue : "";
                  return item?.value === val;
                }) || null
              }
              options={[{ label: label, options }]}
              formatGroupLabel={formatGroupLabel}
              name={name}
              id={key}
              height={30}
              onChange={this.onChange.bind(this)}
              //   styles={customStyles}
            />
          </div>
        </div>
      </>
    );
  }
}
MySelect.defaultProps = {
  triggerChangeOnValueChange: true,
  readOnly: false,
  defaultValue: 0,
  triggerChangeOnLoad: true,
};
