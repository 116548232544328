export const referalConsultantConstants = {
        ADD_REFERRAL_CONSULTANT_REQUEST:'ADD_REFERRAL_CONSULTANT_REQUEST',
       ADD_REFERRAL_CONSULTANT_SUCCESS:'ADD_REFERRAL_CONSULTANT_SUCCESS',
       ADD_REFERRAL_CONSULTANT_FAILURE:'ADD_REFERRAL_CONSULTANT_FAILURE' ,

       GET_ALL_REFERRAL_CONSULTANT_REQUEST:'GET_ALL_REFERRAL_CONSULTANT_REQUEST',
       GET_ALL_REFERRAL_CONSULTANT_SUCCESS:'GET_ALL_REFERRAL_CONSULTANT_SUCCESS',
       GET_ALL_REFERRAL_CONSULTANT_FAILURE:'GET_ALL_REFERRAL_CONSULTANT_FAILURE' ,

       GET_ALL_REFERRAL_DATA_REQUEST:'GET_ALL_REFERRAL_DATA_REQUEST',
       GET_ALL_REFERRAL_DATA_SUCCESS:'GET_ALL_REFERRAL_DATA_SUCCESS',
       GET_ALL_REFERRAL_DATA_FAILURE:'GET_ALL_REFERRAL_DATA_FAILURE' ,

       UPDATE_REFERRAL_CONSULTANT_REQUEST:'UPDATE_REFERRAL_CONSULTANT_REQUEST',
       UPDATE_REFERRAL_CONSULTANT_SUCCESS:'UPDATE_REFERRAL_CONSULTANT_SUCCESS',
       UPDATE_REFERRAL_CONSULTANT_FAILURE:'UPDATE_REFERRAL_CONSULTANT_FAILURE' ,

       DELETE_REFERRAL_CONSULTANT_REQUEST:'DELETE_REFERRAL_CONSULTANT_REQUEST',
       DELETE_REFERRAL_CONSULTANT_SUCCESS:'DELETE_REFERRAL_CONSULTANT_SUCCESS',
       DELETE_REFERRAL_CONSULTANT_FAILURE:'DELETE_REFERRAL_CONSULTANT_FAILURE' ,

       SELECT_REFERRAL_CONSULTANT_REQUEST:'SELECT_REFERRAL_CONSULTANT_REQUEST',
       SELECT_REFERRAL_CONSULTANT_SUCCESS:'SELECT_REFERRAL_CONSULTANT_SUCCESS',
       SELECT_REFERRAL_CONSULTANT_FAILURE:'SELECT_REFERRAL_CONSULTANT_FAILURE' ,

       REFERRAL_CLEAR:'REFERRAL_CLEAR'
}