import { generalMasterConstants } from "../constants";

export function costcenters(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_COSTCENTER_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_COSTCENTER_SUCCESS:
      return { ...state, costcenters: action.costcenters, loading: false };
    case generalMasterConstants.GET_COSTCENTER_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_COSTCENTER_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_COSTCENTER_SUCCESS:
      return {
        ...state,
        costcenters: [...state.costcenters, action.costcenter],
        loading: false,
      };
    case generalMasterConstants.ADD_COSTCENTER_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_COSTCENTER_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_COSTCENTER_SUCCESS:
      let updatedState = action.costcenter;
      let index = state.costcenters.findIndex(
        (ob) => ob.id === updatedState.id
      );
      state.costcenters[index] = updatedState;
      return { ...state, costcenters: [...state.costcenters], loading: false };
    case generalMasterConstants.UPDATE_COSTCENTER_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_COSTCENTER_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_COSTCENTER_SUCCESS:
      //let deletedcenter = action.costcenter;
      //let cindex = state.costcenters.findIndex((ob) => ob.code === deletedcenter.code);
      const updatedCostcenters = state.costcenters
        ? state.costcenters.map((costcenter) =>
            costcenter.id === action.costcenter
              ? { ...costcenter, deleted: !costcenter.deleted }
              : costcenter
          )
        : state.costcenters;

      return {
        ...state,
        costcenters: updatedCostcenters,
        loading: false,
      };

    case generalMasterConstants.DELETE_COSTCENTER_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
