import { sweetAlertConstants } from "../constants";

export const sweetAlertActions = {
  show,
  hide,
};

function show(message, props) {
  return { type: sweetAlertConstants.SHOW, props, message };
}

function hide() {
  return { type: sweetAlertConstants.HIDE };
}
