import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { bankService } from "../../services";
import { toast, Bounce } from "react-toastify";
export const bankActions = {
  getAll,
  setSelected,
  add,
  update,
  remove,

  addBranch,
  updateBranch,
  removeBranch,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    bankService.getAll().then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success("Banks loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_BANK_REQUEST };
  }
  function success(banks) {
    return { type: generalMasterConstants.GET_BANK_SUCCESS, banks };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_BANK_FAILURE, error };
  }
}

function setSelected(BankId) {
  return (dispatch) => {
    dispatch(request());
    bankService.getAllBranches(BankId).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
        } else dispatch(failure(data.err));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.SET_SELECTED_BANK_REQUEST };
  }
  function success(branches) {
    return {
      type: generalMasterConstants.SET_SELECTED_BANK_SUCCESS,
      data: { branches, BankId },
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.SET_SELECTED_BANK_FAILURE,
      error,
    };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.addBank(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_BANK_REQUEST };
  }
  function success(bank) {
    return { type: generalMasterConstants.ADD_BANK_SUCCESS, bank };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_BANK_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.updateBank(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_BANK_REQUEST };
  }
  function success(data) {
    return { type: generalMasterConstants.UPDATE_BANK_SUCCESS, data };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_BANK_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.deleteBank(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_BANK_REQUEST };
  }
  function success(data) {
    return { type: generalMasterConstants.UPDATE_BANK_SUCCESS, data };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_BANK_FAILURE, error };
  }
}

function addBranch(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.addBranch(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_BRANCH_REQUEST };
  }
  function success(branch) {
    return { type: generalMasterConstants.ADD_BRANCH_SUCCESS, branch };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_BRANCH_FAILURE, error };
  }
}

function updateBranch(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.updateBranch(payload).then(
      (data) => {
        if (data.isSuccess) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_BRANCH_REQUEST };
  }
  function success(branch) {
    return { type: generalMasterConstants.UPDATE_BRANCH_SUCCESS, branch };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_BRANCH_FAILURE, error };
  }
}

function removeBranch(payload) {
  return (dispatch) => {
    dispatch(request());
    bankService.deleteBranch(payload).then(
      (data) => {
        if (data.success) {
          dispatch(success(data.data));
          dispatch(alertActions.success(data.message));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_BRANCH_REQUEST };
  }
  function success(branch) {
    return { type: generalMasterConstants.DELETE_BRANCH_SUCCESS, branch };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_BRANCH_FAILURE, error };
  }
}
