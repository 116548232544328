import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import UploadModal from "./Modals/UploadModal";
import { serviceMasterActions } from "../../../actions";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  BarChart,
  Bar,
  ComposedChart,
  CartesianGrid,
} from "recharts";

import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import AmountSetting from "../amount.setting";
import Switch from "react-switch";
import ErrorShownModel from "./Modals/errormodel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { sweetAlertActions, registrationMasterActions } from "../../../actions";
import { serviceMasterService } from "../../../services";
import FormValidation from "../FormValidation";

class Packages1 extends Component {
  constructor(props) {
    super();
    console.log("props logging", props.responseObject);
    this.state = {
      currentPackage: "Master Health Checkup",
      checked: false,
      isToggleOn: true,
      responseObject: props.responseObject,
      selectedState: [],
      totalAmount: 0,
      Revenue_Perc_Hosp: 0,
      Revenue_Perc_OutS: 0,
      Revenue_Perc_Lab: 0,
      PKM_CODE: props.responseObject ? props.responseObject.CODE : "",
      USERDEFINED_NAME: props.responseObject
        ? props.responseObject.USERDEFINED_NAME
        : "",
      OLD_AMOUNT: props.responseObject ? props.responseObject.PACKAGE_AMT : 0,
      visible: false,
      visibleFormValidation: false,
      perChange: 0,
      roundoffType: "floor",
    };
    this.handlePackageChange = this.handlePackageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemovefield = this.handleRemovefield.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.removeService = this.removeService.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
    this.handleEditFlow = this.handleEditFlow.bind(this);
    this.handleHospitalRevenue = this.handleHospitalRevenue.bind(this);
    this.handleOutSorceRevenue = this.handleOutSorceRevenue.bind(this);
    this.handleHospitalLabRevenue = this.handleHospitalLabRevenue.bind(this);
    this.handleEditPackageAmountFlow = this.handleEditPackageAmountFlow.bind(
      this
    );
    this.clearForm = this.clearForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
    this.onTotalAmountChange = this.onTotalAmountChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleRoundOffChange = this.handleRoundOffChange.bind(this);

    //this.onServiceAmountChange = this.onServiceAmountChange.bind(this);
    this.onBaseAmountSetupChange = this.onBaseAmountSetupChange.bind(this);
    this.onBaseAmountSetupPercentageChange = this.onBaseAmountSetupPercentageChange.bind(
      this
    );
    this.onServiceAmountSetupChange = this.onServiceAmountSetupChange.bind(
      this
    );
    this.onServiceAmountSetupValueChange = this.onServiceAmountSetupValueChange.bind(
      this
    );
  }

  onServiceAmountSetupValueChange(e) {
    this.setState({
      serviceAmountSettingValue: e.target.value,
    });
  }

  onServiceAmountSetupChange(e) {
    this.setState({
      serviceAmountSetting: e.target.value,
    });
  }

  onBaseAmountSetupPercentageChange(e) {
    this.setState({
      baseAmountSettingValue: e.target.value,
    });
  }

  onBaseAmountSetupChange(e) {
    this.setState({
      baseAmountSetting: e.target.value,
    });
  }

  handleRoundOffChange() {
    if (this.state.roundoffType === "floor") {
      this.setState({
        roundoffType: "ceil",
      });
    } else {
      this.setState({
        roundoffType: "floor",
      });
    }
  }

  handleEditPackageAmountFlow() {
    this.setState({
      visible: false,
      OLD_AMOUNT: this.state.totalAmount,
    });
  }

  handleHospitalRevenue(e) {
    //let total = Revenue_Perc_Hosp Revenue_Perc_OutS Revenue_Perc_Lab
    if (e.target.value) {
      let Revenue_Perc_Hosp = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_Lab +
        this.state.Revenue_Perc_OutS +
        Revenue_Perc_Hosp;
      if (total > 100) {
        Revenue_Perc_Hosp =
          100 - (this.state.Revenue_Perc_Lab + this.state.Revenue_Perc_OutS);
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_Hosp * this.state.totalAmount) / 100)
            : Math.ceil((Revenue_Perc_Hosp * this.state.totalAmount) / 100);
        this.setState({
          Revenue_Perc_Hosp: Revenue_Perc_Hosp,
          REVENUE_SHARE_HOSP: amount,
        });
      } else {
        let amount = (Revenue_Perc_Hosp * this.state.totalAmount) / 100;
        this.setState({
          Revenue_Perc_Hosp: Revenue_Perc_Hosp,
          REVENUE_SHARE_HOSP: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_Hosp: 0,
        REVENUE_SHARE_HOSP: 0,
      });
    }
  }
  handleHospitalLabRevenue(e) {
    if (e.target.value) {
      let Revenue_Perc_Lab = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_OutS +
        this.state.Revenue_Perc_Hosp +
        Revenue_Perc_Lab;
      if (total > 100) {
        Revenue_Perc_Lab =
          100 - (this.state.Revenue_Perc_OutS + this.state.Revenue_Perc_Hosp);
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_Lab * this.state.totalAmount) / 100)
            : Math.ceil((Revenue_Perc_Lab * this.state.totalAmount) / 100);
        this.setState({
          Revenue_Perc_Lab: Revenue_Perc_Lab,
          Revenue_Share_Lab: amount,
        });
      } else {
        let amount =
          this.state.roundoffType === "floor"
            ? Math.floor((Revenue_Perc_Lab * this.state.totalAmount) / 100)
            : Math.ceil((Revenue_Perc_Lab * this.state.totalAmount) / 100);
        this.setState({
          Revenue_Perc_Lab: Revenue_Perc_Lab,
          Revenue_Share_Lab: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_Lab: 0,
        Revenue_Share_Lab: 0,
      });
    }
  }
  handleOutSorceRevenue(e) {
    if (e.target.value) {
      let Revenue_Perc_OutS = parseFloat(e.target.value);
      let total =
        this.state.Revenue_Perc_Lab +
        this.state.Revenue_Perc_Hosp +
        Revenue_Perc_OutS;
      if (total > 100) {
        Revenue_Perc_OutS =
          100 - (this.state.Revenue_Perc_Lab + this.state.Revenue_Perc_Hosp);
        let amount = (Revenue_Perc_OutS * this.state.totalAmount) / 100;
        this.setState({
          Revenue_Perc_OutS: Revenue_Perc_OutS,
          REVENUE_SHARE_OUT: amount,
        });
      } else {
        let amount = (Revenue_Perc_OutS * this.state.totalAmount) / 100;
        this.setState({
          Revenue_Perc_OutS: Revenue_Perc_OutS,
          REVENUE_SHARE_OUT: amount,
        });
      }
    } else {
      this.setState({
        Revenue_Perc_OutS: 0,
        REVENUE_SHARE_OUT: 0,
      });
    }
  }
  handleEditFlow() {
    this.props.handleEditFlow(this.state.responseObject);
  }

  hide() {
    this.setState({
      visible: false,
      visibleFormValidation: false,
    });
  }

  onAmountChange(e, index) {
    let selectedState = [...this.state.selectedState];
    if (e.target.value) {
      if (selectedState[index].amountAlreadyDedcted) {
        let totalAmount =
          this.state.totalAmount - parseFloat(selectedState[index].newamount);
        totalAmount = totalAmount + parseFloat(e.target.value);
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;
        selectedState[index].newamount = e.target.value;
        if (this.props.isCameFromEditFlow) {
          this.setState({
            selectedState: selectedState,
            totalAmount: totalAmount,
            REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
            REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
            Revenue_Share_Lab: Revenue_Share_Lab,
          });
        } else {
          this.setState({
            selectedState: selectedState,
            totalAmount: totalAmount,
            OLD_AMOUNT: totalAmount,
            REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
            REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
            Revenue_Share_Lab: Revenue_Share_Lab,
          });
        }
      } else {
        let totalAmount =
          this.state.totalAmount - selectedState[index].SERVICE_AMT;
        totalAmount = totalAmount + parseFloat(e.target.value);
        selectedState[index].newamount = e.target.value;
        selectedState[index].amountAlreadyDedcted = true;
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;

        this.setState({
          selectedState: selectedState,
          totalAmount: totalAmount,
          OLD_AMOUNT: totalAmount,
          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      }
    } else {
      if (selectedState[index].amountAlreadyDedcted) {
        let totalAmount =
          this.state.totalAmount - parseFloat(selectedState[index].newamount);
        totalAmount = totalAmount + selectedState[index].SERVICE_AMT;
        selectedState[index].newamount = selectedState[index].SERVICE_AMT;
        let REVENUE_SHARE_HOSP =
          (totalAmount * this.state.Revenue_Perc_Hosp) / 100;
        let REVENUE_SHARE_OUT =
          (totalAmount * this.state.Revenue_Perc_OutS) / 100;
        let Revenue_Share_Lab =
          (totalAmount * this.state.Revenue_Perc_Lab) / 100;

        this.setState({
          selectedState: selectedState,
          totalAmount: totalAmount,
          OLD_AMOUNT: totalAmount,
          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      }
    }
  }

  handleActiveChange(e, index) {
    let selectedState = [...this.state.selectedState];
    if (index > -1) {
      if (selectedState[index].isActive) {
        let totalAmount =
          this.state.totalAmount - selectedState[index].newamount;
        let old_amount =
          this.state.OLD_AMOUNT - selectedState[index].SERVICE_AMT;
        selectedState[index].isActive = false;
        this.setState({
          totalAmount: totalAmount,
          OLD_AMOUNT: old_amount,
          selectedState: selectedState,
        });
      } else {
        let totalAmount =
          this.state.totalAmount + selectedState[index].newamount;
        let old_amount =
          this.state.OLD_AMOUNT + selectedState[index].SERVICE_AMT;
        selectedState[index].isActive = true;
        this.setState({
          totalAmount: totalAmount,
          OLD_AMOUNT: old_amount,
          selectedState: selectedState,
        });
      }
    }
  }

  removeService(index) {
    let selectedState = [...this.state.selectedState];
    let totalAmount = this.state.totalAmount;
    if (index > -1) {
      if (selectedState[index].isActive) {
        if (selectedState[index].newamount) {
          totalAmount = totalAmount - selectedState[index].newamount;
        }
        let old_amount =
          this.state.OLD_AMOUNT - selectedState[index].SERVICE_AMT;

        let REVENUE_SHARE_HOSP =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
              );
        let REVENUE_SHARE_OUT =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
              );
        let Revenue_Share_Lab =
          this.state.roundoffType === "floor"
            ? Math.floor(
                (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
              )
            : Math.ceil(
                (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
              );
        selectedState.splice(index, 1);

        this.setState({
          selectedState: selectedState,
          OLD_AMOUNT: old_amount,
          totalAmount: totalAmount,

          REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
          REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
          Revenue_Share_Lab: Revenue_Share_Lab,
        });
      } else {
        let selectedState = [...this.state.selectedState];
        selectedState.splice(index, 1);
        this.setState({
          selectedState: selectedState,
        });
      }
    }
  }

  save() {
    let { dispatch } = this.props;
    let payload = {
      PKM_CODE: this.state.PKM_CODE,
      SM_CODE: this.state.selectedState,
      PACKAGE_SERVICE_AMT: this.state.totalAmount,
      PACKAGE_AMT: this.state.OLD_AMOUNT,
      Revenue_Perc_Hosp: this.state.Revenue_Perc_Hosp,
      Revenue_Perc_OutS: this.state.Revenue_Perc_OutS,
      Revenue_Perc_Lab: this.state.Revenue_Perc_Lab,
      Revenue_Share_Lab: this.state.Revenue_Share_Lab,
      REVENUE_SHARE_OUT: this.state.REVENUE_SHARE_OUT,
      REVENUE_SHARE_HOSP: this.state.REVENUE_SHARE_HOSP,
      roundoffType: this.state.roundoffType,
      baseAmountSetting: this.state.baseAmountSetting,
      baseAmountSettingValue: this.state.baseAmountSettingValue,
      serviceAmountSetting: this.state.serviceAmountSetting,
      serviceAmountSettingValue: this.state.serviceAmountSettingValue,
    };
    let validateForm = this.formValidation(payload);
    if (validateForm) {
      if (this.props.isCameFromEditFlow) {
        payload._id = this.state._id;
        payload.isEditFlow = true;
      }
      dispatch(serviceMasterActions.addPackageMapping(payload));
    } else {
      this.setState({
        visibleFormValidation: true,
      });
    }
  }

  formValidation(data) {
    if (
      this.state.Revenue_Perc_Hosp +
        this.state.Revenue_Perc_OutS +
        this.state.Revenue_Perc_Lab !==
      100
    ) {
      this.setState({
        errorMessage: "The sum of shares should be 100",
      });
      return false;
    }
    return true;
  }

  handlePackageChange(e) {
    e.preventDefault();
    this.setState({ currentPackage: e.target.value });
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  handleRemovefield(id) {
    //this.setState({ checked });
  }
  clearForm() {
    this.setState({
      selectedState: [],
      totalAmount: 0,
    });
  }

  handleServiceChange(services, totalAmount) {
    let selectedState = [...this.state.selectedState];
    services.forEach((element) => {
      selectedState.push(element);
    });
    //selectedState.push(services);
    //selectedState.flat();
    if (!this.state.totalAmount) {
      let Amount = this.state.totalAmount + totalAmount;
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      let newServiceList = [];
      selectedState.forEach((data) => {
        data.newamount = data.SERVICE_AMT;
        newServiceList.push(data);
      });
      this.setState({
        selectedState: newServiceList,
        totalAmount: Amount,
        OLD_AMOUNT: Amount,
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
    } else {
      let updatedAmount = this.state.OLD_AMOUNT + totalAmount;
      let perChange = (this.state.totalAmount / updatedAmount) * 100;
      let newServiceList = [];
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      selectedState.forEach((data) => {
        data.newamount =
          this.state.roundoffType === "floor"
            ? Math.floor((data.SERVICE_AMT * perChange) / 100)
            : Math.ceil((data.SERVICE_AMT * perChange) / 100);
        newServiceList.push(data);
      });
      this.setState({
        OLD_AMOUNT: updatedAmount,
        perChange:
          this.state.roundoffType === "floor"
            ? Math.floor(perChange)
            : Math.floor(perChange),
        selectedState: newServiceList,
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
      });
    }
  }

  onTotalAmountChange(e) {
    if (e.target.value) {
      let amount = parseFloat(e.target.value);
      let perChange = (amount / this.state.OLD_AMOUNT) * 100;
      let REVENUE_SHARE_HOSP =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Hosp) / 100
            );
      let REVENUE_SHARE_OUT =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_OutS) / 100
            );
      let Revenue_Share_Lab =
        this.state.roundoffType === "floor"
          ? Math.floor(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            )
          : Math.ceil(
              (this.state.totalAmount * this.state.Revenue_Perc_Lab) / 100
            );
      let serviceList = [...this.state.selectedState];
      let newServiceList = [];
      serviceList.forEach((data) => {
        data.newamount =
          this.state.roundoffType === "floor"
            ? Math.floor((data.SERVICE_AMT * perChange) / 100)
            : Math.ceil((data.SERVICE_AMT * perChange) / 100);
        newServiceList.push(data);
      });
      this.setState({
        totalAmount: amount,
        perChange:
          this.state.roundoffType === "floor"
            ? Math.floor(perChange)
            : Math.ceil(perChange),
        REVENUE_SHARE_HOSP: REVENUE_SHARE_HOSP,
        REVENUE_SHARE_OUT: REVENUE_SHARE_OUT,
        Revenue_Share_Lab: Revenue_Share_Lab,
        selectedState: newServiceList,
      });
    } else {
      let serviceList = [...this.state.selectedState];
      let newServiceList = [];
      serviceList.forEach((data) => {
        data.newamount = 0;
        newServiceList.push(data);
      });
      this.setState({
        totalAmount: 0,
        perChange: 0,
        REVENUE_SHARE_HOSP: 0,
        REVENUE_SHARE_OUT: 0,
        Revenue_Share_Lab: 0,
        selectedState: newServiceList,
      });
    }
  }

  componentDidMount() {
    if (this.props.isCameFromEditFlow) {
      serviceMasterService
        .getPackageMappingDetailsById(this.state.PKM_CODE)
        .then((data) => {
          console.log(data);
          if (data.mappingDetails) {
            let perChange = Math.round(
              (parseFloat(data.mappingDetails.PACKAGE_SERVICE_AMT) /
                parseFloat(data.packageDetails.PACKAGE_AMT)) *
                100
            );
            this.setState({
              _id: data.mappingDetails._id,
              selectedState: data.mappingDetails.SM_CODE,
              OLD_AMOUNT: parseFloat(data.packageDetails.PACKAGE_AMT),
              totalAmount: parseFloat(data.mappingDetails.PACKAGE_SERVICE_AMT),
              roundoffType: data.mappingDetails.roundoffType,
              baseAmountSetting: data.mappingDetails.baseAmountSetting,
              baseAmountSettingValue:
                data.mappingDetails.baseAmountSettingValue,
              serviceAmountSetting: data.mappingDetails.serviceAmountSetting,
              serviceAmountSettingValue:
                data.mappingDetails.serviceAmountSettingValue,
              Revenue_Perc_Hosp: data.packageDetails.Revenue_Perc_Hosp,
              Revenue_Perc_OutS: data.packageDetails.Revenue_Perc_OutS,
              Revenue_Perc_Lab: data.packageDetails.Revenue_Perc_Lab,
              Revenue_Share_Lab: data.packageDetails.Revenue_Share_Lab,
              REVENUE_SHARE_OUT: data.packageDetails.REVENUE_SHARE_OUT,
              REVENUE_SHARE_HOSP: data.packageDetails.REVENUE_SHARE_HOSP,

              perChange: perChange,
            });
          }
        });
    }
  }
  render() {
    let tableRows;
    if (this.state.selectedState) {
      tableRows = this.state.selectedState.map((data, index) => {
        let tds = [<td>{index + 1}</td>];
        tds.push(<td>{data.SERVICE_NAME}</td>);
        tds.push(<td>{data.SERVICE_AMT}</td>);
        tds.push(
          <td>
            <Input
              name="new_amount"
              type="number"
              placeholder="enter new amount"
              value={data.newamount}
              disabled
              onChange={(e) => {
                this.onAmountChange(e, index);
              }}
            ></Input>
          </td>
        );
        tds.push(
          <td>
            <FormGroup>
              <Switch
                checked={data.isActive}
                className="mr-2 mb-2"
                id="normal-switch"
                onChange={(e) => this.handleActiveChange(e, index)}
              />
            </FormGroup>
          </td>
        );
        tds.push(
          <td>
            <Button
              className="mb-2 mr-2 btn-icon btn-icon-only"
              outline
              color="danger"
              title="Delete"
              onClick={() => {
                this.removeService(index);
              }}
            >
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
            </Button>
          </td>
        );

        return <tr key={data._id}>{tds}</tr>;
      });
    }
    let { responseObject } = this.props;
    console.log("consoling from rendr", responseObject);
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Packages</CardTitle>
              <CardTitle>
                <Row>
                  <Col sm={2}>
                    {/* {this.state.USERDEFINED_NAME} */}
                    <Label>Package Name</Label>

                    <Input
                      disabled
                      name="userdefined_name"
                      value={this.state.USERDEFINED_NAME}
                    ></Input>
                  </Col>
                  <Col sm={2}>
                    <Label>Package Amount</Label>
                    <Input
                      disabled
                      name="old_amount"
                      value={this.state.OLD_AMOUNT}
                    ></Input>
                  </Col>
                  <Col sm={2}>
                    <Label>New Amount</Label>
                    <Input
                      name="new_amount"
                      value={this.state.totalAmount}
                      onChange={(e) => {
                        this.onTotalAmountChange(e);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <Label for="discount">
                      {this.state.perChange < 100
                        ? "Discount Percentage"
                        : "Increased Percentage"}
                    </Label>
                    <Input
                      id="discount"
                      name="discount"
                      value={
                        this.state.perChange === 0
                          ? 0
                          : this.state.perChange < 100
                          ? 100 - this.state.perChange
                          : this.state.perChange - 100
                      }
                      disabled
                    ></Input>
                  </Col>
                  <Col>
                    <UploadModal
                      handleServiceChange={this.handleServiceChange}
                      selectedState={this.state.selectedState}
                    />
                  </Col>
                </Row>
              </CardTitle>

              <Table hover responsive className="mb-0">
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th>Service Name</th>
                    <th>Service Amuont</th>
                    <th>New Amount</th>
                    <th>Active</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </Table>
            </CardBody>
          </Card>
          <AmountSetting
            title="Package Amount Settings"
            roundoffType={this.state.roundoffType}
            handleRoundOffChange={this.handleRoundOffChange}
            baseAmountSetting={this.state.baseAmountSetting}
            onBaseAmountSetupChange={this.onBaseAmountSetupChange}
            onBaseAmountSetupPercentageChange={
              this.onBaseAmountSetupPercentageChange
            }
            baseAmountSettingValue={this.state.baseAmountSettingValue}
            onServiceAmountSetupChange={this.onServiceAmountSetupChange}
            serviceAmountSetting={this.state.serviceAmountSetting}
            onServiceAmountSetupValueChange={
              this.onServiceAmountSetupValueChange
            }
            serviceAmountSettingValue={this.state.serviceAmountSettingValue}
          />
          <Card style={{ marginTop: "10px" }}>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>Type of Sharing</th>
                    <th>Percentage</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Revenue Sharing % for Hospital</td>
                    <td>
                      <Input
                        type="number"
                        name="Revenue_Perc_Hosp"
                        id="Revenue_Perc_Hosp"
                        placeholder=""
                        onChange={(e) => {
                          this.handleHospitalRevenue(e);
                        }}
                        value={this.state.Revenue_Perc_Hosp}
                        max="100"
                      />
                    </td>
                    <td>
                      <Input
                        type="number"
                        name="REVENUE_SHARE_HOSP"
                        id="REVENUE_SHARE_HOSP"
                        placeholder=""
                        value={this.state.REVENUE_SHARE_HOSP}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue Sharing % for Hospital Lab</td>
                    <td>
                      <Input
                        type="number"
                        name="Revenue_Perc_Lab"
                        id="Revenue_Perc_Lab"
                        placeholder=""
                        max="100"
                        onChange={(e) => {
                          this.handleHospitalLabRevenue(e);
                        }}
                        value={this.state.Revenue_Perc_Lab}
                      />
                    </td>
                    <td>
                      {" "}
                      <Input
                        type="number"
                        name="Revenue_Share_Lab"
                        id="Revenue_Share_Lab"
                        placeholder=""
                        value={this.state.Revenue_Share_Lab}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue Sharing % for OutSource</td>
                    <td>
                      <Input
                        type="number"
                        name="Revenue_Perc_OutS"
                        id="Revenue_Perc_OutS"
                        placeholder=""
                        max="100"
                        onChange={(e) => {
                          this.handleOutSorceRevenue(e);
                        }}
                        value={this.state.Revenue_Perc_OutS}
                      />
                    </td>
                    <td>
                      <Input
                        type="number"
                        name="REVENUE_SHARE_OUT"
                        id="REVENUE_SHARE_OUT"
                        placeholder=""
                        value={this.state.REVENUE_SHARE_OUT}
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* <FormGroup row>
                <Label for='Revenue_Perc_Hosp' sm={3}>
                  Revenue Sharring % for Hospital
                </Label>
                <Col sm={3}>
                  <Input
                    type='number'
                    name='Revenue_Perc_Hosp'
                    id='Revenue_Perc_Hosp'
                    placeholder=''
                    onChange={e => {
                      this.handleHospitalRevenue(e)
                    }}
                    value={this.state.Revenue_Perc_Hosp}
                    max='100'
                  />
                </Col>
                %
                <Col sm={3}>
                  <Input
                    type='number'
                    name='REVENUE_SHARE_HOSP'
                    id='REVENUE_SHARE_HOSP'
                    placeholder=''
                    value={this.state.REVENUE_SHARE_HOSP}
                    disabled
                  />
                </Col>
                <Label for='Customer_Tran_Hospital_Beds' sm={2}>
                  Amt
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label for='REVENUE_SHARE_LAB' sm={3}>
                  Revenue Sharring % for Hospital Lab
                </Label>
                <Col sm={3}>
                  <Input
                    type='number'
                    name='Revenue_Perc_Lab'
                    id='Revenue_Perc_Lab'
                    placeholder=''
                    max='100'
                    onChange={e => {
                      this.handleHospitalLabRevenue(e)
                    }}
                    value={this.state.Revenue_Perc_Lab}
                  />
                </Col>
                %
                <Col sm={3}>
                  <Input
                    type='number'
                    name='Revenue_Share_Lab'
                    id='Revenue_Share_Lab'
                    placeholder=''
                    value={this.state.Revenue_Share_Lab}
                    disabled
                  />
                </Col>
                <Label for='Customer_Tran_Hospital_Beds' sm={2}>
                  Amt
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label for='Revenue_Perc_OutS' sm={3}>
                  Revenue Sharring % for OutSource
                </Label>
                <Col sm={3}>
                  <Input
                    type='number'
                    name='Revenue_Perc_OutS'
                    id='Revenue_Perc_OutS'
                    placeholder=''
                    max='100'
                    onChange={e => {
                      this.handleOutSorceRevenue(e)
                    }}
                    value={this.state.Revenue_Perc_OutS}
                  />
                </Col>
                %
                <Col sm={3}>
                  <Input
                    type='number'
                    name='REVENUE_SHARE_OUT'
                    id='REVENUE_SHARE_OUT'
                    placeholder=''
                    value={this.state.REVENUE_SHARE_OUT}
                    disabled
                  />
                </Col>
                <Label for='Customer_Tran_Hospital_Beds' sm={2}>
                  Amt
                </Label>
              </FormGroup> */}
              <Button
                color="primary"
                className="mt-2 right-side"
                onClick={(e) => {
                  this.save();
                }}
              >
                {this.state.isCameFromEditFlow ? "Update" : "Save"}
              </Button>
              {this.state.isCameFromEditFlow ? (
                <Button
                  color="primary"
                  className="mt-2 right-side"
                  onClick={this.clearForm}
                >
                  Reset
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mt-2 right-side"
                  onClick={this.props.addNavigation}
                >
                  Back
                </Button>
              )}
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
        <ErrorShownModel
          visible={this.state.visible}
          hide={this.hide}
          handleEditPackageAmountFlow={this.handleEditPackageAmountFlow}
        />
        <FormValidation
          visible={this.state.visibleFormValidation}
          hide={this.hide}
          errormessage={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { serviceMaster } = state;
  return { serviceMaster: serviceMaster.data };
}

const Packages = connect(mapStateToProps)(Packages1);

export default Packages;
