import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Card, CardBody } from "reactstrap";
import ViewMain from "./ViewMain";
 class AppointmentMaster extends React.Component { 
  
  render() {
    return (
      <Fragment>           
         <Card>
            <CardBody>
                 <ViewMain {...this.props}/>
            </CardBody>                 
         </Card>               
      </Fragment>
    );
  }
}

function mapStateToProps(state)
{
    const {consultants,appointments,customers}=state;
    return {consultants:consultants.consultants,appointments:appointments.appointments,customer:customers.selected_customer,transaction:customers.currentTransaction}
}

const connectedMainView= connect(mapStateToProps)(AppointmentMaster);
export default connectedMainView;