//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const patientService = {
  getAll,
  add,
  update,
  getByUHID,
  getByPatientUHID,
  getByDate,
  remove,
  findPatients,
  findIPPatients,
  selectPatient,
  exportToExcel,
};

function getAll(query = "") {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/patients?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(payload) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Patients?startDate=${payload.from}&endDate=${payload.to}&viewDeleted=false&page=${payload.page}&records=${payload.records}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getByUHID(uhid) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(uhid),
  };
  return fetch(`${apiUrl}/newpatient/findbyUHID`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByPatientUHID(uhid) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(uhid),
  };
  return fetch(`${apiUrl}/newpatient/getbyUHID`, requestOptions).then(
    helperService.handleResponse
  );
}
function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/Patients`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/Patients/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function exportToExcel() {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(`${apiUrl}/newpatient/export`, requestOptions).then(
    helperService.handleResponse
  );
}
function findPatients(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(
    `${apiUrl}/patients?searchBy=${data.searchValue}&viewDeleted=false&page=${data.page}&records=${data.records}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function findIPPatients(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/newpatient/searchIp`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/newpatient/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function selectPatient(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Patients?patientsId=${id}&viewDeleted=false`,
    requestOptions
  ).then(helperService.handleResponse);
}
