import { generalMasterConstants } from "../constants";

export function discountAuths(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_DISCOUNT_AUTH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_DISCOUNT_AUTH_SUCCESS:
      return {
        ...state,
        discountAuthorizations: action.discountAuthorizations,
        loading: false,
      };
    case generalMasterConstants.GET_DISCOUNT_AUTH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_DISCOUNT_AUTH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_DISCOUNT_AUTH_SUCCESS:
      return {
        ...state,
        discountAuthorizations: [
          ...state.discountAuthorizations,
          action.discountAuthorization,
        ],
        loading: false,
      };
    case generalMasterConstants.ADD_DISCOUNT_AUTH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_DISCOUNT_AUTH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_DISCOUNT_AUTH_SUCCESS:
      let updatedState = action.discountAuthorization;
      let index = state.discountAuthorizations.findIndex(
        (ob) => ob.code === updatedState.code
      );
      state.discountAuthorizations[index] = updatedState;
      return {
        ...state,
        discountAuthorizations: [...state.discountAuthorizations],
        loading: false,
      };
    case generalMasterConstants.UPDATE_DISCOUNT_AUTH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_DISCOUNT_AUTH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_DISCOUNT_AUTH_SUCCESS:
      let deleteddiscountAuth = action.discountAuthorization;
      let cindex = state.discountAuthorizations.findIndex(
        (ob) => ob.code === deleteddiscountAuth.code
      );
      state.discountAuthorizations.splice(cindex, 1);
      return {
        ...state,
        discountAuthorizations: [...state.discountAuthorizations],
        loading: false,
      };
    case generalMasterConstants.DELETE_DISCOUNT_AUTH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
