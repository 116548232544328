import {
    ipdManagementConstants , getAdmTypeContstant
} from '../constants'


const addNewPatientReducer = (state = {}, action) =>{

        switch(action.type){
            case ipdManagementConstants.ADDNEW_PATIENT_REQUEST :
                return {
                    loading :true,
                };

            case ipdManagementConstants.ADDNEW_PATIENT_SUCCESS :
                        return{
                            newPatient :  action.payload,
                        } 
                        
            case ipdManagementConstants.ADDNEW_PATIENT_FAIL :
                    return {
                        loading :false,
                        error : action.payload,
                    }            


                       

            default:
                  return state;          
        }

};

export {addNewPatientReducer};