import { tpaConstants } from "../constants";

export function tpaMaster(state = {}, action) {
  switch (action.type) {
    case tpaConstants.GET_ALL_TPA_REQUEST:
      return { ...state, loading: true };
    case tpaConstants.GET_ALL_TPA_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case tpaConstants.GET_ALL_TPA_SUCCESS:
      return { ...state, error: action.error, loading: false };
    case tpaConstants.ADD_TPA_REQUEST:
      return { ...state, loading: true };
    case tpaConstants.ADD_TPA_SUCCESS:
      let tpaList = [];
      if (state.data) {
        tpaList = state.data;
        tpaList.push(action.data);
      }
      return { ...state, data: tpaList, loading: false };
    case tpaConstants.ADD_TPA_FAILURE:
      return { ...state, error: action.error, loading: false };
    case tpaConstants.UPDATE_TPA_REQUEST:
      return { ...state, loading: true };
    case tpaConstants.UPDATE_TPA_SUCCESS:
      let tpaUpdatedList = [];
      if (state.data) {
        tpaUpdatedList = state.data;
        tpaUpdatedList.forEach((data, index) => {
          if (data.code === action.data.code) {
            tpaUpdatedList.splice(index, 1);
            tpaUpdatedList.push(action.data.code);
          }
        });
      }
      return { ...state, data: tpaUpdatedList, loading: false };
    case tpaConstants.UPDATE_TPA_FAILURE:
      return { ...state, error: action.error, loading: false };

    case tpaConstants.GET_TPA_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case tpaConstants.GET_TPA_CONS_FEES_SUCCESS:
      return { ...state, ConsData: action.data, loading: false };
    case tpaConstants.GET_TPA_CONS_FEES_FAILURE:
      return { ...state, error: action.error, loading: false };

    case tpaConstants.ADD_TPA_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case tpaConstants.ADD_TPA_CONS_FEES_SUCCESS:
      let ConsData = [];
      if (state.ConsData) {
        ConsData = state.ConsData;
      }
      ConsData.push(action.data);
      return { ...state, ConsData: ConsData, loading: false };
    case tpaConstants.ADD_TPA_CONS_FEES_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
