import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const accessService = {
  getAll,
  add,
  update,
  remove,
  updateStatus
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/accessgroup/getaccessgroup`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/accessgroup/addaccessgroup`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/accessgroup/updateAccessGroup`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/accessgroup/updateStatus`, requestOptions).then(
    helperService.handleResponse
  );
}


function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/accessgroup/deleteAccessGroup`, requestOptions).then(
    helperService.handleResponse
  );
}
