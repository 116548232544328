export const CorporateConstants = {


    GET_ALL_CORPORATE_REQUEST : "GET_ALL_CORPORATE_REQUEST",
    GET_ALL_CORPORATE_SUCCESS : "GET_ALL_CORPORATE_SUCCESS",
    GET_ALL_CORPORATE_FAILURE : "GET_ALL_CORPORATE_FAILURE",

    ADD_CORPORATE_REQUEST :"ADD_CORPORATE_REQUEST",
    ADD_CORPORATE_SUCCESS : "ADD_CORPORATE_SUCCESS",
    ADD_CORPORATE_FAILURE : "ADD_CORPORATE_FAILURE",

    UPDATE_CORPORATE_REQUEST : "UPDATE_CORPORATE_REQUEST",
    UPDATE_CORPORATE_SUCCESS : "UPDATE_CORPORATE_SUCCESS",
    UPDATE_CORPORATE_FAILURE : "UPDATE_CORPORATE_FAILURE",

    DELETE_CORPORATE_REQUEST : "DELETE_CORPORATE_REQUEST",
    DELETE_CORPORATE_SUCCESS : "DELETE_CORPORATE_SUCCESS",
    DELETE_CORPORATE_FAILURE : "DELETE_CORPORATE_FAILURE",

    CHANGEACTIVE_CORPORATE_REQUEST : "CHANGEACTIVE_CORPORATE_REQUEST",
    CHANGEACTIVE_CORPORATE_SUCCESS : "CHANGEACTIVE_CORPORATE_SUCCESS",
    CHANGEACTIVE_CORPORATE_FAILURE : "CHANGEACTIVE_CORPORATE_FAILURE",

    ADD_CORPORATE_CONS_FEES_REQUEST : "ADD_CORPORATE_CONS_FEES_REQUEST",
    ADD_CORPORATE_CONS_FEES_SUCCESS : "ADD_CORPORATE_CONS_FEES_SUCCESS",
    ADD_CORPORATE_CONS_FEES_FAILURE : "ADD_CORPORATE_CONS_FEES_FAILURE",

    GET_CORPORATE_CONS_FEES_REQUEST : "GET_CORPORATE_CONS_FEES_REQUEST",
    GET_CORPORATE_CONS_FEES_SUCCESS : "GET_CORPORATE_CONS_FEES_SUCCESS",
    GET_CORPORATE_CONS_FEES_FAILURE : "GET_CORPORATE_CONS_FEES_FAILURE",

    DELETE_CORPORATE_CONS_FEES_REQUEST : "DELETE_CORPORATE_CONS_FEES_REQUEST",
    DELETE_CORPORATE_CONS_FEES_SUCCESS : "DELETE_CORPORATE_CONS_FEES_SUCCESS",
    DELETE_CORPORATE_CONS_FEES_FAILURE : "DELETE_CORPORATE_CONS_FEES_FAILURE",

    UPDATE_CORPORATE_CONS_FEES_REQUEST : "UPDATE_CORPORATE_CONS_FEES_REQUEST",
    UPDATE_CORPORATE_CONS_FEES_SUCCESS : "UPDATE_CORPORATE_CONS_FEES_SUCCESS",
    UPDATE_CORPORATE_CONS_FEES_FAILURE : "UPDATE_CORPORATE_CONS_FEES_FAILURE",
};