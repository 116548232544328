import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const reportMenuService = { 
    getAll,
    create

}

function getAll(){
    const requestOptions = {
        method: 'GET',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
    };
    return fetch(`${apiUrl}/reportModule/getAll`, requestOptions).then(helperService.handleResponse);
}

function create(data){
    console.log(data);
    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
    return fetch(`${apiUrl}/reportModule/create`, requestOptions).then(helperService.handleResponse);
}