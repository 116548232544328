export const Icons = {
    CostCenter : "https://assets.aquahis.com/assets/MasterIcons/cost_center.png",
    BankMaster : "https://assets.aquahis.com/assets/MasterIcons/bank_master.png",
    DepartmentMaster : "https://assets.aquahis.com/assets/MasterIcons/department_master.png",
    DepartmentTypeImage : "https://assets.aquahis.com/assets/MasterIcons/department_type.png",
    DepartmentType : "https://assets.aquahis.com/assets/MasterIcons/department_types.png",
    SpecialtyMaster : "https://assets.aquahis.com/assets/MasterIcons/speciality.png",
    DischargeType : "https://assets.aquahis.com/assets/MasterIcons/discount_authorization.png",
    DiscountType : "https://assets.aquahis.com/assets/MasterIcons/discount_type.png",
   Titles : "https://assets.aquahis.com/assets/MasterIcons/title.png", 
   ConsultantTitles : "https://assets.aquahis.com/assets/MasterIcons/consultant-category.png",
   MaritalStatus : "https://assets.aquahis.com/assets/MasterIcons/marital-status.png",
   Nationality : "https://assets.aquahis.com/assets/MasterIcons/nationality.png",
   Religion : "https://assets.aquahis.com/assets/MasterIcons/religions.png",
   Age : "https://assets.aquahis.com/assets/MasterIcons/Ages.png",
   BloodGroup : "https://assets.aquahis.com/assets/MasterIcons/bloodgroups.png",
   Identification : "https://assets.aquahis.com/assets/MasterIcons/identifications.png",
   Gender : "https://assets.aquahis.com/assets/MasterIcons/gender.png",
   RelationShip : "https://assets.aquahis.com/assets/MasterIcons/relationships.png",
   ResinForVIsit : "https://assets.aquahis.com/assets/MasterIcons/reason-for-visit.png",
   HowYouKnowUs : "https://assets.aquahis.com/assets/MasterIcons/how-did-u-et to-know-us.png",
   Language : "https://assets.aquahis.com/assets/MasterIcons/languages.png",
   ConfidentialityType : "https://assets.aquahis.com/assets/MasterIcons/confidentiality-types.png",
   Occupation : "https://assets.aquahis.com/assets/MasterIcons/occupations.png",
   ConsultantCategory : "https://assets.aquahis.com/assets/MasterIcons/consultant category.png",
   PatientType : "https://assets.aquahis.com/assets/MasterIcons/patient-types.png",
   OpVisitType : "https://assets.aquahis.com/assets/MasterIcons/op-visit-types.png",
   IpVisitType : "https://assets.aquahis.com/assets/MasterIcons/ip-visit-types.png",
   RevenueSource : "https://assets.aquahis.com/assets/MasterIcons/revenue-source.png",
   Education : "https://assets.aquahis.com/assets/MasterIcons/languages.png",
   PaymentType : "https://assets.aquahis.com/assets/MasterIcons/payment_type.png",
   PaymentModes : "https://assets.aquahis.com/assets/MasterIcons/payment_mode.png",
   Add : "https://assets.aquahis.com/assets/Buttons/add.png",
   Clear : "https://assets.aquahis.com/assets/Buttons/clear.png",
   Delete : "https://assets.aquahis.com/assets/Buttons/delete.png",
   Edit : "https://assets.aquahis.com/assets/Buttons/edit.png",
   Launch : "https://assets.aquahis.com/assets/Buttons/launch.png",
   Record : "https://assets.aquahis.com/assets/Buttons/record.png",
   Restore : "https://assets.aquahis.com/assets/Buttons/restore.png",
   Save : "https://assets.aquahis.com/assets/Buttons/save.png",
   Search : "https://assets.aquahis.com/assets/Buttons/search.png",
   Update : "https://assets.aquahis.com/assets/Buttons/update.png",
   Upload : "https://assets.aquahis.com/assets/Buttons/upload.png",
   View : "https://assets.aquahis.com/assets/Buttons/view.png",
   IDProof :"https://assets.aquahis.com/assets/images/id-proof.png",
   Symptoms : "",
   Findings : "",
   AssociatedSymptoms : "",
   Diagnosis : "",
   Instruction : "",
   Procedure : "",
   MedicalProblem : "",
   DrugAllergy : "",
   GeneralAllergy : "",
   HighRiskFactor : "",
   LifeStyle : "",
   FamilyHistory : "",
   Logo : "https://assets.aquahis.com/assets/images/logo.png",
   MaleIcon :"https://assets.aquahis.com/assets/images/default-M.png",
   QRCode : "https://assets.aquahis.com/assets/images/qrcode.png",
   Aadhar : "https://assets.aquahis.com/assets/images/aadhar.png",
   ScanQR : "https://assets.aquahis.com/assets/images/scanqr.png",
   AadharCard : "https://assets.aquahis.com/assets/images/aadhar-card.png",
   ConsentForm : "https://assets.aquahis.com/assets/images/consent-form.png",
   Document : "https://assets.aquahis.com/assets/images/document.png",
   DrivingLicense : "https://assets.aquahis.com/assets/images/driving-license.png",
   PanCard : "https://assets.aquahis.com/assets/images/pan-card.png",
   Passport : "https://assets.aquahis.com/assets/images/passport.png",
   ReferralForm : "https://assets.aquahis.com/assets/images/referral-form.png",
   RationCard : "https://assets.aquahis.com/assets/images/ration-card.png",
   VoterID : "https://assets.aquahis.com/assets/images/voter-id.png",
   Cancelled : "https://assets.aquahis.com/assets/images/cancelled.png",
   Dna : "https://assets.aquahis.com/assets/utils/images/originals/dna.png",
   Home : "https://assets.aquahis.com/assets/Icons/home.png",
   Account : "https://assets.aquahis.com/assets/Icons/appointment.png",
   Organization : "https://assets.aquahis.com/assets/Icons/corporate.png",
   EmployeeRegistration : "https://assets.aquahis.com/assets/Icons/registration.png",
   PatientRegistration : "https://assets.aquahis.com/assets/Icons/registration.png",
   OPD : "https://assets.aquahis.com/assets/Icons/out-patient.png",
   IPD : "https://assets.aquahis.com/assets/Icons/in-patient-old.png",
   Laboratory : "https://assets.aquahis.com/assets/Icons/laboratory.png",
   Ward : "https://assets.aquahis.com/assets/Icons/ward.png",
   Pharmacy : "https://assets.aquahis.com/assets/Icons/pharmacy.png",
   Setting : "https://assets.aquahis.com/assets/MasterIcons/FUNCTION.png",
   LocationMaster : "https://assets.aquahis.com/assets/MasterIcons/location_master.png",
   Appointment : "https://assets.aquahis.com/assets/Icons/appointment.png",
   Reportpng : "https://assets.aquahis.com/assets/Icons/report.png",
   Services : "https://assets.aquahis.com/assets/MasterIcons/SERVICES.png",
   SunDepartment : "https://assets.aquahis.com/assets/MasterIcons/SUB DEPARTMENT.png",
   UserReports : "https://assets.aquahis.com/assets/MasterIcons/UserReports.png",
   Store : "https://assets.aquahis.com/assets/Icons/store.png",
   Tax : "https://assets.aquahis.com/assets/Icons/tpa.png",
   Radiology : "https://assets.aquahis.com/assets/Icons/radiology.png",
   Icon : "https://assets.aquahis.com/assets/Icons/in-patient.png",
   Hospital : "https://assets.aquahis.com/assets/MasterIcons/HOSPITAL.png",
   UnDelete : "https://assets.aquahis.com/assets/utils/images/logo/unDeleteIcon.png",
   TaxLogo : "https://assets.aquahis.com/assets/utils/images/logo/taxLogo.png",
   InverseLogo : "https://assets.aquahis.com/assets/utils/images/logo-inverse.png",
   City1 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/city1.jpg",
   City2 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/city2.jpg",
   City3 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/city3.jpg",
   City4 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/city4.jpg",
   City5 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/city5.jpg",
   Abstract1 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract1.jpg",
   Abstract2 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract2.jpg",
   Abstract3 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract3.jpg",
   Abstract4 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract4.jpg",
   Abstract5 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract5.jpg",
   Abstract6 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract6.jpg",
   Abstract7 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract7.jpg",
   Abstract8 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract8.jpg",
   Abstract9 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract9.jpg",
   Abstract10 : "https://assets.aquahis.com/assets/utils/images/dropdown-header/abstract10.jpg",
}