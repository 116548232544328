import {
  faEllipsisV,
  faEye,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { customerActions } from "../../actions";
import { patientActions } from "../../actions/patient.actions";
import { registrationMasterActions } from "../../actions/registrationMaster.actions";
import { apiUrl } from "../../constants/config";
import { helperService } from "../../services/helper.service";
import { loginservice } from "../../services/login.service";
import DataTable from "../MyComponents/DataTable";
import DateRangePicker from "../MyComponents/DateRangePicker";
import PageTitle from "../MyComponents/PageTitle";
import QRScanner from "../MyComponents/QRScanner";
import { Icons } from "../../helpers/IconHelper";

class ViewAllPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: false,
      modal: false,
      startDate: null,
      endDate: null,
      searchToggle: false,
      searchValue: "",
      mode: "",
      selectPatient: null,
      showQRCode: false,
      showPatientForm: false,
      showPatientCard: false,
      showQRCardsModal: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    //  dispatch(patientActions.getAll());
    this.getTodaysPatient();

    dispatch(registrationMasterActions.getAll());
    const { selected_patient, transaction, customer } = this.props;
    if (selected_patient) {
      if (
        selected_patient.registering_as === "couple" &&
        selected_patient.partner_uhid
      )
        dispatch(patientActions.getByUHID(selected_patient.partner_uhid));

      if (transaction)
        dispatch(
          customerActions.getCustomerById(transaction.customer_location_id)
        );
    }
  }

  onAdmitClick(row) {
    const { dispatch, history } = this.props;
    dispatch(patientActions.selectPatient(row._id));
    history.push(`${this.props.match.url}/patientList`);
  }

  onEditClicked(row) {
    this.setState({ mode: "EDIT", selectPatient: row });
    this.toggle();
  }
  onViewClicked(row) {
    if (row.confidentiality === "true") {
      this.toggle();
      this.setState({ mode: "VIEW", selectPatient: row });
      return;
    } else {
      const { dispatch } = this.props;
      dispatch(patientActions.selectPatient(row._id));
      this.props.history.push({
        pathname: "/patient-registration/transactions/new-patient",
        state: {
          mode: "VIEW",
        },
      });
    }
  }
  onDeleteClicked(row) {
    const data = {
      id: row._id,
      updated_by: "Admin",
    };

    const { dispatch } = this.props;
    dispatch(patientActions.remove(data));
  }
  toggleQRCodeModal() {
    this.setState({ showQRCode: !this.state.showQRCode });
  }

  togglePatientFormModal() {
    this.setState({ showPatientForm: !this.state.showPatientForm });
  }
  togglePatientCardModal() {
    this.setState({ showPatientCard: !this.state.showPatientCard });
  }

  togglePatientQRCardsModal() {
    this.setState({ showQRCardsModal: !this.state.showQRCardsModal });
  }

  onViewPatientForm(row) {
    const { dispatch } = this.props;
    dispatch(patientActions.selectPatient(row._id));
    if (row.registering_as === "couple" && row.partner_uhid) {
      dispatch(patientActions.getByUHID(row.partner_uhid));
    }

    this.togglePatientFormModal();
  }
  onViewPatientIDCard(row) {
    const { dispatch } = this.props;
    dispatch(patientActions.selectPatient(row._id));
    this.togglePatientCardModal();
  }
  onViewPatientQRCode(row) {
    const { dispatch } = this.props;
    dispatch(patientActions.selectPatient(row._id));
    this.toggleQRCodeModal();
  }
  columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row) =>
        row.firstname
          ? row.firstname
          : "" + " " + row.lastname
          ? row.lastname
          : "",
      width: "130px",
    },
    {
      name: "UHID",
      selector: "patient_uhid",
      sortable: true,
      width: "120px",
    },
    {
      name: "MRD No.",
      selector: "mrd_no",
      sortable: true,
      width: "60px",
    },

    {
      name: "Reg date",
      sortable: true,
      selector: (r) => r.reg_date && moment(r.reg_date).format("DD/MM/YYYY"),
      width: "90px",
    },
    {
      name: "Age",
      selector: "age_in_years",
      width: "50px",
      sortable: true,
    },
    {
      name: "Gender",
      selector: (col) => this.codeToValue(col.gender_code, "genders"),
      width: "60px",
      sortable: true,
    },
    {
      name: "Spouse",
      selector: "relative_name",
      width: "80px",
      sortable: true,
    },
    {
      name: "Type",
      selector: (col) => (
        <span style={{ textTransform: "capitalize" }}>
          {col.registering_as}
        </span>
      ),
      width: "80px",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
      width: "70px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "80px",
    },
    {
      name: "Actions",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <a
            style={{ padding: 3, margin: 2 }}
            onClick={() => {
              this.onEditClicked(row);
            }}
            title="Edit"
            className="text-warning font-icon-wrapper "
          >
            {" "}
            <FontAwesomeIcon icon={faUserEdit} size="1x" />
          </a>

          <a
            style={{ padding: 3, margin: 2 }}
            onClick={() => {
              this.onViewClicked(row);
            }}
            title="View"
            className="text-primary font-icon-wrapper "
          >
            {" "}
            <FontAwesomeIcon icon={faEye} size="1x" />{" "}
          </a>
          <UncontrolledButtonDropdown>
            <DropdownToggle color="basic" style={{ padding: 0 }}>
              {/* <FontAwesomeIcon icon={faEllipsisV} size="1x"/> */}
              <a
                style={{ padding: "3px 8px", margin: 2 }}
                title="View"
                className="text-primary font-icon-wrapper "
              >
                {" "}
                <FontAwesomeIcon icon={faEllipsisV} size="1x" />{" "}
              </a>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-left dropdown-menu-rounded dropdown-menu-hover-primary">
              <DropdownItem
                onClick={() => {
                  this.onViewPatientForm(row);
                }}
              >
                Print Form
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.onViewPatientQRCode(row);
                }}
              >
                Print QR Code
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.onViewPatientIDCard(row);
                }}
              >
                Print ID Card
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          {/* <a style={{padding:'3px 5px',margin:2}}  onClick={()=>{this.onDeleteClicked(row)}}  title="Delete" className="text-danger font-icon-wrapper "> <FontAwesomeIcon icon={faTrash} size="1x"/>  </a> */}
        </>
      ),
    },
    {
      name: "Admit",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <Button color="primary" onClick={() => this.onAdmitClick(row)}>
            Admit
          </Button>
        </>
      ),
    },
  ];
  conditionalRowStyles = [
    {
      when: (row) => row.confidentiality_type === "VIP",
      style: {
        backgroundColor: "red",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.confidentiality_type === "Confidential Disease",
      style: {
        backgroundColor: "blue",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.confidentiality_type === "Celebrity",
      style: {
        backgroundColor: "orange",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  onChangeText(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  onDateChange(e) {
    const target = e.target;
    console.log("Date range", e);
    this.setState({ startDate: target.value.start, endDate: target.value.end });
  }

  onSearchWithInDate() {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) {
      const from = moment(startDate).format("YYYY-MM-DD");
      const to = moment(endDate).format("YYYY-MM-DD");
      const { dispatch } = this.props;
      this.setState({ searchToggle: true });
      dispatch(patientActions.getByDate(from, to));
    }
  }

  getTodaysPatient() {
    const from = moment().format("YYYY-MM-DD");
    const to = moment().format("YYYY-MM-DD");
    const { dispatch } = this.props;
    dispatch(patientActions.getByDate(from, to));
  }

  onClearDateFilter() {
    //this.setState({searchToggle:false})
    const { dispatch } = this.props;
    this.getTodaysPatient();
    //dispatch(patientActions.getAll());
  }
  // Init a timeout variable to be used below
  timeout = null;
  onSearchValueChanged(value) {
    clearTimeout(this.timeout);

    const { dispatch } = this.props;
    if (value) {
      // Make a new timeout set to go off in 1000ms (1 second)
      this.timeout = setTimeout(() => {
        dispatch(patientActions.searchPatient(value));
      }, 1000);
    }
    this.setState({ searchValue: value });
  }

  onLogin() {
    const { username, password, mode, selectPatient } = this.state;

    const { dispatch } = this.props;
    const payload = { username, password, login_type: "consultant" };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          if (mode === "EDIT") {
            dispatch(patientActions.selectPatient(selectPatient._id));
            this.props.history.push({
              pathname: "/patient-registration/transactions/new-patient",
              state: {
                mode: "EDIT",
              },
            });
          } else if (mode === "VIEW") {
            dispatch(patientActions.selectPatient(selectPatient._id));
            this.props.history.push({
              pathname: "/patient-registration/transactions/new-patient",
              state: {
                mode: "VIEW",
              },
            });
          }
          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  onScanComplete(data) {
    if (data.patient_uhid) this.setState({ searchValue: data.patient_uhid });
    else {
      this.setState({ searchValue: "" });
    }
  }

  exportToExcel() {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      const from = moment(startDate).format("YYYY-MM-DD");
      const to = moment(endDate).format("YYYY-MM-DD");

      fetch(`${apiUrl}/newpatient/export/${from}/${to}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: helperService.getHeaderData(),
        },
      })
        .then(function(response) {
          return response.blob();
        })
        .then(function(blob) {
          saveAs(blob, "Patient List.xlsx");
        })
        .catch((error) => {
          console.log(error);
          //whatever
        });
    }
  }

  render() {
    const { patients, masters, dateWisePatients } = this.props;

    return (
      <Fragment>
        <PageTitle
          icon={Icons.IPD}
          heading="IPD Patient List"
          printComponent={this.componentRef}
          data={patients}
          columns={this.columns}
          csvFilename="Patientlist.csv"
          onExportClicked={() => this.exportToExcel()}
        />
        {/* <Button onClick={()=>this.exportToExcel()}>Export to Excel</Button> */}

        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div>
            <Nav className="header-megamenu">
              <NavItem>
                <NavLink>
                  <Link to="/patient-registration/transactions/new-patient">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Registration
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="/out-patient-department/opd-billing/opd-billing">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    OP Billing
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="/out-patient-department/appointments-schedule">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Appointment
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Sticker
                  </Link>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink>
                  <Link to="">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Case sheet
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Bill History
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Consolidate
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="">
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    IP Admit
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link>
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    Barcode Stickers
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link>
                    <i className="nav-link-icon pe-7s-gift"> </i>
                    QRCode Stickers
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>

            <Card className="main-card mb-3">
              <CardBody>
                <div ref={(el) => (this.componentRef = el)}>
                  {masters && (
                    <DataTable
                      columns={this.columns}
                      searchValue={this.state.searchValue}
                      onSearchValueChanged={(value) => {
                        this.onSearchValueChanged(value);
                      }}
                      data={patients}
                      onRowClicked={(row) => {
                        this.onViewClicked(row);
                      }}
                      conditionalRowStyles={this.conditionalRowStyles}
                      subHeader={true}
                      subHeaderComponent={
                        <Row style={{ marginRight: "-29px" }}>
                          {/* <Col md={1}></Col> */}
                          <Col md={6}>
                            <DateRangePicker
                              name="dateRange"
                              onChange={this.onDateChange.bind(this)}
                              onSubmit={this.onSearchWithInDate.bind(this)}
                              onClear={() => {
                                this.onClearDateFilter();
                              }}
                            />
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col md={8}></Col>

                              <Col md={4}>
                                <QRScanner
                                  onScanCompleted={this.onScanComplete.bind(
                                    this
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>{" "}
                        </Row>
                      }
                    />
                  )}
                </div>
                <Modal
                  isOpen={this.state.modal}
                  toggle={() => this.toggle()}
                  className={this.props.className}
                  style={{ width: 400 }}
                >
                  <ModalHeader toggle={() => this.toggle()}>
                    Authentication Required!{" "}
                  </ModalHeader>
                  <ModalBody>
                    <Row form>
                      <Col md={8} sm={8} className="offset-sm-1">
                        <FormGroup>
                          <Label for="username">Username</Label>
                          <Input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Username"
                            onChange={(e) => {
                              this.onChangeText(e);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="password">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => {
                              this.onChangeText(e);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          {/* <Label for="password">User Type</Label>
                                                   <CustomInput type="select" name="login_type" onChange={e=>{this.onChangeText(e)}}>
                                                   <option value="">Select</option> 
                                                     <option value="user">User</option> 
                                                      <option value="consultant">Consultant</option>
                                                   </CustomInput> */}
                          <Label for="reason">Reason for Edit</Label>
                          <Input
                            type="text"
                            name="reason"
                            placeholder="Reason for Edit"
                            onChange={(e) => {
                              this.onChangeText(e);
                            }}
                          />
                        </FormGroup>
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}></Col>
                      <Col md={2}>
                        {" "}
                        <Button color="link" onClick={() => this.toggle()}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2}>
                        {" "}
                        <Button color="primary" onClick={() => this.onLogin()}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { patients, registrationMaster, customers } = state;

  return {
    patients: patients.patients,
    masters: registrationMaster.data,
    dateWisePatients: patients.dateWisePatients,
    transaction: customers.currentTransaction,
    customer: customers.selected_customer,
  };
}

const connectedViewAllPatients = connect(mapStateToProps)(ViewAllPatients);
export default connectedViewAllPatients;
//export { connectedFormDemo as FormDemo };
