import moment from 'moment';
import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import {faWindowClose,faEdit, faPhone, faEnvelope, faMedkit, faPen, faMobile, faPrint} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col,  Row, 
  Input,
  CustomInput,
  FormGroup,
  FormFeedback,
  Label,} from 'reactstrap';  
import * as Yup from "yup";
import {apiUrl} from '../../../constants/config'
import { connect } from "react-redux";
import {otBookingActions  } from '../../../actions';
import PrintOTBooking from '../../Modals/PrintOTBooking/index'
import ChangeOTBookingStatus from '../../Modals/ChangeOTBookingStatus'

class BookingPopup extends Component {

  constructor(props) {
      super(props); 
      this.state={
        showOTBookingForm:false,
        showOTBookingStatusModal:false,
        surgery_status:null,
  
          initialValues:this.initialValues
        
      }    
    }

    initialValues = {
      surgery_status_type: "",
      surgery_status_remarks:""
    };
  
    onSubmit = (values) => {
      const { booking, dispatch } = this.props;
      const { surgery_status_type,surgery_status_remarks } = values;
      if (booking) {
        const payload = {
          id: booking._id,
          surgery_status:this.state.surgery_status,
          surgery_status_type: surgery_status_type,
          surgery_status_remarks:surgery_status_remarks
        };
        dispatch(otBookingActions.update(payload));
        this.props.toggle();
      }
    };
  
    validationSchema = Yup.object({
      surgery_status_type: Yup.string().required("Required"),
      surgery_status_remarks: Yup.string(),
    });
  

    toggleOTBookingModal=()=>
{
    this.setState({showOTBookingForm:!this.state.showOTBookingForm});
}

toggleOTBookingStatusModal=()=>
{
   // this.setState({showOTBookingStatusModal:!this.state.showOTBookingStatusModal});
}

   async onUpdate(status,e)
  {
    // const {booking,dispatch}=this.props;
    // const data={id:booking._id,patient_id:booking.patient_id._id, surgery_status:status}
    // let isBooked=await   dispatch(otBookingActions.update(data));
this.setState({surgery_status:status});
  }

  

  codeToValue(code,name)
  {
    try{
      const {masters}=this.props
      if(masters)
      return masters[name].list.find(item=>item.code===code).name
      else return code;
        }
        catch{
          return code;
        }
  }

  componentDidMount()
  {
    const {booking}=this.props;
    if(booking)
    {
      this.setState({initialValues:{
        surgery_status:booking.surgery_status,
        surgery_status_type:booking.surgery_status_type,
      surgery_status_remarks:booking.surgery_status_remarks
      }})
    }
  }

    render() {
        const {booking,consultants}=this.props;
        const {surgery_status}=this.state;
        if(!booking.patient_id)
        {
          return <>No data to show</>
        }
        let patient=booking.patient_id;
    

        

    let status_types=<></>;
    if(surgery_status==="COMPLETED")
    {
      status_types=<>
      <option value="Successful">Successful</option>
      <option value="Partially Successful">Partially Successful</option></>
    }
    else if(surgery_status==="CANCELLED")
{  status_types=<>
  <option value="Cancelled on Table">Cancelled on Table</option>
  <option value="Pre Notified Cancellation">Pre Notified Cancellation</option></>}
      else if(surgery_status==="POSTPONED")
      {  status_types=<>
        <option value="Postponed by Hospital">Postponed by Hospital</option>
        <option value="Postponed by Patient">Postponed by Patient</option></>}


        return (
            <Fragment>
 <div style={{backgroundColor:'#ffffff',width:'370px'}}>
     
      <Row>
        <Col sm="2">
          <img src={`${apiUrl}${patient.photo}`} alt="User Image" style={{width:'50px',height:'auto',borderRadius:'50%'}}/>
        </Col>
        <Col sm="10">
          <Row style={{padding:0,margin:0}}>
          <Col sm="10">
          <h4  style={{marginBottom:0}}>{this.codeToValue(patient.title_code,'titles')} {patient.firstname} {patient.middlename} {patient.lastname} </h4> 
            </Col>
            <Col sm="2">
            <Button color="link"  outline style={{borderRadius:'50%',backgroundColor:'#5a9ece',color:'#fff',padding:'5px 8px'}} onClick={this.toggleOTBookingModal.bind(this)}>
          <FontAwesomeIcon icon={faPrint} size="1x"/>
          </Button>
            </Col>
          </Row>
          <p style={{margin:0}}>UHID : {patient.patient_uhid}</p> 
        </Col>
      </Row>
      <hr/>
      <p><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> &nbsp; {patient.mobile}<br/>
      <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp; {patient.email}</p>
     <hr/>
      <p><FontAwesomeIcon icon={faMedkit}></FontAwesomeIcon> &nbsp; Operation booked with <b>{this.codeToValue(booking.consultant_id.title,'titles')} {booking.consultant_id?booking.consultant_id.firstname:"Dr. Name"}</b> at {moment(booking.time_slot_from).format('HH:mm A')} for {booking.slot_duration} duration</p>       
      <hr/>   
   <p> Current Status : {booking.surgery_status}</p>
     <Row>
       <Col sm="3">Surgery Status</Col>
       <Col sm="3"><Button block size="sm" color="success" onClick={this.onUpdate.bind(this,'COMPLETED')}>Completed</Button>  </Col>
       <Col sm="3"><Button block size="sm"  color="danger" onClick={this.onUpdate.bind(this,'CANCELLED')}>Cancelled</Button></Col>
       <Col sm="3"><Button block size="sm"  color="warning" onClick={this.onUpdate.bind(this,'POSTPONED')}>Postponed</Button></Col>
     </Row>
     <Formik
            initialValues={this.state.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
            enableReinitialize
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                getFieldProps,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>                 
                
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="surgery_status_type">
                            Please select type *{" "}
                          </Label>
                          <CustomInput bsSize="sm"
                            type="select"
                            name="surgery_status_type"
                            {...getFieldProps("surgery_status_type")}
                          >
                            <option value="">Please Select</option>
                            {status_types}
                          </CustomInput>
                          <FormFeedback>
                            {errors.surgery_status_type &&
                              touched.surgery_status_type &&
                              errors.surgery_status_type}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="surgery_status_remarks">Remarks </Label>
                          <Input
                            type="text" bsSize="sm"
                            name="surgery_status_remarks"
                            {...getFieldProps("surgery_status_remarks")}
                          ></Input>
                          <FormFeedback>
                            {errors.surgery_status_remarks &&
                              touched.surgery_status_remarks &&
                              errors.surgery_status_remarks}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
         <Row>
    
           <Col sm="12" > <Button
                      color="primary" block
                      className="btn-shadow  btn-hover-shine right"
                      type="submit"
                    >
                      Done
                    </Button></Col>
         </Row>
                   

                   
                 
                </Form>
              );
            }}
          </Formik>
    
            
     <hr/>
      <p><FontAwesomeIcon icon={faPen}></FontAwesomeIcon> &nbsp; {booking.notes} </p>
       
    
      <PrintOTBooking openModal={this.state.showOTBookingForm} toggle={this.toggleOTBookingModal.bind(this)}/>

   </div>        
 
               
                  
                
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
  const { consultants,registrationMaster } = state;

  return {  consultants:consultants.consultants,masters:registrationMaster.data };
}

const connectedBookingPopup = connect(mapStateToProps)(BookingPopup);
export default connectedBookingPopup;