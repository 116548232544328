import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { dischargemasterService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const dischargemasterActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    dischargemasterService.getAll().then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success("Discharge Type Master loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_DISCHARGE_TYPE_REQUEST };
  }
  function success(dischargemaster) {
    return {
      type: generalMasterConstants.GET_DISCHARGE_TYPE_SUCCESS,
      dischargemaster,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_DISCHARGE_TYPE_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    dischargemasterService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_DISCHARGE_TYPE_REQUEST };
  }
  function success(dischargemaster) {
    return {
      type: generalMasterConstants.ADD_DISCHARGE_TYPE_SUCCESS,
      dischargemaster,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_DISCHARGE_TYPE_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    dischargemasterService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DISCHARGE_TYPE_REQUEST };
  }
  function success(dischargemaster) {
    return {
      type: generalMasterConstants.UPDATE_DISCHARGE_TYPE_SUCCESS,
      dischargemaster,
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.UPDATE_DISCHARGE_TYPE_FAILURE,
      error,
    };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    dischargemasterService.remove(payload).then(
      (data) => {
        if (data) {
          dispatch(success(payload.id));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_DISCHARGE_TYPE_REQUEST };
  }
  function success(dischargemaster) {
    return {
      type: generalMasterConstants.DELETE_DISCHARGE_TYPE_SUCCESS,
      dischargemaster,
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.DELETE_DISCHARGE_TYPE_FAILURE,
      error,
    };
  }
}
