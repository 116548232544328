
    export const otbookingConstants={

       ADD_OT_BOOKING_REQUEST:'ADD_OT_BOOKING_REQUEST',
       ADD_OT_BOOKING_SUCCESS:'ADD_OT_BOOKING_SUCCESS',
       ADD_OT_BOOKING_FAILURE:'ADD_OT_BOOKING_FAILURE' ,

       GET_ALL_OT_BOOKING_REQUEST:'GET_ALL_OT_BOOKING_REQUEST',
       GET_ALL_OT_BOOKING_SUCCESS:'GET_ALL_OT_BOOKING_SUCCESS',
       GET_ALL_OT_BOOKING_FAILURE:'GET_ALL_OT_BOOKING_FAILURE' ,

       GET_BY_CONSULTANT_REQUEST:'GET_BY_CONSULTANT_REQUEST',
       GET_BY_CONSULTANT_SUCCESS:'GET_BY_CONSULTANT_SUCCESS',
       GET_BY_CONSULTANT_FAILURE:'GET_BY_CONSULTANT_FAILURE' ,

       SELECTED_OT_BOOKING_REQUEST:'SELECTED_OT_BOOKING_REQUEST',
       SELECTED_OT_BOOKING_SUCCESS:'SELECTED_OT_BOOKING_SUCCESS',
       SELECTED_OT_BOOKING_FAILURE:'SELECTED_OT_BOOKING_FAILURE',


       UPDATE_OT_BOOKING_REQUEST:'UPDATE_OT_BOOKING_REQUEST',
       UPDATE_OT_BOOKING_SUCCESS:'UPDATE_OT_BOOKING_SUCCESS',
       UPDATE_OT_BOOKING_FAILURE:'UPDATE_OT_BOOKING_FAILURE' ,

       DELETE_OT_BOOKING_REQUEST:'DELETE_OT_BOOKING_REQUEST',
       DELETE_OT_BOOKING_SUCCESS:'DELETE_OT_BOOKING_SUCCESS',
       DELETE_OT_BOOKING_FAILURE:'DELETE_OT_BOOKING_FAILURE' ,

       OT_BOOKING_REMINDER_REQUEST:'OT_BOOKING_REMINDER_REQUEST',
       OT_BOOKING_REMINDER_SUCCESS:'OT_BOOKING_REMINDER_SUCCESS',
       OT_BOOKING_REMINDER_FAILURE:'OT_BOOKING_REMINDER_FAILURE' ,

       OT_BOOKING_CLEAR:'OT_BOOKING_CLEAR'
    }