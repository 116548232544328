import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment-timezone";
import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { patientService } from "../../services";
import QRScanner from "./QRScanner";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class PatientSearchBox extends Component {
  state = {
    searchValue: "",
    patients: [],
    selectedPatient: null,
    loading: false,
    dropdownOpen: false,
    popoverOpen: false,
  };

  onScanComplete = (data) => {
    if (data.patient_uhid) {
      patientService
        .findPatients({ query: data.patient_id })
        .then((res) => {
          if (res.isSuccess) {
            let patient = res.value[0];
            this.setState({ selectedPatient: patient });
            if (this.props.onPatientSelected) {
              this.props.onPatientSelected(patient);
            }
          }
        })
        .catch((err) => {});
      this.setState({ searchValue: data.patient_uhid });
    } else {
      this.setState({ searchValue: "" });
    }
  };

  onKeyUp = (e) => {
    e.persist();
    this.setState({ loading: true });
    // Make a new timeout set to go off in 1000ms (1 second)
    this.timeout = setTimeout(() => {
      let data = e.target.value;
      if (data !== "") {
        patientService
          .findPatients({ query: data })
          .then((res) => {
            if (res.isSuccess) {
              //   this.toggleSearch(true)
              this.setState({ patients: res.value, dropdownOpen: true });
            }
          })
          .catch((err) => {
            // this.toggleSearch(false)
            this.setState({ dropdownOpen: false });
          });
        this.setState({ loading: false });
      }
    }, 1000);
  };

  onSubmit = (e) => {
    this.setState({ loading: true });
    let data = this.state.searchValue;
    if (data !== "") {
      patientService
        .findPatients({ searchValue: data, page: 1, records: 300 })
        .then((res) => {
          if (res.isSuccess) {
            this.setState({ patients: res.value, dropdownOpen: true });
          }
        })
        .catch((err) => {
          this.setState({ dropdownOpen: false });
        });
      this.setState({ loading: false });
    }
  };

  onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        if (rowInfo.original) {
          this.setState({
            selectedPatient: rowInfo.original,
            dropdownOpen: false,
          });
          if (this.props.onPatientSelected) {
            this.props.onPatientSelected(rowInfo.original);
          }
          //this.toggleSearch(false)
        }
        // console.log('A Td Element was clicked!')
        // console.log('it produced this event:', e)
        // console.log('It was in this column:', column)
        // console.log('It was in this row:', rowInfo)
        // console.log('It was in this table instance:', instance)
      },
    };
  };

  render() {
    const { searchValue, patients, loading } = this.state;
    return (
      <>
        <div
          style={{
            border: "2px solid #30B1FF",
            background: "#EEF1F3",
            borderRadius: "15px",
            width: "100%",
          }}
        >
          <div style={{ float: "left", width: "15%", padding: "15px 5px" }}>
            <Label style={{ textAlign: "right" }}>
              <FontAwesomeIcon icon={faSearch} /> Search Patient{" "}
            </Label>
          </div>
          <div style={{ float: "left", width: "75%", padding: "15px 5px" }}>
            <FormGroup>
              <div style={{ position: "relative" }}>
                <form onSubmit={this.onSubmit}>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={searchValue}
                    onChange={(e) => {
                      this.setState({ searchValue: e.target.value });
                    }}
                    placeholder="Enter Patient UHID and Press Enter To Search Patient"
                  />
                  {this.state.loading ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100px",
                        backgroundColor: "#fff",
                        textAlign: "center",
                        zIndex: 100,
                      }}
                    >
                      {" "}
                      <Loader
                        type="RevolvingDot"
                        color="black"
                        height={100}
                        width={100}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </form>

                {this.state.dropdownOpen === true && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      background: "#fff",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={() => this.setState({ dropdownOpen: false })}
                      >
                        Close
                      </Button>
                    </div>
                    <ReactTable
                      data={patients}
                      hover
                      size="sm"
                      getTrProps={this.onRowClick}
                      columns={[
                        {
                          columns: [
                            //     {
                            //     Header: 'Photo',
                            //     id:'Photo',
                            //     width:60,
                            //     accessor: d=><img
                            //     style={{
                            //       width: 40,
                            //       height: 40,
                            //       border: "1px solid grey"
                            //     }}
                            //     className="rounded-circle"
                            //     src={apiUrl + d.photo}
                            //     alt=""
                            //   />
                            // },
                            {
                              Header: "Name",
                              id: "firstname",
                              accessor: (d) =>
                                d.firstName +
                                " " +
                                d.middleName +
                                " " +
                                d.lastname,
                            },
                            {
                              Header: "Patient UHID",
                              id: "uhid",
                              accessor: (d) => d.uhid,
                            },

                            {
                              Header: "Mobile",
                              id: "mobile",
                              accessor: (d) => d.mobile,
                            },
                            {
                              Header: "Date",
                              id: "reg_date",
                              accessor: (d) =>
                                moment(d.registrationDate)
                                  .tz("Asia/Kolkata")
                                  .format("DD/MM/YYYY"),
                            },
                          ],
                        },
                      ]}
                      defaultPageSize={10}
                      className="-stripped -highlight"
                    />
                  </div>
                )}
              </div>
            </FormGroup>
          </div>
          <div style={{ float: "left", width: "10%" }}>
            <QRScanner onScanCompleted={this.onScanComplete} />
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </>
    );
  }
}
