import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

const departmentService = {
  getAll,
  add,
  active,
  update,
  remove,
  addSubdepartment,
  updateSubdepartment,
  removeSubdepartment,
  getStock,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departments`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departments`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departments/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function active(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departments/active`, requestOptions).then(
    helperService.handleResponse
  );
}
function remove(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/departments/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function addSubdepartment(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/subdepartments`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateSubdepartment(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/subdepartments/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function removeSubdepartment(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/subdepartments/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

//get stock from department stock table

function getStock() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/departmentStock`, requestOptions).then(
    helperService.handleResponse
  );
}

export default departmentService;
