import { userMasterConstants } from "../constants/userMaster.constants";

export function userMaster(state = {}, action) {
  switch (action.type) {
    case userMasterConstants.SELECT_EMPLOYEE_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.SELECT_EMPLOYEE_SUCCESS:
      // let selected_consultant = state.consultants.find(
      //   (ob) => ob.id === action.consultantId
      // );

      return {
        ...state,
        selected_employee: action.employee,
        loading: false,
      };

    case userMasterConstants.SELECT_EMPLOYEE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userMasterConstants.ADD_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.ADD_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case userMasterConstants.ADD_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userMasterConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.UPDATE_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case userMasterConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userMasterConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.GET_ALL_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case userMasterConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userMasterConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.DELETE_SUCCESS:
      let cindex = state.data.data.findIndex((ob) => ob.id === action.data.id);
      state.data.data[cindex].deleted = action.data.data.deleted;

      return { ...state, data: { ...state.data }, loading: false };
    case userMasterConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userMasterConstants.SEARCH_ALL_REQUEST:
      return { ...state, loading: true };
    case userMasterConstants.SEARCH_ALL_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case userMasterConstants.SEARCH_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
