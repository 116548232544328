import React from "react";

const SuspenseLoader = () => {
  return (
    <div className="pt-3 text-center">
      <div className="spinner-grow spinner-grow-sm text-dark"></div>
    </div>
  );
};

export default React.memo(SuspenseLoader);
