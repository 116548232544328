import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Bounce, toast } from "react-toastify";
import {
  Button,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ModalFooter from "reactstrap/lib/ModalFooter";
import * as Yup from "yup";
import { sweetAlertActions } from "../../actions";
import CommonTable from "../../components/commonTable";
import { searchObjectsByPartialString } from "../../helpers/utils";
import { reminderService } from "../../services/reminder.service";

class Reminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      initialValues: this.initialValues,
      selected_item: null,
    };
    this.onDateWiseSearch = this.onDateWiseSearch.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onTextSearch = this.onTextSearch.bind(this);
    this.onActiveChanged = this.onActiveChanged.bind(this);
  }

  // initial Values
  initialValues = {
    consultantId: "",
    title: "",
    reminderDate: moment(new Date()).format("YYYY-MM-DD"),
    alertTime: null,
    duration: "All Day",
  };

  // On Load Component
  componentDidMount() {
    const { dispatch, consultant } = this.props;
    if (consultant) {
      this.initialValues.consultantId = consultant.id;
    }
    reminderService
      .getAll()
      .then((res) => {
        if (res.isSuccess) {
          this.setState({ list: res.value });
        }
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  }

  // On text Search
  onTextSearch(startDate, endDate, value) {
    if (startDate && endDate) {
      reminderService
        .getAll(`title=${value}&startDate=${new Date(startDate)}&endDate=${new Date(endDate)}`)
        .then((res) => {
          if (res.isSuccess) {
            const dataOrders = res.value?.map((row) => {
              return {
                ...row,
                name: this.consultantToValue(row.consultantId),
              };
            });
            if (value?.length > 0) {
              const filteredData = searchObjectsByPartialString(
                dataOrders,
                value
              );
              this.setState({ list: filteredData });
            } else {
              this.setState({ list: dataOrders });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // On click clear
  onClearClicked() {
    this.setState({ initialValues: this.initialValues, selected_item: null });
  }

  // On submit Call
  onSubmit = (values, { resetForm }) => {
    const { selected_item, list } = this.state;

    let payload;
    if (!selected_item) {
      payload = values;
      reminderService
        .add(payload)
        .then((data) => {
          if (data.success) {
            resetForm();
            this.setState({
              list: [data.reminder, ...this.state.list],
              initialValues: this.initialValues,
            });
          }
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: data.success ? "success" : "error",
          });
        })
        .catch((err) => {
          toast("Error", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "error",
          });
        });
    } else {
      payload = { id: selected_item.id, ...values };
      reminderService
        .update(payload)
        .then((data) => {
          if (data.success) {
            resetForm();
            const updated_item = data.reminder;
            var prevData = [...this.state.list];
            let index = list.findIndex((ob) => ob.id === updated_item.id);
            prevData[index] = updated_item;
            this.setState({
              list: prevData,
              initialValues: this.initialValues,
              selected_item: null,
            });
          }
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
        })
        .catch((err) => {
          toast("Error", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "error",
          });
        });
    }
  };

  // Change Consultant code to Label
  consultantToValue(code) {
    try {
      const { consultants } = this.props;
      if (consultants) {
        const cons = consultants.find((item) => item.id === code);
        return (
          (cons ? cons.firstname : " ") +
          " " +
          (cons.lastname ? cons.lastname : "")
        );
      } else return code;
    } catch {
      return code;
    }
  }

  // Schema Validation
  validationSchema = Yup.object({
    consultantId: Yup.number().required("Required"),
    title: Yup.string().required("Required"),
    reminderDate: Yup.date().required("Required"),
    alertTime: Yup.string().nullable(),
    duration: Yup.string().required("Required"),
  });

  // Handle The Edit Flow
  onEditClicked(row) {
    this.setState({
      selected_item: row,
      initialValues: {
        consultantId: row.consultantId,
        title: row.title,
        reminderDate: row.reminderDate,
        alertTime: row.alertTime,
        duration: row.duration,
      },
    });
  }

  // Handle Status Change
  onActiveChanged(row) {
    const { list } = this.state;
    let payload = { id: row.id, active: !row.active };
    reminderService
      .update(payload)
      .then((data) => {
        if (data.isSuccess) {
          const updated_item = data.value;
          var prevData = [...list];
          let index = list.findIndex((ob) => ob.id === updated_item.id);
          prevData[index] = updated_item;
          this.setState({ list: prevData });
        }
        toast(data.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "success",
        });
      })
      .catch((err) => {
        toast("Error", {
          transition: Bounce,
          closeButton: true,
          autoClose: 1000,
          position: "bottom-center",
          type: "error",
        });
      });
  }

  // Handle Delete Change
  onDeleteClicked(row) {
    const { dispatch } = this.props;
    dispatch(
      sweetAlertActions.show(
        "Do you want to " + row.deleted ? "restore" : "delete",
        {
          title: `Do you want to ${row.deleted ? "Restore" : "Delete"}?`,
          confirmButtonColor: !row.deleted ? "red" : "blue",
          text: "You can restore the changes if you want!",
          showCancelButton: true,
          onConfirm: () => {
            const { list } = this.state;
            let payload = { id: row.id, deleted: !row.deleted };
            if (!row.deleted) {
              payload["active"] = false;
            }

            reminderService
              .update(payload)
              .then((data) => {
                if (data.isSuccess) {
                  const updated_item = data.value;
                  var prevData = [...list];
                  let index = list.findIndex(
                    (ob) => ob.id === updated_item.id
                  );
                  prevData[index] = updated_item;
                  this.setState({ list: prevData });
                }
                toast(data.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 1000,
                  position: "bottom-center",
                  type: "success",
                });
              })
              .catch((err) => {
                toast("Error", {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 1000,
                  position: "bottom-center",
                  type: "error",
                });
              });
            dispatch(sweetAlertActions.hide());
          },
          onCancel: () => dispatch(sweetAlertActions.hide()),
        }
      )
    );
  }

  // Handle Date Wise Search
  onDateWiseSearch(from, to) {
    if (from && to) {
      this.setState({ searchToggle: true });
      reminderService
        .getAll(`startDate=${new Date(from)}&endDate=${new Date(to)}`)
        .then((data) => {
          if (data.isSuccess) {
            this.setState({ list: data.value });
          }
        })
        .catch((err) => {});
    }
  }

  render() {
    const { list } = this.state;
    let tableData = [];
    if (list?.length) {
      tableData = list?.map((row) => {
        return {
          ...row,
          name: this.consultantToValue(row.consultantId),
        };
      });
    }
    const columnHeader = [
      { name: "Sr.NO", type: "SRNO", field: "", sortable: true, width: "70px" },
      {
        name: "Consultant",
        type: "TEXT",
        field: "name",
        sortable: true,
        width: "190px",
      },
      {
        name: "Title",
        type: "TEXT",
        field: "title",
        sortable: true,
        width: "190px",
      },
      {
        name: "Date",
        type: "DATE",
        field: "reminderDate",
        sortable: true,
        width: "120px",
      },
      {
        name: "Alert Time",
        type: "DATE",
        field: "alertTime",
        sortable: true,
        width: "120px",
      },
      {
        name: "Duration",
        type: "TEXT",
        field: "duration",
        sortable: true,
        width: "100px",
      },
      {
        name: "Created On",
        type: "DATE",
        field: "created_on",
        sortable: true,
        width: "120px",
      },
      {
        name: "Active",
        type: "STATUS",
        field: "active",
        sortable: true,
        width: "100px",
      },
      {
        name: "Actions",
        type: "ACTIONS",
        field: ["edit", "delete"],
        width: "150px",
      },
    ];
    return (
      <Fragment>
        <Formik
          initialValues={this.state.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={this.onSubmit}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              getFieldProps,
              setFieldValue,
            } = props;

            return (
              <Modal
                isOpen={this.props.openModal}
                toggle={this.props.toggle}
                style={{ width: "1100px" }}
              >
                <ModalHeader toggle={this.props.toggle}>
                  Set Reminder
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <ReactCSSTransitionGroup
                      component="div"
                      transitionName="TabsAnimation"
                      transitionAppear={true}
                      transitionAppearTimeout={0}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <Row>
                        <Col sm={3}>
                          <FormGroup>
                            <Label htmlFor="color">Consultant</Label>
                            <CustomInput
                              type="select"
                              bsSize="sm"
                              invalid={errors.consultantId ? true : false}
                              name="consultantId"
                              {...getFieldProps("consultantId")}
                            >
                              <option value="">Select</option>
                              {this.props.consultants.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.firstname +
                                    " " +
                                    (item.lastname ? item.lastname : "")}
                                </option>
                              ))}
                            </CustomInput>
                            {errors.consultantId && touched.consultantId && (
                              <FormFeedback>
                                {errors.consultantId}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          {" "}
                          <FormGroup>
                            <Label for="title">Reminder Title</Label>
                            <Input
                              type="text"
                              name="title"
                              bsSize="sm"
                              placeholder="Reminder title"
                              {...getFieldProps("title")}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label for="reminderDate">Date</Label>
                            <InputGroup>
                              <Input
                                type="date"
                                name="reminderDate"
                                bsSize="sm"
                                {...getFieldProps("reminderDate")}
                              />
                            </InputGroup>
                            {errors.reminderDate && touched.reminderDate && (
                              <FormFeedback>
                                {errors.reminderDate}
                              </FormFeedback>
                            )}
                          </FormGroup>{" "}
                        </Col>
                        <Col sm="4">
                          <FormGroup row>
                            <Col sm={6}>
                              <Col sm={12}>
                                <Label for="duration">Duration</Label>
                              </Col>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="duration"
                                        value="All Day"
                                        checked={
                                          values.duration === "All Day"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                      />
                                      All Day
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        value="Custom"
                                        name="duration"
                                        checked={
                                          values.duration === "Custom"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                      />
                                      Custom
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="alertTime">Alert Time </Label>

                                <DatePicker
                                  name="alertTime"
                                  disabled={values.duration !== "Custom"}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  onChange={(dateString) => {
                                    setFieldValue("alertTime", dateString ? moment(dateString) : null);
                                  }}
                                  onSelect={(dateString) =>
                                    setFieldValue("alertTime", dateString ? moment(dateString) : null)
                                  }
                                  className="form-control"
                                  value={
                                    values.alertTime && values.alertTime !== ""
                                      ? moment(values.alertTime).format(
                                          "hh:mm a"
                                        )
                                      : new Date()
                                  }
                                  dateFormat="h:mm A"
                                />

                                {errors.alertTime && touched.alertTime && (
                                  <FormFeedback>
                                    {errors.alertTime}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="2" className="offset-sm-10">
                          <Button
                            style={{ marginTop: 30 }}
                            color="primary"
                            type="submit"
                            className="btn btn-block"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faSave} size="1x" /> &nbsp;
                            Save
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <CommonTable
                        columnHeader={columnHeader}
                        tableData={tableData}
                        handleDateSearch={this.onDateWiseSearch}
                        handleEdit={this.onEditClicked}
                        handleDelete={this.onDeleteClicked}
                        handleTextSearch={this.onTextSearch}
                        handleStatus={this.onActiveChanged}
                        subHeader
                        search
                        dateWiseSearch
                        minHeight="600"
                      />
                    </ReactCSSTransitionGroup>
                  </Form>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </Modal>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, customers, appointments } = state;
  return {
    consultants: consultants.consultants,
    transaction: customers.currentTransaction,
    appointment: appointments.selected_appointment,
  };
}

const connectedReminder = connect(mapStateToProps)(Reminder);
export default connectedReminder;
