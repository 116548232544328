export const initialAssesmentMaster={

        GET_ALL_INITIALASSESMENTMASTER_REQEST: "GET_ALL_INITIALASSESMENTMASTER_REQUEST",
        GET_ALL_INITIALASSESMENTMASTER_SUCCESS: "GET_ALL_INITIALASSESMENTMASTER_SUCCESS",
        GET_ALL_INITIALASSESMENTMASTER_FAILURE: "GET_ALL_INITIALASSESMENTMASTER_FAILRE",


        ADD_INITIALASSESMENTMASTER_REQUEST : "ADD_INITIALASSESMENTMASTER_REQUEST",
        ADD_INITIALASSESMENTMASTER_SUCCESS : "ADD_INITIALASSESMENTMASTER_SUCESS",
        ADD_INITIALASSESMENTMASTER_FAILURE : "ADD_INITIALASSESMENTMASTER_FAILURE",

        DELETE_INITIALASSESMENT_REQUEST : "DELETE_INITIALASSESMENT_REQUEST",
        DELETE_INITIALASSESMENT_SUCCESS : "DELETE_INITIALASSESMENT_SUCCESS",
        DELETE_INITIALASSESMENT_FAILURE : "DELETE_INITIALASSESMENT_FAILURE",


        ADD_IVF_REQUEST: "ADD_IVF_REQUEST",
        ADD_IVF_SUCCESS : "ADD_IVF_SUCCESS",
        ADD_IVF_FAILURE: "ADD_IVF_FAILURE",

        GET_IVF_REQUEST: "GET_IVF_REQUEST",
        GET_IVF_SUCCESS : "GET_IVF_SUCCESS",
        GET_IVF_FAILURE: "GET_IVF_FAILURE",

        ADD_IVF_FORM_REQUEST: "ADD_IVF_FORM_REQUEST",
        ADD_IVF_FORM_SUCCESS : "ADD_IVF_FORM_SUCCESS",
        ADD_IVF_FORM_FAILURE: "ADD_IVF_FORM_FAILURE",

        GET_FORM_REQUEST  : "GET_FORM_REQUEST",
        GET_FORM_SUCCESS : "GET_FORM_SUCCESS",
        GET_FORM_FAILURE : "GET_FORM_FAILURE",

        UPDATE_FORM_REQUEST  : "UPDATE_FORM_REQUEST",
        UPDATE_FORM_SUCCESS : "UPDATE_FORM_SUCCESS",
        UPDATE_FORM_FAILURE : "UPDATE_FORM_FAILURE",

        DELETE_FORM_REQUEST  : "DELETE_FORM_REQUEST",
        DELETE_FORM_SUCCESS : "DELETE_FORM_SUCCESS",
        DELETE_FORM_FAILURE : "DELETE_FORM_FAILURE",

        
        DELETE_IVF_REQUEST: "DELETE_IVF_REQUEST",
        DELETE_IVF_SUCCESS : "DELETE_IVF_SUCCESS",
        DELETE_IVF_FAILURE: "DELETE_IVF_FAILURE",

        GET_LABORATORY_DATA_BY_UHID_REQUEST : "GET_LABORATORY_DATA_BY_UHID_REQUEST",
        GET_LABORATORY_DATA_BY_UHID_SUCCESS : "GET_LABORATORY_DATA_BY_UHID_SUCCESS",
        GET_LABORATORY_DATA_BY_UHID_FAILURE : "GET_LABORATORY_DATA_BY_UHID_FAILURE",

        GET_RADIOLOGY_DATA_BY_UHID_REQUEST : "GET_RADIOLOGY_DATA_BY_UHID_REQUEST",
        GET_RADIOLOGY_DATA_BY_UHID_SUCCESS : "GET_RADIOLOGY_DATA_BY_UHID_SUCCESS",
        GET_RADIOLOGY_DATA_BY_UHID_FAILURE : "GET_RADIOLOGY_DATA_BY_UHID_FAILURE",

        GET_LATEST_INITIALASSESMENT_REQUEST : "GET_LATEST_INITIALASSESMENT_REQUEST",
        GET_LATEST_INITIALASSESMENT_SUCCESS : "GET_LATEST_INITIALASSESMENT_SUCCESS",
        GET_LATEST_INITIALASSESMENT_FAILURE : "GET_LATEST_INITIALASSESMENT_FAILURE"       

};