import { pharmacyConstants } from '../constants';

export function pharmacies(state = {master_pharmacy:null,pharmacies:[]}, action) {

  switch (action.type) {              

      
            case pharmacyConstants.GET_MASTER_PHARMACY:
            return {master_pharmacy:action.pharmacy};

            case pharmacyConstants.GET_ALL_PHARMACIES:
              return {...state,pharmacies:action.pharmacies}
      
         
    default:
      return state
  }
}