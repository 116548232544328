
    export const patientConstants={

       ADD_PATIENT_REQUEST:'ADD_PATIENT_REQUEST',
       ADD_PATIENT_SUCCESS:'ADD_PATIENT_SUCCESS',
       ADD_PATIENT_FAILURE:'ADD_PATIENT_FAILURE' ,

       GET_ALL_PATIENT_REQUEST:'GET_ALL_PATIENT_REQUEST',
       GET_ALL_PATIENT_SUCCESS:'GET_ALL_PATIENT_SUCCESS',
       GET_ALL_PATIENT_FAILURE:'GET_ALL_PATIENT_FAILURE' ,

       GET_BY_DATE_PATIENTS_REQUEST:'GET_BY_DATE_PATIENTS_REQUEST',
       GET_BY_DATE_PATIENTS_SUCCESS:'GET_BY_DATE_PATIENTS_SUCCESS',
       GET_BY_DATE_PATIENTS_FAILURE:'GET_BY_DATE_PATIENTS_FAILURE' ,

       UPDATE_PATIENT_REQUEST:'UPDATE_PATIENT_REQUEST',
       UPDATE_PATIENT_SUCCESS:'UPDATE_PATIENT_SUCCESS',
       UPDATE_PATIENT_FAILURE:'UPDATE_PATIENT_FAILURE' ,

       DELETE_PATIENT_REQUEST:'DELETE_PATIENT_REQUEST',
       DELETE_PATIENT_SUCCESS:'DELETE_PATIENT_SUCCESS',
       DELETE_PATIENT_FAILURE:'DELETE_PATIENT_FAILURE' ,

       SELECT_PATIENT_REQUEST:'SELECT_PATIENT_REQUEST',
       SELECT_PATIENT_SUCCESS:'SELECT_PATIENT_SUCCESS',
       SELECT_PATIENT_FAILURE:'SELECT_PATIENT_FAILURE' ,

       SELECT_PATIENT:'SELECT_PATIENT',

       SEARCH_PATIENT_REQUEST:'SEARCH_PATIENT_REQUEST',
       SEARCH_PATIENT_SUCCESS:'SEARCH_PATIENT_SUCCESS',
       SEARCH_PATIENT_FAILURE:'SEARCH_PATIENT_FAILURE' ,

       GET_BY_UHID_REQUEST:'GET_BY_UHID_REQUEST',
       GET_BY_UHID_SUCCESS:'GET_BY_UHID_SUCCESS',
       GET_BY_UHID_FAILURE:'GET_BY_UHID_FAILURE' ,

       SEARCH_PATIENTS_REQUEST : "SEARCH_PATIENTS_REQUEST",
       SEARCH_PATIENTS_SUCCESS : "SEARCH_PATIENTS_SUCCESS",
       SEARCH_PATIENTS_FAILURE : "SEARCH_PATIENTS_FAILURE",

       PATIENT_CLEAR:'PATIENT_CLEAR'

    
    }