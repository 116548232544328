import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component, Suspense, lazy, Fragment } from "react";
import ScrollToTop from "../../DemoPages/MyComponents/ScrollToTop";

import { connect } from "react-redux";
import $ from "jquery";
import { get_route } from "../../helpers/menu.helper";
import { get_menus } from "../../menu";
import "./icons.css";
import { customerActions, menuActions } from "../../actions";
import ConsultantHome from "../../DemoPages/ConsultantHome";
import PatientHome from "../../DemoPages/PatientHome";
import { storageService } from "../../services/storage.service";
import {
  customerConstants,
  pharmacyConstants,
  storeConstants,
} from "../../constants";
import { setSideBarHidden } from "../../reducers/ThemeOptions";
import { pharmacyService } from "../../services";
import { storeService } from "../../services/stores";
import { roleActions } from "../../actions";
import SuspenseLoader from "../../DemoPages/MyComponents/SuspenseLoader";
const GeneralHome = React.lazy(() => import("../../DemoPages/GeneralHome"));

class AppMainComp extends Component {
  state = {
    routes: [],
    accessMenus: [],
  };

  componentDidMount() {
    let usertoken = storageService.getData("authorization");
    if (usertoken) {
      var base64Url = usertoken.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var token = JSON.parse(jsonPayload);

      if (token) {
        const { dispatch } = this.props;
        if (false) {
          if (token.login_type === "consultant") {
            dispatch(setSideBarHidden(true));
          } else dispatch(setSideBarHidden(false));

          dispatch({
            type: customerConstants.SET_TRANSACTION_DETAIL,
            transaction: { ...token },
          });

          dispatch(customerActions.getCustomerById(token.customer_location_id));
          pharmacyService.getMaster().then((res) => {
            if (res.success) {
              dispatch({
                type: pharmacyConstants.GET_MASTER_PHARMACY,
                pharmacy: res.pharmacy,
              });
            }
          });

          storeService.getMaster().then((res) => {
            if (res.success) {
              dispatch({
                type: storeConstants.GET_MASTER_STORE,
                store: res.store,
              });
            }
          });
        }
        // dispatch(roleActions.getRoleData(token.role));
        dispatch(menuActions.getRoleBasedMenu(token.role));
      }
    }
  }

  componentDidUpdate(np, ns) {
    if (this.props.menus.menu.length !== np.menus.menu.length) {
      // const menus = np.menus.menu;
      // let parents = menus.filter((menu) => menu.parent == null);
      // $("icons_style").empty();
      // let navData = get_menus(menus, parents);
      //let navData =
      let navData = get_menus(this.props.menus.menu);
      let routes = get_route(navData);
      console.log(routes);
      this.setState({ routes });
    }
  }

  render() {
    return (
      <Fragment>
        <ScrollToTop>
          <React.Suspense fallback={<SuspenseLoader />}>
            <Switch>
              <Route path="/consultantHome" component={ConsultantHome}></Route>
              <Route path="/patientHome" component={PatientHome} />
              {this.state.routes}
              <Route exact path="/" component={GeneralHome}></Route>
            </Switch>
          </React.Suspense>
        </ScrollToTop>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { customers, menu, newMaster } = state;
  return {
    transaction: customers.currentTransaction,
    customer: customers.selected_customer,
    menus: menu,
    newMaster: newMaster,
  };
}

const AppMain = connect(mapStateToProps)(AppMainComp);

export default AppMain;
