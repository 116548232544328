
    export const consultantConstants={

       ADD_CONSULTANT_REQUEST:'ADD_CONSULTANT_REQUEST',
       ADD_CONSULTANT_SUCCESS:'ADD_CONSULTANT_SUCCESS',
       ADD_CONSULTANT_FAILURE:'ADD_CONSULTANT_FAILURE' ,

       GET_ALL_CONSULTANT_REQUEST:'GET_ALL_CONSULTANT_REQUEST',
       GET_ALL_CONSULTANT_SUCCESS:'GET_ALL_CONSULTANT_SUCCESS',
       GET_ALL_CONSULTANT_FAILURE:'GET_ALL_CONSULTANT_FAILURE' ,

       SEARCH_ALL_CONSULTANT_REQUEST:'SEARCH_ALL_CONSULTANT_REQUEST',
       SEARCH_ALL_CONSULTANT_SUCCESS:'SEARCH_ALL_CONSULTANT_SUCCESS',
       SEARCH_ALL_CONSULTANT_FAILURE:'SEARCH_ALL_CONSULTANT_FAILURE' ,

       UPDATE_CONSULTANT_REQUEST:'UPDATE_CONSULTANT_REQUEST',
       UPDATE_CONSULTANT_SUCCESS:'UPDATE_CONSULTANT_SUCCESS',
       UPDATE_CONSULTANT_FAILURE:'UPDATE_CONSULTANT_FAILURE' ,

       DELETE_CONSULTANT_REQUEST:'DELETE_CONSULTANT_REQUEST',
       DELETE_CONSULTANT_SUCCESS:'DELETE_CONSULTANT_SUCCESS',
       DELETE_CONSULTANT_FAILURE:'DELETE_CONSULTANT_FAILURE' ,

       SELECT_CONSULTANT_REQUEST:'SELECT_CONSULTANT_REQUEST',
       SELECT_CONSULTANT_SUCCESS:'SELECT_CONSULTANT_SUCCESS',
       SELECT_CONSULTANT_FAILURE:'SELECT_CONSULTANT_FAILURE' ,

       SET_DEFAULT_CONSULTANT_REQUEST:'SET_DEFAULT_CONSULTANT_REQUEST',
       SET_DEFAULT_CONSULTANT_SUCCESS:'SET_DEFAULT_CONSULTANT_SUCCESS',
       SET_DEFAULT_CONSULTANT_FAILURE:'SET_DEFAULT_CONSULTANT_FAILURE', 

       CONSULTANT_CLEAR:'CONSULTANT_CLEAR'

    
    }