import React, { Component } from "react";
import { Table } from "reactstrap";

import { connect } from "react-redux";

class ConsultantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_consultants: [],
    };
  }
  componentDidMount() {
    const { consultant_list } = this.props;
    this.setState({ selected_consultants: consultant_list });
  }

  componentDidUpdate(pp, ps) {
    const { consultant_list } = this.props;
    if (pp.consultant_list)
      if (pp.consultant_list.length !== ps.selected_consultants.length) {
        this.setState({ selected_consultants: consultant_list });
      }
  }

  consultantToName(code) {
    const { consultants } = this.props;
    if (consultants) {
      const exist = consultants.find((item) => item.code === code);
      if (exist) {
        let result =
          this.codeToValue(exist.title, "titles") +
          " " +
          exist.firstname +
          " " +
          (exist.lastname ? exist.lastname : "");
        return result;
      }
    }
    return "";
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const { selected_consultants } = this.state;
    return (
      <Table size="sm" className="ot-booking-table">
        <thead>
          <th style={{ width: "60px" }}>Sno.</th>
          <th>Name</th>
        </thead>
        <tbody>
          {selected_consultants &&
            selected_consultants.map((user, i) => (
              <tr>
                <td>{i + 1}</td>
                <td> {this.consultantToName(user.consultant_id)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}
function mapStateToProps(state) {
  const { consultants, registrationMaster } = state;

  return {
    consultants: consultants.consultants,
    masters: registrationMaster.data,
  };
}

const connectedConsultantList = connect(mapStateToProps)(ConsultantList);
export default connectedConsultantList;
