import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { departmentService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const departmentActions = {
  getAll,
  setSelected,
  add,
  update,
  active,
  remove,
  addSubdepartment,
  updateSubdepartment,
  removeSubdepartment,
};

function getAll(viewDeleted = true) {
  return (dispatch) => {
    dispatch(request());
    departmentService.getAll(viewDeleted).then(
      (data) => {
        if (data.isSuccess) {
          dispatch(success(data.value));


          dispatch(alertActions.success("Departments loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_DEPARTMENT_REQUEST };
  }
  function success(data) {
    return { type: generalMasterConstants.GET_DEPARTMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_DEPARTMENT_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(data));
        } else {
          // toast("Invalid data", {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 1000,
          //   position: "bottom-center",
          //   type: "warning",
          // });
          dispatch(failure("Error in saving data"));
        }
      },
      (error) => {
        // toast(error, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 1000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_DEPARTMENT_REQUEST };
  }
  function success(department) {
    return { type: generalMasterConstants.ADD_DEPARTMENT_SUCCESS, department };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_DEPARTMENT_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => {
        // toast(error, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 1000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_REQUEST };
  }
  function success(department) {
    return {
      type: generalMasterConstants.UPDATE_DEPARTMENT_SUCCESS,
      department,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_FAILURE, error };
  }
}
function active(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.active(payload).then(
      (data) => {
        if (data.isSuccess) {
          dispatch(success(data.value));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(data.message));
        } else {
          dispatch(failure(data.err));
          toast(data.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_REQUEST };
  }
  function success(department) {
    return {
      type: generalMasterConstants.UPDATE_DEPARTMENT_SUCCESS,
      department,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.remove(payload).then(
      (data) => {
        if (data.isSuccess) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
          toast("success", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(data.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_REQUEST };
  }
  function success(department) {
    return {
      type: generalMasterConstants.UPDATE_DEPARTMENT_SUCCESS,
      department,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_DEPARTMENT_FAILURE, error };
  }
}

function setSelected(department) {
  return (dispatch) => {
    dispatch(request());
    departmentService.getAllSubdepartments(department.id).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
        } else dispatch(failure(data.err));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: generalMasterConstants.SET_SELECTED_DEPARTMENT_REQUEST };
  }
  function success(subdepartment) {
    return {
      type: generalMasterConstants.SET_SELECTED_DEPARTMENT_SUCCESS,
      data: { subdepartment, department },
    };
  }
  function failure(error) {
    return {
      type: generalMasterConstants.SET_SELECTED_DEPARTMENT_FAILURE,
      error,
    };
  }
}

function addSubdepartment(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.addSubdepartment(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_SUBDEPARTMENT_REQUEST };
  }
  function success(subdepartment) {
    return {
      type: generalMasterConstants.ADD_SUBDEPARTMENT_SUCCESS,
      subdepartment,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_SUBDEPARTMENT_FAILURE, error };
  }
}

function updateSubdepartment(payload) {
  return (dispatch) => {
    dispatch(request());
    departmentService.updateSubdepartment(payload).then(
      (data) => {
        if (data) {
          dispatch(success(payload));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_SUBDEPARTMENT_REQUEST };
  }
  function success(subdepartment) {
    return {
      type: generalMasterConstants.UPDATE_SUBDEPARTMENT_SUCCESS,
      subdepartment,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_SUBDEPARTMENT_FAILURE, error };
  }
}

function removeSubdepartment(payload, index) {
  return (dispatch) => {
    dispatch(request());
    departmentService.removeSubdepartment(payload).then(
      (data) => {

        if (data.isSuccess) {
          dispatch(success(payload, index));
          
          // dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_SUBDEPARTMENT_REQUEST };
  }
  function success(subdepartment) {
    return {
      type: generalMasterConstants.DELETE_SUBDEPARTMENT_SUCCESS,
      subdepartment,
      index
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_SUBDEPARTMENT_FAILURE, error };
  }
}

// function getAllSubdepartments() {
//   return (dispatch) => {
//     dispatch(request());
//     departmentService.getAllSubdepartments().then(
//       (data) => {
//         if (data) {
//           dispatch(success(data));
//           dispatch(alertActions.success("Departments loaded"));
//         } else dispatch(failure(data.err));
//       },
//       (error) => dispatch(failure(error.toString()))
//     );
//   };

//   function request() {
//     return { type: generalMasterConstants.GET_DEPARTMENT_REQUEST };
//   }
//   function success(data) {
//     return { type: generalMasterConstants.GET_DEPARTMENT_SUCCESS, data };
//   }
//   function failure(error) {
//     return { type: generalMasterConstants.GET_DEPARTMENT_FAILURE, error };
//   }
// }
