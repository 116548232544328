import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const registerservice = {
    register,
};

function register(data) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: helperService.getHeaderData(),
      },
      body: JSON.stringify(data),
    };
  
    return fetch(`${apiUrl}/signup`, requestOptions).then(
      helperService.handleResponse
    );
}