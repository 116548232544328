import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { specialityService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const specialityActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    specialityService.getAll().then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success("Speciality loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_SPECIALITY_REQUEST };
  }
  function success(specialities) {
    return {
      type: generalMasterConstants.GET_SPECIALITY_SUCCESS,
      specialities,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_SPECIALITY_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    specialityService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_SPECIALITY_REQUEST };
  }
  function success(speciality) {
    return { type: generalMasterConstants.ADD_SPECIALITY_SUCCESS, speciality };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_SPECIALITY_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    specialityService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          dispatch(alertActions.success(data));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_SPECIALITY_REQUEST };
  }
  function success(speciality) {
    return {
      type: generalMasterConstants.UPDATE_SPECIALITY_SUCCESS,
      speciality,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_SPECIALITY_FAILURE, error };
  }
}

function remove(payload, speciality) {
  return (dispatch) => {
    dispatch(request());
    specialityService.remove(payload).then(
      (data) => {
        if (data) {
          dispatch(success(speciality));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_SPECIALITY_REQUEST };
  }
  function success(speciality) {
    return {
      type: generalMasterConstants.DELETE_SPECIALITY_SUCCESS,
      speciality,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.DELETE_SPECIALITY_FAILURE, error };
  }
}
