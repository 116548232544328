import React, { Component } from "react";
import { userMasterService, theatreService } from "../../../services";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Table,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSearch,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

class StaffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selected_users: [],
    };
  }
  componentDidMount() {
    userMasterService
      .getUsersGeneralData()
      .then((data) => {
        if (data.success) {
          this.setState({ users: data.data });
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
    const { staff_list } = this.props;
    this.setState({ selected_users: staff_list });
  }

  componentDidUpdate(pp, ps) {
    const { staff_list } = this.props;
    if (staff_list)
      if (pp.staff_list.length !== ps.selected_users.length) {
        this.setState({ selected_users: staff_list });
      }
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  userCodeToName(code) {
    const { users } = this.state;
    const exist = users && users.find((item) => item._id === code);
    if (exist) {
      let name =
        this.codeToValue(exist.emplyoee_name.title, "titles") +
        " " +
        exist.emplyoee_name.first_name +
        " " +
        exist.emplyoee_name.last_name;
      let desg = exist.designation.designation_name;
      return { name: name, designation: desg };
    }
    return null;
  }
  render() {
    return (
      <Table size="sm" className="ot-booking-table">
        <thead>
          <th>Sno.</th>
          <th>Name</th>
          <th>Designation</th>
        </thead>
        <tbody>
          {this.state.selected_users.map((user, i) => {
            let conv_data = this.userCodeToName(user.staff_id);
            return (
              <tr>
                <td>{i + 1}</td>
                <td> {conv_data && conv_data.name}</td>
                <td>{conv_data && conv_data.designation}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  const { registrationMaster } = state;

  return {
    masters: registrationMaster.data,
  };
}

const connectedStaffList = connect(mapStateToProps)(StaffList);
export default connectedStaffList;
