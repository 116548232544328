import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Button } from "reactstrap";
import {
  faPrint,
  faSearch,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icons } from "../../../helpers/IconHelper";

class PageTitle extends Component {
  randomize(myArray) {
    return myArray[Math.floor(Math.random() * myArray.length)];
  }

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,

      heading,
      icon,
      subheading,
    } = this.props;

    // var arr = [<TitleComponent1 />, <TitleComponent2 />, <TitleComponent3 />];

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div
              className={cx("page-title-icon", {
                "d-none": !enablePageTitleIcon,
              })}
            >
              {/* <i className={icon} /> */}
              <img
                width="40px"
                height="40px"
                src={Icons.TaxLogo}
                alt="Tax"
              />
            </div>
            <div>
              {/* IPD Patient List */}
              IPD Patient Admission List
              {/* <div
                className={cx("page-title-subheading", {
                  "d-none": !enablePageTitleSubheading
                })}
              >
                {subheading}
              </div> */}
            </div>
          </div>

          {/* <div className="page-title-actions">
            <Button
              style={{
                backgroundColor: "#4da8da",
                margin: "10px"
              }}
            >
              <FontAwesomeIcon icon={faPrint} size="2x" />
              <span style={{ margin: "10px" }}>Print List</span>
            </Button>
            <Button
              style={{
                backgroundColor: "#4da8da",
                margin: "10px"
              }}
            >
              <FontAwesomeIcon icon={faPrint} size="2x" />
              <span style={{ margin: "10px" }}> Export to CSV</span>
            </Button>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
