import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap";
import { customerActions } from "../../../actions";
import { patientActions } from "../../../actions/patient.actions";
import { apiUrl } from "../../../constants/config";
import { agreementService, referralService } from "../../../services";
import "../../index.css";
import PatientInfo from "./PatientInfo";
import ConsultantList from "./ConsultantList";
import StaffList from "./StaffList";
import OTCharges from "./OTCharges";
import { Icons } from "../../../helpers/IconHelper";

class PrintOTBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consentText: "",
      referrals: [],
    };
  }

  componentDidMount() {
    const { dispatch, selected_patient, transaction, customer } = this.props;

    if (selected_patient) {
      referralService.getAll().then((res) => {
        if (res.success) {
          this.setState({ referrals: res.referrals });
        }
      });
    }
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  codeToService(code) {
    const { packageData } = this.props;
    let serviceOption = null;
    if (packageData) {
      serviceOption = packageData.services.find((item) => code === item.CODE);
    }
    return serviceOption ? serviceOption.SERVICE_NAME : code;
  }
  render() {
    const { selected_booking, customer } = this.props;

    return (
      <Fragment>
        <Modal
          isOpen={this.props.openModal}
          toggle={this.props.toggle}
          className={this.props.className}
          style={{ width: "830px" }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Patient OT Booking
          </ModalHeader>
          <ModalBody>
            <div style={{ textAlign: "left", marginTop: "-8px" }}>
              <ReactToPrint
                trigger={() => (
                  <Button className="mb-2 mr-2 btn-icon" color="success">
                    <i className="pe-7s-user btn-icon-wrapper"> </i>
                    Print OT Booking Form
                  </Button>
                )}
                content={() => this.componentRef}
              />
            </div>
            <div className="report-page" ref={(el) => (this.componentRef = el)}>
              {customer && selected_booking ? (
                <div name="individualReport">
                  <div
                    className="border"
                    style={{ padding: "10px 10px", paddingBottom: 0 }}
                  >
                    <div style={{ width: "15%", float: "left" }}>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          float: "left",
                        }}
                      >
                        <img
                          style={{ width: "100%", maxHeight: "90px" }}
                          src={
                            apiUrl +
                            (customer.logo_url
                              ? customer.logo_url
                              : Icons.Hospital)
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        float: "left",
                        width: "70%",
                        paddingTop: "10px",
                      }}
                    >
                      <h4>{customer.customer_Tran_Name} </h4>
                      <p>{customer.customer_Tran_Address}</p>
                    </div>
                    <div style={{ width: "15%", float: "right" }}>
                      <div
                        style={{ width: "90px", height: "90px", float: "left" }}
                      >
                        {selected_booking.patient_id &&
                          selected_booking.patient_id.qrcode && (
                            <img
                              style={{ width: "100%" }}
                              src={
                                apiUrl +
                                "/" +
                                selected_booking.patient_id.qrcode
                              }
                            />
                          )}
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="line"></div>
                  <div className="padd-20 border">
                    <Row>
                      <Col sm="6">
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Patient Info
                        </h6>
                        <PatientInfo
                          patient={selected_booking.patient_id}
                          masters={this.props.masters}
                        />
                      </Col>
                      <Col sm="6">
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          OT Booking Info
                        </h6>
                        <Table className="ot-booking-table">
                          <tr>
                            <td className="booking-label">Service Type</td>
                            <td>{selected_booking.service_type}</td>
                          </tr>
                          <tr>
                            <td className="booking-label">Procedure</td>
                            <td>
                              {this.codeToService(
                                selected_booking.planned_procedure
                              )}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Consultant</td>
                            <td>
                              {selected_booking.consultant_id.firstname}{" "}
                              {selected_booking.consultant_id.lastname}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Procedure Notes</td>
                            <td>{selected_booking.procedure_notes}</td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Operation Date </td>
                            <td>
                              {moment(selected_booking.operation_date).format(
                                "DD/MM/YYYY"
                              )}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">OT ID </td>
                            <td>{selected_booking.ot_id}</td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Time slot from </td>
                            <td>
                              {moment(selected_booking.time_slot_from).format(
                                "HH:mm:ss"
                              )}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Time slot to </td>
                            <td>
                              {moment(selected_booking.time_slot_to).format(
                                "HH:mm:ss"
                              )}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td className="booking-label">Slot Duration </td>
                            <td>{selected_booking.slot_duration}</td>{" "}
                          </tr>
                        </Table>
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Consultant list
                        </h6>
                        <ConsultantList
                          consultant_list={selected_booking.consultant_list}
                        />
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Staff list
                        </h6>
                        <StaffList staff_list={selected_booking.staff_list} />

                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          OT Charges
                        </h6>
                        <OTCharges charges_list={selected_booking.ot_charges} />
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <>No Data Found!</>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.props.toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { bookings, customers, registrationMaster, serviceMaster } = state;

  return {
    selected_booking: bookings.selected_booking,
    transaction: customers.currentTransaction,
    customer: customers.selected_customer,
    masters: registrationMaster.data,
    packageData: serviceMaster.data,
  };
}

const connectedPrintOTBooking = connect(mapStateToProps)(PrintOTBooking);

export default connectedPrintOTBooking;
