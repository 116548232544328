export const registrationWithFeesConstants = {
  ADD_REGISTRATION_FEES_REQUEST: "ADD_REGISTRATION_FEES_REQUEST",
  ADD_REGISTRATION_FEES_SUCCESS: "ADD_REGISTRATION_FEES_SUCCESS",
  ADD_REGISTRATION_FEES_FAILURE: "ADD_REGISTRATION_FEES_FAILURE",

  GET_REGISTRATION_FEES_REQUEST: "GET_REGISTRATION_FEES_REQUEST",
  GET_REGISTRATION_FEES_SUCCESS: "GET_REGISTRATION_FEES_SUCCESS",
  GET_REGISTRATION_FEES_FAILURE: "GET_REGISTRATION_FEES_FAILURE",

  DELETE_REGISTRATION_FEES_REQUEST: "DELETE_REGISTRATION_FEES_REQUEST",
  DELETE_REGISTRATION_FEES_SUCCESS: "DELETE_REGISTRATION_FEES_SUCCESS",
  DELETE_REGISTRATION_FEES_FAILURE: "DELETE_REGISTRATION_FEES_FAILURE",

  DEACTIVE_REGISTRATION_FEES_REQUEST: "DEACTIVE_REGISTRATION_FEES_REQUEST",
  DEACTIVE_REGISTRATION_FEES_SUCCESS: "DEACTIVE_REGISTRATION_FEES_SUCCESS",
  DEACTIVE_REGISTRATION_FEES_FAILURE: "DEACTIVE_REGISTRATION_FEES_FAILURE",

  UPDATE_REGISTRATION_FEES_REQUEST: "UPDATE_REGISTRATION_FEES_REQUEST",
  UPDATE_REGISTRATION_FEES_SUCCESS: "UPDATE_REGISTRATION_FEES_SUCCESS",
  UPDATE_REGISTRATION_FEES_FAILURE: "UPDATE_REGISTRATION_FEES_FAILURE",

  ADD_REGISTRATION_CONS_FEES_REQUEST: "ADD_REGISTRATION_CONS_FEES_REQUEST",
  ADD_REGISTRATION_CONS_FEES_SUCCESS: "ADD_REGISTRATION_CONS_FEES_SUCCESS",
  ADD_REGISTRATION_CONS_FEES_FAILURE: "ADD_REGISTRATION_CONS_FEES_FAILURE",

  GET_REGISTRATION_CONS_FEES_REQUEST: "GET_REGISTRATION_CONS_FEES_REQUEST",
  GET_REGISTRATION_CONS_FEES_SUCCESS: "GET_REGISTRATION_CONS_FEES_SUCCESS",
  GET_REGISTRATION_CONS_FEES_FAILURE: "GET_REGISTRATION_CONS_FEES_FAILURE",

  DELETE_REGISTRATION_CONS_FEES_REQUEST:
    "DELETE_REGISTRATION_CONS_FEES_REQUEST",
  DELETE_REGISTRATION_CONS_FEES_SUCCESS:
    "DELETE_REGISTRATION_CONS_FEES_SUCCESS",
  DELETE_REGISTRATION_CONS_FEES_FAILURE:
    "DELETE_REGISTRATION_CONS_FEES_FAILURE",

  UPDATE_REGISTRATION_CONS_FEES_REQUEST:
    "UPDATE_REGISTRATION_CONS_FEES_REQUEST",
  UPDATE_REGISTRATION_CONS_FEES_SUCCESS:
    "UPDATE_REGISTRATION_CONS_FEES_SUCCESS",
  UPDATE_REGISTRATION_CONS_FEES_FAILURE:
    "UPDATE_REGISTRATION_CONS_FEES_FAILURE",

  GET_CONS_BY_WARD_CATAGORY_REQUEST: "GET_CONS_BY_WARD_CATAGORY_REQUEST",
  GET_CONS_BY_WARD_CATAGORY_SUCCESS: "GET_CONS_BY_WARD_CATAGORY_SUCCESS",
  GET_CONS_BY_WARD_CATAGORY_FAILURE: "GET_CONS_BY_WARD_CATAGORY_FAILURE",
};
