import {
  faCalendarAlt,
  faPrint,
  faFile,
  faSmile,
  faTrash,
  faEllipsisV,
  faWindowClose,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Children, Fragment } from "react";
// import BigCalendar from 'react-big-calendar';
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import {
  Button,
  Col,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Row,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  appointmentActions,
  consultantActions,
  registrationMasterActions,
  sweetAlertActions,
  otBookingActions,
  serviceMasterActions,
} from "../../../actions";
import {Icons} from "../../../helpers/IconHelper";
import { apiUrl } from "../../../constants/config";
import { consultantService } from "../../../services";
import AppointmentModal from "../../Modals/AppointmentModal";
import BlockCalendar from "../../Modals/BlockCalendar";
import DeleteAppointment from "../../Modals/DeleteAppointment";
import ReminderModal from "../../Modals/Reminder";
import OTBookingModal from "../../Modals/OTBookingModal";
import Graph from "../Graph";
import "../index.css";
// import TodaySchedule from "../TodaySchedule";
import TodayOTBookings from "../TodayOTBookings";
import TodaySchedule from "../../AppointmentMaster/TodaySchedule";
import PrintPreview from "../../AppointmentMaster/PrintPreview";
import AppointmentPopup from "../../AppointmentMaster/AppointmentPopup";
import { appointmentService } from "../../../services/appointment.service";
import DoctorList from "../../AppointmentMaster/ConsultantList";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// const localizer = BigCalendar.momentLocalizer(moment)
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CURRENT_DATE = moment().toDate();

class ViewMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultantAppointments: [],
      events: [],
      showAppointmentModal: false,
      showReminderModal: false,
      showBlockCalendarModal: false,
      selectedAppoinment: null,
      deleteAppointmentModal: false,
      selectedBooking: null,
      showBookingModal: false,
      mode: "NEW",
      consultants: [],
      totalAppointments: 0,
      consultant: null,
      earnings: null,
      showPrintModal: false,
    };
  }

  onEventResize = (data) => {
    const { dispatch } = this.props;
    dispatch(
      sweetAlertActions.show("Do you want to update this ", {
        title: "Are you sure?",
        confirmButtonColor: "Do you want to update this appointment",
        text: "",
        showCancelButton: true,
        onConfirm: () => {
          const { start, end } = data;
          const events = this.state.events;
          const newevents = events.map((ev) => {
            if (ev.id === data.event.id) {
              ev.start = start;
              ev.end = end;
            }
            return ev;
          });

          this.setState({ events: newevents });
          dispatch(sweetAlertActions.hide());
        },
        onCancel: () => dispatch(sweetAlertActions.hide()),
      })
    );
  };

  onEventDrop = (data) => {
    const { start, end } = data;
    var startDate = new Date(start);
    var todayDate = new Date();
    if (startDate >= todayDate) {
      startDate.setHours(0, 0, 0, 0);
      todayDate.setHours(0, 0, 0, 0);
      const { dispatch } = this.props;
      dispatch(
        sweetAlertActions.show("Do you want to update this appointment", {
          title: "Are you sure?",
          confirmButtonColor: "",
          text: "Do you want to update this appointment",
          showCancelButton: true,
          onConfirm: () => {
            const events = this.state.events;
            const newevents = events.map((ev) => {
              if (ev.id === data.event.id) {
                ev.start = start;
                ev.end = end;
              }
              return ev;
            });
            const { appointments } = this.props;
            const appointment = appointments.find(
              (app) => app._id === data.event.id
            );

            const updatedData = {
              appointment_id: appointment._id,
              patient_id: appointment.patient_id._id,
              appointment_date: startDate,
              time_slot_from: moment(new Date(start)),
            };

            dispatch(appointmentActions.update(updatedData));
            dispatch(sweetAlertActions.hide());
          },
          onCancel: () => dispatch(sweetAlertActions.hide()),
        })
      );
    } else {
      const { dispatch } = this.props;
      dispatch(
        sweetAlertActions.show("Appointment cannot be booked in past", {
          title: "Attention!",
          confirmButtonColor: "",
          text: "Appointment cannot be booked in past",
          showCancelButton: false,
          showConfirmButton: true,
          onCancel: () => dispatch(sweetAlertActions.hide()),
          onConfirm: () => dispatch(sweetAlertActions.hide()),
        })
      );
    }
  };

  togglePrintModal() {
    this.setState({ showPrintModal: !this.state.showPrintModal });
  }

  toggleDeleteAppointment() {
    this.setState({
      deleteAppointmentModal: !this.state.deleteAppointmentModal,
    });
  }
  onDeleteClicked(data) {
    const appointment = this.props.appointments.find(
      (app) => app._id === data.id
    );
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(appointment));
    this.toggleDeleteAppointment();
  }

  Event({ event }) {
    console.log("Logging Event : ", event);
    return (
      <>
        {" "}
        <div style={{ fontSize: 12 }}>
          <a
            className="delete-btn"
            onClick={(e) => {
              e.stopPropagation();
              this.onDeleteClicked(event);
            }}
            title="Delete"
          >
            {" "}
            <FontAwesomeIcon icon={faTrash} size="1x" />{" "}
          </a>
          {event.title}
          <UncontrolledButtonDropdown>
            <DropdownToggle color="basic">
              <a
                style={{ padding: "3px 8px", margin: 2 }}
                title="View"
                className="text-primary font-icon-wrapper "
              >
                <FontAwesomeIcon icon={faEllipsisV} size="1x" />
              </a>
            </DropdownToggle>
            <DropdownMenu
              positionFixed={true}
              className="dropdown-menu-left dropdown-menu-rounded"
            >
              <AppointmentPopup appointment={event.appointment} />
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
        <div></div>
      </>
    );
  }

  ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < CURRENT_DATE ? "green" : "blue",
      },
    });

  // componentDidMount() {
  //   const { dispatch, transaction } = this.props;
  //   dispatch(consultantActions.getAll());
  //   dispatch(registrationMasterActions.getAll());
  //   dispatch(
  //     otBookingActions.getAll(`operation_date=${new Date().toISOString()}`)
  //   );

  //   dispatch(serviceMasterActions.getAllPackages());

  //   if (transaction)
  //     consultantService
  //       .getById(transaction.id)
  //       .then((cons) => {
  //         if (cons.success) {
  //           dispatch(
  //             appointmentActions.getAll(
  //               `appointment_date=${new Date()}&&consultant_id=${
  //                 cons.consultant.code
  //               }`
  //             )
  //           );
  //           consultantService
  //             .getEarning(cons.consultant.code)
  //             .then((result) => {
  //               if (result.success) {
  //                 this.setState({ earnings: result.earning });
  //               }
  //             })
  //             .catch((err) => {});

  //           this.setState({ consultant: cons.consultant });
  //         }
  //       })
  //       .catch((err) => {});
  // }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      appointmentActions.getAll(`appointment_date=${new Date().toISOString()}`)
    );

    dispatch(registrationMasterActions.getAll());
    appointmentService
      .getDateWise("appointment_date=" + new Date().toISOString())
      .then((result) => {
        let total = 0;
        result.consultants.forEach((element) => {
          total += element.totalAppointments;
        });

        this.setState({
          consultants: result.consultants,
          totalAppointments: total,
        });
      });
  }

  onAppointmentBooked() {
    this.componentDidMount();
  }

  onConsultantChanged(data) {
    try {
      const events = data
        .filter((item) => item.status !== "CANCELLED")
        .map((item) => {
          let dt = item.appointment_date;
          let color = "blue";
          if (item.patient_id.confidentiality === true) {
            switch (item.patient_id.confidentiality_type) {
              case "Confidentiality Disease":
                color = "red";
                break;
              case "Celebrity":
                color = "green";
                break;
              case "VIP":
                color = "orange";
                break;
            }
          }
          let startDate = new Date(item.time_slot_from);
          startDate.setDate(startDate.getDate() + 1);
          return {
            id: item._id,
            title: item.patient_id.firstname,
            start: startDate,
            end: new Date(
              startDate.setMinutes(startDate.getMinutes() + item.slot_duration)
            ),
            color: color,
            appointment: item,
          };
        });
      this.setState({ events: events });
    } catch (err) {
      console.log(err);
    }
  }

  // componentDidUpdate(pp, ps) {
  //   const { transaction, dispatch } = this.props;

  //   if (pp.transaction != this.props.transaction) {
  //     if (transaction)
  //       consultantService
  //         .getById(transaction.id)
  //         .then((cons) => {
  //           if (cons.success) {
  //             dispatch(
  //               appointmentActions.getAll(
  //                 `appointment_date=${new Date()}&&consultant_id=${
  //                   cons.consultant.code
  //                 }`
  //               )
  //             );
  //             consultantService
  //               .getEarning(cons.consultant.code)
  //               .then((result) => {
  //                 if (result.success) {
  //                   this.setState({ earnings: result.earning });
  //                 }
  //               })
  //               .catch((err) => {});

  //             this.setState({ consultant: cons.consultant });
  //           }
  //         })
  //         .catch((err) => {});
  //   }
  // }

  handleSelectEvent(data) {
    const appointment = this.props.appointments.find(
      (app) => app._id === data.id
    );
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(appointment));
    this.setState({ selectedAppointment: appointment });
  }

  handleDoubleClickEvent(data) {
    const appointment = this.props.appointments.find(
      (app) => app._id === data.id
    );
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(appointment));
    this.setState({ mode: "EDIT", selectedAppointment: appointment });
    this.toggleAppointmentModal();
  }

  handleDoubleClick(appointment) {
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(appointment));
    this.setState({ mode: "EDIT", selectedAppointment: appointment });
    this.toggleAppointmentModal();
  }

  toggleAppointmentModal() {
    this.setState({ showAppointmentModal: !this.state.showAppointmentModal });
  }

  handleBookingSelectEvent(data) {
    const booking = this.props.bookings.find((app) => app._id === data.id);

    const { dispatch } = this.props;
    dispatch(otBookingActions.selectBooking(booking));
    this.setState({ selectedBooking: booking });
  }
  handleBookingDoubleClick(booking) {
    const { dispatch } = this.props;
    dispatch(otBookingActions.selectBooking(booking));
    this.setState({ mode: "EDIT", selectedBooking: booking });
    this.toggleBookingModal();
  }

  toggleBookingModal() {
    this.setState({ showBookingModal: !this.state.showBookingModal });
  }

  toggleReminderModal() {
    this.setState({ showReminderModal: !this.state.showReminderModal });
  }
  toggleBlockCalendarModal(isSaved) {
    if (isSaved) {
      const { dispatch } = this.props;
      dispatch(
        appointmentActions.getAll(
          `appointment_date=${new Date().toISOString()}`
        )
      );
    }

    this.setState({
      showBlockCalendarModal: !this.state.showBlockCalendarModal,
    });
  }

  onNewAppointment() {
    this.setState({ mode: "NEW" });
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(null));
    this.toggleAppointmentModal();
  }
  onAddReminder() {
    this.toggleReminderModal();
  }

  onBlockCalendar() {
    this.toggleBlockCalendarModal(null);
  }

  // onDateRangeChanged(data) {
  //   const { dispatch } = this.props;
  //   const { consultant } = this.state;
  //   if (consultant && data.length === 1) {
  //     dispatch(
  //       appointmentActions.getAll(
  //         `appointment_date=${new Date(data[0])}&&consultant_id=${
  //           consultant.code
  //         }`
  //       )
  //     );
  //   } else if (data.length === 7) {
  //     dispatch(
  //       appointmentActions.getAll(
  //         `start_date=${new Date(data[0])}&end_date=${new Date(
  //           data[6]
  //         )}&&consultant_id=${consultant.code}`
  //       )
  //     );
  //   } else if (data.start && data.end) {
  //     dispatch(
  //       appointmentActions.getAll(
  //         `start_date=${new Date(data.start)}&end_date=${new Date(
  //           data.end
  //         )}&&consultant_id=${consultant.code}`
  //       )
  //     );
  //   }
  // }

  onDateRangeChanged(data) {
    const { dispatch } = this.props;
    if (data.length === 1) {
      dispatch(
        appointmentActions.getAll(`appointment_date=${new Date(data[0])}`)
      );
      appointmentService
        .getDateWise("appointment_date=" + new Date(data[0]))
        .then((result) => {
          let total = 0;
          result.consultants.forEach((element) => {
            total += element.totalAppointments;
          });

          this.setState({
            consultants: result.consultants,
            totalAppointments: total,
          });
        });
    } else if (data.length === 7) {
      dispatch(
        appointmentActions.getAll(
          `start_date=${new Date(data[0])}&end_date=${new Date(data[6])}`
        )
      );
      appointmentService
        .getDateWise(
          `start_date=${new Date(data[0])}&end_date=${new Date(data[6])}`
        )
        .then((result) => {
          let total = 0;
          result.consultants.forEach((element) => {
            total += element.totalAppointments;
          });

          this.setState({
            consultants: result.consultants,
            totalAppointments: total,
          });
        });
    } else if (data.start && data.end) {
      dispatch(
        appointmentActions.getAll(
          `start_date=${new Date(data.start)}&end_date=${new Date(data.end)}`
        )
      );
      appointmentService
        .getDateWise(
          `start_date=${new Date(data.start)}&end_date=${new Date(data.end)}`
        )
        .then((result) => {
          let total = 0;
          result.consultants.forEach((element) => {
            total += element.totalAppointments;
          });

          this.setState({
            consultants: result.consultants,
            totalAppointments: total,
          });
        });
    }
  }

  onLinksClicked(path) {
    const { selectedAppointment } = this.state;
    this.props.history.push({
      pathname: path,
      state: {
        mode: "CONSULTANT_HOME",
        data: selectedAppointment,
      },
    });
  }

  eventStyleGetter(event, start, end, isSelected) {
    //console.log(event);
    var backgroundColor = "#" + event.hexColor;
    switch (event.appointment.status) {
      case "DONE":
        backgroundColor = "#adebad";
        break;
      case "CANCELLED":
        backgroundColor = "#ff8080";
        break;
      case "NEW":
        backgroundColor = "#ffcc80";
        break;
    }
    var style = {
      backgroundColor: backgroundColor,
      color: "#fff",
      fontSize: "medium",
      height: "40px",
      borderLeftWidth: "5px",
      borderLeftColor: "orange",
    };
    return {
      style: style,
    };
  }

  dateDiff(date) {
    //date = date.split('-');
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    // var yy = parseInt(date[0]);
    // var mm = parseInt(date[1]);
    // var dd = parseInt(date[2]);

    var yy = date.getFullYear();
    var mm = date.getMonth();
    var dd = date.getDate();
    var years, months, days;
    // months
    months = month - mm;
    if (day < dd) {
      months = months - 1;
    }
    // years
    years = year - yy;
    if (month * 100 + day < mm * 100 + dd) {
      years = years - 1;
      months = months + 12;
    }
    // days
    days = Math.floor(
      (today.getTime() - new Date(yy + years, mm + months - 1, dd).getTime()) /
        (24 * 60 * 60 * 1000)
    );
    //
    return { years: years, months: months, days: days };
  }
  render() {
    const { appointments, customer, transaction } = this.props;
    // const { consultant, earnings } = this.state;
    const { consultants, totalAppointments, consultant, earnings } = this.state;

    const events = appointments
      // .filter((item) => item.status != "CANCELLED" && item.status != "DONE"  )
      .map((item) => {
        let dt = item.appointment_date;
        let color = "blue";
        if (item.patient_id.confidentiality === true) {
          switch (item.patient_id.confidentiality_type) {
            case "Confidentiality Disease":
              color = "red";
              break;
            case "Celebrity":
              color = "green";
              break;
            case "VIP":
              color = "orange";
              break;
          }
        }
        const startDate = new Date(item.time_slot_from);
        // const startDate=new Date(item.appointment_date);
        return {
          id: item._id,
          title: item.patient_id.firstname + ` ${item.patient_id.patient_uhid}`,
          start: new Date(item.time_slot_from),
          end: new Date(
            startDate.setMinutes(startDate.getMinutes() + item.slot_duration)
          ),
          color: color,
          appointment: item,
        };
      });

    const regdate = this.dateDiff(
      consultant ? new Date(consultant.created_on) : new Date()
    );

    return (
      <Fragment>
        <DeleteAppointment
          openModal={this.state.deleteAppointmentModal}
          toggle={() => this.toggleDeleteAppointment()}
          appointment={this.state.selectedAppoinment}
        />
        <Row>
          <Col md={2}>
            {customer && (
              <div class="border" style={{ paddingTop: 8 }}>
                <Row>
                  <Col md={2}>
                    <img
                      style={{ width: "100%" }}
                      src={
                        apiUrl +
                        (customer.logo_url
                          ? customer.logo_url
                          : Icons.Hospital)
                      }
                      alt=""
                    />
                  </Col>
                  <Col md={10}>
                    <div>
                      <div style={{ fontWeight: "bold" }}>
                        {customer.customer_Tran_Name}{" "}
                      </div>
                      <p style={{ fontSize: 9 }}>
                        {customer.customer_Tran_Address}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            <div className="border" style={{ marginTop: 8 }}>
              {" "}
              <Nav vertical>
                <NavItem>
                  <DropdownItem>
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                          <div className="avatar-icon-wrapper">
                            <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                            <div className="avatar-icon">
                              <img src={Icons.MaleIcon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            {transaction && transaction.user_name}
                          </div>
                          <div className="widget-subheading">
                            {/* Aenean vulputate. */}
                            {transaction && transaction.designation}
                          </div>
                        </div>
                      </div>
                    </div>
                  </DropdownItem>
                </NavItem>
              </Nav>
            </div>

            <DoctorList
              onChange={(data) => {
                this.onConsultantChanged(data);
              }}
              consultantList={consultants}
              totalAppointments={totalAppointments}
              doctorsList="consultantDoctors"
            />

            <div style={{ marginTop: 8 }}>
              <ListGroup flush>
                <ListGroupItem
                  tag="button"
                  action
                  onClick={() =>
                    this.onLinksClicked(
                      "/out-patient-department/opd-initial-assessment/initial-assessment/add"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    color="grey"
                    size="1x"
                  ></FontAwesomeIcon>
                  &nbsp; Initial Assessments
                </ListGroupItem>
                <ListGroupItem
                  tag="button"
                  action
                  onClick={() =>
                    this.onLinksClicked(
                      "/out-patient-department/opd-initial-assessment/opd-vitals"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    color="grey"
                    size="1x"
                  ></FontAwesomeIcon>
                  &nbsp; OPD Vitals Form{" "}
                </ListGroupItem>

                <ListGroupItem
                  tag="button"
                  action
                  onClick={() =>
                    this.onLinksClicked(
                      "/out-patient-department/opd-orders/service-orders/new"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    color="grey"
                    size="1x"
                  ></FontAwesomeIcon>
                  &nbsp; OPD Service Orders
                </ListGroupItem>

                <ListGroupItem
                  tag="button"
                  action
                  onClick={() =>
                    this.onLinksClicked(
                      "/out-patient-department/opd-pharmacy/new"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    color="grey"
                    size="1x"
                  ></FontAwesomeIcon>
                  &nbsp; OPD Pharmacy Orders
                </ListGroupItem>
              </ListGroup>
            </div>
            <br />
            <TodayOTBookings
              onBookingSelected={(data) => this.handleBookingSelectEvent(data)}
              selectedBooking={this.state.selectedBooking}
              onDoubleClick={(data) => this.handleBookingDoubleClick(data)}
            />
            {this.state.showBookingModal && (
              <OTBookingModal
                showModal={this.state.showBookingModal}
                mode={this.state.mode}
                booking={this.state.selectedBooking}
                consultant={consultant}
                toggle={() => this.toggleBookingModal()}
              />
            )}
          </Col>
          <Col md={7}>
            <Row className="border" style={{ padding: 10 }}>
              <Col md={12} className="text-center">
                {/* <h2>{customer && customer.customer_Tran_Name}</h2> */}
                <p className="blockquote">
                  You have been a part of{" "}
                  {customer && customer.customer_Tran_Name} team from{" "}
                  {regdate.years} Years/{regdate.months} Months/{regdate.days}{" "}
                  Days{" "}
                  <FontAwesomeIcon
                    icon={faSmile}
                    color="blue"
                    // size="1.8x"
                  ></FontAwesomeIcon>
                </p>
              </Col>
            </Row>
            <br />
            <DnDCalendar
              selectable
              defaultDate={moment().toDate()}
              onRangeChange={(d) => this.onDateRangeChanged(d)}
              onSelectEvent={(data) => {
                this.handleSelectEvent(data);
              }}
              onDoubleClickEvent={(data) => {
                this.handleDoubleClickEvent(data);
              }}
              defaultView="day"
              timeSlots={15}
              step={15}
              views={["day", "week", "month"]}
              events={events || []}
              localizer={localizer}
              onEventDrop={this.onEventDrop}
              // scrollToTime={new Date()}
              //  onEventResize={this.onEventResize}
              components={{
                event: this.Event.bind(this),
                // dateCellWrapper: this.ColoredDateCellWrapper,
              }}
              eventPropGetter={this.eventStyleGetter}
              resizable
              style={{ height: "100vh" }}
              // ond
            />
            <hr></hr>
            <Row>
              <Col sm="4">
                <Graph
                  title="Last Week Earnings"
                  data={earnings ? earnings.weekly : []}
                  dataKey="earning"
                />
              </Col>
              <Col sm="4">
                <Graph
                  title="Last Month Earnings"
                  data={earnings ? earnings.monthly : []}
                  dataKey="earning"
                />
              </Col>
              <Col sm="4">
                <Graph
                  title="Yearly Earnings"
                  data={earnings ? earnings.yearly : []}
                  dataKey="earning"
                />
              </Col>
            </Row>
          </Col>
          <Col md="3">
            <Row>
              <Col sm="12">
                <Button
                  className="btn btn-block"
                  onClick={() => this.onNewAppointment()}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} size="1x" /> Schedule
                  Appointment{" "}
                </Button>
                <Button
                  className="btn btn-block"
                  size="sm"
                  onClick={() => this.togglePrintModal()}
                >
                  <FontAwesomeIcon icon={faPrint} size="1x" /> Print List{" "}
                </Button>
                {this.state.showAppointmentModal && (
                  <AppointmentModal
                    openModal={this.state.showAppointmentModal}
                    mode={this.state.mode}
                    appointment={this.state.selectedAppoinment}
                    consultant={consultant}
                    toggle={() => this.toggleAppointmentModal()}
                    appointmentBooked={() => {
                      this.onAppointmentBooked();
                    }}
                    disabled
                  />
                )}
              </Col>
            </Row>
            <Row style={{ margin: 5 }}>
              <Col sm="6">
                <Button
                  className="btn btn-block"
                  onClick={() => this.onAddReminder()}
                >
                  <FontAwesomeIcon icon={faClock} size="1x" /> Add Reminder{" "}
                </Button>

                {this.state.showReminderModal && (
                  <ReminderModal
                    openModal={this.state.showReminderModal}
                    mode={this.state.mode}
                    toggle={() => this.toggleReminderModal()}
                    consultant={consultant}
                  />
                )}
              </Col>
              <Col sm="6">
                <Button
                  className="btn btn-block"
                  onClick={() => this.onBlockCalendar()}
                >
                  <FontAwesomeIcon icon={faWindowClose} size="1x" /> Block
                  Calendar{" "}
                </Button>

                {this.state.showBlockCalendarModal && (
                  <BlockCalendar
                    openModal={this.state.showBlockCalendarModal}
                    mode={this.state.mode}
                    toggle={(isSaved) => this.toggleBlockCalendarModal(isSaved)}
                    consultant={consultant}
                  />
                )}

                {this.state.showPrintModal && (
                  <PrintPreview
                    openModal={this.state.showPrintModal}
                    mode={this.state.mode}
                    toggle={() => this.togglePrintModal()}
                  />
                )}
              </Col>
            </Row>
            <TodaySchedule
              onAppointmentSelected={(data) => this.handleSelectEvent(data)}
              selectedAppointment={this.state.selectedAppointment}
              onDoubleClick={(data) => this.handleDoubleClick(data)}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, appointments, customers, bookings } = state;
  return {
    consultants: consultants.consultants,
    appointments: appointments.appointments,
    customer: customers.selected_customer,
    transaction: customers.currentTransaction,
    bookings: bookings.bookings,
  };
}

const calendarDND = DragDropContext(HTML5Backend)(ViewMain);
const connectedMainView = connect(mapStateToProps)(calendarDND);
export default connectedMainView;
