import { formConstants } from '../constants';

export function forms(state = {}, action) {

  switch (action.type) { 
    case formConstants.GET_ALL_FORM_REQUEST:
      return {...state, loading: true};
    case formConstants.GET_ALL_FORM_SUCCESS:
      return {...state,forms:action.data, loading: false};          
    case formConstants.GET_ALL_FORM_FAILURE:
      return {...state, error: action.error, loading: false };    

      case formConstants.GET_FORM_REQUEST:
        return {...state, loading: true};
      case formConstants.GET_FORM_SUCCESS:
        return {...state,selected_form:action.data, loading: false};          
      case formConstants.GET_FORM_FAILURE:
        return {...state, error: action.error, loading: false };        
                 
            case formConstants.FORM_CLEAR:
            return  {}
      default:
                return state
   
  }
}