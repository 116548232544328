import moment from "moment";
import React, { Fragment } from "react";
// import BigCalendar from "react-big-calendar";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import {
  appointmentActions,
  registrationMasterActions,
} from "../../../actions";
import "../../index.css";
import DeleteAppointment from "../../Modals/DeleteAppointment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
// import { DragDropContext } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';

const BigCalendar = Calendar;
// const localizer = BigCalendar.momentLocalizer(moment);
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(BigCalendar);

const CURRENT_DATE = moment().toDate();

class AppointmentCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      selectedAppoinment: null,
      mode: "NEW",
    };
    this.onEventDrop = this.onEventDrop.bind(this);
  }

  Event({ event }) {
    return (
      <>
        <div style={{ backgroundColor: event.color, fontSize: 12 }}>
          {event.title}
        </div>
        <div></div>
      </>
    );
  }

  componentDidMount() {
    const { dispatch, patient } = this.props;
    if (patient)
      dispatch(
        appointmentActions.getAll(
          `patient_id=${patient._id}&&appointment_date=${new Date()}`
        )
      );

    dispatch(registrationMasterActions.getAll());
  }

  handleSelectEvent(data) {
    console.log(data, "datadatadata");
    const appointment = this.props.appointments.find(
      (app) => app._id === data.id
    );
    const { dispatch } = this.props;
    dispatch(appointmentActions.selectAppointment(appointment));
    this.setState({ selectedAppointment: appointment });
  }

  onDateRangeChanged(data) {
    const { dispatch, patient } = this.props;
    if (data.length === 1) {
      dispatch(
        appointmentActions.getAll(
          `patient_id=${patient._id}&&appointment_date=${new Date(data[0])}`
        )
      );
    } else if (data.length === 7) {
      dispatch(
        appointmentActions.getAll(
          `patient_id=${patient._id}&&start_date=${new Date(
            data[0]
          )}&end_date=${new Date(data[6])}`
        )
      );
    } else if (data.start && data.end) {
      dispatch(
        appointmentActions.getAll(
          `patient_id=${patient._id}&&start_date=${new Date(
            data.start
          )}&end_date=${new Date(data.end)}`
        )
      );
    }
  }

  eventStyleGetter(event, start, end, isSelected) {
    console.log(event);
    var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 1,
      color: "black",
      border: "5px",
      display: "block",
      fontSize: "14px",
      height: "50px",
    };
    return {
      style: style,
    };
  }

  //   onEventDrop({event, start, end}){
  //     console.log(" working")
  //     const { events } = this.state
  //     const idx = events.indexOf(event);

  //     const updatedEvent = { ...event, start, end };

  //     const nextEvents = [...events];
  //     nextEvents.splice(idx, 1, updatedEvent);
  //     this.setState({
  //       events: nextEvents
  //     });
  // }

  render() {
    const { appointments } = this.props;

    const events = appointments
      .filter((item) => item.status !== "CANCELLED")
      .map((item) => {
        let dt = item.appointment_date;
        let color = "blue";
        if (item.patient_id.confidentiality === true) {
          switch (item.patient_id.confidentiality_type) {
            case "Confidentiality Disease":
              color = "red";
              break;
            case "Celebrity":
              color = "green";
              break;
            case "VIP":
              color = "orange";
              break;
          }
        }
        const startDate = new Date(item.time_slot_from);
        return {
          id: item._id,
          title: item.patient_id.firstname + ` ${item.patient_id.patient_uhid}`,
          start: new Date(item.time_slot_from),
          end: new Date(
            startDate.setMinutes(startDate.getMinutes() + item.slot_duration)
          ),
          color: color,
        };
      });

    return (
      <Fragment>
        <DeleteAppointment
          openModal={this.state.deleteAppointmentModal}
          toggle={() => this.toggleDeleteAppointment()}
          appointment={this.state.selectedAppoinment}
        />
        <div className="bigCalendarStyle">
          <DnDCalendar
            selectable
            // defaultDate={moment().toDate()}
            onRangeChange={(d) => this.onDateRangeChanged(d)}
            onSelectEvent={(data) => {
              this.handleSelectEvent(data);
            }}
            // defaultView="day"
            defaultView={BigCalendar.Views.MONTH}
            timeSlots={15}
            step={15}
            views={["day", "week", "month"]}
            events={events || []}
            localizer={localizer}
            // onEventDrop={this.onEventDrop}
            components={{
              event: this.Event.bind(this),
            }}
            resizable
            style={{ height: "100vh" }}
            // ond
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { appointments } = state;
  return { appointments: appointments.appointments };
}

const connectedAppointmentCalendar = connect(mapStateToProps)(
  AppointmentCalendar
);
export default connectedAppointmentCalendar;
