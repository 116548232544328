
    export const patientQueueConstants={
   
      ADD_PATIENT_TO_QUEUE_REQUEST:'ADD_PATIENT_TO_QUEUE_REQUEST',
      ADD_PATIENT_TO_QUEUE_SUCCESS:'ADD_PATIENT_TO_QUEUE_SUCCESS',
      ADD_PATIENT_TO_QUEUE_FAILURE:'ADD_PATIENT_TO_QUEUE_FAILURE' ,

      GET_ALL_QUEUE_PATIENTS:"GET_ALL_QUEUE_PATIENTS",

      PATIENT_ADDED_TO_QUEUE:'PATIENT_ADDED_TO_QUEUE',

      UPDATE_PATIENT_IN_QUEUE:'UPDATE_PATIENT_IN_QUEUE',
  
      REMOVE_PATIENT_IN_QUEUE:'REMOVE_PATIENT_IN_QUEUE',
      CLEAR:'CLEAR'
    
    }