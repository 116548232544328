import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  consultantActions,
  corporateMasterActions,
  departmentActions,
  registrationMasterActions,
  tpaMasterActions,
} from "../../actions";
import { apiUrl } from "../../constants/config";
import { patientService } from "../../services/patient.service";
import AppointmentCalendar from "./Appointment/AppointmentCalendar";
import NewAppointment from "./Appointment/NewAppointment";
import Bills from "./Bills";
import RadiologyReports from "./RadiologyReports";
import Reciepts from "./Reciepts";
import RegistrationDetails from "./RegistrationDetails";
import { Icons } from "../../helpers/IconHelper";

class PatientHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      partner: null,
      couple: false,
      activeTab: "1",
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(registrationMasterActions.getAll());
    dispatch(consultantActions.getAll());
    dispatch(departmentActions.getAll());
    dispatch(tpaMasterActions.getAll());
    dispatch(corporateMasterActions.getAll());

    patientService
      .getByPatientUHID({ uhid: "GENi 2020-1-1" })
      .then((res) => {
        if (res.success) {
          if (res.patient.registering_as === "couple") {
            patientService
              .getByPatientUHID({ uhid: res.patient.partner_uhid })
              .then((p_res) => {
                if (p_res.success) {
                  this.setState({
                    patient: res.patient,
                    couple: true,
                    partner: p_res.patient,
                  });
                }
              });
          } else {
            this.setState({ patient: res.patient });
          }
        }
      })
      .catch((err) => {});
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    const { patient, partner, couple } = this.state;
    return (
      <Fragment>
        {patient ? (
          <Card className="mb-3">
            <Row>
              <Col sm={3}>
                <div>
                  <Card className="card-shadow-primary card-border mb-3">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-primary">
                        <div
                          className="menu-header-image"
                          style={{
                            backgroundImage: "url(" + Icons.City2 + ")",
                          }}
                        ></div>
                        <div className="menu-header-content">
                          <div className="avatar-icon-wrapper avatar-icon-lg">
                            <div className="avatar-icon rounded btn-hover-shine">
                              <img
                                src={apiUrl + patient.photo}
                                alt="Avatar 5"
                              />
                            </div>
                          </div>
                          <div>
                            <h5 className="menu-header-title">
                              {patient.firstname ? patient.firstname : ""}{" "}
                              {patient.middlename ? patient.middlename : ""}{" "}
                              {patient.lastname ? patient.lastname : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="scroll-area-lg">
                                        <PerfectScrollbar> */}
                    <ListGroup flush>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Registration Details
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Lab Investigation Reports
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "3",
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Radiology Investigation Reports
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem disabled>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "4",
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Admission Details
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem disabled>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "5",
                            })}
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Discharge Details
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem disabled>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "6",
                            })}
                            onClick={() => {
                              this.toggle("6");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Discharge Summary
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "7",
                            })}
                            onClick={() => {
                              this.toggle("7");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">Bills</div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "8",
                            })}
                            onClick={() => {
                              this.toggle("8");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">Receipts</div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "9",
                            })}
                            onClick={() => {
                              this.toggle("9");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Book Appointment
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "10",
                            })}
                            onClick={() => {
                              this.toggle("10");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Appiontment Details
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "11",
                            })}
                            onClick={() => {
                              this.toggle("11");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Pharmacy Bills
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem disabled>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "12",
                            })}
                            onClick={() => {
                              this.toggle("12");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Consumable Bills
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem>
                        <div className="widget-content p-0">
                          <NavLink
                            href="#"
                            className={classnames({
                              active: this.state.activeTab === "13",
                            })}
                            onClick={() => {
                              this.toggle("13");
                            }}
                          >
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left center-elem mr-2">
                                <i className="pe-7s-file text-muted fsize-2" />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  Notifications
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </div>{" "}
              </Col>
              <Col sm={9}>
                <div style={{ padding: 20 }}>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <RegistrationDetails
                        patient={patient}
                        partner={partner}
                        couple={couple}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <RadiologyReports patient={patient} />
                    </TabPane>
                    <TabPane tabId="7">
                      <Bills patient={patient} />
                    </TabPane>
                    <TabPane tabId="8">
                      <Reciepts patient={patient} />
                    </TabPane>
                    <TabPane tabId="9">
                      <NewAppointment patient={patient} />
                    </TabPane>
                    <TabPane tabId="10">
                      <AppointmentCalendar patient={patient} />
                    </TabPane>
                    <TabPane tabId="2">
                      <p>
                        Like Aldus PageMaker including versions of Lorem. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.{" "}
                      </p>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Card>
        ) : null}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { registrationMaster } = state;
  return { masters: registrationMaster.data };
}

const connectedPatientHome = connect(mapStateToProps)(PatientHome);

export default connectedPatientHome;
