import React, { Component } from "react";
import { Bounce, toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Table,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSearch,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

class ConsultantListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      filtered_consultants: [],
      selected_consultants: [],
    };
  }
  componentDidMount() {
    const { consultants } = this.props;
    let _consultants = consultants.map((item) => {
      item.title = this.codeToValue(item.title, "titles");
      return item;
    });
    this.setState({
      consultants: _consultants,
      filtered_consultants: _consultants,
    });
    const { consultant_list } = this.props;
    this.setState({ selected_consultants: consultant_list });
  }

  componentDidUpdate(pp, ps) {
    const { consultant_list } = this.props;
    // if(pp.consultant_list)
    // if(pp.consultant_list.length!=ps.selected_consultants.length)
    // {
    //     this.setState({selected_consultants:consultant_list})
    // }

    if (pp.consultant_list.length !== consultant_list.length) {
      this.setState({ selected_consultants: consultant_list });
    }
  }
  onSave = () => {
    this.props.onSubmit(this.state.selected_consultants);
  };

  onAssignClicked = (data, e) => {
    let selected_consultants = [...this.state.selected_consultants];
    let index = selected_consultants.findIndex(
      (cons) => cons.consultant_id === data.code
    );
    if (index > -1) {
      selected_consultants.splice(index, 1);
    } else {
      selected_consultants.push({
        consultant_id: data.code,
        active: true,
        deleted: false,
      });
    }

    this.setState({ selected_consultants: selected_consultants });
  };

  isAssigned = (data) => {
    let index = this.state.selected_consultants.findIndex(
      (user) => user.consultant_id === data.code
    );
    return index > -1 ? true : false;
  };

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  onChangeSearchText(e) {
    const { target } = e;
    const { consultants } = this.state;
    if (target.value !== "") {
      let filteredResult = consultants.filter((item) => {
        if (
          JSON.stringify(item)
            .toLowerCase()
            .includes(target.value.toLowerCase())
        )
          return item;
      });
      this.setState({ filtered_consultants: filteredResult });
    } else this.setState({ filtered_consultants: consultants });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        toggle={() => this.props.toggle()}
        style={{ width: 550 }}
      >
        <ModalHeader toggle={() => this.props.toggle()}>
          OT Consultants List{" "}
        </ModalHeader>
        <ModalBody>
          <InputGroup>
            <Input
              bsSize="sm"
              type="text"
              onChange={(e) => {
                this.onChangeSearchText(e);
              }}
              placeHolder="Search here"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>{" "}
          </InputGroup>
          <div
            className="scroll-area-md"
            style={{ border: "2px solid #e0f3ff" }}
          >
            <PerfectScrollbar>
              <Table size="sm">
                <thead>
                  <th>Sno.</th>
                  <th>Name</th>
                  <th>Assign</th>
                </thead>
                <tbody>
                  {this.state.filtered_consultants.map((user, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {/* {this.codeToValue( user.title,'titles')} */}
                        {user.title} {user.firstname} {user.lastname}
                      </td>
                      <td>
                        {this.isAssigned(user) ? (
                          <Button
                            size="sm"
                            color="warning"
                            onClick={this.onAssignClicked.bind(this, user)}
                          >
                            <FontAwesomeIcon icon={faCheck} /> Assigned
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            color="primary"
                            onClick={this.onAssignClicked.bind(this, user)}
                          >
                            {" "}
                            Assign
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </PerfectScrollbar>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Row>
            <Col sm="6">
              <Button
                className="btn btn-block"
                color="primary"
                bsSize="sm"
                onClick={this.onSave.bind(this)}
              >
                Save
              </Button>
            </Col>
            <Col sm="6">
              <Button
                className="btn btn-block"
                color="warning"
                bsSize="sm"
                onClick={() => this.props.toggle()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  const { consultants, registrationMaster } = state;

  return {
    consultants: consultants.consultants,
    masters: registrationMaster.data,
  };
}

const connectedConsultantListModal = connect(mapStateToProps)(
  ConsultantListModal
);
export default connectedConsultantListModal;
