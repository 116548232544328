import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import UploadModal from "./Modals/UploadModal";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Table,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import {
  AreaChart,
  Area,
  LineChart,
  Line,
  ResponsiveContainer,
  BarChart,
  Bar,
  ComposedChart,
  CartesianGrid,
} from "recharts";

import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

import Switch from "react-switch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { sweetAlertActions, registrationMasterActions } from "../../../actions";

export default class Packages extends Component {
  constructor() {
    super();
    this.state = {
      currentPackage: "Master Health Checkup",
      checked: false,
      isToggleOn: true,
      Package1Services: [
        {
          id: 1,
          servicename: "Complete Blood Count (CBC)",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 2,
          servicename: "Complete Haemogram",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 3,
          servicename: "Liver Function Test",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 4,
          servicename: "Lipid Profile",
          serviceamount: 11,
          newamount: 14,
          active: true,
        },
      ],
      Package2Services: [
        {
          id: 4,
          servicename: "Lipid Profile",
          serviceamount: 11,
          newamount: 14,
          active: true,
        },
        {
          id: 5,
          servicename: "Antinatal Profile",
          serviceamount: 10,
          newamount: 13,
          active: true,
        },
        {
          id: 6,
          servicename: "HB TC DC ESR",
          serviceamount: 12,
          newamount: 15,
          active: true,
        },
      ],
      AllServices: [
        {
          id: 1,
          servicename: "Complete Blood Count (CBC)",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 2,
          servicename: "Complete Haemogram",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 3,
          servicename: "Liver Function Test",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 4,
          servicename: "Lipid Profile",
          serviceamount: 11,
          newamount: 14,
          active: true,
        },
        {
          id: 5,
          servicename: "Antinatal Profile",
          serviceamount: 10,
          newamount: 13,
          active: true,
        },
        {
          id: 6,
          servicename: "HB TC DC ESR",
          serviceamount: 12,
          newamount: 15,
          active: true,
        },
        {
          id: 7,
          servicename: "Coagulation Profile",
          serviceamount: 10,
          newamount: 16,
          active: true,
        },
        {
          id: 8,
          servicename: "Thyroid Function Test",
          serviceamount: 13,
          newamount: 15,
          active: true,
        },
        {
          id: 9,
          servicename: "Urine Routine",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 10,
          servicename: "Iron Profile",
          serviceamount: 11,
          newamount: 15,
          active: true,
        },
        {
          id: 11,
          servicename: "Fluid Analysis",
          serviceamount: 10,
          newamount: 13,
          active: true,
        },
        {
          id: 12,
          servicename: "Renal Profile",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 13,
          servicename: "Synovial Fluid Analysis",
          serviceamount: 10,
          newamount: 14,
          active: true,
        },
        {
          id: 14,
          servicename: "Synovial Fluid For C/S",
          serviceamount: 11,
          newamount: 15,
          active: true,
        },
        {
          id: 15,
          servicename: "CSF Analysis",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 16,
          servicename: "Protein",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 17,
          servicename: "Urine Culture & Sensitivity",
          serviceamount: 10,
          newamount: 15,
          active: true,
        },
        {
          id: 18,
          servicename: "ANTIBIOTICS Culture",
          serviceamount: 12,
          newamount: 15,
          active: true,
        },
        {
          id: 19,
          servicename: "Amikacin",
          serviceamount: 11,
          newamount: 15,
          active: true,
        },
        {
          id: 20,
          servicename: "MICROBIOLOGY",
          serviceamount: 10,
          newamount: 17,
          active: true,
        },
        {
          id: 21,
          servicename: "SAMPLE",
          serviceamount: 10,
          newamount: 16,
          active: true,
        },
        {
          id: 22,
          servicename: "COLONY COUNT",
          serviceamount: 11,
          newamount: 15,
          active: true,
        },
      ],
    };
    this.handlePackageChange = this.handlePackageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemovefield = this.handleRemovefield.bind(this);
  }

  handlePackageChange(e) {
    e.preventDefault();
    this.setState({ currentPackage: e.target.value });
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  handleRemovefield(id) {
    //this.setState({ checked });
  }

  render() {
    const removebutton = (
      <Button
        outline
        className="mb-2 mr-2 btn-pill"
        color="danger"
        onClick={() => {
          this.navigate();
        }}
      >
        Remove
      </Button>
    );

    const ServiceAmount = (
      <CustomInput
        type="number"
        id="ServiceAmount"
        name="ServiceAmount"
        defaultValue="10"
      />
    );

    const NewAmount = (
      <CustomInput
        type="number"
        id="NewAmount"
        name="NewAmount"
        defaultValue="15"
      />
    );

    const Products = this.state.Package1Services.map((service, index) => ({
      sno: index + 1,
      servicename: service.servicename,
      serviceamount: service.serviceamount,
      newamount: service.newamount,
      active: (
        <Switch
          checked={service.active}
          onChange={this.handleChange}
          className="mr-2 mb-2"
        />
      ),
      removefield: this.handleRemovefield(service.id),
    }));

    const products = [
      {
        removefield: removebutton,
        sno: "1",
        servicename: "Complete Blood Count (CBC)",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <Switch
            checked={this.state.checked}
            onChange={this.handleChange}
            className="mr-2 mb-2"
          />
        ),
      },
      {
        removefield: removebutton,
        sno: "2",
        servicename: "Complete Haemogram",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <Switch
            checked={this.state.checked}
            onChange={this.handleChange}
            className="mr-2 mb-2"
          />
        ),
      },
      {
        removefield: removebutton,
        sno: "3",
        servicename: "Liver Function Test",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <Switch
            checked={this.state.checked}
            onChange={this.handleChange}
            className="mr-2 mb-2"
          />
        ),
      },
      {
        removefield: removebutton,
        sno: "4",
        servicename: "Urine Analysis",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <Switch
            checked={this.state.checked}
            onChange={this.handleChange}
            className="mr-2 mb-2"
          />
        ),
      },
    ];

    const products2 = [
      {
        removefield: (
          <CustomInput
            type="checkbox"
            id="RemoveCBC"
            name="RemoveCBC"
            label=""
          />
        ),
        sno: "1",
        servicename: "Complete Blood Count (CBC)",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: <CustomInput type="checkbox" id="CBC" name="CBC" label="" />,
      },
      {
        removefield: (
          <CustomInput
            type="checkbox"
            id="RemoveCompleteHaemogram"
            name="RemoveCompleteHaemogram"
            label=""
          />
        ),
        sno: "2",
        servicename: "Complete Haemogram",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <CustomInput
            type="checkbox"
            id="CompleteHaemogram"
            name="CompleteHaemogram"
            label=""
          />
        ),
      },
      {
        removefield: (
          <CustomInput
            type="checkbox"
            id="RemoveLiverFunctionTest"
            name="RemoveLiverFunctionTest"
            label=""
          />
        ),
        sno: "3",
        servicename: "Liver Function Test",
        serviceamount: ServiceAmount,
        newamount: NewAmount,
        active: (
          <CustomInput
            type="checkbox"
            id="LiverFunctionTest"
            name="LiverFunctionTest"
            label=""
          />
        ),
      },
    ];

    const columns = [
      {
        dataField: "sno",
        text: "SI.No",
        sort: true,
      },
      {
        dataField: "servicename",
        text: "Service Name",
        sort: true,
      },
      {
        dataField: "serviceamount",
        text: "Service Amount",
        sort: true,
      },
      {
        dataField: "newamount",
        text: "New Amount",
        sort: true,
      },
      {
        dataField: "active",
        text: "Active",
        sort: true,
      },
      {
        dataField: "removefield",
        text: "Remove",
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: "name",
        order: "ASC",
      },
    ];

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Packages</CardTitle>
              <CardTitle>
                <Row>
                  <Col sm={2}>
                    <CustomInput
                      type="select"
                      id="Customer_Tran_Cluster_Type"
                      name="Customer_Tran_Cluster_Type"
                      onChange={this.handlePackageChange}
                    >
                      <option
                        value="Master Health Checkup"
                        selected={
                          this.state.currentPackage === "Master Health Checkup"
                            ? true
                            : null
                        }
                      >
                        Master Health Checkup
                      </option>
                      <option
                        value="Regular Health Checkup"
                        selected={
                          this.state.currentPackage === "Regular Health Checkup"
                            ? true
                            : null
                        }
                      >
                        Regular Health Checkup
                      </option>
                    </CustomInput>
                  </Col>
                  <UploadModal />
                </Row>
              </CardTitle>
              <div className="table-responsive">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={
                    this.state.currentPackage === "Master Health Checkup"
                      ? products
                      : products2
                  }
                  columns={columns}
                  filter={filterFactory()}
                  defaultSorted={defaultSorted}
                />
              </div>
              <Button color="primary" className="mt-2 right-side">
                Save
              </Button>
              <Button color="primary" className="mt-2 right-side">
                Reset
              </Button>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
