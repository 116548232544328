import {
    getReferalConstants
} from '../constants'


const getReferalReducer = (state = {}, action) =>{

       
      
       switch(action.type){
           case getReferalConstants.GETALL_REFERAL_REQUEST :
               
               return {
                   ...state, loading :true ,
               };

           case getReferalConstants.GETALL_REFERAL_SUCCESS :
               
                       
                       return{
                           
                           ...state, data :  action.data   ,
                       } 
                       
           case getReferalConstants.GETALL_REFERAL_FAIL :
             
                   return {
                       ...state,
                       loading :false,
                       error : action.error,
                   }            


                      

           default:
                 return state;          
       }

};

export {getReferalReducer};