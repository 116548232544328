import moment from "moment";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Col, Input, Row } from "reactstrap";
import DateRangePicker from "./DateRangePicker";
import QRScanner from "./QRScanner";

function getValues(obj, getString = true, seprator = "•") {
  let objval = obj_values(obj);
  if (getString) {
    // return objval.join(seprator).toLowerCase().replace(/true|false|undefined|null:/gi, (x) => "").trim()
    return objval
      .join(seprator)
      .replace(/true|false|undefined|null:/gi, (x) => "")
      .trim();
  }
  return objval;

  function obj_values(ob) {
    if (ob instanceof Object) {
      let values = Object.values(ob);
      let val = values.reduce((f, v) => {
        if (v instanceof Object) {
          return [...f, ...obj_values(v)];
        }
        return [...f, v];
      }, []);
      return Array.from(new Set(val));
    }

    return ob;
  }
}

class MyDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      textSearch: "",
      width: "__",
    };
  }

  componentWillMount() {
    if (this.props.calendarMinDate) {
      this.setState({
        startDate: this.props.calendarMinDate,
        minDate: this.props.calendarMinDate,
      });
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      this.setState({ previousTable: this.props.data });
    }
  }
  componentDidMount() {
    //this.setState({ filterData: this.props.data });
    if (this.props.tableName === "initialAssesment") {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }
  search_onChange(e) {
    let value = e.target.value;
    // this.setState({ textSearch: value })
    let data = this.props.data;
    let filterData = data.filter((item) => {
      let str = getValues(item); //JSON.stringify(item).toLowerCase().replace(/{|}|\[|\]|,|"|'|:/gi, (x) => "");
      //  console.log(str);
      return str.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({ filterData, textSearch: value });
    // if(value!==this.props.searchValue)
    this.props.onSearchValueChanged(value);
  }
  onDateChange(e) {
    const target = e.target;
    this.setState({ startDate: target.value.start, endDate: target.value.end });
  }

  onSearchWithInDate() {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) {
      const from = moment(startDate).format("YYYY-MM-DD");
      const to = moment(endDate).format("YYYY-MM-DD");
      this.setState({ searchToggle: true });
      //dispatch(patientActions.getByDate(from,to))
      if (this.props.onDateWiseSearch) this.props.onDateWiseSearch(from, to);
    }
  }

  onClearDateFilter() {
    //this.setState({searchToggle:false})
    // const {dispatch}=this.props;
    // dispatch(patientActions.getAll());
    if (this.props.onClear) this.props.onClear();
  }

  onScanComplete(data) {
    alert(JSON.stringify(data));
    if (data.patient_uhid) this.setState({ searchValue: data.patient_uhid });
    else {
      this.setState({ searchValue: "" });
    }
  }

  componentDidUpdate(p, s) {
    // if(p.data.length!=this.props.data.length && s.filterData!=null){
    //    // this.search_onChange({target:{value:this.state.textSearch}});
    //   // this.setState({  filterData:null, textSearch: '' });

    //   // this.props.onSearchValueChanged('')

    // }
    if (p.data !== this.props.data) {
      if (this.state.filterData.length > 0) {
        let oldDataKeys = this.state.filterData.map((item) => item._id);
        let newFilteredData = this.props.data.filter((item) =>
          oldDataKeys.includes(item._id)
        );

        this.setState({ filterData: newFilteredData });
      }
    }

    if (p.searchValue !== this.props.searchValue) {
      this.search_onChange({ target: { value: this.props.searchValue } });
    }
  }

  render() {
    let { columns, addButton, dateWiseSearch } = this.props;

    let data =
      this.state.filterData.length > 0
        ? this.state.filterData
        : this.props.data;
    data = data?.map((item, i) => {
      item["sno"] = i + 1;
      return item;
    });

    columns = [
      { name: "Sr. No.", sortable: true, width: "60px", selector: "sno" },
      ...columns,
    ];

    return (
      <div style={{ backgroundColor: "rgb(221 222 223)", padding: "5px" }}>
        <DataTable
          columns={columns || []}
          // theme="solarized"
          //customStyles={}
          keyField="id"
          data={data}
          pagination={this.props.pagination ? false : true}
          style={{ minHeight: this.props.minHeight || 600 }}
          // responsive={true}
          expandableRows={this.props.expandableRows}
          expandOnRowClicked={this.props.expandOnRowClicked}
          expandableRowsComponent={this.props.expandableRowsComponent}
          highlightOnHover={this.props.expandOnRowClicked}
          conditionalRowStyles={this.props.conditionalRowStyles}
          dense
          noHeader
          striped
          subHeader={this.props.subHeader}
          paginationPerPage={this.props.pageSize || 25}
          paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
          subHeaderComponent={
            <>
              <Row
                style={{
                  backgroundColor: "rgb(221 222 223)",
                  padding: "0px 8px",
                }}
              >
                <Col md={addButton ? 3 : 4}>
                  <Input
                    bsSize="sm"
                    type="text"
                    style={{ minWidth: 200, marginTop: 30 }}
                    placeholder="Search"
                    value={this.props.searchValue}
                    onChange={this.search_onChange.bind(this)}
                    autoFocus={this.props.autoFocus}
                  />
                </Col>
                <Col md={addButton ? 4 : 6}>
                  {dateWiseSearch && (
                    <DateRangePicker
                      name="dateRange"
                      onChange={this.onDateChange.bind(this)}
                      onSubmit={this.onSearchWithInDate.bind(this)}
                      onClear={this.onClearDateFilter.bind(this)}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.minDate}
                      tableName={this.props.tableName}
                    />
                  )}
                </Col>

                <Col md={1}>
                  {this.props.enableQRScanner ? (
                    <QRScanner
                      onScanCompleted={this.onScanComplete.bind(this)}
                    />
                  ) : (
                    <div style={{ height: 70 }}></div>
                  )}
                </Col>

                {addButton ? (
                  <Col md={4} className="text-right">
                    <br />
                    {this.props.subHeaderComponent}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </>
          }
        />{" "}
      </div>
    );
  }
}
MyDataTable.defaultProps = {
  expandableRows: false,
  expandOnRowClicked: false,
  expandableRowsComponent: null,
  conditionalRowStyles: [],
  subHeader: false,
  enableQRScanner: false,
  dateWiseSearch: true,
  addButton: true,
  onSearchValueChanged: function() {},
};
export default MyDataTable;
