import classnames from "classnames";
import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import {
  Button,
  Col,
  ListGroup,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { consultantActions } from "../../../actions/consultant.actions";
import { appointmentType, appointmentStatus } from "../../../constants"
import Loader from "../../MyComponents/loader";
import AppointmentItem from "./AppointmentItem";
class TodaySchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      allAppointments: [],
    };
  }

  // On Component Loading
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(consultantActions.getAll());
  }

  toggle(val) {
    this.setState({ activeTab: val });
  }

  // On select Appointment
  onSelectAppointment(id, e) {
    if (this.props.onAppointmentSelected) {
      this.props.onAppointmentSelected({ id: id });
    }
  }

  // Handle Double Click 
  onDoubleClick(id, e) {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(id);
    }
  }

  render() {
    const { appointments, consultants } = this.props;
    let todaysAppointments = [],
      waitingAppointments = [],
      engagedAppointments = [],
      doneAppointments = [],
      abandonedAppointments = [],
      walkInAppointments = [];

    appointments.forEach((item) => {
        let element = (
        <AppointmentItem
          appointment={item}
          onClick={this.onSelectAppointment.bind(this, item)}
          onDoubleClick={this.onDoubleClick.bind(this, item)}
        />
      );
      todaysAppointments.push(element);
      if (item.appointment_type === appointmentType.Walkin) {
        walkInAppointments.push(element);
      }
      switch (item.status) {
        case appointmentStatus.Waiting:
          waitingAppointments.push(element);
          break;
        case appointmentStatus.Engaged:
          engagedAppointments.push(element);
          break;
        case appointmentStatus.Done:
          doneAppointments.push(element);
          break;
        case appointmentStatus.Cancelled:
          abandonedAppointments.push(element);
          break;
      }
    });

    return (
      <div style={{ border: "2px solid #E0F3FF" }}>
        <Col md={12}>
          <div style={{ backgroundColor: "white" }}>
            <Row>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                  color="link"
                >
                  Today
                  <br />
                  <div className="ml-auto badge badge-pill badge-info">
                    {todaysAppointments.length}
                  </div>
                </Button>
              </Col>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                  color="link"
                >
                  Waiting
                  <br />
                  <div className="ml-auto badge badge-pill badge-warning">
                    {waitingAppointments.length}
                  </div>
                </Button>
              </Col>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                  color="link"
                >
                  Engaged
                  <br />
                  <div className="ml-auto badge badge-pill badge-warning">
                    {engagedAppointments.length}
                  </div>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  style={{ marginTop: "-4px" }}
                  onClick={() => {
                    this.toggle("4");
                  }}
                  color="link"
                >
                  Done
                  <br />
                  <div className="ml-auto badge badge-pill badge-warning">
                    {doneAppointments.length}
                  </div>
                </Button>
              </Col>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "5",
                  })}
                  style={{ padding: 2 }}
                  onClick={() => {
                    this.toggle("5");
                  }}
                  color="link"
                >
                  Cancelled
                  <br />
                  <div className="ml-auto badge badge-pill badge-warning">
                    {abandonedAppointments.length}
                  </div>
                </Button>
              </Col>
              <Col md={4} className="schedule-tabs">
                <Button
                  className={classnames({
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggle("6");
                  }}
                  color="link"
                >
                  Walk In
                  <br />
                  <div className="ml-auto badge badge-pill badge-info">
                    {walkInAppointments.length}
                  </div>
                </Button>
              </Col>
            </Row>
          </div>

          <div className="scroll-area-lg">
            {this.props.loading ? (
              <Loader />
            ) : (
              <PerfectScrollbar>
                <div>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <ListGroup>{todaysAppointments}</ListGroup>
                    </TabPane>
                    <TabPane tabId="2">{waitingAppointments}</TabPane>
                    <TabPane tabId="3">{engagedAppointments}</TabPane>
                    <TabPane tabId="4">{doneAppointments}</TabPane>
                    <TabPane tabId="5">{abandonedAppointments}</TabPane>
                    <TabPane tabId="6">{walkInAppointments}</TabPane>
                  </TabContent>
                </div>
              </PerfectScrollbar>
            )}{" "}
          </div>
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { appointments, consultants } = state;

  return {
    appointments: appointments.appointments,
    loading: appointments.loading,
    consultants: consultants.consultants,
  };
}

const connectedTodaySchedule = connect(mapStateToProps)(TodaySchedule);
export default connectedTodaySchedule;
