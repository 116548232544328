import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Table,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { serviceMasterActions } from "../../../../actions";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faSearch,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";

class ModalExample1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nestedModal: false,
      closeAll: false,
      selectedState: [],
      totalAmount: null,
      type: props.type,
      gridType: "services",
    };

    this.toggle = this.toggle.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
  }

  onTypeChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    let { dispatch } = this.props;
    dispatch(serviceMasterActions.getAllPackages());
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggleNested() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false,
    });
  }
  toggleAll() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true,
    });
  }
  onChange(e, data) {
    let selectedState = [...this.state.selectedState];
    if (e.target.checked) {
      data.isChecked = true;
      selectedState.push(data);
      this.setState({
        selectedState: selectedState,
        totalAmount:
          this.state.gridType === "services"
            ? this.state.totalAmount + data.SERVICE_AMT
            : this.state.totalAmount + data.PACKAGE_AMT,
      });
    } else {
      let index = selectedState.indexOf(data);
      if (index !== -1) {
        data.isChecked = false;
        selectedState.splice(index, 1);
        this.setState({
          selectedState: selectedState,
          totalAmount:
            this.state.gridType === "services"
              ? this.state.totalAmount - data.SERVICE_AMT
              : this.state.totalAmount - data.PACKAGE_AMT,
        });
      }
    }
  }

  isChecked(data, index, type) {
    let selectedState = [...this.state.selectedState];
    let selectedService = selectedState.find(
      (service) => service._id === data._id
    );
    if (selectedService) {
      return true;
    } else {
      return false;
    }
  }

  onfilterTextChange(e) {
    this.setState({
      filterText: e.target.value,
    });
  }

  handleServiceChange() {
    this.toggle();
    this.props.handleServiceChange(
      this.state.selectedState,
      this.state.totalAmount
    );
    this.setState({
      selectedState: [],
      totalAmount: 0,
    });
  }

  render() {
    let { serviceMaster } = this.props;
    let serviceList = null;
    let tableRows;
    let displayServiceList = [];
    let displayPackageList = [];
    let serviceColumns = [
      {
        name: "Select",
        sortable: true,
        cell: (data) => (
          <Input
            type="checkbox"
            style={{ margin: "0px", position: "relative" }}
            checked={data.isChecked}
            onChange={(e) => {
              this.onChange(e, data);
            }}
          ></Input>
        ),
        width: "100px",
      },
      {
        name: "SR. No",
        sortable: true,
        selector: (data) => data.count,
        width: "100px",
      },

      {
        name: "Service Name",
        sortable: true,
        selector: (data) => `${data.SERVICE_NAME}`,
        width: "225px",
      },

      {
        name: "Service Amount",
        sortable: true,
        cell: (data) => (
          <NumberFormat
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            prefix={"₹"}
            displayType={"text"}
            value={data.SERVICE_AMT}
          />
        ),
        width: "150px",
      },
    ];
    let packageColumns = [
      {
        name: "Select",
        sortable: true,
        cell: (data) => (
          <Input
            type="checkbox"
            style={{ margin: "0px", position: "relative" }}
            checked={data.isChecked}
            onChange={(e) => {
              this.onChange(e, data);
            }}
          ></Input>
        ),
        width: "100px",
      },
      {
        name: "SR. No",
        sortable: true,
        selector: (data) => data.count,
        width: "100px",
      },

      {
        name: "Package Name",
        sortable: true,
        selector: (data) => `${data.PACKAGE_NAME}`,
        width: "225px",
      },

      {
        name: "Package Amount",
        sortable: true,
        cell: (data) => (
          <NumberFormat
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            prefix={"₹"}
            displayType={"text"}
            value={data.PACKAGE_AMT}
          />
        ),
        width: "150px",
      },
    ];
    if (serviceMaster) {
      if (serviceMaster.services) {
        let filterArray;
        let count = 0;
        if (this.props.selectedState)
          filterArray = serviceMaster.services.filter(
            (object) => !this.props.selectedState.includes(object)
          );
        else filterArray = serviceMaster.services;
        filterArray.map((data, index) => {
          if (this.state.filterText) {
            let filterText = this.state.filterText.toLowerCase();
            if (
              data.USERDEFINED_NAME.toLowerCase().includes(filterText) ||
              data.SERVICE_NAME.toLowerCase().includes(filterText) ||
              (data.SERVICE_AMT ? data.SERVICE_AMT : 0)
                .toString()
                .toLowerCase()
                .includes(filterText)
            ) {
              // console.log(data);
              if (data.isActive && !data.isDeleted) {
                data.checked = this.isChecked(data, index, "services");
                count = count + 1;
                data.count = count;
                data.type = "services";
                displayServiceList.push(data);
              }
            } else {
            }
          } else {
            if (data.isActive && !data.isDeleted) {
              count = count + 1;
              data.count = count;
              data.type = "services";
              data.checked = this.isChecked(data, index, "services");
              displayServiceList.push(data);
            }
          }
        });
      }

      if (serviceMaster.packages) {
        let filterArray;
        let count = 0;
        if (this.props.selectedState)
          filterArray = serviceMaster.packages.filter(
            (object) => !this.props.selectedState.includes(object)
          );
        else filterArray = serviceMaster.packages;
        filterArray.map((data, index) => {
          if (this.state.filterText) {
            let filterText = this.state.filterText.toLowerCase();
            if (
              data.USERDEFINED_NAME.toLowerCase().includes(filterText) ||
              data.PACKAGE_NAME.toLowerCase().includes(filterText) ||
              (data.PACKAGE_AMT ? data.PACKAGE_AMT : 0)
                .toString()
                .toLowerCase()
                .includes(filterText)
            ) {
              // console.log(data);
              if (data.isActive && !data.isDeleted) {
                data.checked = this.isChecked(data, index, "packages");
                count = count + 1;
                data.count = count;
                data.type = "packages";
                displayPackageList.push(data);
              }
            } else {
            }
          } else {
            if (data.isActive && !data.isDeleted) {
              count = count + 1;
              data.count = count;
              data.type = "packages";
              data.checked = this.isChecked(data, index, "packages");
              displayPackageList.push(data);
            }
          }
        });
      }
    }

    const ModalClasses = `${this.props.className} uploadmodal`;
    return (
      <span className="d-inline-block mb-2 mr-2">
        <Button
          outline
          color="primary"
          onClick={this.toggle}
          style={{ marginTop: "27%" }}
        >
          Add Services List
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={ModalClasses}
        >
          <ModalBody>
            <Row>
              <Col
                style={
                  this.props.type === "packages"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <Input
                  type="select"
                  value={this.state.gridType}
                  name="gridType"
                  onChange={this.onTypeChange}
                >
                  <option value="services">Services</option>
                  <option value="packages">Packages</option>
                </Input>
              </Col>

              <Col>
                <h4>
                  <strong>
                    {this.state.gridType === "services"
                      ? "Services List"
                      : "Package List"}
                  </strong>
                </h4>
              </Col>
              <Col>
                <Input
                  placeholder="Search"
                  name="filterText"
                  id="filterText"
                  onChange={(e) => {
                    this.onfilterTextChange(e);
                  }}
                ></Input>
                <FontAwesomeIcon
                  style={
                    this.props.type === "packages"
                      ? {
                          fontSize: "20px",
                          marginTop: " -30px",
                          position: "absolute",
                          marginLeft: "204px",
                        }
                      : {
                          fontSize: "20px",
                          marginTop: " -30px",
                          position: "absolute",
                          marginLeft: "155px",
                        }
                  }
                  icon={faSearch}
                  size="1x"
                />
              </Col>
              <Col style={{ float: "right", textAlign: "center" }}>
                <Button color="primary" onClick={this.handleServiceChange}>
                  Submit
                </Button>
                <Button color="link" onClick={this.toggle}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <hr></hr>
            <DataTable
              keyField="_id"
              pagination={true}
              expandableRows={false}
              expandOnRowClicked={false}
              dense
              noHeader
              striped
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 30, 45, 60]}
              columns={
                this.state.gridType === "services"
                  ? serviceColumns
                  : packageColumns
              }
              data={
                this.state.gridType === "services"
                  ? displayServiceList
                  : displayPackageList
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleServiceChange}>
              Submit
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

function mapStateToProps(state) {
  const { serviceMaster } = state;
  return { serviceMaster: serviceMaster.data };
}

const ModalExample = connect(mapStateToProps)(ModalExample1);

export default ModalExample;
