import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { apiUrl } from "../../constants/config";
import { referralService } from "../../services";

class PatientInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedReferral: null };
  }

  componentDidMount() {
    const { referrals, selected_patient } = this.props;
    console.log("Referrals", referrals);
    if (selected_patient) {
      const selectedReferral = referrals
        ? referrals.find(
            (item) => item.code === selected_patient.referral_doctor
          )
        : null;

      this.setState({
        selectedReferral: selectedReferral,
      });
    }
  }
  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const selected_patient = this.props.patient;
    const { referrals } = this.props;
    let selectedReferral;
    if (selected_patient)
      selectedReferral = referrals.find(
        (item) => item.code === selected_patient.referral_doctor
      );

    return (
      <div style={{ lineHeight: "normal" }}>
        <div>
          <div style={{ display: "inline-block" }}>
            <div>
              <label className="report-label-key">Patient UHID </label>
              <label className="report-label">
                : {selected_patient.patient_uhid}
              </label>
            </div>
            <div>
              <label className="report-label-key">Registration Date </label>
              <label className="report-label">
                : {moment(selected_patient.reg_date).format("DD/MM/YYYY")}
              </label>
            </div>
            <div>
              <label className="report-label-key">Patient Name </label>
              <label
                className="report-label capitalize"
                style={{
                  width: "137px",
                  fontSize: "12px",
                  textOverflow: "ellipses",
                }}
              >
                {" "}
                : {this.codeToValue(selected_patient.title_code, "titles")}{" "}
                {selected_patient.firstname +
                  " " +
                  (selected_patient.middlename || "") +
                  " " +
                  (selected_patient.lastname || "")}
              </label>
            </div>
            <div>
              <label className="report-label-key">Gender </label>
              <label className="report-label capitalize">
                {" "}
                : {this.codeToValue(selected_patient.gender_code, "genders")}
              </label>
            </div>
            <div>
              <label className="report-label-key">Marital Status </label>
              <label className="report-label capitalize">
                {" "}
                :{" "}
                {this.codeToValue(
                  selected_patient.marital_status,
                  "marital_statuses"
                )}
              </label>
            </div>
            <div>
              <label className="report-label-key">Date of birth </label>

              <label className="report-label">
                {" "}
                : &nbsp;{moment(selected_patient.dob).format("DD/MM/YYYY")}
              </label>
            </div>
          </div>
          <div style={{ display: "inline-block", float: "right" }}>
            <div
              style={{
                width: "120px",
                height: "120px",
              }}
            >
              <img
                src={apiUrl + selected_patient.photo}
                style={{
                  width: "100%",
                  height: "auto",
                  border: "1px solid black",
                  maxWidth: 120,
                }}
              />
            </div>
            {this.props.showQR && (
              <div
                style={{
                  width: "120px",
                  height: "120px",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={apiUrl + "/" + selected_patient.qrcode}
                />
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="report-label-key">Age </label>
          <label className="report-label" style={{ fontSize: "12px" }}>
            : &nbsp;
            {selected_patient.age_in_years ? (
              <>
                {selected_patient.age_in_years} &nbsp; <b> Y </b>
              </>
            ) : (
              ""
            )}
            &nbsp;
            {selected_patient.age_in_months ? (
              <>
                {selected_patient.age_in_months}&nbsp;<b> M</b>
              </>
            ) : (
              ""
            )}{" "}
            &nbsp;
            {selected_patient.age_in_days ? (
              <>
                {selected_patient.age_in_days}&nbsp;<b>D</b>
              </>
            ) : (
              ""
            )}
          </label>
        </div>

        <div>
          <label className="report-label-key">Mobile No. </label>
          <label className="report-label"> : {selected_patient.mobile}</label>
        </div>
        <div>
          <label className="report-label-key">Email </label>
          <label className="report-label"> : {selected_patient.email}</label>
        </div>
        <div>
          <div
            style={{
              float: "left",
              width: "100px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Address
          </div>
          <div
            className="ellipses"
            style={{
              float: "left",
              width: "250px",
              fontFamily: "arial",
              color: "black",
              maxHeight: 70,
              fontSize: "14px",
            }}
          >
            {" "}
            : {selected_patient.permanent_address}{" "}
          </div>
          <div style={{ clear: "both" }}></div>
        </div>

        <div>
          <label className="report-label-key">Nationality/ Religion </label>
          <label className="report-label capitalize">
            {" "}
            :{" "}
            {this.codeToValue(
              selected_patient.nationality_code,
              "nationalities"
            )}
            / {this.codeToValue(selected_patient.religion_code, "religions")}
          </label>
        </div>
        {/* <div  style={{float:'left',width:'50%'}}>
                      <label className="report-label-key">Nationality </label>
                      <label className="report-label">: {this.codeToValue(selected_patient.nationality_code,'nationalities')}</label>
                    </div> */}
        {/* </div> */}
        <div>
          <div>
            <label className="report-label-key">Occupation / Bloodgroup</label>
            <label className="report-label">
              {" "}
              : {this.codeToValue(
                selected_patient.occupation,
                "occupations"
              )} /{" "}
              {this.codeToValue(
                selected_patient.blood_group_code,
                "bloodgroups"
              )}
            </label>
          </div>

          <div>
            <label className="report-label-key">Relative Info</label>
            <label className="report-label">
              {" "}
              : {selected_patient.relative_name} /{" "}
              {selected_patient.secondary_contact}
            </label>
          </div>
        </div>

        <div>
          {" "}
          <label className="report-label-key">ID Type / Number : </label>
          <label className="report-label capitalize">
            {" "}
            {this.codeToValue(
              selected_patient.id_type1,
              "identifications"
            )}{" "}
            &nbsp;[ {selected_patient.id_number1}]
          </label>
        </div>

        <div>
          <img
            src={apiUrl + selected_patient.id_document1}
            style={{ height: 100 }}
          />
        </div>

        <div>
          <label className="report-label-key">Referral Information </label>
        </div>
        <div>
          <label className="report-label-key">Referral Source </label>
          <label className="report-label">
            {" "}
            :{" "}
            {this.codeToValue(
              selected_patient.reason_of_knowing,
              "how_did_you_know_us"
            )}
          </label>
        </div>
        <div>
          <label className="report-label-key">Referral Name </label>
          <label className="report-label">
            {" "}
            :{" "}
            {selectedReferral
              ? selectedReferral.name
              : selected_patient
              ? selected_patient.referral_doctor
              : ""}
          </label>
        </div>
        <div>
          {" "}
          <label className="report-label-key">Referral Phone </label>
          <label className="report-label">
            {" "}
            : {selected_patient.referral_phone}
          </label>
        </div>
      </div>
    );
  }
}

class RegistrationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consentText: "",
      referrals: [],
      selected_patient: null,
      selected_partner: null,
    };
  }

  componentDidMount() {
    const { dispatch, patient, partner, couple } = this.props;

    if (patient) {
      this.setState({ selected_patient: patient });
    }
    if (partner) {
      this.setState({ selected_partner: partner });
    }

    referralService.getAll().then((res) => {
      if (res.success) {
        this.setState({ referrals: res.referrals });
      }
    });
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  render() {
    const { selected_patient, partner_patient } = this.state;

    return (
      <Fragment>
        <div ref={(el) => (this.componentRef = el)}>
          {selected_patient && this.props.couple && partner_patient ? (
            <div name="coupleReport">
              <div className="padd-20 border" style={{ paddingBottom: 0 }}>
                <div>
                  <div style={{ float: "left", width: "48%" }}>
                    <PatientInfo
                      showQR={true}
                      patient={
                        selected_patient.gender_code === "1"
                          ? partner_patient
                          : selected_patient
                      }
                      masters={this.props.masters}
                      referrals={this.state.referrals}
                    />
                  </div>
                  <div
                    style={{
                      float: "left",
                      height: "600px",
                      width: 1,
                      margin: 10,
                      backgroundColor: "black",
                    }}
                  ></div>
                  <div style={{ float: "left", width: "48%" }}>
                    <PatientInfo
                      showQR={true}
                      patient={
                        partner_patient.gender_code === "2"
                          ? selected_patient
                          : partner_patient
                      }
                      masters={this.props.masters}
                      referrals={this.state.referrals}
                    />
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="line"></div>
            </div>
          ) : selected_patient ? (
            <div name="individualReport">
              <PatientInfo
                showQR={true}
                patient={selected_patient}
                masters={this.props.masters}
                referrals={this.state.referrals}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { registrationMaster } = state;

  return { masters: registrationMaster.data };
}

const connectedRegistrationDetails = connect(mapStateToProps)(
  RegistrationDetails
);

export default connectedRegistrationDetails;
