import { registrationWithFeesConstants } from "../constants";

export function registrationWithFeesMaster(state = {}, action) {
  switch (action.type) {
    case registrationWithFeesConstants.ADD_REGISTRATION_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.ADD_REGISTRATION_FEES_SUCCESS:
      let registrationfees = [];
      if (state.registrationfees) {
        registrationfees = [...state.registrationfees];
      }
      registrationfees.push(action.data);
      return { ...state, loading: false, registrationfees: registrationfees };
    case registrationWithFeesConstants.ADD_REGISTRATION_FEES_FAILURE:
      return { ...state, loading: false };

    case registrationWithFeesConstants.GET_REGISTRATION_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.GET_REGISTRATION_FEES_SUCCESS:
      return { ...state, loading: false, registrationfees: action.data.data };
    case registrationWithFeesConstants.GET_REGISTRATION_FEES_FAILURE:
      return { ...state, loading: false };
    case registrationWithFeesConstants.DELETE_REGISTRATION_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.DELETE_REGISTRATION_FEES_SUCCESS:
      let deleteregistrationfees = [];
      if (state.registrationfees) {
        // state.registrationfees.splice(action.data.index,1, action.data)
        // deleteregistrationfees = [...state.registrationfees];

        // state.registrationfees[action.data.index].isDeleted = action.data.isDeleted
        // state.registrationfees[action.data.index].isActive = action.data.isActive

        state.registrationfees[action.data].deleted = !state.registrationfees[
          action.data
        ].deleted;
        //state.registrationfees[action.data].isActive = !state.registrationfees[action.data].isActive
      }
      return {
        ...state,
        loading: false,
        registrationfees: [...state.registrationfees],
      };
    case registrationWithFeesConstants.DELETE_REGISTRATION_FEES_FAILURE:
      return { ...state, loading: false };
    case registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_SUCCESS:
      let deactiveregistrationfees = [];
      if (state.registrationfees) {
        deactiveregistrationfees = [...state.registrationfees];
        deactiveregistrationfees.splice(action.data, 1);
      }
      return {
        ...state,
        loading: false,
        registrationfees: deactiveregistrationfees,
      };
    case registrationWithFeesConstants.DEACTIVE_REGISTRATION_FEES_FAILURE:
      return { ...state, loading: false };
    case registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_SUCCESS:
      let updateregistrationfees = [];
      if (state.registrationfees) {
        updateregistrationfees = [...state.registrationfees];
        updateregistrationfees[action.index] = action.data;
      }
      return {
        ...state,
        loading: false,
        registrationfees: updateregistrationfees,
      };
    case registrationWithFeesConstants.UPDATE_REGISTRATION_FEES_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
