//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const consultantService = {
  getAll,
  getById,
  add,
  update,
  remove,
  setDefault,
  blockCalendar,
  search,
  getEarning,
};

function getAll(viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/consultants?viewDeleted=${viewDeleted}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getEarning(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/newconsultant/earning?consultant_code=${code}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function search(data) {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
    body: data,
  };
  return fetch(`${apiUrl}/newconsultant/search`, requestOptions).then(
    helperService.handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/newconsultant/getbyId/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByUHID(uhid) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/newconsultant/${uhid}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/consultants`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/consultants/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function setDefault(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/newconsultant/setFavorite`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };

  return fetch(`${apiUrl}/consultants/delete/${data}`, requestOptions).then(
    helperService.handleResponse
  );
}

function blockCalendar(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/consultants/blockCalendar`, requestOptions).then(
    helperService.handleResponse
  );
}
