import { newMasterConstants } from '../../constants';
import { alertActions } from '../alert.actions';
import { roleService } from '../../services';
import {
  toast,
  Bounce
} from 'react-toastify';


export const roleActions = {
  getAll,
  getRoleData,
  deleteRoleData,
  statusUpdateData
};

function getAll() {
  return dispatch => {
    dispatch(request());
    roleService.getAll()
      .then(
        data => {
          if (data.success) {
              console.log(data);
            dispatch(success(data.data))
            toast(data.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
            dispatch(alertActions.success('role master loaded'))
          }
          else {
            dispatch(failure(data.err))
            toast("Something went wrong", {
              transition: Bounce,
              closeButton: true,
              autoClose: 3000,
              position: "bottom-center",
              type: "error",
            });
          }
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: newMasterConstants.GET_ALL_ROLE_REQUEST } }
  function success(role) { return { type: newMasterConstants.GET_ALL_ROLE_SUCCESS, role } }
  function failure(error) { return { type: newMasterConstants.GET_ALL_ROLE_FAILURE, error } }
}

function getRoleData(role) {
  return dispatch => {
    dispatch(request());
    roleService.getRoleData(role)
      .then(
        data => {
          if (data) {
            dispatch(success(data));
          
            dispatch(alertActions.success('role data loaded'))
          }
          else dispatch(failure(data.err))
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: newMasterConstants.GET_ROLE_DATA_REQUEST } }
  function success(role) { return { type: newMasterConstants.GET_ROLE_DATA_SUCCESS, role } }
  function failure(error) { return { type: newMasterConstants.GET_ROLE_DATA_FAILURE, error } }
}

function deleteRoleData(payload) {
  return dispatch => {
    dispatch(request());
    roleService.deleteRoleData(payload)
      .then(
        data => {
          if (data) {
            dispatch(success(data.data));
            toast(data.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 3000,
              position: "bottom-center",
              type: "success",
            });
            dispatch(alertActions.success('Role Deleted Successfully'))
          }
          else dispatch(failure(data.err))
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: newMasterConstants.DELETE_ROLE_DATA_REQUEST } }
  function success(role) { return { type: newMasterConstants.DELETE_ROLE_DATA_SUCCESS, role } }
  function failure(error) { return { type: newMasterConstants.DELETE_ROLE_DATA_FAILURE, error } }
}

function statusUpdateData(payload) {
  return dispatch => {
    dispatch(request());
    roleService.statusUpdate(payload)
      .then(
        data => {
          if (data) {
            dispatch(success(data.data));
            toast(data.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 3000,
              position: "bottom-center",
              type: "success",
            });
          }
          else {
            dispatch(failure(data.err))
            toast("Something went wrong", {
              transition: Bounce,
              closeButton: true,
              autoClose: 3000,
              position: "bottom-center",
              type: "error",
            });
          }
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: newMasterConstants.STATUS_UPDATE_ROLE_DATA_REQUEST } }
  function success(role) { return { type: newMasterConstants.STATUS_UPDATE_DATA_SUCCESS, role } }
  function failure(error) { return { type: newMasterConstants.STATUS_UPDATE_DATA_FAILURE, error } }
}