import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import MetisMenu from "react-metismenu";
import { connect } from "react-redux";
import $ from "jquery";
import { get_menus } from "../../menu";
import "../AppMain/icons.css";
class NavList extends Component {
  state = {
    menu: [],
    navData: [],
  };

  componentDidMount() {}

  componentWillUpdate(np, ns) {
    // let orgData = sessionStorage.getItem("orgInfo");
    // if (orgData) {
    //   orgData = JSON.parse(orgData);
    // }
    // if (this.props.menus.menu.length !== np.menus.menu.length) {
    //   const menus = np.menus.menu;
    //   console.log(menus);
    //   console.log(filteredContent);
    //   let parents = menus.filter((menu) => menu.parent == null);
    //   let navData = get_menus(menus, parents);
    //   if (orgData?._id !== "6516cad2c5bb548b893f3b70") {
    //     function updateLabel(jsonArray, condition, newLabel, newToValue) {
    //       for (var i = 0; i < jsonArray.length; i++) {
    //         if (condition(jsonArray[i])) {
    //           jsonArray[i].label = newLabel;
    //           jsonArray[i].to = newToValue;
    //         }
    //       }
    //     }
    //     updateLabel(
    //       navData,
    //       function(item) {
    //         return item.label === "Organizations";
    //       },
    //       "Locations",
    //       "#/org_loactions"
    //     );
    //     var updatedJsonString = navData;
    //     navData = updatedJsonString;
    //   }
    //   this.setState({ navData });
    //   window.setTimeout(() => {
    //     console.log(this.state.navData);
    //   }, 3000);
    // }
    // if (this.props.menus.menu.length !== np.menus.menu.length) {
    //   const navData = get_menus(this.props.menus.menu);
    //   //window.setTimeout(() => {
    //   console.log(navData);
    //   //}, 10000);
    //   this.setState({ navData });
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menus.menu !== this.props.menus.menu) {
      const navData = get_menus(this.props.menus.menu);
      this.setState({ navData });
    }
  }

  render() {
    $("icons_style").empty();
    return (
      <Fragment>
        <MetisMenu
          content={this.state.navData}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
function mapStateToProps(state) {
  const { menu, newMaster } = state;
  console.log(state);
  return { menus: menu, newMaster: newMaster };
}

const Nav = connect(mapStateToProps)(withRouter(NavList));

export default Nav;
