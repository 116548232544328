import React, { Fragment, Component } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  faEye,
  faEllipsisV,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientListHeadBar from "./PatientListHeadBar";
import AdmissionCancelModal from "./AdmissionCancelModal";
import { httpHelperService } from "../../../services/http.helper.service";
import { Bounce, toast } from "react-toastify";
import DataTable from "../../MyComponents/MyDataTable";
import moment from "moment";
import { patientActions } from "../../../actions/patient.actions";
import { connect } from "react-redux";
import { wardManagementActions } from "../../../actions/wardmanagement.actions";
import { registrationMasterActions } from "../../../actions/registrationMaster.actions";
import { loginservice } from "../../../services/login.service";
import { consultantActions } from "../../../actions/consultant.actions";

class PatientListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      modal: false,
      selectPatient: null,
      isAuthorized: false,
    };
    this.onEditClick = this.onEditClick.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(patientActions.getAll());
    dispatch(wardManagementActions.getBedAll());
    dispatch(registrationMasterActions.getAll());
    dispatch(consultantActions.getAll());

    let url = "api/ipadmission/getAllAdmittedPatient";
    httpHelperService
      .getRequest(url)
      .then((res) => {
        console.log(res);
        if (res.success) {
          const result = res.data.filter(
            (value) => value.patient_admission_status === "admitted"
          );
          this.setState({ items: result });
          toast(res.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange() {
    // setChecked(!checked);
  }

  onEditClick(row) {
    let patientId = Array.isArray(row?.patient_id)
      ? row.patient_id[0]?._id
      : row.patient_id;
    this.setState({
      mode: "EDIT",
      selectPatient: { ...row, patientId: patientId },
    });
    this.toggle();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  // onEditClick(row) {
  //   const { dispatch } = this.props;
  //   dispatch(patientActions.selectPatient(row.patient_id));
  //   this.props.history.push({
  //     pathname: `/in-patient-department/ipd-admission/ipd-patient-form`,
  //     state: {
  //       // type: this.state.type,
  //       mode: "Edit",
  //       data: row,
  //     },
  //   });
  // }

  onViewClicked(row) {
    const { dispatch } = this.props;
    let patientId = Array.isArray(row?.patient_id)
      ? row.patient_id[0]?._id
      : row.patient_id;
    dispatch(patientActions.selectPatient(patientId));
    this.props.history.push({
      pathname: `/in-patient-department/ipd-admission/ipd-patient-form`,
      state: {
        mode: "View",
        data: row,
      },
    });
  }

  bedToValue(code, name) {
    try {
      const { wardbed } = this.props;
      if (wardbed) return wardbed.find((item) => item.code === code).bed_name;
      else return code;
    } catch {
      return code;
    }
  }

  patientToValue(uhid, name) {
    try {
      const { patients, masters } = this.props;
      let patientDetail = {};
      console.log(patients, "patientlists");
      if (patients)
        patientDetail = patients.find((item) => item.patient_uhid === uhid);
      else return uhid;
      console.log(patientDetail, "patientdetails");
      if (patientDetail && name === "genders") {
        return masters[name].list.find(
          (item) => item.code === patientDetail.gender_code
        ).name;
      }
      if (patientDetail && name === "patient_name") {
        return (
          patientDetail?.firstname +
          " " +
          patientDetail?.middlename +
          " " +
          patientDetail?.lastname
        );
      }
      if (patientDetail && name === "mobile") {
        return patientDetail.mobile;
      }
      if (patientDetail && name === "consultant") {
        return this.consultantToValue(patientDetail?.consultant_id);
      }
    } catch {
      return uhid;
    }
  }

  consultantToValue(code) {
    try {
      const { consultants } = this.props;
      if (consultants) {
        const consName = consultants.find((item) => item.code === code);
        return consName.firstname + " " + consName?.lastname || "";
      } else return code;
    } catch {
      return code;
    }
  }

  onChangeText(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  onLogin() {
    const { username, password, reason, mode, selectPatient } = this.state;

    const { dispatch } = this.props;
    const payload = {
      username,
      password,
      reason,
      // , login_type: "consultant"
    };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          if (mode === "EDIT") {
            dispatch(patientActions.selectPatient(selectPatient?.patientId));
            this.props.history.push({
              pathname: "/in-patient-department/ipd-admission/ipd-patient-form",
              state: {
                mode: "EDIT",
                data: selectPatient,
              },
            });
          }
          // else if (mode == "VIEW") {
          //   dispatch(patientActions.selectPatient(selectPatient._id));
          //   this.props.history.push({
          //     pathname: "/patient-registration/transactions/new-patient",
          //     state: {
          //       mode: "VIEW",
          //     },
          //   });
          // }
          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  onDateWiseSearch(from, to) {
    if (from && to) {
      let url = "api/ipadmission/getAllAdmittedPatient";
      httpHelperService
        .getWithDateRequest(url, from, to)
        .then((res) => {
          console.log(res);
          if (res.success) {
            const result = res.data.filter(
              (value) => value.patient_admission_status === "admitted"
            );
            this.setState({ items: result });
            toast(res.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({ searchToggle: true });
    }
  }

  columns = [
    {
      name: "Patient Name",
      sortable: true,
      selector: (row) =>
        Array.isArray(row?.patient_id)
          ? row?.patient_id[0]?.firstname +
            " " +
            row?.patient_id[0]?.middlename +
            " " +
            row?.patient_id[0]?.lastname
          : this.patientToValue(row.patient_uhid, "patient_name"),
      width: "130px",
    },
    {
      name: "UHID",
      selector: "patient_uhid",
      sortable: true,
      width: "130px",
    },
    {
      name: "IP No.",
      selector: "ipd_admission_no",
      sortable: true,
      width: "150px",
    },
    {
      name: "Age",
      selector: "age",
      width: "50px",
      sortable: true,
    },
    {
      name: "Gender",
      selector: (col) =>
        Array.isArray(col?.patient_id)
          ? this.codeToValue(col?.patient_id[0]?.gender_code, "genders")
          : this.patientToValue(col.patient_uhid, "genders"),
      width: "100px",
      sortable: true,
    },
    {
      name: "Admission Date",
      selector: (row) => moment(row.admission_date).format("DD/MM/YYYY"),
      width: "150px",
      sortable: true,
    },
    {
      name: "Mode",
      selector: (row) => row.payment_type || "Self",
      width: "130px",
      sortable: true,
    },
    {
      name: "Bed",
      selector: (row) => this.bedToValue(row.bed_code),
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "150px",
    },
    {
      name: "Consultant",
      selector: (row) =>
        Array.isArray(row?.patient_id)
          ? this.consultantToValue(row?.patient_id[0]?.consultant_id)
          : this.patientToValue(row.patient_uhid, "consultant"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Mobile",
      selector: (col) =>
        Array.isArray(col?.patient_id)
          ? col?.patient_id[0]?.mobile
          : this.patientToValue(col.patient_uhid, "mobile"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Refundable Advance",
      selector: (row) => row?.ipdadvance[0]?.refund_total_amount || 0,
      sortable: true,
      width: "150px",
    },
    {
      name: "General Advance",
      selector: (row) => row?.ipdadvance[0]?.total_amount || 0,
      sortable: true,
      width: "150px",
    },
    {
      name: "Actions",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <a
            style={{ padding: 3, margin: 2 }}
            onClick={() => {
              this.onEditClick(row);
            }}
            title="Edit"
            className="text-warning font-icon-wrapper "
          >
            {" "}
            <FontAwesomeIcon icon={faUserEdit} size="1x" />
          </a>
          <a
            style={{ padding: 3, margin: 2 }}
            onClick={() => {
              this.onViewClicked(row);
            }}
            title="View"
            className="text-primary font-icon-wrapper "
          >
            {" "}
            <FontAwesomeIcon icon={faEye} size="1x" />{" "}
          </a>
          {/* <a>
            {row.isDeleted ==== false ? (
              <img src={unDeleteIcon} className="delete-icon" />
            ) : (
              <img src={deleteIcon} className="delete-icon" />
            )}
          </a> */}
          <UncontrolledButtonDropdown>
            <DropdownToggle color="basic" style={{ padding: 0 }}>
              {/* <FontAwesomeIcon icon={faEllipsisV} size="1x"/> */}
              <a
                style={{ padding: "3px 8px", margin: 2 }}
                title="View"
                className="text-primary font-icon-wrapper "
              >
                {" "}
                <FontAwesomeIcon icon={faEllipsisV} size="1x" />{" "}
              </a>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-left dropdown-menu-rounded dropdown-menu-hover-primary">
              <DropdownItem
              // onClick={() => {
              //   this.onViewPatientForm(row);
              // }}
              >
                Print Form
              </DropdownItem>
              <DropdownItem
              // onClick={() => {
              //   this.onViewPatientQRCode(row);
              // }}
              >
                Print QR Code
              </DropdownItem>
              <DropdownItem
              // onClick={() => {
              //   this.onViewPatientIDCard(row);
              // }}
              >
                Print ID Card
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
    {
      name: "Cancel Admit",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <>
          {/* <Button style={{ backgroundColor: "green" }}>Cancel Admit</Button> */}
          <AdmissionCancelModal rowData={row} />
        </>
      ),
    },
  ];
  // conditionalRowStyles = [
  //   {
  //     when: (row) => row.confidentiality_type ==== "VIP",
  //     style: {
  //       backgroundColor: "red",
  //       color: "white",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //     },
  //   },
  //   {
  //     when: (row) => row.confidentiality_type ==== "Confidential Disease",
  //     style: {
  //       backgroundColor: "blue",
  //       color: "white",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //     },
  //   },
  //   {
  //     when: (row) => row.confidentiality_type === "Celebrity",
  //     style: {
  //       backgroundColor: "orange",
  //       color: "white",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //     },
  //   },
  // ];

  render() {
    const { items } = this.state;
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PatientListHeadBar />
          {/* <AdmissionCancelModal /> */}
          {/* <PatientListBar /> */}
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <DataTable
                    columns={this.columns}
                    data={items}
                    conditionalRowStyles={this.conditionalRowStyles}
                    onRowClicked={(row) => {
                      this.onViewClicked(row);
                    }}
                    subHeader={true}
                    subHeaderComponent={<Row></Row>}
                    addButton={false}
                    onDateWiseSearch={(from, to) =>
                      this.onDateWiseSearch(from, to)
                    }
                    onClear={() => {
                      this.componentDidMount();
                    }}
                    enableQRScanner={true}
                    autoFocus={true}
                  />
                  <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.toggle()}
                    className={this.props.className}
                    style={{ width: 400 }}
                  >
                    <ModalHeader toggle={() => this.toggle()}>
                      Authentication Required!{" "}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={8} sm={8} className="offset-sm-1">
                          <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Username"
                              onChange={(e) => {
                                this.onChangeText(e);
                              }}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Password"
                              onChange={(e) => {
                                this.onChangeText(e);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="reason">Reason for Edit</Label>
                            <Input
                              type="text"
                              name="reason"
                              placeholder="Reason for Edit"
                              onChange={(e) => {
                                this.onChangeText(e);
                              }}
                            />
                          </FormGroup>
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={7}></Col>
                        <Col md={2}>
                          {" "}
                          <Button color="link" onClick={() => this.toggle()}>
                            Cancel
                          </Button>
                        </Col>
                        <Col md={2}>
                          {" "}
                          <Button
                            color="primary"
                            onClick={() => this.onLogin()}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    patients,
    registrationMaster,
    consultants,
    customers,
    wardManagement,
  } = state;

  return {
    patients: patients.patients,
    masters: registrationMaster.data,
    dateWisePatients: patients.dateWisePatients,
    transaction: customers.currentTransaction,
    customer: customers.selected_customer,
    wardbed: wardManagement.wardbed,
    consultants: consultants.consultants,
  };
}

const connectedPatientTable = connect(mapStateToProps)(PatientListTable);
export default connectedPatientTable;
