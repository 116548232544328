import "./Layout/AppMain/icons.css";
export function get_menus(userClaims) {
  // Function to check if a permission is present in userClaims
  console.log(userClaims);
  const ogMenuObject = [
    {
      label: "Home",
      icon: "custom-icon icon-menu-home",
      to: "#/home",
      visible: true,
    },
    // {
    //   label: "Organizations",
    //   icon: "custom-icon icon-menu-organization",
    //   to: "#/organizations",
    //   visible: hasPermission("ReadMaster", userClaims),
    // },
    // {
    //   label: "Accounts",
    //   icon: "custom-icon icon-menu-account",
    //   to: "#/accounts",
    //   visible: hasPermission("EditMaster", userClaims),
    // },
    {
      label: "Employees Registration",
      icon: "custom-icon icon-menu-registration",
      visible: hasPermission("EmployeesRegistrationMenu", userClaims), // adjust permission as needed
      content: [
        {
          label: "Employee Master",
          icon: "custom-icon icon-menu-5f2391b18cb4ab42f41b13b9",
          to: "#/employees-registration/employee-master",
          visible: hasPermission("ReadEmployeesMaster", userClaims), // adjust permission as needed
        },
        {
          label: "Consultant Registration",
          icon: "custom-icon icon-menu-5f23926e8cb4ab42f41b13bb",
          to: "#/employees-registration/new-consultant-registration",
          visible: hasPermission("EmployeesRegistration", userClaims), // adjust permission as needed
        },
        {
          label: "Employee Registration",
          icon: "custom-icon icon-menu-5f2392618cb4ab42f41b13ba",
          to: "#/employees-registration/new-employee-registration",
          visible: hasPermission("EmployeesRegistration", userClaims), // adjust permission as needed
        },
      ],
    },
    {
      label: "Patient Registration",
      icon: "custom-icon icon-menu-registration",
      visible: hasPermission("PatientRegistration", userClaims), // adjust permission as needed
      content: [
        {
          label: "Masters",
          icon: "custom-icon icon-menu-5f238c778cb4ab42f41b13b0",
          visible: hasPermission("ReadRegistrationMaster", userClaims),
          content: [
            {
              label: "Registration Masters",
              icon: "custom-icon icon-menu-5f238cf38cb4ab42f41b13b3",
              to: "#/patient-registration/masters/registration-masters",
              visible: hasPermission("ReadRegistrationMaster", userClaims), // adjust permission as needed
            },
            {
              label: "Registration Wise Service Charges",
              icon: "custom-icon icon-menu-5f238d338cb4ab42f41b13b5",
              to:
                "#/patient-registration/masters/registration-wise-service-charges",
              visible: hasPermission("ReadRegistrationMaster", userClaims), // adjust permission as needed
            },
            {
              label: "Registration Wise Consultant Fee",
              icon: "custom-icon icon-menu-5f238d4e8cb4ab42f41b13b6",
              to:
                "#/patient-registration/masters/registration-wise-consultant-fee",
              visible: hasPermission("ReadRegistrationMaster", userClaims), // adjust permission as needed
            },
            {
              label: "Registration Fee Master",
              icon: "custom-icon icon-menu-5f238d048cb4ab42f41b13b4",
              to: "#/patient-registration/masters/registration-fee-master",
              visible: hasPermission("ReadRegistrationMaster", userClaims), // adjust permission as needed
            },
            {
              label: "Age Wise Master",
              icon: "custom-icon icon-menu-639057a5c58db924a7461c37",
              to: "#/patient-registration/masters/age-wise-master",
              visible: hasPermission("ReadRegistrationMaster", userClaims), // adjust permission as needed
            },
            {
              label: "General Master",
              icon: "custom-icon icon-menu-5f41f9d76319e5274408b116",
              to: "#/patient-registration/masters/general-master",
              visible: hasPermission("ReadPatientGeneralMaster", userClaims), // adjust permission as needed
            },
          ],
        },
        {
          label: "New Patient",
          icon: "custom-icon icon-menu-5f238f828cb4ab42f41b13b7",
          to: "#/patient-registration/transactions/new-patient",
          visible: hasPermission("AddPatients", userClaims), // adjust permission as needed
        },
        {
          label: "Patient List",
          icon: "custom-icon icon-menu-5f238f998cb4ab42f41b13b8",
          to: "#/patient-registration/transactions/patient-list",
          visible: hasPermission("ReadPatients", userClaims), // adjust permission as needed
        },
        // {
        //   label: "Reports",
        //   icon: "custom-icon icon-menu-5f238c9b8cb4ab42f41b13b2",
        //   to: "#/patient-registration/reports",
        //   visible: hasPermission("EditMaster", userClaims), // adjust permission as needed
        // },
      ],
    },
    {
      label: "Out Patient Department",
      icon: "custom-icon icon-menu-opd",
      visible: hasPermission("Opd", userClaims),
      content: [
        {
          label: "Service Master",
          icon: "custom-icon icon-menu-5f41cc906319e5274408b0ff",
          to: "#/out-patient-department/service-master",
          visible: hasPermission("ReadServiceMaster", userClaims),
        },
        {
          label: "Appointments Schedule",
          icon: "custom-icon icon-menu-5f2394698cb4ab42f41b13bf",
          to: "#/out-patient-department/appointments-schedule",
          visible: hasPermission("ReadAppointments", userClaims),
        },
        {
          label: "OPD Orders Billing",
          icon: "custom-icon icon-menu-5f2395158cb4ab42f41b13c4",
          visible: hasPermission("ReadOpdBilling", userClaims),
          content: [
            {
              label: "OPD Billing",
              icon: "custom-icon icon-menu-5f41cd516319e5274408b100",
              to: "#/out-patient-department/opd-orders-billing/opd-billing",
              visible: hasPermission("ReadOpdBilling", userClaims),
            },
            {
              label: "OPD Receivable",
              icon: "custom-icon icon-menu-5fa7cecc8708370840df8f5a",
              to: "#/out-patient-department/opd-orders-billing/opd-receivable",
              visible: hasPermission("ReadOpdBilling", userClaims),
            },
            {
              label: "OPD Advance",
              icon: "custom-icon icon-menu-5fa7cf838708370840df8f5b",
              to: "#/out-patient-department/opd-orders-billing/opd-advance",
              visible: hasPermission("ReadOpdBilling", userClaims),
            },
            {
              label: "OPD Refund/Cancel",
              icon: "custom-icon icon-menu-5fa7ce568708370840df8f59",
              to: "#/out-patient-department/opd-orders-billing/opd-refund",
              visible: hasPermission("ReadOpdBilling", userClaims),
            },
          ],
        },
        {
          label: "OP Initial Assesment",
          icon: "custom-icon icon-menu-62f112e24bbeade30f843314",
          // visible: hasPermission("EditMaster", userClaims),
          content: [
            {
              label: "Master",
              icon: "custom-icon icon-menu-63735a69c58db924a73b9dee",
              to: "#/out-patient-department/op-initial-assesment/master",
              // visible: hasPermission("EditMaster", userClaims),
            },
            {
              label: "Initial Assessment",
              icon: "custom-icon icon-menu-63735a69c58db924a73b9deb",
              to:
                "#/out-patient-department/op-initial-assesment/initial-assessment",
              // visible: hasPermission("EditMaster", userClaims),
            },
          ],
        },
        // {
        //   label: "OPD prescription",
        //   icon: "custom-icon icon-menu-639055ccc58db924a7461b85",
        //   to: "#/out-patient-department/opd-prescription",
        //   visible: hasPermission("EditMaster", userClaims),
        // },
        {
          label: "OPD Consent",
          icon: "custom-icon icon-menu-5f2395628cb4ab42f41b13c7",
          to: "#/out-patient-department/opd-consent",
          visible: hasPermission("ReadConsent", userClaims),
        },
        // {
        //   label: "OPD Reports",
        //   icon: "custom-icon icon-menu-626619d27262dc0af81225bf",
        //   visible: hasPermission("EditMaster", userClaims),
        //   content: [
        //     {
        //       label: "Receipt Wise Collection",
        //       icon: "custom-icon icon-menu-62661a567262dc0af81225c0",
        //       to:
        //         "#/out-patient-department/opd-reports/receipt-wise-collection",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //     {
        //       label: "Bill Wise Collection",
        //       icon: "custom-icon icon-menu-6268cb1acd4f11e051f4bae2",
        //       to: "#/out-patient-department/opd-reports/bill-wise-collection",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //     {
        //       label: "Patient Wise Report",
        //       icon: "custom-icon icon-menu-63035259bc5b65b7a7e555f2",
        //       to: "#/out-patient-department/opd-reports/patient-wise-report",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //   ],
        // },
        // {
        //   label: "OPD Pharmacy",
        //   icon: "custom-icon icon-menu-5f23950b8cb4ab42f41b13c3",
        //   to: "#/out-patient-department/opd-pharmacy",
        //   visible: hasPermission("EditMaster", userClaims),
        // },
        // {
        //   label: "Token Monitor",
        //   icon: "custom-icon icon-menu-5f41de4e6319e5274408b114",
        //   to: "#/out-patient-department/token-monitor",
        //   visible: hasPermission("EditMaster", userClaims),
        // },
        // {
        //   label: "Patient Queue",
        //   icon: "custom-icon icon-menu-5f41de656319e5274408b115",
        //   to: "#/out-patient-department/patient-queue",
        //   visible: hasPermission("EditMaster", userClaims),
        // },
        // {
        //   label: "OPD Orders",
        //   icon: "custom-icon icon-menu-5f41fd906319e5274408b117",
        //   visible: hasPermission("EditMaster", userClaims),
        //   content: [
        //     {
        //       label: "Service Orders",
        //       icon: "custom-icon icon-menu-604c32319e11cb28606fce2c",
        //       to: "#/out-patient-department/opd-orders/service-orders",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //     {
        //       label: "Pharmacy Orders",
        //       icon: "custom-icon icon-menu-604c32549e11cb28606fce2d",
        //       to: "#/out-patient-department/opd-orders/pharmacy-orders",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //   ],
        // },
        // {
        //   label: "OPD Initial Assessment",
        //   icon: "custom-icon icon-menu-5fa7d8588708370840df8f5d",
        //   visible: hasPermission("EditMaster", userClaims),
        //   content: [
        //     {
        //       label: "Initial Assessment",
        //       icon: "custom-icon icon-menu-5fa7d8ec8708370840df8f5e",
        //       to:
        //         "#/out-patient-department/opd-initial-assessment/initial-assessment",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //     {
        //       label: "OPD Vitals",
        //       icon: "custom-icon icon-menu-5fa7d9388708370840df8f5f",
        //       to: "#/out-patient-department/opd-initial-assessment/opd-vitals",
        //       visible: hasPermission("EditMaster", userClaims),
        //     },
        //     {
        //       label: "Masters",
        //       icon: "custom-icon icon-menu-5fa7d9828708370840df8f60",
        //       content: [
        //         // Content for Masters
        //       ],
        //     },
        // ],
        // },
      ],
    },
    // {
    //   label: "In Patient Department",
    //   icon: "custom-icon icon-menu-ipd",
    //   visible: hasPermission("EditMaster", userClaims),
    //   content: [
    //     {
    //       label: "Ward Master",
    //       icon: "custom-icon icon-menu-60181eb557ff852f40463773",
    //       visible: hasPermission("EditMaster", userClaims),
    //       content: [
    //         {
    //           label: "Masters",
    //           icon: "custom-icon icon-menu-604ba35a9e11cb28606fce21",
    //           content: [
    //             // Content for Masters
    //           ],
    //         },
    //         {
    //           label: "Registration Fees",
    //           icon: "custom-icon icon-menu-604baafc9e11cb28606fce27",
    //           to: "#/in-patient-department/ward-master/registration-fees",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "IP Visit Charges",
    //           icon: "custom-icon icon-menu-604bab909e11cb28606fce28",
    //           to: "#/in-patient-department/ward-master/ip-visit-charges",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Service Charges",
    //           icon: "custom-icon icon-menu-604bac409e11cb28606fce29",
    //           to: "#/in-patient-department/ward-master/service-charges",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Consultant Service Share",
    //           icon: "custom-icon icon-menu-604bacc19e11cb28606fce2a",
    //           to:
    //             "#/in-patient-department/ward-master/consultant-service-share",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Grace Period Settings",
    //           icon: "custom-icon icon-menu-604bad5a9e11cb28606fce2b",
    //           to: "#/in-patient-department/ward-master/grace-period-settings",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //     },
    //     {
    //       label: "Ipd Service Master",
    //       icon: "custom-icon icon-menu-6089a66f19b6a71d4491cbfc",
    //       to: "#/in-patient-department/ipd-service-master",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Ipd Referral Master",
    //       icon: "custom-icon icon-menu-6089a74619b6a71d4491cbfd",
    //       to: "#/in-patient-department/ipd-referral-master",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "TPA",
    //       icon: "custom-icon icon-menu-60b523fee9ac4a3114305a27",
    //       visible: hasPermission("EditMaster", userClaims),
    //       content: [
    //         {
    //           label: "Ipd TPA Master",
    //           icon: "custom-icon icon-menu-6089a7d619b6a71d4491cbfe",
    //           to: "#/in-patient-department/tpa/ipd-tpa-master",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Ipd Tpa Wise Service",
    //           icon: "custom-icon icon-menu-60ab5342e9ac4a3114305a24",
    //           to: "#/in-patient-department/tpa/ipd-tpa-wise-service",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Authorization",
    //           icon: "custom-icon icon-menu-60e49595e3e57250b8f4e178",
    //           to: "#/in-patient-department/tpa/authorization",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //     },
    //     {
    //       label: "POS",
    //       icon: "custom-icon icon-menu-60b60adbe9ac4a3114305a28",
    //       visible: hasPermission("EditMaster", userClaims),
    //       content: [
    //         {
    //           label: "POS",
    //           icon: "custom-icon icon-menu-60ab5d6ee9ac4a3114305a26",
    //           to: "#/in-patient-department/pos/pos",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Radiology Pos",
    //           icon: "custom-icon icon-menu-60b60b42e9ac4a3114305a29",
    //           to: "#/in-patient-department/pos/radiology-pos",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Nurse Pos",
    //           icon: "custom-icon icon-menu-60b60b58e9ac4a3114305a2a",
    //           to: "#/in-patient-department/pos/nurse-pos",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Laboratory Pos",
    //           icon: "custom-icon icon-menu-60b60b74e9ac4a3114305a2b",
    //           to: "#/in-patient-department/pos/laboratory-pos",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //     },
    //     {
    //       label: "Corporates",
    //       icon: "custom-icon icon-menu-60b60baae9ac4a3114305a2c",
    //       visible: hasPermission("EditMaster", userClaims),
    //       content: [
    //         {
    //           label: "Ipd Corporate Wise Service",
    //           icon: "custom-icon icon-menu-60ab551de9ac4a3114305a25",
    //           to:
    //             "#/in-patient-department/corporates/ipd-corporate-wise-service",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "Authorization",
    //           icon: "custom-icon icon-menu-60e49609e3e57250b8f4e179",
    //           to: "#/in-patient-department/corporates/authorization",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //     },
    //     {
    //       label: "IPD Billing",
    //       icon: "custom-icon icon-menu-60e4967be3e57250b8f4e17a",
    //       visible: hasPermission("EditMaster", userClaims),
    //       content: [
    //         {
    //           label: "IPD Advance",
    //           icon: "custom-icon icon-menu-60e496e4e3e57250b8f4e17b",
    //           to: "#/in-patient-department/ipd-billing/ipd-advance",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "IPD Billing",
    //           icon: "custom-icon icon-menu-60e49737e3e57250b8f4e17c",
    //           to: "#/in-patient-department/ipd-billing/ipd-billing",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "IPD Cancel",
    //           icon: "custom-icon icon-menu-60e49779e3e57250b8f4e17d",
    //           to: "#/in-patient-department/ipd-billing/ipd-cancel",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           label: "IPD Refund",
    //           icon: "custom-icon icon-menu-60e497b1e3e57250b8f4e17e",
    //           to: "#/in-patient-department/ipd-billing/ipd-refund",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //     },
    //     {
    //       label: "IPD Cancel List",
    //       icon: "custom-icon icon-menu-629ddcaf4b9f7a284cf41c7f",
    //       to: "#/in-patient-department/ipd-cancel-list",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "IPD Patient List",
    //       icon: "custom-icon icon-menu-629ddcaf4b9f7a284cf41c82",
    //       to: "#/in-patient-department/ipd-patient-list",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "IPD Discharge",
    //       icon: "custom-icon icon-menu-629ddcaf4b9f7a284cf41c85",
    //       to: "#/in-patient-department/ipd-discharge",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "IPD Admission",
    //       icon: "custom-icon icon-menu-608aa32daf80000534e55df0",
    //       to: "#/in-patient-department/ipd-admission",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //   ],
    // },
    // {
    //   label: "Laboratory",
    //   icon: "custom-icon icon-menu-laboratory",
    //   visible: hasPermission("EditMaster", userClaims),
    //   content: [
    //     {
    //       label: "Out Sources",
    //       icon: "custom-icon icon-menu-5f41d9c56319e5274408b10d",
    //       to: "#/laboratory/out-sources",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Group Mapping",
    //       icon: "custom-icon icon-menu-5f41dde76319e5274408b112",
    //       to: "#/laboratory/group-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Test Sample",
    //       icon: "custom-icon icon-menu-5f41ddf86319e5274408b113",
    //       to: "#/laboratory/test-sample",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Masters",
    //       icon: "custom-icon icon-menu-5f61e36e2cb2381904853e16",
    //       to: "#/laboratory/masters",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Test Sample Collection",
    //       icon: "custom-icon icon-menu-5fd85c76e66292160c214571",
    //       to: "#/laboratory/test-sample-collection",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Report Entry",
    //       icon: "custom-icon icon-menu-5fdb24abe408cf05189dcfcb",
    //       to: "#/laboratory/report-entry",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Interim Report",
    //       icon: "custom-icon icon-menu-61a9c894d9edb2358963b728",
    //       to: "#/laboratory/interim-report",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Approve Reports",
    //       icon: "custom-icon icon-menu-60409864a9716a0394fb00bb",
    //       to: "#/laboratory/approve-reports",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Test Mapping",
    //       icon: "custom-icon icon-menu-5f41ddd46319e5274408b110",
    //       to: "#/laboratory/test-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Subtest Mapping",
    //       icon: "custom-icon icon-menu-5f41dddf6319e5274408b111",
    //       to: "#/laboratory/subtest-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Template Mapping",
    //       icon: "custom-icon icon-menu-5f41ddc96319e5274408b10f",
    //       to: "#/laboratory/template-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Antibiotics Mapping",
    //       icon: "custom-icon icon-menu-6059a5b546a7991a0c17bd48",
    //       to: "#/laboratory/antibiotics-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Out Source Mapping",
    //       icon: "custom-icon icon-menu-5f41d9d56319e5274408b10e",
    //       to: "#/laboratory/out-source-mapping",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "Op Patient Report",
    //       icon: "custom-icon icon-menu-63199350bc5b65b7a7ead3e4",
    //       to: "#/laboratory/op-patient-report",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       label: "POS Config",
    //       icon: "custom-icon icon-menu-5f41d99f6319e5274408b10c",
    //       to: "#/laboratory/pos-config",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //   ],
    // },
    // {
    //   label: "Ward",
    //   icon: "custom-icon icon-menu-ward",
    //   to: "#/ward",
    //   visible: hasPermission("EditMaster", userClaims),
    // },
    // {
    //   label: "Pharmacy",
    //   icon: "custom-icon icon-menu-pharmacy",
    //   visible: hasPermission("EditMaster", userClaims),
    //   content: [
    //     {
    //       icon: "custom-icon icon-menu-5f41cf536319e5274408b101",
    //       label: "Master",
    //       to: "#/pharmacy/master",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-60b24606e69a8b16241580d7",
    //       label: "Stocks",
    //       to: "#/pharmacy/stocks",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-6099060b2e7255049c4aff1a",
    //       label: "Purchase Order",
    //       to: "#/pharmacy/purchase-order",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-60926fe8af80000534e55df2",
    //       label: "Purchase (GRN)",
    //       to: "#/pharmacy/purchase-grn",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-624fc43c4897ea432ae68e7d",
    //       label: "Purchase Return",
    //       to: "#/pharmacy/purchase-return",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-5f41cf626319e5274408b102",
    //       label: "Sales",
    //       content: [
    //         {
    //           icon: "custom-icon icon-menu-61f798bba4d132f97435949d",
    //           label: "Return Sales",
    //           to: "#/pharmacy/sales/return-sales",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           icon: "custom-icon icon-menu-61f79f37a4d132f9743596c7",
    //           label: "New Sales",
    //           to: "#/pharmacy/sales/new-sales",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //         {
    //           icon: "custom-icon icon-menu-621e200a47285dad38ffd0cc",
    //           label: "Due Receivables",
    //           to: "#/pharmacy/sales/due-receivables",
    //           visible: hasPermission("EditMaster", userClaims),
    //         },
    //       ],
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-620f4aa6dc2eb2e9a311c359",
    //       label: "InPatient List",
    //       to: "#/pharmacy/inpatient-list",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-609906342e7255049c4aff1b",
    //       label: "Department Indent",
    //       to: "#/pharmacy/department-indent",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-609904ff2e7255049c4aff17",
    //       label: "Department Transfer",
    //       to: "#/pharmacy/department-transfer",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-6099052d2e7255049c4aff18",
    //       label: "Department Usage",
    //       to: "#/pharmacy/department-usage",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-6390435ac58db924a7461358",
    //       label: "Supplier Payment",
    //       to: "#/pharmacy/supplier-payment",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-60af79e3ba89ae1a6c37cc14",
    //       label: "Reports",
    //       content: [
    //         // Add content objects for reports
    //       ],
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-639043c1c58db924a746137c",
    //       label: "Stock Finder",
    //       to: "#/pharmacy/stock-finder",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //     {
    //       icon: "custom-icon icon-menu-608fd792af80000534e55df1",
    //       label: "Customers",
    //       to: "#/pharmacy/customers",
    //       visible: hasPermission("EditMaster", userClaims),
    //     },
    //   ],
    // },
    {
      label: "Admin",
      icon: "custom-icon icon-menu-registration",
      to: "#/admin",
      visible: hasPermission("Admin", userClaims),
    },
  ];
  const filteredContent = ogMenuObject
    .map((item) => filterVisibleObjects(item))
    .filter(Boolean);
  console.log(ogMenuObject);
  console.log(filteredContent);
  return filteredContent;
}

function hasPermission(permission, userClaims) {
  // Check if the permission is present in userClaims
  return userClaims.includes(permission);
}

function filterVisibleObjects(obj) {
  if (obj.visible === false) {
    return null;
  }

  const { visible, ...filteredObj } = obj;

  if (Array.isArray(obj.content)) {
    filteredObj.content = obj.content
      .map((child) => filterVisibleObjects(child))
      .filter(Boolean);
  }

  return filteredObj;
}
