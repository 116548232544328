
    export const appointmentConstants={

       ADD_APPOINTMENT_REQUEST:'ADD_APPOINTMENT_REQUEST',
       ADD_APPOINTMENT_SUCCESS:'ADD_APPOINTMENT_SUCCESS',
       ADD_APPOINTMENT_FAILURE:'ADD_APPOINTMENT_FAILURE' ,

       GET_ALL_APPOINTMENT_REQUEST:'GET_ALL_APPOINTMENT_REQUEST',
       GET_ALL_APPOINTMENT_SUCCESS:'GET_ALL_APPOINTMENT_SUCCESS',
       GET_ALL_APPOINTMENT_FAILURE:'GET_ALL_APPOINTMENT_FAILURE' ,

       GET_BY_CONSULTANT_REQUEST:'GET_BY_CONSULTANT_REQUEST',
       GET_BY_CONSULTANT_SUCCESS:'GET_BY_CONSULTANT_SUCCESS',
       GET_BY_CONSULTANT_FAILURE:'GET_BY_CONSULTANT_FAILURE' ,

       SELECTED_APPOINTMENT_REQUEST:'SELECTED_APPOINTMENT_REQUEST',
       SELECTED_APPOINTMENT_SUCCESS:'SELECTED_APPOINTMENT_SUCCESS',
       SELECTED_APPOINTMENT_FAILURE:'SELECTED_APPOINTMENT_FAILURE',


       UPDATE_APPOINTMENT_REQUEST:'UPDATE_APPOINTMENT_REQUEST',
       UPDATE_APPOINTMENT_SUCCESS:'UPDATE_APPOINTMENT_SUCCESS',
       UPDATE_APPOINTMENT_FAILURE:'UPDATE_APPOINTMENT_FAILURE' ,

       DELETE_APPOINTMENT_REQUEST:'DELETE_APPOINTMENT_REQUEST',
       DELETE_APPOINTMENT_SUCCESS:'DELETE_APPOINTMENT_SUCCESS',
       DELETE_APPOINTMENT_FAILURE:'DELETE_APPOINTMENT_FAILURE' ,

       SET_REMINDER_REQUEST:'SET_REMINDER_REQUEST',
       SET_REMINDER_SUCCESS:'SET_REMINDER_SUCCESS',
       SET_REMINDER_FAILURE:'SET_REMINDER_FAILURE' ,

       SELECT_APPOINTMENT_REQUEST:'SELECT_APPOINTMENT_REQUEST',
       SELECT_APPOINTMENT_SUCCESS:'SELECT_APPOINTMENT_SUCCESS',
       SELECT_APPOINTMENT_FAILURE:'SELECT_APPOINTMENT_FAILURE' ,

       APPOINTMENT_CLEAR:'APPOINTMENT_CLEAR'

    
    }

    export const appointmentType=
    {
        Walkin: 1,
        ByAppointment: 2
    }

    export const appointmentStatus={
    New: 1,
    Waiting:2,
    Engaged:3,
    Done:4,
    Cancelled:5,
    Reschedule:6
    }