//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";
import { authHeader } from "../store";

export const customerService = {
  getTransactionDetailsById,
  getNameAndLogo,
  getTransactionById,
  getCustomerById,
  getAllCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  addTransaction,
  connectToDb,
  getTenantDetails
};

function getTenantDetails(){
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/tenants/details?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getTransactionDetailsById(TransactionId) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/aqua/customerTransaction/getCustomerTransactionDetailsById/${TransactionId}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllCustomers() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/aqua/customerMaster/getallcustomers`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getTransactionById(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/aqua/customerTransaction/getTransactionDetailById/${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getCustomerById(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/aqua/customerMaster/getCustomerDetailById/${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getNameAndLogo() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${apiUrl}/aqua/customerTransaction/getCustomerTransactionDetailsAtLoading`,
    requestOptions
  ).then(helperService.handleResponse);
}

function addCustomer(data) {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
    body: data,
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerMaster/addCustomer`,
    requestOptions
  ).then(helperService.handleResponse);
}

function updateCustomer(data) {
  const requestOptions = {
    method: "POST",
    body: data,
    headers: { authorization: helperService.getHeaderData() },
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerMaster/updateCustomer`,
    requestOptions
  ).then(helperService.handleResponse);
}

function deleteCustomer(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerMaster/deleteCustomer`,
    requestOptions
  ).then(helperService.handleResponse);
}

function addTransaction(data) {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
    body: data,
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerTransaction/addCustomerTransactionDetails`,
    requestOptions
  ).then(helperService.handleResponse);
}

function connectToDb(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(
    `${apiUrl}/aqua/customerMaster/connectToDb`,
    requestOptions
  ).then(helperService.handleResponse);
}
