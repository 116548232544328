import { laboratoryMasterConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { laboratoryMasterService } from "../services";
import { toast, Bounce } from "react-toastify";

export const laboratoryMasterActions = {
  getAll,
  add,
  update,
  setdefault,
  deleteMaster,
  getForMaster,
};
const EndpointMapping = {
  Units: 1,
  SubTests: 2,
  AntiBiotics: 3,
  CutOffTime: 4,
  TurnAroundTimes: 5,
  Kits: 6,
  Methodologies: 7,
  Quantities: 8,
  Frozens: 9,
  RoomTemperatures: 10,
  ReportingDatetime: 11,
  Centigrades: 12,
  SampleTypes: 13,
  PaymentCycle: 14,
  SpecimenType: 15,
  SpecimenSource: 16,
  ContainerType: 17,
  StorageType: 18,
  PreConditions: 19,
  LabMethods: 20,
};

// Example usage:

function getAll() {
  return (dispatch) => {
    dispatch(request());
    laboratoryMasterService.getActiveOnly().then(
      (data) => {
        if (data.success) {
          dispatch(success(data.value));
          dispatch(alertActions.success("Masters loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.GET_ACTIVE_REQUEST };
  }
  function success(data) {
    return { type: laboratoryMasterConstants.GET_ACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.GET_ACTIVE_FAILURE, error };
  }
}

function getForMaster(key) {
  return (dispatch) => {
    dispatch(request());
    laboratoryMasterService.getAll(EndpointMapping[key]).then(
      (data) => {
        if (data) {
          console.log(data);
          dispatch(success(data.value));
          dispatch(alertActions.success("Masters loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return { type: laboratoryMasterConstants.GET_ALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.GET_ALL_FAILURE, error };
  }
}

function add(type, payload) {
  return (dispatch) => {
    dispatch(request());
    payload = { ...payload, type: EndpointMapping[type] };
    console.log(payload);
    laboratoryMasterService.add(payload).then(
      (result) => {
        console.log(result);
        if (result) {
          dispatch(success(EndpointMapping[type], result.value));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          // dispatch(alertActions.success(result.message));
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.ADD_REQUEST };
  }
  function success(type, data) {
    return {
      type: laboratoryMasterConstants.ADD_SUCCESS,
      data: { type, data },
    };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.ADD_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());

    laboratoryMasterService.update(payload).then(
      (result) => {
        console.log(result);
        if (result) {
          dispatch(success(result.value.value));
          dispatch(alertActions.success(result));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: laboratoryMasterConstants.UPDATE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.UPDATE_FAILURE, error };
  }
}
function setdefault(payload) {
  return (dispatch) => {
    dispatch(request());

    laboratoryMasterService.setDefault(payload).then(
      (result) => {
        console.log(result);
        console.log(payload.type);
        if (result) {
          dispatch(success(payload.id, result.value));
          dispatch(alertActions.success(result.message));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.SET_DEFAULT_REQUEST };
  }
  function success(type, data) {
    return {
      type: laboratoryMasterConstants.SET_DEFAULT_SUCCESS,
      data: { type: type, data },
    };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.SET_DEFAULT_FAILURE, error };
  }
}
function deleteMaster(type, payload) {
  return (dispatch) => {
    dispatch(request());

    laboratoryMasterService.deleteMaster(payload).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(type, result.value));
          dispatch(alertActions.success(result));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: laboratoryMasterConstants.DELETE_REQUEST };
  }
  function success(type, data) {
    return {
      type: laboratoryMasterConstants.DELETE_SUCCESS,
      data: { type, data },
    };
  }
  function failure(error) {
    return { type: laboratoryMasterConstants.DELETE_FAILURE, error };
  }
}
