import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { opBillingService } from "../../services";
import "./printform.css";
import getBillTemplate from "../../helpers/billPrintHelper";

export default class PrintBillModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "<div> Print Bill</div>",
    };
  }

  // On component Load
  componentDidMount() {
    let payload = {
      billNo: this.props.billNo,
      patient_uhid: this.props.patient_uhid,
      printType: this.props.printType,
      bill: this.props.bill_for_printing,
      masters: this.props.masters,
    };

    getBillTemplate(payload).then((data) => {
      if (data.success) {
        this.setState({
          template: data.data,
        });
      }
    });
  }

  // If the Component recive the props the following function is called
  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      let payload = {
        billNo: prevProps.billNo,
        patient_uhid: prevProps.patient_uhid,
        printType: prevProps.printType,
      };
      opBillingService.getBillTemplate(payload).then((data) => {
        if (data.success) {
          this.setState({
            template: data.data,
          });
        }
      });
    }
  }
  render() {
    return (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row className="text-center"></Row>
        <Modal
          isOpen={this.props.openModal}
          toggle={this.props.toggle}
          style={{ minWidth: "800px" }}
        >
          <ModalHeader></ModalHeader>

          <ModalBody>
            <div ref={(el) => (this.componentRef = el)}>
              {parse(this.state.template)}
            </div>
          </ModalBody>

          <ModalFooter>
            {this.props.isCancelFlow && this.props.enableBillCancel ? (
              <Button onClick={this.props.confirmhandler} color="danger">
                Cancel Bill
              </Button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <Button className="mb-2 mr-2 btn-icon" color="success">
                    <FontAwesomeIcon
                      icon={faPrint}
                      size="1x"
                      className="mr-1"
                    />
                    Print Bill
                  </Button>
                )}
                content={() => this.componentRef}
              />
            )}
            <Button
              color="primary"
              onClick={this.props.toggle}
              style={!this.props.isCancelFlow ? { marginTop: "-6px" } : {}}
            >
              {this.props.isCancelFlow ? "Return to Grid" : "Cancel"}
            </Button>
          </ModalFooter>
        </Modal>
      </CSSTransitionGroup>
    );
  }
}
