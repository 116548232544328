//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const opReportingService = {        
     update,
     patientGetByDate
   };

function patientGetByDate(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/newpatient/getByDate/${from}/${to}`, requestOptions).then(helperService.handleResponse);
}

function update(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };   
    return fetch(`${apiUrl}/posconfig/update`, requestOptions).then(helperService.handleResponse);
}