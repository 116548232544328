import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const medicalHistoryService = {
  getAll,
  add,
  update,
  remove,
  getByDate,
  getAllById,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/medicalhistory`, requestOptions).then(
    helperService.handleResponse
  );
}
function getAllById(data, from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/medicalhistories/${data}?startDate=${from}&endDate=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/medicalhistories`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/medicalhistory/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/medicalhistory/delete`, requestOptions).then(
    helperService.handleResponse
  );
}
function getByDate(from, to, id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/medicalhistory?patient_id=${id}&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
