import React, {Fragment} from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

// Forms

import ViewServiceMaster from './viewmaster';
import CustomerMasters from './addTemplate';

// Layout

import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

const ServiceMaster = ({match}) => (
    <Fragment>
       
                    {/* Form Elements */}
<Switch>
                    <Route exact path={`${match.url}`} component={ViewServiceMaster}/>
                     <Route path={`${match.url}/add`} component={CustomerMasters}/>
                    {/*<Route path={`${match.url}/validation`} component={FormElementsValidation}/> */}
                    <Redirect from="*" to={match.url}></Redirect>
                    </Switch>
              
    </Fragment>
);

export default ServiceMaster;