import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"

export const httpHelperService = {      
       getRequest,
       postRequest,
       updateRequest,
       getWithDateRequest
   };

function getRequest(url) {
    const requestOptions = {
        method: 'GET',
        headers: {"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/${url}`, requestOptions).then(helperService.handleResponse);
}

function postRequest(data,url){
    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
    return fetch(`${apiUrl}/${url}`, requestOptions).then(helperService.handleResponse);
}

function updateRequest(data,url){
    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
    return fetch(`${apiUrl}/${url}`, requestOptions).then(helperService.handleResponse);
}

function getWithDateRequest(url, from, to) {
    const requestOptions = {
        method: 'GET',
        headers: {"authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/${url}?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}




