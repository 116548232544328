import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const locationService = {
  getAll,
  getDistricts,
  getStates,
  getCities,
  getStates,
  getDistricts,
  getLocations,
  getPinCode,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    // headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Countries?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getStates(id) {
  const requestOptions = {
    method: "GET",
    // headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/States/${id}?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getDistricts(id) {
  const requestOptions = {
    method: "GET",
    // headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Districts/${id}?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getCities(id) {
  const requestOptions = {
    method: "GET",
    //headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Cities/${id}?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getLocations(id) {
  const requestOptions = {
    method: "GET",
    //headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/Locations/${id}?viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getPinCode(id) {
  const requestOptions = {
    method: "GET",
    //headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/Locations/search/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}
