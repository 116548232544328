import {helperService} from "./helper.service"
import {apiUrl} from "../constants"


export const tpaConsultantService = {  
    add, 
    update,   
    remove, 
    getAll 
   };


function getAll() {
    const requestOptions = {
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        method: 'GET'
    };
    return fetch(`${apiUrl}/tpawiseconsultantfees`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/tpawiseconsultantfees`, requestOptions).then(helperService.handleResponse);
}

function update(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/tpawiseconsultantfees/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {
   
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
    
    return fetch(`${apiUrl}/tpawiseconsultantfees/delete`, requestOptions).then(helperService.handleResponse);
}

// function changeActive(data) {
   
//     const requestOptions = {
//         method: 'POST',
//         headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
//         body:JSON.stringify(data)
//     };
    
//     return fetch(`${apiUrl}/tpas/changeActive`, requestOptions).then(helperService.handleResponse);
// }


