import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const roleService = {
  getAll,
  getRoleData,
  deleteRoleData,
  statusUpdate,
};

function getAll(viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/roles?viewDeleted=${viewDeleted}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getRoleData(role) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/role/role_data?id=${role}`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteRoleData(payload) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(payload),
  };
  return fetch(`${apiUrl}/role/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function statusUpdate(payload) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(payload),
  };
  return fetch(`${apiUrl}/role/status`, requestOptions).then(
    helperService.handleResponse
  );
}
