import React, { Fragment } from "react";
import ReactDOM from "react-dom";
// import registerServiceWorker from './registerServiceWorker';
//import { unregister } from './registerServiceWorker';

import {
  HashRouter as Router,
  Switch,
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
//import configureStore from './config/configureStore';
import { store } from "./store";
import { Provider, connect } from "react-redux";
import { history } from "./helpers";
import AppHeader from "./Layout/AppHeader";
import AppSidebar from "./Layout/AppSidebar";
import AppFooter from "./Layout/AppFooter";
import IdleTimer from "react-idle-timer";
import SuspenseLoader from "../src/DemoPages/MyComponents/SuspenseLoader";
import { storageService, loginservice } from "./services";
import { loginConstant } from "./constants";
import cx from "classnames";
import { toast, Bounce } from "react-toastify";
import { ToastContainer } from "react-toastify";
import ResizeDetector from "react-resize-detector";
import RegisterBoxed from "./DemoPages/UserPages/RegisterBoxed";
import Register from "./DemoPages/UserPages/Register";
import Login from "./DemoPages/UserPages/Login";
//const store = configureStore();
const rootElement = document.getElementById("root");

// const Login = React.lazy(() => import("./DemoPages/UserPages/Login"));

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

class AppMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      logout_time_limit: 1000 * 60 * 60,
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  handleOnAction(event) {
    // console.log('user did something', event)
  }

  handleOnActive(event) {}

  handleOnIdle(event) {
    storageService.removeData("authorization");
    window.location.replace("./");
  }

  componentDidUpdate(pp, ps) {
    if (
      pp.customers.currentTransaction !==
      this.props.customers.currentTransaction
    ) {
      if (this.props.customers.currentTransaction.login_type === "consultant") {
        this.setState({ logout_time_limit: 1000 * 60 * 60 });
      }
    }
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      hideSideBar,
      customers,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 767 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 767,
                },
                { "sidebar-mobile-open": enableMobileMenu }
              )}
            >
              <div
                id="parentDivForLoader"
                className="loading parentDivForLoader"
              >
                <div className="loader"></div>
              </div>
              <AppHeader {...this.props} />
              <div
                className="app-main"
                style={
                  enableClosedSidebar
                    ? { paddingRight: "0px" }
                    : { paddingRight: "0px" }
                }
              >
                <div
                  className="app-main__outer"
                  style={hideSideBar ? { paddingRight: 0 } : null}
                >
                  <div className="app-main__inner">
                    <IdleTimer
                      ref={(ref) => {
                        this.idleTimer = ref;
                      }}
                      timeout={this.state.logout_time_limit}
                      onActive={this.handleOnActive}
                      onIdle={this.handleOnIdle}
                      onAction={this.handleOnAction}
                      debounce={250}
                    />

                    <Main />
                  </div>
                  <AppFooter
                    padding={
                      enableClosedSidebar
                        ? { paddingRight: "0px" }
                        : { paddingRight: "200px" }
                    }
                  />
                </div>
                {!hideSideBar && <AppSidebar />}
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }
}
const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  hideSideBar: state.ThemeOptions.hideSideBar,
  customers: state.customers,
});

const AppMainComp = withRouter(connect(mapStateToProp)(AppMain));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }
  componentDidMount() {
    const data = storageService.getData(loginConstant.AUTHORIZATION_HEADER);
    if (data) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  render() {
    const { isLoggedIn } = this.state;

    return (
      <Router history={history}>
        <React.Suspense fallback={<SuspenseLoader />}>
          <Switch>
            <Route path="/signup">
              <Register />
            </Route>
            <Route path="/login">
              {isLoggedIn ? <AppMainComp /> : <Login />}
            </Route>
            <Route path="/">{isLoggedIn ? <AppMainComp /> : <Login />}</Route>
          </Switch>
          <ToastContainer />
        </React.Suspense>
      </Router>
    );
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
