import { generalMasterConstants } from "../constants";

export function specialities(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_SPECIALITY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_SPECIALITY_SUCCESS:
      return { ...state, specialities: action.specialities, loading: false };
    case generalMasterConstants.GET_SPECIALITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_SPECIALITY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_SPECIALITY_SUCCESS:
      return {
        ...state,
        specialities: [...state.specialities, action.speciality],
        loading: false,
      };
    case generalMasterConstants.ADD_SPECIALITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_SPECIALITY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_SPECIALITY_SUCCESS:
      let updatedState = [...state.specialities];
      let index = updatedState.findIndex(
        (ob) => ob.id === action.speciality.id
      );
      updatedState[index] = action.speciality;
      return {
        ...state,
        specialities: [...updatedState],
        loading: false,
      };
    case generalMasterConstants.UPDATE_SPECIALITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_SPECIALITY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_SPECIALITY_SUCCESS:
      if (state.specialities) {
        state.specialities[action.speciality].deleted = !state.specialities[
          action.speciality
        ].deleted;
        state.specialities[action.speciality].active = !state.specialities[
          action.speciality
        ].active;
      }

      return {
        ...state,
        specialities: [...state.specialities],
        loading: false,
      };
    case generalMasterConstants.DELETE_SPECIALITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
