import { labpatientConstants } from '../constants';

export function labpatient(state = {patient:null}, action) {
  switch (action.type) {
    case labpatientConstants.SELECT_PATIENT:
      return {
        
        patient: action.patient
      };
      case labpatientConstants.SELECTED_REPORTING:
        return {...state,          
          report: action.report
        };
    
    default:
      return state
  }
}