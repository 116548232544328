//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const queueService = {        
    getAll,
    getHistory,
    getPatientHistory
    // add,
    // update,
    // remove
   };


function getAll(queue_date) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/queue?queue_date=${queue_date}`, requestOptions).then(helperService.handleResponse);
}


function getHistory() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/queue/history`, requestOptions).then(helperService.handleResponse);
}

function getPatientHistory(id) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/queue/history/${id}`, requestOptions).then(helperService.handleResponse);
}