import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import { radiologyReportService } from "../../../services";

class ViewAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalReports: [],
      reports: [],

      modal: false,
      selectReport: null,
    };
  }

  componentDidMount() {
    const { patient } = this.props;
    if (patient)
      radiologyReportService
        .getAll(`patient_id=${patient._id}`)
        .then((data) => {
          if (data.success) {
            this.setState({ reports: data.reports });
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }

  onViewClicked(row) {
    //const {dispatch}=this.props;
    //dispatch(consultantActions.selectConsultant(row._id));
    //     this.props.history.push( {
    //       pathname:`${this.props.match.url}/new`,
    //       state : {
    //           mode:'VIEW',
    //           data:row
    //       }
    //   })
  }

  serviceToName(code) {
    const { packageData } = this.props;
    if (packageData && packageData.services) {
      let serv = packageData.services.find((item) => item.CODE === code);
      return serv ? serv.SERVICE_NAME : code;
    }
    return "";
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { reports, statics } = this.state;
    let columns = [
      {
        name: "Bill No.",
        selector: (row) => (row.bill_detail ? row.bill_detail.bill_no : ""),
        sortable: true,
      },
      {
        name: "Bill Date",
        selector: (row) => moment(row.created_on).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Service",
        selector: (row) => this.serviceToName(row.service),
        sortable: true,
      },
      {
        name: "Payment Status",
        selector: (row) =>
          row.bill_detail ? (
            row.bill_detail.payment_status ? (
              <span className="badge badge-success">DONE</span>
            ) : (
              <span className="badge badge-warning">PENDING</span>
            )
          ) : (
            ""
          ),
        sortable: true,
        width: "80px",
      },
      {
        name: "Report Status",
        selector: "report_status",
        sortable: true,
        width: "85px",
      },

      {
        name: "View ",
        sortable: true,
        width: "80px",
        cell: (row) => (
          <>
            <Button
              size="sm"
              color="primary"
              onClick={() => this.onViewClicked(row)}
            >
              View
            </Button>
          </>
        ),
      },
    ];

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div>
            <Card className="main-card mb-3">
              <CardBody>
                <div ref={(el) => (this.componentRef = el)}>
                  <DataTable
                    keyField="_id"
                    dense
                    noHeader
                    pagination={true}
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 75, 100]}
                    striped
                    columns={columns}
                    data={reports}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, registrationMaster, serviceMaster } = state;

  return {
    consultants: consultants.consultants,
    favorite_consultant: consultants.favorite_consultant,
    masters: registrationMaster.data,
    packageData: serviceMaster.data,
  };
}

const connectedViewAll = connect(mapStateToProps)(ViewAll);
export default connectedViewAll;
