import React, { Component, Fragment } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { apiUrl } from "../../constants/config";
import { connect } from "react-redux";
import {
  discountTypeActions,
  consultantActions,
  departmentActions,
  registrationMasterActions,
  referralActions,
  wardManagementActions,
} from "../../actions";
import { patientActions } from "../../actions/patient.actions";
import moment from "moment";

class PatientGeneralInfo1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      visible: false,
    };
    this.onCollapseClick = this.onCollapseClick.bind(this);
    this.codeToValue = this.codeToValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    dispatch(discountTypeActions.getAll());
    dispatch(departmentActions.getAll());
    dispatch(registrationMasterActions.getAll());
    dispatch(consultantActions.getAll());
    dispatch(referralActions.getAll());
    dispatch(wardManagementActions.getBedAll());
  }

  onScanComplete(data) {
    let { dispatch } = this.props;
    if (data) {
      if (data.patient_uhid) {
        let payload = {
          query: this.state.query,
        };
        dispatch(patientActions.search(payload));
        this.setState({
          visible: !this.state.visible,
        });
      }
    }
  }

  onKeyPress(e) {
    if (e.which === 13) {
      let { dispatch } = this.props;
      let payload = {
        query: this.state.query,
      };
      dispatch(patientActions.clearPatient());
      dispatch(patientActions.search(payload));
      this.setState({
        visible: !this.state.visible,
      });
    }
  }

  hide() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  onCollapseClick() {
    this.setState({
      collapsed: this.state.collapsed,
    });
  }

  consultantToValue(code) {
    try {
      const { referrals } = this.props;
      if (referrals) return referrals.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  codeToValue(code, name) {
    try {
      const { masters } = this.props;
      if (masters)
        return masters[name].list.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onchange() {}

  changeDobToAge(dob) {
    if (dob) {
      let dateArray = dob.split("/");
      let today = new Date();
      let changeDate = new Date(dob);
      let difference = today - changeDate;
      let No_of_years = (difference / (1000 * 60 * 60 * 24 * 30 * 12)).toFixed(
        2
      );
      let displayYears = parseInt(No_of_years);
      let No_of_months = (No_of_years - displayYears) * 12;
      let displayMonths = parseInt(No_of_months);
      let No_of_Days = parseInt((No_of_months - displayMonths) * 30);
      return `${displayYears} years, ${displayMonths} months, ${No_of_Days} days`;
    }
    return "";
  }

  render() {
    let { patient, patientData, wardbed } = this.props;
    if (!patient) {
      patient = {};
    }

    return (
      <Fragment>
        <Card>
          <CardBody>
            {patient.patient_uhid && (
              <Col sm="12" md="12" xl="12">
                {/* <div className="card no-shadow rm-border bg-transparent widget-chart text-left" style={{margin:0}}> */}
                <Row style={{ color: "black", marginTop: "25px" }}>
                  <Col sm="2">
                    <div className="icon-wrapper rounded-circle ">
                      <div className="icon-wrapper-bg zoombox" />
                      {patient.confidentiality ? (
                        ""
                      ) : (
                        <img
                          src={`${apiUrl + patient.photo}`}
                          style={{ height: "100%", width: "100%" }}
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm="3">
                    <div className="widget-chart-content">
                      {/* <div className="widget-subheading">
                                Patient Name
                            </div> */}
                      <div
                        className="widget-numbers"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {patient.confidentiality
                          ? " X X X X X"
                          : patient.firstname.concat(
                              " ",
                              patient.middlename,
                              " ",
                              patient.lastname
                            )}
                      </div>
                      <div
                        className="widget-description opacity-8 text-focus"
                        style={{ color: "black" }}
                      >
                        <div className="d-inline text-danger pr-1">
                          <b>
                            {" "}
                            <span className="pl-1">UHID : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : patient.patient_uhid}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <b>
                            <span className="pl-1">MRD No. : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : patient.mrd_no}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <b>
                            <span className="pl-1">Patient Type : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : patient.patient_type &&
                            patient.patient_type.toUpperCase()}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <b>
                            <span className="pl-1">ADM No. : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : patientData?.ipd_admission_no}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <b>
                            <span className="pl-1">ADM Date. : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : (patientData?.admission_date
                              ? moment(patientData?.admission_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "") +
                            " " +
                            (patientData?.admission_time
                              ? patientData?.admission_time
                              : "")}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <b>
                            <span className="pl-1">Payment Type : </span>
                          </b>
                        </div>
                        {patient.confidentiality
                          ? " X X X X X"
                          : patientData?.payment_type}
                        <br />
                      </div>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div className="widget-chart-content">
                      <div className="widget-subheading"></div>
                      <div
                        className="widget-description  opacity-8 text-focus"
                        style={{ color: "black" }}
                      >
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Age</b> :{" "}
                            {patient.confidentiality ? (
                              " X X X X X"
                            ) : (
                              <>
                                {patient.age_in_years}
                                <b> Years </b>
                                {patient.age_in_months}
                                <b> Months </b>
                                {patient.age_in_days}
                                <b> Days</b>
                              </>
                            )}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b> Gender</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : this.codeToValue(
                                  patient.gender_code,
                                  "genders"
                                )}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Mobile</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patient.mobile}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Secondary Contact</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patient.secondary_contact}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Email</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patient.email}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Ward</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patientData?.room_name}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Bed No.</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : wardbed?.find(
                                  (item) => item.code === patientData?.bed_code
                                )?.bed_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>{" "}
                  <Col sm="3">
                    <div className="widget-chart-content">
                      <div
                        className="widget-description  opacity-8 text-focus"
                        style={{ color: "black" }}
                      >
                        {/* <div className="d-inline text-danger pr-1">                                              
                                  <span className="pl-1">Visit Type :  {patient.confidentiality?" X X X X X":patient.visit_type&&patient.visit_type.toUpperCase()} </span>                                             
                              </div>  
                              <br/> */}
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Referal Doctor</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patient.referral_doctor
                              ? this.consultantToValue(patient.referral_doctor)
                              : ""}{" "}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Confidentiality </b>:
                            {patient.confidentiality ? " Yes" : " No"}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Registered As</b> :{" "}
                            {patient.confidentiality
                              ? " X X X X X"
                              : patient?.registering_as
                              ? patient.registering_as.toUpperCase()
                              : null}{" "}
                          </span>
                        </div>

                        {patient.registering_as === "couple" ? (
                          <>
                            {" "}
                            <br />
                            <div className="d-inline text-danger pr-1">
                              <span className="pl-1">
                                <b>Relationship </b>:{" "}
                                {patient.relationship
                                  ? patient.confidentiality
                                    ? "X X X X X"
                                    : patient?.relationship.toUpperCase()
                                  : null}{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {patient &&
                        patient.registering_as === "couple" &&
                        patient.partner_uhid !== "" ? (
                          <>
                            {" "}
                            <br />
                            <div className="d-inline text-danger pr-1">
                              <span className="pl-1">
                                <b>Partner UHID</b> :{" "}
                                {patient.confidentiality
                                  ? " X X X X X"
                                  : patient.partner_uhid}{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>TPA/Corporate Name</b> :{" "}
                            {/* {patient.confidentiality
                              ? " X X X X X"
                              : patient.referral_doctor
                              ? this.consultantToValue(patient.referral_doctor)
                              : ""}{" "} */}
                          </span>
                        </div>
                        <br />
                        <div className="d-inline text-danger pr-1">
                          <span className="pl-1">
                            <b>Policy No.</b> :{" "}
                            {/* {patient.confidentiality
                              ? " X X X X X"
                              : patient.referral_doctor
                              ? this.consultantToValue(patient.referral_doctor)
                              : ""}{" "} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="1">
                    <div className="icon-wrapper">
                      <div className="icon-wrapper-bg" />
                      <img
                        src={`${apiUrl + "/" + patient.qrcode}`}
                        style={{ height: "100%", width: "100%", padding: 5 }}
                      />
                    </div>
                  </Col>
                </Row>
                {/* </div>
                 */}
                {/* <div className="divider m-0 d-md-none d-sm-block"/>                    */}
              </Col>
            )}
            {/* <Row>
                            <Col md={11}>
                                <Table borderless>
                                    <thead>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><label className="bold">Patient UHID : </label> </td>
                                            <td><label className="bold">Name : </label></td>
                                            <td><label className="bold">Contact Details : </label></td>
                                            <td><label className="bold">Secondary Details : </label></td>
                                            <td> <label className="bold">Visit Type : </label></td>
                                        </tr>
                                        <tr>
                                            <td><span >{patient.confidentiality?" X X X X X":patient.patient_uhid}</span></td>
                                            <td><span >{patient.confidentiality ?" X X X X X":`${patient.title? this.codeToValue(patient.title,"titles"):""} ${patient.firstname ? patient.firstname : ""} ${patient.middlename ? patient.middlename : ""} ${patient.lastname ? patient.lastname : ""}`}</span></td>
                                            <td><span >{patient.confidentiality ?" X X X X X":patient.mobile}</span></td>
                                            <td><span>{patient.confidentiality ?" X X X X X":patient.secondary_contact}</span></td>
                                            <td><span> {patient.confidentiality?" X X X X X":" "+patient.patient_type? this.codeToValue(patient.patient_type,"patient_types"):""}</span></td>
                                        </tr>
                                        <tr>
                                            
                                            <td> <label className="bold">Gender/ Age :</label></td>
                                            <td> <label className="bold">Referal Doctor : </label></td>
                                            <td><label className="bold">MRN No. : </label></td>
                                            <td><label className="bold">Confidentiality : </label></td>
                                            <td>{patient.confidentiality  ?<> <label className="bold">Type</label> </>:null}
                                            </td>
                                        </tr>
                                        <tr>
                                            
                                            <td><span> {patient.confidentiality ?" X X X X X":" "+`${patient.gender_code ? this.codeToValue( patient.gender_code,"genders") : ""} / ${this.changeDobToAge(patient.dob)}` }</span></td>
                                            <td><span >{patient.confidentiality ?" X X X X X":" "+patient.referral_doctor?this.consultantToValue(patient.referral_doctor):""}</span></td>
                                            <td>
                                            <span>{patient.confidentiality == "Yes"?" X X X X X":patient.mrn_no}</span>
                                            </td>
                                            <td><div> 
                                                <Switch 
                                                checked={patient ? patient.confidentiality  : ""}

                                                className="mr-2 mb-2"
                                                id="normal-switch"
                                                disabled
                                                />                 
                                            </div></td>
                                            <td><span>
                                            {patient.confidentiality  ?
                                            patient.confidentiality_type
                                            : null}
                                            </span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="bold">Registered As : </label></td>
                                            <td>
                                            {patient.registering_as == "couple"?<> <label className="bold">Relationship : </label>
                                           
                                            </>
                                            : "" }
                                            </td>
                                            <td>{patient.registering_as == "couple"?<><label className="bold">Partner UHID : </label>
       </>:null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <span>
                                                {patient.confidentiality? 'X X X X X': patient.registering_as ? patient.registering_as.toUpperCase() : null} 
                                                </span>
                                            </td>
                                            <td>
                                            <span>
                                           {patient.relationship ? patient.confidentiality? "X X X X X" : patient.relationship.toUpperCase() : null}
                                           </span>
                                            </td>
                                            <td>
                                            <span>{patient ? patient.registering_as=== "couple"? patient.confidentiality ?" X X X X X":" "+patient.partner_uhid : null:null}</span>
                                            <>{
                                                (patient && patient.registering_as=== "couple" && patient.partner_uhid !== "") ?
                                                patient.confidentiality ?" X X X X X": <div> <PartnerDetails style={{margin:"5px"}} partner_uhid={patient ? patient.partner_uhid : null} /> </div>:""}</>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </Table>

                            </Col>
                            <Col md={1}>
                            <img style={{height:110,width:90}} src={patient.photo ? apiUrl + patient.photo : avatar1} alt="" />
                            </Col>
                        </Row> */}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  let {
    consultants,
    patients,
    registrationMaster,
    departments,
    referrals,
    wardManagement,
  } = state;

  return {
    consultants: consultants.consultants,
    patient: patients.selected_patient,
    masters: registrationMaster.data,
    departments: departments.departments,
    referrals: referrals.referrals,
    wardbed: wardManagement.wardbed,
  };
}

const PatientGeneralInfo = connect(mapStateToProps)(PatientGeneralInfo1);

export default PatientGeneralInfo;
