import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { appointmentActions } from "../../actions";

class DeleteAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  initialValues = {
    reason_for_cancellation: "",
  };

  onSubmit = (values) => {
    const { appointment, dispatch } = this.props;
    const { reason_for_cancellation } = values;
    if (appointment) {
      const payload = {
        appointment_id: appointment._id,
        reason: reason_for_cancellation,
      };
      dispatch(appointmentActions.remove(payload));
      this.props.toggle();
    }
  };

  validationSchema = Yup.object({
    reason_for_cancellation: Yup.string().required("Required"),
  });

  render() {
    return (
      <Fragment>
        <Modal
          isOpen={this.props.openModal}
          toggle={this.props.toggle}
          style={{ width: 500 }}
        >
          <ModalHeader toggle={this.props.toggle}></ModalHeader>

          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
            enableReinitialize
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                getFieldProps,
                setFieldValue,
                setFieldTouched,
              } = props;
              {
                console.log(errors);
              }
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <br />
                      <h5>Do you want to cancel this appointment?</h5>
                      <br />
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="cancellation">
                            Select Reason for Cancellation
                          </Label>
                          <CustomInput
                            type="select"
                            name="reason_for_cancellation"
                            {...getFieldProps("reason_for_cancellation")}
                          >
                            <option value="">Select</option>
                            <option value="reason1">Reason 1</option>
                            <option value="reason2">Reason 2</option>
                            <option value="reason3">Reason 3</option>
                            <option value="reason4">Reason 4</option>
                            <option value="other">Other</option>
                          </CustomInput>
                        </FormGroup>
                        {errors.reason_for_cancellation &&
                          touched.reason_for_cancellation &&
                          errors.reason_for_cancellation}
                        <FormGroup></FormGroup>
                      </Col>
                    </Row>
                    {/* <ModalFooter> */}
                    <br />

                    <Row>
                      <Col md={12}>
                        <Button
                          color="warning"
                          className="btn-shadow btn-wide  btn-hover-shine right-side"
                          onClick={this.props.toggle}
                        >
                          Close
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          className="btn-shadow btn-wide  btn-hover-shine right-side"
                          type="submit"
                        >
                          Done
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { appointments } = state;
  return { appointment: appointments.selected_appointment };
}

const connectedDeleteAppointment = connect(mapStateToProps)(DeleteAppointment);
export default connectedDeleteAppointment;
