import { generalMasterConstants } from "../../constants";
import { alertActions } from "../alert.actions";
import { discounTypeService } from "../../services";
import { toast, Bounce } from "react-toastify";

export const discountTypeActions = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    discounTypeService.getAll().then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // dispatch(alertActions.success("Speciality loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.GET_DISCOUNT_TYPE_REQUEST };
  }
  function success(discountTypes) {
    return {
      type: generalMasterConstants.GET_DISCOUNT_TYPE_SUCCESS,
      discountTypes,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.GET_DISCOUNT_TYPE_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    discounTypeService.add(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          // dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.ADD_DISCOUNT_TYPE_REQUEST };
  }
  function success(discountType) {
    return {
      type: generalMasterConstants.ADD_DISCOUNT_TYPE_SUCCESS,
      discountType,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.ADD_DISCOUNT_TYPE_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    discounTypeService.update(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          // dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.UPDATE_DISCOUNT_TYPE_REQUEST };
  }
  function success(discountType) {
    return {
      type: generalMasterConstants.UPDATE_DISCOUNT_TYPE_SUCCESS,
      discountType,
    };
  }
  function failure(error) {
    return { type: generalMasterConstants.UPDATE_DISCOUNT_TYPE_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());
    discounTypeService.remove(payload).then(
      (data) => {
        if (data) {
          dispatch(success(data.value));
          // toast(data.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          // dispatch(alertActions.success(data.message));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: generalMasterConstants.DELETE_DISCOUNT_TYPE_REQUEST };
  }
  function success(discountType) {
    return {
      type: generalMasterConstants.DELETE_DISCOUNT_TYPE_SUCCESS,
      discountType,
    };
  }

  function failure(error) {
    return { type: generalMasterConstants.DELETE_DISCOUNT_TYPE_FAILURE, error };
  }
}
