import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { registrationMasterService } from "../services";
import { customerService } from "../services";
import { opBillingService } from "../services";
import { patientService } from "../services";
import { consultantService } from "../services";
import { apiUrl } from "../constants";
import { helperService } from "../services/helper.service";
import oldBillBody from "../actions/opdbilling.actions";

// const OPAdvanceBillingSchema = db.OPAdvanceBillingSchema;
// const OPBillingSchema = db.OPBillingSchema;

// const NewPatient = db.NewPatient;
// const ReferralSchema = db.ReferralMaster;
// const ConsultantSchema = db.Consultant;
// const CustomerTransactionSchema = db.CustomerTransactionSchema;
// const RegistrationMaster = db.RegistrationMaster;

// const ReferralMaster = db.ReferralMaster;

const isReferral = process.env.isReferral;

export default getBillTemplate;

axios.defaults.headers.common = {
  authorization: helperService.getHeaderData(),
};

var a = [
  "",
  "One ",
  "Two ",
  "Three ",
  "Four ",
  "Five ",
  "Six ",
  "Seven ",
  "Eight ",
  "Nine ",
  "Ten ",
  "Eleven ",
  "Twelve ",
  "Thirteen ",
  "Fourteen ",
  "Fifteen ",
  "Sixteen ",
  "Seventeen ",
  "Eighteen ",
  "Nineteen ",
];
var b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];
function count_duplicate(a) {
  let counts = {};

  for (let i = 0; i < a.length; i++) {
    if (counts[a[i]]) {
      counts[a[i]] += 1;
    } else {
      counts[a[i]] = 1;
    }
  }
  // for (let prop in counts){
  //     if (counts[prop] >= 2){
  //         console.log(prop + " counted: " + counts[prop] + " times.")
  //     }
  // }
  // console.log(counts)
  return counts;
}
function inWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        " "
      : "";
  return str;
}

async function getConsultantTitle(params, masters) {
  try {
    let titles = masters["Titles"].list;
    let title = titles.find((data) => data.id == params).name;

    return title;
  } catch (e) {
    console.log(e);
    return "";
  }
}

async function getPatientGender(params, masters) {
  try {
    let titles = masters["Gender"].list;
    let title = titles.find((data) => data.id == params).name;
    return title;
  } catch (e) {
    console.log(e);
    return "";
  }
}

function addZeros(data) {
  if (data) {
    let splitedData = data.toString().split(".");
    if (splitedData.length < 2) {
      return "₹" + data + ".00";
    } else {
      return "₹" + data;
    }
  } else {
    return "₹" + 0;
  }
}

let opdbillHeadertemplate = `<div class="bill-container">
                        <div class="bill-header">
                        <div class="container .container-table">
                        <div class="form-header">
                        <div >
                          <div >
                            <div class="HospitalDetails d-flex align-items-center" style="margin-top: 1%">
                              <div class="logoImage" style="width: '100%';maxHeight:'90px'">
                                <img class="imageContainer" style="width: '100%';maxHeight:'90px'; margin-bottom: 10%" src=#imageurl>
                              </div>
                              <div class="hospital_header" style="display: inline-grid">
                              <h5 class="hospitalName"><strong>#hospitalname</strong></h5>
                                <h6 class="hospitalAddress">#address<br>Ph No. : #number |
                                email : #email
                                </h6>
                              </div>
                              <div style="float: right;" class="d-none"}>
                                <h5 class="hospitalName">AQUA</h5>
                              </div>
                            </div>
                            <div style="margin-top: 0.5%">
                              <h5 style="margin-left: 0;" class="text-center formBillHeading d-block">#BillHeading</h5>
                            </div>
                          </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div class="container">
                        <div class="patient-details d-flex">
                            <table class="border-less full-width customBillHeader">
                            <tr>
                            <td><label>Patient Name :</label> <span>#patientName</span></td>
                            <td><label>Patient UHID :</label> <span>#patientHID</span></td>  
                          </tr>
                          <tr>
                          <td><label>Age/Gender :</label> <span>#patientAge/#gender</span></td>
                          <td><label>Bill No. :</label> <span>#billNo</span></td>
                          </tr>
                          <tr>
                          <td><label>Mobile No. :</label> <span>#mobileNo</span></td>
                          <td><label>Bill Date :</label> <span>#billDate</span></td>
                          </tr>
                            <tr>
                            <td><label>Consultant :</label> <span>#consultant</span></td>
                            <td><label>Printed On : </label> <span>#Date</span></td>
                             
                            </tr>
                            <tr>
                            <td class=${
                              isReferral === "No" ? "d-none" : ""
                            }><label>Referral : </label>
                            
                            <span>#referral</span>   
                            </td>
                            <td hidden=true>
                            <label>#cancelOrRefundLabel: </label>
                            <span>#cancelOrRefundDate</span>
                            </td>
                            
                            </tr>
                            </table>
                        </div>
                        </div>
                       
                        <div class="container">
							<div class="service-list">
							<table class="border-less table-striped full-width">
							
              <thead>
    <tr>
    <th>
    SR No.
    </th>
    <th>
    Service Name
    </th>
    <th>
    Service Quantity
    </th>
    <th>
    Service Amount
    </th>
    <th>
    Net Amount
    </th>
    </tr>
</thead>
							<tbody>
								#serviceList
							</tbody>
							</table>
							</div>
                            </div>
                            <br>
                <div class="payment-summery">

                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">#totalAdvanceAmountlabel</div>
                <div class="width-20 displayinline">#totalAdvanceAmount</div>
                </div>

                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">#totalamountlabel:</div>
                <div class="width-20 displayinline">#totalamount</div>
                </div>
               
                <div #showTaxAmount class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">Tax:</div>
                <div class="width-20 displayinline">#taxAmount</div>
                </div>
                <div #showDiscountAmount class="full-width">
                  <div class="width-60 displayinlineblock"></div>
                  <div class="width-20 displayinlineblock">Discount:</div>
                  <div class="width-20 displayinline">#discountAmount</div>
                </div>

                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">Net Amount:</div>
                <div class="width-20 displayinline">#netamount</div>
                </div>
                
                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">Paid Amount:</div>
                <div class="width-20 displayinline">#paidamount</div>
                </div>
                
                <div #showShortfallAmount class="full-width">
                  <div class="width-60 displayinlineblock"></div>
                  <div class="width-20 displayinlineblock">Shortfall Amount:</div>
                  <div class="width-20 displayinline">#shortfallAmount</div>
                </div>
                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">Balance Amount :</div>
                <div class="width-20 displayinline">#balanceamount</div>
                </div>

                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">#consumeamountlabel</div>
                <div class="width-20 displayinline">#consumeamount</div>
                </div>

                <div class="full-width">
                <div class="width-60 displayinlineblock"></div>
                <div class="width-20 displayinlineblock">#advanceamountlabel</div>
                <div class="width-20 displayinline">#advanceamount</div>
                </div>

                </div>
                <br>
				<div class="receipt_section">
				#receipt_part
                </div>
                <br>
				
<div class="bill_footer">
<div> Received with Thanks from #patientNameBill a sum of Rs. #totalpaidamountwords Only</div>
<br>
<span class="hospitalfootername">For <span class="bold"> #hospitalName</span> </span>
<span class="hospitalfootersign "><span class="bold">Authorized Signatory : </span> #username</span>
</div>
<div class="textaligncenter bold font9px">
* This is a computer generated receipt and does not require a signature
</div>
`;

let opdbillwithoutheader = `<div class="bill-container">
<h5 class="formHeading">OP Billing Receipt</h5>
<hr/>
<div class="container">
                        <div class="patient-details">
                            <table class="border-less full-width">
                            <tr>
                            <td>Patient Name : #patientName</td>
                            <td>Patient UHID : #patientHID</td>  
                          </tr>
                          <tr>
                          <td>Age/Gender :#patientAge/#gender</td>
                          <td>Bill No. : #billNo</td>
                          </tr>
                          <tr>
                          <td>Mobile No. : #mobileNo</td>
                            <td>Bill Date : #billDate</td>
                            </tr>
                            </table>
                        </div>
                        </div>
                       
                        <div class="container">
<div class="service-list">
<table class="border-less full-width">
<thead>
    <tr>
    <th>
    SR No.
    </th>
    <th>
    Service Name
    </th>
    <th>
    Service Quantity
    </th>
    <th>
    Service Amount
    </th>
    <th>
    Net Amount
    </th>
    </tr>
</thead>
<tbody>
    #serviceList
</tbody>
</table>
</div>
</div>
<div class="payment-summery">
    #paymentSummery
</div>
</div>
<br>
<div class="receipt_section">
#receipt_part
        </div>
        <br>`;

let departmentHeader = `<div class="bill-container">
<div class="bill-header">
    <div class="row">
    <div class="logoImage" style="width: '100%';maxHeight:'90px'">
    <img class="imageContainer" src=#imageurl>
    </div>
    <div class="HospitalDetails">
          <h3 class="hospitalName">GENEA FERTILITY CENTRE</h3>
          <h6 class="hospitalAddress">Address : #address </h6>
          <br>
          
          <h6 class="todayDate">Printed On : #Date</h6>
          <h5 class="formHeading">Initial Assesment Form</h5>
          <h6 class="hospital_no">Hospital Ph No. #number</h6>
    
    </div>
    </div>
</div>
<div class="patient-details">
<table class="border-less full-width">
    <thead>
        <tr>
        <th>
        Patient UHID
        </th>
        <th>
        Patient Name
        </th>
        <th>Age</th>
        <th>Mobile No.</th>
        <th> Bill No</th>
        <th>Bill Date</th>
        
        </tr>
    </thead>
    <tbody>
        <tr>
        <td>
        #patientHID
        </td>
        <td>
            #patientName
        </td>
        <td>
            #patientAge
        </td>
        <td>
            #mobileNo
        </td>
        <td>
            #billNo
        </td>
        <td>
            #billDate
        </td>
       
        </tr>
    </tbody>
</table>
</div>
<div class="container">
<div class="service-list">
<table class="border-less full-width">
<thead>
    <tr>
    <th>
    SR No.
    </th>
    <th>
    Service Name
    </th>
    <th>
    Service Quantity
    </th>
    <th>
    Service Amount
    </th>
    <th>
    Net Amount
    </th>
    </tr>
</thead>
<tbody>
    #serviceList
</tbody>
</table>
</div>
</div>
<div class = "department_summary">
    #departmentsummary
</div>
<div class="payment-summery">
    #paymentSummery
</div>
</div>`;

async function getBillTemplate(req) {
  try {
    let customerTrans = {};
    if (req) {
      let customerTransResponse = await axios.get(
        `${apiUrl}/tenants/details?viewDeleted=true`
      );
      customerTrans = customerTransResponse.data.value[0] || [];
    }
    let billTemplate = opdbillHeadertemplate;
    if (req.printType == "PWD") {
      billTemplate = opdbillHeadertemplate;
    } else if (req.printType == "PWOH") {
      billTemplate = opdbillwithoutheader;
    }
    let totalBillAmount = 0;
    let selectedBill = null;

    let checkBill = req.bill ? req.bill : [];

    // OPBillingSchema.findOne({
    //   $and: [{ bill_no: req.body.billNo }],
    // });
    // console.log(checkBill, "checkBill");
    if (checkBill.status === "advance") {
      selectedBill = req.bill ? req.bill : [];

      // .populate([
      //   {
      //     path: "recipt_no",
      //   },
      // ]);
    } else {
      selectedBill = req.bill ? req.bill : [];
    }
    if (selectedBill) {
      let selectedPatientResponse = await axios.get(
        `${apiUrl}/patients?patientsId=${selectedBill.patient_id.id}&viewDeleted=true`
      );
      let selectedPatient = selectedPatientResponse.data.value[0]
        ? selectedPatientResponse.data.value[0]
        : [];

      // NewPatient.findOne({
      //   patient_uhid: selectedBill.patient_uhid,
      // });
      // let advanceBill = await axios.get(
      //   `${apiUrl}/opbilling/advanceAmount?patientId=${selectedBill.patient_idv.id}&viewDeleted=true`
      // );

      let masters = req.masters;

      let titles = masters["ConsultantTitles"].list;
      let gender = masters["Titles"].list;
      let serviceList = "";
      let doctorfess = "";
      let registrationfess = "";
      let overallService = "";
      let count = 0;
      let apiurl = "https://assets.aquahis.com/assets/images";
      let all_consultant = [];
      selectedPatient.gender = await getPatientGender(
        selectedPatient.gender,
        masters
      );
      billTemplate = billTemplate.replace("#gender", selectedPatient.gender);

      if (selectedPatient.referralDoctor) {
        let referaldoctor = selectedPatient.referaldoctor;

        // ReferralSchema.findOne({
        //   code: selectedPatient.referral_doctor,
        // });
        // console.log(
        //   referaldoctor,
        //   "referaldoctor-------------------------------------------------"
        // );
        if (referaldoctor) {
          billTemplate = billTemplate.replace("#referral", referaldoctor);
        } else {
          billTemplate = billTemplate.replace("#referral", "");
        }
      } else {
        billTemplate = billTemplate.replace("#referral", "");
      }
      if (selectedBill.consultantId) {
        let consultantRespone = await axios.get(
          `${apiUrl}/consultants/${selectedBill.consultantId}`
        );
        let consultant = consultantRespone.data.value
          ? consultantRespone.data.value
          : [];

        // ConsultantSchema.findOne({
        //   code: selectedBill.all_consultant_name,
        // });
        if (consultant) {
          let title = await getConsultantTitle(consultant.title, masters);

          billTemplate = billTemplate.replace(
            "#consultant",
            title + " " + consultant.firstName + " " + consultant.lastName
          );
        } else {
          billTemplate = billTemplate.replace("#consultant", "");
        }
      } else {
        billTemplate = billTemplate.replace("#consultant", "");
        all_consultant = [];
      }
      // if (selectedBill.registrationFee) {
      //   let data = selectedBill.registrationFee;
      //   count++;
      //   totalBillAmount = totalBillAmount + data.opRegistrationFees;
      //   registrationfess =
      //     registrationfess +
      //     `<tr>
      //                   <td>${count}</td>
      //                   <td>Registration Charges (${data.opRegistrationFees *
      //                     (1 -
      //                       (data.discount?.discountPercentage
      //                         ? data.discount?.discountPercentage
      //                         : 0))})</td>
      //                   <td>${data.Qty ? data.Qty : "1"}</td>
      //                   <td>${addZeros(data.opRegistrationFees)}</td>
      //                   <td>${addZeros(data.opRegistrationFees)}</td>
      //                   </tr>`;
      // }
      // if (selectedBill.consultantId) {
      //   selectedBill.doctor_fees.map((data) => {
      //     count++;
      //     totalBillAmount = totalBillAmount + data.amount;
      //     doctorfess =
      //       doctorfess +
      //       `<tr>
      //                   <td>${count}</td>
      //                   <td>${data.type}</td>
      //                   <td>${data.Qty ? data.Qty : "1"}</td>
      //                   <td>${addZeros(data.amount)}</td>
      //                   <td>${addZeros(data.amount)}</td>
      //                   </tr>`;
      //   });
      // }
      if (selectedBill.serviceList) {
        selectedBill.serviceList.map((data) => {
          let consultantBilling = "";
          if (!selectedBill.consultant_all_selected) {
            if (data.consultantCode) {
              let consultant = all_consultant.find(
                (condata) => condata.code == data.consultantCode.code
              );

              if (consultant) {
                let title = "";
                try {
                  title = titles.find((data) => data.code == consultant.title)
                    .name;
                } catch (e) {}
                consultantBilling = `(${title} ${
                  consultant.firstname ? consultant.firstname : ""
                } ${consultant.lastName ? consultant.lastName : ""})`;
              }
            }
          }
          if (data.type == "services") {
            count++;
            totalBillAmount = totalBillAmount + data.Qty * data.SERVICE_AMT;
            serviceList =
              serviceList +
              `<tr>
                            <td>${count}</td>
                            <td>${data.SERVICE_NAME +
                              " " +
                              consultantBilling}</td>
                            <td>${data.Qty ? data.Qty : "1"}</td>
                            <td>${addZeros(data.SERVICE_AMT)}</td>
                            <td>${addZeros(data.netAmount)}</td>
                            </tr>`;
          } else if (data.type == "packages") {
            count++;
            totalBillAmount = totalBillAmount + data.Qty * data.PACKAGE_AMT;
            serviceList =
              serviceList +
              `<tr>
                            <td>${count}</td>
                            <td>${data.PACKAGE_NAME + consultantBilling}</td>
                            <td>${data.Qty ? data.Qty : "1"}</td>
                            <td>${addZeros(data.PACKAGE_AMT)}</td>
                            <td>${addZeros(data.netAmount)}</td>
                            </tr>`;
          } else if (data.type == "groups") {
            count++;
            totalBillAmount = totalBillAmount + data.Qty * data.GROUP_AMT;
            serviceList =
              serviceList +
              `<tr>
                            <td>${count}</td>
                            <td>${data.GROUP_NAME + consultantBilling}</td>
                            <td>${data.Qty ? data.Qty : "1"}</td>
                            <td>${addZeros(data.GROUP_AMT)}</td>
                            <td>${addZeros(data.netAmount)}</td>
                            </tr>`;
          } else {
            count++;
            totalBillAmount =
              totalBillAmount +
              (data.Qty ? data.Qty : 1) * data.SERVICE_AMT +
              (parseFloat(data.gstAmount) || 0);
            serviceList =
              serviceList +
              `<tr>
                            <td>${count}</td>
                            <td>${data.SERVICE_NAME}</td>
                            <td>${data.Qty ? data.Qty : "1"}</td>
                            <td>${addZeros(data.SERVICE_AMT)}</td>
                            <td>${addZeros(data.SERVICE_AMT)}</td>
                            </tr>`;
          }
        });
      }
      let receipt_part = `
				<table class="full-width receipt-form">
				<thead>
				<tr>
				<td>
				Receipt No
				</td>
				<td>
				Receipt Date
				</td>
				<td>
				Receipt Amt
				</td>
				<td>
				Cash
				</td>
				<td>
				Card
				</td>
				<td>
				Cheque
				</td>
				<td>
				Digital
				</td>
				</tr>
				</thead>
                <tbody>`;

      selectedBill.recipt_no.forEach((billdata) => {
        receipt_part =
          receipt_part +
          `<tr>
                    <td>${billdata.receipt_no}</td>
                    <td>${billdata.receipt_date}
                    </td>
                    <td>${addZeros(
                      billdata.cash_amount +
                        billdata.card_amount +
                        billdata.cheque_amount +
                        billdata.digital_amount
                    )}</td>
                    <td>${addZeros(billdata.cash_amount)}</td>
                    
                    <td>${addZeros(billdata.card_amount)}</td>
                    <td>${addZeros(billdata.cheque_amount)}</td>
                    <td>${addZeros(billdata.digital_amount)}</td>
                    <tr>`;
      });
      receipt_part =
        receipt_part +
        `
				</tbody>
				</table>
				`;

      // apiurl = req.protocol + "://" + req.get("host");

      overallService = registrationfess + doctorfess + serviceList;
      billTemplate = billTemplate.replace("#imageurl", customerTrans.logoUrl);

      billTemplate = billTemplate.replace(
        "#hospitalname",
        customerTrans.companyName
      );
      billTemplate = billTemplate.replace(
        "#hospitalName",
        customerTrans.companyName
      );
      billTemplate = billTemplate.replace(
        "#address",
        customerTrans.address.communicationAddress
      );
      billTemplate = billTemplate.replace(
        "#username",
        req.user ? req.user.user_name : "Admin"
      );
      billTemplate = billTemplate.replace("#number", `${customerTrans.phoneNumber} / ${customerTrans.mobileNumber}`);
      billTemplate = billTemplate.replace("#email", customerTrans.email);
      if (selectedBill.bill_type == 3) {
        billTemplate = billTemplate.replace(
          "#BillHeading",
          "OP Advance Receipt"
        );
      } else {
        billTemplate = billTemplate.replace("#BillHeading", "OP Bill Receipt");

        if (
          selectedBill.bill_summary.advance_amount == 0 ||
          selectedBill.bill_summary.advance_amount > 0
        ) {
          billTemplate = billTemplate.replace(
            "#totalAdvanceAmountlabel",
            "Total Advance Amount : "
          );
          billTemplate = billTemplate.replace(
            "#totalAdvanceAmount",
            addZeros(selectedBill.bill_summary.total_advance_amount)
          );
          billTemplate = billTemplate.replace(
            "#advanceamountlabel",
            "Advance Available : "
          );
          billTemplate = billTemplate.replace(
            "#advanceamount",
            addZeros(
              selectedBill.bill_summary.advance_amount -
                selectedBill.bill_summary.consume_advance_amount
            )
          );
          billTemplate = billTemplate.replace(
            "#consumeamountlabel",
            "Consumed Amount : "
          );
          // if (
          //   selectedBill.bill_summary.totalAmount <
          //   selectedBill.bill_summary.advance_amount
          // ) {
          //   billTemplate = billTemplate.replace(
          //     "#consumeamount",
          //     addZeros(selectedBill.bill_summary.totalAmount)
          //   );
          // } else if (
          //   selectedBill.bill_summary.totalAmount <=
          //   selectedBill.bill_summary.advance_amount
          // ) {
          //   billTemplate = billTemplate.replace(
          //     "#consumeamount",
          //     addZeros(selectedBill.bill_summary.consume_advance_amount)
          //   );
          // } else if (
          //   selectedBill.bill_summary.totalAmount >=
          //   selectedBill.bill_summary.advance_amount
          // ) {
          //   billTemplate = billTemplate.replace(
          //     "#consumeamount",
          //     addZeros(selectedBill.bill_summary.consume_advance_amount)
          //   );
          // } else {
          //   billTemplate = billTemplate.replace(
          //     "#consumeamount",
          //     addZeros(selectedBill.bill_summary.advance_amount)
          //   );
          // }
          if (
            selectedBill.bill_summary.total_consume_advance_amount === 0 &&
            selectedBill.bill_summary.total_advance_amount > 0
          ) {
            billTemplate = billTemplate.replace(
              "#consumeamount",
              addZeros(selectedBill.bill_summary.consume_advance_amount)
            );
          } else {
            billTemplate = billTemplate.replace(
              "#consumeamount",
              addZeros(selectedBill.bill_summary.consume_advance_amount)
            );
          }
        } else {
          billTemplate = billTemplate.replace("#advanceamountlabel", "");
          billTemplate = billTemplate.replace("#advanceamount", "");
          billTemplate = billTemplate.replace("#consumeamountlabel", "");
          billTemplate = billTemplate.replace("#consumeamount", "");
          billTemplate = billTemplate.replace("#totalAdvanceAmountlabel", "");
          billTemplate = billTemplate.replace("#totalAdvanceAmount", "");
        }
      }
      if (selectedBill.bill_type == 3) {
        billTemplate = billTemplate.replace("#advanceamountlabel", "");
        billTemplate = billTemplate.replace("#advanceamount", "");
        billTemplate = billTemplate.replace("#consumeamountlabel", "");
        billTemplate = billTemplate.replace("#consumeamount", "");
        billTemplate = billTemplate.replace("#servicetablehead", "");
        billTemplate = billTemplate.replace("#totalAdvanceAmountlabel", "");
        billTemplate = billTemplate.replace("#totalAdvanceAmount", "");

        billTemplate = billTemplate.replace("#serviceList", overallService);
        billTemplate = billTemplate.replace("#receipt_part", receipt_part);
        billTemplate = billTemplate.replace(
          "#totalamountlabel",
          "Total Advance Amount"
        );
      } else {
        billTemplate = billTemplate.replace(
          "#totalamountlabel",
          "Total Bill Amount"
        );
        let servicehead = `<thead>
                    <tr>
                    <th>
                    SR No.
                    </th>
                    <th>
                    Service Name
                    </th>
                    <th>
                    Service Quantity
                    </th>
                    <th>
                    Service Amount
                    </th>
                    <th>
                    Net Amount
                    </th>
                    </tr>
                </thead>`;
        billTemplate = billTemplate.replace("#servicetablehead", servicehead);
        billTemplate = billTemplate.replace("#serviceList", overallService);
        billTemplate = billTemplate.replace("#receipt_part", receipt_part);
      }
      // billTemplate = billTemplate.replace(
      //   "#Date",
      //   moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
      // );

      billTemplate = billTemplate.replace(
        "#Date",
        moment(new Date())
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY hh:mm a")
      );
      billTemplate = billTemplate.replace(
        "#patientHID",
        selectedBill.patient_uhid
      );
      billTemplate = billTemplate.replace(
        "#totalamount",
        addZeros(selectedBill.bill_summary.total_amount)
      );
      billTemplate = billTemplate.replace(
        "#netamount",
        addZeros(selectedBill.bill_summary.net_amount)
      );

      if (selectedBill.isCanceled) {
        billTemplate = billTemplate.replace("hidden=true", "");
        billTemplate = billTemplate.replace(
          "#cancelOrRefundLabel",
          "Cancelled On: "
        );
        billTemplate = billTemplate.replace(
          "#cancelOrRefundDate",
          moment(selectedBill.cancelled_on, "DD/MM/YYYY hh:mm:ss a").format(
            "DD/MM/YY hh:mm a"
          )
        );
      }
      // if (selectedBill.isRefunded) {
      //   billTemplate = billTemplate.replace("hidden=true", "");
      //   billTemplate = billTemplate.replace(
      //     "#cancelOrRefundLabel",
      //     "Refunded On: "
      //   );
      //   billTemplate = billTemplate.replace(
      //     "#cancelOrRefundDate",
      //     moment(selectedBill.cancelled_on).format("MM/DD/YY hh:mm a")
      //   );

      // }

      if (!selectedBill.shortfall_amount) {
        billTemplate = billTemplate.replace(
          "#showShortfallAmount",
          `style="display: none"`
        );
      } else {
        billTemplate = billTemplate.replace(
          "#showShortfallAmount",
          `style="display: block"`
        );
        billTemplate = billTemplate.replace(
          "#shortfallAmount",
          addZeros(selectedBill.shortfall_amount)
        );
      }

      if (!selectedBill.tax) {
        billTemplate = billTemplate.replace(
          "#showTaxAmount",
          `style="display: none"`
        );
      } else {
        billTemplate = billTemplate.replace(
          "#showTaxAmount",
          `style="display: block"`
        );
        billTemplate = billTemplate.replace(
          "#taxAmount",
          addZeros(selectedBill.tax)
        );
      }
      if (!selectedBill.discount_amount) {
        billTemplate = billTemplate.replace(
          "#showDiscountAmount",
          `style="display: none"`
        );
      } else {
        billTemplate = billTemplate.replace(
          "#showDiscountAmount",
          `style="display: block"`
        );
        billTemplate = billTemplate.replace(
          "#discountAmount",
          addZeros(selectedBill.discount_amount)
        );
      }
      billTemplate = billTemplate.replace(
        "#totalpaidamountwords",
        inWords(
          parseInt(
            selectedBill.cash_amount +
              selectedBill.card_amount +
              selectedBill.cheque_amount +
              selectedBill.digital_amount +
              (selectedBill.bill_summary.consume_advance_amount || 0)
          )
        )
      );
      billTemplate = billTemplate.replace(
        "#paidamount",
        addZeros(
          selectedBill.cash_amount +
            selectedBill.card_amount +
            selectedBill.cheque_amount +
            selectedBill.digital_amount +
            (selectedBill.bill_summary.consume_advance_amount || 0)
        )
      );
      billTemplate = billTemplate.replace(
        "#balanceamount",
        addZeros(selectedBill.due_amount)
      );

      if (selectedPatient.confidentiality) {
        billTemplate = billTemplate.replace("#patientName", "xxxxxx");
        billTemplate = billTemplate.replace("#patientNameBill", "xxxxxx");

        billTemplate = billTemplate.replace("#patientAge", "xxxxxx");
        billTemplate = billTemplate.replace("#mobileNo", "xxxxxx");
        billTemplate = billTemplate.replace("#gender", "xxxxxx");
      } else {
        let title = await getConsultantTitle(selectedPatient.title, masters);
        billTemplate = billTemplate.replace(
          "#patientName",
          title + " " +
          selectedPatient.firstName +
            " " +
            selectedPatient.middleName +
            " " +
            selectedPatient.lastname
        );
        billTemplate = billTemplate.replace(
          "#patientNameBill",
          selectedPatient.firstName +
            " " +
            selectedPatient.middleName +
            " " +
            selectedPatient.lastname
        );

        billTemplate = billTemplate.replace(
          "#patientAge",
          selectedPatient.ageInYear
        );
        billTemplate = billTemplate.replace(
          "#mobileNo",
          selectedPatient.patientNumber
        );
        billTemplate = billTemplate.replace("#gender", selectedPatient.gender);
      }

      billTemplate = billTemplate.replace("#billNo", selectedBill.bill_no);
      if (selectedBill.bill_type == 3) {
        billTemplate = billTemplate.replace("Bill No", "OP Receipt No");
      }

      billTemplate = billTemplate.replace(
        "#billDate",
        moment(selectedBill.created_on, "DD/MM/YYYY hh:mm:ss a").format(
          "DD/MM/YY hh:mm a"
        )
      );
    } else {
      return {
        success: true,
        message: "Get Bill Template Successfully",
        data: "<div>Bill No Missing</div>",
      };
    }
    return {
      success: true,
      message: "Get Bill Template Successfully",
      data: billTemplate,
    };
  } catch (e) {
    console.log(e);
    return {
      message: "Server Error",
      success: false,
    };
  }
}
