import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";

export const pharmaStockService = {
  getAll,
  add,
  update,
  remove,
  get,
  getAllreports,
  getCurrentDetailReport,
  getCurrentConsolidateReport,
  getAllDetailReport,
  getClosingConsolidateReport,
  getStockReport,
  getDrugName,
  getStockByDate
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaStock`, requestOptions).then(
    helperService.handleResponse
  );
}

function getCurrentDetailReport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/currentdetailreport`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getCurrentConsolidateReport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/currentconsolidatereport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllDetailReport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/closingdetailreport`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getClosingConsolidateReport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/closingconsolidatereport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllreports(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/report?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getStockReport() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaStock/stockreport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getDrugName(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaStock/stockreport?product_id=${id}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getStockByDate(id, from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaStock/stockreport?product_id=${id}&from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaStock/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaStock`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaStock/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaStock/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

//search stock
function search(name) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaStock/?name=${name}`, requestOptions).then(
    helperService.handleResponse
  );
}
