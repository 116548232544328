//import { authHeader } from '../store';
import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"



export const storeService = {        
    getAll,
    add,
    update,
    remove,
    get,
    getMaster
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/stores`, requestOptions).then(helperService.handleResponse);
}

function getMaster() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/stores/getMaster`, requestOptions).then(helperService.handleResponse);
}


function get(code) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/stores/${code}`, requestOptions).then(helperService.handleResponse);
}


function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{"authorization" : helperService.getHeaderData()},   
        body:data
    };

    return fetch(`${apiUrl}/stores`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{"authorization" : helperService.getHeaderData()},   
        body:data
    };
   
    return fetch(`${apiUrl}/stores/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/stores/delete`, requestOptions).then(helperService.handleResponse);
}

