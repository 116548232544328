import moment from "moment";
import React, { Component, Fragment } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { customerActions } from "../../actions";
import { getAppointmentStatus } from "../../constants/appointmentStatus";
import "../PatientRegistration/printform.css";
import { Icons } from "../../helpers/IconHelper";

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    const { dispatch, report, transaction, customer, consultants } = this.props;
      dispatch(
        customerActions.getTenantDetails()
      );
  }

  serviceToName(code) {
    const { packageData } = this.props;
    if (packageData && packageData.services) {
      let serv = packageData.services.find((item) => item.CODE === code);
      return serv ? serv.SERVICE_NAME : code;
    }
    return "";
  }

  consultantToFirstName(code) {
    const { consultants } = this.props;
    if (consultants) {
      let cons = consultants.find((item) => item.id === code);
      return cons ? "Dr. " + cons.firstname : "";
    } else return code;
  }

  render() {
    const { appointments, customer } = this.props;
    return (
      <Fragment>
        <Modal
          isOpen={this.props.openModal}
          toggle={this.props.toggle}
          className={this.props.className}
          style={{ minWidth: "840px" }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Appointments Report
          </ModalHeader>
          <ModalBody>
            <div style={{ textAlign: "left", marginTop: "-8px" }}>
              <ReactToPrint
                trigger={() => (
                  <Button className="mb-2 mr-2 btn-icon" color="success">
                    <i className="pe-7s-user btn-icon-wrapper"> </i>
                    Print Appointment List
                  </Button>
                )}
                content={() => this.componentRef}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: "17px",
                 // boxSizing: "content-box",
                }}
              >
                <div
                  className="report-page"
                  ref={(el) => (this.componentRef = el)}
                >
                  {customer ? (
                    <div name="individualReport">
                      
                        <div style={{ width: "15%", float: "left" }}>
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          >
                            <img
                              style={{ width: "100%", maxHeight: "90px" }}
                              src={
                               customer.logoUrl
                                  ? customer.logoUrl
                                  : Icons.Hospital
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            float: "left",
                            width: "70%",
                            paddingTop: "10px",
                          }}
                        >
                          <h4>{customer.companyName} </h4>
                          <p>{customer.address.communicationAddress}</p>
                        </div>
                        <div style={{ width: "15%", float: "right" }}>
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              float: "left",
                            }}
                          ></div>
                          </div>
                        <div className="clearfix"></div>
                      
                      <div className="line"></div>

                      <div className="padd-10 border">
                        {appointments && (
                          <table
                              border={1}
                            style={{ width: "100%" }}
                            cellPadding="2"
                          >
                            <thead>
                              <tr>
                                <th>Sno</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Consultant</th>
                                <th>Patient Name</th>
                                <th>Patient UHID</th>
                                <th>Appointment Type</th>
                                <th>Service Name</th>
                                <th>Planned Procedure</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            {appointments.map((item, i) => {
                              let timeSlot = new Date(item.time_slot_from);
                              let getMins = timeSlot.setMinutes(
                                timeSlot.getMinutes() + item.slot_duration
                              );
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {moment(item.appointment_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item.time_slot_from).format(
                                      "hh:mm a"
                                    ) +
                                      " - " +
                                      moment(getMins).format("hh:mm a")}
                                  </td>
                                  <td>
                                    {this.consultantToFirstName(
                                      item.consultant_id
                                    )}
                                  </td>
                                  <td>
                                    {item.patient_id
                                      ? item.patient_id.firstname +
                                        " " +
                                        (item.patient_id.lastname
                                          ? item.patient_id.lastname
                                          : "")
                                      : ""}
                                  </td>
                                  <td>{item.patient_id.uhid}</td>
                                  <td>
                                    {item.appointment_type === 2
                                      ? "Appointment"
                                      : "Walk In"}
                                  </td>
                                  <td>
                                    {item.service_type === "service"
                                      ? this.serviceToName(
                                          item.planned_procedure
                                        )
                                      : ""}
                                  </td>
                                  <td>{item.procedure_notes}</td>
                                  <td>{getAppointmentStatus(item.status)}</td>
                                </tr>
                              );
                            })}
                          </table>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>No Data Found!</>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.props.toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { customers, appointments, serviceMaster, consultants } = state;
  return {
    customer: customers.selected_customer,
    appointments: appointments.appointments,
    packageData: serviceMaster.data,
    consultants: consultants.consultants,
  };
}

const connectedPrintPreview = connect(mapStateToProps)(PrintPreview);

export default connectedPrintPreview;
