//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const registrationWithFeesService = {
  getAll,
  add,
  update,
  updateRegFee,
  deleteMaster,
  getConsAll,
  addConsFess,
  deleteConsFees,
  updateConsFees,
  getService,
  // addService,
  // deactivateMaster,
  getConsByRegCode,
};

function getService(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
    // body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/regtypewisecharge/${data.id}?applicableTo=${data.applicableTo}&viewDeleted=${data.viewDeleted}`,
    requestOptions
  ).then(helperService.handleResponse);
}

// function addService(data) {
//   console.log("Loggin from service", data);
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       authorization: helperService.getHeaderData(),
//     },
//     body: JSON.stringify(data),
//   };
//   console.log("Loggin from service", requestOptions);
//   return fetch(`${apiUrl}/opregistrationservice`, requestOptions).then(
//     helperService.handleResponse
//   );
// }

function getAll(viewDeleted = true) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/registrationfees?viewDeleted=${viewDeleted}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function add(data) {
  //console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  // console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/RegistrationFees`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/regtypewisecharge/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateRegFee(data) {
  //console.log("Loggin from service", data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  //console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/registrationfees/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}
function deleteMaster(data) {
  //console.log("Loggin from service", data);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  //console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/RegistrationFees/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

// function deactivateMaster(data) {
//   console.log("Loggin from service", data);
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       authorization: helperService.getHeaderData(),
//     },
//     body: JSON.stringify(data),
//   };
//   console.log("Loggin from service", requestOptions);
//   return fetch(`${apiUrl}/opregistration/deactive`, requestOptions).then(
//     helperService.handleResponse
//   );
// }

function getConsAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/opdregconsultantfee`, requestOptions).then(
    helperService.handleResponse
  );
}

function getConsByRegCode(data) {
  const requestOptions = {
    method: "POST",
    headers: { authorization: helperService.getHeaderData() },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opregistrationfess/getByConsRegCode`,
    requestOptions
  ).then(helperService.handleResponse);
}

function addConsFess(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/opdregconsultantfee`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteConsFees(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/opdregconsultantfee/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function updateConsFees(data) {
  console.log("Loggin from service", data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  console.log("Loggin from service", requestOptions);
  return fetch(`${apiUrl}/opdregconsultantfee/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}
