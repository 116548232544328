
import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"

export const locationService = {  

    addCountry,  
    updateCountry,  
    getCountries,
    deleteCountry,

    addState,
    updateState,
    getStates,
    deleteState,

    addDistrict,
    getDistricts,
    updateDistrict,
    deleteDistrict,

    addCity,
    updateCity,
    deleteCity,
    getCities,

    addLocation,
    updateLocation,
    deleteLocation,
    getLocations,

    addPincode,
    updatePincode,
    deletePincode,
    getPincodes,

    // update,
    // _delete

   };


function addCountry(data) {

    const requestOptions = {
        method: 'POST',
        headers:{"authorization" : helperService.getHeaderData()},
        body:data
    };
  
    return fetch(`${apiUrl}/locations/addCountry`, requestOptions).then(helperService.handleResponse);
}

function updateCountry(data) {

    const requestOptions = {
        method: 'POST',
        headers:{"authorization" : helperService.getHeaderData()},
        body:data
    };
  
    return fetch(`${apiUrl}/locations/updateCountry`, requestOptions).then(helperService.handleResponse);
}


function getCountries() {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/locations?countries=true`, requestOptions).then(helperService.handleResponse);
}

function deleteCountry(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/removeCountry`, requestOptions).then(helperService.handleResponse);
}

function addState(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/addState`, requestOptions).then(helperService.handleResponse);
}

function updateState(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/updateState`, requestOptions).then(helperService.handleResponse);
}

function deleteState(data) {
    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/deleteState`, requestOptions).then(helperService.handleResponse);
}

function getStates(country) {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/locations?countryId=${country}`, requestOptions).then(helperService.handleResponse);
}


function addDistrict(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/addDistrict`, requestOptions).then(helperService.handleResponse);
}
function updateDistrict(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/updateDistrict`, requestOptions).then(helperService.handleResponse);
}

function deleteDistrict(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/deleteDistrict`, requestOptions).then(helperService.handleResponse);
}

function getDistricts(state) {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/locations?stateId=${state}`, requestOptions).then(helperService.handleResponse);
}

function addCity(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/addCity`, requestOptions).then(helperService.handleResponse);
}

function updateCity(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/updateCity`, requestOptions).then(helperService.handleResponse);
}

function deleteCity(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/deleteCity`, requestOptions).then(helperService.handleResponse);
}

function getCities(district) {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/locations?districtId=${district}`, requestOptions).then(helperService.handleResponse);
}

function addLocation(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/addLocation`, requestOptions).then(helperService.handleResponse);
}

function updateLocation(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/updateLocation`, requestOptions).then(helperService.handleResponse);
}

function deleteLocation(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/deleteLocation`, requestOptions).then(helperService.handleResponse);
}

function getLocations(city) {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/locations?cityId=${city}`, requestOptions).then(helperService.handleResponse);
}


function addPincode(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/addPincode`, requestOptions).then(helperService.handleResponse);
}

function updatePincode(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/updatePincode`, requestOptions).then(helperService.handleResponse);
}

function deletePincode(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
  
    return fetch(`${apiUrl}/locations/deletePincode`, requestOptions).then(helperService.handleResponse);
}

function getPincodes(location) {
    const requestOptions = {
        method: 'GET',
        headers:{ "authorization" : helperService.getHeaderData()},
    };
    return fetch(`${apiUrl}/locations?locationId=${location}`, requestOptions).then(helperService.handleResponse);
}

// function update(data) {

//     const requestOptions = {
//         method: 'POST',
//         headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
//         body:JSON.stringify(data)
//     };
  
//     return fetch(`${apiUrl}/locations/update`, requestOptions).then(helperService.handleResponse);
// }

// function _delete(data) {

//     const requestOptions = {
//         method: 'POST',
//         headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()},
//         body:JSON.stringify(data)
//     };
  
//     return fetch(`${apiUrl}/locations/delete`, requestOptions).then(helperService.handleResponse);
// }
