import { clinicConstants } from "../constants";

export function clinics(state = { loading: false, clinics: [] }, action) {
  switch (action.type) {
    case clinicConstants.SELECT_CLINIC_REQUEST:
      return { ...state, loading: true };
    case clinicConstants.SELECT_CLINIC_SUCCESS:
      let selected_CLINIC = state.clinics.find(
        (ob) => ob._id === action.clinicId
      );
      return { ...state, selected_CLINIC: selected_CLINIC, loading: false };

    case clinicConstants.SELECT_CLINIC_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clinicConstants.GET_ALL_CLINIC_REQUEST:
      return { ...state, loading: true };
    case clinicConstants.GET_ALL_CLINIC_SUCCESS:
      return { clinics: action.clinics, loading: false };
    case clinicConstants.GET_ALL_CLINIC_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clinicConstants.ADD_CLINIC_REQUEST:
      return { ...state, loading: true };
    case clinicConstants.ADD_CLINIC_SUCCESS:
      return { clinics: [...state.clinics, action.clinic], loading: false };
    case clinicConstants.ADD_CLINIC_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clinicConstants.UPDATE_CLINIC_REQUEST:
      return { ...state, loading: true };
    case clinicConstants.UPDATE_CLINIC_SUCCESS:
      let updatedState = action.clinic;
      let index = state.clinics.findIndex(
        (ob) => ob.code === updatedState.code
      );
      state.clinics[index] = updatedState;
      return { ...state, clinics: [...state.clinics], loading: false };

    case clinicConstants.UPDATE_CLINIC_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clinicConstants.DELETE_CLINIC_REQUEST:
      return { ...state, loading: true };

    case clinicConstants.DELETE_CLINIC_SUCCESS:
      let deletedclinic = action.clinic;
      let cindex = state.clinics.findIndex(
        (ob) => ob.code === deletedclinic.code
      );
      state.clinics.splice(cindex, 1);
      return { ...state, clinics: [...state.clinics], loading: false };
    case clinicConstants.DELETE_CLINIC_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
