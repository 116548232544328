//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const ageWiseMasterService = {
  getAll,
  add,
  update,
  remove,
  deactive,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/AgeWiseMasters`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/AgeWiseMasters`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/AgeWiseMasters/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/AgeWiseMasters/Delete/${data.id}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function deactive(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/ageWiseMaster/deactive`, requestOptions).then(
    helperService.handleResponse
  );
}
