import { registrationMasterConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { registrationMasterService } from "../services";
import { toast, Bounce } from "react-toastify";

export const registrationMasterActions = {
  getAll,
  add,
  update,
  setdefault,
  deleteMaster,
  getForMaster,
};

const EndpointMapping = {
  Titles: 1,
  ConsultantTitles: 2,
  MaritalStatus: 3,
  Nationality: 4, //needed
  Religion: 5, //needed
  Ages: 6, // not needed
  BloodGroup: 7,
  Gender: 8, //needed
  Identification: 9,
  Relationships: 10,
  ReasonsForVisit: 11,
  HowDidYouGetToKnowUs: 12,
  Languages: 13, //needed
  ConfidentialityTypes: 14, //needed
  Ocupations: 15,
  ConsultantCategory: 16,
  PatientTypes: 17,
  OpVisitTypes: 18,
  IpVisitTypes: 19,
  RevenueSource: 20,
  Educations: 21,
  PaymentTypes: 22,
  PaymentModes: 23,
};

const EndpointArray = [
  "Titles",
  "ConsultantTitles",
  "MaritalStatus",
  "Nationality",
  "Religion",
  "Ages",
  "BloodGroup",
  "Gender",
  "Identification",
  "Relationships",
  "ReasonsForVisit",
  "HowDidYouGetToKnowUs",
  "Languages",
  "ConfidentialityTypes",
  "Ocupations",
  "ConsultantCategory",
  "PatientTypes",
  "OpVisitTypes",
  "IpVisitTypes",
  "RevenueSource",
  "Educations",
  "PaymentTypes",
  "PaymentModes",
];

function getAll(viewDeleted = false) {
  return (dispatch) => {
    dispatch(request());
    registrationMasterService.getAll(viewDeleted).then(
      (data) => {
        if (data) {
          const finalData = {};
          for (const tempObj of data.value) {
            finalData[EndpointArray[tempObj.type - 1]] = createObj(
              EndpointArray[tempObj.type - 1],
              tempObj.record
            );
          }
          console.log(finalData);
          dispatch(success(finalData));
          dispatch(alertActions.success("Masters loaded"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function createObj(name, data) {
    const obj = {
      name: insertSpaces(name),
      image: `/icons/masters/title.png`,
      list: data,
      default: EndpointMapping[name],
    };
    return obj;
  }

  function insertSpaces(str) {
    let result = str[0]; // Start with the first character of the string

    // Iterate over each character starting from the second character
    for (let i = 1; i < str.length; i++) {
      // Check if the current character is a capital letter
      if (str[i] === str[i].toUpperCase()) {
        // If it's a capital letter, add a space before it
        result += " ";
      }
      // Add the current character to the result string
      result += str[i];
    }

    return result;
  }

  function request() {
    return { type: registrationMasterConstants.GET_ACTIVE_REQUEST };
  }
  function success(data) {
    return { type: registrationMasterConstants.GET_ACTIVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: registrationMasterConstants.GET_ACTIVE_FAILURE, error };
  }
}

function getForMaster(payload) {
  return (dispatch) => {
    dispatch(request());
    registrationMasterService
      .getAllMasters(
        (payload = { ...payload, key: EndpointMapping[payload.key] })
      )
      .then(
        (data) => {
          if (data) {
            dispatch(success(data));
            dispatch(alertActions.success("Masters loaded"));
          } else dispatch(failure(data.err));
        },
        (error) => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: registrationMasterConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return { type: registrationMasterConstants.GET_ALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: registrationMasterConstants.GET_ALL_FAILURE, error };
  }
}

function add(type, payload) {
  return (dispatch) => {
    dispatch(request());
    payload = { ...payload, settingsType: EndpointMapping[type] };
    registrationMasterService.add(payload).then(
      (result) => {
        if (result) {
          dispatch(success(EndpointMapping[type], result.value));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result));
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: registrationMasterConstants.ADD_REQUEST };
  }
  function success(type, data) {
    return {
      type: registrationMasterConstants.ADD_SUCCESS,
      data: { type, data },
    };
  }
  function failure(error) {
    return { type: registrationMasterConstants.ADD_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());

    registrationMasterService.update(payload).then(
      (result) => {
        if (result) {
          dispatch(success(result.value));
          dispatch(alertActions.success(result));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: registrationMasterConstants.UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: registrationMasterConstants.UPDATE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return { type: registrationMasterConstants.UPDATE_FAILURE, error };
  }
}
function setdefault(payload) {
  return (dispatch) => {
    dispatch(request());
    //console.log(payload);
    registrationMasterService.setDefault(payload).then(
      (result) => {
        console.log(result);
        if (result) {
          dispatch(success(payload.id, result.value));
          dispatch(alertActions.success(result.message));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: registrationMasterConstants.SET_DEFAULT_REQUEST };
  }
  function success(type, data) {
    return {
      type: registrationMasterConstants.SET_DEFAULT_SUCCESS,
      data: { type: type, data },
    };
  }
  function failure(error) {
    return { type: registrationMasterConstants.SET_DEFAULT_FAILURE, error };
  }
}
function deleteMaster(payload) {
  return (dispatch) => {
    dispatch(request());

    registrationMasterService.deleteMaster(payload).then(
      (result) => {
        if (result) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
        } else dispatch(failure(result.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: registrationMasterConstants.DELETE_REQUEST };
  }
  function success(data) {
    return {
      type: registrationMasterConstants.DELETE_SUCCESS,
      data: data,
    };
  }
  function failure(error) {
    return { type: registrationMasterConstants.DELETE_FAILURE, error };
  }
}
