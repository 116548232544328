import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputFlexComponent from "../../utils/InputFlexComponent/InputFlexComponent";
import moment from "moment";
import { httpHelperService } from "../../../services/http.helper.service";
import { Bounce, toast } from "react-toastify";
import { loginservice } from "../../../services/login.service";
import {useHistory} from 'react-router-dom'
 
const SignupSchema = Yup.object().shape({
  username: Yup.string().required("Reason is Required"),
  password: Yup.string().required("Reason is Required"),
  reason: Yup.string().required("Reason is Required"),
});

const AdmissionCancelModal = (props) => {
  const [initialValues, setInitialValues] = useState({});
  const [modal, setModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loginDetails, setLoginDetails] = useState({})
  const history = useHistory();

  const toggle = () => {
    setModal(!modal);
  };

  const toggleCancel = () => {
    setCancelModal(!cancelModal);
  };

  const onChangeText = (e) => {
    setLoginDetails({...loginDetails, [e.target.name]: e.target.value });
  }

  const onChange = (e) => {
    setInitialValues({...initialValues, [e.target.name] : e.target.value})
  }

  const onLogin = () => {
    const payload = loginDetails;

    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          toggle()
          toggleCancel()
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  const submitForm = (values) => {
    const payload = {
      ipd_admission_no: props.rowData.ipd_admission_no,
      cancel_date: moment(new Date()).format("YYYY-MM-DD"),
      cancel_time: moment(new Date()).format("HH:mm:ss"),
      cancel_reason: values.reason,
    };
    let url = "api/ipadmission/cancelAdmittedPatient";
    httpHelperService
      .postRequest(payload, url)
      .then((res) => {
        console.log(res);
        if (res.success) {
          toggleCancel()
          toast(res.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
          history.push({
            pathname: `/in-patient-department/ipd-cancel-list`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Button color="primary" onClick={toggle}>
        Cancel Admit
      </Button>
      <Modal className="modal-content" isOpen={cancelModal} toggle={toggleCancel}>
        <ModalHeader style={{ borderRadius: "20px" }} toggle={toggleCancel}>
          Admission Cancel
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={SignupSchema}
              onSubmit={submitForm}
            >
              {({ errors, touched, values, setFieldValue }) => {
                // console.log(values);
                return (
                  <Form style={{ padding: "40px" }}>
                    <Row form>
                      <Col md={12}>
                        {/* <Label style={{ color: "red" }}>
                          For this patient POS amount is RS:850.00
                        </Label> */}
                        <FormGroup>
                          <InputFlexComponent
                            label="username"
                            star="*"
                            tag={Field}
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={(e) =>
                              onChange(e)
                            }
                            placeholder="Enter username"
                            className={
                              errors.username && touched.username
                                ? "invalid"
                                : ""
                            }
                            errors={errors.username}
                            touched={touched.username}
                          />
                        </FormGroup>

                        <FormGroup>
                          {/* <Label for="password">Password</Label> */}
                          <InputFlexComponent
                            type="password"
                            label="Password"
                            tag={Field}
                            star="*"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={(e) =>
                              onChange(e)
                            }
                            className={
                              errors.password && touched.password
                                ? "invalid"
                                : ""
                            }
                            errors={errors.password}
                            touched={touched.password}
                          />
                        </FormGroup>
                        <FormGroup>
                          <InputFlexComponent
                            label="Reason"
                            star="*"
                            tag={Field}
                            type="textarea"
                            name="reason"
                            value={values.reason}
                            onChange={(e) =>
                              onChange(e)
                            }
                            placeholder="Enter Reason"
                            className={
                              errors.reason && touched.reason ? "invalid" : ""
                            }
                            errors={errors.reason}
                            touched={touched.reason}
                          />
                        </FormGroup>
                        {/* <Label style={{ color: "red" }}>
                          Are you sure you want to cancel
                        </Label>
                        <FormGroup tag="fieldset" className="radio-btn-flex">
                          <FormGroup check className="radio-btn-group">
                            <Input
                              className="radio-btn"
                              type="radio"
                              name="yes" 
                            />
                            <Label className="radio-btn-label" check>
                              YES
                            </Label>
                          </FormGroup>
                          <FormGroup check className="radio-btn-group">
                            <Input
                              className="radio-btn"
                              type="radio"
                              name="no"
                            />
                            <Label className="radio-btn-label" check>
                              NO
                            </Label>
                          </FormGroup>
                        </FormGroup> */}
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{
                          width: "150px",
                          backgroundColor: "#f2f2f2",
                          color: "black",
                          marginTop: "20px",
                        }}
                        onClick={() => toggleCancel()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        style={{
                          width: "150px",
                          backgroundColor: "red",
                          marginTop: "20px",
                        }}
                      >
                        Cancel Admit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        </ModalBody>
        {/* <ModalFooter>
          <Button type="submit" color="primary" onClick={toggleCancel}>
            Update Address
          </Button>
        </ModalFooter> */}
      </Modal>
      <Modal
                  isOpen={modal}
                  toggle={() => toggle()}
                  className={props.className}
                  style={{ width: 400 }}
                >
                  <ModalHeader toggle={() => toggle()}>
                    Authentication Required!{" "}
                  </ModalHeader>
                  <ModalBody>
                    <Row form>
                      <Col md={8} sm={8} className="offset-sm-1">
                        <FormGroup>
                          <Label for="username">Username</Label>
                          <Input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Username"
                            onChange={(e) => {
                              onChangeText(e);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="password">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => {
                              onChangeText(e);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="reason">Reason for Cancel</Label>
                          <Input
                            type="text"
                            name="reason"
                            placeholder="Reason for Cancel"
                            onChange={(e) => {
                              onChangeText(e);
                            }}
                          />
                        </FormGroup>
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}></Col>
                      <Col md={2}>
                        {" "}
                        <Button color="link" onClick={() => toggle()}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2}>
                        {" "}
                        <Button color="primary" onClick={() => onLogin()}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
    </span>
  );
};

export default AdmissionCancelModal;
