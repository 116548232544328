import { generalMasterConstants } from "../constants";
let index = 0;
let updatedState = null;
export function departments(state = { departments: [] }, action) {
  switch (action.type) {
    case generalMasterConstants.GET_DEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: action.data,
        favorite_department: action.data.favorite_department,
        loading: false,
      };
    case generalMasterConstants.GET_DEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_DEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [...state.departments, action.department],
        loading: false,
      };
    case generalMasterConstants.ADD_DEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };
    case generalMasterConstants.SET_SELECTED_DEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.SET_SELECTED_DEPARTMENT_SUCCESS:
      const selected_department = {
        ...action.data.department,
        subdepartments: action.data.subdepartment,
      };
      return { ...state, selected_department, loading: false };
    case generalMasterConstants.SET_SELECTED_DEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };
    case generalMasterConstants.UPDATE_DEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_DEPARTMENT_SUCCESS:
      let updatedDepartment = action.department;
      index = state.departments.findIndex(
        (ob) => ob.code === updatedDepartment.code
      );
      state.departments[index] = updatedDepartment;
      return { ...state, departments: [...state.departments], loading: false };
    case generalMasterConstants.UPDATE_DEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_DEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_DEPARTMENT_SUCCESS:
      let deleteddepartment = action.data;
      let cindex = state.departments.findIndex(
        (ob) => ob.code === deleteddepartment.code
      );
      state.departments.splice(cindex, 1);
      return { ...state, departments: [...state.departments], loading: false };
    case generalMasterConstants.DELETE_DEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_SUBDEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_SUBDEPARTMENT_SUCCESS:
      const subdepartments = [
        ...state.selected_department.subdepartments,
        action.subdepartment,
      ];
      return {
        ...state,
        selected_department: { ...state.selected_department, subdepartments },
        loading: false,
      };
    case generalMasterConstants.ADD_SUBDEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_SUBDEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_SUBDEPARTMENT_SUCCESS:
      updatedState = action.subdepartment;
      index = state.selected_department.subdepartments.findIndex(
        (ob) => ob.id === updatedState.id
      );
      state.selected_department.subdepartments[index] = updatedState;
      return {
        ...state,
        selected_department: {
          ...state.selected_department,
          subdepartments: [...state.selected_department.subdepartments],
        },
        loading: false,
      };
    case generalMasterConstants.UPDATE_SUBDEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_SUBDEPARTMENT_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_SUBDEPARTMENT_SUCCESS:
      const status = state.selected_department.subdepartments[action.index].deleted; // current state deleted
      state.selected_department.subdepartments[action.index].deleted = !status;

      return {
        ...state,
        selected_department: {
          ...state.selected_department,
          subdepartments: [...state.selected_department.subdepartments],
        },
        loading: false,
      };
    case generalMasterConstants.DELETE_SUBDEPARTMENT_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
}
