
    export const referralConstants={

       ADD_REFERRAL_REQUEST:'ADD_REFERRAL_REQUEST',
       ADD_REFERRAL_SUCCESS:'ADD_REFERRAL_SUCCESS',
       ADD_REFERRAL_FAILURE:'ADD_REFERRAL_FAILURE' ,

       GET_ALL_REFERRAL_REQUEST:'GET_ALL_REFERRAL_REQUEST',
       GET_ALL_REFERRAL_SUCCESS:'GET_ALL_REFERRAL_SUCCESS',
       GET_ALL_REFERRAL_FAILURE:'GET_ALL_REFERRAL_FAILURE' ,

       UPDATE_REFERRAL_REQUEST:'UPDATE_REFERRAL_REQUEST',
       UPDATE_REFERRAL_SUCCESS:'UPDATE_REFERRAL_SUCCESS',
       UPDATE_REFERRAL_FAILURE:'UPDATE_REFERRAL_FAILURE' ,

       DELETE_REFERRAL_REQUEST:'DELETE_REFERRAL_REQUEST',
       DELETE_REFERRAL_SUCCESS:'DELETE_REFERRAL_SUCCESS',
       DELETE_REFERRAL_FAILURE:'DELETE_REFERRAL_FAILURE' ,

       SELECT_REFERRAL_REQUEST:'SELECT_REFERRAL_REQUEST',
       SELECT_REFERRAL_SUCCESS:'SELECT_REFERRAL_SUCCESS',
       SELECT_REFERRAL_FAILURE:'SELECT_REFERRAL_FAILURE' ,

       DEACTIVE_REFERRAL_REQUEST:'DEACTIVE_REFERRAL_REQUEST',
       DEACTIVE_REFERRAL_SUCCESS:'DEACTIVE_REFERRAL_SUCCESS',
       DEACTIVE_REFERRAL_FAILURE:'DEACTIVE_REFERRAL_FAILURE',
       
       REFERRAL_CLEAR:'REFERRAL_CLEAR'

    
    }