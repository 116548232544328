import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"


export const accessListService = {  
    getAll   
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/accesslist/getList`, requestOptions).then(helperService.handleResponse);
}