import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Col, Input, Row } from "reactstrap";
import { apiUrl } from "../../constants";
import { helperService } from "../../services/helper.service";

function getValues(obj, getString = true, seprator = "•") {
  let objval = obj_values(obj);
  if (getString) {
    return objval
      .join(seprator)
      .replace(/true|false|undefined|null:/gi, (x) => "")
      .trim();
  }
  return objval;

  function obj_values(ob) {
    if (ob instanceof Object) {
      let values = Object.values(ob);
      let val = values.reduce((f, v) => {
        if (v instanceof Object) {
          return [...f, ...obj_values(v)];
        }
        return [...f, v];
      }, []);
      return Array.from(new Set(val));
    }

    return ob;
  }
}

class MyDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      textSearch: "",
      width: "__",
    };

    this.updateFilterData = this.updateFilterData.bind(this);
  }
  componentDidMount() {}
  search_onChange(e) {
    let value = e.target.value;
    this.updateFilterData(value);
  }

  updateFilterData(value) {
    let data = this.props.data;
    let filterData = data.filter((item) => {
      let str =
        item?.firstname?.toLowerCase() + " " + item?.lastname?.toLowerCase();
      return str.includes(value.toLowerCase());
    });
    this.setState({ ...this.state, filterData, textSearch: value });
    this.props.onSearchValueChanged(value);
  }

  componentDidUpdate(p, s) {
    if (p.data?.length !== this.props.data?.length && s.filterData != null) {
    }

    const propsData = s.filterData.filter((o1) =>
      this.props.data.some(
        (o2) => o1.deleted !== o2.deleted && o1._id === o2._id
      )
    );
    if (propsData.length > 0) {
      this.updateFilterData(s.textSearch);
    }
    if (p.searchValue !== this.props.searchValue) {
      this.search_onChange({ target: { value: this.props.searchValue } });
    }
  }

  timeout = null;

  onKeyUp(e) {
    e.persist();
    this.timeout = setTimeout(() => {
      let data = e.target.value;
      const options = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: helperService.getHeaderData(),
        },
        body: JSON.stringify({ data: data }),
      };
      fetch(`${apiUrl}/scanqrcode`, options)
        .then((res) => res.json())
        .then((json) => {
          if (json.success)
            if (this.props.onScanCompleted) {
              this.props.onScanCompleted(JSON.parse(json.data));
            }
        })
        .catch((err) => alert(JSON.stringify(err)));
    }, 1000);
  }

  render() {
    let { columns } = this.props;
    let data = this.state?.textSearch
      ? this.state?.filterData
      : this.props.data;
    data = data
      ?.map((item, i) => {
        if (!item) return;
        item["sno"] = i + 1;
        return item;
      })
      .filter((item, i) => item !== undefined);

    columns = [
      { name: "Sr. No.", sortable: true, width: "70px", selector: "sno" },
      ...columns,
    ];
    return (
      <DataTable
        columns={columns || []}
        // theme="solarized"
        //customStyles={}
        keyField="id"
        data={data}
        pagination={true}
        showSearch={this.props.showSearch}
        // style={{ minHeight: 600 }}
        // responsive={true}
        expandableRows={this.props.expandableRows}
        expandOnRowClicked={this.props.expandOnRowClicked}
        expandableRowsComponent={this.props.expandableRowsComponent}
        highlightOnHover={this.props.expandOnRowClicked}
        conditionalRowStyles={this.props.conditionalRowStyles}
        dense
        noHeader
        striped
        subHeader={this.props.subHeader}
        paginationPerPage={this.props.pageSize || 50}
        paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
        subHeaderComponent={
          <>
            <div style={{ width: "100%", backgroundColor: "#D6F0D6" }}>
              <Row
                style={{
                  borderBottom: "2px solid grey",
                  padding: 5,
                  backgroundColor: "#D6F0D6",
                  margin: 0,
                }}
              >
                {this.showSearch == true ? (
                  <Col sm={3}>
                    <Input
                      bsSize="sm"
                      type="text"
                      style={{ minWidth: 200, marginTop: 30 }}
                      placeholder="Search"
                      value={this.props.searchValue}
                      onChange={this.search_onChange.bind(this)}
                      autoFocus={this.props.autoFocus === true ? false : true}
                      // onKeyUp={(e) => {
                      //   this.onKeyUp(e);
                      // }}
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col sm={9}>
                  <div>{this.props.subHeaderComponent}</div>
                </Col>
              </Row>
            </div>
          </>
        }
      />
    );
  }
}
MyDataTable.defaultProps = {
  expandableRows: false,
  expandOnRowClicked: false,
  expandableRowsComponent: null,
  conditionalRowStyles: [],
  subHeader: false,
  showSearch: true,
  onSearchValueChanged: function() {},
};
export default MyDataTable;
