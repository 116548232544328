
export const customerConstants = {

  GET_ALL_CUSTOMERS_REQUEST: 'GET_ALL_CUSTOMERS_REQUEST',
  GET_ALL_CUSTOMERS_SUCCESS: 'GET_ALL_CUSTOMERS_SUCCESS',
  GET_ALL_CUSTOMERS_FAILURE: 'GET_ALL_CUSTOMERS_FAILURE',

  GET_CUSTOMER_BY_ID_REQUEST: ' GET_CUSTOMER_BY_ID_REQUEST',
  GET_CUSTOMER_BY_ID_SUCCESS: ' GET_CUSTOMER_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILURE: 'GET_CUSTOMER_BY_ID_FAILURE',

  ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",

  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",

  GET_TRANSACTION_DETAIL_BY_ID_REQUEST: 'GET_TRANSACTION_DETAIL_BY_ID_REQUEST',
  GET_TRANSACTION_DETAIL_BY_ID_SUCCESS: 'GET_TRANSACTION_DETAIL_BY_ID_SUCCESS',
  GET_TRANSACTION_DETAIL_BY_ID_FAILURE: 'GET_TRANSACTION_DETAIL_BY_ID_FAILURE',

  SET_TRANSACTION_DETAIL: 'SET_TRANSACTION_DETAIL',

  CUSTOMER_CLEAR: 'CUSTOMER_CLEAR'



}