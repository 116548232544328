import { reportsConstants } from '../constants';

export function reports(state = {}, action) {

    switch (action.type) {

        case reportsConstants.GET_PATIENT_REG_COUNT_REQUEST:
            return { ...state, loading: true }
        case reportsConstants.GET_PATIENT_REG_COUNT_SUCCESS:
            return { ...state, result: action.data, loading: false };
        case reportsConstants.GET_PATIENT_REG_COUNT_FAILURE:
            return { ...state, loading: false };

        case reportsConstants.GET_BILLWISE_COLLECTION_REQUEST:
            return { ...state, loading: true }
        case reportsConstants.GET_BILLWISE_COLLECTION_SUCCESS:
            console.log("action------GET_BILLWISE_COLLECTION_SUCCESS-----------",action)
            return { ...state, receiptReport: action.data, loading: false };
        case reportsConstants.GET_BILLWISE_COLLECTION_FAILURE:
            return { ...state, loading: false };
    
        default:
            return state
    }
}