export const  tpaConstants = {

    GET_ALL_TPA_REQUEST : "GET_ALL_TPA_REQUEST",
    GET_ALL_TPA_SUCCESS : "GET_ALL_TPA_SUCCESS",
    GET_ALL_TPA_FAILURE : "GET_ALL_TPA_FAILURE",

    ADD_TPA_REQUEST :"ADD_TPA_REQUEST",
    ADD_TPA_SUCCESS : "ADD_TPA_SUCCESS",
    ADD_TPA_FAILURE : "ADD_TPA_FAILURE",

    UPDATE_TPA_REQUEST : "UPDATE_TPA_REQUEST",
    UPDATE_TPA_SUCCESS : "UPDATE_TPA_SUCCESS",
    UPDATE_TPA_FAILURE : "UPDATE_TPA_FAILURE",

    DELETE_TPA_REQUEST : "DELETE_TPA_REQUEST",
    DELETE_TPA_SUCCESS : "DELETE_TPA_SUCCESS",
    DELETE_TPA_FAILURE : "DELETE_TPA_FAILURE",

    CHANGEACTIVE_TPA_REQUEST : "CHANGEACTIVE_TPA_REQUEST",
    CHANGEACTIVE_TPA_SUCCESS : "CHANGEACTIVE_TPA_SUCCESS",
    CHANGEACTIVE_TPA_FAILURE : "CHANGEACTIVE_TPA_FAILURE",

    ADD_TPA_CONS_FEES_REQUEST : "ADD_TPA_CONS_FEES_REQUEST",
    ADD_TPA_CONS_FEES_SUCCESS : "ADD_TPA_CONS_FEES_SUCCESS",
    ADD_TPA_CONS_FEES_FAILURE : "ADD_TPA_CONS_FEES_FAILURE",

    GET_TPA_CONS_FEES_REQUEST : "GET_TPA_CONS_FEES_REQUEST",
    GET_TPA_CONS_FEES_SUCCESS : "GET_TPA_CONS_FEES_SUCCESS",
    GET_TPA_CONS_FEES_FAILURE : "GET_TPA_CONS_FEES_FAILURE",

    DELETE_TPA_CONS_FEES_REQUEST : "DELETE_TPA_CONS_FEES_REQUEST",
    DELETE_TPA_CONS_FEES_SUCCESS : "DELETE_TPA_CONS_FEES_SUCCESS",
    DELETE_TPA_CONS_FEES_FAILURE : "DELETE_TPA_CONS_FEES_FAILURE",

    UPDATE_TPA_CONS_FEES_REQUEST : "UPDATE_TPA_CONS_FEES_REQUEST",
    UPDATE_TPA_CONS_FEES_SUCCESS : "UPDATE_TPA_CONS_FEES_SUCCESS",
    UPDATE_TPA_CONS_FEES_FAILURE : "UPDATE_TPA_CONS_FEES_FAILURE",
}