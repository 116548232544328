import { otMasterConstants } from '../constants';
import { alertActions } from './alert.actions';
import {otRoomService} from '../services/operationTheatres/otRoom.service';
import {
  toast,
  Bounce
} from 'react-toastify';

export const  otMasterActions = {  
  getAllRooms
};

function getAllRooms(query) {
  return dispatch => {
      dispatch(request());
      otRoomService.getAll(query)
          .then(
              data =>{
                if(data.success)
                {
                  dispatch(success(data.data))               
                }
                 else dispatch(failure(data.err))
                },
              error => dispatch(failure(error.toString()))
          );
  };

  function request() { return { type: otMasterConstants.GET_ALL_OT_ROOM_REQUEST  } }
  function success(ots) { return { type: otMasterConstants.GET_ALL_OT_ROOM_SUCCESS, ots } }
  function failure(error) { return { type: otMasterConstants.GET_ALL_OT_ROOM_FAILURE, error } }
}

