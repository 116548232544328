export const appointmentStatus = {
    New: 1,
    Waiting: 2,
    Engaged: 3,
    Done: 4,
    Cancelled: 5,
    Reschedule: 6
};

export function getAppointmentStatus(status) {
    for (const [key, value] of Object.entries(appointmentStatus)) {
        if (value === status) {
            return key;
        }
    }
    // Return null or throw an error if the status is not found
    return null; // or throw new Error('Appointment status not found');
}
