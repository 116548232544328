import { generalMasterConstants } from "../constants";

export function locations(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.ADD_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: [...state.countries, action.country],
        loading: false,
      };
    case generalMasterConstants.ADD_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.SET_SELECTED_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.SET_SELECTED_COUNTRY_SUCCESS:
      const sel_country = state.countries.find((country) => {
        return country._id === action.id;
      });
      return { ...state, selected_country: sel_country, loading: false };
    case generalMasterConstants.SET_SELECTED_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_STATE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_STATE_SUCCESS:
      const states = [...state.selected_country.states, action.state];
      return {
        ...state,
        selected_country: { ...state.selected_country, states },
        loading: false,
      };
    case generalMasterConstants.ADD_STATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_STATE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_STATE_SUCCESS:
      let updatedState = action.data;
      let index = state.selected_country.states.findIndex(
        (ob) => ob._id === updatedState.id
      );

      state.selected_country.states[index] = updatedState;

      return {
        ...state,
        selected_country: {
          ...state.selected_country,
          states: [...state.selected_country.states],
        },
        loading: false,
      };
    case generalMasterConstants.UPDATE_STATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_STATE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_STATE_SUCCESS:
      let deletedState = action.state.state;
      let dindex = state.selected_country.states.findIndex(
        (ob) => ob._id === deletedState._id
      );
      state.selected_country.states[dindex] = deletedState;

      return {
        ...state,
        selected_country: {
          ...state.selected_country,
          states: [...state.selected_country.states],
        },
        loading: false,
      };
    case generalMasterConstants.DELETE_STATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_LOCATION_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_LOCATION_SUCCESS:
      let deletedcountry = action.location.country;
      let cindex = state.countries.findIndex(
        (ob) => ob._id === deletedcountry._id
      );
      state.countries[cindex] = deletedcountry;

      return { ...state, countries: [...state.countries], loading: false };
    case generalMasterConstants.DELETE_LOCATION_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_CITY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_CITY_SUCCESS:
      return { ...state, loading: false };
    case generalMasterConstants.ADD_CITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_LOCATION_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_LOCATION_SUCCESS:
      return { ...state, loading: false };
    case generalMasterConstants.ADD_LOCATION_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.GET_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_COUNTRY_SUCCESS:
      return { ...state, countries: action.countries, loading: false };
    case generalMasterConstants.GET_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.GET_STATE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_STATE_SUCCESS:
      return { ...state, selected_country: action.countries, loading: false };
    case generalMasterConstants.GET_STATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.GET_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_COUNTRY_SUCCESS:
      return { ...state, countries: action.countries, loading: false };
    case generalMasterConstants.GET_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_COUNTRY_SUCCESS:
      let updatedCountry = action.country;
      let countryindex = state.countries.findIndex(
        (ob) => ob.code === updatedCountry.code
      );
      state.countries[countryindex] = updatedCountry;
      return { ...state, countries: [...state.countries], loading: false };
    case generalMasterConstants.UPDATE_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_COUNTRY_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_COUNTRY_SUCCESS:
      let deletedCountry = action.country;
      let delcountryindex = state.countries.findIndex(
        (ob) => ob.code === deletedCountry.code
      );
      state.countries[delcountryindex] = deletedCountry;
      return { ...state, countries: [...state.countries], loading: false };
    case generalMasterConstants.DELETE_COUNTRY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
