import { referalConsultantConstants } from '../constants';

export function referralConsultants(state = {loading:false,referrals:[]}, action) {

  switch (action.type) { 
    case referalConsultantConstants.ADD_REFERRAL_CONSULTANT_REQUEST:
        return {...state, loading: true};
      case referalConsultantConstants.ADD_REFERRAL_CONSULTANT_SUCCESS:
        return {...state, referrals: [...state.referrals,action.referral],loading:false};          
      case referalConsultantConstants.ADD_REFERRAL_CONSULTANT_FAILURE:
        return { ...state,error: action.error,loading:false };  
        case referalConsultantConstants.GET_ALL_REFERRAL_DATA_REQUEST:
          return {...state, loading: true};
        case referalConsultantConstants.GET_ALL_REFERRAL_DATA_SUCCESS:
          return {...state, referrals: action.data,loading:false};          
        case referalConsultantConstants.GET_ALL_REFERRAL_DATA_FAILURE:
          return { ...state,error: action.error,loading:false };  
        
        default:
            return state 
  }
}