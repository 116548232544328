import { otMasterConstants } from '../constants';

export function otMasters(state = {loading:false,ots:[],wards:[]}, action) 
{
  switch (action.type) {              
          case otMasterConstants.GET_ALL_OT_ROOM_REQUEST:
            return {...state, loading: true};
          case otMasterConstants.GET_ALL_OT_ROOM_SUCCESS:
            return {ots: action.ots,loading:false};          
          case otMasterConstants.GET_ALL_OT_ROOM_FAILURE:
            return { ...state,error: action.error,loading:false };          
    default:
      return state
  }
}