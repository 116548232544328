//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const problemCategoryService = {        
    getAll,
    getSubCategory,
    add,
    update,
    remove
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/problemCategory`, requestOptions).then(helperService.handleResponse);
}

function getSubCategory(code) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/problemCategory/${code}`, requestOptions).then(helperService.handleResponse);
}


function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/problemCategory`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/problemCategory/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/problemCategory/delete`, requestOptions).then(helperService.handleResponse);
}
