import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DropdownMenu, DropdownToggle,
  ListGroupItem,
  UncontrolledButtonDropdown
} from "reactstrap";
import BookingPopup from '../BookingPopup';

class BookingItem extends Component
{
  onSelectBooking(id,e)
  {
  if(this.props.onClick)
  {
   this.props.onClick(id)
  }}
  codeToValue(code,name)
  {
    try{
      const {masters}=this.props
      if(masters)
      return masters[name].list.find(item=>item.code===code).name
      else return code;
        }
        catch{
          return code;
        }
  }

  codeToValue(code,name)
  {
    try{
      const {masters}=this.props
      if(masters)
      return masters[name].list.find(item=>item.code===code).name
      else return code;
        }
        catch{
          return code;
        }
  }

  render()
  {
     const {booking}=this.props;

     let timing,patientName,consultant,tokenNumber,id,operation_date;
     patientName=booking.patient_id?(this.codeToValue(booking.patient_id.title_code,'titles')+ booking.patient_id.firstname+" "+booking.patient_id.middlename+" "+booking.patient_id.lastname):"";
     operation_date=booking.operation_date;
     timing=booking.time_slot_from;
     consultant=booking.consultant_id?booking.consultant_id.firstname:"No";
   
    return(
      <ListGroupItem tag="button" action style={{padding:'0px 5px',cursor:'pointer'}} onClick={this.props.onClick}  onDoubleClick={this.props.onDoubleClick}>                                     
      <div className="widget-content" style={{padding:0}}>
           <div className="widget-content-wrapper">           
           <div className="widget-content-left mr-2" style={{fontSize:"12px"}}>
           { moment(timing).format('hh:mm A')}  
           <br/>                        
           { moment(operation_date).format('DD/MM')}     </div>
<div className="widget-content-left" style={{fontSize:12,paddingLeft:10}}>
  <b>{patientName}</b>
  <br/>Dr. {consultant}
</div>
<div className="widget-content-right" style={{fontSize:12,paddingLeft:10}}>
<div className="ml-auto badge badge-pill badge-warning">
{/* {tokenNumber} */}
</div>
<UncontrolledButtonDropdown>
          <DropdownToggle  color="basic">            
                <a style={{padding:'3px'}}    title="View" className="text-primary font-icon-wrapper "> 
                    <FontAwesomeIcon icon={faEllipsisV} size="1x"/>
                </a>  
          </DropdownToggle>
          <DropdownMenu  positionFixed={true}  className="dropdown-menu-left dropdown-menu-rounded ">
        <BookingPopup booking={booking} onEdit={ this.onSelectBooking.bind(this,booking)} />
           </DropdownMenu>
          </UncontrolledButtonDropdown>
</div></div>
</div>
</ListGroupItem>                                  
  )
  }
}

function mapStateToProps(state) {
  const { consultants ,registrationMaster} = state;

  return {  consultants:consultants.consultants,masters:registrationMaster.data  };
}

const connectedBookingItem = connect(mapStateToProps)(BookingItem);
export default connectedBookingItem;
