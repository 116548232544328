//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const userMasterService = {
  getAll,
  getUsersGeneralData,
  add,
  update,
  deleteMaster,
  getCount,
  generateIdCard,
  generateQRCard,
  searchAll,
  getById,
};

function searchAll(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/user/search`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/employees?viewDeleted=true`, requestOptions).then(
    helperService.handleResponse
  );
}

function getUsersGeneralData() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/user/getUsersGeneralData`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/employees`, requestOptions).then(
    helperService.handleResponse
  );
}

function getCount(TransactionId) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/user/getCountByCustomerTransactionId/${TransactionId}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function update(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/employees/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function deleteMaster(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/employees/delete/${data}`, requestOptions).then(
    helperService.handleResponse
  );
}

function generateIdCard(email, type) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/user/generateIdCard/${email}/${type}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function generateQRCard(email) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/user/generateQrCard/${email}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/employees/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}
