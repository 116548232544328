import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const opBillingService = {
  getAll,
  getBillListForReporting,
  getAllWithPatient,
  getAllHold,
  getBillByUHID,
  getBillByBillNo,
  getBillByHoldNo,
  addBill,
  addHold,
  getAllByDateRange,
  clearHold,
  getBillTemplate,
  addAdvanceBill,
  getAllAdvance,
  getAdvanceAmountByID,
  getDueBillList,
  getBillQueue,
  getPatientServiceList,
  cancelBillByBillNo,
  getReceivableByUHID,
  getSearchBillResult,
  refundBillByBillno,
  makepartialpayment,
  getSummaryOfBillsByDate,
  getSummaryOfReceipts,
  getSummaryOfBillsDeptWiseByDate,
  getSummaryOfBillsConsultantWiseByDate,
  getSummaryOfDiscountedBills,
  getDueAmountByUHID,
  getSummaryOfReceivableBils,
  getReciptsForDateRange,
  getAdvancedReportSummary,
  getPatientDetailedReport,
  getPatientConsolidatedReport,
  getPatientReportByDate,
  getPatientConsolidatedByDate,
  getConsultantWiseDetailedReport,
  getConsultantWiseServiceDetailsReport,
  getConsultantDetailedByDate,
  getConsultantWiseServiceDetailsByDate,
  getPatientById,
  getAllOpPatient,
  getAllOpPatientByDate,
  getConsultantWiseDynamicTableReport,
  getConsultantConsolidatedReport,
};

function getDueAmountByUHID(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getDueAmountByUHID`,
    requestOptions
  ).then(helperService.handleResponse);
}

function makepartialpayment(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbilling/receivable`, requestOptions).then(
    helperService.handleResponse
  );
}

function refundBillByBillno(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbilling/refund`, requestOptions).then(
    helperService.handleResponse
  );
}

function getSearchBillResult(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSearchBillResult`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getReceivableByUHID(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getReceivableByUHID`,
    requestOptions
  ).then(helperService.handleResponse);
}

function cancelBillByBillNo(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbilling/cancel/${data.id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function addAdvanceBill(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opadvancebillingapi/addAdvanceBill`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getDueBillList(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opdserviceorder`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAdvanceAmountByID(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbilling/advanceAmount?patientId=${data.id}&viewDeleted=true`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getPatientServiceList(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getPatientService`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillTemplate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getBillTemplate`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllAdvance(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/opbilling/advanceAmount`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/opbillingapi/getAll`, requestOptions).then(
    helperService.handleResponse
  );
}

function getPatientDetailedReport(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/detailedReport?patient_id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getPatientReportByDate(id, from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/detailedReport?patient_id=${id}&from=${from}&to=${to},`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getPatientConsolidatedReport(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consolidateReport?patient_id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getPatientConsolidatedByDate(id, from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consolidateReport?patient_id=${id}&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAllWithPatient() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/opbillingapi/getAllWithPatient`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllHold() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/opbillingapi/getAllHold`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillByUHID(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getBillByUHID`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillByBillNo(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getBillByBillNo`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillByHoldNo(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getBillByHoldNo`, requestOptions).then(
    helperService.handleResponse
  );
}

function addBill(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbilling`, requestOptions).then(
    helperService.handleResponse
  );
}

function addHold(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/addHold`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllByDateRange(data) {
  const requestOptions = {
    method: "GET",
    headers: {
      authorization: helperService.getHeaderData(),
    },
  };
  const { searchValue, from, to, page, records, notCancelledBill } = data;

  let queryParams = [];

  if (searchValue) {
    queryParams.push(`searchBy=${searchValue}`);
  }

  if (from && to) {
    queryParams.push(`startDate=${from}`);
    queryParams.push(`endDate=${to}`);
  }

  if (page) {
    queryParams.push(`page=${page}`);
  }

  if (records) {
    queryParams.push(`records=${records}`);
  }

  if (data.receivable) {
    queryParams.push("status=6&viewDeleted=true");
  } else if (data.refunded) {
    queryParams.push("status=5&viewDeleted=true");
  } else if (data.cancelled) {
    queryParams.push("status=4&viewDeleted=true");
  } else if (data.advance) {
    queryParams.push("status=3&viewDeleted=true");
  } else {
    queryParams.push("viewDeleted=true");
  }

  if (notCancelledBill) {
    queryParams.push("nonCancelledBills=true");
  } else {
    queryParams.push("nonCancelledBills=false");
  }
  const queryString = queryParams.join("&");
  return fetch(`${apiUrl}/opbilling?${queryString}`, requestOptions).then(
    helperService.handleResponse
  );
}

function clearHold(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/clearHold`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillQueue(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/opdserviceorder?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getBillListForReporting(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiUrl}/opbillingapi/getBillsByDate`, requestOptions).then(
    helperService.handleResponse
  );
}

function getSummaryOfBillsByDate(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfBillsByDate`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getSummaryOfReceipts(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfReceipts`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getSummaryOfBillsDeptWiseByDate(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfBillsDeptWiseByDate`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getSummaryOfBillsConsultantWiseByDate(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfBillsConsultantWiseByDate`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getSummaryOfDiscountedBills(data) {
  console.log(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfDiscountedBills`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getReciptsForDateRange(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getReciptsForDateRange`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getSummaryOfReceivableBils(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getSummaryOfReceivableBils`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getAdvancedReportSummary(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${apiUrl}/opbillingapi/getAdvancedReportSummary`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getConsultantWiseDetailedReport(code) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantDetailedReport?consultant_code=${code}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getConsultantWiseServiceDetailsReport(code) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantDetailedReport?consultantCode=${code}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getConsultantDetailedByDate(code, from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantDetailedReport?consultant_code=${code}&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getConsultantWiseServiceDetailsByDate(code, from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantDetailedReport?consultantCode=${code}&from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getPatientById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/opPatient?patient_id=${id}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getAllOpPatient() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(`${apiUrl}/opbillingapi/opPatient`, requestOptions).then(
    helperService.handleResponse
  );
}
function getAllOpPatientByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/opPatient?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getConsultantWiseDynamicTableReport(from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantDetailedReport?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getConsultantConsolidatedReport(code) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
  };
  return fetch(
    `${apiUrl}/opbillingapi/consultantConsolidateReport?consultant_code=${code}`,
    requestOptions
  ).then(helperService.handleResponse);
}
