//import { authHeader } from '../store';
import { helperService } from "../helper.service";
import { apiUrl } from "../../constants";
//import { search } from "core-js/fn/symbol";

export const pharmaPurchaseOrderService = {
  getAll,
  add,
  getreports,
  getAllGRN,
  update,
  get,
  getByDate,
  getByDateStatus,
  getreportByDate,
  _delete,
  getreportSearch,
  
};

function getAll(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchaseOrder?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getAllGRN() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchaseOrder`, requestOptions).then(
    helperService.handleResponse
  );
}
function getreports() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchaseOrder/report`, requestOptions).then(
    helperService.handleResponse
  );
}

function getreportSearch(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchaseOrder?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/pharmaPurchaseOrder/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchaseOrder?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getByDateStatus(from, to,status) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchaseOrder?from=${from}&to=${to}&status=${status}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getreportByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/pharmaPurchaseOrder/report?from=${from}&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/update`, requestOptions).then(
    helperService.handleResponse
  );
}
function _delete(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/pharmaPurchaseOrder/delete`, requestOptions).then(
    helperService.handleResponse
  );
}