import {helperService} from '../helper.service'
import {apiUrl} from "../../constants"

export const iaConsultantService = {
  getAll,
  add,
  update,
  remove,
  getById,
  getHistory,
  getByDate,
  getByDateAndPatientId,
}

function getAll() {
  const requestOptions = {
      method: 'GET',
      headers:{"authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/iaconsultant`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

  const requestOptions = {
      method: 'POST',  
      headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
      body:JSON.stringify(data)
  };

  return fetch(`${apiUrl}/iaconsultant`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

  const requestOptions = {
      method: 'POST',
      headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
      body:JSON.stringify(data)
  };
 
  return fetch(`${apiUrl}/iaconsultant/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

  const requestOptions = {
      method: 'POST',
      headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},
      body:JSON.stringify(data)
  };
 
  return fetch(`${apiUrl}/iaconsultant/delete`, requestOptions).then(helperService.handleResponse);
}
function getByDate(from,to) {
  const requestOptions = {
      method: 'GET',
      headers:{"authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/iaconsultant?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}
function getByDateAndPatientId(from,to, id) {
  const requestOptions = {
      method: 'GET',
      headers:{"authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/iaconsultant?patient_id=${id}&from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}

function getById(id) {
  const requestOptions = {
      method: 'GET',
      headers:{"authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/iaconsultant?patient_id=${id}`, requestOptions).then(helperService.handleResponse);
}
function getHistory(id) {
  const requestOptions = {
      method: 'GET',
      headers:{"authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/iaconsultant/pasthistory?patient_id=${id}`, requestOptions).then(helperService.handleResponse);
}
