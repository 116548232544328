import { userMasterConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { userMasterService } from "../services/userMaster.service";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";

export const userMasterActions = {
  add,
  getAll,
  searchAll,
  deleteUser,
  update,
  selectEmployee,
  newBody,
  oldBody,
};

function newBody(formdata) {
  const formData = {
    employeeCode: formdata.employee_code,
    departmentId: parseInt(formdata.department),
    subDepartmentId: parseInt(formdata.sub_department),
    designationId: parseInt(formdata.designation),
    titleId: parseInt(formdata.title),
    firstName: formdata.first_name,
    lastName: formdata.last_name,
    employeePhoto: formdata.user_image ?? "",
    genderId: parseInt(formdata.gender),
    dateOfBirth: formdata.dob,
    maritalStatusId: parseInt(formdata.maritial_status),
    religionId: parseInt(formdata.religion),
    bloodGroupId: parseInt(formdata.blood_group),
    personalEmail: formdata.user_email,
    officialEmail: formdata.user_email,
    mobileNumber: formdata.user_mobile,
    homeContact: formdata.user_home_no || "",
    emergencyNumber: formdata.user_emergency_no,
    alternateNumber: formdata.user_alternate_no || "",
    referralName: formdata.refered_person_name,
    referralContactNumber: formdata.refered_person_phone,
    referralEmail: formdata.refered_person_email,
    idType1: parseInt(formdata.id_type1_name) ?? 0,
    idNumber1: formdata.id_number1,
    documentImage1: formdata.id_document1,
    idType2: parseInt(formdata.id_type2_name ?? 0) ?? 0,
    idNumber2: formdata.id_number2,
    documentImage2: formdata.id_document2,
    previousEmployers: formdata.previous_work_experience.map((employer) => ({
      previousEmployerName: employer.previous_company_name,
      designation: employer.previous_designation_name,
      year: employer.number_of_years_served,
      // Assuming default value
    })),

    employeeEducations: formdata.education_details_array.map((education) => ({
      educationId: parseInt(education.highest_education_degree_name), // Assuming default value
      registrationNo: education.certificate_no,
      degreePhoto:
        education.user_education_image_name.substring(
          education.user_education_image_name.indexOf(",") + 1
        ) ?? "", // Assuming default value
    })),

    password: formdata.password,
    confirmPassword: formdata.password,
    roles: formdata.selectedRoles.map((roles) => roles.value),
    address: {
      permanentAddress: formdata.permanent_address,
      gramPanchayat: formdata.gram_panchayat,
      country: `${formdata.country}`,
      city: `${formdata.city}`,
      location: `${formdata.location}`,
      village: formdata.village,
      pincode: formdata.pincode,
      tehsil: formdata.tehsil,
      district: `${formdata.district}`,
      state: `${formdata.state}`,
      communicationAddress: formdata.communication_address,
    },
  };

  return formData;
}

function oldBody(newPayload) {
  const oldData = {
    id: newPayload.id ?? 0,
    employee_code: newPayload.employeeCode ?? "",
    user_image: newPayload.employeePhoto ?? "",
    department: String(newPayload.departmentId),
    sub_department: String(newPayload.subDepartmentId),
    designation: String(newPayload.designationId),
    role: newPayload.roles.map((roles) => roles),
    title: String(newPayload.title),
    first_name: newPayload.firstName,
    last_name: newPayload.lastName,
    gender: String(newPayload.gender),
    dob: newPayload.dateOfBirth,
    No_of_years: String(newPayload.age),
    maritial_status: String(newPayload.maritalStatus),
    religion: String(newPayload.religion),
    blood_group: String(newPayload.bloodGroup),
    user_email: newPayload.personalEmail,
    user_mobile: newPayload.mobileNumber,
    user_home_no: newPayload.homeContact,
    user_emergency_no: newPayload.emergencyNumber,
    user_alternate_no: newPayload.alternateNumber,
    country: newPayload.address.country,
    state: newPayload.address.state,
    district: newPayload.address.district,
    city: newPayload.address.city,
    location: newPayload.address.location,
    pincode: String(newPayload.address.pincode),
    street_address: newPayload.address.communicationAddress,
    refered_person_name: newPayload.referralName,
    refered_person_phone: newPayload.referralContactNumber,
    refered_person_email: newPayload.referralEmail,
    id_type1_name: String(newPayload.idType1),
    id_number1: newPayload.idNumber1,
    id_document1: newPayload.documentImage1 ?? "",
    id_type2_name: String(newPayload.idType2),
    id_number2: newPayload.idNumber2,
    id_document2: newPayload.documentImage2 ?? "",
    previous_work_experience: newPayload.previousEmployers[0]
      ? newPayload.previousEmployers.map((employer) => ({
          previous_company_name: employer.previousEmployerName ?? "",
          previous_designation_name: employer.designation ?? "",
          number_of_years_served: employer.year ?? "",
        }))
      : [
          {
            previous_company_name: "",
            previous_designation_name: "",
            number_of_years_served: "",
          },
        ],
    education_details_array: newPayload.employeeEducationDetails.map(
      (education) => ({
        highest_education_degree_name: String(education.degreeId ?? "") ?? "",
        certificate_no: education.registrationNo ?? "",
        user_education_image_name: `data:image/jpeg;base64,${education.degreePhoto ??
          ""}`,
        datakey: education.datakey,
      })
    ),
    password: newPayload.password,
    confirm_password: newPayload.password,
  };

  return oldData;
}

function subsetOldBody(newPayload) {
  const oldDataSubset = {
    first_name: newPayload.firstName,
    last_name: newPayload.lastName,
    gender: String(newPayload.gender),
    user_email: newPayload.email,
    user_mobile: newPayload.phoneNumber,
    id: String(newPayload.id),
    deleted: newPayload.deleted,
  };

  return oldDataSubset;
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());
    return userMasterService.add(newBody(payload)).then((result) => {
      if (result.isSuccess) {
        dispatch(success(result));
        toast("added successfully", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "success",
        });
        dispatch(alertActions.success(result.successMessagemessage));
        //history.goBack();
      } else {
        dispatch(failure(result.validationErrors));
        toast(`${result.validationErrors[0].errorMessage}`, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
      }
      return result;
    });
  };

  function request() {
    return { type: userMasterConstants.ADD_REQUEST };
  }
  function success(data) {
    return { type: userMasterConstants.ADD_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: userMasterConstants.ADD_FAILURE, error };
  }
}

function update(payload) {
  return (dispatch) => {
    dispatch(request());
    return userMasterService.update(newBody(payload), payload.id).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result));
          toast("updated successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
        }
        return result;
      },
      (error) => {
        toast(JSON.stringify(error), {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userMasterConstants.ADD_REQUEST };
  }
  function success(data) {
    return { type: userMasterConstants.ADD_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: userMasterConstants.ADD_FAILURE, error };
  }
}

function deleteUser(payload) {
  return (dispatch) => {
    dispatch(request());

    userMasterService.deleteMaster(payload.id).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value, payload.id));
          // toast(
          //   bool === "active"
          //     ? payload?.isActive
          //       ? "Active Employee"
          //       : "In-Active Employee"
          //     : result.message,
          //   {
          //     transition: Bounce,
          //     closeButton: true,
          //     autoClose: 5000,
          //     position: "bottom-center",
          //     type: "success",
          //   }
          // );
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userMasterConstants.DELETE_REQUEST };
  }
  function success(data, id) {
    return { type: userMasterConstants.DELETE_SUCCESS, data: { data, id } };
  }
  function failure(error) {
    return { type: userMasterConstants.DELETE_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userMasterService.getAll().then(
      (result) => {
        if (result.isSuccess) {
          const old_body = result.value.map((employee) => {
            return subsetOldBody(employee);
          });
          dispatch(success(old_body));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "error",
          // });
        }
      },
      (error) => {
        // toast(error.message, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 5000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userMasterConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return { type: userMasterConstants.GET_ALL_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: userMasterConstants.GET_ALL_FAILURE, error };
  }
}

function searchAll(data) {
  return (dispatch) => {
    dispatch(request());

    userMasterService.searchAll(data).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userMasterConstants.SEARCH_ALL_REQUEST };
  }
  function success(data) {
    return { type: userMasterConstants.SEARCH_ALL_SUCCESS, data: { data } };
  }
  function failure(error) {
    return { type: userMasterConstants.SEARCH_ALL_FAILURE, error };
  }
}

function selectEmployee(id) {
  return (dispatch) => {
    dispatch(request());
    userMasterService.getById(id).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(oldBody(result.value)));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "success",
          // });
          dispatch(alertActions.success("Employee Selected"));
        } else {
          dispatch(failure(result.err));
          // toast(result.message, {
          //   transition: Bounce,
          //   closeButton: true,
          //   autoClose: 5000,
          //   position: "bottom-center",
          //   type: "error",
          // });
        }
      },
      (error) => {
        // toast(error.message, {
        //   transition: Bounce,
        //   closeButton: true,
        //   autoClose: 5000,
        //   position: "bottom-center",
        //   type: "error",
        // });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userMasterConstants.SELECT_EMPLOYEE_REQUEST };
  }
  function success(employee) {
    return {
      type: userMasterConstants.SELECT_EMPLOYEE_SUCCESS,
      employee,
    };
  }
  function failure(error) {
    return { type: userMasterConstants.SELECT_EMPLOYEE_FAILURE, error };
  }
}
