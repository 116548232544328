import React, { Fragment, Component } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Container } from 'reactstrap';
import { capchaConfig } from '../../../../config/captchaConfig';
import ReCAPTCHA from "react-google-recaptcha";
export default class LoginWithOtpComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
            {/* <Form onSubmit={this.props.handleOtpLogin}> */}
                <Row>
                    <Col>
                        <FormGroup style={{ "width": "100%" }}>
                            <Label for="exampleEmail">Email</Label>
                            <Input type="email" name="email" id="exampleEmail" onChange={(e) => this.props.onChangeHandler("customer_email", e.target.value)}
                                placeholder="Email here..." />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup style={{ "width": "100%" }}>
                            <Label for="login_type">Login As</Label>
                            <Input type="select" id="login_type" onChange={(e) => { this.props.onChangeHandler("login_type", e.target.value) }}>
                                <option value="user">Employee</option>
                                <option value="consultant">Dr./Consultant</option>
                                <option value="patient">Patient</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="divider" />
                {/* <Row>
                    <Col>
                        <ReCAPTCHA
                            sitekey={capchaConfig.client_key}
                            onChange={this.props.onChange}
                        />
                    </Col>

                    <Col style={{ "marginTop": "auto" }}>

                        <div className="d-flex align-items-center">

                            <Button color="primary" size="lg" type="submit">Login to Dashboard</Button>

                        </div>
                    </Col>
                </Row> */}
            {/* </Form> */}
            </>
        )
    }
}