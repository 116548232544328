import {
     getAdmTypeContstant
} from '../constants'


const getAdmTypeReducer = (state = {}, action) =>{

        
       
        switch(action.type){
            case getAdmTypeContstant.GETALL_ADMTYPE_REQUEST :
                
                return {
                    ...state, loading :true ,
                };

            case getAdmTypeContstant.GETALL_ADMTYPE_SUCCESS :
                
                        
                        return{
                            
                            ...state, data :  action.data   ,
                        } 
                        
            case getAdmTypeContstant.GETALL_ADMTYPE_FAIL :
              
                    return {
                        ...state,
                        loading :false,
                        error : action.payload,
                    }            


                       

            default:
                  return state;          
        }

};

export {getAdmTypeReducer};