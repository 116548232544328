
import moment from "moment";
import { store } from '../store';

export function getMinReportDate() {
  const stateDate = store.getState();
  const report_setting = stateDate?.newMaster?.roleData?.[0]?.report_setting?.[0];
  let minReportDate = moment().format("YYYY-MM-DD");
  if (report_setting) {
    switch (report_setting.days) {
      case "Days":
        minReportDate = moment(new Date()).subtract(report_setting.numbers, 'days').format("YYYY-MM-DD")
        break;
      case "Month":
        minReportDate = moment(new Date()).subtract(report_setting.numbers, 'month').format("YYYY-MM-DD")
        break;
      case "Year":
        minReportDate = moment(new Date()).subtract(report_setting.numbers, 'month').format("YYYY-MM-DD")
        break;
    }
  } else {
    minReportDate = moment("01 01 1970", "YYYY-MM-DD")
  }
  return minReportDate;
}
