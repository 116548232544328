import React from "react";
import { Route } from "react-router-dom";
import FormElementsControls from "../DemoPages/Pages/Elements/Controls";
import $ from "jquery";
import { Error404 } from "../ErrorPages";
import { apiUrl } from "../constants/config";
import IPDTPAMaster from "../DemoPages/IPD TPAMaster";
import IPDTPAWiseServiceMaster from "../DemoPages/TPAWiseServiceMaster";
import IPDCorporateMaster from "../DemoPages/IPD CorporateMaster";
import IPDCorporateWiseServiceMaster from "../DemoPages/CorporateWiseServiceMaster";
import IPDServiceMaster from "../DemoPages/IPD Service Master";
import IPDReferralMaster from "../DemoPages/ReferalDoctorMaster";
import ConsultantReferalMaster from "../DemoPages/ReferalDoctorMaster";
import LABPOS from "../DemoPages/LAB POS";
import GeneralPOS from "../DemoPages/GeneralPOS";
import IPDPatientRegistration from "../DemoPages/IPD Patient Registration/ViewAllPatients";
import PatientModuleIpd from "../DemoPages/IPD/index";
import LABPosMaster from "../DemoPages/LAB POS";
import NursePos from "../DemoPages/Nurse POS";
import RadiologyPos from "../DemoPages/Radiology POS";
import CanteenPos from "../DemoPages/Canteen POS";
import WardRoom from "../DemoPages/WardMaster/wardroom";
import WardBed from "../DemoPages/WardMaster/wardBed";
import IPDAdmissionForm from "../DemoPages/IPD/Patient";
//const Register = React.lazy(() => import("./views/pages/register/Register"));
const PatientRegistration = React.lazy(() =>
  import("../DemoPages/PatientRegistration/index")
);
const RegistrationMasters = React.lazy(() =>
  import("../DemoPages/RegistrationMaster/index")
);
const ViewAllPatients = React.lazy(() =>
  import("../DemoPages/PatientRegistration/ViewAllPatients")
);
const RegistrationFeesMaster = React.lazy(() =>
  import("../DemoPages/RegistrationFeesMaster")
);
const RegistrationAgewiseServices = React.lazy(() =>
  import("../DemoPages/RegistrationAgewiseServices")
);
const RegistrationWiseConsultantFee = React.lazy(() =>
  import("../DemoPages/RegistrationWiseConsultantFees")
);
const RegistrationwiseServices = React.lazy(() =>
  import("../DemoPages/RegistrationwiseServices/")
);
const Vitals = React.lazy(() => import("../DemoPages/Vitals"));
const PharmacyOrders = React.lazy(() =>
  import("../DemoPages/OPDMaster/PharmacyOrders")
);
const ServiceOrders = React.lazy(() =>
  import("../DemoPages/OPDMaster/ServiceOrders")
);
const GenerateLicence = React.lazy(() =>
  import("../DemoPages/SuperAdmin/generate-licence")
);
const GenerateJSONFile = React.lazy(() =>
  import("../DemoPages/SuperAdmin/generate-json-file")
);
const OPDBilling = React.lazy(() => import("../DemoPages/OPDBilling"));
const OpdReports = React.lazy(() => import("../DemoPages/OpdReports"));
const PatientWiseDetailedReport = React.lazy(() =>
  import("../DemoPages/OpdReports/PatientWiseDetailedReport")
);
// const PatientWiseConsolidatedReport = React.lazy(() =>
//   import("../DemoPages/OpdReports/PatientWiseDetailedReport/ConsolidatedReport")
// );
const ConsultantWiseReport = React.lazy(() =>
  import("../DemoPages/OpdReports/ConsultantWiseReport")
);

const OPDCancelBillingGridView = React.lazy(() =>
  import("../DemoPages/OPDBilling/cancelrefundgrid")
);
const OPDReceivableBillingGridView = React.lazy(() =>
  import("../DemoPages/OPDBilling/receivablegrid")
);

const InitialAssessmentMaster = React.lazy(() =>
  import("../DemoPages/InitialAssesment")
);
const InitialAssessment = React.lazy(() =>
  import("../DemoPages/newmaster/initialAssesment/index")
);
const GeneralMasters = React.lazy(() =>
  import("../DemoPages/GeneralMaster/index")
);
const NewMaster = React.lazy(() => import("../DemoPages/newmaster"));
const MyConsultantMasters = React.lazy(() =>
  import("../DemoPages/MyConsultantMaster")
);
const ViewConsultants = React.lazy(() =>
  import("../DemoPages/MyConsultantMaster/ViewAllConsultant")
);
const Department = React.lazy(() =>
  import("../DemoPages/GeneralMaster/MyDepartments")
);
const QueueMonitor = React.lazy(() => import("../DemoPages/QueueMonitor"));
const UserMaster = React.lazy(() => import("../DemoPages/UserMaster"));
const CustomerMasters = React.lazy(() => import("../DemoPages/ServiceMasters"));
const CustomerMaster = React.lazy(() => import("../DemoPages/CustomerMaster"));
const InitialAssesment = React.lazy(() =>
  import("../DemoPages/InitialAssesment")
);
const RegistrationWiseMaster = React.lazy(() =>
  import("../DemoPages/RegistrationFeesMaster")
);
const RegistartionFessConsultantMaster = React.lazy(() =>
  import("../DemoPages/RegistrationWiseConsultantFees")
);
const RegistartionWiseserviceMaster = React.lazy(() =>
  import("../DemoPages/RegistrationwiseServices")
);
const WebCam = React.lazy(() => import("../DemoPages/AudioVideoRecorder"));
const AudioVideo = React.lazy(() =>
  import("../DemoPages/AudioVideoRecorder/audiovideorecorder")
);
const AppointmentMaster = React.lazy(() =>
  import("../DemoPages/AppointmentMaster")
);
const ConsultantHome = React.lazy(() => import("../DemoPages/ConsultantHome"));
const PatientHome = React.lazy(() => import("../DemoPages/PatientHome"));
const ConsultantRefaralIPView = React.lazy(() =>
  import("../DemoPages/ReferalDoctorPayabal")
);
const PatientQueue = React.lazy(() => import("../DemoPages/QueueMonitor"));
const TPAMaster = React.lazy(() => import("../DemoPages/TPAMaster"));

const OPDMaster = React.lazy(() => import("../DemoPages/OPDMaster"));
const DMSGridView = React.lazy(() => import("../DemoPages/DMS"));

const SuperAdmin = React.lazy(() => import("../DemoPages/SuperAdmin"));

const TokenMonitor = React.lazy(() => import("../DemoPages/TokenMonitor"));
const LaboratoryMaster = React.lazy(() =>
  import("../DemoPages/LaboratoryMaster")
);
const LabPOSConfig = React.lazy(() => import("../DemoPages/LabPOSConfig"));
const LabOutSource = React.lazy(() => import("../DemoPages/LabOutSources"));
const LabOutSourceMapping = React.lazy(() =>
  import("../DemoPages/LabOutSourceMapping")
);

const LabMasters = React.lazy(() => import("../DemoPages/LaboratoryMaster"));
const LabTemplates = React.lazy(() => import("../DemoPages/LabTemplates"));
const LabTestMapping = React.lazy(() => import("../DemoPages/LabTestMapping"));
const LabSubTestMapping = React.lazy(() =>
  import("../DemoPages/LabSubTestMapping")
);
const LabGroupMapping = React.lazy(() =>
  import("../DemoPages/LabGroupMapping")
);
const LabTestSample = React.lazy(() =>
  import("../DemoPages/LabTestSampleCollection")
);
const LabTechnicians = React.lazy(() => import("../DemoPages/LabTechnicians"));
const LabPatientList = React.lazy(() => import("../DemoPages/LabPatientList"));
const LabReportEntry = React.lazy(() => import("../DemoPages/LabReportEntry"));
const LabApproveReports = React.lazy(() =>
  import("../DemoPages/LabApproveReports")
);
const LabInterimReport = React.lazy(() =>
  import("../DemoPages/LabReportEntry/InterimReport")
);
// const LabIpPatientReport = React.lazy(() =>
//   import("../DemoPages/LapIpPatientReport/IpPatientReport")
// );
const LabOpPatientReport = React.lazy(() =>
  import("../DemoPages/LapOpPatientReport/OpPatientReport")
);

const LabAntibioticsMapping = React.lazy(() =>
  import("../DemoPages/LabAntibioticsMapping")
);

const WardConsultantShare = React.lazy(() =>
  import("../DemoPages/WardMaster/consultantshare")
);
const WardWiseServiceCharge = React.lazy(() =>
  import("../DemoPages/WardWiseServices")
);
const WardConsultantFees = React.lazy(() =>
  import("../DemoPages/WardMaster/wardwiseconsultationfees")
);
const GracePeriodSettings = React.lazy(() =>
  import("../DemoPages/WardMaster/graceperiodsetting")
);
const WardType = React.lazy(() => import("../DemoPages/WardMaster/wardtype"));
const WardCatagory = React.lazy(() =>
  import("../DemoPages/WardMaster/wardcatagory")
);
const WardFacility = React.lazy(() =>
  import("../DemoPages/WardMaster/wardfacilities")
);
const WardSubCatagory = React.lazy(() =>
  import("../DemoPages/WardMaster/wardsubcatagory")
);
const WardCatagoryFees = React.lazy(() =>
  import("../DemoPages/WardMaster/wardwisefeesmaster")
);
const WardTransfer = React.lazy(() => import("../DemoPages/WardTransfer"));

const WardCalculation = React.lazy(() =>
  import("../DemoPages/WardMaster/wardcalculation")
);

//const Radiology =React.lazy(()=>import('../DemoPages/RadiologyMaster'
const RadiologyReportEntry = React.lazy(() =>
  import("../DemoPages/RadiologyMaster/RadiologyReportEntry")
);
const RadiologyCollection = React.lazy(() =>
  import("../DemoPages/RadiologyMaster/RadiologyCollection")
);
const RadiologyDepartmentCollection = React.lazy(() =>
  import("../DemoPages/RadiologyMaster/RadiologyDepartmentCollection")
);
const RadiologyInPatientList = React.lazy(() =>
  import("../DemoPages/RadiologyMaster/RadiologyInPatientList")
);
const RadiologyTemplates = React.lazy(() =>
  import("../DemoPages/RadiologyMaster/RadiologyTemplates")
);
const RadiologyOpPatientReport = React.lazy(() =>
  import(
    "../DemoPages/RadiologyMaster/RadiologyOpPatientReport/OpPatientReport"
  )
);

const PharmacyMaster = React.lazy(() => import("../DemoPages/PharmacyMaster"));
const PharmaSales = React.lazy(() => import("../DemoPages/Pharmacy/Sales"));
const PharmaSalesCompleted = React.lazy(() =>
  import("../DemoPages/Pharmacy/SalesCompleted")
);
const PharmaDueReceivables = React.lazy(() =>
  import("../DemoPages/Pharmacy/Duebalancepayment")
);
const PharmaPurchase = React.lazy(() =>
  import("../DemoPages/Pharmacy/Purchase")
);
const PharmaPurchaseGrn = React.lazy(() =>
  import("../DemoPages/Pharmacy/Purchase/GRN Cancel")
);
const PharmacyCustomer = React.lazy(() =>
  import("../DemoPages/Pharmacy/Customer")
);
const PharmacyPresciption = React.lazy(() =>
  import("../DemoPages/Pharmacy/Prescription")
);
const InPatientIndent = React.lazy(() =>
  import("../DemoPages/Pharmacy/InpatientIndent")
);
const DepartmentIndent = React.lazy(() =>
  import("../DemoPages/Pharmacy/Department Indent")
);
const PurchaseReturn = React.lazy(() =>
  import("../DemoPages/Pharmacy/PurchasegrnReturn")
);
const DepartmentTransfer = React.lazy(() =>
  import("../DemoPages/Pharmacy/Department Transfer")
);
const DepartmentUsage = React.lazy(() =>
  import("../DemoPages/Pharmacy/Department Usage")
);
const PurchaseOrder = React.lazy(() =>
  import("../DemoPages/Pharmacy/Purchase Order")
);
const SupplierPayment = React.lazy(() =>
  import("../DemoPages/Pharmacy/SupplierPayment")
);
const StockFinder = React.lazy(() =>
  import("../DemoPages/Pharmacy/StockFinder")
);
const PharmacyDailyCollectionReports = React.lazy(() =>
  import("../DemoPages/Pharmacy/Reports/DailyCollectionReport")
);
const PharmacyPurchaseOrderReports = React.lazy(() =>
  import("../DemoPages/Pharmacy/Purchase Order Report")
);
const PharmacyPurchaseGRNReports = React.lazy(() =>
  import("../DemoPages/Pharmacy/PurchaseGRNReport")
);
const PharmacyPurchaseGSTReports = React.lazy(() =>
  import("../DemoPages/Pharmacy/PurchaseGSTreport")
);
const PharmacySalesReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/SalesReport")
);
// const IPDPharmacySalesReport = React.lazy(() =>
//   import("../DemoPages/Pharmacy/SalesReport/IPDSalesReport")
// );
const PharmacyDueReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/DueReport")
);
const PurchaseReturnReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/PurchaseReturnReport")
);
const PharmacyExpiryReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/ExpiryReport")
);
const PharmacyCurrentStockReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/Current Stock")
);

const PharmacyClosingStockReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/Closing Stock")
);
const PharmacyOPPatientSalesReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/OPDPatientwiseSalesReport")
);
const PharmacyIPPatientSalesReport = React.lazy(() =>
  import("../DemoPages/Pharmacy/IPDPatientwiseSalesReport")
);
const PharmacyPurchaseReturns = React.lazy(() =>
  import("../DemoPages/Pharmacy/Purchase Return")
);
const PharmacySaleReturns = React.lazy(() =>
  import("../DemoPages/Pharmacy/Sales Return")
);
const PharmacyStockAdjustments = React.lazy(() =>
  import("../DemoPages/Pharmacy/StockAdjustments")
);

const StoresMaster = React.lazy(() => import("../DemoPages/Stores/Masters"));
const StoresSales = React.lazy(() => import("../DemoPages/Stores/Sales"));
const StoresPurchase = React.lazy(() => import("../DemoPages/Stores/Purchase"));
const StoresCustomer = React.lazy(() => import("../DemoPages/Stores/Customer"));
const StoresPresciption = React.lazy(() =>
  import("../DemoPages/Stores/Prescription")
);
const StoresDepartmentIndent = React.lazy(() =>
  import("../DemoPages/Stores/Department Indent")
);
const StoresDepartmentTransfer = React.lazy(() =>
  import("../DemoPages/Stores/Department Transfer")
);
const StoresDepartmentUsage = React.lazy(() =>
  import("../DemoPages/Stores/Department Usage")
);
const StoresPurchaseOrder = React.lazy(() =>
  import("../DemoPages/Stores/Purchase Order")
);
const StoresDailyCollectionReports = React.lazy(() =>
  import("../DemoPages/Stores/Reports/DailyCollectionReport")
);
const StoresPurchaseReturns = React.lazy(() =>
  import("../DemoPages/Stores/Purchase Return")
);
const StoresSaleReturns = React.lazy(() =>
  import("../DemoPages/Stores/Sales Return")
);
const StoresStockAdjustments = React.lazy(() =>
  import("../DemoPages/Stores/StockAdjustments")
);

const Theatres = React.lazy(() =>
  import("../DemoPages/OperationTheatre/Theatres")
);
const OTBookingMaster = React.lazy(() =>
  import("../DemoPages/OperationTheatre/BookingMaster")
);
const OTBookingListReport = React.lazy(() =>
  import("../DemoPages/OperationTheatre/OTBookingReports")
);
const OTBookingConsultantWiseReport = React.lazy(() =>
  import("../DemoPages/OperationTheatre/ConsultantWiseBooking")
);
const OTBookingList = React.lazy(() =>
  import("../DemoPages/OperationTheatre/OTBookingList")
);
const OTReportEntry = React.lazy(() =>
  import("../DemoPages/OperationTheatre/OperationReportEntry")
);

const Stores = React.lazy(() => import("../DemoPages/Stores"));
const IPD = React.lazy(() => import("../DemoPages/IPD"));

const ReferralConsultantMaster = React.lazy(() =>
  import("../DemoPages/ReferalDoctorPayabal")
);

const AquaDesk = React.lazy(() => import("../DemoPages/AquaDesk"));
const RaiseTicket = React.lazy(() =>
  import("../DemoPages/AquaDesk/RaiseTicket")
);
const ViewAllTicket = React.lazy(() => import("../DemoPages/AquaDesk/ViewAll"));
const TicketPriority = React.lazy(() =>
  import("../DemoPages/AquaDesk/Masters/Priorities")
);
const ProblemCategories = React.lazy(() =>
  import("../DemoPages/AquaDesk/Masters/ProblemCategories")
);
const SLA = React.lazy(() => import("../DemoPages/AquaDesk/Masters/SLA"));

const IVFMaster = React.lazy(() => import("../DemoPages/newmaster/ivfMaster"));

const ConsentForms = React.lazy(() => import("../DemoPages/ConsentForms"));
//const InitialAssesment =React.lazy(()=>import('../DemoPages/newmaster/initialAssesment'

const GeneralHome = React.lazy(() => import("../DemoPages/GeneralHome"));

const Admin = React.lazy(() => import("../DemoPages/Admin"));

const IPDAdvance = React.lazy(() => import("../DemoPages/IPD Billings"));
const IPDBilling = React.lazy(() => import("../DemoPages/IPD Billing"));
const IPDRefund = React.lazy(() => import("../DemoPages/IPD Refund"));
const IPDReceivable = React.lazy(() => import("../DemoPages/IPD Receivable"));
const OpdPrescription = React.lazy(() => import("../DemoPages/IpPrescription"));
const IPDDischarge = React.lazy(() => import("../DemoPages/IPD Discharge"));
const IPDPatientList = React.lazy(() =>
  import("../DemoPages/IPD/PatientList/PatientListTable")
);
const IPDCancelList = React.lazy(() =>
  import("../DemoPages/IPD/PatientList/PatientCancelList")
);

const OPIAMaster = React.lazy(() =>
  import("../DemoPages/OPInitialAssessment/Masters")
);
const OPInitialAssessment = React.lazy(() =>
  import("../DemoPages/OPInitialAssessment")
);
const IPDDateWiseReport = React.lazy(() => import("../DemoPages/IPDReports"));

const Organization = React.lazy(() => import("../DemoPages/Organization"));
const Branches = React.lazy(() => import("../DemoPages/Organization"));

export function toTitleCase(str) {
  return (
    str &&
    str.replace(/\w\S*/g, function(txt) {
      return txt;
      //return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
  );
}
export function formatMenuLink(str) {
  return (
    str &&
    str
      .replace(/ /g, "-")
      .replace(/\//, "")
      .replace("(", "")
      .replace(")", "")
      .toLowerCase()
  );
}
export function get_menus(menus, parents, pmenu = null) {
  let orgData = sessionStorage.getItem("orgInfo");
  if (orgData) {
    orgData = JSON.parse(orgData);
  }
  let nav = parents.map((menu) => {
    if (pmenu == null) {
      menu.link = "#/" + formatMenuLink(menu.name);
    } else {
      menu.link = `${pmenu.link}/${formatMenuLink(menu.name)}`;
    }

    let childs = menus.filter((m) => m.parent === menu._id);
    childs = childs.sort((a, b) => (a.slnum > b.slnum && 1) || -1);
    let exists = childs.length;

    // let ob = {
    //     icon: menu.icon_type=="icon"?"pe-7s-diamond":menu.icon,
    //     label: toTitleCase(menu.name),

    // }

    let ob = {
      icon: "custom-icon icon-menu-" + menu._id,
      label: toTitleCase(menu.name),
    };

    if (exists) {
      $("#icons_style").append(
        ".icon-menu-" +
          menu._id +
          "{background-image: url('" +
          apiUrl +
          menu.icon +
          "');}"
      );

      ob.content = get_menus(menus, childs, menu);
    } else {
      if (pmenu == null) {
        $("#icons_style").append(
          ".icon-menu-" +
            menu._id +
            "{background-image: url('" +
            apiUrl +
            menu.icon +
            "');}"
        );
      }
      ob.to = menu.link;
    }
    if (
      ob?.label === "Organizations" &&
      orgData?._id !== "6516cad2c5bb548b893f3b70"
    ) {
      let branchob = ob;
      branchob["to"] = "#/org_loactions";
      return branchob;
    } else {
      return ob;
    }
  });
  return nav;
}

export function get_route(navData) {
  let routes = navData.reduce((n, f) => {
    if (f.content !== undefined) {
      let c = get_route(f.content);
      return [...n, ...c];
    } else {
      let url = f.to.replace("#", "");
      // console.log(url)
      // console.log(linksToComponent[url])
      let component = linksToComponent[url] ? linksToComponent[url] : Error404;
      //  if(url=='/aquadesk/new-ticket')
      //    console.log(component)
      return [
        ...n,
        <Route key={`${url}`} path={`${url}`} component={component} />,
      ];
    }
  }, []);
  return routes;
}
export function get_urls(navData) {
  let routes = navData.reduce((n, f) => {
    if (f.content !== undefined) {
      let c = get_urls(f.content);
      return { ...n, ...c };
    } else {
      return { ...n, [f.to]: "compo" };
    }
  }, {});
  return routes;
}

const linksToComponent = {
  ///////////////////////HOME/////////////////////////////////////////////
  "/home": GeneralHome,

  "/consultantHome": ConsultantHome,

  ///////////////////////ADMIN/////////////////////////////////////////////
  "/admin": Admin,
  "/admin-master/admin": Admin,

  ///////////////PATIENT REGISTRATION/////////////////////////////////////
  "/patient-registration/masters/registration-masters": RegistrationMasters,
  "/patient-registration/masters/general-master": GeneralMasters,
  "/patient-registration/masters/registration-fee-master": RegistrationFeesMaster,
  "/patient-registration/masters/age-wise-master": RegistrationAgewiseServices,
  "/patient-registration/masters/registration-wise-service-charges": RegistartionWiseserviceMaster,
  "/patient-registration/masters/registration-wise-consultant-fee": RegistrationWiseConsultantFee,
  "/patient-registration/transactions/new-patient": PatientRegistration,
  "/patient-registration/transactions/patient-list": ViewAllPatients,

  ///////////////EMPLOYEES REGISTRATION/////////////////////////////////////
  "/employees-registration/employee-master": NewMaster,
  "/employees-registration/new-employee-registration": UserMaster,
  "/employees-registration/new-consultant-registration": MyConsultantMasters,
  //"/employees-registration/new-referrer-registration":ReferralConsultantMaster,

  ////////////////OUT PATIENT DEPARTMENT/////////////////////////////////
  "/out-patient-department/initial-assessment-master": InitialAssessmentMaster,
  "/out-patient-department/opd-advances": RegistrationMasters,
  "/out-patient-department/appointments-schedule": AppointmentMaster,

  "/out-patient-department/opd-initial-assessment": InitialAssessment,

  "/out-patient-department/opd-orders/pharmacy-orders": PharmacyOrders,

  "/out-patient-department/opd-orders/service-orders": ServiceOrders,

  "/out-patient-department/opd-pharmacy": PharmacyOrders,

  "/out-patient-department/opd-consent": ConsentForms,
  "/out-patient-department/service-master": CustomerMasters,
  "/out-patient-department/token-monitor": TokenMonitor,
  "/out-patient-department/patient-queue": PatientQueue,

  ///////////////////OPD BILLING//////////////////////////////////////
  "/out-patient-department/opd-reports/receipt-wise-collection": OpdReports,
  "/out-patient-department/opd-reports/patient-wise-report": PatientWiseDetailedReport,
  //"/out-patient-department/opd-reports/patient-wise-consolidated-report": PatientWiseConsolidatedReport,
  "/out-patient-department/opd-reports/consultant-wise-report": ConsultantWiseReport,
  "/out-patient-department/opd-orders-billing/opd-billing": OPDBilling,
  "/out-patient-department/opd-orders-billing/opd-bill-edit": OPDBilling,
  "/out-patient-department/opd-orders-billing/opd-cancel": OPDCancelBillingGridView,
  "/out-patient-department/opd-orders-billing/opd-advance": OPDBilling,
  "/out-patient-department/opd-orders-billing/opd-refund": OPDCancelBillingGridView,
  "/out-patient-department/opd-orders-billing/opd-receivable": OPDReceivableBillingGridView,

  "/referrals/new-referral-registration": ConsultantReferalMaster,

  "/out-patient-department/opd-orders": OPDMaster,
  "/out-patient-department/opd-prescription": OpdPrescription,

  //////////////////////////IPD//////////////////////////////////////
  "/in-patient-department/ipd-service-master": IPDServiceMaster,
  "/in-patient-department/tpa/ipd-tpa-wise-service": IPDTPAWiseServiceMaster,
  "/in-patient-department/ipd-admission": PatientModuleIpd,
  "/in-patient-department/lab-pos": LABPOS,
  "/in-patient-department/pos/laboratory-pos": LABPosMaster,
  "/in-patient-department/pos/radiology-pos": RadiologyPos,
  "/in-patient-department/pos/canteen-pos": CanteenPos,
  "/in-patient-department/pos/nurse-pos": NursePos,
  "/in-patient-department/ipd-referral-master": IPDReferralMaster,
  "/in-patient-department/ipd-corporate-master": IPDCorporateMaster,
  "/in-patient-department/tpa/ipd-tpa-master": IPDTPAMaster,
  "/in-patient-department/corporates/ipd-corporate-wise-service": IPDCorporateWiseServiceMaster,
  "/in-patient-department/pos/pos": GeneralPOS,
  "/in-patient-department/ipd-discharge": IPDDischarge,
  "/in-patient-department/ipd-billing/ipd-advance": IPDAdvance,
  "/in-patient-department/ipd-billing/ipd-billing": IPDBilling,
  "/in-patient-department/ipd-billing/ipd-refund": IPDRefund,
  "/in-patient-department/ipd-billing/ipd-receivable": IPDReceivable,
  "/in-patient-department/ipd-patient-list": IPDPatientList,
  "/in-patient-department/ipd-cancel-list": IPDCancelList,
  "/in-patient-department/reports/ipd-date-wise-report": IPDDateWiseReport,

  //////////////////////////WARD//////////////////////////////////////

  //////////////////////////Pharmacy/////////////////////////////////
  "/pharmacy/master": PharmacyMaster,
  "/pharmacy/sales/new-sales": PharmaSales,
  "/pharmacy/sales/return-sales": PharmaSalesCompleted,
  "/pharmacy/sales/due-receivables": PharmaDueReceivables,
  "/pharmacy/purchase-grn": PharmaPurchase,
  "/pharmacy/purchase-grn-cancel": PharmaPurchaseGrn,
  "/pharmacy/customers": PharmacyCustomer,
  "/pharmacy/out-patient-prescription": PharmacyPresciption,
  "/pharmacy/inpatient-list": InPatientIndent,
  "/pharmacy/department-indent": DepartmentIndent,
  "/pharmacy/purchase-return": PurchaseReturn,

  "/pharmacy/department-transfer": DepartmentTransfer,
  "/pharmacy/department-usage": DepartmentUsage,
  "/pharmacy/purchase-order": PurchaseOrder,
  "/pharmacy/supplier-payment": SupplierPayment,
  "/pharmacy/stocks": PharmacyStockAdjustments,
  "/pharmacy/stock-finder": StockFinder,
  "/pharmacy/reports/daily-collection": PharmacyDailyCollectionReports,
  "/pharmacy/reports/po-report": PharmacyPurchaseOrderReports,
  "/pharmacy/reports/grn-report": PharmacyPurchaseGRNReports,
  "/pharmacy/reports/purchase-gst": PharmacyPurchaseGSTReports,
  "/pharmacy/reports/sales-report": PharmacySalesReport,
  // "/pharmacy/reports/ipd-sales-report": IPDPharmacySalesReport,
  "/pharmacy/reports/due-report": PharmacyDueReport,
  "/pharmacy/reports/purchase-return-report": PurchaseReturnReport,
  "/pharmacy/reports/expiry-report": PharmacyExpiryReport,
  "/pharmacy/reports/current-stock": PharmacyCurrentStockReport,
  "/pharmacy/reports/closing-stock": PharmacyClosingStockReport,
  "/pharmacy/reports/opd-patient-wise-sales-report": PharmacyOPPatientSalesReport,
  "/pharmacy/reports/ipd-patient-wise-sales-report": PharmacyIPPatientSalesReport,
  "/pharmacy/reports/sales-returns": PharmacySaleReturns,
  "/pharmacy/reports/purchase-returns": PharmacyPurchaseReturns,

  "/operation-theatre/ot-bookings": OTBookingMaster,
  "/operation-theatre/reports/ot-booking-list": OTBookingListReport,
  "/operation-theatre/reports/consultant-wise-bookings": OTBookingConsultantWiseReport,
  "/operation-theatre/ot-booking-list": OTBookingList,
  "/operation-theatre/reports/procedure-reports": OTReportEntry,

  //////////////////////////Pharmacy/////////////////////////////////
  "/stores/master": StoresMaster,
  "/stores/sales": StoresSales,
  "/stores/purchase-grn": StoresPurchase,
  "/stores/customers": StoresCustomer,
  "/stores/out-patient-prescription": StoresPresciption,
  "/stores/department-indent": StoresDepartmentIndent,
  "/stores/department-transfer": StoresDepartmentTransfer,
  "/stores/department-usage": StoresDepartmentUsage,
  "/stores/purchase-order": StoresPurchaseOrder,
  "/stores/stocks": StoresStockAdjustments,
  "/stores/reports/daily-collection": StoresDailyCollectionReports,
  "/stores/reports/sales-returns": StoresSaleReturns,
  "/stores/reports/purchase-returns": StoresPurchaseReturns,

  "/in-patient-department/patient": IPD,

  //////////////////////////Referrals////////////////////////////////
  "/referrals/referrals-consultant-master": ReferralConsultantMaster,

  //////////////////////////TPA//////////////////////////////////////

  //////////////////////////Corporates///////////////////////////////

  //////////////////////////AV RECORDING/////////////////////////////

  //////////////////////////Radiology////////////////////////////////

  "/radiology/templates": RadiologyTemplates,
  "/radiology/collection": RadiologyCollection,
  "/radiology/department-collection": RadiologyDepartmentCollection,
  "/radiology/report-entry": RadiologyReportEntry,
  "/radiology/inpatient-list": RadiologyInPatientList,
  "/radiology/op-patient-report": RadiologyOpPatientReport,

  //////////////////////////Laboratory//////////////////////////////////////
  "/laboratory/masters": LabMasters,
  "/laboratory/pos-config": LabPOSConfig,
  "/laboratory/out-sources": LabOutSource,
  "/laboratory/out-source-mapping": LabOutSourceMapping,
  "/laboratory/template-mapping": LabTemplates,
  "/laboratory/test-mapping": LabTestMapping,
  "/laboratory/subtest-mapping": LabSubTestMapping,
  "/laboratory/antibiotics-mapping": LabAntibioticsMapping,
  "/laboratory/group-mapping": LabGroupMapping,
  "/laboratory/test-sample": LabTestSample,
  "/laboratory/lab-technicians": LabTechnicians,
  "/laboratory/patient-list": LabPatientList,
  "/laboratory/test-sample-collection": LabPatientList,
  "/laboratory/report-entry": LabReportEntry,
  "/laboratory/approve-reports": LabApproveReports,
  "/laboratory/interim-report": LabInterimReport,
  // "/laboratory/ip-patient-report": LabIpPatientReport,
  "/laboratory/op-patient-report": LabOpPatientReport,
  //////////////AUDIO AND VIDEO RECORDING////////////////////////////
  "/audio-video-recording/video-recording": WebCam,
  "/audio-video-recording/patient-audio-&-video-records": AudioVideo,
  "/dms": DMSGridView,

  ///////////////////////SUPERADMIN///////////////////
  //"/superadmin" : SuperAdmin,

  ///////////////////AQUADESK//////////////////////////////////////
  //"/aquadesk":AquaDesk,
  "/aquadesk/new-ticket": RaiseTicket,
  "/aquadesk/view-tickets": ViewAllTicket,
  "/aquadesk/masters/priority": TicketPriority,
  "/aquadesk/masters/sla": SLA,
  "/aquadesk/masters/problem-categories": ProblemCategories,

  //////////////////////////////WardMaster///////////////
  "/in-patient-department/ward-master/masters/ward-type": WardType,
  "/in-patient-department/ward-master/masters/ward-category": WardCatagory,
  "/in-patient-department/ward-master/masters/ward-subcategory": WardSubCatagory,
  "/in-patient-department/ward-master/masters/room": WardRoom,
  "/in-patient-department/ward-master/masters/bed": WardBed,
  "/in-patient-department/ward-master/masters/ward-transfer": WardTransfer,
  "/in-patient-department/ward-master/masters/ward-calculation": WardCalculation,
  "/in-patient-department/ward-master/registration-fees": WardCatagoryFees,
  "/in-patient-department/ward-master/grace-period-settings": GracePeriodSettings,
  "/in-patient-department/ward-master/masters/ward-facility": WardFacility,
  "/in-patient-department/ward-master/ip-visit-charges": WardConsultantFees,
  "/in-patient-department/ward-master/service-charges": WardWiseServiceCharge,
  "/in-patient-department/ward-master/consultant-service-share": WardConsultantShare,

  ////////////////////SuperAdmin
  "/superadmin/customer-management": CustomerMaster,
  "/superadmin/licence-management/generate-licence": GenerateLicence,
  "/superadmin/licence-management/generate-json-file": GenerateJSONFile,

  /////////////////// INITIAL ASSESSMENT //////////////////////////////////////
  "/out-patient-department/opd-initial-assessment/opd-vitals": Vitals,
  "/out-patient-department/opd-initial-assessment/masters/ivf-master": IVFMaster,
  "/out-patient-department/opd-initial-assessment/initial-assessment": InitialAssessmentMaster,
  "/out-patient-department/opd-initial-assessment/masters/ccm-master": InitialAssessment,
  "/third-party-associates/third-party-associates": TPAMaster,
  // "/out-patient-department/opd-initial-assessment/masters/symptoms" : SymptomsMaster
  // "/out-patient-department/opd-initial-assessment/masters/findings" : FindingsMaster

  /////////////////// OP INITIAL ASSESSMENT //////////////////////////////////////
  "/out-patient-department/op-initial-assesment/master": OPIAMaster,
  "/out-patient-department/op-initial-assesment/initial-assessment": OPInitialAssessment,

  ///////////////////////ORGANIZATION///////////////////
  "/org_loactions": Branches,

  "/organizations": Organization,
};
