
    export const registrationMasterConstants={

      GET_ALL_REQUEST:'GET_ALL_REQUEST',
      GET_ALL_SUCCESS:'GET_ALL_SUCCESS',
      GET_ALL_FAILURE:'GET_ALL_FAILURE',   
      
      GET_ACTIVE_REQUEST:'GET_ACTIVE_REQUEST',
      GET_ACTIVE_SUCCESS:'GET_ACTIVE_SUCCESS',
      GET_ACTIVE_FAILURE:'GET_ACTIVE_FAILURE', 

      ADD_REQUEST:'ADD_REQUEST',
      ADD_SUCCESS:'ADD_SUCCESS',
      ADD_FAILURE:'ADD_FAILURE',  

      DELETE_REQUEST:'DELETE_REQUEST',
      DELETE_SUCCESS:'DELETE_SUCCESS',
      DELETE_FAILURE:'DELETE_FAILURE',  

      UPDATE_REQUEST:'UPDATE_REQUEST',
      UPDATE_SUCCESS:'UPDATE_SUCCESS',
      UPDATE_FAILURE:'UPDATE_FAILURE', 

      SET_DEFAULT_REQUEST:'SET_DEFAULT_REQUEST',
      SET_DEFAULT_SUCCESS:'SET_DEFAULT_SUCCESS',
      SET_DEFAULT_FAILURE:'SET_DEFAULT_FAILURE', 

      CLEAR:'CLEAR'
       
       
    
    }