import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Table,
  Card,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
  Modal,
  CustomInput,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { sweetAlertActions, departmentActions } from "../../actions";
import { toast, Bounce } from "react-toastify";
import { httpHelperService } from "../../services/http.helper.service";
import _ from "lodash";
import PatientGeneralInfo from "../MyComponents/patientipdgeneralcomponent";
import Select, { components } from "react-select";
import { registrationWithFeesService } from "../../services";
import { loginservice } from "../../services/login.service";
import { referalConsultantService } from "../../services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icons } from "../../helpers/IconHelper";

const  unDeleteIcon = Icons.Restore
class RadiologyPOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Add",
      errormessage: "",
      visible: false,
      items: [],
      graceHour: [],
      transfer_history: [],
      max_date: moment(new Date()).format("YYYY-MM-DD"),
      from_date: moment(new Date()).format("YYYY-MM-DD"),
      from_time: moment(new Date()).format("HH:mm"),
      modal: false,
      packageAmtIncrease: {},
      amountCalculation: "increaseBy%",
      filterData: "all",
      pos_amount_show: false,
      isDeleteAuthorized: false,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.togglePackagePopup = this.togglePackagePopup.bind(this);
    this.packageAmtIncrease = this.packageAmtIncrease.bind(this);
    this.packageServiceIncrease = this.packageServiceIncrease.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showAmountField = this.showAmountField.bind(this);
  }

  NoOptionsMessage(props) {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Select option here</span>
      </components.NoOptionsMessage>
    );
  }

  onSubmitForm = () => {
    let { dispatch, patient } = this.props;

    const items = this.state.items;

    delete items[0];

    const itemData = items.filter((value) => value != null);

    let payload = {
      patient_ipd_mrn_no: patient.patient_ipd_mrn_no,
      patient_uhid: patient.patient_uhid,
      patient_id: patient._id,
      patient_ipd_mrn_no: patient.patient_ipd_mrn_no,
      ipd_admission_no: this.state.subCategoryList.ipd_admission_no,
      pos_service_list: itemData,
    };

    if (!this.state._id) {
      let url = "api/pos/addPosServices";
      httpHelperService
        .postRequest(payload, url)
        .then((res) => {
          if (res.success) {
            this.props.history.push({
              pathname: `/in-patient-department/pos/radiology-pos`,
            });
            toast(res.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let url = `api/pos/updatePosServices/${this.state._id}`;
      httpHelperService
        .updateRequest(payload, url)
        .then((res) => {
          if (res.success) {
            this.props.history.push({
              pathname: `/in-patient-department/pos/radiology-pos`,
            });
            toast(res.message, {
              transition: Bounce,
              closeButton: true,
              autoClose: 1000,
              position: "bottom-center",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onChangeText(e) {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  showAmountField() {
    const { items } = this.state;

    for (let i = 0; i < items.length; i++) {
      items[i].pos_amount_show = true;
    }
    this.setState({ items: items, pos_amount_show: true });
  }

  onChange(e) {
    if (e.target.value === "all") {
      this.setState({ searchValue: "" });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClick(key) {
    let list = [...this.state.items];
    let item = list[key];
    if (item.isActive) {
      item.isActive = false;
    } else {
      item.isActive = true;
    }

    this.setState({
      items: list,
    });
  }

  componentDidMount() {
    let {
      wardsubcatagory,
      serviceMaster,
      patient,
      consultants,
      dispatch,
      departments,
    } = this.props;
    // dispatch(departmentActions.getAll());
    let deptCode = departments.find((dept) =>
      dept.name.toLowerCase().includes("radi")
    )?.code;

    if (!this.props.location.state) {
      this.props.history.push({
        pathname: `/in-patient-department/pos/radiology-pos`,
      });
    }

    if (this.props.location && this.props.location.state) {
      const { mode, data } = this.props.location.state;

      if (mode === "NEW") {
        if (data) {
          const serviceData = serviceMaster.data.services
            .filter(
              (value) => value.D_CODE.toLowerCase() === deptCode
              // value.D_CODE.toLowerCase() === "radi" ||
              // value.D_CODE === "2"
            )
            .map((items, key) => {
              return {
                key: key,
                value: items.CODE,
                label: items.SERVICE_NAME,
                type: "Service",
              };
            });

          const packageData = serviceMaster.data.packages
            .filter(
              (value) => value.D_CODE.toLowerCase() === deptCode
              // value.D_CODE.toLowerCase() === "radi" ||
              // value.D_CODE === "2"
            )
            .map((items, key) => {
              return {
                key: key,
                value: items.CODE,
                label: items.PACKAGE_NAME,
                type: "Package",
              };
            });

          const groupData = serviceMaster.data.groups
            .filter(
              (value) => value.D_CODE.toLowerCase() === deptCode
              // value.D_CODE.toLowerCase() === "radi" ||
              // value.D_CODE === "2"
            )
            .map((items, key) => {
              return {
                key: key,
                value: items.CODE,
                label: items.GROUP_NAME,
                type: "Group",
              };
            });

          const consultantList = consultants
            .filter((value) => value.active && !value.deleted)
            .map((items, key) => {
              return {
                key: key,
                value: items.code,
                label:
                  // "Dr. " +
                  // + this.codeToValue(
                  //   items.title,
                  //   "titles"
                  // )
                  // " " +
                  items.firstname + " " + items.lastname,
                type: "Group",
              };
            });

          const subCategoryData = wardsubcatagory.find(
            (list1) => list1.code === data.wsc_code
          );

          this.getWardServiceCharges(subCategoryData);
          this.consultantShareMaster(data?.wsc_code);

          let posData = [];

          let deptCodeValue =
            departments &&
            departments.find((list) => list.code.toLowerCase() === deptCode);

          let url = `api/pos/getPosServices?ipd_admission_no=${
            data?.ipd_admission_no
          }&pos_service_type=${deptCode?.toLowerCase()}`;
          // }&pos_service_type=${deptCodeValue?.code?.toLowerCase()}`;
          httpHelperService
            .getRequest(url)
            .then((res) => {
              if (res.success) {
                posData = res?.data?.pos_service_list || [];
                this.setState({
                  _id: res?.data?._id?._id,
                });

                toast(res.message, {
                  transition: Bounce,
                  closeButton: true,
                  autoClose: 1000,
                  position: "bottom-center",
                  type: "success",
                });
              }

              const resultData = {
                sno: (posData?.length > 0 ? posData.length : 0) + 1,
                service_code: "",
                package_code: "value",
                wsc_code: data.wsc_code,
                pos_amount: 0,
                pos_total_amount: 0,
                pos_qty: 0,
                pos_date: new Date(),
                service_edit: false,
                pos_qty_edit: false,
                pos_amount_edit: false,
                tax_edit: false,
                dept_code: "",
                user: data.created_by,
                tax_amount: 0,
                tax_percentage: 0,
                consultant: "",
                consultant_share: 0,
                consultant_edit: true,
                discount_applicable: false,
                deleteIcon: false,
              };

              let posDataService = [];
              if (posData?.length > 0) {
                let deletedData = posData.filter((value) => value.is_deleted);
                let non_DeletedData = posData.filter(
                  (value) => !value.is_deleted
                );
                non_DeletedData.push(...deletedData);

                posDataService = [resultData, ...non_DeletedData];
              } else {
                posDataService = [resultData];
              }

              this.setState({
                items: posDataService,
                selectedList: [...serviceData, ...packageData, ...groupData],
                subCategoryList: data,
                packageMapping: serviceMaster.data.mappingData,
                serviceDataList: serviceData,
                consultantList: consultantList,
                subCategoryData: subCategoryData,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  getWardServiceCharges(payload) {
    registrationWithFeesService
      .getService({ rtwp_rtm_code: payload?.ward_catagory })
      .then((data) => {
        if (data.success) {
          this.setState({
            serviceList: data.services,
            packageList: data.packages,
            groupList: data.groups,
          });
        }
      });
  }

  consultantShareMaster(code) {
    referalConsultantService
      .getById({
        ward_sub_catagory: code,
      })
      .then((data) => {
        this.setState({
          consultantserviceList: data.services,
          consultantpackageList: data.packages,
          consultantgroupList: data.groups,
        });
      });
  }

  onLogin() {
    const {
      username,
      password,
      reason,
      mode,
      selectdata,
      indexValue,
    } = this.state;

    const { dispatch } = this.props;
    const payload = {
      username,
      password,
      reason,
    };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          this.showAmountField();
          this.togglePopup();
          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  onDeleteLogin(data, i) {
    const { username, password, reason, deletedata, deleteIndex } = this.state;

    const { dispatch } = this.props;
    const payload = { username, password, reason, login_type: "consultant" };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          if (this.state.isDeleteAuthorized) {
            this.deletePos(data, i);
          } else {
            this.deletePos(deletedata, deleteIndex);
            this.setState({ isDeleteAuthorized: true });
            this.toggleDelete();
          }
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  toggleDelete(data, i) {
    if (data || i) {
      this.setState({
        deletedata: data,
        deleteIndex: i,
      });
    }
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }

  onLoginData() {
    const {
      username,
      password,
      package_add_reason,
      mode,
      selectdata,
      indexValue,
    } = this.state;

    const { dispatch } = this.props;
    const payload = {
      username,
      password,
      reason: package_add_reason,
    };
    loginservice
      .authenticate(payload)
      .then((res) => {
        if (res.success) {
          this.addPackageService(selectdata, indexValue);
          this.togglePackagePopup();
          this.setState({ isAuthorized: true });
        } else {
          alert("Authentication failed");
        }
      })
      .catch((err) => {
        alert("Authentication failed");
      });
  }

  serviceHandler = (option, i) => {
    const {
      items,
      serviceList,
      packageList,
      groupList,
      subCategoryList,
      packageMapping,
    } = this.state;
    const { patient } = this.props;
    let serviceFilter;
    let selectedName;
    let serviceAmount;
    let taxAmount;
    let totalAmount;
    let deptCode;

    if (option.type === "Service") {
      serviceFilter = serviceList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.SERVICE_NAME === option?.label;
      });
      selectedName = serviceFilter?.SERVICE_NAME;
    } else if (option.type === "Package") {
      serviceFilter = packageList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.PACKAGE_NAME === option?.label;
      });
      selectedName = serviceFilter.PACKAGE_NAME;
    } else if (option.type === "Group") {
      serviceFilter = groupList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.GROUP_NAME === option?.label;
      });
      selectedName = serviceFilter.GROUP_NAME;
    }

    if (option.type === "Package") {
      const packageFilter = packageMapping?.find((list) => {
        return list.PKM_CODE === option.value;
      });

      const packageMapFilter = {
        sno: items.length + packageFilter?.SM_CODE?.length,
        service_code: option?.value || option?.label,
        package_code: option?.value || option?.label,
        selected_name: selectedName || option?.label,
        package_service_name: "",
        package_service_amount: packageFilter?.PACKAGE_SERVICE_AMT || 0,
        package_amount_edit: false,
        service_edit: false,
        pos_amount: packageFilter?.PACKAGE_SERVICE_AMT || 0,
        pos_qty: 1,
        addButton: true,
        pos_qty_edit: serviceFilter?.IP_QTY_EDIT,
        pos_amount_edit: serviceFilter?.EDIT_SERVICE_AMT_IP,
        tax_percentage: serviceFilter?.TAX_AMOUNT_IP,
        tax_amount:
          (packageFilter?.PACKAGE_SERVICE_AMT * serviceFilter?.TAX_AMOUNT_IP) /
          100,
        pos_total_amount: 1 * (packageFilter?.PACKAGE_SERVICE_AMT || 0),
        dept_code: serviceFilter?.D_CODE,
        user: subCategoryList?.created_by,
        pos_date: new Date(),
        wsc_code: subCategoryList?.wsc_code,
        type: "Packages",
        sm_code: packageFilter?.SM_CODE,
        disabled: !serviceFilter?.EDIT_SERVICE_AMT_IP,
        amount: packageFilter?.PACKAGE_SERVICE_AMT || 0,
        pos_amount_show: this.state.pos_amount_show,
        is_package: true,
        consultant: "",
        consultant_share: 0,
        discount_applicable: serviceFilter?.DISCOUNT_IP_APP,
        package_id: serviceFilter?._id,
        pos_service_type: serviceFilter?.D_CODE.toLowerCase(),
        deptCode: serviceFilter?.D_CODE,
        deleteIcon: true,
      };

      const packageServiceFilter = packageFilter?.SM_CODE?.map(
        (value, index) => {
          const serviceAmount =
            value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT || 0;

          return {
            sno: items.length - 1 + (packageFilter?.SM_CODE?.length - index),
            service_code: option?.value || option?.label,
            package_code: option?.value || option?.label,
            selected_name: "",
            package_service_name: value.SERVICE_NAME,
            package_service_amount: serviceAmount,
            package_amount_edit: value?.EDIT_SERVICE_AMT_IP,
            service_edit: false,
            pos_amount: null,
            amount: serviceAmount,
            pos_qty: null,
            pos_qty_edit: value?.IP_QTY_EDIT,
            pos_amount_edit: false,
            // tax_percentage: value?.TAX_AMOUNT_IP,
            // tax_amount: (serviceAmount * value?.TAX_AMOUNT_IP) / 100,
            // pos_total_amount:
            //   1 * (serviceAmount + serviceAmount * value?.TAX_AMOUNT_IP),
            tax_percentage: null,
            tax_amount: null,
            pos_total_amount: null,
            tax_edit: value?.TAX_APPLICABLE_IN_IP,
            dept_code: value?.D_CODE,
            user: subCategoryList?.created_by,
            pos_date: new Date(),
            wsc_code: subCategoryList?.wsc_code,
            // type: "Packages",
            sm_code: packageFilter?.SM_CODE,
            disabled: true,
            pos_amount_show: this.state.pos_amount_show,
            consultant: "",
            consultant_share: 0,
            package_id: serviceFilter?._id,
            service_id: value?._id,
            deleteIcon: true,
            type: option?.type,
          };
        }
      );
      items.splice(0, 1, packageMapFilter, ...packageServiceFilter);
    } else {
      if (
        option.label
          .replace(/ /g, "")
          .toLowerCase()
          .includes("ipvisit")
      ) {
        serviceAmount = 0;
        taxAmount = 0;
        totalAmount = 0;
        deptCode = serviceFilter?.D_CODE;
      } else {
        serviceAmount =
          serviceFilter[
            `${subCategoryList?.ward_sub_catagory}_${subCategoryList?.code}_amount`
          ] ||
          serviceFilter?.newAmount ||
          serviceFilter?.SERVICE_AMT ||
          serviceFilter?.GROUP_AMT ||
          0;
        taxAmount = (serviceAmount * serviceFilter?.TAX_AMOUNT_IP) / 100;
        totalAmount = 1 * serviceAmount;
        deptCode = serviceFilter?.D_CODE;
      }

      const item = items[i];
      item.service_code = option?.value || option?.label;
      item.package_code = option?.value || option?.label;
      item.selected_name = selectedName || option?.label;
      item.package_service_name = "";
      item.service_edit = false;
      item.pos_amount = serviceAmount;
      item.amount = serviceAmount;
      item.pos_date = new Date();
      item.pos_qty = 1;
      item.pos_qty_edit = serviceFilter?.IP_QTY_EDIT;
      item.pos_amount_edit = serviceFilter?.EDIT_SERVICE_AMT_IP;
      item.tax_percentage = serviceFilter?.TAX_AMOUNT_IP || 0;
      item.tax_amount = taxAmount;
      item.pos_total_amount = totalAmount;
      item.tax_edit = serviceFilter?.TAX_APPLICABLE_IN_IP;
      item.pos_service_type = deptCode.toLowerCase();
      item.dept_code = deptCode;
      item.user = subCategoryList?.created_by;
      item.wsc_code = subCategoryList?.wsc_code;
      item.disabled = !serviceFilter?.EDIT_SERVICE_AMT_IP;
      item.pos_amount_show = this.state.pos_amount_show;
      item.consultant = "";
      item.consultant_share = 0;
      item.discount_applicable = serviceFilter?.DISCOUNT_IP_APP;
      item.service_id = serviceFilter?._id;
      item.deleteIcon = true;
      item.type = option?.type;
    }

    const resultData = {
      sno: items.length + 1,
      service_code: null,
      package_code: "value",
      wsc_code: subCategoryList?.wsc_code,
      pos_amount: 0,
      amount: 0,
      pos_total_amount: 0,
      pos_qty: 0,
      pos_date: new Date(),
      service_edit: true,
      pos_qty_edit: true,
      pos_amount_edit: true,
      tax_edit: true,
      dept_code: "",
      user: subCategoryList?.created_by,
      tax_amount: 0,
      tax_percentage: 0,
      disabled: false,
      // pos_amount_show : false
      consultant: "",
      consultant_share: 0,
      consultant_edit: true,
      discount_applicable: false,
      deleteIcon: false,
    };

    this.setState({
      items: [resultData, ...items],
    });
  };

  changeHandler = (e, item, i) => {
    const { items, consultPercentage } = this.state;

    const list = [...items];

    this.setState({
      packageAmtIncrease: {
        increasedAmount: parseInt(e.target.value),
        package: item,
        amount: list[i].pos_amount,
        index: i,
      },
    });

    item[e.target.name] = e.target.value;

    if (e.target.name === "pos_qty") {
      item.pos_total_amount = e.target.value * item.pos_amount;
    }

    if (e.target.name === "pos_amount") {
      item.pos_total_amount = item.pos_qty * e.target.value;

      if (
        item.selected_name
          .replace(/ /g, "")
          .toLowerCase()
          .includes("ipvisit")
      ) {
        item.consultant_share = e.target.value * consultPercentage || 0;
      }
    }

    // if (e.target.name === "tax_percentage") {
    //   item.tax_amount = (e.target.value * item.pos_amount) / 100;
    //   item.pos_total_amount =
    //     item.pos_qty * (item.pos_amount + e.target.value * item.pos_amount);
    // }

    // if (e.target.name === "tax_amount") {
    //   item.tax_percentage = (e.target.value / item.pos_amount) * 100;
    //   item.pos_total_amount =
    //     item.pos_qty * (item.pos_amount + e.target.value * item.pos_amount);
    // }

    list[i] = item;

    this.setState({
      items: list,
    });
  };

  packageHandler = (e, row, index) => {
    const { items, packageMapping } = this.state;

    const list = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    list[index][e.target.name] = valueAmount;
    list[index].pos_total_amount = list[index]?.pos_qty * valueAmount;

    const percentage =
      (valueAmount - row?.package_service_amount) / row?.package_service_amount;

    for (let i = 1; i <= row?.sm_code?.length; i++) {
      const amount = parseInt(
        (
          list[index + i]?.amount +
          list[index + i]?.amount * percentage
        ).toFixed(0)
      );

      list[index + i].package_service_amount = amount;
    }

    this.setState({
      items: list,
    });
  };

  wardSubCatagoryCodeToValue(code) {
    let { wardsubcatagory } = this.props;
    try {
      let name = wardsubcatagory.find((data) => data.code === code)
        .ward_sub_catagory;
      return name;
    } catch {
      return code;
    }
  }

  onconsultantChange(option, row, key) {
    const { registrationConsWithFeesMaster, consultants } = this.props;
    const {
      subCategoryList,
      subCategoryData,
      consultantserviceList,
      consultantpackageList,
      consultantgroupList,
    } = this.state;

    let list = [...this.state.items];
    let item = list[key];

    item["consultant"] = option.value;
    item.consultant_share = 0;
    let consultantShare = "";

    let consultshare = consultants
      .find((value) => value.code === option.value)
      ?.ipvisitcharges?.filter(
        (value) =>
          value?.display_visittype
            .replace(/ /g, "")
            .toLowerCase()
            .includes("firstvisit") ||
          value?.display_visittype
            .replace(/ /g, "")
            .toLowerCase()
            .includes("newpatient")
      )[0];

    if (row.type === "Packages") {
      consultantShare = consultantpackageList.find(
        (value) => value.CODE === row.CODE
      );
      item.consultant_share =
        (consultantShare &&
          consultantShare[`${option.label}_${option.value}_amount`]) ||
        0;
    } else {
      consultantShare = consultantserviceList.find(
        (value) => value.CODE === row.service_code
      );
      item.consultant_share =
        (consultantShare &&
          consultantShare[`${option.label}_${option.value}_amount`]) ||
        0;
    }

    if (item.consultant_share > item.pos_amount) {
      item.consultant_share = item.pos_amount;
    }

    if (
      row.selected_name
        .replace(/ /g, "")
        .toLowerCase()
        .includes("ipvisit")
    ) {
      const data = registrationConsWithFeesMaster.find(
        (item) => item.rtcf_rtm_code === subCategoryData?.ward_catagory
      );
      const filterData = data?.rtwp_sm_code?.find(
        (value) => value.code === option.value
      );

      let posValueAmount = 0;

      if (filterData) {
        posValueAmount =
          filterData[
            `${subCategoryData?.ward_sub_catagory}_${subCategoryData?.code}_amount`
          ] || 0;
      } else {
        posValueAmount = consultshare?.fee || 0;
      }

      item.pos_amount = posValueAmount;
      item.pos_total_amount = item.pos_qty * posValueAmount || 0;

      item.consultant_share =
        posValueAmount *
          (parseFloat(consultshare?.consultant_share_perc || 0) / 100) || 0;
    }
    this.setState({
      items: list,
      consultPercentage:
        parseFloat(consultshare?.consultant_share_perc || 0) / 100,
    });
  }

  togglePopup() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  packageAmtIncrease(e, row, i) {
    const { items, packageMapping, packageAmtIncrease } = this.state;

    const list = [...items];
    let findIndex;

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;
    list[i][e.target.name] = valueAmount;

    const length = row.sm_code?.length;
    findIndex = row.sm_code.findIndex(
      (value) =>
        value?.SERVICE_NAME === row.package_service_name &&
        (value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT) ===
          row?.amount
    );

    if (findIndex === -1) {
      findIndex = row.sm_code.findIndex(
        (value) =>
          value?.package_service_name === row.package_service_name &&
          value?.amount === row?.amount
      );
    }

    let indexValue = i - findIndex;
    let packageAmount = 0;

    for (let i = 0; i < length; i++) {
      packageAmount += parseFloat(
        list[indexValue + i].package_service_amount || 0
      );
    }

    list[i - findIndex - 1].pos_amount = packageAmount;
    list[i - findIndex - 1].pos_total_amount =
      list[i - findIndex - 1].pos_qty * packageAmount;
    this.setState({
      items: list,
    });
  }

  packageServiceIncrease() {
    const { items, packageMapping, packageAmtIncrease } = this.state;

    const list = [...items];
    const length = packageAmtIncrease?.package?.sm_code?.length;
    const findIndex = packageAmtIncrease?.package?.sm_code.findIndex(
      (value) =>
        value?.SERVICE_NAME ===
          packageAmtIncrease?.package?.package_service_name &&
        (value?.newamount || value?.SERVICE_AMT || value?.GROUP_AMT) ===
          packageAmtIncrease?.amount
    );

    list[packageAmtIncrease?.index - findIndex].package_service_amount +=
      packageAmtIncrease?.increasedAmount - packageAmtIncrease?.amount;

    // this.togglePopup();
    this.setState({
      items: list,
    });
  }

  addPackageService(row, i) {
    const { items, subCategoryList } = this.state;
    const { patient } = this.props;

    const resultData = {
      sno: items.length - 1,
      service_code: "value",
      package_code: "",
      package_service_amount: 0,
      wsc_code: subCategoryList?.wsc_code,
      pos_amount: null,
      amount: 0,
      package_service_amount: 0,
      pos_total_amount: null,
      pos_qty: null,
      pos_date: new Date(),
      service_edit: true,
      pos_qty_edit: true,
      pos_amount_edit: true,
      tax_edit: true,
      dept_code: "",
      user: subCategoryList?.created_by,
      tax_amount: null,
      tax_percentage: null,
      sm_code: row.sm_code,
      package_id: row.package_id,
      consultant: "",
      consultant_share: 0,
      consultant_edit: true,
      discount_applicable: false,
      deleteIcon: false,
    };

    resultData.sm_code.unshift(resultData);
    items.splice(i + 1, 0, resultData);

    this.setState({
      items: items,
    });
  }

  togglePackagePopup(data, i) {
    if (data || i) {
      this.setState({
        selectdata: data,
        indexValue: i,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  packageServiceData(option, i) {
    const {
      items,
      serviceList,
      packageList,
      groupList,
      subCategoryList,
    } = this.state;
    const { patient } = this.props;

    let serviceFilter;
    let selectedName;
    let serviceAmount;

    if (option.type === "Service") {
      serviceFilter = serviceList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.SERVICE_NAME === option?.label;
      });
      selectedName = serviceFilter.SERVICE_NAME;
    } else if (option.type === "Package") {
      serviceFilter = packageList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.PACKAGE_NAME === option?.label;
      });
      selectedName = serviceFilter.PACKAGE_NAME;
    } else if (option.type === "Group") {
      serviceFilter = groupList.find((list) => {
        return option?.value
          ? list.CODE === option.value
          : list.GROUP_NAME === option?.label;
      });
      selectedName = serviceFilter.GROUP_NAME;
    }

    serviceAmount =
      serviceFilter[
        `${subCategoryList?.ward_sub_catagory}_${subCategoryList?.code}_amount`
      ] ||
      serviceFilter?.newAmount ||
      serviceFilter?.SERVICE_AMT ||
      serviceFilter?.GROUP_AMT ||
      0;

    const item = items[i];
    item.service_code = option.value;
    item.package_code = option.value;
    item.package_service_name = selectedName;
    item.service_edit = false;
    item.package_service_amount = 0;
    item.pos_amount = null;
    item.amount = 0;
    item.pos_date = new Date();
    item.pos_qty = null;
    item.pos_qty_edit = serviceFilter?.IP_QTY_EDIT;
    item.pos_amount_edit = false;
    item.tax_percentage = null;
    item.tax_amount = null;
    item.pos_total_amount = null;
    // item.tax_percentage = serviceFilter?.TAX_AMOUNT_IP;
    // item.tax_amount = (serviceAmount * serviceFilter?.TAX_AMOUNT_IP) / 100;
    // item.pos_total_amount =
    //   1 * (serviceAmount + serviceAmount * serviceFilter?.TAX_AMOUNT_IP);
    item.tax_edit = serviceFilter?.TAX_APPLICABLE_IN_IP;
    item.dept_code = serviceFilter?.D_CODE;
    item.user = subCategoryList?.created_by;
    item.wsc_code = subCategoryList?.wsc_code;
    item.service_id = serviceFilter?._id;
    item.type = option?.type;

    this.setState({
      items: items,
    });
  }

  deletePos(data, i) {
    const { dispatch } = this.props;
    const { items } = this.state;
    const list = [...items];
    data.is_deleted = true;
    list.splice(i, 1);
    list.push(data);

    this.setState({
      items: list,
    });
  }

  departmentToValue(code) {
    try {
      const { departments } = this.props;
      if (departments)
        return departments.find((item) => item.code === code).name;
      else return code;
    } catch {
      return code;
    }
  }

  consultant_share_amount(e, item, i) {
    const { items } = this.state;

    const list = [...items];

    let valueAmount = e.target.value ? parseInt(e.target.value) : 0;

    if (valueAmount > item.pos_amount) {
      item[e.target.name] = item.pos_amount;
    } else {
      item[e.target.name] = valueAmount;
    }

    list[i] = item;

    this.setState({
      items: list,
    });
  }

  dateHandler(date, item, i) {
    const { items } = this.state;

    const list = [...items];

    item.pos_date = date;

    list[i] = item;

    this.setState({
      items: list,
    });
  }

  render() {
    const { subCategoryList } = this.state;
    let min_date = subCategoryList && new Date(subCategoryList?.admission_date);

    let serviceList;
    if (this.state.items) {
      serviceList = this.state.items
        .filter((list) => !list.is_deleted)
        .map((data, i) => {
          if (this.state.searchValue) {
            if (
              this.departmentToValue(data.dept_code)
                ?.toLowerCase()
                .includes(this.state.searchValue.toLowerCase()) ||
              data?.selected_name
                ?.toLowerCase()
                .includes(this.state.searchValue.toLowerCase())
            ) {
              let tds = [
                <td>
                  {this.state.items?.filter((value) => !value.is_deleted)
                    ?.length - i}
                </td>,
              ];
              tds.push(
                <td>
                  <DatePicker
                    selected={new Date(data?.pos_date)}
                    name="pos_date"
                    placeholderText="Select date"
                    minDate={min_date}
                    onChange={(date) => this.dateHandler(date, data, i)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control removeUnwantedClass"
                    autoComplete="off"
                  />
                </td>
              );
              tds.push(<td>{this.departmentToValue(data.dept_code)}</td>);
              tds.push(
                <td>
                  {
                    <div style={{ width: "200px" }} className="serviceWrapper">
                      {!data.service_code ? (
                        <Select
                          name="service_code"
                          placeholder="Select Service"
                          onChange={(opt) => this.serviceHandler(opt, i)}
                          options={this.state.selectedList}
                          value={data.service_code}
                          menuPortalTarget={document.body}
                          menuPosition="absolute"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          maxMenuHeight={150}
                        ></Select>
                      ) : (
                        data?.selected_name
                      )}
                    </div>
                  }
                </td>
              );
              tds.push(
                <td>
                  {
                    <div style={{ width: "200px" }} className="serviceWrapper">
                      {!data.package_code ? (
                        <Select
                          name="package_code"
                          placeholder="Select Service"
                          onChange={(opt) => this.packageServiceData(opt, i)}
                          options={this.state.serviceDataList}
                          value={this.state.serviceDataList?.find(
                            (obj) => obj.value === data.service_code
                          )}
                          menuPortalTarget={document.body}
                          menuPosition="absolute"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          maxMenuHeight={150}
                        ></Select>
                      ) : (
                        data?.package_service_name
                      )}
                    </div>
                  }
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data?.package_service_amount >= 0 && (
                      <Input
                        type="text"
                        name="package_service_amount"
                        value={
                          data?.pos_amount_show
                            ? data.package_service_amount
                            : "xxxx"
                        }
                        // onChange={(e) => this.packageAmtIncrease(e, data, i)}
                        disabled={true}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  {data?.addButton ? (
                    <div style={{ width: "100px" }}>
                      <Button onClick={() => this.togglePackagePopup(data, i)}>
                        Add
                      </Button>
                    </div>
                  ) : null}
                </td>
              );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //       <Input
              //         type="text"
              //         name="tpa_code"
              //         value={data.tpa_code}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //       ></Input>
              //     </div>
              //   </td>
              // );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data?.pos_amount != null && (
                      <Input
                        type="text"
                        name="pos_amount"
                        value={data?.pos_amount_show ? data.pos_amount : "xxxx"}
                        // onChange={(e) =>
                        //   data.type === "Packages"
                        //     ? this.packageHandler(e, data, i)
                        //     : this.changeHandler(e, data, i)
                        // }
                        disabled={true}
                      ></Input>
                    )}
                  </div>
                </td>
              );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //     {data?.tax_percentage != null &&

              //       <Input
              //         type="text"
              //         name="tax_percentage"
              //         value={data?.tax_percentage}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //         disabled={true}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //     {data?.tax_amount != null &&

              //       <Input
              //         type="text"
              //         name="tax_amount"
              //         value={data?.tax_amount}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //         disabled={true}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //     {data?.pos_qty != null &&

              //       <Input
              //         type="text"
              //         name="pos_qty"
              //         value={data?.pos_qty}
              //         onChange={(e) => this.changeHandler(e, data, i)}
              //         disabled={!data?.pos_qty_edit}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //     {data?.pos_total_amount != null &&

              //       <Input
              //         type="text"
              //         name="pos_total_amount"
              //         value={data?.pos_total_amount || (data?.pos_amount * data?.pos_qty)}
              //         disabled={true}
              //       ></Input>
              //     }
              //     </div>
              //   </td>
              // );
              tds.push(
                <td style={{ width: "150px" }}>
                  {this.wardSubCatagoryCodeToValue(data.wsc_code)}
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {data.consultant ? (
                      <Select
                        name="consultant"
                        placeholder="Select consultant"
                        onChange={(opt) =>
                          this.onconsultantChange(opt, data, i)
                        }
                        options={this.state.consultantList}
                        value={this.state.consultantList?.find(
                          (obj) => obj.value === data.consultant
                        )}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : data.deleteIcon ? (
                      <Select
                        name="consultant"
                        placeholder="Select consultant"
                        onChange={(opt) =>
                          this.onconsultantChange(opt, data, i)
                        }
                        options={this.state.consultantList}
                        value={""}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              );
              tds.push(
                <td>
                  <div style={{ width: "100px" }}>
                    {data.deleteIcon && (
                      <Input
                        type="text"
                        name="consultant_share"
                        value={data.consultant_share}
                        onChange={(e) =>
                          this.consultant_share_amount(e, data, i)
                        }
                      ></Input>
                    )}
                  </div>
                </td>
              );
              // tds.push(
              //   <td>
              //     <div style={{ width: "100px" }}>
              //       <Input
              //         type="checkbox"
              //         name="tpa_amount"
              //         value={data.consultant_share}
              //       ></Input>
              //     </div>
              //   </td>
              // );
              tds.push(<td>{data.user}</td>);
              tds.push(
                <td>
                  {data.deleteIcon && (
                    <a>
                      <img
                        src={unDeleteIcon}
                        onClick={() =>
                          this.state.isDeleteAuthorized
                            ? this.onDeleteLogin(data, i)
                            : this.toggleDelete(data, i)
                        }
                        className="delete-icon"
                      />
                    </a>
                  )}
                </td>
              );

              return <tr key={data._id}>{tds}</tr>;
            }
          } else {
            let tds = [
              <td>
                {this.state.items?.filter((value) => !value.is_deleted)
                  ?.length - i}
              </td>,
            ];
            tds.push(
              <td>
                <DatePicker
                  selected={new Date(data?.pos_date)}
                  name="pos_date"
                  placeholderText="Select date"
                  minDate={min_date}
                  onChange={(date) => this.dateHandler(date, data, i)}
                  dateFormat="dd/MM/yyyy"
                  className="form-control removeUnwantedClass"
                  autoComplete="off"
                />
              </td>
            );
            tds.push(<td>{this.departmentToValue(data.dept_code)}</td>);
            tds.push(
              <td>
                {
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {!data.service_code ? (
                      <Select
                        name="service_code"
                        placeholder="Select Service"
                        onChange={(opt) => this.serviceHandler(opt, i)}
                        options={this.state.selectedList}
                        value={data.service_code}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      data?.selected_name
                    )}
                  </div>
                }
              </td>
            );
            tds.push(
              <td>
                {
                  <div style={{ width: "200px" }} className="serviceWrapper">
                    {!data.package_code ? (
                      <Select
                        name="package_code"
                        placeholder="Select Service"
                        onChange={(opt) => this.packageServiceData(opt, i)}
                        options={this.state.serviceDataList}
                        value={this.state.serviceDataList?.find(
                          (obj) => obj.value === data.service_code
                        )}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        maxMenuHeight={150}
                      ></Select>
                    ) : (
                      data?.package_service_name
                    )}
                  </div>
                }
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data?.package_service_amount >= 0 && (
                    <Input
                      type="text"
                      name="package_service_amount"
                      value={
                        data?.pos_amount_show
                          ? data.package_service_amount
                          : "xxxx"
                      }
                      // onChange={(e) => this.packageAmtIncrease(e, data, i)}
                      disabled={true}
                    ></Input>
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                {data?.addButton ? (
                  <div style={{ width: "100px" }}>
                    <Button onClick={() => this.togglePackagePopup(data, i)}>
                      Add
                    </Button>
                  </div>
                ) : null}
              </td>
            );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //       <Input
            //         type="text"
            //         name="tpa_code"
            //         value={data.tpa_code}
            //         onChange={(e) => this.changeHandler(e, data, i)}
            //       ></Input>
            //     </div>
            //   </td>
            // );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data?.pos_amount != null && (
                    <Input
                      type="text"
                      name="pos_amount"
                      value={data?.pos_amount_show ? data.pos_amount : "xxxx"}
                      // onChange={(e) =>
                      //   data.type === "Packages"
                      //     ? this.packageHandler(e, data, i)
                      //     : this.changeHandler(e, data, i)
                      // }
                      disabled={true}
                    ></Input>
                  )}
                </div>
              </td>
            );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //     {data?.tax_percentage != null &&

            //       <Input
            //         type="text"
            //         name="tax_percentage"
            //         value={data?.tax_percentage}
            //         onChange={(e) => this.changeHandler(e, data, i)}
            //         disabled={true}
            //       ></Input>
            //     }
            //     </div>
            //   </td>
            // );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //     {data?.tax_amount != null &&

            //       <Input
            //         type="text"
            //         name="tax_amount"
            //         value={data?.tax_amount}
            //         onChange={(e) => this.changeHandler(e, data, i)}
            //         disabled={true}
            //       ></Input>
            //     }
            //     </div>
            //   </td>
            // );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //     {data?.pos_qty != null &&

            //       <Input
            //         type="text"
            //         name="pos_qty"
            //         value={data?.pos_qty}
            //         onChange={(e) => this.changeHandler(e, data, i)}
            //         disabled={!data?.pos_qty_edit}
            //       ></Input>
            //     }
            //     </div>
            //   </td>
            // );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //     {data?.pos_total_amount != null &&

            //       <Input
            //         type="text"
            //         name="pos_total_amount"
            //         value={data?.pos_total_amount || (data?.pos_amount * data?.pos_qty)}
            //         disabled={true}
            //       ></Input>
            //     }
            //     </div>
            //   </td>
            // );
            tds.push(
              <td style={{ width: "150px" }}>
                {this.wardSubCatagoryCodeToValue(data.wsc_code)}
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "200px" }} className="serviceWrapper">
                  {console.log(data.consultant, "consultant")}
                  {data.consultant ? (
                    <Select
                      name="consultant"
                      placeholder="Select consultant"
                      onChange={(opt) => this.onconsultantChange(opt, data, i)}
                      options={this.state.consultantList}
                      value={this.state.consultantList?.find(
                        (obj) => obj.value === data.consultant
                      )}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      maxMenuHeight={150}
                    ></Select>
                  ) : data.deleteIcon ? (
                    <Select
                      name="consultant"
                      placeholder="Select consultant"
                      onChange={(opt) => this.onconsultantChange(opt, data, i)}
                      options={this.state.consultantList}
                      value={""}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      maxMenuHeight={150}
                    ></Select>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            );
            tds.push(
              <td>
                <div style={{ width: "100px" }}>
                  {data.deleteIcon && (
                    <Input
                      type="text"
                      name="consultant_share"
                      value={data.consultant_share}
                      onChange={(e) => this.consultant_share_amount(e, data, i)}
                    ></Input>
                  )}
                </div>
              </td>
            );
            // tds.push(
            //   <td>
            //     <div style={{ width: "100px" }}>
            //       <Input
            //         type="checkbox"
            //         name="tpa_amount"
            //         value={data.consultant_share}
            //       ></Input>
            //     </div>
            //   </td>
            // );
            tds.push(<td>{data.user}</td>);
            tds.push(
              <td>
                {data.deleteIcon && (
                  <a>
                    <img
                      src={unDeleteIcon}
                      onClick={() =>
                        this.state.isDeleteAuthorized
                          ? this.onDeleteLogin(data, i)
                          : this.toggleDelete(data, i)
                      }
                      className="delete-icon"
                    />
                  </a>
                )}
              </td>
            );

            return <tr key={data._id}>{tds}</tr>;
          }
        });
    }

    return (
      <Fragment>
        <Card>
          <CardHeader>Radiology POS</CardHeader>
          <PatientGeneralInfo
            patientData={this.state.subCategoryList}
          ></PatientGeneralInfo>
        </Card>
        <div className="d-flex my-3 align-items-end">
          <Col md={4}>
            <Label style={{ fontWeight: "bold" }}>Filter by</Label>
            <FormGroup
              row
              className="hmgroup Registrationgroupradio"
              style={{ width: "100%" }}
            >
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="date"
                  value="date"
                  name="filterData"
                  label="Date"
                  onChange={this.onChange}
                  checked={this.state.filterData === "date"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="service"
                  value="service"
                  name="filterData"
                  label="Service"
                  onChange={this.onChange}
                  checked={this.state.filterData === "service"}
                  className="labelFontSmall"
                />
              </div>

              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="department"
                  value="department"
                  name="filterData"
                  label="Department"
                  onChange={this.onChange}
                  checked={this.state.filterData === "department"}
                  className="labelFontSmall"
                />
              </div>
              <div className="wardService_types">
                <CustomInput
                  type="radio"
                  id="all"
                  value="all"
                  name="filterData"
                  label="All"
                  onChange={this.onChange}
                  checked={this.state.filterData === "all"}
                  className="labelFontSmall"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={2}>
            <div className="mr-3">
              {this.state.filterData !== "all" && (
                <>
                  <Label style={{ fontWeight: "bold" }}>Filter by</Label>
                  <FormGroup>
                    <div className="wardService_types">
                      <CustomInput
                        type="text"
                        value={this.state.searchValue}
                        name="searchValue"
                        onChange={this.onChange}
                      />
                    </div>
                  </FormGroup>
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="d-flex align-items-end justify-content-end">
              <div>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={this.togglePopup}
                >
                  Show Amount
                </Button>
                <Button
                  color="success"
                  className="mr-2"
                  onClick={this.onSubmitForm}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Col>
        </div>
        <Row style={{ overflow: "hidden" }}>
          <Col>
            <Card>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>SR No.</th>
                      <th>Date</th>
                      <th>Dept.</th>
                      <th>Service</th>
                      <th>Pack-Services</th>
                      <th>Package Amount</th>
                      <th>Add Service</th>
                      {/* <th>TPA Code</th> */}
                      <th>Amount</th>
                      {/* <th>Tax Percentage</th>
                      <th>Tax Amount</th>
                      <th>Qty</th>
                      <th>Total Amount</th> */}
                      <th>Ward</th>
                      <th>Consultant</th>
                      <th>Share</th>
                      {/* <th>TPA</th> */}
                      <th>User</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{serviceList}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.togglePopup()}
          className={this.props.className}
          style={{ width: 400 }}
        >
          <ModalHeader toggle={() => this.togglePopup()}>
            Authentication Required!{" "}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={8} sm={8} className="offset-sm-1">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason needed for showing amount</Label>
                  <Input
                    type="text"
                    name="reason"
                    placeholder="Reason"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={2}>
                {" "}
                <Button color="link" onClick={() => this.togglePopup()}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                {" "}
                <Button color="primary" onClick={() => this.onLogin()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.togglePackagePopup()}
          className={this.props.className}
          style={{ width: 400 }}
        >
          <ModalHeader toggle={() => this.togglePackagePopup()}>
            Authentication Required!{" "}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={8} sm={8} className="offset-sm-1">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason for adding service</Label>
                  <Input
                    type="text"
                    name="package_add_reason"
                    placeholder="Reason"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={2}>
                {" "}
                <Button color="link" onClick={() => this.togglePackagePopup()}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                {" "}
                <Button color="primary" onClick={() => this.onLoginData()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={() => this.toggleDelete()}
          className={this.props.className}
          style={{ width: 400 }}
        >
          <ModalHeader toggle={() => this.toggleDelete()}>
            Authentication Required!{" "}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={8} sm={8} className="offset-sm-1">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason for Delete</Label>
                  <Input
                    type="text"
                    name="reason"
                    placeholder="Reason for Delete"
                    onChange={(e) => {
                      this.onChangeText(e);
                    }}
                  />
                </FormGroup>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={2}>
                {" "}
                <Button color="link" onClick={() => this.toggleDelete()}>
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                {" "}
                <Button color="primary" onClick={() => this.onDeleteLogin()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    newMaster,
    wardManagement,
    patients,
    consultants,
    serviceMaster,
    departments,
  } = state;
  return {
    newMaster: newMaster.userType,
    wardtypes: wardManagement.wardtypes,
    wardroom: wardManagement.wardroom,
    wardbycatagorys: wardManagement.wardbycatagorys,
    wardcatagorys: wardManagement.wardcatagorys,
    wardsubcatagory: wardManagement.wardsubcatagory,
    patient: patients.selected_patient,
    wardbed: wardManagement.wardbed,
    wardFacility: wardManagement.wardfacility,
    consultants: consultants.consultants,
    serviceMaster: serviceMaster,
    graceperiod: wardManagement.graceperiod,
    departments: departments.departments,
    wardcatagoryfees: wardManagement.wardcatagoryfees,
  };
}

const connectedRadiologyPOS = connect(mapStateToProps)(RadiologyPOS);

export default connectedRadiologyPOS;
