import { patientConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { patientService } from "../services";
import { toast, Bounce } from "react-toastify";
import { history } from "../helpers";
import { result } from "lodash";

export const patientActions = {
  getAll,
  getByDate,
  add,
  update,
  getByUHID,
  selectPatient,
  search,
  searchPatient,
  remove,
  clearPatient,
  oldBody,
};

function newBody(formdata) {
  const formData = {
    oldPatientRecordNo: formdata.old_patient_record_no,
    registeringAs: formdata.registering_as,
    relativeRelationship: formdata.relative_relation,
    partnerUhid: 1, // issue
    patientType: formdata.patient_type, //issue
    photo: formdata.photo,
    firstName: formdata.firstname,
    middleName: formdata.middlename,
    lastName: formdata.lastname,
    dobAndTime: formdata.dob,
    consultantId: formdata.consultant_id == "" ? 0 : formdata.consultant_id,
    paymentType: formdata.payment_type,
    companyName: formdata.company_name,
    insuranceNumber: formdata.insurance_number,
    corporate: formdata.corporate,
    employeeId: formdata.employee_id,
    idType1: formdata.id_type1,
    idNumber1: formdata.id_number1,
    documentImage1: formdata.id_document1,
    idType2: formdata.id_type2,
    idNumber2: formdata.id_number2,
    documentImage2: formdata.id_document2,
    maritalStatus: formdata.marital_status,
    religion: formdata.religion_code,
    nationality: formdata.nationality_code,
    bloodGroup: formdata.blood_group_code,
    confidentiality: formdata.confidentiality,
    confidentialityType: formdata.confidentiality_type,
    language: formdata.language,
    educationalDetail: formdata.educational_detail,
    occupation: formdata.occupation,
    relativeName: formdata.relative_name,
    secondaryContact: formdata.secondary_contact,
    visitReason: formdata.visit_reason,
    visitedBefore: formdata.visited_before,
    clinicDetail: formdata.clinic_detail,
    reasonOfKnowing: formdata.reason_of_knowing,
    referralDoctor: formdata.referral_doctor,
    referralPhone: formdata.referral_phone,
    opdConsentForm: formdata.opd_consent_form,
    referralForm: formdata.referral_form,
    remark: formdata.remark,
    signature: formdata.signature,
    qrCode: "",
    title: formdata.title_code,
    phoneNumber: formdata.mobile,
    email: formdata.email,
    gender: formdata.gender_code,
    patientAddress: {
      permanentAddress: formdata.permanent_address,
      gramPanchayat: formdata.gram_panchayat,
      country: `${formdata.country}`,
      city: `${formdata.city}`,
      location: `${formdata.location}`,
      village: formdata.village,
      pincode: formdata.pincode,
      tehsil: formdata.tehsil,
      district: `${formdata.district}`,
      state: `${formdata.state}`,
      communicationAddress: formdata.communication_address,
    },
    abhaId: "",
    registrationType: 1,
    startTime: "2024-03-12T08:07:47.045Z",
    endTime: "2024-03-12T08:07:47.045Z",
    uhid: formdata.patient_uhid,
    ipAddress: "",
    machineDetails: "",
    id: formdata.id ?? "",
  };

  return formData;
}

export function oldBody(formdata) {
  const data = {
    patient_uhid: formdata.uhid,
    old_patient_record_no:
      formdata.oldPatientRecordNo !== undefined
        ? formdata.oldPatientRecordNo
        : "",
    registering_as:
      formdata.registeringAs !== undefined ? formdata.registeringAs : "",
    relative_relation:
      formdata.relationship !== undefined ? formdata.relationship : "",
    partnerUhid: formdata.partnerUhid !== undefined ? formdata.partnerUhid : 0,
    patient_type:
      formdata.patientType !== undefined ? formdata.patientType : "",
    photo: formdata.patientPhoto !== undefined ? formdata.patientPhoto : "",
    firstname: formdata.firstName !== undefined ? formdata.firstName : "",
    middlename: formdata.middleName !== undefined ? formdata.middleName : "",
    lastname:
      formdata.lastname !== undefined ? formdata.lastname : formdata.lastName,
    dob: formdata.dobAndTime !== undefined ? formdata.dobAndTime : "",
    consultant_id:
      formdata.consultantId !== undefined ? formdata.consultantId : 0,
    payment_type:
      formdata.paymentType !== undefined ? formdata.paymentType : "",
    company_name:
      formdata.companyName !== undefined ? formdata.companyName : "",
    insurance_number:
      formdata.insuranceNumber !== undefined ? formdata.insuranceNumber : "",
    corporate: formdata.corporate !== undefined ? formdata.corporate : "",
    employee_id: formdata.employeeId !== undefined ? formdata.employeeId : 0,
    id_type1: formdata.idType1 !== undefined ? formdata.idType1 : "",
    id_number1: formdata.idNumber1 !== undefined ? formdata.idNumber1 : "",
    id_type2: formdata.idType2 !== undefined ? formdata.idType2 : "",
    id_number2: formdata.idNumber2 !== undefined ? formdata.idNumber2 : "",
    marital_status:
      formdata.maritalStatus !== undefined ? formdata.maritalStatus : "",
    religion_code: formdata.religion !== undefined ? formdata.religion : "",
    nationality_code:
      formdata.nationality !== undefined ? formdata.nationality : "",
    blood_group_code:
      formdata.bloodGroup !== undefined ? formdata.bloodGroup : "",
    confidentiality:
      formdata.confidentiality !== undefined ? formdata.confidentiality : "",
    confidentiality_type:
      formdata.confidentialityType !== undefined
        ? formdata.confidentialityType
        : "",
    language: formdata.language !== undefined ? formdata.language : "",
    educational_detail:
      formdata.educationalDetail !== undefined
        ? formdata.educationalDetail
        : "",
    occupation: formdata.occupation !== undefined ? formdata.occupation : "",
    relative_name:
      formdata.relativeName !== undefined ? formdata.relativeName : "",
    secondary_contact:
      formdata.secondaryContact !== undefined ? formdata.secondaryContact : "",
    visit_reason:
      formdata.visitReason !== undefined ? formdata.visitReason : "",
    visited_before:
      formdata.visitedBefore !== undefined ? formdata.visitedBefore : "",
    clinic_detail:
      formdata.clinicDetail !== undefined ? formdata.clinicDetail : "",
    reason_of_knowing:
      formdata.reasonOfKnowing !== undefined ? formdata.reasonOfKnowing : "",
    referral_doctor:
      formdata.referralDoctor !== undefined ? formdata.referralDoctor : "",
    referral_phone:
      formdata.referralPhone !== undefined ? formdata.referralPhone : "",
    remark: formdata.remark !== undefined ? formdata.remark : "",
    signature: formdata.signature !== undefined ? formdata.signature : "",
    qrcode: formdata.qrCode !== undefined ? formdata.qrCode : "",
    title_code: formdata.title !== undefined ? formdata.title : "",
    mobile: formdata.patientNumber !== undefined ? formdata.patientNumber : "",
    email: formdata.email !== undefined ? formdata.email : "",
    gender_code: formdata.gender !== undefined ? formdata.gender : "",
    permanent_address:
      formdata.patientAddress?.permanentAddress !== undefined
        ? formdata.patientAddress.permanentAddress
        : "",
    gram_panchayat:
      formdata.patientAddress?.gramPanchayat !== undefined
        ? formdata.patientAddress.gramPanchayat
        : "",
    country:
      formdata.patientAddress?.country !== undefined
        ? formdata.patientAddress.country
        : "",
    city:
      formdata.patientAddress?.city !== undefined
        ? formdata.patientAddress.city
        : "",
    location:
      formdata.patientAddress?.location !== undefined
        ? formdata.patientAddress.location
        : "",
    village:
      formdata.patientAddress?.village !== undefined
        ? formdata.patientAddress.village
        : "",
    pincode:
      formdata.patientAddress?.pincode !== undefined
        ? formdata.patientAddress.pincode
        : "",
    tehsil:
      formdata.patientAddress?.tehsil !== undefined
        ? formdata.patientAddress.tehsil
        : "",
    district:
      formdata.patientAddress?.district !== undefined
        ? formdata.patientAddress.district
        : "",
    state:
      formdata.patientAddress?.state !== undefined
        ? formdata.patientAddress.state
        : "",
    communication_address:
      formdata.patientAddress?.communicationAddress !== undefined
        ? formdata.patientAddress.communicationAddress
        : "",
    string:
      formdata.patientAddress?.dataKey !== undefined
        ? formdata.patientAddress.dataKey
        : "",
    "": formdata.abhaId !== undefined ? formdata.abhaId : "",
    registrationType:
      formdata.registrationType !== undefined ? formdata.registrationType : 1,
    startTime: formdata.startTime !== undefined ? formdata.startTime : "",
    endTime: formdata.endTime !== undefined ? formdata.endTime : "",
    // '': formdata.ipAddress !== undefined ? formdata.ipAddress : "",
    // '': formdata.machineDetails !== undefined ? formdata.machineDetails : ""
    opd_consent_form:
      formdata.opdConsentForm !== undefined ? formdata.opdConsentForm : "",
    referral_form:
      formdata.referralForm !== undefined ? formdata.referralForm : "",
    id_document1:
      formdata.documentImage1 !== undefined ? formdata.documentImage1 : "",
    id_document2:
      formdata.documentImage2 !== undefined ? formdata.documentImage2 : "",
    age_in_years: formdata.ageInYear !== undefined ? formdata.ageInYear : "",
    age_in_months: formdata.ageInMonth !== undefined ? formdata.ageInMonth : "",
    age_in_days: formdata.ageInDays !== undefined ? formdata.ageInDays : "",
    registration_date:
      formdata.createdOn !== undefined ? formdata.createdOn : "",
    id: formdata.id,
    created_by: formdata.createdBy,
    updated_by: formdata.updatedBy,
  };

  return data;
}

function clearPatient() {
  return (dispatch) => {
    dispatch(success());
    dispatch(alertActions.success("Patient Cleared"));
  };
  function success() {
    return { type: patientConstants.PATIENT_CLEAR };
  }
}

function getAll(query = "") {
  return (dispatch) => {
    dispatch(request());
    patientService.getAll(query).then(
      (data) => {
        if (data.isSuccess) {
          const oldPayload = data.value.map((patient) => {
            return oldBody(patient);
          });

          dispatch(success(oldPayload, data.pagedInfo));
          dispatch(alertActions.success("Patients loaded"));
        } else dispatch(failure(data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.GET_ALL_PATIENT_REQUEST };
  }
  function success(patients, pagedInfo) {
    return {
      type: patientConstants.GET_ALL_PATIENT_SUCCESS,
      data: { patients, pagedInfo },
    };
  }
  function failure(error) {
    return { type: patientConstants.GET_ALL_PATIENT_FAILURE, error };
  }
}

function getByDate(payload) {
  return (dispatch) => {
    dispatch(request());
    patientService.getByDate(payload).then(
      (data) => {
        if (data.value) {
          const oldPayload = data.value.map((patient) => {
            return oldBody(patient);
          });
          dispatch(success(oldPayload, data.pagedInfo));
          dispatch(alertActions.success("Patients loaded"));
        } else dispatch(failure(data));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.GET_BY_DATE_PATIENTS_REQUEST };
  }
  function success(patients, pagedInfo) {
    return {
      type: patientConstants.GET_BY_DATE_PATIENTS_SUCCESS,
      data: { patients, pagedInfo },
    };
  }
  function failure(error) {
    return { type: patientConstants.GET_BY_DATE_PATIENTS_FAILURE, error };
  }
}

function getByUHID(uhid) {
  return (dispatch) => {
    dispatch(request());
    patientService.getByUHID({ uhid }).then(
      (data) => {
        if (data.isSuccess) {
          const oldPayload = data.value.map((patient) => {
            return oldBody(patient);
          });
          dispatch(success(oldPayload, data.pagedInfo));
          dispatch(alertActions.success("Patient Fetched"));
        } else dispatch(failure(data.err));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.GET_BY_UHID_REQUEST };
  }
  function success(patient, pagedInfo) {
    return {
      type: patientConstants.GET_BY_UHID_SUCCESS,
      data: { patient, pagedInfo },
    };
  }
  function failure(error) {
    return { type: patientConstants.GET_BY_UHID_FAILURE, error };
  }
}

function searchPatient(query) {
  return (dispatch) => {
    dispatch(request());
    patientService.findPatients(query).then(
      (data) => {
        if (data.isSuccess) {
          const oldPayload = data.value.map((patient) => {
            return oldBody(patient);
          });
          dispatch(success(oldPayload, data.pagedInfo));
          dispatch(alertActions.success("Patient Fetched"));
        } else {
          dispatch(failure(data.err));
          toast("No results found", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.SEARCH_PATIENT_REQUEST };
  }
  function success(patients, pagedInfo) {
    return {
      type: patientConstants.SEARCH_PATIENT_SUCCESS,
      data: { patients, pagedInfo },
    };
  }
  function failure(error) {
    return { type: patientConstants.SELECT_PATIENT_FAILURE, error };
  }
}

function selectPatient(id) {
  return (dispatch) => {
    dispatch(request());
    patientService.selectPatient(id).then(
      (data) => {
        if (data.isSuccess) {
          const newBody = oldBody(data.value[0]);

          dispatch(success(newBody));
          dispatch(alertActions.success("Patient Fetched"));
        } else {
          dispatch(failure(data.err));
          toast("No results found", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.SELECT_PATIENT_REQUEST };
  }
  function success(patient) {
    return { type: patientConstants.SELECT_PATIENT_SUCCESS, patient };
  }
  function failure(error) {
    return { type: patientConstants.SELECT_PATIENT_FAILURE, error };
  }
}

function add(payload) {
  return (dispatch) => {
    dispatch(request());

    return patientService.add(newBody(payload)).then(
      (result) => {
        if (result) {
          dispatch(success(result));
          toast("added successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });

          dispatch(alertActions.success(result));
          return true;
          //  window.location='/patient-registration/transactions/patient-list'
        } else {
          toast("Error in saving data", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
          dispatch(failure("Error in saving data"));
          return false;
        }
      },
      (error) => {
        toast("Fill all the fields that are required", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
        return false;
      }
    );
  };

  function request() {
    return { type: patientConstants.ADD_PATIENT_REQUEST };
  }
  function success(patient) {
    return { type: patientConstants.ADD_PATIENT_SUCCESS, patient };
  }
  function failure(error) {
    return { type: patientConstants.ADD_PATIENT_FAILURE, error };
  }
}
function update(payload) {
  return (dispatch) => {
    dispatch(request());
    console.log("Update payload", payload);
    return patientService.update(newBody(payload)).then(
      (result) => {
        console.log("Result after update", result);
        if (result) {
          dispatch(success(result.value));
          dispatch(alertActions.success(result));
          toast("update success", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          return true;
          //  window.location='/patient-registration/transactions/patient-list'
        } else {
          toast("update fail", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
          dispatch(failure(result.err));
          return false;
        }
      },
      (error) => {
        toast(error, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        console.log(error);
        dispatch(failure(error.toString()));
        return false;
      }
    );
  };

  function request() {
    return { type: patientConstants.UPDATE_PATIENT_REQUEST };
  }
  function success(patient) {
    return { type: patientConstants.UPDATE_PATIENT_SUCCESS, patient };
  }
  function failure(error) {
    return { type: patientConstants.UPDATE_PATIENT_FAILURE, error };
  }
}

function remove(payload) {
  return (dispatch) => {
    dispatch(request());

    patientService.remove(payload).then(
      (result) => {
        if (result) {
          dispatch(success(payload));
          dispatch(alertActions.success(result.message));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
        } else {
          dispatch(failure(result.err));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.DELETE_PATIENT_REQUEST };
  }
  function success(patient) {
    return { type: patientConstants.DELETE_PATIENT_SUCCESS, patient };
  }
  function failure(error) {
    return { type: patientConstants.DELETE_PATIENT_FAILURE, error };
  }
}

function search(payload) {
  return (dispatch) => {
    dispatch(request());

    patientService.findPatients(payload).then(
      (result) => {
        if (result.isSuccess === true) {
          const oldPayload = result.value.map((patient) => {
            return oldBody(patient);
          });
          dispatch(success(oldPayload, result.pagedInfo));
        } else {
          dispatch(failure("Error in fetching data"));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: patientConstants.SEARCH_PATIENTS_REQUEST };
  }
  function success(patients, pagedInfo) {
    return {
      type: patientConstants.SEARCH_PATIENTS_SUCCESS,
      data: { patients, pagedInfo },
    };
  }
  function failure(error) {
    return { type: patientConstants.SELECT_PATIENT_FAILURE, error };
  }
}
