export * from './alert.constants';
export * from './menu.constants';
export * from './customer.constants'
export * from './form.constants';
export * from './registrationMaster.constants'
export * from './generalMaster.constants'
export * from "./config";
export * from "./sweetAlert.constants";
export * from './login.contants'
export * from './patient.constant'
export * from './newmasters.constants'
export * from './userMaster.constants'
export * from './consultant.constant'
export * from './servicemaster.constants'
export * from './referral.constants'
export * from './clinic.constants'
export * from "./initialassesment.master.constants";
export * from "./registrationwithfees.constants";
export * from './appointment.constant'
export * from './opbilling.constants'
export * from './referralconsultant.constants'
export * from './laboratoryMaster.constants'
export * from './corporate.contants'
export * from './tpa.constants'
export * from './patientqueue.constant'
export * from './notification.constants'
export * from './labPatient.constant'
export * from './wardmanagement.constants'
export * from './pharmacy.constants';
export * from './store.constants'
export * from './otbooking.constant';
export * from './otMaster.constant'
export * from './reports.constant'

export const alertConstants = {
    SUCCESS : 'SUCCESS',
    ERROR : 'ERROR',
    CLEAR : 'CLEAR',
}

//ipd new patient constants

export const ipdManagementConstants = {

    ADDNEW_PATIENT_REQUEST : 'ADDNEW_PATIENT_REQUEST',
    ADDNEW_PATIENT_SUCCESS : 'ADDNEW_PATIENT_SUCCESS',
    ADDNEW_PATIENT_FAIL : 'ADDNEW_PATIENT_FAIL',

    
  
}


export const getAllPatientConstants = {
    GETALL_PATIENT_REQUEST : 'GETALL_PATIENT_REQUEST',
    GETALL_PATIENT_SUCCESS : 'GETALL_PATIENT_SUCCESS',
    GETALL_PATIENT_FAIL : 'GETALL_PATIENT_FAIL',
}


export const getAdmTypeContstant = {
    
    GETALL_ADMTYPE_REQUEST : 'GETALL_ADMTYPE_REQUEST',
    GETALL_ADMTYPE_SUCCESS : 'GETALL_ADMTYPE_SUCCESS',
    GETALL_ADMTYPE_FAIL : 'GETALL_ADMTYPE_FAIL',
}



export const getNewConsultantConstants = {

    GETALL_NEWCONSULT_REQUEST  : 'GETALL_NEWCONSULT_REQUEST',
    GETALL_NEWCONSULT_SUCCESS :  'GETALL_NEWCONSULT_SUCCESS',
    GETALL_NEWCONSULT_FAIL  : 'GETALL_NEWCONSULT_FAIL', 
}



// //api urls :

//referals 

export const getReferalConstants = {

    GETALL_REFERAL_REQUEST : "GETALL_REFERAL_REQUEST",
    GETALL_REFERAL_SUCCESS : "GETALL_REFERAL_SUCCESS",
    GETALL_REFERAL_FAIL : "GETALL_REFERAL_FAIL" ,

}

//insurance constants

export const getInsuranceConstant = {
    GETALL_INSURANCE_REQUEST : "GETALL_INSURANCE_REQUEST",
    GETALL_INSURANCE_SUCCESS : "GETALL_INSURANCE_SUCCESS",
    GETALL_INSURANCE_FAIL : "GETALL_INSURANCE_FAIL",
}

//corporate constants


export const getCorporateConstants= {
    GETALL_CORPORATE_REQUEST : "GETALL_CORPORATE_REQUEST",
    GETALL_CORPORATE_SUCCESS : "GETALL_CORPORATE_SUCCESS",
    GETALL_CORPORATE_FAIL : "GETALL_CORPORATE_FAIL",
}

//ward Names

export const getWardNamesConstant = {
    GETALL_WARDNAMES_REQUEST : "GETALL_WARDNAMES_REQUEST",
    GETALL_WARDNAMES_SUCCESS : "GETALL_WARDNAMES_SUCCESS",
    GETALL_WARDNAMES_FAIL : "GETALL_WARDNAMES_FAIL",
}

export const getRoomTypeConstant = {
    GETALL_ROOMTYPE_REQUEST : "GETALL_ROOMTYPE_REQUEST",
    GETALL_ROOMTYPE_SUCCESS : "GETALL_ROOMTYPE_SUCCESS",
    GETALL_ROOMTYPE_FAIL : "GETALL_ROOMTYPE_FAIL",
}

//masters constants

export const getMasterConstant = {
    GETALL_MASTER_REQUEST : "GETALL_MASTER_REQUEST",
    GETALL_MASTER_SUCCESS : "GETALL_MASTER_SUCCESS",
    GETALL_MASTER_FAIL : "GETALL_MASTER_FAIL" 
}