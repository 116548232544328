import { newMasterConstants } from "../constants";

export function newMaster(state = {}, action) {
  switch (action.type) {
    case newMasterConstants.GET_ALL_EDUCATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_EDUCATION_SUCCESS:
      return { ...state, education: action.education, loading: false };
    case newMasterConstants.GET_ALL_EDUCATION_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.ADD_EDUCATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.ADD_EDUCATION_SUCCESS:
      let education;
      if (state.education) {
        education = [...state.education, action.education];
      } else {
        education = action.education;
      }
      return { ...state, education: education, loading: false };
    case newMasterConstants.ADD_EDUCATION_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.UPDATE_EDUCATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.UPDATE_EDUCATION_SUCCESS:
      const newEd = state.education.map((education) => {
        if (education.id === action.education.id) {
          return action.education;
        } else {
          return education;
        }
      });
      return { ...state, education: newEd, loading: false };
    case newMasterConstants.UPDATE_EDUCATION_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.DELETE_EDUCATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.DELETE_EDUCATION_SUCCESS:
      const edu_id = action.education;
      const eduindex = state.education.findIndex((ob) => ob.id === edu_id.id);
      const edu_status = state.education[eduindex].deleted;
      state.education[eduindex].deleted = !edu_status;
      //state.education[eduindex].active = edu_status;
      return {
        ...state,
        education: [...state.education],
        loading: false,
      };
    case newMasterConstants.DELETE_EDUCATION_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.GET_ALL_DESIGNATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_DESIGNATION_SUCCESS:
      return { ...state, designation: action.designation, loading: false };
    case newMasterConstants.GET_ALL_DESIGNATION_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.ADD_DESIGNATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.ADD_DESIGNATION_SUCCESS:
      return {
        ...state,
        designation: [...state.designation, action.designation],
        loading: false,
      };
    case newMasterConstants.ADD_DESIGNATION_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.UPDATE_DESIGNATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.UPDATE_DESIGNATION_SUCCESS:
      const newDes = state.designation.map((designation) => {
        if (designation.id === action.designation.id) {
          return action.designation;
        } else {
          return designation;
        }
      });
      return { ...state, designation: newDes, loading: false };
    case newMasterConstants.UPDATE_DESIGNATION_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.DELETE_DESIGNATION_REQUEST:
      return { ...state, loading: true };

    case newMasterConstants.DELETE_DESIGNATION_SUCCESS:
      const desg_id = action.designation;
      let cindex = state.designation.findIndex((ob) => ob.id === desg_id.id);
      const status = state.designation[cindex].deleted;
      state.designation[cindex].deleted = !status;
      state.designation[cindex].active = status;
      return {
        ...state,
        designation: [...state.designation],
        loading: false,
      };
    case newMasterConstants.DELETE_DESIGNATION_FAILURE:
      return { ...state, error: action.error, loading: false };

    case newMasterConstants.GET_ALL_ROLE_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_ROLE_SUCCESS:
      return { ...state, role: action.role, loading: false };
    case newMasterConstants.GET_ALL_ROLE_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.GET_ROLE_DATA_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ROLE_DATA_SUCCESS:
      return { ...state, role: action.role, loading: false };
    case newMasterConstants.GET_ROLE_DATA_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.DELETE_ROLE_DATA_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.DELETE_ROLE_DATA_SUCCESS:
      return { ...state, role: action.role, loading: false };
    case newMasterConstants.DELETE_ROLE_DATA_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.STATUS_UPDATE_ROLE_DATA_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.STATUS_UPDATE_DATA_SUCCESS:
      return { ...state, role: action.role, loading: false };
    case newMasterConstants.STATUS_UPDATE_DATA_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.GET_ALL_ACCESSGROUP_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_ACCESSGROUP_SUCCESS:
      return { ...state, accessgroup: action.accessgroup, loading: false };
    case newMasterConstants.GET_ALL_ACCESSGROUP_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.ADD_ACCESSGROUP_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.ADD_ACCESSGROUP_SUCCESS:
      return {
        ...state,
        accessgroup: [...state.accessgroup, action.accessgroup],
        loading: false,
      };
    case newMasterConstants.ADD_ACCESSGROUP_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.UPDATE_ACCESSGROUP_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.UPDATE_ACCESSGROUP_SUCCESS:
      return { ...state, accessgroup: action.accessgroup, loading: false };
    case newMasterConstants.UPDATE_ACCESSGROUP_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.UPDATE_STATUS_ACCESSGROUP_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.UPDATE_STATUS_ACCESSGROUP_SUCCESS:
      return { ...state, accessgroup: action.accessgroup, loading: false };
    case newMasterConstants.UPDATE_STATUS_ACCESSGROUP_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.DELETE_ACCESSGROUP_REQUEST:
      return { ...state, loading: true };

    case newMasterConstants.DELETE_ACCESSGROUP_SUCCESS:
      const accgrp_id = action.accessgroup;
      const acindex = state.accessgroup.findIndex(
        (ob) => ob._id === accgrp_id._id
      );
      const ac_status = state.accessgroup[acindex].deleted;
      state.accessgroup[acindex].deleted = !ac_status;
      state.accessgroup[acindex].active = ac_status;
      return {
        ...state,
        accessgroup: [...state.accessgroup],
        loading: false,
      };
    case newMasterConstants.DELETE_ACCESSGROUP_FAILURE:
      return { ...state, error: action.error, loading: false };

    case newMasterConstants.GET_ALL_ACCESSLIST_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_ACCESSLIST_SUCCESS:
      return { ...state, accesslist: action.accesslist, loading: false };
    case newMasterConstants.GET_ALL_ACCESSLIST_FAILURE:
      return { ...state, loading: false };
    case newMasterConstants.GET_ALL_USERTYPE_REQEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ALL_USERTYPE_SUCCESS:
      return { ...state, userType: action.userType, loading: false };
    case newMasterConstants.GET_ALL_USERTYPE_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.UPDATE_USER_TYPE_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.UPDATE_USER_TYPE_SUCCESS:
      let userType = [...state.userType];
      let newuserType = userType.indexOf(action.payload);
      userType.splice(newuserType, 1);
      userType = [...userType, action.userType];
      return { ...state, userType: userType, loading: false };
    case newMasterConstants.UPDATE_USER_TYPE_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.GET_ORGANIZATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action.organizationData,
        loading: false,
      };
    case newMasterConstants.GET_ORGANIZATION_FAILURE:
      return { ...state, loading: false };

    case newMasterConstants.CREATE_ORGANIZATION_REQUEST:
      return { ...state, loading: true };
    case newMasterConstants.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case newMasterConstants.CREATE_ORGANIZATION_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
