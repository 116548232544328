//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const drugMasterService = {
  getAll,
  add,
  update,
  remove,
  get,
  getTypes,
  search,
  getByDate,
  getDetails,
  getDrugStock,
  getStock,
  getStockSearch,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/Medicine`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs?from=${from}&to=${to}`, requestOptions).then(
    helperService.handleResponse
  );
}

function get(code) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs/${code}`, requestOptions).then(
    helperService.handleResponse
  );
}

function search(name) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs/search?name=${name}`, requestOptions).then(
    helperService.handleResponse
  );
}

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/drugs`, requestOptions).then(
    helperService.handleResponse
  );
}
function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/drugs/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/drugs/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function getTypes() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugTypes`, requestOptions).then(
    helperService.handleResponse
  );
}

function getDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs/getDrugDetails/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}
function getDrugStock() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs/getDrug/inStock`, requestOptions).then(
    helperService.handleResponse
  );
}
function getStock() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/drugs/getStock`, requestOptions).then(
    helperService.handleResponse
  );
}
function getStockSearch(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/drugs/getStock?queryParam=${query}`,
    requestOptions
  ).then(helperService.handleResponse);
}
