import {
  faCalendarAlt,
  faSearch,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import "./form.css";

export default class DtPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelBoxShown: true,
      isAuthorized: false,
      modal: false,
      startDate: this.props?.startDate
        ? new Date(this.props?.startDate)
        : new Date(),
      endDate: this.props?.endDate ? new Date(this.props?.endDate) : new Date(),
      minDate: this.props?.minDate ? new Date(this.props?.minDate) : "",
    };
  }
  static defaultProps = {
    dateSetting: false,
  };

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    this.setState({ startDate, endDate });
    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: { start: startDate, end: endDate },
        },
      });
  };

  handleChangeStart = (startDate) => {
    const _startDate = new Date(startDate);
    const _day = _startDate.getDate();
    const _month = _startDate.getMonth();
    const _year = _startDate.getFullYear();
    var _endDate = new Date(this.state.endDate);

    const today = new Date();
    const totalDays = new Date(
      _startDate.getFullYear(),
      _startDate.getMonth(),
      0
    ).getDate();

    if (this.props.dateSetting === true) {
      _endDate.setDate(totalDays - _day);
      if (today.getMonth() > _month && today.getFullYear() > _year) {
        _endDate.setMonth(_month + 1);
      } else {
        _endDate.setMonth(_month);
        _endDate.setDate(new Date().getDate());
      }
    }

    this.setState({ startDate: _startDate, endDate: _endDate });
    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: { start: _startDate, end: _endDate },
        },
      });
  };

  handleChangeEnd = (endDate) => this.handleChange({ endDate });

  onClear() {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
    });
    if (this.props.onClear) this.props.onClear();
  }
  render() {
    const { key, label, placeholder, name, value } = this.props;

    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (
      this.props.startDate &&
      this.props.endDate &&
      this.props.tableName === "initialAssesment"
    ) {
      startDate = new Date(this.props.startDate);
      endDate = new Date(this.props.endDate);
    }

    return (
      <div className="form-group">
        {label && <label for={key}>{label}</label>}
        <Row>
          <Col md={9}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label
                    for="exampleEmail"
                    className="mr-sm-2 font-weight-bold text-uppercase"
                  >
                    Start Date
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                    <DatePicker
                      selected={startDate}
                      minDate={this.state.minDate}
                      selectsStart
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      fixedHeight
                      showYearDropdown
                      showMonthDropdown
                      startDate={startDate}
                      endDate={endDate}
                      // maxDate={endDate}
                      onChange={this.handleChangeStart}
                      maxDate={new Date()}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label
                    for="examplePassword"
                    className="mr-sm-2 font-weight-bold text-uppercase"
                  >
                    End Date
                  </Label>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>{" "}
                    <DatePicker
                      selected={endDate}
                      minDate={startDate}
                      selectsEnd
                      fixedHeight
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.handleChangeEnd}
                      maxDate={new Date()}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <div style={{ marginTop: 23 }}>
              <a
                style={{ padding: 3, cursor: "pointer" }}
                onClick={this.props.onSubmit}
                title="Search"
                className="text-primary  "
              >
                {" "}
                <FontAwesomeIcon
                  style={{
                    fontSize: "30px",
                    marginTop: " 3px",
                    paddingTop: "8px",
                  }}
                  icon={faSearch}
                  size="1x"
                />
              </a>
              {this.props.cancelBoxShown && (
                <a
                  style={{ padding: 3, cursor: "pointer" }}
                  onClick={() => this.onClear()}
                  title="Clear"
                  className="text-warning   "
                >
                  {" "}
                  <FontAwesomeIcon
                    style={{
                      fontSize: "30px",
                      marginTop: " 3px",
                      paddingTop: "8px",
                      paddingRight: "5px",
                    }}
                    icon={faWindowClose}
                    size="1x"
                  />
                </a>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
