import { registrationWithFeesConstants } from "../constants";

export function registrationConsWithFeesMaster(state = {}, action) {
  switch (action.type) {
    case registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_SUCCESS:
      let registrationconfees = [];
      if (state.registrationconfees) {
        registrationconfees = [...state.registrationconfees];
        registrationconfees[action.id] = action.data;
      }
      registrationconfees.push(action.data);
      return {
        ...state,
        loading: false,
        registrationconfees: registrationconfees,
      };
    case registrationWithFeesConstants.ADD_REGISTRATION_CONS_FEES_FAILURE:
      return { ...state, loading: false };

    case registrationWithFeesConstants.GET_REGISTRATION_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.GET_REGISTRATION_CONS_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        registrationconfees: action.data.data,
      };
    case registrationWithFeesConstants.GET__REGISTRATION_CONS_FEES_FAILURE:
      return { ...state, loading: false };

    case registrationWithFeesConstants.DELETE_REGISTRATION_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.DELETE_REGISTRATION_CONS_FEES_SUCCESS:
      let deleteregistrationfees = [];
      if (state.registrationconfees) {
        deleteregistrationfees = [...state.registrationconfees];
        deleteregistrationfees[action.index] = action.data;
      }
      return {
        ...state,
        loading: false,
        registrationconfees: deleteregistrationfees,
      };
    case registrationWithFeesConstants.DELETE__REGISTRATION_CONS_FEES_FAILURE:
      return { ...state, loading: false };

    case registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_REQUEST:
      return { ...state, loading: true };
    case registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_SUCCESS:
      let updatedregistrationfees = [];
      if (state.registrationconfees) {
        updatedregistrationfees = [...state.registrationconfees];
        updatedregistrationfees[action.index] = action.data;
      }
      return {
        ...state,
        loading: false,
        registrationconfees: updatedregistrationfees,
      };
    case registrationWithFeesConstants.UPDATE_REGISTRATION_CONS_FEES_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
