import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { TabContent, TabPane } from "reactstrap";
import GenerateOtpComponent from "./Component/generateOtp";
import LoginPasswordComponent from "./Component/loginPasswordComponent";
import LoginWithOtpComponent from "./Component/loginwithotp";
import ResetOtpComponent from "./Component/resetpassword";
import VerifyOtpComponent from "./Component/verifyOtp";
import { Icons } from "../../../helpers/IconHelper";
import { connect } from "react-redux";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { loginActions } from "../../../actions/login.actions";
import { loginConstant } from "../../../constants";
import { Link } from "react-router-dom";
import SpinnerButton from "../../Components/Loaders/spinnerButton";
import CircleLoading from "../../Components/Loaders/Loadingspinner";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_email: "",
      password: "",
      customer_trans_id: "5e4591c5ac7f1f2cfc557c56",
      visible: false,
      animation: "zoom",
      visibleOTPScreen: false,
      visibleResetScreen: false,
      password: "",
      confirmPassword: "",
      OTP: "",
      oldType: "",
      loginOtpFlow: false,
      activeTab: "1",
      login_type: "user",
      capcha_value: null,
      loading: true,
      app_name: "",
      app_logo: "",
      loading: false,
      isLoading: false,
    };

    this.handleOtpLogin = this.handleOtpLogin.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChange = this.onChange.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onResetClickHandler = this.onResetClickHandler.bind(this);
    this.onOTPClickHandler = this.onOTPClickHandler.bind(this);
    this.onConfirmClickHandler = this.onConfirmClickHandler.bind(this);
  }

  componentDidUpdate() {
    let { masters } = this.props;
    if (masters) {
      if (!masters.loading) {
        if (masters.data) {
          if (
            masters.data.success === true &&
            masters.data.type === loginConstant.GENERATE_OTP_TYPE
          ) {
            if (this.state.visibleOTPScreen !== true) {
              if (this.state.oldType !== masters.data.type)
                this.handleOtpController(masters);
            }
          } else if (
            masters.data.success === true &&
            masters.data.type === loginConstant.VERIFY_OTP_RESET_FLOW
          ) {
            if (this.state.visibleResetScreen !== true) {
              if (this.state.oldType !== masters.data.type)
                this.handleOtpVerifyController(masters);
            }
          }
        }
      }
    }
  }

  componentDidMount() {
    // customerService.getNameAndLogo().then((res) => {
    //   if (res?.success && res?.data) {
    //     this.setState({
    //       // app_name: res.data.name,
    //       app_name: "Aqua His",
    //       app_logo: apiUrl + res.data.logo,
    //     });
    //     document.getElementById("favicon").href =
    //       apiUrl + "/cutomerTransimages/favicon.ico";
    //     document.title = res.data.name;
    //   }
    // });
  }
  toggle(tab, flow) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (flow) {
      this.setState({
        loginOtpFlow: true,
      });
    } else {
      this.setState({
        loginOtpFlow: false,
      });
    }
  }

  show(animation) {
    this.setState({
      animation,
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
      visibleOTPScreen: false,
      visibleResetScreen: false,
    });
  }

  onChangeHandler(key, value) {
    this.setState({ [key]: value });
  }

  onChange(value) {
    this.setState({
      capcha_value: value,
    });
  }

  async handleLogin(e, closeSpinner) {
    let { dispatch } = this.props;
    // this.setState({ isLoading: true });
    setTimeout(() => {
      
    });
    const user = {
      userName: this.state.customer_email,
      password: this.state.password,
      // customer_trans_id: this.state.customer_trans_id,
      // login_type: this.state.login_type,
    };
    await dispatch(loginActions.login(user));
    closeSpinner();
    
    // this.setState({ isLoading: false });
    //callBack();
  }
  handleOtpLoginFlow(e) {
    this.setState({
      loginOtpFlow: !this.state.loginOtpFlow,
    });
  }

  handleOtpLogin(e) {
    e.preventDefault();

    // if (this.state.capcha_value) {
    let { dispatch } = this.props;
    const loginDetails = {
      login_type: this.state.login_type,
      customer_email: this.state.customer_email,
      type: loginConstant.VERIFY_OTP_SERVER_REQUEST_TYPE,
    };
    dispatch(loginActions.generateOtp(loginDetails));
    // } else {
    //   window.alert("Please verify the capcha");
    // }
  }

  handleOtpController(data) {
    if (this.state.visibleOTPScreen !== true) {
      this.setState({
        visible: false,
        visibleOTPScreen: true,
        oldType: data.data.type,
      });
      //this.props.data=null;
    }
  }

  onResetClickHandler(e) {
    let { dispatch, masters } = this.props;
    const requstObject = {
      login_type: this.state.login_type,
      customer_email: this.state.customer_email,
      type: loginConstant.GENERATE_OTP_SERVER_REQUEST_TYPE,
    };
    dispatch(loginActions.generateOtp(requstObject));
  }

  handleOtpVerifyController(data) {
    if (this.state.visibleResetScreen !== true) {
      this.setState({
        visibleOTPScreen: false,
        visibleResetScreen: true,
        oldType: data.data.type,
      });
    }
  }

  onOTPClickHandler(e) {
    let { dispatch, masters } = this.props;
    if (this.state.loginOtpFlow) {
      let otpDetails = {
        login_type: this.state.login_type,
        customer_email: this.state.customer_email,
        otp: this.state.OTP,
        type: loginConstant.VERIFY_OTP_SERVER_REQUEST_TYPE,
      };
      dispatch(loginActions.verifyOTP(otpDetails));
    } else {
      const requstObject = {
        customer_email: this.state.customer_email,
        login_type: this.state.login_type,
        otp: this.state.OTP,
      };
      dispatch(loginActions.verifyOTP(requstObject));
    }
  }

  onConfirmClickHandler(e) {
    let { dispatch, masters } = this.props;
    const requstObject = {
      login_type: this.state.login_type,
      customer_email: this.state.email,
      password: this.state.password,
      type: loginConstant.RESET_PASSWORD_TYPE,
    };
    dispatch(loginActions.resetPassword(requstObject));
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    const { loading } = this.state;
    return (
      <Fragment>
        {loading && <CircleLoading/>}
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <img src={Icons.Dna} style={{ backgroundSize: "cover" }} />
              </div>
            </Col>
            <Col
              lg="8"
              md="12"
              className="h-100 d-flex justify-content-center align-items-center"
            >
              <Col lg="9" md="10" sm="12" className="app-login-box">
                <Card>
                  <CardBody>
                    <div className="logo logoWrapper">
                      <div
                        className="clientLogo"
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        {" "}
                        <img src={Icons.Logo} alt="logo" />{" "}
                        <div
                          style={{ position: "relative", top: -16, right: 10 }}
                        >
                          <b>V1.3.0</b>
                        </div>
                      </div>
                      <div className="dariaLogo">
                        <h4>
                          {this.state.app_name ? this.state.app_name : "AQUA"}
                        </h4>{" "}
                      </div>
                    </div>

                    <Nav pills fill>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Login Via Password
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2", "otp");
                          }}
                        >
                          Login Via OTP
                        </NavLink> */}
                      </NavItem>
                    </Nav>
                    <Row className="divider" />
                    <div>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <LoginPasswordComponent
                            handleLogin={this.handleLogin}
                            onChangeHandler={this.onChangeHandler}
                            onChange={this.onChange}
                            show={this.show}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <LoginWithOtpComponent
                            handleOtpLogin={this.handleOtpLogin}
                            onChangeHandler={this.onChangeHandler}
                            onChange={this.onChange}
                          />
                        </TabPane>
                      </TabContent>
                      <Row className="mt-4 d-flex align-items-center">
                        <h6 className="mb-0">
                          If you don't have an account?{" "}
                          <Link to="/signup" className="text-primary">
                            Create Account
                          </Link>
                        </h6>
                        <Col className="text-right">
                          <SpinnerButton
                            color="primary"
                            size="lg"
                            // disabled={this.state.isLoading}
                            style={{ marginTop: "10px" }}
                            onClick={
                              !this.state.loginOtpFlow
                                ? this.handleLogin
                                : this.handleOtpLogin
                            }
                            title={"Login to Dashboard"}
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </div>
        {/* {loading ? (
          <CircleLoading/>
        ):(
        )} */}
        <GenerateOtpComponent
          visible={this.state.visible}
          hide={this.hide}
          onChangeHandler={this.onChangeHandler}
          onResetClickHandler={this.onResetClickHandler}
        />
        <VerifyOtpComponent
          visibleOTPScreen={this.state.visibleOTPScreen}
          hide={this.hide}
          onChangeHandler={this.onChangeHandler}
          onOTPClickHandler={this.onOTPClickHandler}
        />
        <ResetOtpComponent
          visibleResetScreen={this.state.visibleResetScreen}
          hide={this.hide}
          onChangeHandler={this.onChangeHandler}
          onConfirmClickHandler={this.onResetClickHandler}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { login } = state;
  return { masters: login };
}

const connectedViewMaster = connect(mapStateToProps)(Login);

export default connectedViewMaster;
