import React from "react";
class DrawGrid extends React.Component {
  render() {
    return (
      <div className="container-drawgrid">
        <h2></h2>
        <table className="drawGrid">
          <tbody>
            <tr>
              {this.props.beds.length > 0
                ? this.props.beds.map((row) => (
                    <td
                      className={
                        this.props.reserved.indexOf(row) > -1
                          ? "reserved"
                          : this.props.available.indexOf(row) > -1
                          ? "available"
                          : this.props.occupied.indexOf(row) > -1
                          ? "occupied"
                          : null
                      }
                      key={row._id}
                      onClick={(e) =>
                        this.props.occupied.indexOf(row) > -1
                          ? null
                          : this.onClickSeat(row)
                      }
                    >
                      {row.bed_name}
                    </td>
                  ))
                : "There are no beds alloted for this room"}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  onClickSeat(seat) {
    this.props.onClickData(seat);
  }
}

export default DrawGrid;
