import React, { Component } from "react";
import { Bounce, toast } from "react-toastify";
import { otRoomService } from "../../services/operationTheatres/otRoom.service";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Table,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { sweetAlertActions } from "../../actions";
import { create_UUID } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faTrash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

class OTChargesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charges_list: [],
      service_amount: null,
      charges_type: "",
      consultant: "",
      ward: "",
      ot: "",
      amount: 0,
      amount_percent: "",
      ot_list: [],
      ward_list: [],
      selectedItem: null,
      utilized_amount: 0,
      balance: null,
    };
  }
  componentDidMount() {
    this.setState({ service_amount: this.props.serviceAmount });
    otRoomService
      .getAll()
      .then((res) => {
        if (res.success) {
          let ot_list = res.data.map((item) => ({
            name: item.room_name,
            code: item.code,
          }));
          this.setState({ ot_list: ot_list });
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    otRoomService
      .getWards()
      .then((res) => {
        if (res.success) {
          let ward_list = res.data.map((item) => ({
            name: item.ward_catagory,
            code: item.code,
          }));
          this.setState({ ward_list: ward_list });
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    // this.setState({ward_list:[{name:'Ward1',code:"ward1"},{name:'Ward2',code:"ward2"}]})
    const { charges_list } = this.props;
    this.setState({ charges_list: charges_list });
  }

  componentDidUpdate(pp, ps) {
    const { charges_list } = this.props;
    if (this.state.service_amount !== this.props.serviceAmount)
      this.setState({ service_amount: this.props.serviceAmount });
    // if (charges_list)
    //   {
    if (charges_list !== pp.charges_list) {
      this.setState({ charges_list: charges_list });
    }
    // }
  }
  onSave = () => {
    const result = this.state.charges_list.map((item) => {
      delete item._id;
      return item;
    });
    this.props.onSubmit(result);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      const { service_amount } = this.state;
      if (service_amount > 0) {
        let perc = (value / service_amount) * 100;
        this.setState({ amount_percent: perc.toFixed(2) });
      }
    }

    if (name === "service_amount") {
      const { amount } = this.state;
      if (value > 0) {
        let perc = (amount / value) * 100;
        this.setState({ amount_percent: perc.toFixed(2) });
      }
    }

    if (name === "amount_percent") {
      const { service_amount } = this.state;
      if (value) {
        let percent = value === "" ? 0 : value;
        let amount = (percent * service_amount) / 100;
        this.setState({ amount: amount.toFixed(2) });
      }
    }
    this.setState({ [name]: value });
  };

  onAddCharges = (e) => {
    // if(this.state.balance==0)
    // {
    //  return;
    // }

    const charges_list = [...this.state.charges_list];
    const {
      service_amount,
      charges_type,
      consultant,
      ward,
      ot,
      amount,
      amount_percent,
    } = this.state;
    if (
      service_amount === "" ||
      charges_type === "" ||
      amount === "" ||
      amount_percent === ""
    )
      return;
    charges_list.push({
      _id: create_UUID(),
      service_amount,
      charges_type,
      consultant,
      ward,
      ot,
      amount,
      amount_percent,
    });

    let utilized_amount = charges_list.reduce((acc, currentItem) => {
      return acc + parseFloat(currentItem.amount);
    }, 0);
    let balance = 0;
    if (service_amount >= 0 && utilized_amount >= 0) {
      balance = service_amount - utilized_amount;
    }

    this.setState({
      charges_list: charges_list,
      charges_type: "",
      consultant: "",
      ward: "",
      ot: "",
      amount: "",
      amount_percent: "",
      utilized_amount,
      balance,
    });
  };

  onUpdateCharges = (e) => {
    if (this.state.selectedItem) {
      const list = this.state.charges_list;
      const {
        service_amount,
        charges_type,
        consultant,
        ward,
        ot,
        amount,
        amount_percent,
      } = this.state;
      var prevData = [...list];
      let index = list.findIndex(
        (ob) => ob._id === this.state.selectedItem._id
      );
      prevData[index] = {
        _id: this.state.selectedItem._id,
        charges_type,
        consultant,
        ward,
        ot,
        amount,
        amount_percent,
      };

      let utilized_amount = prevData.reduce((acc, currentItem) => {
        return acc + parseFloat(currentItem.amount);
      }, 0);
      let balance = 0;
      if (service_amount >= 0 && utilized_amount >= 0) {
        balance = service_amount - utilized_amount;
      }

      this.setState({
        charges_list: prevData,
        selectedItem: null,
        charges_type: "",
        consultant: "",
        ward: "",
        ot: "",
        amount: 0,
        amount_percent: "",
        utilized_amount,
        balance,
      });
    }
  };

  getConsultantOrWardOrOt = (item) => {
    let val, exist;
    const { consultants } = this.props;
    const { ot_list, ward_list } = this.state;
    switch (item.charges_type) {
      case "Ward Charges":
        exist = ward_list.find((wd) => wd.code === item.ward);
        return exist ? exist.name : "";
      case "OT Charges":
        exist = ot_list.find((ot) => ot.code === item.ot);
        return exist ? exist.name : "";
      case "Basic Investigations":
        return "";
      default:
        exist = consultants.find((cons) => cons.code === item.consultant);
        return exist ? exist.firstname : "";
    }
  };

  onEditClick = (item, e) => {
    this.setState({
      selectedItem: item,
      charges_type: item.charges_type,
      consultant: item.consultant,
      ward: item.ward,
      ot: item.ot,
      amount: item.amount,
      amount_percent: item.amount_percent,
    });
  };

  onDeleteClick = (row, e) => {
    const { dispatch } = this.props;
    dispatch(
      sweetAlertActions.show("Do you want to delete", {
        title: "Do you want to delete",
        confirmButtonColor: "red",
        text: "You cannot restore the changes.",
        showCancelButton: true,
        onConfirm: () => {
          const list = this.state.charges_list;
          var prevData = [...list];
          let index = list.findIndex((ob) => ob._id === row._id);
          prevData.splice(index, 1);
          const { service_amount } = this.state;
          let utilized_amount = prevData.reduce((acc, currentItem) => {
            return acc + parseFloat(currentItem.amount);
          }, 0);
          let balance = 0;
          if (service_amount >= 0 && utilized_amount >= 0) {
            balance = service_amount - utilized_amount;
          }
          this.setState({ charges_list: prevData, utilized_amount, balance });
          toast("Deleted successfully!", {
            transition: Bounce,
            closeButton: true,
            autoClose: 1000,
            position: "bottom-center",
            type: "success",
          });

          dispatch(sweetAlertActions.hide());
        },
        onCancel: () => dispatch(sweetAlertActions.hide()),
      })
    );
  };
  getWhoseCharges(props) {}
  render() {
    const { consultants } = this.props;
    const { charges_list, ot_list, ward_list } = this.state;
    let whos_charges = <></>;
    switch (this.state.charges_type) {
      case "Ward Charges":
        whos_charges = (
          <CustomInput
            type="select"
            bsSize="sm"
            name="ward"
            value={this.state.ward}
            onChange={this.handleChange.bind(this)}
          >
            <option value="">Select</option>
            {ward_list &&
              ward_list.map((item) => (
                <option value={item.code}>{item.name}</option>
              ))}
          </CustomInput>
        );
        break;
      case "OT Charges":
        whos_charges = (
          <CustomInput
            type="select"
            bsSize="sm"
            name="ot"
            value={this.state.ot}
            onChange={this.handleChange.bind(this)}
          >
            <option value="">Select</option>
            {ot_list &&
              ot_list.map((item) => (
                <option value={item.code}>{item.name}</option>
              ))}
          </CustomInput>
        );
        break;
      case "Basic Investigations":
        whos_charges = <></>;
        break;
      case "Surgeon Charges":
      case "Asst. Surgeon Charges":
      case "Aneastist Charges":
        whos_charges = (
          <CustomInput
            type="select"
            bsSize="sm"
            name="consultant"
            value={this.state.consultant}
            onChange={this.handleChange.bind(this)}
          >
            <option value="">Select</option>
            {consultants &&
              consultants.map((item) => (
                <option value={item.code}>{item.firstname}</option>
              ))}
          </CustomInput>
        );
        break;
    }
    return (
      <Modal
        isOpen={this.props.showModal}
        toggle={() => this.props.toggle()}
        style={{ width: 750 }}
      >
        <ModalHeader toggle={() => this.props.toggle()}>
          OT Charges{" "}
        </ModalHeader>
        <ModalBody>
          <Table size="sm">
            <tr style={{ backgroundColor: "#ECEFF2" }}>
              <td>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>Service</div>
              </td>
              <td>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>Amount</div>
              </td>
              <td>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>
                  Utilized Amount
                </div>
              </td>
              <td>
                <div style={{ fontSize: 16, fontWeight: "bold" }}>
                  Balance Amount
                </div>
              </td>
            </tr>
            <tr style={{ backgroundColor: "#ECEFF2" }}>
              <td>
                <div style={{ color: "orange", fontWeight: "bold" }}>
                  {this.props.serviceName}
                </div>
              </td>
              <td>
                <div>
                  <Input
                    type="number"
                    value={this.state.service_amount}
                    name="service_amount"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </td>
              <td>
                <div>
                  <Input
                    type="number"
                    value={this.state.utilized_amount}
                    name="utilized_amount"
                  />
                </div>
              </td>
              <td>
                <div>
                  <Input
                    type="number"
                    value={this.state.balance}
                    name="balance"
                  />
                </div>
              </td>
            </tr>
          </Table>
          <Table size="sm">
            <thead>
              <tr>
                <td colSpan="4" style={{ height: 10 }}></td>
              </tr>
              <tr>
                <th>Service Name</th>
                <th>Ward/OT/Consultant</th>
                <th>Amount</th>
                <th>Amount %</th>
                <th>Add</th>
              </tr>
              <tr>
                <td>
                  <CustomInput
                    type="select"
                    bsSize="sm"
                    name="charges_type"
                    value={this.state.charges_type}
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value="">Select</option>
                    <option value="Ward Charges">Ward Charges</option>
                    <option value="Basic Investigations">
                      Basic Investigations
                    </option>
                    <option value="OT Charges">OT Charges</option>
                    <option value="Surgeon Charges">Surgeon Charges</option>
                    <option value="Asst. Surgeon Charges">
                      Asst. Surgeon Charges
                    </option>
                    <option value="Aneastist Charges">Aneastist Charges</option>
                  </CustomInput>
                </td>
                <td>{whos_charges}</td>
                <td>
                  <Input
                    type="number"
                    bsSize="sm"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange.bind(this)}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    bsSize="sm"
                    name="amount_percent"
                    value={this.state.amount_percent}
                    onChange={this.handleChange.bind(this)}
                  />
                </td>
                <td>
                  {!this.state.selectedItem ? (
                    <Button bsSize="sm" onClick={this.onAddCharges.bind(this)}>
                      Add
                    </Button>
                  ) : (
                    <Button
                      bsSize="sm"
                      onClick={this.onUpdateCharges.bind(this)}
                    >
                      Update
                    </Button>
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div
            className="scroll-area-sm"
            style={{ border: "2px solid #e0f3ff" }}
          >
            <PerfectScrollbar>
              <Table size="sm">
                <thead>
                  <th>Sno.</th>
                  <th>Service Name</th>
                  <th>Ward/Consultant</th>
                  <th>Amount</th>
                  <th>Amount %</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </thead>
                <tbody>
                  {charges_list &&
                    charges_list.map((item, ind) => (
                      <tr>
                        <td>{ind + 1}</td>
                        <td>{item.charges_type}</td>
                        <td>{this.getConsultantOrWardOrOt(item)}</td>
                        <td>{item.amount}</td>
                        <td>{item.amount_percent}</td>
                        <td>
                          <Button
                            sm
                            color="warning"
                            onClick={this.onEditClick.bind(this, item)}
                          >
                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <Button
                            sm
                            color="danger"
                            onClick={this.onDeleteClick.bind(this, item)}
                          >
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </PerfectScrollbar>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Row>
            <Col sm="6">
              <Button
                className="btn btn-block"
                color="primary"
                bsSize="sm"
                onClick={this.onSave.bind(this)}
              >
                Save
              </Button>
            </Col>
            <Col sm="6">
              <Button
                className="btn btn-block"
                color="warning"
                bsSize="sm"
                onClick={() => this.props.toggle()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { consultants, serviceMaster } = state;

  return {
    consultants: consultants.consultants,
    packageData: serviceMaster.data,
  };
}

const connectedOTChargesModal = connect(mapStateToProps)(OTChargesModal);
export default connectedOTChargesModal;
