import { generalMasterConstants } from "../constants";

export function ConsentForm(state = {}, action) {
  switch (action.type) { 
    case generalMasterConstants.GET_ConsentForm_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_ConsentForm_SUCCESS:
        ;
      return { ...state, ConsentForm: action.concentform, loading: false };
    case generalMasterConstants.GET_ConsentForm_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_ConsentForm_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_ConsentForm_SUCCESS:
      return {
        ...state,
        ConsentForm: [...state.ConsentForm, action.ConsentForm],
        loading: false,
      };
    case generalMasterConstants.ADD_ConsentForm_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_ConsentForm_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_ConsentForm_SUCCESS:
      let updatedState = [...state.ConsentForm];
      let index = updatedState.findIndex(
        (ob) => ob.id === action.ConsentForm.id
      );
      updatedState[index] = action.ConsentForm;
      return {
        ...state,
        ConsentForm: [...updatedState],
        loading: false,
      };
    case generalMasterConstants.UPDATE_ConsentForm_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_ConsentForm_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_ConsentForm_SUCCESS:
        
      if (state.ConsentForm) {
        state.ConsentForm[action.ConsentForm].deleted = !state.ConsentForm[
          action.ConsentForm
        ].deleted;
        state.ConsentForm[action.ConsentForm].active = !state.ConsentForm[
          action.ConsentForm
        ].active;
      }

      return {
        ...state,
        ConsentForm: [...state.ConsentForm],
        loading: false,
      };
    case generalMasterConstants.DELETE_ConsentForm_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
