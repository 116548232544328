import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Input,
  CustomInput,
  FormGroup,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { otBookingActions } from "../../actions";

class OTBookingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues:this.initialValues
    };
  }

  initialValues = {
    surgery_status_type: "",
    surgery_status_remarks:""
  };

  onSubmit = (values) => {
    const { booking, dispatch } = this.props;
    const { surgery_status_type,surgery_status_remarks } = values;
    if (booking) {
      const payload = {
        id: booking._id,
        surgery_status_type: surgery_status_type,
        surgery_status_remarks:surgery_status_remarks
      };
      dispatch(otBookingActions.update(payload));
      this.props.toggle();
    }
  };

  validationSchema = Yup.object({
    surgery_status_type: Yup.string().required("Required"),
    surgery_status_remarks: Yup.string(),
  });

  render() {
    const { surgery_status } = this.props;


    let status_types=<></>;
    if(surgery_status==="COMPLETED")
    {
      status_types=<>
      <option value="Successful">Successful</option>
      <option value="Partially Successful">Partially Successful</option></>
    }
    else if(surgery_status==="CANCELLED")
{  status_types=<>
  <option value="Cancelled on Table">Cancelled on Table</option>
  <option value="Pre Notified Cancellation">Pre Notified Cancellation</option></>}
      else if(surgery_status==="POSTPONED")
      {  status_types=<>
        <option value="Postponed by Hospital">Postponed by Hospital</option>
        <option value="Postponed by Patient">Postponed by Patient</option></>}

    return (
      <Fragment>
        <Modal
          isOpen={this.props.openModal}
          toggle={this.props.toggle}
          style={{ width: 500 }}
        >
          <ModalHeader toggle={this.props.toggle}>Change Surgery Status</ModalHeader>

          <Formik
            initialValues={this.state.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
            enableReinitialize
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                getFieldProps,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <br />
                      <h5>Do you want to change the booking status?</h5>
                      <br />
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="surgery_status_type">
                            Please select type *{" "}
                          </Label>
                          <CustomInput bsSize="sm"
                            type="select"
                            name="surgery_status_type"
                            {...getFieldProps("surgery_status_type")}
                          >
                            {status_types}
                          </CustomInput>
                          <FormFeedback>
                            {errors.surgery_status_type &&
                              touched.surgery_status_type &&
                              errors.surgery_status_type}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="surgery_status_remarks">Remarks </Label>
                          <Input
                            type="text" bsSize="sm"
                            name="surgery_status_remarks"
                            {...getFieldProps("surgery_status_remarks")}
                          ></Input>
                          <FormFeedback>
                            {errors.surgery_status_remarks &&
                              touched.surgery_status_remarks &&
                              errors.surgery_status_remarks}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="warning"
                      className="btn-shadow btn-wide  btn-hover-shine right-side"
                      onClick={this.props.toggle}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      className="btn-shadow btn-wide  btn-hover-shine right-side"
                      type="submit"
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { registrationMasters } = state;
  return { masters: registrationMasters };
}

const connectedOTBookingStatus = connect(mapStateToProps)(OTBookingStatus);
export default connectedOTBookingStatus;
