import React, { Component, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { appointmentActions } from "../../../actions";
import { apiUrl } from "../../../constants";
import { withStyles } from "@material-ui/core";
import { styles } from "./styles";
import cx from "classnames";
class ConsultantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConsultant: null,
      consultants: [],
      countArr: [],
      total: 0,
    };
  }

  // Handle Doctor
  onDoctorBtnClick(consultant) {
    const { dispatch } = this.props;
    dispatch(
      appointmentActions.getAll(
        `startDate=${moment(new Date()).format("YYYY-MM-DD")}&&consultantId=${
          consultant.id
        }`
      )
    );
  }

  // Handling all the doctors
  onAllDoctorBtnClick() {
    const { dispatch } = this.props;
    dispatch(
      appointmentActions.getAll(`startDate=${moment(new Date()).format("YYYY-MM-DD")}`)
    );
  }

  // On component Load
  componentDidMount() {
    this.onAllDoctorBtnClick();
  }
  render() {
    const { consultantList, totalAppointments, classes } = this.props;
    return (
      <Fragment>
        <Row>
          <Col lg="12">
            <>
              <CardTitle className="mb-0">
                <div className={classes.apointmentIndicators}>
                  Appointment Indicators
                </div>
              </CardTitle>
            </>
          </Col>
          <Col lg="12">
            <ul style={{ "list-style-type": "none", "padding-left": "10px" }}>
              <li>
                <svg width="30" height="20">
                  <rect width="200" height="100" fill="#ffbf00" />
                </svg>{" "}
                New
              </li>
              <li>
                <svg width="30" height="20">
                  <rect width="200" height="100" fill="#545cd8" />
                </svg>{" "}
                Engaged
              </li>
              <li>
                <svg width="30" height="20">
                  <rect width="200" height="100" fill="#66cc66" />
                </svg>{" "}
                Done
              </li>
              <li>
                <svg width="30" height="20">
                  <rect width="200" height="100" fill="#ff4d4d" />
                </svg>{" "}
                Cancelled
              </li>
            </ul>
          </Col>
          <Col lg="12" className={this.props.doctorsList}>
            <>
              <CardTitle style={{ marginBottom: 0 }}>
                <div
                  onClick={() => {
                    this.onAllDoctorBtnClick();
                  }}
                  className={classes.allDoctorsList}
                >
                  All Doctors
                  <div style={{ float: "right" }}>
                    <div className="ml-auto badge badge-pill badge-warning">
                      {totalAppointments}
                    </div>
                  </div>
                </div>
              </CardTitle>
            </>
            <div
              className="scroll-area-lg"
              style={{ border: "2px solid #e0f3ff" }}
            >
              <PerfectScrollbar>
                <ListGroup>
                  {consultantList.map((consultant, i) => {
                   return (
                        <ListGroupItem
                          className={classes.consultantList}
                          onClick={(e) => this.onDoctorBtnClick(consultant)}
                        >
                          <div className="widget-content p-0 hoverable">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <Button
                                  color="link"
                                  style={{ paddingRight: 0 }}
                                >
                                  {consultant.photo && <img
                                    className={classes.consultantImage}
                                    src={apiUrl + consultant.photo}
                                    alt="No Image"
                                  />}
                                  {consultant.online ? (
                                    <span
                                      className={cx(
                                        "badge badge-dot badge-dot-lg",
                                        classes.consultantOnline
                                      )}
                                    >
                                      {" "}
                                    </span>
                                  ) : (
                                    <span
                                      className={cx(
                                        "badge badge-danger badge-dot badge-dot-lg",
                                        classes.consultantOffline
                                      )}
                                    >
                                      {" "}
                                    </span>
                                  )}
                                </Button>
                              </div>
                              <div
                                className={cx(
                                  "widget-content-left",
                                  classes.widgetContent
                                )}
                              >
                                <b>
                                  {consultant.firstName +
                                    " " +
                                    (consultant.lastName
                                      ? consultant.lastName
                                      : "")}
                                </b>{" "}
                              </div>
                              <div className="widget-content-right">
                                <div className="ml-auto badge badge-pill badge-warning">
                                  {consultant.totalAppointments}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      );
                  })}
                </ListGroup>
              </PerfectScrollbar>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { consultants } = state;
  return { consultants: consultants.consultants };
}

const connectedConsultantList = connect(mapStateToProps)(ConsultantList);
export default withStyles(styles)(connectedConsultantList);
