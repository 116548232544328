import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"

export const otBookingService = {        
    getAll,
    search,
    add,
    update,
    changeStatus,
    remove,
    getDateWise,
    getByConsultantId,
    getBookingCounts,
    getLastBooking,
    getSlotWise,
    getBookedSlots,
    cancelBooking,
    setReminder,
    getDepartmentWiseCounts,
    getBookingConsultantWise
   };



   function getAll(query) {
    const requestOptions = {
        method: 'GET',  
       headers:{ authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/otBooking?${query}`, requestOptions).then(helperService.handleResponse);
}

function search(query) {
    const requestOptions = {
        method: 'GET',  
       headers:{ authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/otBooking/search?query=${query}`, requestOptions).then(helperService.handleResponse);
}


function getDateWise(query) {
    const requestOptions = {
        method: 'GET',  
       headers:{ authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/otBooking/getDateWise?${query}`, requestOptions).then(helperService.handleResponse);
}
function getBookingCounts() {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/otBooking/getBookingCounts`, requestOptions).then(helperService.handleResponse);
}

function getDepartmentWiseCounts(query) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/otBooking/getDepartmentWiseCount?${query}`, requestOptions).then(helperService.handleResponse);
}

function getBookingConsultantWise(query) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/otBooking/getConsultantWise?${query}`, requestOptions).then(helperService.handleResponse);
}

function getLastBooking() {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData() }  
    };
    return fetch(`${apiUrl}/otBooking/getLastBooking`, requestOptions).then(helperService.handleResponse);
}


function getByConsultantId(id) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()   }
    };
    return fetch(`${apiUrl}/otBooking?consultant_id=${id}`, requestOptions).then(helperService.handleResponse);
}

function getSlotWise(id,operation_date) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()}   
    };
    return fetch(`${apiUrl}/otBooking/getSlotWise?consultant_id=${id}&&operation_date=${operation_date}`, requestOptions).then(helperService.handleResponse);
}

function getBookedSlots(id,operation_date) {
    const requestOptions = {
        method: 'GET',
       headers:{ authorization : helperService.getHeaderData()}   
    };
    return fetch(`${apiUrl}/otBooking/getBookedSlots?consultant_id=${id}&&operation_date=${operation_date}`, requestOptions).then(helperService.handleResponse);
}
function add(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/otBooking`, requestOptions).then(helperService.handleResponse);
}

function changeStatus(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/otBooking/changeStatus`, requestOptions).then(helperService.handleResponse);
}
function cancelBooking(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/otBooking/cancel`, requestOptions).then(helperService.handleResponse);
}



function setReminder(data) {

    const requestOptions = {
        method: 'POST',     
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/otBooking/setreminder`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/otBooking/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/otBooking/delete`, requestOptions).then(helperService.handleResponse);
}


