import { generalMasterConstants } from "../constants";

export function banks(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_BANK_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_BANK_SUCCESS:
      return { ...state, banks: action.banks, loading: false };
    case generalMasterConstants.GET_BANK_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_BANK_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_BANK_SUCCESS:
      return { ...state, banks: [...state.banks, action.bank], loading: false };
    case generalMasterConstants.ADD_BANK_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.SET_SELECTED_BANK_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.SET_SELECTED_BANK_SUCCESS:
      const sel_bank = state.banks.find((bank) => {
        return bank._id === action.data.BankId;
      });
      const selected_bank = {
        ...sel_bank,
        branches: action.data.branches,
      };
      return { ...state, selected_bank, loading: false };
    case generalMasterConstants.SET_SELECTED_BANK_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_BRANCH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_BRANCH_SUCCESS:
      const branches = [...state.selected_bank.branches, action.branch];
      return {
        ...state,
        selected_bank: { ...state.selected_bank, branches },
        loading: false,
      };
    case generalMasterConstants.ADD_BRANCH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_BRANCH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_BRANCH_SUCCESS:
      const updatedState = action.branch;
      const index = state.selected_bank.branches.findIndex(
        (ob) => ob._id === updatedState._id
      );
      state.selected_bank.branches[index] = updatedState;
      return {
        ...state,
        selected_bank: {
          ...state.selected_bank,
          branches: [...state.selected_bank.branches],
        },
        loading: false,
      };
    case generalMasterConstants.UPDATE_BRANCH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_BRANCH_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_BRANCH_SUCCESS:
      let deletedBranch = action.branch;
      let dindex = state.selected_bank.branches.findIndex(
        (ob) => ob.code === deletedBranch.code
      );
      state.selected_bank.branches[dindex].deleted = deletedBranch.deleted;
      state.selected_bank.branches[dindex].active = deletedBranch.active;
      // state.selected_bank.branches.splice(dindex, 1);
      console.log(state.selected_bank.branches);
      return {
        ...state,
        selected_bank: {
          ...state.selected_bank,
          branches: [...state.selected_bank.branches],
        },
        loading: false,
      };
    case generalMasterConstants.DELETE_BRANCH_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_BANK_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_BANK_SUCCESS:
      const deletedBanks = state.banks
        ? state.banks.map((bank) =>
            bank.id === action.bank.id ? { ...bank, ...action.bank } : bank
          )
        : state.banks;

      return {
        ...state,
        banks: deletedBanks,
        loading: false,
      };

    case generalMasterConstants.DELETE_BANK_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_BANK_REQUEST:
      return { ...state, loading: true };

    case generalMasterConstants.UPDATE_BANK_SUCCESS:
      let updatedBanks = [...state.banks];
      let i = updatedBanks.findIndex((bank) => bank.id === action.data.id);
      updatedBanks[i] = action.data;
      return {
        ...state,
        banks: [...updatedBanks],
        loading: false,
      };

    case generalMasterConstants.UPDATE_BANK_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
