//import { authHeader } from '../store';
import {helperService} from "./helper.service"
import {apiUrl} from "../constants"



export const clinicService = {        
    getAll,
    add,
    update,
    remove
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
      headers:{  authorization : helperService.getHeaderData()  } 
    };
    return fetch(`${apiUrl}/clinics`, requestOptions).then(helperService.handleResponse);
}



function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/clinics`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/clinics/update`, requestOptions).then(helperService.handleResponse);
}

function remove(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',    authorization : helperService.getHeaderData()   },
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/clinics/delete`, requestOptions).then(helperService.handleResponse);
}
