import { customerConstants } from '../constants';

export function customers(state = {}, action) {

  switch (action.type) {
    case customerConstants.GET_ALL_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case customerConstants.GET_ALL_CUSTOMERS_SUCCESS:
      return { ...state, loading: false, customers: action.data };
    case customerConstants.GET_ALL_CUSTOMERS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case customerConstants.ADD_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case customerConstants.ADD_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customers: action.data };
    case customerConstants.ADD_CUSTOMER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case customerConstants.UPDATE_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case customerConstants.UPDATE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customers: action.data };
    case customerConstants.UPDATE_CUSTOMER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case customerConstants.GET_CUSTOMER_BY_ID_REQUEST:
      return { ...state, loading: true };
    case customerConstants.GET_CUSTOMER_BY_ID_SUCCESS:
      return { ...state, selected_customer: action.data };
    case customerConstants.GET_CUSTOMER_BY_ID_FAILURE:
      return { ...state, error: action.error };

    case customerConstants.GET_TRANSACTION_DETAIL_BY_ID_REQUEST:
      return { ...state, loading: true };
    case customerConstants.GET_TRANSACTION_DETAIL_BY_ID_SUCCESS:
      return { ...state, transactions: action.data };
    case customerConstants.GET_TRANSACTION_DETAIL_BY_ID_FAILURE:
      return { ...state, error: action.error };


    case customerConstants.SET_TRANSACTION_DETAIL: {
      return { ...state, currentTransaction: action.transaction }
    }
    case customerConstants.CUSTOMER_CLEAR:
      { }
    default:
      return state

  }
}