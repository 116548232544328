import React, { Component } from "react";

export default class TextInput extends Component {
  // constructor(props)
  // {
  //     super(props);

  // }

  onChange(e) {
    var num = e.target.value;
    if (this.props.textOnly) num = e.target.value.replace(/[0-9]/g, "");
    let allowSpecial = this.props.allowSpecial
      ? this.props.allowSpecial
      : false;

    if (this.props.type === "email" || allowSpecial) {
    } else {
      num = e.target.value.replace(/[^\w\s-]/gi, "");
    }

    if (this.props.onChange)
      this.props.onChange({ target: { name: this.props.name, value: num } });
  }

  render() {
    const {
      key,
      label,
      placeholder,
      type,
      name,
      required,
      readonly,
      textOnly,
      disabled,
      invalid,
      maxLength,
    } = this.props;

    return (
      <div className="form-group">
        {label && (
          <label for={key}>
            {label} {required ? <a className="mandatory"> *</a> : ""}
          </label>
        )}
        <input
          maxlength={maxLength}
          type={type}
          style={invalid ? { border: "1px solid red" } : null}
          className="form-control form-control-sm"
          id={key}
          readOnly={readonly}
          disabled={disabled}
          placeholder={placeholder}
          value={this.props.value}
          name={name}
          onChange={(e) => {
            this.props.onChangeHandler
              ? this.props.onChangeHandler(e)
              : this.onChange(e);
          }}
        />
      </div>
    );
  }
}
