import { menuConstants } from '../constants';

export function menu(state = {menu: [],loading:false}, action) {

  switch (action.type) { 
          case menuConstants.GET_MENU_REQUEST:
            return {...state, loading: true};
          case menuConstants.GET_MENU_SUCCESS:
            return {menu: action.menus,loading:false};          
          case menuConstants.GET_MENU_FAILURE:
            return { ...state,error: action.error,loading:false };                   
         
    default:
      return state
  }
}