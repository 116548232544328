//import { authHeader } from '../store';
import { helperService } from "./helper.service";
import { apiUrl } from "../constants";

export const labPatientListService = {
  getAll,
  update,
  remove,
  getById,
  getByDate,
  getStatusCount,
  getWhereSampleCollected,
  getWhereReportProcessed,
  abort,
  getByUHID,
  approveVerifyReport,
  getOpPatientReport,
  getByDateReport
};

function getAll(query) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/laboratorypatients?${query}`, requestOptions).then(
    helperService.handleResponse
  );
}

function getWhereSampleCollected(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratorypatients/sampleCollected?from=${from}&&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}
function getWhereReportProcessed(from, to) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratorypatients/reportProcessed?from=${from}&&to=${to}`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getStatusCount() {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };

  return fetch(
    `${apiUrl}/laboratorypatients/statics/getCounts`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(`${apiUrl}/laboratorypatients/${id}`, requestOptions).then(
    helperService.handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratorypatients/update`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByUHID(uhid) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify({ patient_uhid: uhid }),
  };

  return fetch(`${apiUrl}/laboratorypatients/getByUHID`, requestOptions).then(
    helperService.handleResponse
  );
}

function abort(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratorypatients/abort`, requestOptions).then(
    helperService.handleResponse
  );
}
function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${apiUrl}/laboratorypatients/delete`, requestOptions).then(
    helperService.handleResponse
  );
}

function getByDate(from, to, status) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratorypatients?from=${from}&&to=${to}&&sample_status=${status}`,
    requestOptions
  ).then(helperService.handleResponse);
}

// karthick added approveVerifyReport - approve or suspend report from approve reports

function approveVerifyReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: helperService.getHeaderData(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiUrl}/laboratorypatients/approveVerifyReport`,
    requestOptions
  ).then(helperService.handleResponse);
}

function getOpPatientReport(from,to) {
  const requestOptions = {
      method: 'GET',
      headers:{'Content-Type':'application/json', "authorization" : helperService.getHeaderData()}
  };
  return fetch(`${apiUrl}/laboratorypatients/getOpPatientReport?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}

function getByDateReport(from, to, status, sampleDate) {
  const requestOptions = {
    method: "GET",
    headers: { authorization: helperService.getHeaderData() },
  };
  return fetch(
    `${apiUrl}/laboratorypatients?start=${from}&&end=${to}&&sample_status=${status}&&sampleDate=${sampleDate}`,
    requestOptions
  ).then(helperService.handleResponse);
}

