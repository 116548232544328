import React, { Component } from "react";
import CountUp from "react-countup";
import { Card, CardHeader } from "reactstrap";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";

const data2 = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
];
class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0 };
  }
  componentDidUpdate(pp, ps) {
    if (pp.data.length !== this.props.data.length) {
      const { dataKey, data } = this.props;
      var total = 0;
      data.forEach((item) => {
        total = total + item[dataKey];
      });
      this.setState({ total: total });
    }
  }

  render() {
    return (
      <div>
        {/* <Chart options={this.state.options3} series={this.state.series} width="100%" /> */}

        <Card className="card-hover-shadow-2x mb-3">
          <CardHeader className="rm-border responsive-center text-left">
            <div>
              <h5 className="menu-header-title text-capitalize text-danger">
                {this.props.title}
              </h5>
            </div>
          </CardHeader>
          <div className="widget-chart widget-chart2 text-left pt-0">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="text-danger">
                        <CountUp
                          start={0}
                          end={this.state.total}
                          separator=""
                          decimals={0}
                          decimal=","
                          prefix=""
                          duration="10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-10 m-0">
                <ResponsiveContainer height={131}>
                  <AreaChart
                    data={this.props.data}
                    //margin={{top: -20, right: 0, left: 0, bottom: 0}}
                  >
                    <Tooltip />
                    <Area
                      type="monotoneX"
                      dataKey={this.props.dataKey}
                      stroke="var(--danger)"
                      strokeWidth={3}
                      fill="var(--danger)"
                      fillOpacity=".1"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default Graph;
Graph.defaultProps = {
  data: [],
  dataKey: "",
  total: 0,
  title: "Graph Title",
};
