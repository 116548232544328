//import { authHeader } from '../store';
import {helperService} from "../helper.service"
import {apiUrl} from "../../constants"



export const departmentTransferService = {        
    getAll,
    add,
    update,  
    get,  
    getByDate 
   };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/departmentTransfer`, requestOptions).then(helperService.handleResponse);
}

function get(code) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/departmentTransfer/${code}`, requestOptions).then(helperService.handleResponse);
}
function getByDate(from,to) {
    const requestOptions = {
        method: 'GET',
        headers:{"authorization" : helperService.getHeaderData()}
    };
    return fetch(`${apiUrl}/departmentTransfer?from=${from}&to=${to}`, requestOptions).then(helperService.handleResponse);
}

function add(data) {

    const requestOptions = {
        method: 'POST',  
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };

    return fetch(`${apiUrl}/departmentTransfer`, requestOptions).then(helperService.handleResponse);
}
function update(data) {

    const requestOptions = {
        method: 'POST',
        headers:{'Content-Type':'application/json',"authorization" : helperService.getHeaderData()},   
        body:JSON.stringify(data)
    };
   
    return fetch(`${apiUrl}/departmentTransfer/update`, requestOptions).then(helperService.handleResponse);
}



