import { generalMasterConstants } from "../constants";

export function discountTypes(state = {}, action) {
  switch (action.type) {
    case generalMasterConstants.GET_DISCOUNT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.GET_DISCOUNT_TYPE_SUCCESS:
      return { ...state, discountTypes: action.discountTypes, loading: false };
    case generalMasterConstants.GET_DISCOUNT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.ADD_DISCOUNT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.ADD_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        discountTypes: [...state.discountTypes, action.discountType],
        loading: false,
      };
    case generalMasterConstants.ADD_DISCOUNT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.UPDATE_DISCOUNT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.UPDATE_DISCOUNT_TYPE_SUCCESS:
      let updatedState = [...state.discountTypes];
      let index = updatedState.findIndex(
        (ob) => ob.id === action.discountType.id
      );
      updatedState[index] = action.discountType;
      return {
        ...state,
        discountTypes: [...updatedState],
        loading: false,
      };
    case generalMasterConstants.UPDATE_DISCOUNT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.DELETE_DISCOUNT_TYPE_REQUEST:
      return { ...state, loading: true };
    case generalMasterConstants.DELETE_DISCOUNT_TYPE_SUCCESS:
      const updatedDiscountTypes = state.discountTypes
        ? state.discountTypes.map((discountType) =>
            discountType.id === action.discountType.id
              ? { ...discountType, ...action.discountType }
              : discountType
          )
        : state.discountTypes;

      return {
        ...state,
        discountTypes: updatedDiscountTypes,
        loading: false,
      };

    case generalMasterConstants.DELETE_DISCOUNT_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case generalMasterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
